/*eslint-disable*/
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles((theme) => ({
  divMain: {
    maxWidth: 345,
  },
  card: {
    width: 720,
    maxWidth: 720,
    minHeight: 77,
    borderRadius: 16,
    backgroundColor: "#2d3034",
    padding: theme.spacing(2),
  },
  griCard: {
    padding: theme.spacing(2),
  },
  noWrap: {
    textOverflow: "ellipsis",
    wordWrap: "anywhere",
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": 3,
    "-webkit-box-orient": "vertical",
  },
}));

export default function CardHistory(props) {
  const classes = useStyles();
  const { data } = props;

  const options = {
    // weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  return (
    <Card className={classes.card}>
      <Grid container direction="column">
        <Grid
          item
          xs={12}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 16,
              color: "#ffffff",
              textAlign: "left",
              lineHeight: 1.2,
            }}
          >
            {data?.Action}
          </Typography>
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 12,
              color: "#ffffff",
              textAlign: "left",
            }}
          >
            {new Date(data?.Date).toLocaleString("es-MX", options)}
          </Typography>
        </Grid>
        {data?.Action === "Estatus de empleado modificado" && (
          <Grid item>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 14,
                color: "#c1c1c1",
                textAlign: "left",
              }}
            >
              {data?.Body.statusDemek}
            </Typography>
          </Grid>
        )}

        <Grid item style={{ marginTop: 8 }}>
          <Grid container direction="row">
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 14,
                color: "#fff",
                textAlign: "left",
              }}
            >
              Acción realizada por:
            </Typography>

            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 14,
                color: "#c1c1c1",
                textAlign: "left",
                marginLeft: 8,
              }}
            >
              {data?.User.Email}
            </Typography>
          </Grid>
        </Grid>

        {data?.Action === "Estatus de empleado modificado" && (
          <Grid item style={{ marginTop: 8 }}>
            <Grid container direction="row">
              <FontAwesomeIcon
                icon={faComment}
                style={{
                  width: 12,
                  height: 12,
                  color: "#ac0bce",
                  marginTop: 2,
                }}
              />

              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 12,
                  color: "#b9b9b9",
                  textAlign: "left",
                  marginLeft: 8,
                }}
              >
                {data?.Body.comentariosStatusDemek}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Card>
  );
}
