/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../utils/axiosInstance";
import FlowComponent from "./components/FlowComponent";
import DynamicForm from "../../common_components/DynamicForm";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    paddingLeft: "30px",
  },
  divBack: {
    cursor: "pointer",
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
  back: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 500,
    textAlign: "left",
    color: "#ffffff",
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 24,
    fontWeight: 500,
    textAlign: "left",
    color: "#ffffff",
  },
  subtitle: {
    fontFamily: "Roboto",
    fontSize: 14,
    textAlign: "left",
    color: "#ffffff",
  },
  icon: {
    width: "35px",
    height: "35px",
    color: "white",
    backgroundColor: "#a2a2a2",
    borderRadius: "15px",
  },
  gridMargin: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
  gridMargin2: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 20,
    },
  },
}));

export default function Enterprise(props) {
  const classes = useStyles();
  const [info, setInfo] = useState(null);
  const [selectedSection, setSelectedSection] = useState("Perfil");
  const [form, setForm] = useState(null);
  const history = useHistory();


  const backStep = () => {};

  const nextStep = () => {};

  const lastStep = () => {};

  const changeStep = (step) => {
    setSelectedSection(step);
  };

  const renderRegisterScreen = () => {};

  const saveSubcontractor = async () => {};

  const updateSubcontractor = async () => {};

  const savePhotoSubcontractor = async () => {};

  useEffect(() => {
    //{{server}}{{version}}rules/config?type_string=Destajista3_form
    getInfo();
    getForm();
  }, []);

  const getInfo = () => {
    var params = new URLSearchParams();
    params.append("type_string", "enterprise_type");
    params.append("formName_string", "DestajistaTest_form");

    axiosInstance()
      .get("/api/v1/rules/config", {
        params,
      })
      .then((res) => {
        console.log("data", res.data.data);
        setInfo(res.data.data[0]);
      })
      .catch((e) => {
        console.log("error");
      });
  };

  const getForm = () => {
    var params = new URLSearchParams();
    params.append("type_string", "DestajistaTest_form");

    axiosInstance()
      .get("/api/v1/rules/config", {
        params,
      })
      .then((res) => {
        let data = res.data.data.map((data) => ({
          ...data.formik,
          section: data.subsection,
        }));
        setForm(data);
      })
      .catch((e) => {
        console.log("error");
      });
  };

  return (
    <div className={classes.divMain}>
      <Grid container>
        <Grid
          item
          xs={6}
          sm={4}
          md={2}
          lg={2}
          xl={2}
          container
          direction="row"
          alignItems="center"
          onClick={backStep}
          className={classes.divBack}
        >
          <Grid item onClick={() => history.goBack()}>
            <FontAwesomeIcon
              icon={faArrowAltCircleLeft}
              style={{
                width: "20px",
                height: "20px",
                marginRight: "10px",
                color: "#d6d6d6",
              }}
            />
          </Grid>
          <Grid item>
            <Typography className={classes.back}>Regresar</Typography>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={8} md={10} lg={10} xl={10}></Grid>

        <Grid item xs={12}>
          <Typography className={classes.title}>
            Editar subcontratista
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.subtitle}>
            Actualiza los datos de la empresa subcontratista
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          <FlowComponent
            sections={info?.subsections}
            selectedSection={selectedSection}
            changeStep={changeStep}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
          {/* <ScrollBars style={{ width: "100%", height: "60vh" }}> */}
          <DynamicForm
            formJson={form?.filter((x) => x.section == selectedSection) ?? []}
          />
          {/* </ScrollBars> */}
        </Grid>

        {/* <Grid
          item
          xs={12}
          container
          direction="row"
          justifyContent="flex-end"
          style={{
            paddingRight: "38px",
          }}
          className={classes.gridMargin2}
        >
          {screen > 1 ? (
            <Button
              variant="contained"
              onClick={lastStep}
              style={{
                width: "160px",
                height: "48px",
                borderRadius: "8px",
                color: "#ffffff",
                backgroundColor: "#2d3034",
                textTransform: "none",
              }}
            >
              <FontAwesomeIcon
                icon={faArrowAltCircleLeft}
                style={{
                  width: "20px",
                  height: "20px",
                  marginRight: "10px",
                  color: "#fff",
                  backgroundColor: "#d233ff",
                  borderRadius: "50%",
                }}
              />
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 21,
                  textAlign: "left",
                  color: "#ffffff",
                  paddingRight: "10px",
                }}
              >
                Anterior
              </Typography>
            </Button>
          ) : (
            <div></div>
          )}

          {screen == 3 ? (
            <div></div>
          ) : (
            <Button
              variant="contained"
              disabled={disableButton}
              onClick={nextStep}
              style={{
                width: "160px",
                height: "48px",
                borderRadius: "8px",
                color: "#ffffff",
                backgroundColor: colorButtonNext.backColor,
                marginLeft: "30px",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 21,
                  textAlign: "left",
                  color: colorButtonNext.text,
                  textTransform: "none",
                }}
              >
                Siguiente
              </Typography>
              <FontAwesomeIcon
                icon={faArrowAltCircleRight}
                style={{
                  width: "20px",
                  height: "20px",
                  marginLeft: "10px",
                  color: colorButtonNext.arrow,
                  backgroundColor: colorButtonNext.backArrow,
                  borderRadius: "50%",
                }}
              />
            </Button>
          )}
        </Grid> */}

        {/* <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          style={{
            paddingRight: "38px",
          }}
        >
          <Grid item className={classes.gridMargin}>
            <BarProgressComponent
              progress={
                subcontractorData.porcentajePerfil.total
                  ? subcontractorData.porcentajePerfil.total
                  : 0
              }
            />
          </Grid>

          <Grid
            item
            style={{
              marginLeft: "30%",
            }}
            className={classes.gridMargin}
          >
            <Button
              variant="contained"
              disabled={disableButton}
              onClick={saveSubcontractor}
              style={{
                width: "160px",
                height: "48px",
                borderRadius: "8px",
                backgroundColor: colorButtonSave.backColor,
              }}
            >
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 21,
                  textAlign: "left",
                  color: colorButtonSave.text,
                  textTransform: "none",
                }}
              >
                Actualizar
              </Typography>
            </Button>
          </Grid>
        </Grid> */}
      </Grid>
    </div>
  );
}
