/*eslint-disable*/
import { types } from "../types/types";

const initialState = {
  screenRedux: 0,
  screenEmployees: 1,
  screenProfile: 1,
};

export const screenReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.screen:
      return {
        screenRedux: action.payload,
      };

    case types.screenEmployees:
      return {
        screenEmployees: action.payload,
      };

    case types.screenProfile:
      return {
        screenProfile: action.payload,
      };

    case types.reset:
      return initialState;

    default:
      return state;
  }
};
