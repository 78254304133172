/*eslint-disable*/
import { useEffect, useState } from "react";
import axios from "axios";
import axiosInstance from "../../../utils/axiosInstance";

export default function useAssistanceSearch(
  query,
  pageNumber,
  loadingData,
  subcontratistas,
  proyectos
) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);

  useEffect(() => {
    setRows([]);
  }, [query]);

  useEffect(() => {
    if (loadingData) {
      let Subcontratistas =
        query.Subcontratistas.length > 0
          ? query.Subcontratistas
          : subcontratistas;
      let Proyectos = query.Proyectos.length > 0 ? query.Proyectos : proyectos;

      var params = new URLSearchParams();
      params.append("page", query.page - 1);
      params.append("limit", query.pageSize);
      params.append("trabajadores_nombre_partial", query.Empleado);
      params.append("lookup", "catalogos");
      params.append("lookup", "respuestasformularios");

      params.append(
        "initialDate",
        new Date(query.intialDate.setHours(0, 0, 0, 0))
      );
      params.append(
        "finalDate",
        new Date(query.finalDate.setHours(23, 59, 59, 999))
      );

      Proyectos?.map((proyecto) => {
        params.append("proyectos_proyecto_string", proyecto.label);
      });

      Subcontratistas?.map((subcontratista) => {
        params.append("subcontratistas_empresa_string", subcontratista.label);
      });

      query?.Unidades?.map((unidad) => {
        params.append("subcontratistas_unidad_string", unidad.label);
      });

      query?.Eventos?.map((evento) => {
        params.append("catalogos_id", evento.value);
      });

      setLoading(true);
      setError(false);
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      axiosInstance()
        .get("/api/v1/rules/asistencias/filter", {
          params,
          cancelToken: source.token,
        })
        .then((response) => {
          // console.log(response.data.data.Results);
          let result = response.data.data.Results;

          result.map((asistencia) => {
            asistencia.id = asistencia._id;
          });

          setRows(result);
          setTotalRows(parseInt(response.data.data.Total));
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          if (axios.isCancel(e)) return;
          setError(true);
        });
      return () => source.cancel();
    }
  }, [query, pageNumber, loadingData]);

  return { loading, error, rows, totalRows };
}
