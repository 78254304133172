/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const useStyles = makeStyles((theme) => ({
  divMain: {
    // width: "95%",
    // height: "100px",
    // padding: theme.spacing(1),
    // backgroundColor: "red",
    // cursor: "pointer",
    // "&:hover": {
    //   background: "#3B3D40",
    //   boxShadow: "0 0px 16px 0 rgba(90, 90, 90, 0.2)",
    // },
    marginBottom: 20,
  },
  photo: {
    width: "52px",
    height: "52px",
    aspectRatio: 1,
    objectFit: "cover",
    borderRadius: "50%",
  },
  typoName: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.31,
    textAlign: "left",
    color: "#fff",
  },
  typoEnterprise: {
    fontFamily: "Roboto",
    fontSize: 14,
    lineHeight: 1.36,
    textAlign: "left",
    color: "#acacac",
    marginBottom: 3,
  },
  typoImss: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 1.33,
    textAlign: "left",
    color: "#fff",
  },
}));

export default function CardEmployeeImss(props) {
  const classes = useStyles();

  // const setPhotoProfile = (urlPhoto) => {
  //   props.setUserData({
  //     ...props.userData,
  //     foto: urlPhoto,
  //     fotoCache: urlPhoto,
  //   });
  //   props.setOpendialogSearch(!props.openDialogSearch);
  // };

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      className={classes.divMain}
      // onClick={() =>
      //   props.setOpenDialogSearchEmployee(!props.openDialogSearchEmployee)
      // }
    >
      <Grid
        item
        xs={3}
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <img src={props.urlPhoto} className={classes.photo} alt="photo" />
      </Grid>

      <Grid item xs={9} container direction="column">
        <Typography className={classes.typoName}>{props.name}</Typography>
        <Typography className={classes.typoEnterprise}>
          {props.enterprise}
        </Typography>

        <Grid container direction="row" justifyContent="flex-start">
          <FontAwesomeIcon
            icon={"fa-circle-exclamation"}
            style={{
              width: "12px",
              height: "12px",
              color: "#d60101",
              marginRight: 5,
            }}
          />
          <Typography className={classes.typoImss}>{props.imss}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
