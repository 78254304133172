/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Select, MenuItem } from "@material-ui/core";
import TopTabComponent from "./components/TopTab";
import ScrollBars from "../../common_components/ScrollBars";
import TodayScreen from "./components/TodayScreen";
import StadisticsScreen from "./components/StadisticsScreen";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    padding: theme.spacing(0, 2, 0, 2),
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 28,
    fontWeight: 600,
    textAlign: "left",
    color: "#ffffff",
  },
  subtitle: {
    fontFamily: "Roboto",
    fontSize: 18,
    lineHeight: 1.33,
    textAlign: "left",
    color: "#fff",
    marginBottom: 10,
  },
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 16,
    textAlign: "left",
    color: "#dedcdc",
  },
  select: {
    width: "212px",
    height: "40px",
    borderRadius: 8,
    backgroundColor: "#27292d",
    "& .MuiInputBase-input": {
      color: "#acacac",
    },
    "& .MuiSelect-icon": {
      color: "#acacac",
    },
  },
}));

export default function Dashboard(props) {
  const classes = useStyles();
  const [tab, setTab] = useState(1);

  const handleChange = (e) => {
    console.log(e.target.value);
    // props.setChangesFlag(true);
    // const value = e.target.value;
    // props.setEmployeeData({
    //   ...props.employeeData,
    //   [e.target.name]: value,
    // });
  };

  const renderScreen = () => {
    if (tab == 1) return <TodayScreen />;
    if (tab == 2) return <StadisticsScreen />;
  };

  useEffect(() => {}, []);

  return (
    <div className={classes.divMain}>
      <Grid container direction="column" justifyContent="flex-start">
        <Typography className={classes.title}>Dashboard</Typography>
        <Typography className={classes.subtitle}>
          Hoy, 26 de noviembre
        </Typography>

        <Typography className={classes.textFieldLetter}>Obra</Typography>
        <Select
          name="genero"
          // value={props.employeeData.genero}
          onChange={handleChange}
          defaultValue="Michelin"
          inputProps={{ "aria-label": "Without label" }}
          className={classes.select}
        >
          <MenuItem value="Michelin">&ensp;Michelin</MenuItem>
          {/* <MenuItem value="No especificado">&ensp;No especificado</MenuItem> */}
        </Select>
      </Grid>

      <Grid container direction="row">
        <Grid
          item
          xs={12}
          style={{
            marginTop: 15,
          }}
        >
          <TopTabComponent tab={tab} setTab={setTab} />
        </Grid>

        <ScrollBars
          style={{
            height: "68vh",
            marginTop: 15,
            // backgroundColor: "red",
          }}
          color="#212429"
          cursor="pointer"
        >
          {renderScreen()}
        </ScrollBars>
      </Grid>
    </div>
  );
}
