/*eslint-disable*/
import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import axiosInstance from "../../../utils/axiosInstance";

export default function useBookSearch(query, pageNumber) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [books, setBooks] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    setBooks([]);
  }, [query]);

  useEffect(() => {
    if (user) {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      let contractor = user ? user.Subcontractor[0] : null;

      var params = new URLSearchParams();
      params.append("limit", 1000);
      params.append("page", 0);
      params.append("empresa_partial", query.Empresa);
      params.append("showoculta", true);

      if (query.ProyectoObraId != "") {
        params.append("proyectos_id", query.ProyectoObraId);
      }

      setLoading(true);
      setError(false);
      let cancel;

      axiosInstance()
        .get("/api/v1/rules/subcontratistas/filter", {
          params,
          cancelToken: source.token,
        })
        .then((res) => {
          // console.log(res.data.data.Results);
          let result = res.data.data.Results;

          if (!contractor.empresaPadre) {
            result = result.filter((result) => result._id === contractor?._id);
          }

          setBooks(result);
          setLoading(false);
        })
        .catch((e) => {
          if (axios.isCancel(e)) return;
          setError(true);
        });
      return () => source.cancel();
    }
  }, [query, pageNumber, user]);

  return { loading, error, books, hasMore };
}
