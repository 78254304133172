/*eslint-disable*/
import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    [theme.breakpoints.down("md")]: {
      marginTop: 20,
    },
  },
  textInfo: {
    fontFamily: "Roboto",
    fontSize: 20,
    fontWeight: 500,
    textAlign: "left",
    color: "#ffffff",
    cursor: "pointer",
  },
  textInfoSub: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 300,
    textAlign: "left",
    color: "#bebebe",
    marginBottom: "28px",
  },
  divIconActive: {
    width: "40px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50px",
    backgroundColor: "#d233ff",
    cursor: "pointer",
  },
  divIconInactive: {
    width: "40px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50px",
    backgroundColor: "#a2a2a2",
    cursor: "pointer",
  },
}));

export default function FlowComponent(props) {
  const classes = useStyles();
  const { sections, selectedSection, changeStep } = props;

  return (
    <Grid container className={classes.divMain} style={{ marginTop: 100 }}>
      <Grid item xs={9}>
        {sections?.map((section) => (
          <Fragment>
            <Typography
              onClick={() => changeStep(section.name)}
              className={classes.textInfo}
            >
              {section.name}
            </Typography>
            <Typography className={classes.textInfoSub}>
              {/* {props.subcontractorData.porcentajePerfil.infoBasica
            ? props.subcontractorData.porcentajePerfil.infoBasica
            : "0"}{" "} */}
              de 100% completado
            </Typography>
          </Fragment>
        ))}
      </Grid>

      <Grid item xs={3} container direction="column" alignItems="center">
        {sections?.map((section, index) => (
          <Fragment>
            <Grid
              onClick={() => changeStep(section.name)}
              className={
                selectedSection == section.name
                  ? classes.divIconActive
                  : classes.divIconInactive
              }
            >
              <FontAwesomeIcon
                icon={section.icon}
                style={{
                  width: 18,
                  height: 18,
                  color: "#fff",
                }}
              />
            </Grid>
            {index + 1 != sections?.length && (
              <Grid container direction="row" justifyContent="center">
                <div
                  style={{
                    width: 1,
                    height: 40,
                    backgroundColor: "#a2a2a2",
                  }}
                />
              </Grid>
            )}
          </Fragment>
        ))}
      </Grid>
    </Grid>
  );
}
