/*eslint-disable*/
import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Box } from "@material-ui/core";
import FormLogin from "./components/form_login";
// import Logo from "../../images/absa-industrial.png";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import Splash from "../../images/rtsp_splash_1.png";
import Splash1 from "../../images/techton_splash_1.webp";
import Splash2 from "../../images/techton_splash_2.webp";
import Splash3 from "../../images/techton_splash_3.webp";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme) => ({
  divMain: {
    minHeight: "100vh",
    justifyContent: "center",
  },
  logo: {
    width: 220,
    height: 130,
    marginTop: 30,
    marginLeft: 50,
  },
  gridFormLogin: {
    backgroundColor: theme.palette.primary.light,
    padding: 100,
  },
  gridResponsive: {
    backgroundColor: theme.palette.neutral.primary,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

export default function LoginScreen(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  const images = [
    {
      id: 0,
      imgPath: Splash1,
    },
    {
      id: 1,
      imgPath: Splash2,
    },
    {
      id: 2,
      imgPath: Splash3,
    },
  ];

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <div className={classes.divMain}>
      <CssBaseline />
      <Grid container direction="row" style={{ height: "100vh" }}>
        <Grid item md={6} lg={7} xl={7} className={classes.gridResponsive}>
          {/* <img src={Logo} className={classes.logo} alt="techton logo" /> */}

          <Grid
            container
            direction="column"
            justifyContent="center"
            alignContent="center"
            style={{ width: "100%", height: "100%" }}
          >
            <img
              src={Splash}
              style={{
                maxWidth: "70%",
                maxHeight: "90%",
              }}
              alt="Splash"
            />
            {/*<div style={{ maxWidth: 500 }}>
              <AutoPlaySwipeableViews
                axis={"x"}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
                autoplay={true}
                interval={5000}
                animateTransitions={true}
              >
                {images.map((step, index) => (
                  <div key={step.id}>
                    {Math.abs(activeStep - index) <= 2 ? (
                      <Box
                        component="img"
                        sx={{
                          display: "block",
                          maxWidth: 500,
                          overflow: "hidden",
                        }}
                        src={step.imgPath}
                        alt={step.label}
                      />
                    ) : null}
                  </div>
                ))}
              </AutoPlaySwipeableViews>

               <Grid
                container
                direction="row"
                justifyContent="center"
                alignContent="center"
                style={{ marginTop: 15 }}
              >
                {images.map((img, index) => (
                  <div
                    key={index}
                    onClick={() => handleStepChange(index)}
                    style={{
                      width: 15,
                      height: 15,
                      borderRadius: "50%",
                      backgroundColor:
                        activeStep == index ? "#ac0bce" : "#434343",
                      cursor: "pointer",
                      marginRight: 8,
                    }}
                  ></div>
                ))}
              </Grid> 
            </div>*/}
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={5}
          xl={5}
          className={classes.gridFormLogin}
        >
          <FormLogin />
        </Grid>
      </Grid>
    </div>
  );
}
