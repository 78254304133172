/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import InfoBasic from "./components/infoBasic";
import InfoAdvance from "./components/infoAdvance";
import InfoHealth from "./components/infoHealth";
import InfoHealthAdvance from "./components/infoHealthAdvance";
import InfoFiles from "./components/infoFiles";
import FlowComponent from "./components/FlowComponent";
import BarProgressComponent from "./components/BarProgressComponent";
import { useDispatch, useSelector } from "react-redux";
import { UploadDocuments } from "../../actions/file";
import axios from "axios";
import axiosInstance from "../../utils/axiosInstance";
import DialogSubcontractorsList from "./components/DialogSubcontractorsList";
import DialogSitesList from "./components/DialogSitesList";
import DialogJobList from "./components/DialogJobList";
import DialogSpecialtyList from "./components/DialogSpecialtyList";
import DialogAdminSpecialtyList from "./components/DialogAdminSpecialtyList";
import DialogAddNewSpecialty from "./components/DialogAddNewSpecialty";
import DialogBackScreen from "./components/DialogBackScreen";
import { ipServer } from "../../config/vars";
import ScrollBars from "../../common_components/ScrollBars";
import DialogLoadingUploadDocuments from "./components/DialogLoadingUploadDocuments";
import DialogSiblings from "./components/DialogSiblings";
import DialogPhotoQuality from "./components/DialogPhotoQuality";
import DialogPhotoRequirements from "./components/DialogPhotoRequirements";
import DialogCatalogList from "../../common_components/Dialogs/DialogCatalogList";
import { CapitalizeString } from "../../utils/CapitalizeString";
import BackdropComponent from "./components/Backdrop";
import DialogUnsavedEmployee from "./components/DialogUnsavedEmployee";

const curpRegex =
  /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    paddingLeft: "30px",
    // backgroundColor: "blue",
  },
  divBack: {
    cursor: "pointer",
    marginBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
  back: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 500,
    textAlign: "left",
    color: "#ffffff",
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 24,
    fontWeight: 500,
    textAlign: "left",
    color: "#ffffff",
  },
  subtitle: {
    fontFamily: "Roboto",
    fontSize: 14,
    textAlign: "left",
    color: "#ffffff",
  },
  icon: {
    width: "35px",
    height: "35px",
    color: "white",
    backgroundColor: "#a2a2a2",
    borderRadius: "15px",
  },
  gridMargin: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
  gridMargin2: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 20,
    },
  },
}));

export default function RegisterEmployeeScreen(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { files } = useSelector((state) => state.file);
  const { user } = useSelector((state) => state.user);
  const [screen, setScreen] = useState(1);
  const [openDialog, setOpendialog] = useState(false);
  const [openDialogSibling, setOpenDialogSibling] = useState(false);
  const [openDialogPhoto, setOpenDialogPhoto] = useState(false);
  const [openDialogPhotoReq, setOpenDialogPhotoReq] = useState(false);
  const [infoDialog, setInfoDialog] = useState(false);
  const [openDialogSite, setOpendialogSite] = useState(false);
  const [openDialogJobs, setOpenDialogJobs] = useState(false);
  const [openDialogSpecialty, setOpenDialogSpecialty] = useState(false);
  const [openDialogSpecialtyAdmin, setOpenDialogSpecialtyAdmin] =
    useState(false);
  const [openDialogAddNewSpecialty, setOpenDialogAddNewSpecialty] =
    useState(false);
  const [openDialogUnits, setOpenDialogUnits] = useState(false);
  const [openDialogBack, setOpendialogBack] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [mandatoryFlag, setMandatoryFlag] = useState(false);
  const [changesFlag, setChangesFlag] = useState(false);
  const [changesFile, setChangesFile] = useState(0);
  const [siteFlag, setSiteFlag] = useState(false);
  const [jobsFlag, setJobsFlag] = useState(false);
  const [specialtyFlag, setSpecialtyFlag] = useState(false);
  const [unitsFlag, setUnitsFlag] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openDialogUnsavedEmployee, setOpenDialogUnsavedEmployee] =
    useState(false);
  const [subcontractorFlag, setSubcontractorFlag] = useState(false);
  const [colorButtonSave, setColorButtonSave] = useState({
    backColor: "#474A4E",
    text: "#919191",
  });
  const [colorButtonNext, setColorButtonNext] = useState({
    backColor: "#474a4e",
    text: "#919191",
    backArrow: "#676767",
    arrow: "#919191",
  });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [proyects, setProyects] = useState([]);
  const [idConfirm, setIdConfirm] = useState("");
  const [faceMacth, setFaceMatch] = useState([]);
  const [errorData, setErrorData] = useState({
    title: "",
    description: "",
  });
  const [employeeData, setEmployeeData] = useState({
    idUser: "",
    foto: "",
    fotoCache: "",
    nombre: "",
    apellidos: "",
    subcontratista: "",
    subcontratistaId: "",
    oldSubcontratista: "",
    oldSubcontratistaId: "",
    proyectoObra: "",
    proyectoObraId: "",
    oldProyectoObra: "",
    oldProyectoObraId: "",
    unidad: "",
    genero: "",
    fechaNacimiento: "",
    fechaNacimientoString: "",
    puesto: "",
    curp: "",
    fechaIngreso: "",
    fechaIngresoString: "",
    lugarNacimiento: "",
    escribir: "",
    leer: "",
    domicilio: "",
    gradoEstudios: "",
    contactoEmergencia: "",
    telefono1: "",
    telefono2: "",
    imss: "",
    sangre: "",
    fuma: "",
    bebe: "",
    drogas: "",
    actividadFisica: "",
    peso: "",
    altura: "",
    enfermedadCronica: "",
    tipoEnfermedadCronica: "",
    tratamientoMedico: "",
    tipoTratamientoMedico: "",
    medicamento: "",
    tipoMedicamento: "",
    lentes: "",
    tipoLentes: "",
    alergias: "",
    tipoAlergia: "",
    porcentajePerfil: [],
    ObrasTemp: [],
    puestos: [],
    estatusImss: {},
    numeroEmpleado: "",
  });
  const [userData, setUserData] = useState({
    idUser: "",
    fotoReq: false,
  });
  const [job, setJob] = useState({
    id: "",
    type: "trabajador_puestos",
    name: "",
    description: "",
    extra: "",
  });
  const [specialty, setSpecialty] = useState({
    id: "",
    type: "trabajador_Especialidades",
    name: "",
    description: "",
    extra: "",
  });
  const [unit, setUnit] = useState({
    id: "",
    type: "unidad",
    name: "",
    description: "",
  });

  const backStep = () => {
    if (
      (changesFlag && mandatoryFlag) ||
      files.some((file) => file.process != "db" && file.url === null)
    ) {
      setOpendialogBack(!openDialogBack);
    } else {
      if (localStorage.getItem("idEmployee") == "")
        history.push(`/dashboard/empleados`);
      else
        history.push(
          `/dashboard/empleados/${localStorage.getItem("idEmployee")}`
        );
    }
    setScreen(1);
  };

  const nextStep = () => {
    if (screen == 1 && mandatoryFlag == false) {
      enqueueSnackbar("Favor de llenar campos obligatorios", {
        variant: "error",
      });
      return;
    }
    setScreen(screen + 1);
  };

  const lastStep = () => {
    setScreen(screen - 1);
  };

  const renderRegisterScreen = () => {
    if (screen == 1)
      return (
        <InfoBasic
          employeeData={employeeData}
          userData={userData}
          setEmployeeData={setEmployeeData}
          proyects={proyects}
          setMandatoryFlag={setMandatoryFlag}
          setChangesFlag={setChangesFlag}
          openDialog={openDialog}
          setOpendialog={setOpendialog}
          openDialogSite={openDialogSite}
          setOpendialogSite={setOpendialogSite}
          openDialogJobs={openDialogJobs}
          setOpenDialogJobs={setOpenDialogJobs}
          openDialogSpecialty={openDialogSpecialty}
          setOpenDialogSpecialty={setOpenDialogSpecialty}
          openDialogPhotoReq={openDialogPhotoReq}
          setOpenDialogPhotoReq={setOpenDialogPhotoReq}
          setInfoDialog={setInfoDialog}
          openDialogUnits={openDialogUnits}
          setOpenDialogUnits={setOpenDialogUnits}
        />
      );
    if (screen == 2)
      return (
        <InfoAdvance
          employeeData={employeeData}
          setEmployeeData={setEmployeeData}
          setChangesFlag={setChangesFlag}
        />
      );
    if (screen == 3)
      return (
        <InfoHealth
          employeeData={employeeData}
          setEmployeeData={setEmployeeData}
          setChangesFlag={setChangesFlag}
        />
      );
    if (screen == 4)
      return (
        <InfoHealthAdvance
          employeeData={employeeData}
          setEmployeeData={setEmployeeData}
          setChangesFlag={setChangesFlag}
        />
      );
    if (screen == 5) return <InfoFiles />;
  };

  const checkCurpFormat = (curp) => {
    // console.log(curpRegex.test(curp));
    if (!curpRegex.test(curp)) {
      enqueueSnackbar("Favor de ingresar un CURP valido", {
        variant: "error",
      });
      return false;
    }
    return true;
  };

  const checkImssFormat = (imss) => {
    // console.log(curpRegex.test(imss));
    if (imss.length < 11) {
      enqueueSnackbar("El numero de seguro social debe de tener 11 digitos", {
        variant: "error",
      });
      return false;
    }
    return true;
  };

  const saveEmployee = async () => {
    if (screen == 1 && mandatoryFlag == false) {
      enqueueSnackbar("Favor de llenar campos obligatorios", {
        variant: "error",
      });
      return;
    }

    if (
      user?.EnterpriseData?.Db != "Dicoma680603" &&
      !checkCurpFormat(employeeData.curp)
    )
      return;
    if (
      user?.EnterpriseData?.Db != "Dicoma680603" &&
      !checkImssFormat(employeeData.imss)
    )
      return;

    setDisableButton(true);
    setOpenBackdrop(true);
    var _obj = {};
    if (employeeData.idUser == "") {
      _obj = await registerEmployee();
      if (_obj.flag == false) return;
      // await assignEmployeeSubcontractor(_obj.id);
      // await assignEmployeeProyect(_obj.id);
    } else {
      if (subcontractorFlag) {
        if (!(await updateSubcontractor(employeeData.idUser))) {
          enqueueSnackbar("Error al actualizar subcontratista", {
            variant: "error",
          });
          return;
        }
      }

      if (siteFlag) {
        if (!(await updateProyect(employeeData.idUser))) {
          enqueueSnackbar("Error al actualizar obra", {
            variant: "error",
          });
          return;
        }
      }

      if (!(await updateEmployee(employeeData.idUser))) return;
      await assignEmployeeSubcontractor(employeeData.idUser);
      await assignEmployeeProyect(employeeData.idUser);
    }

    const documents = files.some(
      (file) => file.process != "db" && file.url === null
    );
    if (documents) {
      dispatch(
        UploadDocuments(
          employeeData.idUser != "" ? employeeData.idUser : _obj.id,
          files,
          "trabajadores",
          setChangesFile
        )
      );
    }

    await updateUser();
    setSubcontractorFlag(false);
    setSiteFlag(false);
    setChangesFlag(false);

    setTimeout(() => {
      setDisableButton(false);
    }, 2000);

    return documents;
  };

  const registerEmployee = async () => {
    var _obj = {
      id: "",
      flag: false,
    };
    var subcontratistas = [];
    subcontratistas.push(employeeData.subcontratistaId);
    var proyectos = [];
    proyectos.push(employeeData.proyectoObraId);

    let formData = new FormData();
    formData.append("file", employeeData.foto);
    formData.append(
      "data",
      JSON.stringify({
        nombre: CapitalizeString(employeeData.nombre),
        apellidos: CapitalizeString(employeeData.apellidos),
        genero: employeeData.genero,
        imss: employeeData.imss,
        curp: employeeData.curp,
        unidad: employeeData.unidad,
        fechaNacimiento: employeeData.fechaNacimiento,
        fechaNacimientoString: employeeData.fechaNacimientoString,
        puesto: employeeData.puesto,
        fechaIngreso: employeeData.fechaIngreso,
        fechaIngresoString: employeeData.fechaIngresoString,
        lugarNacimiento: CapitalizeString(employeeData.lugarNacimiento),
        escribir: employeeData.escribir,
        leer: employeeData.leer,
        domicilio: CapitalizeString(employeeData.domicilio),
        gradoEstudios: employeeData.gradoEstudios,
        numeroEmpleado: employeeData.numeroEmpleado,
        // telefono: "",
        // ine: "",
        especialidad: employeeData.especialidad,
        emergencias: {
          contactoEmergencia: CapitalizeString(employeeData.contactoEmergencia),
          telefono1: employeeData.telefono1,
          telefono2: employeeData.telefono2,
        },
        cuestionarioSalud: {
          tipoSangre: employeeData.sangre,
          fuma: employeeData.fuma,
          bebeAlcol: employeeData.bebe,
          haUsadoDrogas: employeeData.drogas,
          actividadFisica: employeeData.actividadFisica,
          peso: employeeData.peso,
          altura: employeeData.altura,
          enfermedadCronica: employeeData.enfermedadCronica,
          tipoEnfermedadCronica: CapitalizeString(
            employeeData.tipoEnfermedadCronica
          ),
          tratamientoMedico: employeeData.tratamientoMedico,
          tipoTratamientoMedico: CapitalizeString(
            employeeData.tipoTratamientoMedico
          ),
          medicamento: employeeData.medicamento,
          tipoMedicamento: CapitalizeString(employeeData.tipoMedicamento),
          lentes: employeeData.lentes,
          tipoLentes: employeeData.tipoLentes,
          alergias: employeeData.alergias,
          tipoAlergia: CapitalizeString(employeeData.tipoAlergia),
        },
        _Assign: [
          { proyectos: proyectos },
          { subcontratistas: subcontratistas },
        ],
        estatusImss: {
          estatusAnterior: null,
          estatusActual: "IMSS Sin Estatus",
          ultimaLectura_datetime: new Date(),
        },
        // horario: "",
        // examenesPracticados: [
        //   {
        //     date: "",
        //     antidoping: "",
        //     paso: true,
        //     notas: "",
        //   },
        // ],
        // expedientes: [],
        // notas: "",
      })
    );

    await axios
      .post(`${ipServer}/api/v1/rules/trabajadores`, formData, {
        headers: {
          Authorization: localStorage.getItem("JWT"),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        // console.log("register", response.data);
        if (
          response.data.message ==
          "Rostro ya existe en reconocimiento facial, usar idconfirm to continue"
        ) {
          setIdConfirm(response.data.data.idconfirm);
          setFaceMatch(response.data.data.faceMatches);
          setOpenBackdrop(false);
          setOpenDialogPhoto(!openDialogPhoto);
        } else {
          _obj.id = response.data.extra._id;
          _obj.flag = true;
          setEmployeeData({
            ...employeeData,
            idUser: response.data.extra._id,
            foto: response.data.extra.faceIndex.imageIndex,
            porcentajePerfil: response.data.extra.percentage,
          });
          setOpenBackdrop(false);
          enqueueSnackbar("Empleado registrado correctamente", {
            variant: "success",
          });
          console.log("Empleado registrado correctamente!");
        }
      })
      .catch((e) => {
        _obj.flag = false;
        setDisableButton(false);
        setOpenBackdrop(false);
        if (e.response?.data) {
          console.log(e.response.data);
          if (
            e.response.data.message ==
            "Could not upload the file. TypeError: Cannot read property '0' of undefined"
          ) {
            enqueueSnackbar("Favor de seleccionar foto de perfil", {
              variant: "error",
            });
          } else if (e.response.data.message == "rekognition error") {
            enqueueSnackbar("Favor de subir una foto de persona", {
              variant: "error",
            });
          } else if (
            e.response.data.message == "Validacion de datos duplicados"
          ) {
            enqueueSnackbar(
              `Trabajador con ` +
                e.response.data.data[0].name +
                ` registrado previamente`,
              {
                variant: "error",
              }
            );
          } else {
            enqueueSnackbar("Error al registrar trabajador", {
              variant: "error",
            });
            enqueueSnackbar(e.response.data.message, {
              variant: "error",
            });
          }

          if (e.response.data.message == "Numero de empleado duplicado") {
            enqueueSnackbar(e.response.data.message, {
              variant: "error",
            });
          }
        } else {
          console.log(e.response);
          setErrorData({
            title: "Error al registrar trabajador",
            description: "No se pudo guardar el trabajador",
          });

          setOpenDialogUnsavedEmployee(true);
        }
      });

    return _obj;
  };

  const updateEmployee = async () => {
    // console.log(typeof employeeData.foto);
    // console.log(employeeData);
    if (typeof employeeData.foto === "string") {
      let body = {
        nombre: CapitalizeString(employeeData.nombre),
        apellidos: CapitalizeString(employeeData.apellidos),
        genero: employeeData.genero,
        imss: employeeData.imss,
        curp: employeeData.curp,
        unidad: employeeData.unidad,
        fechaNacimiento: employeeData.fechaNacimiento,
        fechaNacimientoString: employeeData.fechaNacimientoString,
        lugarNacimiento: CapitalizeString(employeeData.lugarNacimiento),
        escribir: employeeData.escribir,
        leer: employeeData.leer,
        puesto: employeeData.puesto,
        fechaIngreso: employeeData.fechaIngreso,
        fechaIngresoString: employeeData.fechaIngresoString,
        domicilio: CapitalizeString(employeeData.domicilio),
        gradoEstudios: employeeData.gradoEstudios,
        numeroEmpleado: employeeData.numeroEmpleado,
        especialidad: employeeData.especialidad,
        emergencias: {
          contactoEmergencia: CapitalizeString(employeeData.contactoEmergencia),
          telefono1: employeeData.telefono1,
          telefono2: employeeData.telefono2,
        },
        cuestionarioSalud: {
          tipoSangre: employeeData.sangre,
          fuma: employeeData.fuma,
          bebeAlcol: employeeData.bebe,
          haUsadoDrogas: employeeData.drogas,
          actividadFisica: employeeData.actividadFisica,
          peso: employeeData.peso,
          altura: employeeData.altura,
          enfermedadCronica: employeeData.enfermedadCronica,
          tipoEnfermedadCronica: CapitalizeString(
            employeeData.tipoEnfermedadCronica
          ),
          tratamientoMedico: employeeData.tratamientoMedico,
          tipoTratamientoMedico: CapitalizeString(
            employeeData.tipoTratamientoMedico
          ),
          medicamento: employeeData.medicamento,
          tipoMedicamento: CapitalizeString(employeeData.tipoMedicamento),
          lentes: employeeData.lentes,
          tipoLentes: employeeData.tipoLentes,
          alergias: employeeData.alergias,
          tipoAlergia: CapitalizeString(employeeData.tipoAlergia),
        },
      };
      return await axios
        .patch(
          `${ipServer}/api/v1/rules/trabajadores/` + employeeData.idUser,
          body,
          {
            headers: {
              Authorization: localStorage.getItem("JWT"),
            },
          }
        )
        .then((response) => {
          // console.log("update", response.data);
          setEmployeeData({
            ...employeeData,
            idUser: response.data.data._id,
            porcentajePerfil: response.data.data.percentage,
          });
          setOpenBackdrop(false);
          enqueueSnackbar("Empleado actualizado correctamente", {
            variant: "success",
          });
          console.log("Empleado actualizado correctamente!");
          return true;
        })
        .catch((e) => {
          setDisableButton(false);
          setOpenBackdrop(false);
          if (e.response.data) {
            console.log(e.response.data.message);
            if (
              e.response.data.message ==
              "No se pudo guardar el trabajador en la base de datos, la imss o el rfc ya existen"
            ) {
              enqueueSnackbar(
                "Trabajdor con Curp ó Imss registrado previamente",
                {
                  variant: "error",
                }
              );
            } else if (
              e.response.data.message == "Sin permiso para modificar"
            ) {
              enqueueSnackbar(
                "Tu perfil no tiene permiso para actualizar empleados",
                {
                  variant: "error",
                }
              );
            } else if (e.response.data.workerStatus) {
              enqueueSnackbar("Trabajador " + e.response.data.message, {
                variant: "error",
              });
            } else {
              enqueueSnackbar("Error al registrar trabajador", {
                variant: "error",
              });
              enqueueSnackbar(e.response.data.message, {
                variant: "error",
              });
            }

            if (e.response.data.message == "Numero de empleado duplicado") {
              enqueueSnackbar(e.response.data.message, {
                variant: "error",
              });
            }
          } else {
            console.log(e.response);
            setErrorData({
              title: "Error al actualizar empleado",
              description:
                "No se pudo guardar el trabajador en la base de datos",
            });
            setOpenDialogUnsavedEmployee(true);
          }
          return false;
        });
    } else {
      let formData = new FormData();
      formData.append("file", employeeData.foto);
      formData.append(
        "data",
        JSON.stringify({
          nombre: CapitalizeString(employeeData.nombre),
          apellidos: CapitalizeString(employeeData.apellidos),
          genero: employeeData.genero,
          imss: employeeData.imss,
          curp: employeeData.curp,
          unidad: employeeData.unidad,
          fechaNacimiento: employeeData.fechaNacimiento,
          fechaNacimientoString: employeeData.fechaNacimientoString,
          puesto: employeeData.puesto,
          fechaIngreso: employeeData.fechaIngreso,
          fechaIngresoString: employeeData.fechaIngresoString,
          lugarNacimiento: CapitalizeString(employeeData.lugarNacimiento),
          escribir: employeeData.escribir,
          leer: employeeData.leer,
          domicilio: CapitalizeString(employeeData.domicilio),
          gradoEstudios: employeeData.gradoEstudios,
          numeroEmpleado: employeeData.numeroEmpleado,
          especialidad: employeeData.especialidad,
          emergencias: {
            contactoEmergencia: CapitalizeString(
              employeeData.contactoEmergencia
            ),
            telefono1: employeeData.telefono1,
            telefono2: employeeData.telefono2,
          },
          cuestionarioSalud: {
            tipoSangre: employeeData.sangre,
            fuma: employeeData.fuma,
            bebeAlcol: employeeData.bebe,
            haUsadoDrogas: employeeData.drogas,
            actividadFisica: employeeData.actividadFisica,
            peso: employeeData.peso,
            altura: employeeData.altura,
            enfermedadCronica: employeeData.enfermedadCronica,
            tipoEnfermedadCronica: CapitalizeString(
              employeeData.tipoEnfermedadCronica
            ),
            tratamientoMedico: employeeData.tratamientoMedico,
            tipoTratamientoMedico: CapitalizeString(
              employeeData.tipoTratamientoMedico
            ),
            medicamento: employeeData.medicamento,
            tipoMedicamento: CapitalizeString(employeeData.tipoMedicamento),
            lentes: employeeData.lentes,
            tipoLentes: employeeData.tipoLentes,
            alergias: employeeData.alergias,
            tipoAlergia: CapitalizeString(employeeData.tipoAlergia),
          },
        })
      );

      // for (var value of formData.values()) {
      //   console.log(value);
      // }

      return await axios
        .put(
          `${ipServer}/api/v1/rules/trabajadores/` + employeeData.idUser,
          formData,
          {
            headers: {
              Authorization: localStorage.getItem("JWT"),
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          // console.log("update", response.data);
          if (
            response.data.message ==
            "Rostro ya existe en reconocimiento facial, usar idconfirm to continue"
          ) {
            setIdConfirm(response.data.data.idconfirm);
            setFaceMatch(response.data.data.faceMatches);
            setOpenBackdrop(false);
            setOpenDialogPhoto(!openDialogPhoto);
          } else {
            setEmployeeData({
              ...employeeData,
              idUser: response.data.extra._id,
              porcentajePerfil: response.data.extra.percentage,
            });
            setOpenBackdrop(false);
            enqueueSnackbar("Empleado actualizado correctamente", {
              variant: "success",
            });
            console.log("Empleado actualizado correctamente!");
            return true;
          }
        })
        .catch((e) => {
          setDisableButton(false);
          setOpenBackdrop(false);
          if (e.response.data) {
            console.log(e.response.data);
            if (
              e.response.data.message ==
              "Rostro ya existe en reconocimiento facial"
            ) {
              enqueueSnackbar(
                "Foto de perfil del trabajador ya se registro previamente",
                {
                  variant: "error",
                }
              );
            }
            // else if (
            //   e.response.data.message ==
            //   "No se pudo guardar el trabajador en la base de datos, la imss o el rfc ya existen"
            // ) {
            //   enqueueSnackbar(
            //     "Trabajdor con Curp ó Imss registrado previamente",
            //     {
            //       variant: "error",
            //     }
            //   );
            // }
            else if (e.response.data.message == "rekognition error") {
              enqueueSnackbar("Favor de subir una foto de persona", {
                variant: "error",
              });
            } else if (
              e.response.data.message == "Sin permiso para modificar"
            ) {
              enqueueSnackbar(
                "Tu perfil no tiene permiso para actualizar empleados",
                {
                  variant: "error",
                }
              );
            } else if (e.response.data.workerStatus) {
              enqueueSnackbar("Trabajador " + e.response.data.message, {
                variant: "error",
              });
            } else {
              enqueueSnackbar("Error al registrar trabajador", {
                variant: "error",
              });
              enqueueSnackbar(e.response.data.message, {
                variant: "error",
              });
            }
          } else {
            console.log(e.response);
            setErrorData({
              title: "Error al actualizar empleado",
              description:
                "No se pudo guardar el trabajador en la base de datos",
            });
            setOpenDialogUnsavedEmployee(true);
          }
          return false;
        });
    }
  };

  const updateUser = async () => {
    let body = {
      PhotoReq: false,
    };

    return await axiosInstance()
      .patch(`/api/v1/security/user/profile/`, body)
      .then((response) => {
        // console.log(response.data);
        // enqueueSnackbar("Usuario actualizado correctamente", {
        //   variant: "success",
        // });
        return true;
      })
      .catch((e) => {
        console.log(e.response);
        return false;
      });
  };

  const assignEmployeeSubcontractor = async (idUser) => {
    var trabajadores = [];
    var subcontratistas = [];

    trabajadores.push(idUser);
    subcontratistas.push(employeeData.subcontratistaId);

    return await axios
      .post(
        `${ipServer}/api/v1/rules/assing`,
        {
          trabajadores: trabajadores,
          subcontratistas: subcontratistas,
        },
        {
          headers: {
            Authorization: localStorage.getItem("JWT"),
          },
        }
      )
      .then((response) => {
        // console.log(response.data);
        // enqueueSnackbar("Usuario registrado correctamente", {
        //   variant: "success",
        // });
        console.log("Empleado asignado a un subcontratista");
        return true;
      })
      .catch((e) => {
        console.log(e);
        return false;
        // if (
        //   e.response.data.message ==
        //   "Could not upload the file. TypeError: Cannot read property '0' of undefined"
        // ) {
        //   console.log(e.response.data);
        //   enqueueSnackbar("Favor de seleccionar foto de perfil", {
        //     variant: "error",
        //   });
        // }
      });
  };

  const updateSubcontractor = async (idUser) => {
    var trabajadores = [];
    var subcontratistas = [];

    trabajadores.push(idUser);
    subcontratistas.push(employeeData.oldSubcontratistaId);

    return await axios
      .delete(`${ipServer}/api/v1/rules/assing`, {
        headers: {
          Authorization: localStorage.getItem("JWT"),
        },
        data: {
          trabajadores: trabajadores,
          subcontratistas: subcontratistas,
        },
      })
      .then((response) => {
        // console.log("delete assign", response.data);
        console.log("subcontratista desasignado correctamente");
        //validar respuesta
        return true;
      })
      .catch((e) => {
        console.log(e.response);
        return false;
      });
  };

  const assignEmployeeProyect = async (idUser) => {
    var trabajadores = [];
    var proyectos = [];

    trabajadores.push(idUser);
    proyectos.push(employeeData.proyectoObraId);

    return await axios
      .post(
        `${ipServer}/api/v1/rules/assing`,
        {
          trabajadores: trabajadores,
          proyectos: proyectos,
        },
        {
          headers: {
            Authorization: localStorage.getItem("JWT"),
          },
        }
      )
      .then((response) => {
        // console.log(response.data);
        // enqueueSnackbar("Usuario registrado correctamente", {
        //   variant: "success",
        // });
        console.log("Empleado asignado a un proyecto");
        return true;
      })
      .catch((e) => {
        console.log(e);
        return false;
        // if (
        //   e.response.data.message ==
        //   "Could not upload the file. TypeError: Cannot read property '0' of undefined"
        // ) {
        //   console.log(e.response.data);
        //   enqueueSnackbar("Favor de seleccionar foto de perfil", {
        //     variant: "error",
        //   });
        // }
      });
  };

  const updateProyect = async (idUser) => {
    var trabajadores = [];
    var proyectos = [];

    trabajadores.push(idUser);
    proyectos.push(employeeData.oldProyectoObraId);

    return await axios
      .delete(`${ipServer}/api/v1/rules/assing`, {
        headers: {
          Authorization: localStorage.getItem("JWT"),
        },
        data: {
          trabajadores: trabajadores,
          proyectos: proyectos,
        },
      })
      .then((response) => {
        // console.log("delete assign", response.data);
        console.log("obra desasignada correctamente");
        //validar respuesta
        return true;
      })
      .catch((e) => {
        console.log(e.response);
        return false;
      });
  };

  const getProyectsList = (idSubcontratista) => {
    axios
      .get(
        `${ipServer}/api/v1/rules/subcontratistas/${idSubcontratista}/proyectos`,
        {
          headers: { Authorization: localStorage.getItem("JWT") },
        }
      )
      .then((response) => {
        // console.log(response.data.data);
        const proyects = response.data.data.map((object) => {
          return { id: object._id, proyecto: object.proyecto };
        });
        setProyects(proyects);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    // console.log(employeeData);
    if (employeeData.subcontratistaId != "")
      getProyectsList(employeeData.subcontratistaId);
  }, [employeeData.subcontratistaId]);

  useEffect(() => {
    if (mandatoryFlag == true) {
      setColorButtonSave({
        ...colorButtonSave,
        backColor: "#ac0bce",
        text: "#fff",
      });
      setColorButtonNext({
        ...colorButtonNext,
        backColor: "#2d3034",
        text: "#fff",
        backArrow: "#d233ff",
        arrow: "#fff",
      });
    } else {
      setColorButtonSave({
        ...colorButtonSave,
        backColor: "#474A4E",
        text: "#919191",
      });
      setColorButtonNext({
        ...colorButtonNext,
        backColor: "#474a4e",
        text: "#919191",
        backArrow: "#676767",
        arrow: "#919191",
      });
    }
  }, [mandatoryFlag]);

  useEffect(() => {
    if (screen != 1) {
      setDisableButton(false);
      setMandatoryFlag(true);
    }

    if (localStorage.getItem("idEmployee")) {
      axiosInstance()
        .get(`/api/v1/rules/trabajadores/` + localStorage.getItem("idEmployee"))
        .then((response) => {
          // console.log("load", response.data.data[0]);
          setEmployeeData({
            ...employeeData,
            idUser: response.data.data[0]?._id ?? employeeData.idUser,
            foto: response.data.data[0]?.foto ?? employeeData.foto,
            fotoCache: response.data.data[0]?.foto ?? employeeData.foto,
            nombre: response.data.data[0]?.nombre ?? employeeData.nombre,
            apellidos:
              response.data.data[0]?.apellidos ?? employeeData.apellidos,
            numeroEmpleado:
              response.data.data[0]?.numeroEmpleado ??
              employeeData.numeroEmpleado,
            especialidad:
              response.data.data[0]?.especialidad ?? employeeData.especialidad,
            subcontratista:
              response.data.data[0]?.subcontratistas[0]?.empresa ??
              employeeData.subcontratista,
            subcontratistaId:
              response.data.data[0]?.subcontratistas[0]?._id ??
              employeeData.subcontratistaId,
            proyectoObra:
              response.data.data[0]?.proyectos[0]?.proyecto ??
              employeeData.proyecto,
            proyectoObraId:
              response.data.data[0]?.proyectos[0]?._id ??
              employeeData.proyectoObraId,
            unidad: response.data.data[0]?.unidad ?? employeeData.unidad,
            genero: response.data.data[0].genero ?? employeeData.genero,
            domicilioObra:
              response.data.data[0]?.proyectos[0]?.domicilio ??
              employeeData.domicilioObra,
            fechaNacimiento:
              response.data.data[0]?.fechaNacimiento ??
              employeeData.fechaNacimiento,
            fechaNacimientoString:
              response.data.data[0]?.fechaNacimientoString ??
              employeeData.fechaNacimientoString,
            puesto: response.data.data[0]?.puesto ?? employeeData.puesto,
            curp: response.data.data[0]?.curp ?? employeeData.curp,
            fechaIngreso:
              response.data.data[0]?.fechaIngreso ?? employeeData.fechaIngreso,
            fechaIngresoString:
              response.data.data[0]?.fechaIngresoString ??
              employeeData.fechaIngresoString,
            lugarNacimiento:
              response.data.data[0]?.lugarNacimiento ??
              employeeData.lugarNacimiento,
            escribir: response.data.data[0]?.escribir ?? employeeData.escribir,
            leer: response.data.data[0]?.leer ?? employeeData.leer,
            domicilio:
              response.data.data[0]?.domicilio ?? employeeData.domicilio,
            gradoEstudios:
              response.data.data[0]?.gradoEstudios ??
              employeeData.gradoEstudios,
            contactoEmergencia:
              response.data.data[0]?.emergencias?.contactoEmergencia ??
              employeeData.contactoEmergencia,
            telefono1:
              response.data.data[0]?.emergencias?.telefono1 ??
              employeeData.telefono1,
            telefono2:
              response.data.data[0]?.emergencias?.telefono2 ??
              employeeData.telefono2,
            imss: response.data.data[0]?.imss ?? employeeData.imss,
            sangre:
              response.data.data[0]?.cuestionarioSalud?.tipoSangre ??
              employeeData.sangre,
            fuma:
              response.data.data[0]?.cuestionarioSalud?.fuma ??
              employeeData.fuma,
            bebe:
              response.data.data[0]?.cuestionarioSalud?.bebeAlcol ??
              employeeData.bebe,
            drogas:
              response.data.data[0]?.cuestionarioSalud?.haUsadoDrogas ??
              employeeData.drogas,
            actividadFisica:
              response.data.data[0]?.cuestionarioSalud?.actividadFisica ??
              employeeData.actividadFisica,
            peso:
              response.data.data[0]?.cuestionarioSalud?.peso ??
              employeeData.peso,
            altura:
              response.data.data[0]?.cuestionarioSalud?.altura ??
              employeeData.altura,
            enfermedadCronica:
              response.data.data[0]?.cuestionarioSalud?.enfermedadCronica ??
              employeeData.enfermedadCronica,
            tipoEnfermedadCronica:
              response.data.data[0]?.cuestionarioSalud?.tipoEnfermedadCronica ??
              employeeData.tipoEnfermedadCronica,
            tratamientoMedico:
              response.data.data[0]?.cuestionarioSalud?.tratamientoMedico ??
              employeeData.tratamientoMedico,
            tipoTratamientoMedico:
              response.data.data[0]?.cuestionarioSalud?.tipoTratamientoMedico ??
              employeeData.tipoTratamientoMedico,
            medicamento:
              response.data.data[0]?.cuestionarioSalud?.medicamento ??
              employeeData.medicamento,
            tipoMedicamento:
              response.data.data[0]?.cuestionarioSalud?.tipoMedicamento ??
              employeeData.tipoMedicamento,
            lentes:
              response.data.data[0]?.cuestionarioSalud?.lentes ??
              employeeData.lentes,
            tipoLentes:
              response.data.data[0]?.cuestionarioSalud?.tipoLentes ??
              employeeData.tipoLentes,
            alergias:
              response.data.data[0]?.cuestionarioSalud?.alergias ??
              employeeData.alergias,
            tipoAlergia:
              response.data.data[0]?.cuestionarioSalud?.tipoAlergia ??
              employeeData.tipoAlergia,
            documentos:
              response.data.data[0]?.documentos ?? employeeData.documentos,
            porcentajePerfil:
              response.data.data[0]?.percentage ??
              employeeData.porcentajePerfil,
            ObrasTemp:
              response.data.data[0]?.subcontratistas[0]?.proyectos_id ??
              employeeData.ObrasTemp,
            estatusImss:
              response.data.data[0]?.estatusImss ?? employeeData.estatusImss,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }

    axiosInstance()
      .get(`/api/v1/security/user/profile/`)
      .then((response) => {
        // console.log(response.data.data);
        setUserData({
          ...userData,
          // idUser: response.data.data._id,
          fotoReq: response.data.data.PhotoReq
            ? response.data.data.PhotoReq
            : userData.fotoReq,
        });
      })
      .catch((e) => {
        console.log(e.response);
      });
  }, []);

  useEffect(() => {
    // console.log(employeeData);
    if (!siteFlag) {
      setEmployeeData({
        ...employeeData,
        oldProyectoObra: employeeData.proyectoObra,
        oldProyectoObraId: employeeData.proyectoObraId,
      });
    }
  }, [employeeData.proyectoObra]);

  useEffect(() => {
    // console.log(employeeData);
    if (!subcontractorFlag) {
      setEmployeeData({
        ...employeeData,
        oldSubcontratista: employeeData.subcontratista,
        oldSubcontratistaId: employeeData.subcontratistaId,
        oldProyectoObra: employeeData.proyectoObra,
        oldProyectoObraId: employeeData.proyectoObraId,
      });
      /* se comenta la siguiente linea para:
        corregir error de desasignar 
        obra cuando quieres editar un empleado con una 
        fotografia utilizada anteriormente*/
      // setSiteFlag(true);
    }
  }, [employeeData.subcontratista]);

  useEffect(() => {
    if (changesFile != 0) {
      setEmployeeData({
        ...employeeData,
        porcentajePerfil: changesFile,
      });
    }
  }, [changesFile]);

  return (
    <div className={classes.divMain}>
      <Grid container>
        <Grid
          item
          xs={6}
          sm={4}
          md={2}
          lg={2}
          xl={2}
          container
          direction="row"
          alignItems="center"
          onClick={backStep}
          className={classes.divBack}
        >
          <Grid item>
            <FontAwesomeIcon
              icon={faArrowAltCircleLeft}
              style={{
                width: "20px",
                height: "20px",
                marginRight: "10px",
                color: "#d6d6d6",
              }}
            />
          </Grid>
          <Grid item>
            <Typography className={classes.back}>Regresar</Typography>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={8} md={10} lg={10} xl={10}></Grid>

        <Grid item xs={12}>
          <Typography className={classes.title}>
            {localStorage.getItem("idEmployee") == "" ? "Registrar" : "Editar"}{" "}
            Empleado
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.subtitle}>
            {localStorage.getItem("idEmployee") == ""
              ? "Registra"
              : "Actualiza"}{" "}
            los empleados que pertenezcan a tu empresa
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          <FlowComponent
            screen={screen}
            setScreen={setScreen}
            employeeData={employeeData}
            mandatoryFlag={mandatoryFlag}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
          <ScrollBars style={{ width: "100%", height: "60vh" }}>
            {renderRegisterScreen()}
          </ScrollBars>
        </Grid>

        <Grid
          item
          xs={12}
          container
          direction="row"
          justifyContent="flex-end"
          style={{
            paddingRight: "38px",
          }}
          className={classes.gridMargin2}
        >
          {screen > 1 ? (
            <Button
              variant="contained"
              onClick={lastStep}
              style={{
                width: "160px",
                height: "48px",
                borderRadius: "8px",
                color: "#ffffff",
                backgroundColor: "#2d3034",
                textTransform: "none",
              }}
            >
              <FontAwesomeIcon
                icon={faArrowAltCircleLeft}
                style={{
                  width: "20px",
                  height: "20px",
                  marginRight: "10px",
                  color: "#fff",
                  backgroundColor: "#d233ff",
                  borderRadius: "50%",
                }}
              />
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 21,
                  textAlign: "left",
                  color: "#ffffff",
                  paddingRight: "10px",
                }}
              >
                Anterior
              </Typography>
            </Button>
          ) : (
            <div></div>
          )}

          {screen == 5 ? (
            <div></div>
          ) : (
            <Button
              variant="contained"
              disabled={disableButton}
              onClick={nextStep}
              style={{
                width: "160px",
                height: "48px",
                borderRadius: "8px",
                color: "#ffffff",
                backgroundColor: colorButtonNext.backColor,
                marginLeft: "30px",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 21,
                  textAlign: "left",
                  color: colorButtonNext.text,
                  textTransform: "none",
                }}
              >
                Siguiente
              </Typography>
              <FontAwesomeIcon
                icon={faArrowAltCircleRight}
                style={{
                  width: "20px",
                  height: "20px",
                  marginLeft: "10px",
                  color: colorButtonNext.arrow,
                  backgroundColor: colorButtonNext.backArrow,
                  borderRadius: "50%",
                }}
              />
            </Button>
          )}
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          style={{
            paddingRight: "38px",
          }}
        >
          <Grid item className={classes.gridMargin}>
            <BarProgressComponent
              progress={
                employeeData.porcentajePerfil.total
                  ? employeeData.porcentajePerfil.total
                  : 0
              }
            />
          </Grid>

          <Grid
            item
            style={{
              marginLeft: "30%",
            }}
            className={classes.gridMargin}
          >
            <Button
              variant="contained"
              disabled={disableButton}
              onClick={saveEmployee}
              style={{
                width: "160px",
                height: "48px",
                borderRadius: "8px",
                backgroundColor: colorButtonSave.backColor,
              }}
            >
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 21,
                  textAlign: "left",
                  color: colorButtonSave.text,
                  textTransform: "none",
                }}
              >
                {localStorage.getItem("idEmployee") == ""
                  ? "Guardar"
                  : "Actualizar"}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <DialogPhotoQuality
        employeeData={employeeData}
        setDisableButton={setDisableButton}
        openDialogPhoto={openDialogPhoto}
        setOpenDialogPhoto={setOpenDialogPhoto}
        openDialogSibling={openDialogSibling}
        setOpenDialogSibling={setOpenDialogSibling}
      />

      <DialogPhotoRequirements
        userData={userData}
        setUserData={setUserData}
        employeeData={employeeData}
        setEmployeeData={setEmployeeData}
        infoDialog={infoDialog}
        openDialogPhotoReq={openDialogPhotoReq}
        setOpenDialogPhotoReq={setOpenDialogPhotoReq}
      />

      <DialogSiblings
        employeeData={employeeData}
        setEmployeeData={setEmployeeData}
        faceMacth={faceMacth}
        idConfirm={idConfirm}
        setChangesFlag={setChangesFlag}
        setChangesFile={setChangesFile}
        setDisableButton={setDisableButton}
        siteFlag={siteFlag}
        setSiteFlag={setSiteFlag}
        subcontractorFlag={subcontractorFlag}
        setSubcontractorFlag={setSubcontractorFlag}
        openDialogSibling={openDialogSibling}
        setOpenDialogSibling={setOpenDialogSibling}
        openBackdrop={openBackdrop}
        setOpenBackdrop={setOpenBackdrop}
        openDialogUnsavedEmployee={openDialogUnsavedEmployee}
        setOpenDialogUnsavedEmployee={setOpenDialogUnsavedEmployee}
        errorData={errorData}
        setErrorData={setErrorData}
      />

      <DialogSubcontractorsList
        employeeData={employeeData}
        setEmployeeData={setEmployeeData}
        openDialog={openDialog}
        setOpendialog={setOpendialog}
        setChangesFlag={setChangesFlag}
        setSubcontractorFlag={setSubcontractorFlag}
      />

      <DialogSitesList
        employeeData={employeeData}
        setEmployeeData={setEmployeeData}
        proyects={proyects}
        openDialogSite={openDialogSite}
        setOpendialogSite={setOpendialogSite}
        setChangesFlag={setChangesFlag}
        setSiteFlag={setSiteFlag}
      />

      <DialogJobList
        type={"trabajador_puestos"}
        job={job}
        setJob={setJob}
        employeeData={employeeData}
        setEmployeeData={setEmployeeData}
        openDialogJobs={openDialogJobs}
        setOpenDialogJobs={setOpenDialogJobs}
        jobsFlag={jobsFlag}
        setJobsFlag={setJobsFlag}
        setChangesFlag={setChangesFlag}
      />

      {/* <DialogCatalogList
        catalogItem={job}
        setCatalogItem={setJob}
        data={employeeData}
        setData={setEmployeeData}
        openDialog={openDialogJobs}
        setOpenDialog={setOpenDialogJobs}
        itemFlag={jobsFlag}
        setItemFlag={setJobsFlag}
        setChangesFlag={setChangesFlag}
      /> */}

      <DialogCatalogList
        catalogItem={unit}
        setCatalogItem={setUnit}
        data={employeeData}
        setData={setEmployeeData}
        openDialog={openDialogUnits}
        setOpenDialog={setOpenDialogUnits}
        itemFlag={unitsFlag}
        setItemFlag={setUnitsFlag}
        setChangesFlag={setChangesFlag}
      />

      <DialogBackScreen
        saveEmployee={saveEmployee}
        openDialogBack={openDialogBack}
        setOpendialogBack={setOpendialogBack}
      />

      <DialogLoadingUploadDocuments
        type="trabajadores"
        setChangesFile={setChangesFile}
      />

      <BackdropComponent
        openBackdrop={openBackdrop}
        setOpenBackdrop={setOpenBackdrop}
      />

      <DialogUnsavedEmployee
        openDialogUnsavedEmployee={openDialogUnsavedEmployee}
        setOpenDialogUnsavedEmployee={setOpenDialogUnsavedEmployee}
        employeeData={employeeData}
        setEmployeeData={setEmployeeData}
        faceMacth={faceMacth}
        idConfirm={idConfirm}
        setChangesFlag={setChangesFlag}
        setChangesFile={setChangesFile}
        setDisableButton={setDisableButton}
        siteFlag={siteFlag}
        setSiteFlag={setSiteFlag}
        subcontractorFlag={subcontractorFlag}
        setSubcontractorFlag={setSubcontractorFlag}
        openDialogSibling={openDialogSibling}
        setOpenDialogSibling={setOpenDialogSibling}
        openBackdrop={openBackdrop}
        setOpenBackdrop={setOpenBackdrop}
        errorData={errorData}
        setErrorData={setErrorData}
        saveEmployee={saveEmployee}
      />

      <DialogSpecialtyList
        employeeData={employeeData}
        setEmployeeData={setEmployeeData}
        specialtyFlag={specialtyFlag}
        setChangesFlag={setChangesFlag}
        openDialogSpecialty={openDialogSpecialty}
        setOpenDialogSpecialty={setOpenDialogSpecialty}
        openDialogSpecialtyAdmin={openDialogSpecialtyAdmin}
        setOpenDialogSpecialtyAdmin={setOpenDialogSpecialtyAdmin}
      />

      <DialogAdminSpecialtyList
        specialty={specialty}
        setSpecialty={setSpecialty}
        specialtyFlag={specialtyFlag}
        setSpecialtyFlag={setSpecialtyFlag}
        openDialogSpecialty={openDialogSpecialty}
        setOpenDialogSpecialty={setOpenDialogSpecialty}
        openDialogSpecialtyAdmin={openDialogSpecialtyAdmin}
        setOpenDialogSpecialtyAdmin={setOpenDialogSpecialtyAdmin}
        openDialogAddNewSpecialty={openDialogAddNewSpecialty}
        setOpenDialogAddNewSpecialty={setOpenDialogAddNewSpecialty}
      />

      <DialogAddNewSpecialty
        specialty={specialty}
        setSpecialty={setSpecialty}
        specialtyFlag={specialtyFlag}
        setSpecialtyFlag={setSpecialtyFlag}
        openDialogSpecialtyAdmin={openDialogSpecialtyAdmin}
        setOpenDialogSpecialtyAdmin={setOpenDialogSpecialtyAdmin}
        openDialogAddNewSpecialty={openDialogAddNewSpecialty}
        setOpenDialogAddNewSpecialty={setOpenDialogAddNewSpecialty}
      />
    </div>
  );
}
