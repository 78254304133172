/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useDropzone } from "react-dropzone";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typo from "../../../common_components/typo";
import PhotoQuality from "../../../images/photo_requirements.png";

const useStyles = makeStyles((theme) => ({
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: "bold",
    textAlign: "left",
    color: "#cdcece",
  },
  textFieldCustom: {
    width: "350px",
    color: "#676767",
    border: "solid 1px #c5c5c5",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
  },
  divImg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "144px",
    height: "144px",
    borderRadius: "8px",
    backgroundColor: "#2d3034",
    cursor: "pointer",
  },
  trackVertical: {
    height: "95%",
    right: 0,
    width: "10px !important",
  },
  thumbVertical: {
    backgroundColor: "#2d3034",
    height: "100%",
    right: 0,
    width: "8px !important",
    borderRadius: "8px",
  },
  photo: {
    width: "120px",
    height: "120px",
    borderRadius: "8px",
    aspectRatio: 1,
    // position: "absolute",
    objectFit: "cover",
  },
}));

export default function DialogPhotoRequirements(props) {
  const classes = useStyles();

  const handleOpenDialog = () => {
    props.setOpenDialogPhotoReq(!props.openDialogPhotoReq);
  };

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    noKeyboard: true,
    accept: "image/*",
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      acceptedFiles.map((file) => processImage(file));
    },
    // noClick: true
  });

  const processImage = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let fileURL = window.URL.createObjectURL(file);
      props.setEmployeeData({
        ...props.employeeData,
        foto: file,
        fotoCache: fileURL,
      });
      props.setOpenDialogPhotoReq(!props.openDialogPhotoReq);
    };
  };

  return (
    <Dialog
      open={props.openDialogPhotoReq}
      onClose={handleOpenDialog}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        style: {
          width: "483px",
          height: "600px",
          paddingTop: 10,
          textColor: "#fsf",
          backgroundColor: "#171a1e",
          borderRadius: "8px",
        },
      }}
    >
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 18,
              textAlign: "center",
              color: "#fff",
              fontWeight: "bold",
            }}
          >
            Indicaciones de foto
          </Typography>

          <Grid
            container
            justifyContent="flex-end"
            style={{ position: "absolute" }}
          >
            <Button onClick={handleOpenDialog}>
              <FontAwesomeIcon
                icon={faTimes}
                style={{
                  width: "20px",
                  height: "20px",
                  color: "#fff",
                }}
              />
            </Button>
          </Grid>
        </Grid>
        <div
          style={{
            width: "100%",
            height: 1,
            background: "#707070",
            marginTop: 8,
            padding: 0,
          }}
        ></div>
      </DialogTitle>

      <DialogContent>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typo
            fontSize={16}
            color={"#fff"}
            textAlign={"justify"}
            text={
              "La foto del empleado debe de cumplir con las siguientes características para un correcto funcionamiento en la toma de asistencia."
            }
          />
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{
              marginTop: 80,
            }}
          >
            <Grid
              item
              xs={5}
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{
                  width: "144px",
                  height: "168px",
                  borderRadius: "12px",
                  backgroundColor: "#2d3034",
                }}
              >
                <img src={PhotoQuality} className={classes.photo} alt="photo" />
              </Grid>
            </Grid>

            <Grid
              item
              xs={7}
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Typo
                fontSize={16}
                color={"#c9c9c9"}
                textAlign={"center"}
                text={"Fondo blanco en foto"}
              />
              <Typo
                fontSize={16}
                color={"#c9c9c9"}
                textAlign={"center"}
                text={"Rostro sin lentes y despejado"}
                marginTop={5}
              />
              <Typo
                fontSize={16}
                color={"#c9c9c9"}
                textAlign={"center"}
                text={"Foto tomada de frente"}
                marginTop={5}
              />
              <Typo
                fontSize={16}
                color={"#c9c9c9"}
                textAlign={"center"}
                text={"No mayor a 10Mb"}
                marginTop={5}
              />
              <Typo
                fontSize={16}
                color={"#c9c9c9"}
                textAlign={"center"}
                text={"Buena iluminación en la foto"}
                marginTop={5}
              />
              <Typo
                fontSize={16}
                color={"#c9c9c9"}
                textAlign={"center"}
                text={"El rostro abarque el 70% de la foto"}
                marginTop={5}
              />
            </Grid>
          </Grid>

          {props.infoDialog ? (
            <Button
              variant="contained"
              onClick={() =>
                props.setOpenDialogPhotoReq(!props.openDialogPhotoReq)
              }
              style={{
                width: "320px",
                height: "48px",
                borderRadius: "8px",
                backgroundColor: "#ac0bce",
                textTransform: "none",
                marginTop: 70,
              }}
            >
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 18,
                  textAlign: "left",
                  color: "#fff",
                }}
              >
                OK
              </Typography>
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={() => {
                props.setOpenDialogPhotoReq(!props.openDialogPhotoReq);
                props.setUserData({
                  ...props.userData,
                  fotoReq: false,
                });
              }}
              style={{
                width: "320px",
                height: "48px",
                borderRadius: "8px",
                backgroundColor: "#ac0bce",
                textTransform: "none",
                marginTop: 70,
              }}
              {...getRootProps()}
            >
              <input {...getInputProps()} />

              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 18,
                  textAlign: "left",
                  color: "#fff",
                }}
              >
                OK
              </Typography>
            </Button>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
