/*eslint-disable*/
export const types = {
  fileAdd: "[file] Add File",
  fileDelete: "[file] Delete File",
  fileUpdate: "[file] Update File",
  fileClear: "[file] Clear Files",
  fileUpdloadModal: "[file] Upload modal",
  fileRetry: "[file] Retry File",
  fileUpdateObject: "[file] Update File Object",
  fileReplaceObject: "[file] Replace File Object",

  assistanceLoad: "[assistance] Load Assistance",
  assistanceLoading: "[assistance] Loading Assistance",

  obraLoad: "[obras] Add Obras",
  obraSelected: "[obras] Selected Obras",
  obrasSelectedContratista: "[obras] Selected Contratista",
  obrasUpdate: "[obras] Update Obras",

  permissionsLoad: "[permissions] Load Permissions",
  modulesLoad: "[permissions] Load Modules",

  userLoad: "[user] Load User",

  reset: "[all] All Reset",

  login: "[auth] Login",

  screen: "[screen] Render Screen",
  screenEmployees: "[screenEmployees] Render Screen",
  screenProfile: "[screenProfile] Render Screen",

  filtersWordkingTime: "[Filters] Filters Working Time",
  filtersEmployees: "[Filters] Filters Employees",
  filtersProyects: "[Filters] Filters Proyects",
  filtersSubcontractors: "[Filters] Filters Subcontractors",
  filtersAttendance: "[Filters] Filters Attendance",
  filtersAssistance: "[Filters] Filters Assistance",
  filtersGateway: "[Filters] Filters Gateway",
  filtersDevice: "[Filters] Filters Device",
  filtersReset: "[Filters] All Reset Filters",

  workingTimeSelectedDetails: "[WorkingTime] Selected Detail Working Time",

  ContractorSelectedDetails: "[Contractor] Selected Detail Contractor",

  loadConfig: "[Config] Config Initial Load",
  loadColors: "[Config] Colors Initial Load",
};
