/*eslint-disable*/
import { types } from "../types/types";

export const LoadPermissions = (result) => ({
  type: types.permissionsLoad,
  payload: result,
});


export const LoadModules = (result) => ({
  type: types.modulesLoad,
  payload: result,
});
