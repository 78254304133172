/*eslint-disable*/
import React from "react";
import DataGrid from "../../../common_components/Filters/DataGrid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { makeStyles } from "@material-ui/styles";
import { Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .header": {
      color: "white",
      fontFamily: "Roboto",
      fontSize: 18,
      fontWeight: 500,
      display: "flex",
    },
  },
  photo: {
    width: 40,
    height: 40,
    borderRadius: 8,
    margin: "auto",
    aspectRatio: 1,
    objectFit: "cover",
  },
}));

export default function DataGridAssitance(props) {
  const classes = useStyles();

  const columns = [
    {
      field: "foto",
      headerName: " ",
      headerAlign: "center",
      flex: 0.4,
      sortable: false,
      headerClassName: "header",
      renderCell: (params) => {
        return <img src={params.value} className={classes.photo} alt="photo" />;
      },
    },
    {
      field: "trabajadores_nombre",
      headerName: "Nombre",
      headerAlign: "center",
      flex: 1,
      sortable: false,
      headerClassName: "header",
    },
    {
      field: "proyectos_proyecto",
      headerName: "Obra",
      headerAlign: "center",
      flex: 1,
      sortable: false,
      headerClassName: "header",
    },
    {
      field: "subcontratistas_empresa",
      headerName: "Subcontratista",
      headerAlign: "center",
      flex: 1,
      sortable: false,
      headerClassName: "header",
    },
    {
      field: "subcontratistas_unidad",
      headerName: "Unidad",
      headerAlign: "center",
      flex: 1,
      sortable: false,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <div style={{ margin: "auto" }}>
            {params.row.subcontratistas_unidad}
          </div>
        );
      },
    },
    {
      field: "date",
      headerName: "Día",
      headerAlign: "center",
      flex: 0.8,
      sortable: false,
      headerClassName: "header",
      renderCell: (params) => {
        let date = new Date(params.row.timestamp).toLocaleString();
        return <div style={{ margin: "auto" }}>{date.split(" ")[0]}</div>;
      },
    },
    {
      field: "time",
      headerName: "Hora",
      headerAlign: "center",
      flex: 0.5,
      sortable: false,
      headerClassName: "header",
      renderCell: (params) => {
        let date = new Date(params.row.timestamp).toLocaleTimeString("en-GB");
        return <div style={{ margin: "auto" }}>{date}</div>;
      },
    },
    {
      field: "tipoEvento",
      headerName: "Tipo de evento",
      headerAlign: "center",
      flex: 0.8,
      sortable: false,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Grid container direction="column" justifyContent="center">
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <img
                src={params.row.catalogos[0]?.iconUrl}
                style={{
                  width: 14,
                  height: 16,
                  marginRight: 8,
                }}
              />
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 14,
                  lineHeight: 1.36,
                  textAlign: "left",
                  color: "#fff",
                }}
              >
                {params.row.tipoEvento}
              </Typography>
            </Grid>

            {params.row.status == "manual assistance" ? (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{
                  marginTop: 2,
                }}
              >
                <FontAwesomeIcon
                  icon={faUserEdit}
                  style={{
                    width: "12.5px",
                    height: "10px",
                    color: "#d233ff",
                    marginRight: 5,
                    marginBottom: 2,
                  }}
                />
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 12,
                    lineHeight: 1.33,
                    textAlign: "left",
                    color: "#acacac",
                  }}
                >
                  Asistencia manual
                </Typography>
              </Grid>
            ) : null}
          </Grid>
        );
      },
    },
  ];

  return (
    <DataGrid
      rows={props.rows}
      columns={columns}
      loading={props.loading}
      rowCount={props.rowCount}
      rowsPerPageOptions={props.rowsPerPageOptions}
      page={props.page}
      pageSize={props.pageSize}
      onPageChange={props.onPageChange}
      onPageSizeChange={props.onPageSizeChange}
      style={props.style}
      onSelectionModelChange={props.onSelectionModelChange}
      selectionModel={props.selectionModel}
    />
  );
}
