/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import {
  Grid,
  Select,
  MenuItem,
  TextField,
  Typography,
  makeStyles,
  IconButton,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import DateIconComponent from "../../../../common_components/DateIconComponent";
import axios from "axios";
import axiosInstance from "../../../../utils/axiosInstance";

const useStyles = makeStyles((theme) => ({
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: "bold",
    textAlign: "left",
    color: "#cdcece",
  },
  textFieldCustom: {
    width: "324px",
    color: "#676767",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
  },
  formControl: {
    width: "324px",
  },
  select: {
    width: "324px",
    height: "40px",
    borderRadius: "6px",
    backgroundColor: "#fff",
  },
  multi: {
    color: "white",
    padding: 2,
    border: 0,
    borderRadius: "8px",

    "&.rmsc .dropdown-heading ": {
      backgroundColor: "white !important",
      color: "#676767",
      borderRadius: 5,
    },
  },
}));

export default function DialogObservations(props) {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { setOpendialog, openDialog, query, setQuery } = props;
  const [empresas, setEmpresas] = useState([]);
  const [date, setDate] = useState(null);
  const [disableButton, setDisableButton] = useState(false);

  const schema = Yup.object({
    empresa: Yup.string().required("Campo Obligatorio"),
    observacion: Yup.string().required("Campo Obligatorio"),
  });

  const formik = useFormik({
    initialValues: {
      empresa: "",
      observacion: "",
    },
    validationSchema: schema,
  });

  const validateData = async () => {
    return await schema.isValid(formik.values);
  };

  const handleDialog = () => {
    setOpendialog(!openDialog);
    setDate(null);
    formik.resetForm();
  };

  const getEmpresasList = () => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    var params = new URLSearchParams();
    params.append("limit", 1000);
    params.append("page", 0);
    params.append("empresa_partial", "");
    params.append("showoculta", true);

    axiosInstance()
      .get("/api/v1/rules/subcontratistas/filter", {
        params,
        cancelToken: source.token,
      })
      .then((response) => {
        // console.log(response.data.data.Results);
        let result = response.data.data.Results.map((object) => {
          return {
            name: object.empresa,
            id: object._id,
            padre: object.empresaPadre ? object.empresaPadre : false,
            ocultar: object.oculta ? object.oculta : false,
          };
        });

        // result = result.filter(
        //   (empresa) => empresa.name != "Sin subcontratista"
        // );
        setEmpresas(result);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getEmpresasList();
  }, []);

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("/");
  }

  const saveObservation = (data, date) => {
    return axiosInstance()
      .post("/api/v1/rules/wtobservaciones", {
        subcontratistas_id: data.empresa,
        fecha: formatDate(date),
        fecha_datetime: new Date(date),
        observacion: data.observacion,
      })
      .then((response) => {
        return true;
      })
      .catch((e) => {
        console.log(e);
        setDisableButton(false);
        return false;
      });
  };

  const handleNewObservation = async () => {
    let result = false;
    // console.log(date);
    if (!((await validateData()) && date != null)) {
      enqueueSnackbar("Favor de llenar todos los campos", {
        variant: "warning",
      });
      return;
    }

    setDisableButton(true);
    result = await saveObservation(formik.values, date);

    if (result) {
      enqueueSnackbar("Observación agregada correctamente", {
        variant: "success",
      });

      setQuery({ ...query, flagChange: !query.flagChange });
      handleDialog();
      // setOpendialog(!openDialog);
    } else {
      enqueueSnackbar("Error al agregar observación", {
        variant: "warning",
      });
    }

    setTimeout(() => {
      setDisableButton(false);
    }, 2000);
  };

  return (
    <Dialog
      open={openDialog}
      aria-labelledby="form-dialog-title"
      onClose={handleDialog}
      PaperProps={{
        style: {
          backgroundColor: "#171a1e",
          textColor: "#fsf",
          width: "483px",
          height: "690px",
          paddingTop: 15,
          borderRadius: 8,
        },
      }}
    >
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 22,
              textAlign: "center",
              color: "#fff",
              fontWeight: "bold",
            }}
          >
            Agregar observación
          </Typography>

          <Grid
            container
            justifyContent="flex-end"
            style={{ position: "absolute" }}
          >
            <IconButton onClick={handleDialog}>
              <FontAwesomeIcon
                icon={faTimes}
                style={{
                  width: "20px",
                  height: "20px",
                  color: "#fff",
                  marginLeft: -50,
                }}
              />
            </IconButton>
          </Grid>
        </Grid>
        <div
          style={{
            width: "100%",
            height: 1,
            background: "#707070",
            marginTop: 8,
            padding: 0,
          }}
        ></div>
      </DialogTitle>

      <DialogContent>
        {/* DateIconComponent */}
        <Grid container direction="column" alignContent="center">
          <Typography
            style={{
              marginTop: 25,
              fontFamily: "Roboto",
              fontSize: 17,
              fontWeight: "bold",
              textAlign: "left",
              color: "#cdcece",
            }}
          >
            Día de observación
          </Typography>
          <DateIconComponent date={date} setDate={setDate} />

          <Typography
            style={{
              marginTop: 25,
              fontFamily: "Roboto",
              fontSize: 17,
              fontWeight: "bold",
              textAlign: "left",
              color: "#cdcece",
            }}
          >
            Subcontratista
          </Typography>
          <Select
            name="empresa"
            id="empresa"
            inputProps={{ "aria-label": "Without label" }}
            className={classes.select}
            value={formik.values.empresa}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            displayEmpty
          >
            <MenuItem value="" disabled>
              &ensp;Obligatorio
            </MenuItem>
            {empresas.map((_empresas, index) => (
              <MenuItem key={index} value={_empresas.id}>
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 17,
                    marginLeft: 10,
                    color: "black",
                  }}
                >
                  {_empresas.name}
                </Typography>
              </MenuItem>
            ))}
          </Select>

          <Typography
            style={{
              marginTop: 25,
              fontFamily: "Roboto",
              fontSize: 17,
              fontWeight: "bold",
              textAlign: "left",
              color: "#cdcece",
            }}
          >
            Observación
          </Typography>

          <TextField
            id="observacion"
            name="observacion"
            size="small"
            variant="outlined"
            placeholder="Obligatorio"
            className={classes.textFieldCustom}
            multiline
            rows={10}
            value={formik.values.observacion}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="center"
          style={{ marginTop: 50 }}
        >
          <Button
            disabled={disableButton}
            onClick={handleNewObservation}
            style={{
              width: "320px",
              height: "40px",
              borderRadius: "8px",
              color: "#ffffff",
              backgroundColor: "#ac0bce",
              textTransform: "none",
            }}
          >
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 21,
                color: "#ffffff",
              }}
            >
              Guardar
            </Typography>
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
