/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Grid, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import svgOut from "../../../images/salida_icono.svg";
import svgIn from "../../../images/ingreso_icono.svg";
import { BarChart } from "./charts/BarChartComponent";

const useStyles = makeStyles((theme) => ({
  card: {
    width: 674,
    height: 349,
    padding: theme.spacing(2),
    borderRadius: 16,
    backgroundColor: "#2d3034",
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.3,
    textAlign: "left",
    color: "#fff",
    // display: "-webkit-box",
    // WebkitBoxOrient: "vertical",
    // WebkitLineClamp: 2,
    // textOverflow: "ellipsis",
    // overflow: "hidden",
    // maxWidth: "80%",
  },
  subtitle: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 1.31,
    textAlign: "left",
    color: "#afafaf",
  },
  typoWatch: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 1.33,
    textAlign: "left",
    color: "#d233ff",
    cursor: "pointer",
  },
  divBackIcon: {
    width: 32,
    height: 32,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
    backgroundImage: "linear-gradient(91deg, #d233ff 1%, #ac0bce 99%)",
  },
  typoWorkersNumber: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.31,
    textAlign: "left",
    color: "#fff",
    marginRight: 5,
  },
  typoWorkersLabel: {
    fontFamily: "Roboto",
    fontSize: 14,
    lineHeight: 1.36,
    textAlign: "left",
    color: "#acacac",
  },
}));

export default function BigCardGraphics(props) {
  const classes = useStyles();
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "",
        data: [],
        backgroundColor: [],
        borderWidth: 1,
      },
    ],
  });

  const data = {
    labels: ["Red", "Orange", "Blue"],
    // datasets is an array of objects where each object represents a set of data to display corresponding to the labels above. for brevity, we'll keep it at one object
    datasets: [
      {
        label: "Popularity of colours",
        data: ["55", "23", "96"],
        // you can set indiviual colors for each bar
        backgroundColor: [
          "rgba(255, 255, 255, 0.6)",
          "rgba(255, 255, 255, 0.6)",
          "rgba(255, 255, 255, 0.6)",
        ],
        borderWidth: 1,
      },
    ],
  };

  useEffect(() => {
    const fetchPrices = async () => {
      const res = await fetch("https://api.coincap.io/v2/assets/?limit=30");
      const data = await res.json();

      setChartData({
        // labels: data.data?.map((crypto) => crypto?.name),
        labels: data.data?.map((crypto) => ""),
        datasets: [
          {
            label: "Reconocimientos",
            data: data.data?.map((crypto) => crypto?.priceUsd),
            backgroundColor: () => {
              var array = [];
              for (var i = 1; i <= data.data.length; i++) {
                array.push("#d233ff");
              }
              return array;
            },
          },
        ],
      });
    };
    fetchPrices();
  }, []);

  return (
    <Card className={classes.card}>
      <Grid
        container
        direction="column"
        style={{
          marginBottom: 15,
        }}
      >
        <Typography className={classes.title}>{props.title}</Typography>
        <Typography className={classes.subtitle}>{props.subtitle}</Typography>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <BarChart chartData={chartData} />
      </Grid>
    </Card>
  );
}
