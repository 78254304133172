/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExitToApp from "@material-ui/icons/ExitToApp";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "../../../images/absa-industrial.png";
import { useDispatch, useSelector } from "react-redux";
import { LoadPermissions, LoadModules } from "../../../actions/permissions";
import { LoadUser } from "../../../actions/user";
import { types } from "../../../types/types";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../../utils/axiosInstance";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiListItemIcon-root": {
      minWidth: "30px !important",
    },
  },
  toolbar: theme.mixins.toolbar,
  mainDiv: {
    height: "100vh",
    // backgroundColor: theme.palette.neutral.primary,
  },
  inactiveListItem: {
    paddingLeft: "45px",
    color: theme.palette.secondary.dark,
  },
  activeListItem: {
    color: theme.palette.neutral.primary,
    borderRightWidth: "6px",
    borderRightColor: theme.palette.primary.main,
    borderRightStyle: "solid",
    backgroundImage: `linear-gradient(to right, #81BEF7 0%, #2E9AFE 100%)`,
  },
  typografy: {
    fontFamily: "Roboto",
    fontSize: 18,
    fontWeight: 600,
    textAlign: "left",
    // color: theme.palette.secondary.dark,
    marginTop: 4,
  },
  icon: {
    fontSize: 20,
    width: 20,
    // color: theme.palette.secondary.dark,
    marginRight: 15,
    verticalAlign: "bottom",
    float: "none",
    strokeWidth: "2.3px",
    transition: "all 0.3s ease",
  },
  toolbarKnesys: {
    // backgroundColor: theme.palette.neutral.primary,
    paddingTop: "15px",
    paddingBottom: "15px",
    ...theme.mixins.toolbar,
  },
  logo: {
    width: "160px",
    height: "90px",
  },
}));

export default function ListDrawer(props) {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [open, setOpen] = useState(false);
  const [permisosUsuario, setPermisosUsuario] = useState([]);
  const UserInfo = props.UserInfo;

  const ReloadUser = async () => {
    try {
      return await axiosInstance()
        .get("/api/v1/security/user/profile/")
        .then((response) => {
          dispatch(LoadUser(response.data.data));
          return response.data.data.Role_id;
        })
        .catch((e) => {
          console.log(e.response);
          return false;
        });
    } catch (error) {
      return false;
    }
  };

  const getModules = async () => {
    return await axiosInstance()
      .get("/api/v1/security/modules")
      .then((response) => {
        const modules = response.data.data;
        dispatch(LoadModules(modules));
        return modules;
      })
      .catch((e) => {
        console.log(e);
        return null;
      });
  };

  const getPermissions = async () => {
    const roleId = await ReloadUser();
    const modules = await getModules();

    axiosInstance()
      .get(`/api/v1/security/role/${roleId}`)
      .then(async (response) => {
        const permisions = response.data.data[0].Permisions;
        var permisionsAccess = [];

        dispatch(LoadPermissions(permisions));

        Object.keys(permisions).map(function (key, index) {
          if (permisions[key]?.habilitar) {
            const module = modules.find((x) => x.nombre == key);

            if (module?.menu) {
              permisionsAccess.push(module);
            }
          }
        });

        setPermisosUsuario(permisionsAccess);
        if (history.location.pathname === "/dashboard/") {
          history.push(
            permisionsAccess?.sort((a, b) =>
              a.posicion > b.posicion ? 1 : -1
            )[0].url
          );
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getPermissions();
  }, []);

  const handleExit = () => {
    dispatch({ type: types.reset });
    localStorage.clear();
  };

  const handleChange = () => {
    dispatch({ type: types.filtersReset });
  };

  return (
    <div className={classes.mainDiv}>
      <div className={classes.toolbarKnesys}>
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          direction="column"
        >
          <Grid item>
            <img src={Logo} className={classes.logo} alt="techton logo" />
          </Grid>
        </Grid>
      </div>

      <Grid
        container
        direction="column"
        justifyContent="space-between"
        style={{ height: "85vh" }}
        // style={{ height: "calc(100vh - 17%)"}}
      >
        <List>
          {permisosUsuario
            ?.sort((a, b) => (a.posicion > b.posicion ? 1 : -1))
            .map((_module, index) => (
              <ListItem
                alignItems="center"
                activeClassName={classes.activeListItem}
                className={classes.inactiveListItem}
                onClick={handleChange}
                component={NavLink}
                to={_module.url}
                key={index}
              >
                <FontAwesomeIcon
                  icon={_module.icono}
                  className={classes.icon}
                />
                <ListItemText
                  disableTypography
                  primary={
                    <Typography className={classes.typografy}>
                      {_module.nombre.replace(
                        "Contratistas",
                        "Subcontratistas"
                      )}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
        </List>

        <List>
          <ListItem
            alignItems="center"
            className={classes.inactiveListItem}
            onClick={handleExit}
            component={NavLink}
            to="/inicio_sesion"
          >
            <ListItemIcon>
              <ExitToApp className={classes.icon} />
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary={
                <Typography className={classes.typografy}>Salir</Typography>
              }
            />
          </ListItem>
        </List>
      </Grid>
    </div>
  );
}
