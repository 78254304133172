/*eslint-disable*/
import React, { Fragment, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCopy } from "@fortawesome/free-solid-svg-icons";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ipServer } from "../../../config/vars";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Roboto",
    fontSize: 22,
    textAlign: "center",
    color: "#fff",
    fontWeight: "bold",
  },
  divDivider: {
    width: "100%",
    height: 1,
    background: "#707070",
    marginTop: 8,
    padding: 0,
  },
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: "bold",
    textAlign: "left",
    color: "#cdcece",
    marginTop: 25,
  },
  textFieldCustom: {
    width: "100%",
    color: "#676767",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
  },
  typoFormikError: {
    fontFamily: "Roboto",
    textAlign: "center",
    color: "#FF1717",
    marginBottom: -25,
  },
  typoLicense: {
    fontFamily: "Roboto",
    fontSize: 20,
    fontWeight: "bold",
    lineHeight: 1.3,
    textAlign: "left",
    color: "#fff",
  },
  button: {
    width: "320px",
    height: "48px",
    borderRadius: "8px",
    color: "#ffffff",
    backgroundColor: "#ac0bce",
    textTransform: "none",
    marginTop: 50,
  },
  typoButton: {
    fontFamily: "Roboto",
    fontSize: 21,
    color: "#ffffff",
  },
}));

export default function DialogInviteUser(props) {
  const classes = useStyles();
  const { setOpendialog, openDialog, complete, setComplete, setData } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [roles, setRoles] = useState(null);
  const [empresas, setEmpresas] = useState(null);
  const [Reload, setReload] = useState(false);

  const schema = Yup.object({
    nombre: Yup.string().required("Campo Obligatorio"),
    costoObraActiva: Yup.string().required("Campo Obligatorio"),
    costoReconocimiento: Yup.string().required("Campo Obligatorio"),
    corte: Yup.string().required("Campo Obligatorio"),
    pago: Yup.string().required("Campo Obligatorio"),
  });

  const formik = useFormik({
    initialValues: {
      nombre: "",
      costoObraActiva: "",
      costoReconocimiento: "",
      corte: "",
      pago: "",
    },
    validationSchema: schema,
  });

  const validateData = async () => {
    return await schema.isValid(formik.values);
  };

  //   useEffect(() => {
  //     if (!openDialog) {
  //       setTimeout(() => {
  //         setComplete(false);
  //         setData(null);
  //       }, 200);
  //     }
  //   }, [openDialog]);

  //   useEffect(() => {
  //     if (!!props.data && complete) {
  //       reenviar(props.data);
  //     }
  //   }, [props.data]);

  const invite = async (data, obras) => {
    return await axios
      .post(
        `${ipServer}/api/v1/security/user/invite/`,
        {
          Email: data.email,
          RoleId: data.rol,
          Role_id: data.rol,
          SubcontractorId: data.empresa,
          ObrasId: obras,
        },
        {
          headers: {
            Authorization: localStorage.getItem("JWT"),
          },
        }
      )
      .then((response) => {
        return true;
      })
      .catch((e) => {
        console.log(e);
        if (e.response.data.errors == "User already exist") {
          enqueueSnackbar("El correo ya fue registrado por alguna empresa.", {
            variant: "error",
          });
        }
        return false;
      });
  };

  const saveClient = async () => {
    let projects = [];
    formik.values.obras.map((subcontractor, index) => {
      projects.push(subcontractor.value);
    });

    if (projects.length === 0) {
      enqueueSnackbar("Favor de seleccionar al menos una obra", {
        variant: "warning",
      });
      return;
    }

    if (!(await validateData())) {
      enqueueSnackbar("Favor de llenar todos los campos", {
        variant: "warning",
      });
      return;
    }

    if (await invite(formik.values, projects)) {
      enqueueSnackbar("Invitacion enviada correctamente", {
        variant: "success",
      });

      setComplete(true);
      setReload(true);
    } else {
      enqueueSnackbar("Error al enviar invitacion", {
        variant: "error",
      });
    }
  };

  const handleQueryChange = (queryElement) => (e) => {
    formik.setFieldValue(queryElement, e);
  };

  const getEmpresasList = () => {
    var params = new URLSearchParams();
    params.append("showoculta", true);

    axios({
      method: "GET",
      url: `${ipServer}/api/v1/rules/subcontratistas`,
      params,
      headers: { Authorization: localStorage.getItem("JWT") },
    })
      // axios
      //   .get(`${ipServer}/api/v1/rules/subcontratistas`, {
      //     headers: { Authorization: localStorage.getItem("JWT") },
      //   })
      .then((response) => {
        let result = response.data.data.map((object) => {
          return {
            name: object.empresa,
            id: object._id,
            padre: object.empresaPadre ? object.empresaPadre : false,
            ocultar: object.oculta ? object.oculta : false,
          };
        });

        result = result.filter(
          (empresa) => empresa.name != "Sin subcontratista"
        );
        setEmpresas(result);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  //   useEffect(() => {
  //     getEmpresasList();
  //   }, []);

  //   useEffect(() => {
  //     if (formik.values.empresa != "") {
  //       getProyectsList();
  //       getRolesList();
  //       formik.setFieldValue("rol", "");
  //       formik.setFieldValue("obras", []);
  //     }
  //   }, [formik.values.empresa]);

  return (
    <Dialog
      open={openDialog}
      aria-labelledby="form-dialog-title"
      onClose={() => props.setOpendialog((prev) => !prev)}
      PaperProps={{
        style: {
          backgroundColor: "#171a1e",
          textColor: "#fsf",
          width: "483px",
          height: "777px",
          paddingTop: 15,
          paddingBottom: 40,
        },
      }}
    >
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Typography className={classes.title}>Nuevo cliente</Typography>

          <Grid
            container
            justifyContent="flex-end"
            style={{ position: "absolute" }}
          >
            <Button onClick={() => props.setOpendialog((prev) => !prev)}>
              <FontAwesomeIcon
                icon={faTimes}
                style={{
                  width: "20px",
                  height: "20px",
                  color: "#fff",
                }}
              />
            </Button>
          </Grid>
        </Grid>
        <div className={classes.divDivider} />
      </DialogTitle>

      <DialogContent>
        <Fragment>
          <Grid container>
            {/* {props.license ? ( */}
            <Grid item xs={12}>
              <Typography className={classes.typoLicense}>
                Licencia de software
              </Typography>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <FontAwesomeIcon
                  icon={faCopy}
                  style={{
                    width: "18px",
                    height: "20px",
                    color: "#fff",
                    marginRight: 10,
                  }}
                />
                <Typography className={classes.typoLicense}>
                  AA23-BB21-BB12-FFCD
                </Typography>
              </Grid>
            </Grid>
            {/* ) : null} */}

            <Grid item xs={12}>
              <Typography className={classes.textFieldLetter}>
                Nombre cliente
              </Typography>
              <TextField
                name="nombre"
                size="small"
                variant="outlined"
                className={classes.textFieldCustom}
                value={formik.values.nombre}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.nombre && formik.errors.nombre ? (
                <Typography className={classes.typoFormikError}>
                  {formik.errors.nombre}
                </Typography>
              ) : null}
            </Grid>

            <Grid item xs={12}>
              <Typography className={classes.textFieldLetter}>
                Costo obra activa
              </Typography>
              <TextField
                name="costoObraActiva"
                size="small"
                variant="outlined"
                className={classes.textFieldCustom}
                value={formik.values.costoObraActiva}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.costoObraActiva &&
              formik.errors.costoObraActiva ? (
                <Typography className={classes.typoFormikError}>
                  {formik.errors.costoObraActiva}
                </Typography>
              ) : null}
            </Grid>

            <Grid item xs={12}>
              <Typography className={classes.textFieldLetter}>
                Costo reconocimiento
              </Typography>
              <TextField
                name="costoReconocimiento"
                size="small"
                variant="outlined"
                className={classes.textFieldCustom}
                value={formik.values.costoReconocimiento}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.costoReconocimiento &&
              formik.errors.costoReconocimiento ? (
                <Typography className={classes.typoFormikError}>
                  {formik.errors.costoReconocimiento}
                </Typography>
              ) : null}
            </Grid>

            <Grid item xs={12}>
              <Typography className={classes.textFieldLetter}>
                Fecha corte
              </Typography>
              <TextField
                name="corte"
                size="small"
                variant="outlined"
                className={classes.textFieldCustom}
                value={formik.values.corte}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.corte && formik.errors.corte ? (
                <Typography className={classes.typoFormikError}>
                  {formik.errors.corte}
                </Typography>
              ) : null}
            </Grid>

            <Grid item xs={12}>
              <Typography className={classes.textFieldLetter}>
                Fecha de pago
              </Typography>
              <TextField
                name="pago"
                size="small"
                variant="outlined"
                className={classes.textFieldCustom}
                value={formik.values.pago}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.pago && formik.errors.pago ? (
                <Typography className={classes.typoFormikError}>
                  {formik.errors.pago}
                </Typography>
              ) : null}
            </Grid>
          </Grid>

          <Grid container direction="row" justifyContent="center">
            <Button
              variant="contained"
              // onClick={saveClient}
              className={classes.button}
            >
              <Typography className={classes.typoButton}>Guardar</Typography>
            </Button>
          </Grid>
        </Fragment>
      </DialogContent>
    </Dialog>
  );
}
