/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { Card, Typography, Grid, makeStyles } from "@material-ui/core";
import CardAttendanceEvents from "./CardAttendanceEvents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faEdit,
  faAngleDown,
  faAngleUp,
  faHistory,
} from "@fortawesome/free-solid-svg-icons";
import CardAttendanceEventsExtra from "./CardAttendanceEventsExtra";
import DialogEditAttendance from "../DialogEditAttendance";
import DialogHistory from "../DialogHistory";
import { ValidatePermissions } from "../../../../utils/permissions";

export default function CardAttendance(props) {
  const [more, setMore] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogHistory, setOpenDialogHistory] = useState(false);
  const { data, query, setQuery } = props;

  const handleDialog = () => {
    setOpenDialog(!openDialog);
  };

  const handleDialogHistory = () => {
    setOpenDialogHistory(!openDialogHistory);
  };

  const months = [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
  ];

  function secondsToHms(d) {
    d = Number(d);
    var hours = Math.floor(d / 3600);
    var minutes = Math.floor((d % 3600) / 60);
    var seconds = Math.floor((d % 3600) % 60);
    return {
      hours,
      minutes,
      seconds,
    };
  }

  // useEffect(() => {
  //   console.log("data", data)
  // }, [data]);

  return (
    <Card
      style={{
        width: 1202,
        minWidth: 1202,
        // minHeight: 130,
        // height: more ? "auto" : 140,
        borderRadius: 12,
        backgroundColor: "#2d3034",
        paddingTop: 16,
        paddingRight: 16,
      }}
    >
      <Grid container direction="row">
        <Grid
          item
          xs={12}
          container
          direction="row"
          justifyContent="flex-end"
          style={{ cursor: "pointer" }}
        >
          <div style={{ height: 18 }} />
          {(data.entradaSalida.length > 0 || data?.evento.length > 0) && (
            <FontAwesomeIcon
              onClick={handleDialogHistory}
              icon={faHistory}
              style={{
                width: 18,
                height: 18,
                color: "#acacac",
                zIndex: 2,
              }}
            />
          )}

          {(ValidatePermissions("crear") ||
            (ValidatePermissions("editar") &&
              (data.entradaSalida.length > 0 || data?.evento.length > 0)) ||
            (ValidatePermissions("eliminar") &&
              (data.entradaSalida.length > 0 || data?.evento.length > 0))) && (
            <FontAwesomeIcon
              onClick={handleDialog}
              icon={faEdit}
              style={{
                width: 21,
                height: 18,
                color: "#acacac",
                zIndex: 2,
                marginLeft: 15,
              }}
            />
          )}
        </Grid>

        <Grid
          item
          xs={11}
          container
          direction="row"
          alignItems="center"
          style={{ marginTop: -20, minHeight: 110 }}
        >
          <Grid item xs={4}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                xs={1}
                style={{
                  width: 36,
                  height: 66,
                  backgroundColor: "#707070",
                  borderTopRightRadius: 8,
                  borderBottomRightRadius: 8,
                }}
              >
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  style={{ marginTop: 6 }}
                >
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 20,
                      textAlign: "left",
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    {new Date(data?.fecha).getDate()}
                  </Typography>

                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 14,
                      textAlign: "left",
                      color: "#e8e8e8",
                    }}
                  >
                    {months[new Date(data?.fecha).getMonth()]}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                xs={3}
                container
                direction="row"
                justifyContent="center"
              >
                <img
                  src={data?.imageIndex}
                  style={{
                    width: 56,
                    height: 56,
                    borderRadius: 8,
                    aspectRatio: 1,
                    objectFit: "cover",
                  }}
                  alt="photo"
                />
              </Grid>

              <Grid item xs={8} container direction="column">
                <Grid style={{ marginLeft: 15 }}>
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 16,
                      textAlign: "left",
                      color: "#fff",
                      fontWeight: 500,
                    }}
                  >
                    {data?.nombre}
                  </Typography>

                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 14,
                      textAlign: "left",
                      color: "#acacac",
                    }}
                  >
                    {data?.puesto}
                  </Typography>

                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 14,
                      textAlign: "left",
                      color: "#fff",
                      fontWeight: 500,
                    }}
                  >
                    {data?.empresa}
                  </Typography>

                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 16,
                      textAlign: "left",
                      color: "#acacac",
                      fontWeight: 500,
                    }}
                  >
                    {data?.unidad}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={3}
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ marginLeft: -30, marginLeft: 16, height: "auto" }}
          >
            {data.entradaSalida.length > 0 ? (
              data?.entradaSalida
                ?.slice(0, more ? Object.keys(data?.entradaSalida).length : 2)
                ?.map((_attendance, index) => (
                  <Grid
                    key={index}
                    container
                    direction="row"
                    style={{ marginBottom: 8 }}
                  >
                    <Grid item xs={4}>
                      <CardAttendanceEvents
                        data={_attendance.entrada > 0 && _attendance.entrada}
                        event="Entrada"
                        manual={
                          _attendance?.statusEntrada == "manual assistance"
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        style={{
                          width: 25,
                          height: 25,
                          color: "#fff",
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <CardAttendanceEvents
                        data={_attendance.salida > 0 && _attendance.salida}
                        event="Salida"
                        manual={
                          _attendance?.statusSalida == "manual assistance"
                        }
                      />
                    </Grid>
                  </Grid>
                ))
            ) : (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{
                  width: 243,
                  height: 40,
                  backgroundColor: "#2d3034",
                  border: "dashed 2px #707070",

                  borderRadius: 8,
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 17,
                    textAlign: "center",
                    color: "#e5e5e5",
                  }}
                >
                  Sin asistencia
                </Typography>
              </Grid>
            )}

            {!more && Object.keys(data?.entradaSalida).length > 2 && (
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 12,
                  textAlign: "center",
                  color: "#a1a1a1",
                }}
              >
                {`${
                  Object.keys(data?.entradaSalida).length - 2
                } asistencias más `}
              </Typography>
            )}
          </Grid>

          <Grid
            item
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            xs={2}
          >
            <Grid container direction="row" justifyContent="flex-end">
              {data?.evento.length > 0 ? (
                data?.evento
                  ?.slice(0, more ? Object.keys(data?.evento).length : 2)
                  ?.map((_attendance, index) => (
                    <Grid
                      item
                      key={index}
                      style={{ marginBottom: 8, margin: "center" }}
                    >
                      <CardAttendanceEventsExtra data={_attendance} />
                    </Grid>
                  ))
              ) : (
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  style={{
                    width: 175,
                    height: 40,
                    backgroundColor: "#2d3034",
                    border: "dashed 2px #707070",

                    borderRadius: 8,
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 17,
                      textAlign: "center",
                      color: "#e5e5e5",
                    }}
                  >
                    Sin eventos
                  </Typography>
                </Grid>
              )}
            </Grid>

            {!more && Object.keys(data?.evento)?.length > 2 && (
              <Grid item style={{ marginBottom: 8, margin: "center" }}>
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 12,
                    textAlign: "center",
                    color: "#a1a1a1",
                  }}
                >
                  {`${Object.keys(data?.evento)?.length - 2} eventos más`}
                </Typography>
              </Grid>
            )}
          </Grid>

          <Grid
            item
            xs={2}
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 26,
                    textAlign: "left",
                    color: "#fff",
                    fontWeight: 500,
                  }}
                >
                  {secondsToHms(data?.totalSegundosTrabajados)?.hours}
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 18,
                    textAlign: "left",
                    color: "#acacac",
                    marginLeft: 8,
                  }}
                >
                  Hrs
                </Typography>

                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 26,
                    textAlign: "left",
                    color: "#fff",
                    fontWeight: 500,
                    marginLeft: 12,
                  }}
                >
                  {secondsToHms(data?.totalSegundosTrabajados)?.minutes}
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 18,
                    textAlign: "left",
                    color: "#acacac",
                    marginLeft: 8,
                  }}
                >
                  min
                </Typography>
              </Grid>
            </Grid>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 14,
                textAlign: "center",
                color: "#acacac",
                fontWeight: 500,
              }}
            >
              Horas trabajadas
            </Typography>
          </Grid>
        </Grid>

        {(Object.keys(data?.evento).length > 2 ||
          Object.keys(data?.entradaSalida).length > 2) && (
          <Grid
            item
            xs={1}
            container
            direction="row"
            alignItems="flex-end"
            style={{ marginLeft: -50 }}
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              onClick={() => setMore(!more)}
              style={{
                backgroundColor: "#4f5257",
                width: "100%",
                height: 26,
                padding: 5,
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
                cursor: "pointer",
                marginBottom: !more && -1,
                bottom: 0,
              }}
            >
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 13,
                  textAlign: "center",
                  color: "#fff",
                }}
              >
                {!more ? "Ver más" : "Ver menos"}
              </Typography>

              <FontAwesomeIcon
                icon={!more ? faAngleDown : faAngleUp}
                style={{
                  width: 22,
                  height: 14,
                  color: "#fff",
                  marginTop: 2,
                }}
              />
            </Grid>
          </Grid>
        )}
      </Grid>

      <DialogEditAttendance
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        data={data}
        query={query}
        setQuery={setQuery}
      />

      <DialogHistory
        openDialog={openDialogHistory}
        setOpenDialog={setOpenDialogHistory}
        employeeId={data?._id}
        date={data?.fecha}
      />
    </Card>
  );
}
