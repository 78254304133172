/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, TextField, Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles((theme) => ({
  divImg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "356px",
    height: "290px",
    borderRadius: "8px",
    border: "solid 1px #707070",
    backgroundColor: "#212429",
    cursor: "pointer",
  },

  photo: {
    maxWidth: "291px",
    maxHeight: "190px",
    borderRadius: "1px",
    aspectRatio: 1,
    objectFit: "cover",
  },
  img_profile: {
    width: "56px",
    height: "56px",
    objectFit: "contain",
    borderRadius: "8px",
  },
}));

export default function DialogPhotoExitPass(props) {
  const classes = useStyles();

  const handleOpenDialog = () => {
    props.setOpenDialogPhotoExitPass(!props.openDialogPhotoExitPass);
  };

  return (
    <div>
      <Dialog
        open={props.openDialogPhotoExitPass}
        onClose={handleOpenDialog}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            width: "483px",
            height: "562px",
            backgroundColor: "#171a1e",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                lineHeight: 1.3,
                textAlign: "left",
                color: "#fff",
              }}
            >
              Firma biométrica
            </Typography>

            <Grid
              container
              justifyContent="flex-end"
              style={{ position: "absolute" }}
            >
              <Button onClick={handleOpenDialog}>
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{
                    width: "16px",
                    height: "16px",
                    color: "#fff",
                  }}
                />
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>
        <div
          style={{
            width: "482px",
            height: 0,
            border: "solid 1px #707070",
          }}
        ></div>

        <DialogContent>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              style={{
                marginTop: "39px",
                marginBottom: "11px",
              }}
            >
              <img
                src={props.dataDialog.photoURL}
                className={classes.photo}
                alt="photo"
              />
            </Grid>

            <Typography
              style={{
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: 1.31,
                textAlign: "left",
                color: "#fff",
              }}
            >
              {props.dataDialog.name}
            </Typography>
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: 1.36,
                textAlign: "left",
                color: "#acacac",
                marginBottom: "85px",
              }}
            >
              {props.dataDialog.email}
            </Typography>

            <Typography
              style={{
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: 1.36,
                textAlign: "left",
                color: "#fff",
              }}
            >
              Fecha
            </Typography>

            <Typography
              style={{
                fontSize: "14px",
                lineHeight: 1.36,
                textAlign: "left",
                color: "#acacac",
                marginBottom: "39px",
              }}
            >
              {props.dataDialog.date}
            </Typography>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
