/*eslint-disable*/
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { es } from "date-fns/locale";

const useStyles = makeStyles((theme) => ({
  textFieldCustom: {
    width: "100%",
    borderRadius: "6px",
    border: "solid 1px #c5c5c5",
    "& .MuiInputBase-input": {
      color: theme.palette.secondary.main,
    },
    marginBottom: 12,
  },
}));

export default function DateHourComponent(props) {
  const classes = useStyles();

  const handleChange = (newValue) => {
    // console.log(newValue);
    if (newValue != "" && newValue != null) {
      // console.log(props.name);
      props.setData({
        ...props.data,
        [props.name]: new Date(newValue),
      });
    }
  };

  return (
    <LocalizationProvider /* adapterLocale={es} */ dateAdapter={AdapterDateFns}>
      <DateTimePicker
        value={props.date}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            size="small"
            variant="outlined"
            // value={
            //   props.date
            //     ? new Date(props.date)?.toLocaleString("es-MX", {
            //         year: "numeric",
            //         month: "numeric",
            //         day: "numeric",
            //         hour: "2-digit",
            //         minute: "2-digit",
            //       })
            //     : ""
            // }
            // className={classes.textFieldCustom}
            {...params}
            // readOnly
            // inputProps={{
            //   placeholder: "Obligatorio",
            // }}
          />
        )}
      />
    </LocalizationProvider>
  );
}
