/*eslint-disable*/
import React, { Fragment, useEffect, useState } from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ImageGalleryComponent from "../ImageGalleryComponent";

export default function DialogGallery(props) {
  const { setOpenDialog, openDialog } = props;

  const handleDialog = () => {
    setOpenDialog(!openDialog);
  };

  //   useEffect(() => {
  //     props.rows.map((event) => {
  //       if (event.geolocation && event._id == props.selectionRows[0]) {
  //         const fullAdress = event?.geolocation?.fullAdress?.split(",");

  //         setaddress({
  //           calle: fullAdress ? fullAdress[0]?.trim() : "",
  //           colonia: fullAdress ? fullAdress[1]?.trim() : "",
  //           codigoPostal: fullAdress
  //             ? fullAdress[2]?.trim()?.substring(0, 5)
  //             : "",
  //           ciudad: fullAdress ? fullAdress[2]?.trim()?.substring(6) : "",
  //           estado: fullAdress ? fullAdress[3]?.trim() : "",
  //         });

  //         setState({
  //           markerPosition: {
  //             lat: parseFloat(event.geolocation.latitude),
  //             lng: parseFloat(event.geolocation.longitude),
  //           },
  //           mapPosition: {
  //             lat: parseFloat(event.geolocation.latitude),
  //             lng: parseFloat(event.geolocation.longitude),
  //           },
  //         });
  //       }
  //     });
  //   }, [props.selectionRows]);

  //   const center = React.useMemo(
  //     function memo() {
  //       return {
  //         lat: state.mapPosition.lat,
  //         lng: state.mapPosition.lng,
  //       };
  //     },
  //     [state.mapPosition]
  //   );

  return (
    <Dialog
      //   open={true}
      open={openDialog}
      aria-labelledby="form-dialog-title"
      onClose={handleDialog}
      disableEnforceFocus
      PaperProps={{
        style: {
          backgroundColor: "#171a1e",
          textColor: "#fsf",
          minWidth: 1145,
          width: 1145,
          height: 730,
          minHeight: 730,
          paddingTop: 15,
          zIndex: 1,
        },
      }}
    >
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 22,
              textAlign: "center",
              color: "#fff",
              fontWeight: "bold",
            }}
          >
            Evidencia fotografica
          </Typography>

          <Grid
            container
            justifyContent="flex-end"
            style={{ position: "absolute" }}
          >
            <Button onClick={handleDialog}>
              <FontAwesomeIcon
                icon={faTimes}
                style={{
                  width: "20px",
                  height: "20px",
                  color: "#fff",
                }}
              />
            </Button>
          </Grid>
        </Grid>
        <div
          style={{
            width: "100%",
            height: 1,
            background: "#707070",
            marginTop: 8,
            padding: 0,
          }}
        ></div>
      </DialogTitle>

      <DialogContent>
        <ImageGalleryComponent />
      </DialogContent>
    </Dialog>
  );
}
