/*eslint-disable*/
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Scrollbars } from "react-custom-scrollbars";
import { Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  trackVertical: {
    height: "95%",
    right: 0,
    width: "10px !important",
    cursor: "pointer",
  },
  thumbVertical: {
    backgroundColor: "#2d3034",
    height: "100%",
    right: 0,
    width: "8px !important",
    borderRadius: "8px",
  },
}));

export default function ScrollBars(props) {
  const classes = useStyles();
  let color = props?.color ? props?.color : "#2d3034";

  return (
    <Scrollbars
      renderTrackVertical={(props) => (
        <div {...props} className={classes.trackVertical} />
      )}
      renderThumbVertical={(props) => (
        <div
          {...props}
          className={classes.thumbVertical}
          style={{
            backgroundColor: color,
          }}
        />
      )}
      renderTrackHorizontal={(props) => <div />}
      renderThumbHorizontal={(props) => <div />}
      style={props.style}
    >
      {/* alignContent="center" justifyContent="center"  */}

      {props.spacing || props.padding ? (
        <Grid
          container
          spacing={props?.spacing ? props.spacing : 0}
          style={{ padding: props?.padding ? props.padding : 0 }}
        >
          {props.children}
        </Grid>
      ) : (
        props.children
      )}
    </Scrollbars>
  );
}
