/*eslint-disable*/
import { combineReducers } from "redux";
import { fileReducer } from "./fileReducer";
import { assistanceReducer } from "./assistanceReducer";
import { obrasReducer } from "./obrasReducer";
import { permissionsReducer } from "./permissionsReducer";
import { userReducer } from "./userReducer";
import { authReducer } from "./authReducer";
import { screenReducer } from "./screenReducer";
import { filtersReducer } from "./filtersReducer";
import { workingTimeReducer } from "./workingTimeReducer";
import { contractorReducer } from "./contractorReducer";
import { configReducer } from "./configReducer";

export const rootReducer = combineReducers({
  user: userReducer,
  file: fileReducer,
  assistance: assistanceReducer,
  obras: obrasReducer,
  permissions: permissionsReducer,
  auth: authReducer,
  screen: screenReducer,
  filters: filtersReducer,
  workingTime: workingTimeReducer,
  contractor: contractorReducer,
  config: configReducer,
});
