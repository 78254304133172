/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Checkbox, Typography } from "@material-ui/core";
import { Grid, TextField } from "@material-ui/core";
import { Select, FormControl, MenuItem } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faPlus } from "@fortawesome/free-solid-svg-icons";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ScrollBars from "../../common_components/ScrollBars";
import useUnitSearch from "../useUnitSearch";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: "bold",
    lineHeight: 1.35,
    textAlign: "left",
    color: "#cdcece",
    marginBottom: 5,
  },
  textFieldCustom: {
    width: "100%",
    color: "#676767",
    border: "solid 1px #c5c5c5",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
  },
  formControl: {
    width: "100%",
  },
  select: {
    width: "100%",
    height: "40px",
    borderRadius: "6px",
    backgroundColor: "#fff",
  },
  button: {
    width: "320px",
    height: "48px",
    borderRadius: "8px",
    backgroundColor: "#ac0bce",
    textTransform: "none",
  },
  typoButton: {
    fontFamily: "Roboto",
    fontSize: 21,
    lineHeight: 1.33,
    textAlign: "left",
    color: "#fff",
  },
  typoCheckbox: {
    fontFamily: "Roboto",
    fontSize: 18,
    fontWeight: "bold",
    lineHeight: 1.33,
    textAlign: "left",
    color: "#cdcece",
  },
  checkbox: {
    // padding: 2,
    color: "#484848",
    "&.Mui-checked": {
      color: "#d233ff",
    },
    "& .MuiSvgIcon-root": { fontSize: 32 },
  },
  buttonAdd: {
    width: "100%",
    height: "48px",
    borderRadius: 8,
    border: "solid 2px #707070",
    borderStyle: "dashed",
    textTransform: "none",
    marginTop: 10,
  },
  gridMargin: {
    marginBottom: 12,
  },
}));

export default function DialogNewQuestion(props) {
  const classes = useStyles();
  const { user } = useSelector((state) => state.user);
  const [questionType, setQuestionType] = useState("Opción multiple");
  //   const { books, hasMore, loading, error } = useUnitSearch(query, unitsFlag);

  const handleOpenDialog = () => {
    props.setOpenDialogNewQuestion(!props.openDialogNewQuestion);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    // setQuery({
    //   ...query,
    //   [e.target.name]: value,
    // });
  };

  // useEffect(() => {
  //   console.log(questionType);
  // }, [questionType]);

  return (
    <div>
      <Dialog
        // open={true}
        open={props.openDialogNewQuestion}
        onClose={handleOpenDialog}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            backgroundColor: "#171a1e",
            textColor: "#fsf",
            width: "483px",
            height: "777px",
            padding: "15px 10px 0px 10px ",
            borderRadius: 8,
          },
        }}
      >
        <DialogTitle>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 22,
                textAlign: "center",
                color: "#fff",
                fontWeight: "bold",
              }}
            >
              Nueva pregunta
            </Typography>

            <Grid
              container
              justifyContent="flex-end"
              style={{ position: "absolute" }}
            >
              <Button onClick={handleOpenDialog}>
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{
                    width: "20px",
                    height: "20px",
                    color: "#fff",
                  }}
                />
              </Button>
            </Grid>
          </Grid>
          <div
            style={{
              width: "100%",
              height: 1,
              background: "#707070",
              marginTop: 8,
              padding: 0,
            }}
          ></div>
        </DialogTitle>

        <DialogContent>
          <Grid
            container
            // direction="column"
            // justifyContent="flex-start"
            style={{
              height: 510,
              //   backgroundColor: "red",
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              //   style={{
              //     backgroundColor: "blue",
              //   }}
            >
              <Grid item xs={12}>
                <Typography className={classes.textFieldLetter}>
                  Tipo de pregunta
                </Typography>
                <FormControl className={classes.formControl}>
                  <Select
                    // name="gradoEstudios"
                    value={questionType}
                    onChange={(e) => setQuestionType(e.target.value)}
                    inputProps={{ "aria-label": "Without label" }}
                    className={classes.select}
                  >
                    <MenuItem value="Cuadro de texto simple">
                      &ensp;Cuadro de texto simple
                    </MenuItem>
                    <MenuItem value="Opción multiple">
                      &ensp;Opción multiple
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <ScrollBars
                style={{ height: 450, marginTop: 20 }}
                color="#212429"
              >
                {questionType == "Cuadro de texto simple" ? (
                  <Grid container>
                    <Grid item xs={12} className={classes.gridMargin}>
                      <Typography className={classes.textFieldLetter}>
                        Texto de pregunta
                      </Typography>
                      <TextField
                        placeholder="Buscar..."
                        name="Nombre"
                        size="small"
                        variant="outlined"
                        // value={query?.Nombre}
                        className={classes.textFieldCustom}
                        // onChange={handleChange}
                      />
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography className={classes.typoCheckbox}>
                        Obligatoria
                      </Typography>
                      <Checkbox
                        //   name={text}
                        checked={true}
                        //   onChange={handleChange}
                        className={classes.checkbox}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    container
                    direction="column"
                    justifyContent="space-between"
                  >
                    <Grid item xs={12} className={classes.gridMargin}>
                      <Typography className={classes.textFieldLetter}>
                        Texto de pregunta
                      </Typography>
                      <TextField
                        placeholder="Buscar..."
                        name="Nombre"
                        size="small"
                        variant="outlined"
                        // value={query?.Nombre}
                        className={classes.textFieldCustom}
                        // onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.gridMargin}>
                      <Typography className={classes.textFieldLetter}>
                        Opcion 1
                      </Typography>
                      <TextField
                        placeholder="Nombre opción"
                        name="Nombre"
                        size="small"
                        variant="outlined"
                        // value={query?.Nombre}
                        className={classes.textFieldCustom}
                        // onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.gridMargin}>
                      <Typography className={classes.textFieldLetter}>
                        Opcion 2
                      </Typography>
                      <TextField
                        placeholder="Nombre opción"
                        name="Nombre"
                        size="small"
                        variant="outlined"
                        // value={query?.Nombre}
                        className={classes.textFieldCustom}
                        // onChange={handleChange}
                      />
                    </Grid>
                    <Button className={classes.buttonAdd} onClick={() => {}}>
                      <FontAwesomeIcon
                        icon={faPlus}
                        style={{
                          width: "12px",
                          height: "12px",
                          color: "#ac0bce",
                          marginRight: "10px",
                        }}
                      />
                      <Typography
                        style={{
                          fontFamily: "Roboto",
                          fontSize: 17,
                          lineHeight: 1.35,
                          textAlign: "left",
                          color: "#e5e5e5",
                        }}
                      >
                        Nueva opción
                      </Typography>
                    </Button>

                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography className={classes.typoCheckbox}>
                        Obligatoria
                      </Typography>
                      <Checkbox
                        //   name={text}
                        checked={true}
                        //   onChange={handleChange}
                        className={classes.checkbox}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </Grid>
                  </Grid>
                )}
              </ScrollBars>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-end"
            >
              <Button variant="contained" className={classes.button}>
                <Typography className={classes.typoButton}>Guardar</Typography>
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
