/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Typography, TextField, InputAdornment } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import { useDropzone } from "react-dropzone";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    // backgroundColor: "blue",
  },
  divImg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "160px",
    height: "160px",
    borderRadius: "16px",
    border: "solid 1px #707070",
    backgroundColor: "#212429",
    cursor: "pointer",
  },
  photo: {
    width: "90%",
    height: "90%",
    borderRadius: "8px",
    aspectRatio: 1,
    // position: "absolute",
    objectFit: "contain",
  },
  icon: {
    width: "40px",
    height: "40px",
    color: "white",
    marginBottom: "20px",
  },
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: "bold",
    textAlign: "left",
    color: "#cdcece",
  },
  textFieldCustom: {
    width: "90%",
    color: "#676767",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
  },
  textFieldCustom2: {
    width: "95%",
    color: "#676767",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  formControl: {
    width: "90%",
  },
  select: {
    width: "100%",
    height: "40px",
    borderRadius: "6px",
    backgroundColor: "#fff",
  },
  gridMargin: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
}));

export default function InfoBasic(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    noKeyboard: true,
    accept: "image/*",
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      acceptedFiles.map((file) => processImage(file));
    },
    // noClick: true
  });

  const processImage = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let fileURL = window.URL.createObjectURL(file);
      props.setSubcontractorData({
        ...props.subcontractorData,
        foto: file,
        fotoCache: fileURL,
      });
      props.setChangesFlag(true);
    };
  };

  const handleChange = (e) => {
    props.setChangesFlag(true);
    const value = e.target.value;
    props.setSubcontractorData({
      ...props.subcontractorData,
      [e.target.name]: value,
    });
  };

  useEffect(() => {
    if (
      props.subcontractorData.empresa != "" &&
      props.subcontractorData.rfc != "" &&
      props.subcontractorData.unidad != ""
    ) {
      props.setMandatoryFlag(true);
    } else {
      props.setMandatoryFlag(false);
    }
  }, [
    props.subcontractorData.empresa,
    props.subcontractorData.rfc,
    props.subcontractorData.unidad,
  ]);

  return (
    <Grid container className={classes.divMain}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        className={classes.gridMargin}
      >
        <div className={classes.divImg} {...getRootProps()}>
          <input {...getInputProps()} />

          {props.subcontractorData.fotoCache ? (
            <img
              src={props.subcontractorData.fotoCache}
              className={classes.photo}
              alt="fotoCache"
            />
          ) : (
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <AddToPhotosIcon className={classes.icon} />
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 16,
                  fontWeight: 500,
                  lineHeight: "1.13",
                  textAlign: "center",
                  color: "#d9d9d9",
                  htmlFor: "fileInput",
                }}
              >
                Subir imagen
              </Typography>
            </Grid>
          )}
        </div>
      </Grid>

      <Grid
        item
        xs={12}
        style={{
          height: "30px",
        }}
      />

      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <Typography className={classes.textFieldLetter}>
          Nombre empresa
        </Typography>
        <TextField
          placeholder="Obligatorio"
          name="empresa"
          size="small"
          variant="outlined"
          value={props.subcontractorData.empresa}
          className={classes.textFieldCustom}
          onChange={handleChange}
          inputProps={{
            maxLength: 50,
            style: { textTransform: "capitalize" },
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
        xl={4}
        className={classes.gridMargin}
      >
        <Typography className={classes.textFieldLetter}>RFC</Typography>
        <TextField
          placeholder="Obligatorio"
          name="rfc"
          size="small"
          variant="outlined"
          value={props.subcontractorData.rfc.toUpperCase()}
          className={classes.textFieldCustom}
          onChange={handleChange}
          inputProps={{
            maxLength: 12,
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
        xl={4}
        className={classes.gridMargin}
      >
        <Typography className={classes.textFieldLetter}>
          Razón social
        </Typography>
        <TextField
          name="razonSocial"
          size="small"
          variant="outlined"
          value={props.subcontractorData.razonSocial}
          className={classes.textFieldCustom}
          onChange={handleChange}
        />
      </Grid>

      <Grid
        item
        xs={12}
        style={{
          height: "30px",
        }}
      ></Grid>

      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
        xl={4}
        className={classes.gridMargin}
      >
        <Typography className={classes.textFieldLetter}>
          Usuario representante
        </Typography>
        <TextField
          name="representante"
          size="small"
          variant="outlined"
          value={props.subcontractorData.representante}
          className={classes.textFieldCustom}
          onChange={handleChange}
          inputProps={{
            maxLength: 50,
            style: { textTransform: "capitalize" },
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
        xl={4}
        className={classes.gridMargin}
      >
        <Typography className={classes.textFieldLetter}>
          Teléfono de contacto
        </Typography>
        <TextField
          type="number"
          name="telefono"
          size="small"
          variant="outlined"
          value={props.subcontractorData.telefono}
          className={classes.textFieldCustom}
          onChange={handleChange}
          onInput={(e) => {
            e.target.value = e.target.value.slice(0, 10);
            // e.target.value = Math.max(0, parseInt(e.target.value))
            //   .toString()
            //   .slice(0, 10);
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
        xl={4}
        className={classes.gridMargin}
      >
        <Typography className={classes.textFieldLetter}>
          Registro Patronal IMSS
        </Typography>
        <TextField
          name="numeroSeguro"
          size="small"
          variant="outlined"
          value={props.subcontractorData.numeroSeguro.toUpperCase()}
          className={classes.textFieldCustom}
          onChange={handleChange}
          inputProps={{ maxLength: 11 }}
        />
      </Grid>

      <Grid
        item
        xs={12}
        style={{
          height: "30px",
        }}
      ></Grid>

      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
        xl={4}
        className={classes.gridMargin}
      >
        <Typography className={classes.textFieldLetter}>Unidad</Typography>
        <TextField
          name="unidad"
          size="small"
          variant="outlined"
          value={props.subcontractorData.unidad}
          className={classes.textFieldCustom}
          // onChange={handleChange}
          onClick={() => props.setOpenDialogUnits(!props.openDialogUnits)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <FontAwesomeIcon
                  icon={faCaretDown}
                  className={classes.iconForm}
                  style={{
                    width: "24px",
                    height: "24px",
                    color: "#ADADAD",
                  }}
                />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
        <Typography className={classes.textFieldLetter}>Descripcion</Typography>
        <TextField
          name="descripcion"
          size="small"
          variant="outlined"
          value={props.subcontractorData.descripcion}
          className={classes.textFieldCustom2}
          onChange={handleChange}
          inputProps={{ maxLength: 120 }}
        />
      </Grid>
    </Grid>
  );
}
