/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  TextField,
  Select,
  FormControl,
  MenuItem,
} from "@material-ui/core";
import { CapitalizeString } from "../../../utils/CapitalizeString";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
  },
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "left",
    color: "#cdcece",
  },
  textFieldCustom: {
    width: "90%",
    color: "#676767",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
  },
  textFieldCustom2: {
    width: "100%",
    color: "#676767",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  formControl: {
    width: "90%",
  },
  formControl2: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  select: {
    width: "100%",
    height: "40px",
    borderRadius: "6px",
    backgroundColor: "#fff",
  },
  gridMargin: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
  gridMargin2: {
    marginRight: "3%",
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      marginTop: 10,
    },
  },
}));

export default function InfoHealth(props) {
  const classes = useStyles();
  const [allergyFlag, setAllergyFlag] = useState(false);

  const handleChange = (e) => {
    props.setChangesFlag(true);
    const value = e.target.value;
    props.setEmployeeData({
      ...props.employeeData,
      [e.target.name]: value,
    });
    // console.log(props.employeeData);
  };

  useEffect(() => {
    // console.log(props.employeeData.alergias);
    if (props.employeeData.alergias == "Si") setAllergyFlag(true);
    else {
      setAllergyFlag(false);
      props.setEmployeeData({
        ...props.employeeData,
        tipoAlergia: "",
      });
    }
  }, [props.employeeData.alergias]);

  return (
    <Grid container className={classes.divMain}>
      <Grid
        item
        lg={12}
        xl={12}
        style={{
          height: "80px",
        }}
      />

      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          className={classes.gridMargin}
        >
          <Typography className={classes.textFieldLetter}>
            Nombre contacto emergencia
          </Typography>
          <TextField
            name="contactoEmergencia"
            size="small"
            variant="outlined"
            value={CapitalizeString(props.employeeData.contactoEmergencia)}
            className={classes.textFieldCustom}
            onChange={handleChange}
            inputProps={{
              maxLength: 50,
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          className={classes.gridMargin}
        >
          <Typography className={classes.textFieldLetter}>
            Teléfono 1 contacto emergencia
          </Typography>
          <TextField
            type="number"
            name="telefono1"
            size="small"
            variant="outlined"
            value={props.employeeData.telefono1}
            className={classes.textFieldCustom}
            onChange={handleChange}
            onInput={(e) => {
              e.target.value = e.target.value.slice(0, 10);
              // e.target.value = Math.max(0, parseInt(e.target.value))
              //   .toString()
              //   .slice(0, 10);
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          className={classes.gridMargin}
        >
          <Typography className={classes.textFieldLetter}>
            Teléfono 2 contacto emergencia
          </Typography>
          <TextField
            type="number"
            name="telefono2"
            size="small"
            variant="outlined"
            value={props.employeeData.telefono2}
            className={classes.textFieldCustom}
            onChange={handleChange}
            onInput={(e) => {
              e.target.value = e.target.value.slice(0, 10);
              // e.target.value = Math.max(0, parseInt(e.target.value))
              //   .toString()
              //   .slice(0, 10);
            }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            height: "30px",
          }}
        ></Grid>

        <Grid
          item
          xs={12}
          sm={5}
          md={3}
          lg={2}
          xl={2}
          className={classes.gridMargin2}
        >
          <Typography className={classes.textFieldLetter}>
            Tipo de sangre
          </Typography>
          <FormControl className={classes.formControl2}>
            <Select
              name="sangre"
              value={props.employeeData.sangre}
              onChange={handleChange}
              defaultValue=""
              inputProps={{ "aria-label": "Without label" }}
              className={classes.select}
            >
              <MenuItem value=" "> </MenuItem>
              <MenuItem value="A+">&ensp;A+</MenuItem>
              <MenuItem value="A-">&ensp;A-</MenuItem>
              <MenuItem value="B+">&ensp;B+</MenuItem>
              <MenuItem value="B-">&ensp;B-</MenuItem>
              <MenuItem value="AB+">&ensp;AB+</MenuItem>
              <MenuItem value="AB-">&ensp;AB-</MenuItem>
              <MenuItem value="O+">&ensp;O+</MenuItem>
              <MenuItem value="O-">&ensp;O-</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          sm={5}
          md={3}
          lg={2}
          xl={2}
          className={classes.gridMargin2}
        >
          <Typography className={classes.textFieldLetter}>Fuma</Typography>
          <FormControl className={classes.formControl2}>
            <Select
              name="fuma"
              value={props.employeeData.fuma}
              onChange={handleChange}
              defaultValue=""
              inputProps={{ "aria-label": "Without label" }}
              className={classes.select}
            >
              <MenuItem value=" "> </MenuItem>
              <MenuItem value="Si">&ensp;Si</MenuItem>
              <MenuItem value="No">&ensp;No</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          sm={5}
          md={3}
          lg={2}
          xl={2}
          className={classes.gridMargin2}
        >
          <Typography className={classes.textFieldLetter}>
            Bebe alcohol
          </Typography>
          <FormControl className={classes.formControl2}>
            <Select
              name="bebe"
              value={props.employeeData.bebe}
              onChange={handleChange}
              defaultValue=""
              inputProps={{ "aria-label": "Without label" }}
              className={classes.select}
            >
              <MenuItem value=" "> </MenuItem>
              <MenuItem value="Si">&ensp;Si</MenuItem>
              <MenuItem value="No">&ensp;No</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          sm={5}
          md={3}
          lg={2}
          xl={2}
          className={classes.gridMargin2}
        >
          <Typography className={classes.textFieldLetter}>
            Ha usado drogas
          </Typography>
          <FormControl className={classes.formControl2}>
            <Select
              name="drogas"
              value={props.employeeData.drogas}
              onChange={handleChange}
              defaultValue=""
              inputProps={{ "aria-label": "Without label" }}
              className={classes.select}
            >
              <MenuItem value=" "> </MenuItem>
              <MenuItem value="Si">&ensp;Si</MenuItem>
              <MenuItem value="No">&ensp;No</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          sm={5}
          md={3}
          lg={2}
          xl={2}
          className={classes.gridMargin2}
        >
          <Typography className={classes.textFieldLetter}>
            Actividad física
          </Typography>
          <FormControl className={classes.formControl2}>
            <Select
              name="actividadFisica"
              value={props.employeeData.actividadFisica}
              onChange={handleChange}
              defaultValue=""
              inputProps={{ "aria-label": "Without label" }}
              className={classes.select}
            >
              <MenuItem value=" "> </MenuItem>
              <MenuItem value="Si">&ensp;Si</MenuItem>
              <MenuItem value="No">&ensp;No</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            height: "30px",
          }}
        ></Grid>

        <Grid
          item
          xs={12}
          sm={5}
          md={3}
          lg={2}
          xl={2}
          className={classes.gridMargin2}
        >
          <Typography className={classes.textFieldLetter}>Peso (Kg)</Typography>
          <TextField
            type="number"
            name="peso"
            size="small"
            variant="outlined"
            value={props.employeeData.peso}
            className={classes.textFieldCustom2}
            onChange={handleChange}
            onInput={(e) => {
              e.target.value = e.target.value.slice(0, 3);
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={5}
          md={3}
          lg={2}
          xl={2}
          className={classes.gridMargin2}
        >
          <Typography className={classes.textFieldLetter}>
            Altura (cm)
          </Typography>
          <TextField
            type="number"
            name="altura"
            size="small"
            variant="outlined"
            value={props.employeeData.altura}
            className={classes.textFieldCustom2}
            onChange={handleChange}
            onInput={(e) => {
              e.target.value = e.target.value.slice(0, 3);
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
