/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme) => ({
  icon: {
    width: "24px",
    height: "24px",
    color: "#fff",
    marginRight: -3,
  },
}));

export default function NavBarListOption(props) {
  const classes = useStyles();

  return (
    <MenuItem
      style={{ width: "100%", color: "white", backgroundColor: "#2d2f33" }}
      onClick={() => props.handle()}
    >
      {typeof props.icon == "string" ? (
        <Icon className={classes.icon}>{props.icon}</Icon>
      ) : (
        <FontAwesomeIcon
          icon={props.icon}
          style={{
            width: "22px",
            height: "22px",
            color: "#fff",
            marginLeft: 2,
          }}
        />
      )}
      <Typography
        style={{
          fontFamily: "Roboto",
          fontSize: 19,
          color: "#acacac",
          fontWeight: 500,
          marginLeft: 15,
          width: "80%",
        }}
      >
        {props.option}
      </Typography>

      <FontAwesomeIcon
        icon={faAngleRight}
        style={{
          width: "15px",
          height: "24px",
          color: "#bb",
          marginLeft: 40,
        }}
      />
    </MenuItem>
  );
}
