require("dotenv").config();

// console.log("current environment REACT_APP_ENV: ", process.env.REACT_APP_ENV);

module.exports = {
  env: process.env.REACT_APP_ENV,
  api_key: process.env.REACT_APP_GOOGLE_API_KEY,
  ipServer:
    process.env.REACT_APP_ENV === "production"
      ? process.env.REACT_APP_API_PRODUCTION
      : process.env.REACT_APP_ENV === "testing"
      ? process.env.REACT_APP_API_TESTING
      : process.env.REACT_APP_API_DEVELOPMENT,
};
