/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Grid, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const useStyles = makeStyles((theme) => ({
  card: {
    width: 250,
    height: 122,
    padding: theme.spacing(1, 2, 1, 2),
    borderRadius: 7,
    backgroundColor: "#2d3034",
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 18,
    lineHeight: 1.33,
    textAlign: "left",
    color: "#fff",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxWidth: "80%",
  },
  divBackIcon: {
    width: 32,
    height: 32,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
    backgroundImage: "linear-gradient(91deg, #d233ff 1%, #ac0bce 99%)",
  },
  typoWorkersNumber: {
    fontFamily: "Roboto",
    fontSize: 26,
    fontWeight: 500,
    lineHeight: 1.31,
    textAlign: "left",
    color: "#fff",
    marginRight: 5,
  },
  typoWorkersLabel: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 1.33,
    textAlign: "left",
    color: "#ccc",
  },
}));

export default function RecognitionCard(props) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{
          marginBottom: 15,
        }}
      >
        <Typography className={classes.title}>{props.title}</Typography>

        <div className={classes.divBackIcon}>
          <FontAwesomeIcon
            icon={props.icon}
            style={{
              width: "19px",
              height: "15px",
              color: "#fff",
              borderRadius: "5px",
            }}
          />
        </div>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-end"
      >
        <Typography className={classes.typoWorkersNumber}>
          {props.number}
        </Typography>
        <Typography className={classes.typoWorkersLabel}>
          {props.active}
        </Typography>
      </Grid>
    </Card>
  );
}
