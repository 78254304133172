/*eslint-disable*/
import React, { useState, useEffect, useRef, useCallback } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { makeStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";
import CardObservations from "./Components/CardObservations";
import DialogObservations from "./Components/DialogObservations";
import DateRange from "../../../common_components/Filters/DateRange";
import useObservations from "./Components/useObservations";
import NotObservationsFound from "./Components/NotObservationsFound";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    paddingTop: 10,
    height: "100%",
  },
}));

export default function ObservationsScreen(props) {
  const classes = useStyles();
  const { user } = useSelector((state) => state.user);
  const { workingTime } = useSelector((state) => state.filters);
  const [openDialog, setOpendialog] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [flagChange, setFlagChange] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: workingTime
        ? workingTime.initialDate
        : new Date(Date.now() - 604800000),
      endDate: workingTime ? workingTime.finalDate : new Date(),
      key: "selection",
    },
  ]);
  const [query, setQuery] = useState({
    initialDate: workingTime
      ? workingTime.initialDate
      : new Date(Date.now() - 604800000),
    finalDate: workingTime ? workingTime.finalDate : new Date(),
    flagChange: false,
  });

  useEffect(() => {
    setPageNumber(0);
  }, [query]);

  const { books, hasMore, loading, error } = useObservations(query, pageNumber);

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const handleSelect = (ranges) => {
    setDateRange([ranges.selection]);
    updateData("initialDate", ranges.selection.startDate);
    updateData("finalDate", ranges.selection.endDate);
  };

  const updateData = (k, v) => {
    setQuery((prev) => ({ ...prev, [k]: v }));
  };

  const handleDialog = () => {
    setOpendialog(!openDialog);
  };

  useEffect(() => {
    if (user && user?.EnterpriseData?.Db == "GrupoGarzaPonce903228") {
      setDateRange([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        },
      ]);
    }
  }, [user]);

  return (
    <div className={classes.divMain}>
      <Grid container direction="column">
        <Grid
          item
          xs={12}
          container
          direction="row"
          alignItems="flex-end"
          style={{ marginTop: 10 }}
        >
          <Grid item>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 16,
                textAlign: "left",
                color: "#dedcdc",
              }}
            >
              Fecha
            </Typography>

            <DateRange
              value={dateRange}
              onChange={handleSelect}
              InitialWeek={true}
            />
          </Grid>

          <Grid item style={{ marginLeft: 20 }}>
            <Button
              onClick={handleDialog}
              style={{
                width: 204,
                height: "40px",
                borderRadius: "8px",
                color: "#ffffff",
                backgroundColor: "#ac0bce",
                textTransform: "none",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 18,
                  color: "#ffffff",
                }}
              >
                Agregar observación
              </Typography>
            </Button>
          </Grid>
        </Grid>

        <Grid item xs={12} container spacing={4} style={{ marginTop: 20 }}>
          {books.length == 0 && !loading ? (
            <NotObservationsFound />
          ) : (
            books.map((observations, index) => {
              if (books.length === index + 1) {
                return (
                  <Grid
                    ref={lastBookElementRef}
                    item
                    xs={12}
                    md={6}
                    lg={4}
                    xl={4}
                    sm={12}
                    key={index}
                  >
                    <CardObservations
                      subcontratista={observations?.subcontratistas[0]?.empresa}
                      fecha={observations?.fecha}
                      unidad={observations?.subcontratistas[0]?.unidad}
                      descripcion={observations?.observacion}
                    />
                  </Grid>
                );
              } else {
                return (
                  <Grid item xs={12} md={6} lg={4} xl={3} sm={12} key={index}>
                    <CardObservations
                      subcontratista={observations?.subcontratistas[0]?.empresa}
                      fecha={observations?.fecha}
                      unidad={observations?.subcontratistas[0]?.unidad}
                      descripcion={observations?.observacion}
                    />
                  </Grid>
                );
              }
            })
          )}
        </Grid>
      </Grid>

      <DialogObservations
        openDialog={openDialog}
        setOpendialog={setOpendialog}
        query={query}
        setQuery={setQuery}
      />
    </div>
  );
}
