/*eslint-disable*/
import { useEffect, useState } from "react";
import axios from "axios";
import axiosInstance from "../../../utils/axiosInstance";
import { useSelector } from "react-redux";

export default function useAssistanceSearch(
  query
  // loadingData
  // subcontratistas,
  // obras
) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    setRows([]);
  }, [query]);

  useEffect(() => {
    // if (loadingData && user) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    // let contractor = user ? user.Subcontractor[0] : null;
    // let Subcontratistas =
    //   query.Subcontratistas.length > 0
    //     ? query.Subcontratistas
    //     : !contractor.empresaPadre
    //     ? subcontratistas
    //     : [];
    // let Obras =
    //   query.Obras.length > 0
    //     ? query.Obras
    //     : !contractor.empresaPadre
    //     ? obras
    //     : [];

    var params = new URLSearchParams();
    params.append("Name_partial", query.Empleado);
    params.append("Email_partial", query.Empleado);
    params.append("page", query.page - 1);
    params.append("limit", query.pageSize);
    // params.append("_neid", user._id);

    // Subcontratistas.map((subcontratista) => {
    //   params.append("Subcontractor_id", subcontratista.value);
    // });

    // Obras.map((obra) => {
    //   params.append("Obras_id", obra.value);
    // });

    setLoading(true);
    setError(false);

    axiosInstance()
      .get("/api/v1/security/user", { params, cancelToken: source.token })
      .then((response) => {
        // console.log(response.data.data.Results);
        let result = response.data.data.Results;

        result.map((user) => {
          (user.obras = user.Obras_id ? user.Obras_id.length : 0),
            (user.id = user._id);
        });

        setRows(result);
        setTotalRows(parseInt(response.data.data.Total));
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setError(true);
        if (axios.isCancel(e)) return;
      });
    return () => source.cancel();
    // }
  }, [query, /* loadingData, */ user]);

  return { loading, error, rows, totalRows };
}
