import React, { useEffect } from "react";
import { Formik, Form, useFormikContext } from "formik";
import { Fragment } from "react";
import Yup from "./YupMethod";
import { TextFieldCustom } from "./components/TextFieldCustom";
import { Grid } from "@material-ui/core";
// import * as Yup from "yup";
const Debugging = () => {
  const { values, errors } = useFormikContext();
  useEffect(() => {
    console.log("values", values);
  }, [values]);

  useEffect(() => {
    console.log("errors", errors);
  }, [errors]);

  return null;
};

export default function DynamicForm(props) {
  const { formJson } = props;
  const initialValues = {};
  const requiredFields = {};

  for (const input of formJson) {
    initialValues[input.name] = input.value;

    if (!input.validations) continue;

    let schema = Yup.string();

    for (const rule of input.validations) {
      if (rule.type === "required") {
        schema = schema.required("Este campo es requerido");
      }

      if (rule.type === "minLength") {
        schema = schema.min(
          rule.value || 2,
          `Mínimo de ${rule.value || 2} caracteres`
        );
      }

      if (rule.type === "email") {
        schema = schema.email(`Revise el formato del email`);
      }
    }

    requiredFields[input.name] = schema;
  }

  const validationSchema = Yup.object({ ...requiredFields });

  // console.log("initialValues", initialValues);
  // console.log("requiredFields", requiredFields);

  return (
    <Fragment>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={(values) => {
          console.log(values);
        }}
      >
        {({ values, errors, touched, setValues, setFieldValue, handleChange }) => (
          <Form noValidate>
            <Debugging />

            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={3}
            >
              {formJson.map(({ type, name, placeholder, label, options }) => {
                if (
                  type === "input" ||
                  type === "password" ||
                  type === "email"
                ) {
                  return (
                    <TextFieldCustom
                      key={name}
                      type={type}
                      name={name}
                      label={label}
                      placeholder={placeholder}
                      errors={errors}
                      touched={touched}
                    />
                  );
                }

                // else if (type === "select") {
                //   return (
                //     <MySelect key={name} label={label} name={name}>
                //       <option value="">Select an option</option>
                //       {options?.map(({ id, label }) => (
                //         <option key={id} value={id}>
                //           {label}
                //         </option>
                //       ))}
                //     </MySelect>
                //   );
                // }

                throw new Error(`El type: ${type}, no es soportado`);
              })}
            </Grid>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
}
