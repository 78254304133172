/*eslint-disable*/
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Typography, Button, Box } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReportCard from "./ReportCard";
import NotReportsFound from "./NotReportsFound";
import useReportSearch from "./useReportSearch";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    // height: "100%",
    // backgroundColor: "red",
  },
  divBack: {
    cursor: "pointer",
    marginBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
  back: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 500,
    textAlign: "left",
    color: "#fff",
  },
  icon: {
    color: "white",
    marginRight: 6,
    marginTop: -2,
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 24,
    fontWeight: 500,
    textAlign: "left",
    color: "#fff",
    marginRight: 20,
  },
  button: {
    width: "160px",
    height: "40px",
    borderRadius: "8px",
    color: "#fff",
    backgroundColor: "#ac0bce",
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
}));

export default function SearchReportScreen(props) {
  const classes = useStyles();
  const history = useHistory();
  const [pageNumber, setPageNumber] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [query, setQuery] = useState({
    TrabajadoresId: "",
  });

  const { books, hasMore, loading, error } = useReportSearch(
    query,
    pageNumber,
    loadingData
  );

  const backStep = () => {
    history.push(`/dashboard/empleados/${localStorage.getItem("idEmployee")}`);
  };

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  useEffect(
    () => {
      setQuery({
        ...query,
        TrabajadoresId: localStorage.getItem("idEmployee"),
      });
    },
    [
      /* props.userId */
    ]
  );

  return (
    <div className={classes.divMain}>
      <Grid container>
        <Grid
          item
          xs={6}
          sm={4}
          md={2}
          lg={2}
          xl={2}
          container
          direction="row"
          alignItems="center"
          onClick={backStep}
          className={classes.divBack}
        >
          <Grid item>
            <FontAwesomeIcon
              icon={faArrowAltCircleLeft}
              style={{
                width: "20px",
                height: "20px",
                marginRight: "10px",
                color: "#d6d6d6",
              }}
            />
          </Grid>
          <Grid item>
            <Typography className={classes.back}>Regresar</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6} sm={8} md={10} lg={10} xl={10}></Grid>

      <Grid container direction="row" justifyContent="flex-start">
        <Typography className={classes.title}>Perfil de conducta</Typography>
        <Button
          variant="contained"
          onClick={() => {
            props.setReportData({
              ...props.reportData,
              idReport: "",
              activity: "",
              area: "",
              times: "",
              incidentsMade: [],
              remedialAction: "",
              sanction: "",
              daysSanction: "",
              riskmodification: "",
              establishedActions: "",
              complementaryActions: "",
            });
            props.setBehaviorScreen(2);
          }}
          className={classes.button}
        >
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 18,
              textAlign: "left",
              color: "#fff",
            }}
          >
            Nuevo reporte
          </Typography>
        </Button>
      </Grid>

      <Grid
        style={{
          height: "30px",
        }}
      />

      <Grid container spacing={4}>
        {books.length == 0 && !loading ? (
          <NotReportsFound />
        ) : (
          books.map((event, index) => {
            if (books.length === index + 1) {
              return (
                <Grid
                  ref={lastBookElementRef}
                  item
                  xs={12}
                  md={6}
                  lg={6}
                  xl={6}
                  sm={12}
                  key={index}
                >
                  <ReportCard
                    setBehaviorScreen={props.setBehaviorScreen}
                    setReportData={props.setReportData}
                    idReport={event._id}
                    date={event.FechaReporte}
                    activity={event.Actividad}
                    area={event.Area}
                    times={event.NoVeces}
                    incidentsMade={event.Incidencias}
                    remedialAction={event.AccionRemediadora}
                    sanction={event.Sancion}
                    daysSanction={event.DiasSancion}
                    riskmodification={event.ModIdriesgos}
                    establishedActions={event.AccionesRealizadas}
                    complementaryActions={event.AccionesComplementarias}
                  />
                </Grid>
              );
            } else {
              return (
                <Grid item xs={12} md={6} lg={6} xl={6} sm={12} key={index}>
                  <ReportCard
                    setBehaviorScreen={props.setBehaviorScreen}
                    setReportData={props.setReportData}
                    idReport={event._id}
                    date={event.FechaReporte}
                    activity={event.Actividad}
                    area={event.Area}
                    times={event.NoVeces}
                    incidentsMade={event.Incidencias}
                    remedialAction={event.AccionRemediadora}
                    sanction={event.Sancion}
                    daysSanction={event.DiasSancion}
                    riskmodification={event.ModIdriesgos}
                    establishedActions={event.AccionesRealizadas}
                    complementaryActions={event.AccionesComplementarias}
                  />
                </Grid>
              );
            }
          })
        )}
      </Grid>

      <Box
        display="flex"
        m={1}
        p={1}
        alignItems="center"
        justifyContent="center"
      >
        {loading && (
          <CircularProgress disableShrink size={100} color="secondary" />
        )}
      </Box>
    </div>
  );
}
