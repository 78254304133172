/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Typography, Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import IncidentComponentCard from "./IncidentComponentCard";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    display: "block",
    // height: "100%",
    // backgroundColor: "red",
  },
  gridPaper: {
    padding: theme.spacing(1),
    width: "100%",
    height: "250px",
    borderRadius: "16px",
    backgroundColor: "#2d3034",
    cursor: "pointer",
    "&:hover": {
      background: "#3B3D40",
      boxShadow: "0 0px 16px 0 rgba(90, 90, 90, 0.2)",
    },
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  },
  typo1: {
    fontFamily: "Roboto",
    fontSize: 12,
    color: "#fff",
    // textOverflow: "ellipsis",
    // whiteSpace: "nowrap",
    // overflow: "hidden",
  },
  typo2: {
    fontFamily: "Roboto",
    fontSize: 12,
    color: "#c1c1c1",
    // textOverflow: "ellipsis",
    // whiteSpace: "nowrap",
    // overflow: "hidden",
  },
  divCircle: {
    width: "12px",
    height: "12px",
    border: "solid 2px #8f8f8f",
    borderRadius: "50%",
    // backgroundColor: "#fff",
  },
}));

export default function ReportCard(props) {
  const classes = useStyles();

  const InfoReport = () => {
    props.setReportData({
      ...props.reportData,
      idReport: props.idReport,
    });
    props.setBehaviorScreen(2);
  };

  const renderIncidents = () => {
    const items = props.incidentsMade.map((incident, index) => {
      return <IncidentComponentCard key={index} incident={incident} />;
    });

    return items;
  };

  return (
    <div className={classes.divMain} onClick={InfoReport}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.gridPaper}
      >
        <Grid item xs container direction="column" spacing={1}>
          <Grid
            item
            xs={12}
            container
            direction="row"
            justifyContent="flex-end"
          >
            {/* <Typography className={classes.typo1}>{props.date}</Typography>
            <Button
              aria-label="more"
              id="long-button"
              aria-controls="long-menu"
              // aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              // onClick={handleClick}
            >
              <FontAwesomeIcon
                icon={faEllipsisV}
                style={{
                  width: "15px",
                  height: "15px",
                  color: "#737373",
                  position: "absolute",
                }}
              />
            </Button> */}
          </Grid>

          <Grid item xs>
            <Typography className={classes.typo1}>
              {props.times == 1 ? props.times + " vez" : props.times + " veces"}
            </Typography>

            <Grid
              item
              xs
              container
              direction="row"
              justifyContent="flex-start"
              style={{
                height: "100%",
                maxHeight: "60px",
                overflowY: "hidden",
              }}
            >
              {renderIncidents()}
            </Grid>
          </Grid>

          <Grid item xs>
            <Typography className={classes.typo2}>
              La no conformidad fue realizada mientras se realizaba la actividad
              de "{props.activity}"
            </Typography>
            <Typography className={classes.typo2}>
              En el área de "{props.area}"
            </Typography>
          </Grid>

          <Grid item xs>
            <Grid item container direction="row" justifyContent="flex-start">
              <Typography className={classes.typo1}>
                Sanción aplicada:
              </Typography>

              <Grid
                style={{
                  width: "5px",
                }}
              />

              <Typography className={classes.typo2}>
                {props.sanction}
              </Typography>
            </Grid>

            <Grid item container direction="row" justifyContent="flex-start">
              <Typography className={classes.typo1}>
                {props.daysSanction == 0
                  ? "0 días"
                  : props.daysSanction == 1
                  ? props.daysSanction + " día"
                  : props.daysSanction + " días"}
              </Typography>

              <Grid
                style={{
                  width: "5px",
                }}
              />

              <Typography className={classes.typo2}>de sancíon</Typography>
            </Grid>
          </Grid>

          <Grid item xs>
            <Grid item container direction="row" justifyContent="flex-start">
              <Typography className={classes.typo2}>
                Firmas generación de reporte
              </Typography>

              <Grid
                style={{
                  width: "10px",
                }}
              />

              {/* <FontAwesomeIcon
                icon={faCheckCircle}
                style={{
                  width: "12px",
                  height: "12px",
                  color: "#ac0bce",
                }}
              /> */}
              <div className={classes.divCircle} />

              <Grid
                style={{
                  width: "5px",
                }}
              />

              {/* <FontAwesomeIcon
                icon={faCheckCircle}
                style={{
                  width: "12px",
                  height: "12px",
                  color: "#ac0bce",
                }}
              /> */}
              <div className={classes.divCircle} />

              <Grid
                style={{
                  width: "5px",
                }}
              />

              <div className={classes.divCircle} />

              <Grid
                style={{
                  width: "5px",
                }}
              />

              <div className={classes.divCircle} />
            </Grid>

            <Grid item container direction="row" justifyContent="flex-start">
              <Typography className={classes.typo2}>
                Firmas de VoBo acciones remediadoras
              </Typography>

              <Grid
                style={{
                  width: "10px",
                }}
              />

              {/* <FontAwesomeIcon
                icon={faCheckCircle}
                style={{
                  width: "12px",
                  height: "12px",
                  color: "#ac0bce",
                }}
              /> */}
              <div className={classes.divCircle} />

              <Grid
                style={{
                  width: "5px",
                }}
              />

              {/* <FontAwesomeIcon
                icon={faCheckCircle}
                style={{
                  width: "12px",
                  height: "12px",
                  color: "#ac0bce",
                }}
              /> */}
              <div className={classes.divCircle} />

              <Grid
                style={{
                  width: "5px",
                }}
              />

              <div className={classes.divCircle} />

              <Grid
                style={{
                  width: "5px",
                }}
              />

              <div className={classes.divCircle} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
