/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { Grid, Typography, TextField, Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles } from "@material-ui/styles";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import InputAdornment from "@material-ui/core/InputAdornment";
import useUserSearch from "./Components/useUserSearch";
import MultiSelect from "../../common_components/Filters/MutiSelect";
import DataGridUsers from "./Components/DataGridUsers";
import DialogInviteUser from "./Components/DialogInviteUser";
import DialogEditUser from "./Components/DialogEditUser";
import {
  ValidatePermissions,
  ValidatePermissionsModule,
} from "../../utils/permissions";
import { useSelector } from "react-redux";
import axiosInstance from "../../utils/axiosInstance";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    height: "100%",
    paddingLeft: 15,
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 32,
    textAlign: "left",
    color: theme.palette.secondary.dark,
    fontWeight: 500,
    marginBottom: 30,
  },
  typoLabel: {
    fontFamily: "Roboto",
    fontSize: 16,
    textAlign: "left",
    color: "#dedcdc",
  },
  textFieldCustom: {
    width: "100%",
    height: 40,
    background: "white",
    borderRadius: 8,
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
  button: {
    width: "225px",
    height: 40,
    borderRadius: 8,
    backgroundColor: theme.palette.primary.main,
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
  typoButton: {
    fontFamily: "Roboto",
    fontSize: 18,
    textAlign: "left",
    color: theme.palette.neutral.primary,
  },
  button2: {
    width: "230px",
    height: 40,
    borderRadius: 8,
    backgroundColor: theme.palette.primary.main,
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
  typoButton2: {
    fontFamily: "Roboto",
    fontSize: 17,
    textAlign: "left",
    color: theme.palette.neutral.primary,
  },
  gridMargin: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
}));

export default function UsersScreen(props) {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useSelector((state) => state.user);
  const [subcontratistas, setSubcontratistas] = useState(null);
  const [obras, setObras] = useState(null);
  const [openDialog, setOpendialog] = useState(false);
  const [openDialogEdit, setOpendialogEdit] = useState(false);
  const [complete, setComplete] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const permissionsUpdate = ValidatePermissions("editar");
  const [selectionRows, setSelectionRows] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const [query, setQuery] = useState({
    Empleado: "",
    // Subcontratistas: [],
    // Obras: [],
    pageSize: 10,
    rowsPerPageOptions: [5, 10, 15],
    page: 1,
  });

  const { rows, totalRows, loading, error } = useUserSearch(
    query
    // loadingData
    // subcontratistas,
    // obras
  );

  const handleQueryChange = (queryElement) => (e) => {
    // const value =
    //   queryElement === "Subcontratistas" || queryElement === "Obras"
    //     ? e
    //     : e.target.value;
    return setQuery((prevQuery) => {
      return { ...prevQuery, ...{ [queryElement]: e.target.value } };
    });
  };

  const updateData = (k, v) => {
    setQuery((prev) => ({ ...prev, [k]: v }));
  };

  const handleInvite = () => {
    setOpendialog(!openDialog);
  };

  const getProyectsList = async (contractor) => {
    try {
      var params = new URLSearchParams();
      params.append("sortMongoAsc", "proyecto");

      return await axiosInstance()
        .get(`/api/v1/rules/subcontratistas/${contractor?._id}/proyectos`, {
          params,
        })
        .then((response) => {
          let proyects = response.data.data.map((object) => {
            return { label: object.proyecto, value: object._id };
          });

          setObras(proyects);
          return proyects;
        })
        .catch((e) => {
          console.log(e);
          return false;
        });
    } catch (error) {
      return false;
    }
  };

  const getSubcontractorList = async (contractor) => {
    try {
      var params = new URLSearchParams();
      params.append("showoculta", true);
      params.append("sortMongoAsc", "empresa");

      return await axiosInstance()
        .get("/api/v1/rules/subcontratistas", { params })
        .then((response) => {
          let empresa = response.data.data.map((object) => {
            return { label: object.empresa, value: object._id };
          });

          if (!contractor.empresaPadre) {
            empresa = empresa.filter(
              (empresa) => empresa.value === contractor?._id
            );
          }

          setSubcontratistas(empresa);
          return empresa;
        })
        .catch((e) => {
          console.log(e);
          return false;
        });
    } catch (error) {
      return false;
    }
  };

  // useEffect(() => {
  //   getSubcontractorList(contractor);
  //   getProyectsList(contractor);
  // }, [user]);

  useEffect(() => {
    if (openDialog) {
      setOpendialogEdit(false);
    }
  }, [openDialogEdit]);

  return (
    <div className={classes.divMain}>
      <Grid container direction="column">
        <Typography className={classes.title}>
          Administración de usuarios
        </Typography>

        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-end"
        >
          {/* <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
            <Typography className={classes.typoLabel}>Obras</Typography>
            <MultiSelect
              options={obras ? obras : []}
              value={query.Obras}
              onChange={handleQueryChange("Obras")}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
            <Typography className={classes.typoLabel}>
              Subcontratista
            </Typography>
            <MultiSelect
              options={subcontratistas ? subcontratistas : []}
              value={query.Subcontratistas}
              onChange={handleQueryChange("Subcontratistas")}
            />
          </Grid> */}

          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={3}
            xl={3}
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-end"
          >
            <TextField
              variant="outlined"
              size="small"
              placeholder="Buscar usuario"
              // onChange={handleQueryChange("Empleado")}
              className={classes.textFieldCustom}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon
                      icon={faSearch}
                      style={{
                        width: "18px",
                        height: "18px",
                        color: "#676767",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            lg={2}
            xl={2}
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-end"
          >
            {ValidatePermissions("crear") && (
              <Button
                variant="contained"
                onClick={handleInvite}
                className={classes.button}
              >
                <Typography className={classes.typoButton}>
                  Invitar usuario
                </Typography>
              </Button>
            )}
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={5}
            lg={3}
            xl={3}
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-end"
          >
            {ValidatePermissionsModule("habilitar", "roles") && (
              <Button
                variant="contained"
                onClick={() => history.push(`/dashboard/usuarios/roles`)}
                className={classes.button2}
              >
                <Typography className={classes.typoButton2}>
                  Administración de roles
                </Typography>
              </Button>
            )}
          </Grid>
        </Grid>

        <Grid container>
          <DataGridUsers
            setOpendialog={setOpendialog}
            setOpendialogEdit={setOpendialogEdit}
            setComplete={setComplete}
            rows={rows ? rows : []}
            loading={loading}
            rowCount={totalRows}
            rowsPerPageOptions={query.rowsPerPageOptions}
            page={query.page}
            pageSize={query.pageSize}
            selectionModel={selectionRows}
            onSelectionModelChange={(ids) => {
              setSelectionRows(ids);
              if (permissionsUpdate) {
                setSelectedUser(rows.find((row) => row.id == ids[0]));
                setOpendialogEdit(true);
              }
            }}
            onPageChange={(data) => {
              updateData("page", data + 1);
            }}
            onPageSizeChange={(data) => {
              updateData("page", 1);
              updateData("pageSize", data);
            }}
            style={{ height: 680, width: "100%", display: "flex" }}
          />
        </Grid>
      </Grid>

      {/* <DialogEditUser
        setOpendialog={setOpendialogEdit}
        openDialog={openDialogEdit}
        data={selectedUser}
        setData={setSelectedUser}
        setSelectionRows={setSelectionRows}
      /> */}
      <DialogInviteUser
        setOpendialog={setOpendialog}
        openDialog={openDialog}
        complete={complete}
        data={selectedUser}
        setData={setSelectedUser}
        setComplete={setComplete}
      />
    </div>
  );
}
