/*eslint-disable*/
import React, { useState, useEffect } from "react";
///import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, TextField, Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileUpload,
  faFileAlt,
  faFileDownload,
  faTimes,
  faEllipsisV,
  faHistory,
  faUserTimes,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import AddNewFirm from "./addNewFirm";

const img = `https://upload.wikimedia.org/wikipedia/commons/c/c1/Lionel_Messi_20180626.jpg`;

const useStyles = makeStyles((theme) => ({
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: "bold",
    textAlign: "left",
    color: "#cdcece",
  },
  textFieldLetter2: {
    fontFamily: "Roboto",
    fontSize: 15,
    fontWeight: "bold",
    textAlign: "left",
    color: "#cdcece",
  },
  textFieldCustom: {
    width: "90%",
    color: "#676767",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
  },
  photo: {
    maxWidth: "60px",
    maxHeight: "60px",
    borderRadius: "16px",
    aspectRatio: 1,
    objectFit: "cover",
    marginBottom: "7px",
  },
}));

export default function InfoSigns(props) {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [predefinedFirms, setPredefinedFirms] = useState([
    "Asignar firma",
    "Asignar firma",
    "Asignar firma",
  ]);

  ///const { firms } = useSelector((state) => state.file);

  const firmsFilter = [
    // ...new Map(firms.map((item) => [item["name"], item])).values(),
  ];

  function difference(a1, a2) {
    return a1.filter((o1) => a2.filter((o2) => o2 === o1.name).length === 0);
  }

  /*return (
    <div>
      <Grid
        container
        direction="row"
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        <Grid style={{ marginRight: "61px" }}>
          <Grid
            item
            xs={12}
            style={{
              height: "35px",
            }}
          ></Grid>

          <Grid>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              style={{
                width: "200px",
                height: "200px",
                borderRadius: "8px",
                backgroundColor: "#2d3034",
                marginBottom: "14px",
              }}
            >
              <FontAwesomeIcon
                icon={faUserTimes}
                style={{
                  width: "37.5px",
                  height: "30px",
                  color: "#9a9a9a",
                  marginBottom: "22px",
                }}
              />
              <Typography
                style={{
                  fontSize: 14,
                  fontWeight: 500,
                  lineHeight: 1.29,
                  textAlign: "center",
                  color: "#9a9a9a",
                  marginBottom: "46px",
                }}
              >
                Firma sin asignación
              </Typography>

              <Button
                style={{
                  width: "127px",
                  height: "32px",
                  borderRadius: "8px",
                  backgroundColor: "#ac0bce",
                  textTransform: "none",
                }}
                onClick={() =>
                  props.setOpenDialogChooseSign(!props.openDialogChooseSign)
                }
              >
                <Typography
                  style={{
                    fontSize: "16px",
                    lineHeight: 1.31,
                    textAlign: "left",
                    color: "#fff",
                  }}
                >
                  Asignar firma
                </Typography>
              </Button>
            </Grid>

            <Typography
              style={{
                fontSize: "18px",
                fontWeight: 500,
                lineHeight: 1,
                textAlign: "center",
                color: "#fff",
              }}
            >
              Firma {"1"}
            </Typography>
          </Grid>
        </Grid>
        {/// ------------------------------------------------------------ }
        <Grid style={{ marginRight: "61px" }}>
          <Grid
            item
            xs={12}
            style={{
              height: "35px",
            }}
          ></Grid>

          <Grid>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              style={{
                width: "200px",
                height: "200px",
                borderRadius: "8px",
                backgroundColor: "#2d3034",
                marginBottom: "14px",
              }}
            >
              <FontAwesomeIcon
                icon={faUserCircle}
                style={{
                  width: "37.5px",
                  height: "30px",
                  color: "#9a9a9a",
                  marginBottom: "14.1px",
                }}
              />
              <Typography
                style={{
                  fontSize: 14,
                  fontWeight: 500,
                  lineHeight: 1.29,
                  textAlign: "center",
                  color: "#9a9a9a",
                  marginBottom: "10px",
                }}
              >
                Firma asignada
              </Typography>

              <Typography
                style={{
                  fontSize: "12px",
                  fontWeight: 500,
                  lineHeight: 1.5,
                  textAlign: "center",
                  color: "#ececec",
                }}
              >
                Javier Medina
              </Typography>

              <Typography
                style={{
                  fontSize: "12px",
                  lineHeight: 1.5,
                  textAlign: "center",
                  color: "#b1b1b1",
                  marginBottom: "17px",
                }}
              >
                javier.medina@knesysplus.com
              </Typography>

              <Button
                style={{
                  width: "138px",
                  height: "32px",
                  borderRadius: "8px",
                  backgroundColor: "#ac0bce",
                  textTransform: "none",
                }}
              >
                <Typography
                  style={{
                    fontSize: "14px",
                    lineHeight: 1.36,
                    textAlign: "left",
                    color: "#fff",
                  }}
                >
                  Editar asignación
                </Typography>
              </Button>
            </Grid>

            <Typography
              style={{
                fontSize: "18px",
                fontWeight: 500,
                lineHeight: 1,
                textAlign: "center",
                color: "#fff",
              }}
            >
              Firma {"2"}
            </Typography>
          </Grid>
        </Grid>
        {////---------------------------------------------- }
          {}
        <Grid style={{ marginRight: "61px" }}>
          <Grid
            item
            xs={12}
            style={{
              height: "35px",
            }}
          ></Grid>

          <Grid>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              style={{
                width: "200px",
                height: "200px",
                borderRadius: "8px",
                backgroundColor: "#2d3034",
                marginBottom: "14px",
              }}
            >
              <img src={img} className={classes.photo} alt="photo" />
              <Typography
                style={{
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: 1.29,
                  textAlign: "center",
                  color: "#9a9a9a",
                  marginBottom: "7px",
                }}
              >
                Firma generada
              </Typography>
              <Typography
                style={{
                  fontSize: "12px",
                  fontWeight: 500,
                  lineHeight: 1.5,
                  textAlign: "center",
                  color: "#ececec",
                }}
              >
                Javier Medina
              </Typography>
              <Typography
                style={{
                  fontSize: "12px",
                  lineHeight: 1.5,
                  textAlign: "center",
                  color: "#b1b1b1",
                  marginBottom: "19px",
                }}
              >
                javier.medina@knesysplus.com
              </Typography>
              <Button
                style={{
                  width: "138px",
                  height: "32px",
                  borderRadius: "8px",
                  backgroundColor: "#ac0bce",
                  textTransform: "none",
                }}
                onClick={() =>
                  props.setOpenDialogGeneratedSignature(
                    !props.openDialogGeneratedSignature
                  )
                }
              >
                <Typography
                  style={{
                    fontSize: "18px",
                    lineHeight: 1.33,
                    textAlign: "left",
                    color: "#fff",
                  }}
                >
                  Ver firma
                </Typography>
              </Button>
            </Grid>

            <Typography
              style={{
                fontSize: "18px",
                fontWeight: 500,
                lineHeight: 1,
                textAlign: "center",
                color: "#fff",
              }}
            >
              Firma {"3"}
            </Typography>
          </Grid>
        </Grid>
        {///-------------------- --------------------------------------------------}
        <Grid style={{ marginRight: "61px" }}>
          <Grid
            item
            xs={12}
            style={{
              height: "35px",
            }}
          ></Grid>

          <Grid>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              style={{
                width: "200px",
                height: "200px",
                borderRadius: "8px",
                backgroundColor: "#2d3034",
                marginBottom: "14px",
              }}
            >
              <FontAwesomeIcon
                icon={faUserCircle}
                style={{
                  width: "34.9px",
                  height: "34.9px",
                  color: "#9a9a9a",
                  marginBottom: "13.8px",
                }}
              />
              <Typography
                style={{
                  marginBottom: "10px",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: 1.29,
                  textAlign: "center",
                  color: "#9a9a9a",
                }}
              >
                Firma asignada
              </Typography>
              <Typography
                style={{
                  fontSize: "12px",
                  fontWeight: 500,
                  lineHeight: 1.5,
                  textAlign: "center",
                  color: "#ececec",
                }}
              >
                Javier Medina
              </Typography>
              <Typography
                style={{
                  marginBottom: "12.8px",
                  fontSize: "12px",
                  lineHeight: 1.5,
                  textAlign: "center",
                  color: "#b1b1b1",
                }}
              >
                Software Enginner
              </Typography>

              <Button
                style={{
                  width: "127px",
                  height: "32px",
                  borderRadius: "8px",
                  backgroundColor: "#ac0bce",
                  textTransform: "none",
                }}
              >
                <Typography
                  style={{
                    fontSize: "14px",
                    lineHeight: 1.36,
                    textAlign: "left",
                    color: "#fff",
                  }}
                >
                  Editar asignación
                </Typography>
              </Button>
            </Grid>

            <Typography
              style={{
                fontSize: "18px",
                fontWeight: 500,
                lineHeight: 1,
                textAlign: "center",
                color: "#fff",
              }}
            >
              Firma {"4"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );*/
  return (
    <div>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        style={{
          paddingRight: 38,
          marginBottom: 25,
        }}
      >
        <AddNewFirm
          predefinedFirms={predefinedFirms}
          setPredefinedFirms={setPredefinedFirms}
        />
      </Grid>
      <Grid
        container
        direction="row"
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        {predefinedFirms.map((predefined, index) => (
          <Grid key={index} style={{ marginRight: "61px" }}>
            <Grid
              item
              xs={12}
              style={{
                height: "35px",
              }}
            ></Grid>

            <Grid>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{
                  width: "200px",
                  height: "200px",
                  borderRadius: "8px",
                  backgroundColor: "#2d3034",
                  marginBottom: "14px",
                }}
              >
                <FontAwesomeIcon
                  icon={faUserTimes}
                  style={{
                    width: "37.5px",
                    height: "30px",
                    color: "#9a9a9a",
                    marginBottom: "22px",
                  }}
                />
                <Typography
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                    lineHeight: 1.29,
                    textAlign: "center",
                    color: "#9a9a9a",
                    marginBottom: "46px",
                  }}
                >
                  Firma sin asignación
                </Typography>

                <Button
                  style={{
                    width: "127px",
                    height: "32px",
                    borderRadius: "8px",
                    backgroundColor: "#ac0bce",
                    textTransform: "none",
                  }}
                  onClick={() =>
                    props.setOpenDialogChooseSign(!props.openDialogChooseSign)
                  }
                >
                  <Typography
                    style={{
                      fontSize: "16px",
                      lineHeight: 1.31,
                      textAlign: "left",
                      color: "#fff",
                    }}
                  >
                    {predefined}
                  </Typography>
                </Button>
              </Grid>

              <Typography
                style={{
                  fontSize: "18px",
                  fontWeight: 500,
                  lineHeight: 1,
                  textAlign: "center",
                  color: "#fff",
                }}
              >
                Firma {index + 1}
              </Typography>
            </Grid>
          </Grid>
        ))}
        {/*firms &&
          difference(firmsFilter, predefinedFirms).map((firm, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              xl={3}
              key={index + predefinedDocuments.length}
            >
              <FontAwesomeIcon
                icon={faUserTimes}
                style={{
                  width: "37.5px",
                  height: "30px",
                  color: "#9a9a9a",
                  marginBottom: "22px",
                }}
              />
              {firm.name}
            </Grid>
              ))*/}
      </Grid>
    </div>
  );
}
