/*eslint-disable*/
import React, { Fragment } from "react";
import { Card, Typography, Grid, makeStyles } from "@material-ui/core";
import svgSalida from "../../../../images/salida_icono.svg";

const useStyles = makeStyles((theme) => ({
  divMain: {
    maxWidth: 345,
  },
  card: {
    width: 393,
    height: 80,
    borderRadius: 8,
    backgroundColor: "#4f5257",
    padding: 8,
  },
  griCard: {
    padding: theme.spacing(2),
  },
  noWrap: {
    textOverflow: "ellipsis",
    wordWrap: "anywhere",
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": 3,
    "-webkit-box-orient": "vertical",
  },
  button: {
    cursor: "pointer",
    padding: 8,
    "&:hover": {
      backgroundColor: "#4f5257",
    },
  },
}));

export default function CardHistory(props) {
  const classes = useStyles();
  const { date, user, type, status } = props;

  return (
    <Fragment>
      <Card className={classes.card}>
        <Grid
          container
          direction="row"
          alignItems="center"
          style={{ marginLeft: 8 }}
        >
          <Grid item container direction="column" style={{ width: "15%" }}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 20,
                textAlign: "left",
                color: "#fff",
                fontWeight: "bold",
                lineHeight: 1.3,
              }}
            >
              {new Date(date).toLocaleTimeString(navigator.language, {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </Typography>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 12,
                textAlign: "left",
                color: "#bcbcbc",
                lineHeight: 1.3,
              }}
            >
              {new Date(date)
                .toLocaleDateString(navigator.language, {
                  day: "2-digit",
                  month: "short",
                })
                .replace("-", " ")}
            </Typography>
          </Grid>

          <Grid
            item
            container
            direction="row"
            justifyContent="center"
            style={{ width: "8%" }}
          >
            <div
              style={{
                width: 4,
                height: 66,
                borderRadius: 4,
                backgroundColor: "#d233ff",
              }}
            />
          </Grid>

          <Grid container direction="column" style={{ width: "70%" }}>
            <Grid>
              <Grid container direction="row">
                <img
                  src={svgSalida}
                  style={{
                    width: 14,
                    height: 16,
                  }}
                />

                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 14,
                    textAlign: "left",
                    color: "#fff",
                    lineHeight: 1.3,
                    fontWeight: "bold",
                    marginLeft: 6,
                  }}
                >
                  {type}
                </Typography>
              </Grid>
              {status == "manual assistance" && (
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 10,
                    textAlign: "left",
                    color: "#bcbcbc",
                    lineHeight: 1.3,
                  }}
                >
                  Asistencia manual
                </Typography>
              )}
            </Grid>

            <Grid style={{ marginTop: 5 }}>
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 12,
                  textAlign: "left",
                  color: "#fff",
                  lineHeight: 1.3,
                  fontWeight: "bold",
                }}
              >
                Asistencia tomada por
              </Typography>

              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 12,
                  textAlign: "left",
                  color: "#bcbcbc",
                  lineHeight: 1.3,
                }}
              >
                {user}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Fragment>
  );
}
