/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import svgTrabajador from "../../../images/usuario_trabajador.svg";
import { ValidatePermissions } from "../../../utils/permissions";
import { useDispatch, useSelector } from "react-redux";
import { FiltersEmployees } from "../../../actions/filters";

const useStyles = makeStyles((theme) => ({
  gridPaper: {
    height: "auto",
    borderRadius: "16px",
    backgroundColor: "#2d3034",
    padding: theme.spacing(2, 3, 2, 3),
    // [theme.breakpoints.down("sm")]: {
    //   height: "auto",
    // },
  },
  gridMargin: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
  gridMargin2: {
    paddingLeft: 10,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 20,
    },
  },
}));

export default function CardEnterpriseEmployee(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { employees } = useSelector((state) => state.filters);

  const handleEdit = () => {
    dispatch(
      FiltersEmployees({
        ...employees,
        Subcontratistas: [
          {
            label: props.enterprise,
            value: props.id,
          },
        ],
      })
    );
    history.push(`/dashboard/empleados`);
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      className={classes.gridPaper}
    >
      <Grid
        item
        xs={1}
        // sm={4}
        // md={4}
        // lg={4}
        // xl={4}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.gridMargin}
        style={{
          height: "90px",
        }}
      >
        <img
          src={svgTrabajador}
          alt="techton logo"
          style={{
            width: "20px",
            height: "20px",
          }}
        />
      </Grid>

      <Grid
        item
        xs={9}
        // sm={8}
        // md={8}
        // lg={8}
        // xl={8}
        className={classes.gridMargin2}
      >
        <Grid container direction="column" justifyContent="center" spacing={1}>
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 21,
              lineHeight: 1.33,
              color: "#fff",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            Mis empleados
          </Typography>

          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 24,
              lineHeight: 1.33,
              color: "#fff",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {props.employees}
          </Typography>

          <Grid container direction="row" justifyContent="flex-start">
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 14,
                lineHeight: 1.36,
                color: "#fff",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                marginRight: 10,
              }}
            >
              {props.active + " activos"}
            </Typography>

            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 14,
                lineHeight: 1.36,
                color: "#fff",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                marginRight: 10,
              }}
            >
              {props.inactive + " inactivos"}
            </Typography>

            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 14,
                lineHeight: 1.36,
                color: "#fff",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                marginRight: 10,
              }}
            >
              {props.bulletin + " boletinados"}
            </Typography>

            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 14,
                lineHeight: 1.36,
                color: "#fff",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {props.veto + " vetados"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {ValidatePermissions("editar") ? (
        <Grid
          item
          xs={2}
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-start"
          style={{
            height: "90px",
          }}
        >
          <Typography
            onClick={handleEdit}
            style={{
              fontFamily: "Roboto",
              fontSize: 18,
              fontWeight: 500,
              color: "#d233ff",
              cursor: "pointer",
            }}
          >
            Ver
          </Typography>
        </Grid>
      ) : (
        <div></div>
      )}
    </Grid>
  );
}
