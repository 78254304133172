/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import LinearProgress from "@material-ui/core/LinearProgress";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import svgTrabajador from "../../../images/usuario_trabajador.svg";
import svgMedkit from "../../../images/icono_info_medica.svg";
import { faImage, faAddressBook } from "@fortawesome/free-solid-svg-icons";
import Typo from "../../../common_components/typo";

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  divMain: {
    maxWidth: 345,
  },
  card: {
    maxWidth: "100%",
    minHeight: "270px",
    borderRadius: "12px",
    cursor: "pointer",
    backgroundColor: "#2d3034",
    "&:hover": {
      background: "#3B3D40",
      boxShadow: "0 0px 16px 0 rgba(90, 90, 90, 0.2)",
    },
  },
  griCard: {
    padding: theme.spacing(3),
  },
  divPhoto: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "56px",
    height: "56px",
    borderRadius: "8px",
    backgroundColor: "#a5a5a5",
  },
  photo: {
    width: "56px",
    height: "56px",
    borderRadius: "4px",
    aspectRatio: 1,
    objectFit: "contain",
  },
  root: {
    width: "60%",
    borderRadius: "20px",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
  input: {
    display: "none",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
}));

export default function CardSubcontractor(props) {
  const classes = useStyles();
  const history = useHistory();

  const detailEmployee = () => {
    localStorage.setItem("idSubcontractor", props.idSubcontractor);
    history.push(`/dashboard/contratistas/${props.idSubcontractor}`);
  };

  return (
    <div onClick={detailEmployee}>
      <Card className={classes.card}>
        <Grid className={classes.griCard}>
          <Grid container direction="row" justifyContent="flex-start">
            <Grid item xs={2}>
              {props.urlPhoto == "" || props.urlPhoto == null ? (
                <div className={classes.divPhoto}>
                  <FontAwesomeIcon
                    icon={faImage}
                    style={{
                      width: "36px",
                      height: "28px",
                      color: "#2d3034",
                      borderRadius: "8px",
                    }}
                  />
                </div>
              ) : (
                <img
                  src={props.urlPhoto}
                  className={classes.photo}
                  alt="photo"
                />
              )}
            </Grid>
            <Grid item xs={10} style={{ paddingLeft: 20 }}>
              <Grid container direction="column">
                <Typo
                  fontSize={18}
                  fontWeight={500}
                  color={"#fff"}
                  text={props.enterprise}
                  textOverflow={"ellipsis"}
                  whiteSpace={"nowrap"}
                  overflow={"hidden"}
                  maxWidth={"90%"}
                />

                {props.businessName == "" || props.businessName == null ? (
                  <Typo fontSize={16} color={"#b2b2b2"} text={"--"} />
                ) : (
                  <Typo
                    fontSize={16}
                    color={"#b2b2b2"}
                    text={props.businessName}
                    textOverflow={"ellipsis"}
                    whiteSpace={"nowrap"}
                    overflow={"hidden"}
                    maxWidth={"90%"}
                  />
                )}

                {props.unit == "" || props.unit == null ? (
                  <div
                    style={{
                      height: "13px",
                      width: "94%",
                      backgroundColor: "#525151",
                      marginTop: 5,
                      marginLeft: 15,
                    }}
                  />
                ) : (
                  <Typo
                    fontSize={16}
                    color={"#b2b2b2"}
                    text={props.unit}
                    textOverflow={"ellipsis"}
                    whiteSpace={"nowrap"}
                    overflow={"hidden"}
                    maxWidth={"90%"}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>

          {/* {props.status == "" || props.status == null ? (
            <div
              style={{
                height: "13px",
                width: "50%",
                backgroundColor: "#525151",
                marginTop: 20,
                // marginLeft: 15,
              }}
            />
          ) : (
            <Grid container direction="row">
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{
                  width: "14px",
                  height: "18px",
                  color: "#d233ff",
                  marginTop: 12,
                }}
              />
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 14,
                  textAlign: "left",
                  color: "#fff",
                  marginTop: 12,
                  marginLeft: 7,
                }}
              >
                {props.status}
              </Typography>
            </Grid>
          )} */}

          {props.description == "" || props.description == null ? (
            <div
              style={{
                //   height: "12px",
                //   width: "100%",
                //   backgroundColor: "#525151",
                marginTop: 25,
              }}
            />
          ) : (
            // <Typo
            //   fontSize={12}
            //   color={"#fff"}
            //   text={props.description}
            //   display={"-webkit-box"}
            //   WebkitBoxOrient={"vertical"}
            //   WebkitLineClamp={2}
            //   textOverflow={"ellipsis"}
            //   whiteSpace={"nowrap"}
            //   overflow={"hidden"}
            //   maxWidth={"90%"}
            // />
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 12,
                color: "#fff",
                marginTop: 15,
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "98%",
              }}
            >
              {props.description}
            </Typography>
          )}

          <Grid
            container
            style={{
              marginTop: 10,
            }}
          >
            <Grid item xs={6}>
              <Typo
                fontSize={16}
                color={"#b2b2b2"}
                text={props.rfc}
                textOverflow={"ellipsis"}
                whiteSpace={"nowrap"}
                overflow={"hidden"}
                maxWidth={"90%"}
              />
            </Grid>

            <Grid item xs={6} container direction="row">
              <Grid item xs={1}>
                <img
                  src={svgTrabajador}
                  style={{
                    width: "12px",
                    height: "12px",
                  }}
                  alt="techton logo"
                />
              </Grid>
              <Grid item xs>
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 14,
                    color: "#fff",
                    marginLeft: 5,
                  }}
                >
                  {props.employees == 1
                    ? props.employees + " Trabajador"
                    : props.employees == ""
                    ? "0 Trabajadores"
                    : props.employees + " Trabajadores"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid container direction="row">
            <img
              src={svgMedkit}
              style={{
                width: 13,
                height: 13,
              }}
              alt="techton logo"
            />
            {/* {props.securityNumber == "" || props.securityNumber == null ? (
              <Typo fontSize={10} color={"#fff"} text={"--"} />
            ) : ( */}
            <Typo
              fontSize={10}
              color={"#ffff"}
              text={props.securityNumber}
              textOverflow={"ellipsis"}
              whiteSpace={"nowrap"}
              overflow={"hidden"}
              maxWidth={"40%"}
              marginLeft={4}
            />
            {/* )} */}
          </Grid>

          {props.agent == "" ||
          (props.agent == null && props.telephone == "") ||
          props.telephone == null ? (
            <Grid
              container
              direction="row"
              alignItems="center"
              style={{
                marginTop: 19,
                marginBottom: 18,
              }}
            >
              <FontAwesomeIcon
                icon={faAddressBook}
                style={{
                  width: "15px",
                  height: "17px",
                  color: "#888",
                }}
              />
              <Typo
                fontSize={16}
                color={"#b2b2b2"}
                text={"Sin registro de contacto"}
                textOverflow={"ellipsis"}
                whiteSpace={"nowrap"}
                overflow={"hidden"}
                maxWidth={"80%"}
                marginLeft={5}
              />
            </Grid>
          ) : props.agent != "" && props.telephone == "" ? (
            <Grid container direction="column">
              <Typo
                fontSize={14}
                fontWeight={500}
                color={"#fff"}
                text={props.agent}
                textOverflow={"ellipsis"}
                whiteSpace={"nowrap"}
                overflow={"hidden"}
                maxWidth={"80%"}
                marginTop={19}
              />
              <Typo fontSize={14} color={"#fff"} text={"Tel. --"} />
            </Grid>
          ) : props.telephone != "" && props.agent == "" ? (
            <Grid container direction="column">
              <Typo fontSize={14} color={"#fff"} text={"--"} marginTop={19} />
              <Typo
                fontSize={14}
                fontWeight={500}
                color={"#fff"}
                text={"Tel. " + props.telephone}
                textOverflow={"ellipsis"}
                whiteSpace={"nowrap"}
                overflow={"hidden"}
                maxWidth={"80%"}
              />
            </Grid>
          ) : (
            <Grid container direction="column">
              <Typo
                fontSize={14}
                fontWeight={500}
                color={"#fff"}
                text={props.agent}
                textOverflow={"ellipsis"}
                whiteSpace={"nowrap"}
                overflow={"hidden"}
                maxWidth={"80%"}
                marginTop={19}
              />
              <Typo
                fontSize={14}
                fontWeight={500}
                color={"#fff"}
                text={"Tel. " + props.telephone}
                textOverflow={"ellipsis"}
                whiteSpace={"nowrap"}
                overflow={"hidden"}
                maxWidth={"80%"}
                marginTop={4}
              />
            </Grid>
          )}
        </Grid>
      </Card>
    </div>
  );
}
