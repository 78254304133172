/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Typography, Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import ScrollBars from "../../../common_components/ScrollBars";
import FlowComponent from "./FlowComponent";
import InfoReport from "./InfoReport";
import InfoEmployee from "./InfoEmployee";
import InfoSigns from "./InfoSigns";
import { ipServer } from "../../../config/vars";
import axios from "axios";
import DialogIncidentList from "./DialogIncidentList";
import DialogIncidentAdminList from "./DialogIncidentsAdmin";
import DialogAddNewIncident from "./DialogAddNewIncident";
import DialogAnotherIncident from "./DialogAnotherIncident";
import DialogDeleteReport from "./DialogDeleteReport";
import DialogBackScreen from "../../../common_components/Dialogs/DialogBackScreen";
import DialogChooseSign from "./DialogChooseSign";
import DialogSearchUser from "./DialogSearchUser";
import DialogSearchEmployee from "./DialogSearchEmployee";
import DialogGeneratedSignature from "./DialogGeneratedSignature";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    // height: "100%",
    // backgroundColor: "red",
  },
  divBack: {
    cursor: "pointer",
    marginBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
  back: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 500,
    textAlign: "left",
    color: "#fff",
  },
  icon: {
    color: "white",
    marginRight: 6,
    marginTop: -2,
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 24,
    fontWeight: 500,
    textAlign: "left",
    color: "#fff",
    // marginBottom: 25,
  },
  subtitle: {
    fontFamily: "Roboto",
    fontSize: 14,
    textAlign: "left",
    color: "#ffffff",
  },
  gridMargin: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
}));

export default function CreateReportScreen(props) {
  const classes = useStyles();
  const [screen, setScreen] = useState(1);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [incidence, setIncidence] = useState(0);
  const [openDialogBack, setOpendialogBack] = useState(false);
  const [openDialogIncidents, setOpenDialogIncidents] = useState(false);
  const [openDialogIncidentsAdmin, setOpenDialogIncidentsAdmin] =
    useState(false);
  const [openDialogAddNewIncident, setOpenDialogAddNewIncident] =
    useState(false);
  const [openDialogAnotherIncident, setOpenDialogAnotherIncident] =
    useState(false);
  const [openDialogDeleteReport, setOpenDialogDeleteReport] = useState(false);

  const [openDialogChooseSign, setOpenDialogChooseSign] = useState(false);
  const [openDialogSearchUser, setOpenDialogSearchUser] = useState(false);
  const [openDialogSearchEmployee, setOpenDialogSearchEmployee] =
    useState(false);
  const [openDialogGeneratedSignature, setOpenDialogGeneratedSignature] =
    useState(false);

  const [incidentFlag, setIncidentFlag] = useState(false);
  const [changesFlag, setChangesFlag] = useState(false);
  const [mandatoryFlag, setMandatoryFlag] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [colorButtonSave, setColorButtonSave] = useState({
    backColor: "#474A4E",
    text: "#919191",
  });
  const [colorButtonNext, setColorButtonNext] = useState({
    backColor: "#474a4e",
    text: "#919191",
    backArrow: "#676767",
    arrow: "#919191",
  });
  const [employeeData, setEmployeeData] = useState({
    idUser: "",
    foto: "",
    nombre: "--",
    subcontratista: "--",
    proyectoObra: "--",
    domicilioObra: "--",
    puesto: "--",
  });
  const [incident, setIncident] = useState({
    id: "",
    type: "trabajador_incidencias",
    name: "",
  });

  const backStep = () => {
    if (changesFlag && mandatoryFlag) {
      setOpendialogBack(!openDialogBack);
    } else {
      props.setBehaviorScreen(1);
    }
  };

  // const nextStep = () => {
  //   if (props.screen == 1 && mandatoryFlag == false) {
  //     enqueueSnackbar("Favor de llenar campos obligatorios", {
  //       variant: "error",
  //     });
  //     return;
  //   }
  //   props.setScreen(props.screen + 1);
  // };

  const renderBehaviorScreen = () => {
    if (screen == 1)
      return (
        <InfoReport
          reportData={props.reportData}
          setReportData={props.setReportData}
          openDialogIncidents={openDialogIncidents}
          setOpenDialogIncidents={setOpenDialogIncidents}
          setMandatoryFlag={setMandatoryFlag}
          setChangesFlag={setChangesFlag}
        />
      );
    if (screen == 2)
      return (
        <InfoSigns
          reportData={props.reportData}
          setReportData={props.setReportData}
          setChangesFlag={setChangesFlag}
          openDialogChooseSign={openDialogChooseSign}
          setOpenDialogChooseSign={setOpenDialogChooseSign}
          openDialogGeneratedSignature={openDialogGeneratedSignature}
          setOpenDialogGeneratedSignature={setOpenDialogGeneratedSignature}
        />
      );
  };

  const saveReport = async () => {
    if (mandatoryFlag == false) {
      enqueueSnackbar("Favor de llenar campos obligatorios", {
        variant: "error",
      });
      return;
    }

    setDisableButton(true);
    var _obj = {};
    if (props.reportData.idReport == "") {
      _obj = await createReport();
      if (!_obj.flag) return;
      if (!(await assignReport(_obj.id))) return;

      enqueueSnackbar("Reporte creado correctamente", {
        variant: "success",
      });
    } else {
      console.log(props.reportData.idReport);
      if (!(await updateReport(props.reportData.idReport))) return;

      enqueueSnackbar("Reporte actualizado correctamente", {
        variant: "success",
      });
    }

    setChangesFlag(false);
    setTimeout(() => {
      setDisableButton(false);
      props.setBehaviorScreen(1);
    }, 2000);
  };

  const createReport = async () => {
    var _obj = {
      id: "",
      flag: false,
    };

    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    if (month < 10) {
      date = `${day}/0${month}/${year}`;
      // console.log(`${day}/0${month}/${year}`)
    } else {
      date = `${day}/${month}/${year}`;
      // console.log(`${day}/${month}/${year}`)
    }

    await axios
      .post(
        `${ipServer}/api/v1/rules/incidencia`,
        {
          FechaReporte: date,
          Actividad: props.reportData.activity,
          Area: props.reportData.area,
          NoVeces: props.reportData.times,
          Incidencias: props.reportData.incidentsMade,
          AccionRemediadora: props.reportData.remedialAction,
          Sancion: props.reportData.sanction,
          DiasSancion: props.reportData.daysSanction,
          ModIdriesgos: props.reportData.riskmodification,
          AccionesRealizadas: props.reportData.establishedActions,
          AccionesComplementarias: props.reportData.complementaryActions,
        },
        {
          headers: {
            Authorization: localStorage.getItem("JWT"),
          },
        }
      )
      .then((response) => {
        // console.log(response.data);
        _obj.id = response.data.data._id;
        _obj.flag = true;
        props.setReportData({
          ...props.reportData,
          idReport: response.data.data._id,
        });
        console.log("Reporte creado correctamente");
      })
      .catch((e) => {
        console.log(e.response);
        _obj.flag = false;
        setDisableButton(false);
        enqueueSnackbar("Error al crear reporte", {
          variant: "error",
        });
      });

    return _obj;
  };

  const assignReport = async (idReport) => {
    var trabajadores = [];
    var incidencia = [];

    trabajadores.push(localStorage.getItem("idEmployee"));
    incidencia.push(idReport);

    return await axios
      .post(
        `${ipServer}/api/v1/rules/assing`,
        {
          trabajadores: trabajadores,
          incidencia: incidencia,
        },
        {
          headers: {
            Authorization: localStorage.getItem("JWT"),
          },
        }
      )
      .then((response) => {
        // console.log(response.data);
        console.log("Reporte asignado correctamente");
        return true;
      })
      .catch((e) => {
        console.log(e);
        enqueueSnackbar("Error al asignar reporte", {
          variant: "error",
        });
        setDisableButton(false);
        return false;
      });
  };

  const updateReport = async (idReport) => {
    return await axios
      .patch(
        `${ipServer}/api/v1/rules/incidencia/` + idReport,
        {
          FechaReporte: props.reportData.dateReport,
          Actividad: props.reportData.activity,
          Area: props.reportData.area,
          NoVeces: props.reportData.times,
          Incidencias: props.reportData.incidentsMade,
          AccionRemediadora: props.reportData.remedialAction,
          Sancion: props.reportData.sanction,
          DiasSancion: props.reportData.daysSanction,
          ModIdriesgos: props.reportData.riskmodification,
          AccionesRealizadas: props.reportData.establishedActions,
          AccionesComplementarias: props.reportData.complementaryActions,
        },
        {
          headers: {
            Authorization: localStorage.getItem("JWT"),
          },
        }
      )
      .then((response) => {
        // console.log(response.data);
        console.log("Reporte actualizado correctamente");
        return true;
      })
      .catch((e) => {
        console.log(e.response);
        enqueueSnackbar("Error al actualizar reporte", {
          variant: "error",
        });
        setDisableButton(false);
        return false;
      });
  };

  useEffect(() => {
    if (mandatoryFlag == true) {
      setColorButtonSave({
        ...colorButtonSave,
        backColor: "#ac0bce",
        text: "#fff",
      });
      // setColorButtonNext({
      //   ...colorButtonNext,
      //   backColor: "#2d3034",
      //   text: "#fff",
      //   backArrow: "#d233ff",
      //   arrow: "#fff",
      // });
    } else {
      setColorButtonSave({
        ...colorButtonSave,
        backColor: "#474A4E",
        text: "#919191",
      });
      // setColorButtonNext({
      //   ...colorButtonNext,
      //   backColor: "#474a4e",
      //   text: "#919191",
      //   backArrow: "#676767",
      //   arrow: "#919191",
      // });
    }
  }, [mandatoryFlag]);

  useEffect(() => {
    axios
      .get(
        `${ipServer}/api/v1/rules/trabajadores/${localStorage.getItem(
          "idEmployee"
        )}`,
        {
          headers: {
            Authorization: localStorage.getItem("JWT"),
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        // console.log("get employee", response.data.data[0]);
        setEmployeeData({
          ...employeeData,
          idUser: response.data.data[0]._id
            ? response.data.data[0]._id
            : employeeData.idUser,
          foto: response.data.data[0].foto
            ? response.data.data[0].foto
            : employeeData.foto,
          nombre: response.data.data[0].nombre
            ? response.data.data[0].nombre
            : employeeData.nombre,
          subcontratista: response.data.data[0].subcontratistas[0]
            ? response.data.data[0].subcontratistas[0].empresa
            : employeeData.subcontratista,
          proyectoObra: response.data.data[0].proyectos[0]
            ? response.data.data[0].proyectos[0].proyecto
            : employeeData.proyectoObra,
          domicilioObra: response.data.data[0].proyectos[0]
            ? response.data.data[0].proyectos[0].domicilio
            : employeeData.domicilioObra,
          puesto: response.data.data[0].puesto
            ? response.data.data[0].puesto
            : employeeData.puesto,
        });

        // console.log(response.data.data[0].incidencia);
        if (props.reportData.idReport != "") {
          response.data.data[0].incidencia.map((incidence, index) => {
            if (incidence._id == props.reportData.idReport) {
              // console.log(index);
              // console.log(incidence._id);
              setIncidence(index);
            }
          });

          props.setReportData({
            ...props.reportData,
            idReport: response.data.data[0].incidencia
              ? response.data.data[0].incidencia[incidence]._id
              : props.reportData.idReport,
            dateReport: response.data.data[0].incidencia
              ? response.data.data[0].incidencia[incidence].FechaReporte
              : props.reportData.dateReport,
            activity: response.data.data[0].incidencia
              ? response.data.data[0].incidencia[incidence].Actividad
              : props.reportData.activity,
            area: response.data.data[0].incidencia
              ? response.data.data[0].incidencia[incidence].Area
              : props.reportData.area,
            times: response.data.data[0].incidencia
              ? response.data.data[0].incidencia[incidence].NoVeces
              : props.reportData.times,
            incidentsMade: response.data.data[0].incidencia
              ? response.data.data[0].incidencia[incidence].Incidencias
              : props.reportData.incidentsMade,
            remedialAction: response.data.data[0].incidencia
              ? response.data.data[0].incidencia[incidence].AccionRemediadora
              : props.reportData.remedialAction,
            sanction: response.data.data[0].incidencia
              ? response.data.data[0].incidencia[incidence].Sancion
              : props.reportData.sanction,
            daysSanction: response.data.data[0].incidencia
              ? response.data.data[0].incidencia[incidence].DiasSancion
              : props.reportData.daysSanction,
            riskmodification: response.data.data[0].incidencia
              ? response.data.data[0].incidencia[incidence].ModIdriesgos
              : props.reportData.riskmodification,
            establishedActions: response.data.data[0].incidencia
              ? response.data.data[0].incidencia[incidence].AccionesRealizadas
              : props.reportData.establishedActions,
            complementaryActions: response.data.data[0].incidencia
              ? response.data.data[0].incidencia[incidence]
                  .AccionesComplementarias
              : props.reportData.complementaryActions,
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [incidence]);

  return (
    <div className={classes.divMain}>
      <Grid container>
        <Grid
          item
          xs={6}
          sm={4}
          md={2}
          lg={2}
          xl={2}
          container
          direction="row"
          alignItems="center"
          onClick={backStep}
          className={classes.divBack}
        >
          <Grid item>
            <FontAwesomeIcon
              icon={faArrowAltCircleLeft}
              style={{
                width: "20px",
                height: "20px",
                marginRight: "10px",
                color: "#d6d6d6",
              }}
            />
          </Grid>
          <Grid item>
            <Typography className={classes.back}>Regresar</Typography>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={8} md={10} lg={10} xl={10}></Grid>

        <Grid container direction="row" justifyContent="flex-start">
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            container
            direction="column"
          >
            <Typography className={classes.title}>Registrar reporte</Typography>
            <Typography className={classes.subtitle}>
              Reporte de no conformidad por actitud
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            lg={8}
            xl={8}
            className={classes.gridMargin}
          >
            <InfoEmployee
              reportData={props.reportData}
              employeeData={employeeData}
              setEmployeeData={setEmployeeData}
              openDialogDeleteReport={openDialogDeleteReport}
              setOpenDialogDeleteReport={setOpenDialogDeleteReport}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          <FlowComponent screen={screen} setScreen={setScreen} />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
          <ScrollBars style={{ width: "100%", height: "60vh" }}>
            {renderBehaviorScreen()}
          </ScrollBars>
        </Grid>
      </Grid>

      <Grid
        style={{
          height: "30px",
        }}
      ></Grid>

      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        style={{ width: "98%" }}
      >
        <Button
          variant="contained"
          disabled={disableButton}
          onClick={saveReport}
          style={{
            width: "160px",
            height: "48px",
            borderRadius: "8px",
            // backgroundColor: "#ac0bce",
            backgroundColor: colorButtonSave.backColor,
          }}
        >
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 21,
              textAlign: "left",
              // color: "#fff",
              color: colorButtonSave.text,
              textTransform: "none",
            }}
          >
            {props.reportData.idReport == "" ? "Guardar" : "Actualizar"}
          </Typography>
        </Button>
      </Grid>

      <DialogIncidentList
        reportData={props.reportData}
        setReportData={props.setReportData}
        incidentFlag={incidentFlag}
        setChangesFlag={setChangesFlag}
        openDialogIncidents={openDialogIncidents}
        setOpenDialogIncidents={setOpenDialogIncidents}
        openDialogIncidentsAdmin={openDialogIncidentsAdmin}
        setOpenDialogIncidentsAdmin={setOpenDialogIncidentsAdmin}
        openDialogAnotherIncident={openDialogAnotherIncident}
        setOpenDialogAnotherIncident={setOpenDialogAnotherIncident}
      />

      <DialogIncidentAdminList
        incident={incident}
        setIncident={setIncident}
        incidentFlag={incidentFlag}
        setIncidentFlag={setIncidentFlag}
        openDialogIncidents={openDialogIncidents}
        setOpenDialogIncidents={setOpenDialogIncidents}
        openDialogIncidentsAdmin={openDialogIncidentsAdmin}
        setOpenDialogIncidentsAdmin={setOpenDialogIncidentsAdmin}
        openDialogAddNewIncident={openDialogAddNewIncident}
        setOpenDialogAddNewIncident={setOpenDialogAddNewIncident}
      />

      <DialogAddNewIncident
        incident={incident}
        setIncident={setIncident}
        incidentFlag={incidentFlag}
        setIncidentFlag={setIncidentFlag}
        openDialogAddNewIncident={openDialogAddNewIncident}
        setOpenDialogAddNewIncident={setOpenDialogAddNewIncident}
        openDialogIncidentsAdmin={openDialogIncidentsAdmin}
        setOpenDialogIncidentsAdmin={setOpenDialogIncidentsAdmin}
      />

      <DialogAnotherIncident
        reportData={props.reportData}
        setReportData={props.setReportData}
        setChangesFlag={setChangesFlag}
        openDialogAnotherIncident={openDialogAnotherIncident}
        setOpenDialogAnotherIncident={setOpenDialogAnotherIncident}
      />

      <DialogDeleteReport
        reportData={props.reportData}
        employeeData={employeeData}
        openDialogDeleteReport={openDialogDeleteReport}
        setOpenDialogDeleteReport={setOpenDialogDeleteReport}
      />

      <DialogBackScreen
        setStep={props.setBehaviorScreen}
        saveFunction={saveReport}
        openDialogBack={openDialogBack}
        setOpendialogBack={setOpendialogBack}
      />
      <DialogChooseSign
        openDialogChooseSign={openDialogChooseSign}
        setOpenDialogChooseSign={setOpenDialogChooseSign}
        openDialogSearchUser={openDialogSearchUser}
        setOpenDialogSearchUser={setOpenDialogSearchUser}
        openDialogSearchEmployee={openDialogSearchEmployee}
        setOpenDialogSearchEmployee={setOpenDialogSearchEmployee}
      />
      <DialogSearchUser
        openDialogSearchUser={openDialogSearchUser}
        setOpenDialogSearchUser={setOpenDialogSearchUser}
      />
      <DialogSearchEmployee
        openDialogSearchEmployee={openDialogSearchEmployee}
        setOpenDialogSearchEmployee={setOpenDialogSearchEmployee}
      />

      <DialogGeneratedSignature
        openDialogGeneratedSignature={openDialogGeneratedSignature}
        setOpenDialogGeneratedSignature={setOpenDialogGeneratedSignature}
      />
    </div>
  );
}
