/*eslint-disable*/
import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  divMain: {
    // flexGrow: 1,
    // height: "100%",
    // backgroundColor: "red",
  },
  divStyle: {
    width: "10px",
    height: "100%",
    borderRadius: 2,
    backgroundColor: "#d233ff",
  },
}));

export default function LogHistoryComponent(props) {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid item xs={3} container direction="column">
        <Typography
          style={{
            fontFamily: "Roboto",
            fontSize: 18,
            textAlign: "left",
            color: "#fff",
          }}
        >
          {props.time}
        </Typography>
        <Typography
          style={{
            fontFamily: "Roboto",
            fontSize: 12,
            textAlign: "left",
            color: "#b9b9b9",
          }}
        >
          {props.date}
        </Typography>
      </Grid>

      <div
        style={{
          width: "5px",
          height: "40px",
          marginLeft: "10px",
          marginRight: "10px",
          borderRadius: 2,
          backgroundColor: "#d233ff",
        }}
      />

      <Grid item xs={8} container direction="column">
        <Typography
          style={{
            fontFamily: "Roboto",
            fontSize: 18,
            textAlign: "left",
            color: "#fff",
          }}
        >
          {props.action}
        </Typography>
        <Typography
          style={{
            fontFamily: "Roboto",
            fontSize: 12,
            textAlign: "left",
            color: "#b9b9b9",
          }}
        >
          {props.by}
        </Typography>
      </Grid>
    </Grid>
  );
}
