/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
import { Grid, TextField } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faPlus } from "@fortawesome/free-solid-svg-icons";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Roboto",
    fontSize: 22,
    fontWeight: "bold",
    textAlign: "center",
    color: "#fff",
  },
  divDivider: {
    width: "100%",
    height: 1,
    background: "#707070",
    marginTop: 8,
    padding: 0,
  },
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: "bold",
    lineHeight: 1.35,
    textAlign: "left",
    color: "#cdcece",
    marginBottom: 2,
  },
  textFieldCustom: {
    width: "100%",
    color: "#676767",
    border: "solid 1px #c5c5c5",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
  },
  button: {
    width: "320px",
    height: "48px",
    borderRadius: "8px",
    backgroundColor: "#ac0bce",
    textTransform: "none",
  },
  typoButton: {
    fontFamily: "Roboto",
    fontSize: 21,
    lineHeight: 1.33,
    textAlign: "left",
    color: "#fff",
  },
  gridMargin: {
    marginTop: 20,
  },
  griButton: {
    height: "100%",
    marginTop: 40,
    // backgroundColor: "red",
  },
}));

export default function DialogAddNewProduct(props) {
  const classes = useStyles();

  const handleOpenDialog = () => {
    props.setOpenDialogNewProduct(!props.openDialogNewProduct);
  };

  return (
    <Dialog
      open={props.openDialogNewProduct}
      onClose={handleOpenDialog}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        style: {
          backgroundColor: "#171a1e",
          textColor: "#fsf",
          width: "483px",
          height: "442px",
          padding: "15px 10px 0px 10px ",
          borderRadius: 8,
        },
      }}
    >
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Typography className={classes.title}>Agregar producto</Typography>

          <Grid
            container
            justifyContent="flex-end"
            style={{ position: "absolute" }}
          >
            <Button onClick={handleOpenDialog}>
              <FontAwesomeIcon
                icon={faTimes}
                style={{
                  width: "20px",
                  height: "20px",
                  color: "#fff",
                }}
              />
            </Button>
          </Grid>
        </Grid>
        <div className={classes.divDivider} />
      </DialogTitle>

      <DialogContent>
        <Grid container>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Grid item xs={12} className={classes.gridMargin}>
              <Typography className={classes.textFieldLetter}>
                Producto
              </Typography>
              <TextField
                placeholder="Busca por nombre o identificador"
                name="producto"
                size="small"
                variant="outlined"
                // value={query?.Nombre}
                className={classes.textFieldCustom}
                // onChange={handleChange}
              />
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-end"
              className={classes.griButton}
            >
              <Button variant="contained" className={classes.button}>
                <Typography className={classes.typoButton}>Guardar</Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
