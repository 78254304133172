/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Grid, FormControl, Select, MenuItem, Switch } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ipServer } from "../../../config/vars";
import axios from "axios";
import MultiSelect from "../../../common_components/Filters/MutiSelect";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiLinearProgress-colorPrimary": {
      backgroundColor: "red",
    },
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "red",
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: "white",
    },
    "& .MuiSwitch-switchBase": {
      "&.Mui-checked": {
        "& + .MuiSwitch-track": {
          backgroundColor: "#d233ff",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
    },
    "& .MuiSwitch-track": {
      backgroundColor: "#707070",
      opacity: 1,
      border: 0,
    },
  },

  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: "bold",
    textAlign: "left",
    color: "#cdcece",
  },
  textFieldCustom: {
    width: "276px",
    color: "#676767",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
  },
  formControl: {
    width: "90%",
  },
  select: {
    width: "400px",
    height: "40px",
    borderRadius: "6px",
    backgroundColor: "#fff",
  },
  multi: {
    color: "white",
    padding: 2,
    border: 0,
    borderRadius: "8px",
    maxWidth: 400,

    "&.rmsc .dropdown-heading ": {
      backgroundColor: "white !important",
      color: "#676767",
      borderRadius: 5,
    },
  },
}));

export default function DialogEditUser(props) {
  const classes = useStyles();
  const { setOpendialog, openDialog, data, setData, setSelectionRows } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [proyectos, setProyectos] = useState(null);
  const [empresas, setEmpresas] = useState(null);
  const [roles, setRoles] = useState(null);

  const [updateData, setUpdateData] = useState({
    status: false,
    role: "",
    obras: [],
    empresa: "",
  });

  const handleChangeSwitch = (event) => {
    setUpdateData({ ...updateData, status: event.target.checked });
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const handleDialog = (Reload) => {
    if (Reload) {
      setTimeout(() => {
        location.reload();
      }, 200);
    }

    setUpdateData({
      status: false,
      role: "",
      obras: [],
      empresa: "",
    });
    setSelectionRows([]);
    setData(null);
    setOpendialog(!openDialog);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setUpdateData({
      ...updateData,
      [e.target.name]: value,
    });
  };

  const handleQueryChange = (queryElement) => (e) => {
    const value = queryElement === "obras" ? e : e.target.value;
    return setUpdateData((prevQuery) => {
      return { ...prevQuery, ...{ [queryElement]: value } };
    });
  };

  const update = async (data, id) => {
    return await axios
      .patch(`${ipServer}/api/v1/security/user/${id}`, data, {
        headers: {
          Authorization: localStorage.getItem("JWT"),
        },
      })
      .then((response) => {
        return true;
      })
      .catch((e) => {
        console.log(e);
        return false;
      });
  };

  function diff(a, b) {
    return a.filter((item) => b.indexOf(item) === -1);
  }

  const handleUpdateUser = async () => {
    var user = null;
    const array = updateData?.obras?.map((data) => {
      return data.value;
    });

    const changeObras =
      diff(array, data?.Obras_id).length > 0 ||
      diff(data?.Obras_id, array).length > 0;

    if (
      !(
        roles.some((role) => role.id === updateData.role) &&
        updateData.empresa != "" &&
        updateData.obras.length > 0
      )
    ) {
      enqueueSnackbar("Todos los campos son obligatorios!", {
        variant: "warning",
      });
      return;
    }

    if (
      data?.Role_id != updateData.role ||
      data?.Subcontractor_id != updateData.empresa ||
      (data?.Active != updateData.status &&
        data?.Status !== "Invitación enviada") ||
      changeObras
    ) {
      let projects = [];
      updateData.obras.map((subcontractor, index) => {
        projects.push(subcontractor.value);
      });

      user = {
        Role_id: updateData.role,
        Obras_id: projects,
        Subcontractor_id: updateData.empresa,
      };

      if (data?.Status !== "Invitación enviada") {
        user = { ...user, Active: updateData.status };
      }
    }

    if (user == null) {
      enqueueSnackbar("No se realizaron cambios!", {
        variant: "warning",
      });
      return;
    }

    if (await update(user, data?.id)) {
      enqueueSnackbar("Usuario actualizado correctamente", {
        variant: "success",
      });
    } else {
      enqueueSnackbar("Error al actualizar usuario", {
        variant: "error",
      });
    }

    handleDialog(true);
  };

  const getProyectsList = (status, EUI) => {
    return new Promise((resolve, reject) => {
      try {
        axios
          .get(
            `${ipServer}/api/v1/rules/subcontratistas/${updateData.empresa}/proyectos`,
            {
              headers: { Authorization: localStorage.getItem("JWT") },
              DialogEditUser,
            }
          )
          .then((response) => {
            const proyects = response.data.data.map((object) => {
              return { label: object.proyecto, value: object._id };
            });
            setProyectos(proyects);
            resolve(proyects);
          })
          .catch((e) => {
            console.log(e);
            resolve(false);
          });
      } catch (error) {
        resolve(false);
      }
    });
  };

  const getRolesList = () => {
    return new Promise((resolve, reject) => {
      try {
        axios
          .get(`${ipServer}/api/v1/security/role/`, {
            headers: { Authorization: localStorage.getItem("JWT") },
          })
          .then((response) => {
            let result = response.data.data.map((object) => {
              return { name: object.RoleName, id: object._id };
            });

            result = empresas.find(
              (empresa) => empresa.id === updateData.empresa
            ).padre
              ? result.filter(
                  (rol) => rol.name !== "Administrador Subcontratista"
                )
              : result.filter(
                  (rol) => rol.name === "Administrador Subcontratista"
                );

            setRoles(result);
            resolve(true);
          })
          .catch((e) => {
            console.log(e);
            resolve(false);
          });
      } catch (error) {
        resolve(false);
      }
    });
  };

  const getEmpresasList = () => {
    var params = new URLSearchParams();
    params.append("showoculta", true);

    axios({
      method: "GET",
      url: `${ipServer}/api/v1/rules/subcontratistas`,
      params,
      headers: { Authorization: localStorage.getItem("JWT") },
    })
      // axios
      // .get(`${ipServer}/api/v1/rules/subcontratistas`, {
      //   headers: { Authorization: localStorage.getItem("JWT") },
      // })
      .then((response) => {
        let result = response.data.data.map((object) => {
          return {
            name: object.empresa,
            id: object._id,
            padre: object.empresaPadre ? object.empresaPadre : false,
            ocultar: object.oculta ? object.oculta : false,
          };
        });

        result = result.filter(
          (empresa) => empresa.name != "Sin subcontratista"
        );

        // result = result.filter((empresa) => !empresa.ocultar || empresa.padre);
        setEmpresas(result);

        setUpdateData({
          ...updateData,
          empresa: data?.Subcontractor_id,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (data != null) {
      getEmpresasList();
    }
  }, [data]);

  useEffect(() => {
    const fetchData = async () => {
      if (updateData.empresa != "") {
        const proyects = await getProyectsList();
        const roles = await getRolesList();

        // console.log(proyects);
        // console.log(roles);

        if (proyects && roles) {
          setUpdateData({
            ...updateData,
            obras: proyects.filter((o1) =>
              data?.Obras_id.some((o2) => o1.value === o2)
            ),
            role: data?.Role_id,
            status: data?.Active,
          });
        } else {
          setUpdateData({
            ...updateData,
            status: data?.Active,
          });
        }
      }
    };

    fetchData();
  }, [updateData.empresa]);

  const rolesList = () => {
    const items = roles.map((role, index) => {
      return (
        <MenuItem key={index} value={role.id}>
          &ensp;{role.name}
        </MenuItem>
      );
    });
    return items;
  };

  const empresasList = () => {
    const items = empresas.map((empresa, index) => {
      return (
        <MenuItem key={index} value={empresa.id}>
          &ensp;{empresa.name}
        </MenuItem>
      );
    });
    return items;
  };

  return (
    <Dialog
      open={openDialog}
      aria-labelledby="form-dialog-title"
      onClose={() => handleDialog(false)}
      PaperProps={{
        style: {
          backgroundColor: "#171a1e",
          textColor: "#fsf",
          width: "483px",
          height: "690px",
          // paddingTop: 15,
        },
      }}
    >
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 22,
              textAlign: "center",
              color: "#fff",
              fontWeight: "bold",
            }}
          >
            Editar usuario
          </Typography>

          <Grid
            container
            justifyContent="flex-end"
            style={{ position: "absolute" }}
          >
            <Button onClick={() => handleDialog(false)}>
              <FontAwesomeIcon
                icon={faTimes}
                style={{
                  width: "20px",
                  height: "20px",
                  color: "#fff",
                }}
              />
            </Button>
          </Grid>
        </Grid>
        <div
          style={{
            width: "100%",
            height: 1,
            background: "#707070",
            marginTop: 8,
            padding: 0,
          }}
        ></div>
      </DialogTitle>

      <DialogContent>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          style={{ marginLeft: 20, marginTop: 25 }}
        >
          <Grid>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 20,
                color: "#fff",
                fontWeight: "bold",
              }}
            >
              {data?.Name}
            </Typography>

            <Typography
              style={{
                marginTop: 2,
                fontFamily: "Roboto",
                fontSize: 16,
                color: "#d3d3d3",
              }}
            >
              {data?.Email}
            </Typography>

            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              style={{ marginTop: 30 }}
            >
              <Grid
                container
                direction="column"
                item
                xs={9}
                justifyContent="flex-start"
              >
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 20,
                    color: "#fff",
                    fontWeight: "bold",
                  }}
                >
                  Usuario activado
                </Typography>

                <Typography
                  style={{
                    marginTop: 2,
                    fontFamily: "Roboto",
                    fontSize: 14,
                    color: "#d3d3d3",
                  }}
                >
                  Desactiva o activa el acceso al usuario al sistema
                </Typography>
              </Grid>

              {data?.Status != "Invitación enviada" ? (
                <Grid container item xs={3}>
                  <Switch
                    {...label}
                    className={classes.root}
                    checked={updateData.status}
                    onChange={handleChangeSwitch}
                  />
                </Grid>
              ) : (
                <div></div>
              )}
            </Grid>

            <Typography
              className={classes.textFieldLetter}
              style={{ marginTop: 35 }}
            >
              Empresa
            </Typography>
            <Select
              name="empresa"
              value={updateData.empresa}
              onChange={handleChange}
              inputProps={{ "aria-label": "Without label" }}
              className={classes.select}
              displayEmpty
            >
              <MenuItem value="" disabled>
                &ensp;Obligatorio
              </MenuItem>
              {empresas ? empresasList() : <MenuItem />}
            </Select>

            <Typography
              className={classes.textFieldLetter}
              style={{ marginTop: 20 }}
            >
              Rol
            </Typography>
            <Select
              name="role"
              value={updateData.role}
              onChange={handleChange}
              inputProps={{ "aria-label": "Without label" }}
              className={classes.select}
              disabled={updateData.empresa === ""}
              displayEmpty
            >
              <MenuItem value="" disabled>
                &ensp;Obligatorio
              </MenuItem>
              {roles ? rolesList() : <MenuItem />}
            </Select>

            <Typography
              className={classes.textFieldLetter}
              style={{ marginTop: 20 }}
            >
              Obras asignadas
            </Typography>

            <MultiSelect
              disabled={updateData.empresa === ""}
              options={proyectos ? proyectos : []}
              value={updateData?.obras}
              onChange={handleQueryChange("obras")}
              className={classes.multi}
            />
          </Grid>
        </Grid>

        <Grid container justifyContent="center">
          <Button
            onClick={handleUpdateUser}
            style={{
              width: "274px",
              height: "40px",
              borderRadius: "8px",
              color: "#ffffff",
              backgroundColor: "#ac0bce",
              textTransform: "none",
              marginTop: 80,
            }}
          >
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 21,
                color: "#ffffff",
              }}
            >
              Guardar
            </Typography>
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
