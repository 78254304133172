/*eslint-disable*/
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Popover } from "@material-ui/core";
import { Calendar } from "react-date-range";
import { es } from "date-fns/locale";

const useStyles = makeStyles((theme) => ({
  textFieldCustom: {
    width: "90%",
    color: "#676767",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
  },
}));

export default function DateComponent(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleSelect = (date) => {
    // console.log(date);
    props.setDate(date);

    const monthNames = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];

    var day = date.getDate();
    var month = date.getMonth();
    var year = date.getFullYear();
    const dateFormat = day + " de " + monthNames[month] + " " + year;
    // console.log(dateFormat);

    if (props.name == "fechaNacimiento") {
      props.setEmployeeData({
        ...props.employeeData,
        fechaNacimiento: date.getTime(),
        fechaNacimientoString: dateFormat,
      });
    } else {
      props.setEmployeeData({
        ...props.employeeData,
        fechaIngreso: date.getTime(),
        fechaIngresoString: dateFormat,
      });
    }

    props.setChangesFlag(true);
    setAnchorEl(null);
  };

  // const handleChange = (e) => {
  //   console.log(e.target.value);
  //   props.setChangesFlag(true);
  //   const value = e.target.value;
  //   props.setEmployeeData({
  //     ...props.employeeData,
  //     [e.target.name]: value,
  //   });
  // };

  return (
    <div>
      <TextField
        name={props.name}
        size="small"
        variant="outlined"
        value={props.value}
        className={classes.textFieldCustom}
        // onChange={handleChange}
        onClick={handleClick}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Calendar
          color="#D233FF"
          locale={es}
          date={props.date}
          onChange={handleSelect}
          // maxDate={new Date(new Date().getFullYear() - 18, 12, 0)}
          // minDate={new Date(new Date().getFullYear() - 100, 12, 0)}
          maxDate={
            props.name == "fechaNacimiento"
              ? new Date(new Date().getFullYear() - 18, 12, 0)
              : new Date(new Date().getFullYear() - 0, 12, 0)
          }
          minDate={
            props.name == "fechaNacimiento"
              ? new Date(new Date().getFullYear() - 100, 12, 0)
              : new Date(new Date().getFullYear() - 80, 12, 0)
          }
        />
      </Popover>
    </div>
  );
}
