/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import LinearProgress from "@material-ui/core/LinearProgress";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import svgTrabajador from "../../../../images/usuario_trabajador.svg";
import { faMedkit, faImage } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { SelectedContratista } from "../../../../actions/obras";

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  divMain: {
    maxWidth: 345,
  },
  card: {
    maxWidth: "100%",
    minHeight: "270px",
    borderRadius: "24px",
    cursor: "pointer",
    backgroundColor: "#2d3034",
    "&:hover": {
      background: "#3B3D40",
      boxShadow: "0 0px 16px 0 rgba(90, 90, 90, 0.2)",
    },
  },
  griCard: {
    padding: theme.spacing(3),
  },
  divPhoto: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "56px",
    height: "56px",
    borderRadius: "8px",
    backgroundColor: "#a5a5a5",
  },
  photo: {
    width: "80px",
    height: "70px",
    borderRadius: "8px",
    aspectRatio: 1,
    objectFit: "contain",
  },
  root: {
    width: "60%",
    borderRadius: "20px",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
  input: {
    display: "none",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
}));

export default function CardSubcontractor(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const detailEmployee = () => {
    localStorage.setItem("idSubcontractor", props.idSubcontractor);
    dispatch(SelectedContratista(props.idSubcontractor));
    history.push(`/dashboard/contratistas/${props.idSubcontractor}`);
  };

  return (
    <div onClick={detailEmployee}>
      <Card className={classes.card}>
        <Grid className={classes.griCard}>
          <Grid container direction="row" justifyContent="flex-start">
            <Grid item xs={3}>
              {props.urlPhoto == "" || props.urlPhoto == null ? (
                <div className={classes.divPhoto}>
                  <FontAwesomeIcon
                    icon={faImage}
                    style={{
                      width: "36px",
                      height: "28px",
                      color: "#2d3034",
                      borderRadius: "8px",
                    }}
                  />
                </div>
              ) : (
                <img
                  src={props.urlPhoto}
                  className={classes.photo}
                  alt="photo"
                />
              )}
            </Grid>
            <Grid item xs={9}>
              <Grid container direction="column">
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    textAlign: "left",
                    color: "#fff",
                    fontSize: 18,
                    fontWeight: 500,
                    marginLeft: 15,
                    marginTop: 5,
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    maxWidth: "90%",
                  }}
                >
                  {props.enterprise}
                </Typography>

                {props.businessName == "" || props.businessName == null ? (
                  // <div
                  //   style={{
                  //     height: "13px",
                  //     width: "80%",
                  //     backgroundColor: "#525151",
                  //     marginTop: 5,
                  //     marginLeft: 15,
                  //   }}
                  // />
                  <Typography
                    style={{
                      fontSize: 16,
                      color: "#b2b2b2",
                      marginLeft: 15,
                    }}
                  >
                    --
                  </Typography>
                ) : (
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 16,
                      textAlign: "left",
                      color: "#b2b2b2",
                      marginLeft: 15,
                      marginTop: 2,
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      maxWidth: "90%",
                    }}
                  >
                    {props.businessName}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>

          {/* <Grid container direction="row">
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{
                width: "14px",
                height: "18px",
                color: "#d233ff",
                marginTop: 12,
              }}
            />
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 14,
                textAlign: "left",
                color: "#fff",
                marginTop: 12,
                marginLeft: 7,
              }}
            >
              Empresa Aprobada
            </Typography>
          </Grid> */}

          {props.description == "" || props.description == null ? (
            <div
              style={{
                // height: "12px",
                // width: "100%",
                // backgroundColor: "#525151",
                marginTop: 30,
              }}
            />
          ) : (
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 12,
                textAlign: "left",
                color: "#fff",
                marginTop: 12,
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "98%",
              }}
            >
              {props.description}
            </Typography>
          )}

          <Grid container>
            <Grid item xs={6}>
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 16,
                  textAlign: "left",
                  color: "#b2b2b2",
                  marginTop: 8,
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  maxWidth: "90%",
                }}
              >
                {props.rfc}
              </Typography>{" "}
            </Grid>

            <Grid item xs={6} container direction="row">
              <Grid item xs={1}>
                <img
                  src={svgTrabajador}
                  style={{
                    width: "12px",
                    height: "12px",
                    marginTop: 12,
                  }}
                  alt="techton logo"
                />
              </Grid>
              <Grid item xs>
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 14,
                    textAlign: "left",
                    color: "#fff",
                    // fontWeight: 500,
                    marginLeft: 5,
                    marginTop: 8,
                  }}
                >
                  {props.employees == "" || props.employees == null
                    ? "0 Trabajadores"
                    : props.employees == 1
                    ? props.employees + " Trabajador"
                    : props.employees + " Trabajadores"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {props.securityNumber == "" || props.securityNumber == null ? (
            <div
              style={{
                height: "12px",
                width: "45%",
                backgroundColor: "#525151",
                marginTop: 4,
              }}
            />
          ) : (
            <Grid container direction="row">
              <FontAwesomeIcon
                icon={faMedkit}
                style={{
                  width: "12px",
                  height: "12px",
                  color: "#fff",
                  marginTop: 4,
                  zIndex: 99,
                }}
              />

              <div
                style={{
                  background: "#d233ff",
                  position: "absolute",
                  width: "6px",
                  height: "6px",
                  marginTop: 8,
                  marginLeft: 3,
                }}
              ></div>

              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 10,
                  textAlign: "left",
                  color: "#fff",
                  marginTop: 4,
                  marginLeft: 4,
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  maxWidth: "40%",
                }}
              >
                {props.securityNumber}
              </Typography>
            </Grid>
          )}

          {props.agent == "" || props.agent == null ? (
            <div
              style={{
                height: "14px",
                width: "60%",
                backgroundColor: "#525151",
                marginTop: 18,
              }}
            />
          ) : (
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 14,
                textAlign: "left",
                color: "#fff",
                fontWeight: 500,
                marginTop: 18,
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                maxWidth: "80%",
              }}
            >
              {props.agent}
            </Typography>
          )}

          {props.telephone == "" || props.telephone == null ? (
            <div
              style={{
                height: "14px",
                width: "40%",
                backgroundColor: "#525151",
                marginTop: 8,
              }}
            />
          ) : (
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 14,
                textAlign: "left",
                color: "#fff",
                fontWeight: 500,
                marginTop: 4,
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                maxWidth: "80%",
              }}
            >
              {props.telephone}
            </Typography>
          )}
        </Grid>
      </Card>
    </div>
  );
}
