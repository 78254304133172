/*eslint-disable*/
import { useEffect, useState } from "react";
import axios from "axios";
import axiosInstance from "../../../utils/axiosInstance";

export default function useAttendance(
  query,
  pageNumber,
  limit,
  loadingData,
  subcontratistas,
  proyectos,
  unidades
) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [books, setBooks] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [total, setTotal] = useState(0);
  const [paramsState, setParamsState] = useState(null);

  useEffect(() => {
    setBooks([]);
  }, [query]);

  useEffect(() => {
    if (loadingData) {
      let Subcontratistas =
        query.Subcontratistas.length > 0
          ? query.Subcontratistas
          : subcontratistas;
      let Proyectos = query.Proyectos.length > 0 ? query.Proyectos : proyectos;
      let Unidades = query.Unidad.length > 0 ? query.Unidad : unidades;

      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      var params = new URLSearchParams();

      params.append("limit", limit);
      params.append("page", pageNumber);
      params.append("nombre", query.Empleado);
      // params.append("fullName_partial", query.Empleado);
      params.append("timezone", "America/Mexico_City");
      // params.append("fullName_concat", `["$nombre", " ", "$apellidos"]`);

      params.append(
        "initialDate",
        new Date(query.intialDate.setHours(0, 0, 0, 0))
      );
      params.append(
        "finalDate",
        new Date(query.finalDate.setHours(23, 59, 59, 999))
      );

      Proyectos?.map((proyecto) => {
        params.append("proyecto_string", proyecto.label);
      });

      Unidades?.map((unidad) => {
        params.append("unidad_string", unidad.label);
      });

      Subcontratistas?.map((_subcontratista) => {
        params.append("subcontratista_string", _subcontratista.label);
      });

      setLoading(true);
      setError(false);
      setParamsState(params.toString());
      // console.log("params.toString()", params.toString())

      axiosInstance()
        .get("/api/v1/rules/workingtime/asistenciaV4", {
          params,
          cancelToken: source.token,
        })
        .then((response) => {
          setBooks((prevBooks) => {
            return [...new Set([...prevBooks, ...response.data.data.Results])];
          });

          setHasMore(response.data.data.Results.length > 0);
          setTotal(response.data.data.Total);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          if (axios.isCancel(e)) return;
          setError(true);
        });
      return () => source.cancel();
    }
  }, [query, limit, pageNumber, loadingData]);

  return { loading, error, books, hasMore, total, paramsState };
}
