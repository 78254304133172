/*eslint-disable*/
import { types } from "../types/types";

export const FiltersWorkingTime = (result) => ({
  type: types.filtersWordkingTime,
  payload: result,
});

export const FiltersAttendance = (result) => ({
  type: types.filtersAttendance,
  payload: result,
});

export const FiltersAssistance = (result) => ({
  type: types.filtersAssistance,
  payload: result,
});

export const FiltersEmployees = (result) => ({
  type: types.filtersEmployees,
  payload: result,
});

export const FiltersProyects = (result) => ({
  type: types.filtersProyects,
  payload: result,
});

export const FiltersSubcontractors = (result) => ({
  type: types.filtersSubcontractors,
  payload: result,
});

export const FiltersGateway = (result) => ({
  type: types.filtersGateway,
  payload: result,
});

export const FiltersDevice = (result) => ({
  type: types.filtersDevice,
  payload: result,
});
