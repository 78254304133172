/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import LogHistoryComponent from "./LogHistoryComponent";
import axiosInstance from "../../utils/axiosInstance";
import ScrollBars from "../ScrollBars";
import Lottie from "react-lottie";
import LottieNotFound from "../../animations/lottie_not_found.json";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
  },
  gridFile: {
    padding: theme.spacing(3),
    height: "289px",
    borderRadius: "16px",
    backgroundColor: "#2d3034",
  },
}));

export default function CardHistoryEnterprise(props) {
  const classes = useStyles();
  const historyPush = useHistory();
  const [history, setHistory] = useState([]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LottieNotFound,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const getHistory = async () => {
    var params = new URLSearchParams();
    params.append("limit", 10);
    params.append("page", 0);
    params.append(
      "subcontratistas_id",
      localStorage.getItem("idSubcontractor")
    );

    await axiosInstance()
      .get("/api/v1/rules/historial", { params })
      .then((res) => {
        setHistory(res.data.data.Results);
      })
      .catch((e) => {
        console.log(e);
        setHistory([]);
      });
  };

  useEffect(() => {
    getHistory();
  }, []);

  return (
    <div className={classes.divMain}>
      <Grid item xs={12} className={classes.gridFile}>
        <Grid container direction="row">
          <Grid item xs={9}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 22,
                fontWeight: 500,
                color: "#fff",
              }}
            >
              Historial
            </Typography>
          </Grid>
          <Grid item xs={3} container direction="row" justifyContent="flex-end">
            <Typography
              onClick={() =>
                historyPush.push(
                  `/dashboard/miempresa/historial/${localStorage.getItem(
                    "idSubcontractor"
                  )}`
                )
              }
              style={{
                fontFamily: "Roboto",
                fontSize: 18,
                fontWeight: 500,
                color: "#d233ff",
                cursor: "pointer",
              }}
            >
              Ver más
            </Typography>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            height: "12px",
          }}
        />

        <ScrollBars style={{ height: "80%" }} color="#212429">
          {history.length > 0 ? (
            history.map((_history, index) => (
              <Grid key={index} style={{ marginTop: 20 }}>
                <LogHistoryComponent
                  time={new Date(_history.Date).toLocaleTimeString("en-GB", {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                  date={new Date(_history.Date).toLocaleDateString("es-MX")}
                  action={_history.Action}
                  by={`Acción realiza por ${_history.User.Email}`}
                />
              </Grid>
            ))
          ) : (
            <Grid container direction="column" style={{ margin: "auto" }}>
              <Lottie options={defaultOptions} height={149} width={164} />
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 16,
                  textAlign: "center",
                  color: "#c1c1c1",
                }}
              >
                Sin Historial Registrado
              </Typography>
            </Grid>
          )}
        </ScrollBars>
      </Grid>
    </div>
  );
}
