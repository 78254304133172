/*eslint-disable*/
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { Button, Grid, Typography } from "@material-ui/core";
import DialogGallery from "../../../../common_components/Dialogs/DialogGallery";

const useStyles = makeStyles((theme) => ({
  divMain: {
    width: "100%",
    height: "auto",
    borderRadius: "12px",
    backgroundColor: "#2d3034",
    padding: theme.spacing(2),
  },
  typoTitle: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 1.31,
    textAlign: "left",
    color: "#acacac",
    marginRight: 12,
  },
  typoTitle2: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 1.31,
    textAlign: "left",
    color: "#acacac",
  },
  typoSubtitle: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.31,
    color: "#fff",
  },
  typoSubtitle2: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 1.31,
    color: "#fff",
  },
  button: {
    width: 96,
    height: 96,
    borderRadius: 8,
    border: "solid 1px #707070",
  },
  photo: {
    width: 85,
    height: 85,
    borderRadius: 8,
    aspectRatio: 1,
    objectFit: "cover",
  },
  divRelative: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  divEvidence: {
    position: "absolute",
    top: 80,
    left: 80,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 24,
    height: 24,
    borderRadius: "50%",
    backgroundColor: "#d233ff",
  },
}));

export default function CardStockistEntry(props) {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const url =
    "https://ca-times.brightspotcdn.com/dims4/default/deca070/2147483647/strip/true/crop/1135x744+0+0/resize/1135x744!/quality/90/?url=https%3A%2F%2Fcalifornia-times-brightspot.s3.amazonaws.com%2F90%2F82%2F4ad71dc542d2a9120bbefbcadf70%2Fquiere-la-roca-pelea-938977.JPG";

  return (
    <div>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        className={classes.divMain}
      >
        <Grid item xs={9}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography className={classes.typoTitle}>Folio</Typography>
            <Typography className={classes.typoSubtitle}>
              {props.folio}
            </Typography>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography className={classes.typoTitle}>
              Subcontratista
            </Typography>
            <Typography className={classes.typoSubtitle}>
              {props.subcontratistas}
            </Typography>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography className={classes.typoTitle}>
              solicitado por
            </Typography>
            <Typography className={classes.typoSubtitle}>
              {props.solicitadoX}
            </Typography>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            style={{ marginTop: "16px" }}
          >
            <Typography className={classes.typoSubtitle}>
              {props.articulos +
                " " +
                (props.articulos > 1 ? "artículos" : "artículo")}
            </Typography>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            style={{ marginTop: "11px" }}
          >
            <Typography className={classes.typoTitle}>Entregado a</Typography>
            <Typography className={classes.typoSubtitle}>
              {props.entregadoA}
            </Typography>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography className={classes.typoTitle}>Estatus</Typography>
            <FontAwesomeIcon
              icon={faClock}
              style={{
                width: "14px",
                height: "14px",
                marginRight: "6px",
                color: "#8f8f8f",
              }}
            />
            <Typography className={classes.typoSubtitle}>
              {props.estatus}
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs container direction="column" alignItems="flex-start">
          <Typography className={classes.typoTitle2}>{props.date}</Typography>

          <Typography className={classes.typoTitle2}>{props.hour}</Typography>

          <Typography
            className={classes.typoSubtitle}
            style={{
              marginTop: 20,
            }}
          >
            Evidencia
          </Typography>

          <div className={classes.divRelative}>
            <Button
              onClick={() => setOpenDialog(!openDialog)}
              className={classes.button}
            >
              <img src={url} className={classes.photo} alt="photo" />
            </Button>

            <div className={classes.divEvidence}>
              <Typography className={classes.typoSubtitle2}>
                {props.numeroEvidencia}
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>

      <DialogGallery openDialog={openDialog} setOpenDialog={setOpenDialog} />
    </div>
  );
}
