/*eslint-disable*/
import { types } from "../types/types";
import { ipServer } from "../config/vars";
import axios from "axios";
import { parseWithOptions } from "date-fns/fp";

export const UploadDocuments = (idUser, files, type, setChangesFile) => {
  return async (dispatch) => {
    //TODO:map files
    dispatch(fileModalOpen());
    files.map(async (file, index) => {
      if (
        (file.process === "new" && !file.url) ||
        (file.process === "update" && !file.url)
      ) {
        let formData = new FormData();

        formData.append("file", file.file);
        formData.append(
          "data",
          JSON.stringify({ filename: file.name, originalName: file.file.name })
        );

        dispatch(
          updateObjectFile({
            name: file.name,
            progress: 0,
            status: "upload",
            idUser: idUser,
            id: file.id,
          })
        );

        await axios({
          method: file.process === "new" ? "POST" : "PATCH",
          url: `${ipServer}/api/v1/rules/${type}/upload/${idUser}`,
          data: formData,
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );
            const status = progress === 100 ? "correct" : "upload";

            dispatch(
              updateObjectFile({
                name: file.name,
                progress: progress,
                status: status,
                id: file.id,
              })
            );
          },
          headers: {
            Authorization: localStorage.getItem("JWT"),
            "Content-Type": "multipart/form-data",
          },
        })
          .then((response) => {
            // console.log(response.data);
            if (response.data.status === "ok") {
              let documentUpload = response.data.data.documentos
                .reverse()
                .find((e) => e.filename === file.name);
              dispatch(
                updateObjectFile({
                  name: file.name,
                  url: documentUpload.file,
                  originalName: documentUpload.originalName,
                  id: file.id,
                })
              );

              if (type === "trabajadores" || type == "subcontratistas") {
                setChangesFile(response.data.data.percentage);
              }
            } else {
              console.log("error backend");
            }
          })
          .catch((error) => {
            dispatch(
              updateObjectFile({
                name: file.name,
                progress: 0,
                status: "failure",
              })
            );

            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            }

            console.log(error);
          });
      }
    });
  };
};

export const RetryUploadDocuments = (files, type, setChangesFile) => {
  return async (dispatch) => {
    //TODO:map files
    files.map(async (file, index) => {
      let formData = new FormData();

      formData.append("file", file.file);
      formData.append(
        "data",
        JSON.stringify({ filename: file.name, originalName: file.file.name })
      );
      dispatch(
        updateObjectFile({
          name: file.name,
          progress: 0,
          status: "upload",
        })
      );

      await axios
        .post(
          `${ipServer}/api/v1/rules/${type}/upload/${file.idUser}`,
          formData,
          {
            onUploadProgress: (progressEvent) => {
              const progress = Math.round(
                (progressEvent.loaded / progressEvent.total) * 100
              );
              const status = progress === 100 ? "correct" : "upload";

              dispatch(
                updateObjectFile({
                  name: file.name,
                  progress: progress,
                  status: status,
                })
              );
            },
            headers: {
              Authorization: localStorage.getItem("JWT"),
              "Content-Type": "multipart/form-data",
            },
          }
        )

        .then((response) => {
          // console.log("UploadDocuments", response);
          if (response.data.status === "ok") {
            let documentUpload = response.data.data.documentos.find(
              (e) => e.name === file.name
            );
            //TODO:validar?

            // replaceObjectFile
            dispatch(
              updateObjectFile({
                name: file.name,
                url: documentUpload.file,
                originalName: documentUpload.originalName,
                process: "finished",
              })
            );

            if (type === "trabajadores" || type == "subcontratistas") {
              setChangesFile(response.data.data.percentage);
            }
          } else {
            console.log("error backend");
          }
        })
        .catch((error) => {
          dispatch(
            updateObjectFile({
              name: file.name,
              progress: 0,
              status: "failure",
            })
          );

          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }

          console.log(error);
          // console.log("UploadDocuments", error);
        });
    });

    // setTimeout(async () => {
    //   dispatch(fileModalOpen());
    // }, 25000);
  };
};

export const addFile = (file) => ({
  type: types.fileAdd,
  payload: file,
});

// export const retryFile = (file) => ({
//   type: types.fileRetry,
//   payload: file,
// });

export const deleteFile = (file) => ({
  type: types.fileDelete,
  payload: file,
});

export const updateFile = (file) => ({
  type: types.fileUpdate,
  payload: file,
});

export const clearFiles = () => ({
  type: types.fileClear,
});

// const updateURL = (url) => ({
//   type: types.fileUpdateURL,
//   payload: url,
// });

// const updateProgress = (progress) => ({
//   type: types.fileUpdateProgress,
//   payload: progress,
// });

export const fileModalOpen = () => ({
  type: types.fileUpdloadModal,
});

export const updateObjectFile = (info) => ({
  type: types.fileUpdateObject,
  payload: info,
});

export const replaceObjectFile = (info) => ({
  type: types.fileReplaceObject,
  payload: info,
});
