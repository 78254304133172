/*eslint-disable*/
import React, { useState, useEffect, useRef, useCallback } from "react";
import { Box, Typography, Divider, Grid, Switch } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faEnvelope,
  faLock,
  faBuilding,
  faBell,
  faSignOutAlt,
  faCog,
  faCommentAlt,
  faSms,
} from "@fortawesome/free-solid-svg-icons";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LoadUser } from "../../../actions/user";
import { types } from "../../../types/types";
import { SetScreenProfile } from "../../../actions/screen";
import NavBarListOption from "./NavBarListOption";
import NotificationComponent from "./NotificationComponent";
import useNotificationsSearch from "./useNotificationsSearch";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import axiosInstance from "../../../utils/axiosInstance";
import { ipServer } from "../../../config/vars";
import Typo from "../../../common_components/typo";
import { ValidatePermissionsModule } from "../../../utils/permissions";
import { getHours } from "../../../utils/gethours";
import NotNotificationAnimation from "./NotNotificationsFound";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiLinearProgress-colorPrimary": {
      backgroundColor: "red",
    },
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "red",
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: "white",
    },
    "& .MuiSwitch-switchBase": {
      "&.Mui-checked": {
        "& + .MuiSwitch-track": {
          backgroundColor: "#d233ff",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
    },
    "& .MuiSwitch-track": {
      backgroundColor: "#707070",
      opacity: 1,
      border: 0,
    },
  },
  icon: {
    color: "white",
    marginRight: 6,
    marginTop: -2,
  },
  noWrap: {
    textOverflow: "ellipsis",
    wordWrap: "anywhere",
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
  },
  gridItem: {
    padding: theme.spacing(1, 4, 1, 4),
  },
  gridConfig: {
    padding: theme.spacing(0, 2, 0, 2),
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 24,
    fontWeight: "bold",
    textAlign: "left",
    color: "#fff",
  },
  subtitle: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: "bold",
    textAlign: "left",
    color: "#d9d9d9",
  },
  label: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 600,
    textAlign: "left",
    color: "#f1f1f1",
  },
  griInfoUser: {
    width: 333,
    height: 56,
    borderRadius: 32,
    backgroundColor: theme.palette.primary.light,
    // cursor: "pointer",
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    marginTop: 8,
    marginLeft: 20,
    width: 40,
    height: 40,
  },
  typoUserName: {
    fontFamily: "Roboto",
    fontSize: 16,
    color: theme.palette.neutral.primary,
    fontWeight: 500,
    width: "90%",
    textOverflow: "ellipsis",
    wordWrap: "anywhere",
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
  },
  typoUserRole: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: "bold",
    color: theme.palette.primary.dark,
    width: "90%",
    textOverflow: "ellipsis",
    wordWrap: "anywhere",
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
  },
}));

export default function NavBar(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { screenProfile } = useSelector((state) => state.screen);
  const [pageNumber, setPageNumber] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [anchorElNoti, setAnchorElNoti] = useState(null);
  const openMenuNoti = Boolean(anchorElNoti);
  const [anchorElConfig, setAnchorElConfig] = useState(null);
  const openMenuConfig = Boolean(anchorElConfig);
  const [notisNotViewed, setNotisNotViewed] = useState(true);
  const [notificationsConfig, setNotificationsConfig] = useState({
    push: false,
    mail: false,
  });
  const [update, setUpdate] = useState(false);

  // const { books, hasMore, loading, error } = useNotificationsSearch(
  //   pageNumber,
  //   loadingData,
  //   update
  // );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickNoti = (event) => {
    setAnchorElNoti(event.currentTarget);
  };

  const handleClickConfig = (event) => {
    setAnchorElConfig(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClose2 = () => {
    setAnchorElNoti(null);
  };
  const handleClose3 = () => {
    setAnchorElConfig(null);
  };

  const handleExit = () => {
    dispatch({ type: types.reset });
    localStorage.clear();
    history.push("/inicio_sesion");
  };

  const handleChangeSwitch = (event) => {
    const Notifications = {
      ...notificationsConfig,
      [event.target.name]: event.target.checked,
    };

    setNotificationsConfig(Notifications);
    updateNotifications({ Notifications });
  };

  const updateNotifications = async (data) => {
    await axiosInstance()
      .patch(`api/v1/security/user/profile/`, data)
      .then((response) => {
        ReloadUser();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const ReloadUser = async () => {
    try {
      return await axiosInstance()
        .get("/api/v1/security/user/profile/")
        .then((response) => {
          dispatch(LoadUser(response.data.data));
          return response.data.data.Role_id;
        })
        .catch((e) => {
          console.log(e);
          return false;
        });
    } catch (error) {
      return false;
    }
  };

  function stringAvatar(names) {
    let initials = "";
    names.split(" ").map((name) => {
      initials = initials + name[0];
    });

    if (initials.length > 2) {
      initials = initials.slice(0, 2);
    }

    return {
      children: initials,
    };
  }

  // const observer = useRef();
  // const lastBookElementRef = useCallback(
  //   (node) => {
  //     if (loading) return;
  //     if (observer.current) observer.current.disconnect();
  //     observer.current = new IntersectionObserver((entries) => {
  //       if (entries[0].isIntersecting && hasMore) {
  //         setPageNumber((prevPageNumber) => prevPageNumber + 1);
  //       }
  //     });
  //     if (node) observer.current.observe(node);
  //   },
  //   [loading, hasMore]
  // );

  // const getNotisNotViewed = () => {
  //   const CancelToken = axios.CancelToken;
  //   const source = CancelToken.source();

  //   var params = new URLSearchParams();
  //   params.append("limit", 1);
  //   params.append("page", pageNumber);
  //   params.append("viewed_boolean", false);

  //   return axiosInstance()
  //     .get("/api/v1/notification/", {
  //       params,
  //       cancelToken: source.token,
  //     })
  //     .then((res) => {
  //       // console.log(res.data.data);
  //       if (res.data.data.Total > 0) setNotisNotViewed(false);
  //       else setNotisNotViewed(true);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //       if (axios.isCancel(e)) return;
  //     });
  // };

  // useEffect(() => {
  //   getNotisNotViewed();
  // }, []);

  useEffect(() => {
    if (user) {
      setNotificationsConfig({
        ...notificationsConfig,
        push: user.Notifications?.push ?? false,
        mail: user.Notifications?.mail ?? false,
      });
    }
  }, [user]);

  return (
    <div style={{ width: "100%" }}>
      <Box
        bgcolor="#fff"
        alignItems="center"
        height="auto"
        style={{ paddingLeft: 16, paddingTop: 16, paddingRight: 16 }}
      >
        <Grid container direction="row" justifyContent="flex-end">
          {/* <div
            onClick={(e) => {
              handleClickNoti(e);
              setUpdate(!update);
            }}
          >
            <div
              style={{
                width: 56,
                height: 56,
                marginRight: 25,
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "50%",
                backgroundColor: "#414246",
                cursor: "pointer",
              }}
            >
              {!notisNotViewed && (
                <div
                  style={{
                    position: "absolute",
                    top: 15,
                    left: 30,
                    width: 10,
                    height: 10,
                    borderRadius: "50%",
                    backgroundColor: "#d233ff",
                  }}
                />
              )}

              <FontAwesomeIcon
                icon={faBell}
                style={{
                  width: "28px",
                  height: "32px",
                  color: "#fff",
                }}
              />
            </div>
          </div> */}

          <div
            aria-label="more"
            id="long-button"
            aria-controls="long-menu"
            aria-haspopup="true"
            // onClick={handleClick}
          >
            <Grid container direction="row" className={classes.griInfoUser}>
              <Avatar
                className={classes.avatar}
                {...stringAvatar(user ? user.Name : "")}
              />
              <Grid item xs={6} style={{ marginTop: 5, marginLeft: 25 }}>
                <Typography className={classes.typoUserName}>
                  {user ? user.Name : ""}
                </Typography>

                <Typography className={classes.typoUserRole}>
                  {user ? user.RoleName : ""}
                </Typography>
              </Grid>

              {/* <FontAwesomeIcon
                icon={faChevronDown}
                style={{
                  width: "38px",
                  height: "22px",
                  color: "#fff",
                  marginTop: 18,
                }}
              /> */}
            </Grid>
          </div>

          <Menu
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            id="long-menu"
            getContentAnchorEl={null}
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleClose}
            PaperProps={{
              style: {
                backgroundColor: "#2d2f33",
                width: "271px",
                marginTop: 4,
              },
            }}
          >
            <NavBarListOption
              option={"Perfil"}
              icon={"person"}
              handle={() => {
                dispatch(SetScreenProfile(1));
                history.push("/dashboard/perfil");
                setAnchorEl(null);
              }}
            />
            <NavBarListOption
              option={"Contraseña"}
              icon={faLock}
              handle={() => {
                dispatch(SetScreenProfile(2));
                history.push("/dashboard/perfil");
                setAnchorEl(null);
              }}
            />
            {/* <NavBarListOption
              option={"Configuración"}
              icon={"settings"}
              handle={() => {}}
              // handle={() => {
              //   dispatch(SetScreenProfile(3));
              //   history.push("/dashboard/perfil");
              //   setAnchorEl(null);
              // }}
            /> */}
            <Divider
              style={{
                height: "2px",
                marginTop: "8px",
                marginBottom: "8px",
                backgroundColor: "#404040",
              }}
            />

            {ValidatePermissionsModule("Read", "PerfilSubcontratista") && (
              <NavBarListOption
                option={"Perfil empresa"}
                icon={faBuilding}
                handle={() => {
                  dispatch(SetScreenProfile(4));
                  history.push("/dashboard/perfil");
                  setAnchorEl(null);
                }}
              />
            )}
            {/* <NavBarListOption
              option={"Licencia"}
              icon={faKey}
              handle={() => {}}
              // handle={() => {
              //   dispatch(SetScreenProfile(5));
              //   history.push("/dashboard/perfil");
              //   setAnchorEl(null);
              // }}
            /> */}
            <NavBarListOption
              option={"Cerrar sesión"}
              icon={"exit_to_app"}
              handle={handleExit}
            />
          </Menu>

          {/* Notificaciones */}
          {/* <Menu
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            id="long-menu"
            getContentAnchorEl={null}
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorElNoti}
            open={openMenuNoti}
            onClose={handleClose2}
            PaperProps={{
              style: {
                backgroundColor: "#2d2f33",
                width: "609px",
                height: "100%",
              },
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              className={classes.gridItem}
            >
              <Grid
                item
                xs={1}
                onClick={() => setAnchorElNoti(null)}
                style={{
                  cursor: "pointer",
                }}
              >
                <FontAwesomeIcon
                  icon={faSignOutAlt}
                  style={{
                    width: "32px",
                    height: "32px",
                    color: "#fff",
                  }}
                />
              </Grid>
              <Grid
                item
                xs={10}
                container
                direction="row"
                justifyContent="center"
              >
                <Typo
                  fontSize={32}
                  fontWeight={"bold"}
                  color={"#fff"}
                  text={"Notificaciones"}
                />
              </Grid>
              <Grid
                item
                xs={1}
                onClick={handleClickConfig}
                style={{
                  cursor: "pointer",
                }}
              >
                <FontAwesomeIcon
                  icon={faCog}
                  style={{
                    width: "34px",
                    height: "34px",
                    color: "#fff",
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              style={{
                height: "790px",
              }}
            >
              {books.length == 0 && !loading ? (
                <NotNotificationAnimation />
              ) : (
                books?.map((event, index) => {
                  if (books.length === index + 1) {
                    return (
                      <Grid key={index} ref={lastBookElementRef}>
                        <NotificationComponent
                          id={event._id}
                          title={event.title}
                          detailNoti={event.body}
                          viewed={event.viewed}
                          time={getHours(event?.date_datetime)}
                          setAnchorElNoti={setAnchorElNoti}
                          setPageNumber={setPageNumber}
                          getNotisNotViewed={getNotisNotViewed}
                        />
                      </Grid>
                    );
                  } else {
                    return (
                      <Grid key={index}>
                        <NotificationComponent
                          id={event._id}
                          title={event.title}
                          detailNoti={event.body}
                          viewed={event.viewed}
                          time={getHours(event?.date_datetime)}
                          setAnchorElNoti={setAnchorElNoti}
                          setPageNumber={setPageNumber}
                          getNotisNotViewed={getNotisNotViewed}
                        />
                      </Grid>
                    );
                  }
                })
              )}

              {loading && (
                <Box
                  m={1}
                  p={1}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  style={{
                    height: "80%",
                  }}
                >
                  <CircularProgress
                    disableShrink
                    size={100}
                    color="secondary"
                  />
                </Box>
              )}
            </Grid>
          </Menu> */}

          <Menu
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            // transformOrigin={{ vertical: "top", horizontal: "left" }}
            id="long-menu"
            getContentAnchorEl={null}
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorElConfig}
            open={openMenuConfig}
            onClose={handleClose3}
            PaperProps={{
              style: {
                backgroundColor: "#2d2f33",
                width: "302px",
                height: "264px",
              },
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              className={classes.gridConfig}
            >
              <Typography className={classes.title}>Configuración</Typography>
              <Typography
                className={classes.subtitle}
                style={{
                  marginBottom: 20,
                }}
              >
                Personaliza los medios de notificaciones
              </Typography>

              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                style={{
                  marginBottom: 10,
                }}
              >
                <Grid
                  item
                  xs={9}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    style={{
                      width: "30px",
                      height: "24px",
                      color: "#fff",
                      marginRight: 14,
                    }}
                  />
                  <Typography className={classes.label}>Email</Typography>
                </Grid>
                <Grid
                  item
                  xs={3}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="flex-end"
                >
                  <Switch
                    // {...label}
                    name="mail"
                    className={classes.root}
                    checked={notificationsConfig.mail}
                    onChange={handleChangeSwitch}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                style={{
                  marginBottom: 10,
                }}
              >
                <Grid
                  item
                  xs={9}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <FontAwesomeIcon
                    icon={faCommentAlt}
                    style={{
                      width: "30px",
                      height: "24px",
                      color: "#fff",
                      marginRight: 14,
                    }}
                  />
                  <Typography className={classes.label}>
                    Notificaciones Push
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={3}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="flex-end"
                >
                  <Switch
                    // {...label}
                    name="push"
                    className={classes.root}
                    checked={notificationsConfig.push}
                    onChange={handleChangeSwitch}
                  />
                </Grid>
              </Grid>

              {/* <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid
                  item
                  xs={9}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <FontAwesomeIcon
                    icon={faSms}
                    style={{
                      width: "36px",
                      height: "31px",
                      color: "#fff",
                      marginRight: 14,
                    }}
                  />
                  <Typography className={classes.label}>SMS</Typography>
                </Grid>
                <Grid
                  item
                  xs={3}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="flex-end"
                >
                  <Switch
                    // {...label}
                    name="sms"
                    className={classes.root}
                    checked={notificationsConfig.sms}
                    onChange={handleChangeSwitch}
                  />
                </Grid>
              </Grid> */}
            </Grid>
          </Menu>
        </Grid>
      </Box>
      {props.children}
    </div>
  );
}
