/*eslint-disable*/
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faClock } from "@fortawesome/free-solid-svg-icons";
import { DateFormatString } from "../../../../utils/DateFormatString";
import DataGrid from "../../../../common_components/Filters/DataGrid";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .header": {
      color: "white",
      fontFamily: "Roboto",
      fontSize: "18px",
      fontWeight: 500,
      display: "flex",
    },
  },
  photo: {
    width: "40px",
    height: "40px",
    borderRadius: "8px",
    margin: "auto",
    aspectRatio: 1,
  },
}));

export default function DataGridPurchaseOrders(props) {
  const classes = useStyles();

  const columns = [
    {
      field: "Folio",
      headerName: "#Folio",
      headerAlign: "center",
      flex: 0.5,
      sortable: false,
      headerClassName: "header",
    },
    {
      field: "Proveedor",
      headerName: "Proveedor",
      headerAlign: "center",
      flex: 1,
      sortable: false,
      headerClassName: "header",
      // renderCell: (params) => {
      //   return (
      //     <div style={{ margin: "auto" }}>
      //       {params.row?.subcontratistas[0]?.empresa}
      //     </div>
      //   );
      // },
    },
    {
      field: "AlmacenEntregar",
      headerName: "Almacen a Entregar",
      headerAlign: "center",
      flex: 1,
      sortable: false,
      headerClassName: "header",
      // renderCell: (params) => {
      //   return (
      //     <div style={{ margin: "auto" }}>{params.row?.usuario?.Name}</div>
      //   );
      // },
    },
    {
      field: "CreadaPor",
      headerName: "Creada Por",
      headerAlign: "center",
      flex: 1,
      sortable: false,
      headerClassName: "header",
    },
    {
      field: "DiaEntrega",
      headerName: "Dia de Entrega",
      headerAlign: "center",
      flex: 0.8,
      sortable: false,
      headerClassName: "header",
      // renderCell: (params) => {
      //   return (
      //     <div style={{ margin: "auto" }}>{params.row?.articulos?.length}</div>
      //   );
      // },
    },
    {
      field: "Estatus",
      headerName: "Estatus",
      headerAlign: "center",
      flex: 1,
      sortable: false,
      headerClassName: "header",

      // renderCell: (params) => {
      //   return (
      //     <div style={{ margin: "auto" }}>
      //       {params.row.status == "Recolectado" ? (
      //         <Grid container direction="row" alignItems="center">
      //           <FontAwesomeIcon
      //             icon={faCheckCircle}
      //             style={{
      //               width: "18px",
      //               height: "18px",
      //               color: "#ac0bce",
      //               marginRight: 5,
      //             }}
      //           />
      //           <Typography
      //             style={{
      //               fontFamily: "Roboto",
      //               fontSize: 16,
      //               color: "#fff",
      //             }}
      //           >
      //             Recolectado
      //           </Typography>
      //         </Grid>
      //       ) : params.row.status == "En espera de recoleccion" ? (
      //         <Grid container direction="row" alignItems="center">
      //           <FontAwesomeIcon
      //             icon={faClock}
      //             style={{
      //               width: "14px",
      //               height: "14px",
      //               color: "#8f8f8f",
      //               marginRight: 5,
      //             }}
      //           />
      //           <Typography
      //             style={{
      //               fontFamily: "Roboto",
      //               fontSize: 11,
      //               fontWeight: 500,
      //               color: "#fff",
      //             }}
      //           >
      //             En espera de recoleccion
      //           </Typography>
      //           {/* <div
      //             onClick={() => {
      //               props.setComplete(true);
      //               props.setOpendialog(true);
      //             }}
      //             style={{ cursor: "pointer" }}
      //           >
      //             <Typography
      //               style={{
      //                 fontFamily: "Roboto",
      //                 fontSize: 12,
      //                 color: "#d233ff",
      //                 marginLeft: 6,
      //               }}
      //             >
      //               Volver a enviar invitación
      //             </Typography>
      //           </div> */}
      //         </Grid>
      //       ) : (
      //         <div></div>
      //       )}
      //     </div>
      //   );
      // },
    },
  ];

  return (
    <DataGrid
      rows={props.rows}
      columns={columns}
      loading={props.loading}
      rowCount={props.rowCount}
      rowsPerPageOptions={props.rowsPerPageOptions}
      page={props.page}
      pageSize={props.pageSize}
      onPageChange={props.onPageChange}
      onPageSizeChange={props.onPageSizeChange}
      style={props.style}
      onSelectionModelChange={props.onSelectionModelChange}
      selectionModel={props.selectionModel}
    />
  );
}
