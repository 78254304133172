/*eslint-disable*/
import React from "react";
import DataGrid from "../../../common_components/Filters/DataGrid";
import { Grid, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { makeStyles } from "@material-ui/styles";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .header": {
      color: "white",
      fontFamily: "Roboto",
      fontSize: "18px",
      fontWeight: 500,
      display: "flex",
    },
  },
  photo: {
    width: "40px",
    height: "40px",
    borderRadius: "8px",
    margin: "auto",
    aspectRatio: 1,
  },
}));

export default function DataGridClients(props) {
  const classes = useStyles();

  const columns = [
    {
      field: "Cliente",
      headerName: "Cliente",
      headerAlign: "center",
      flex: 1,
      sortable: false,
      headerClassName: "header",
      //   renderCell: (params) => {
      //     return (
      //       <div style={{ margin: "auto" }}>
      //         {params.row.Name ? params.row.Name : "--"}
      //       </div>
      //     );
      //   },
    },
    {
      field: "ObrasActivas",
      headerName: "Obras activas",
      headerAlign: "center",
      flex: 1,
      sortable: false,
      headerClassName: "header",
    },
    {
      field: "CostosModulo",
      headerName: "Costos modulos",
      headerAlign: "center",
      flex: 1,
      sortable: false,
      headerClassName: "header",
    },
    {
      field: "CostoReconocimiento",
      headerName: "Costo reconocimiento",
      headerAlign: "center",
      flex: 1,
      sortable: false,
      headerClassName: "header",
    },
    {
      field: "Facturacion",
      headerName: "Facturación total histórica",
      headerAlign: "center",
      flex: 1,
      sortable: false,
      headerClassName: "header",
    },
    {
      field: "Estatus",
      headerName: "Estatus",
      headerAlign: "center",
      flex: 0.8,
      sortable: false,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <div style={{ margin: "auto" }}>
            {params.row.Estatus == "Pagado" ? (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  style={{
                    width: "18px",
                    height: "18px",
                    color: "#ac0bce",
                    marginRight: 8,
                  }}
                />
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 16,
                    color: "#fff",
                  }}
                >
                  {params.row.Estatus}
                </Typography>
              </Grid>
            ) : (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{
                  width: 86,
                  height: 24,
                  borderRadius: 12,
                  backgroundColor: "#eb1818",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 16,
                    color: "#fff",
                  }}
                >
                  {params.row.Estatus}
                </Typography>
              </Grid>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <DataGrid
      rows={props.rows}
      columns={columns}
      loading={props.loading}
      rowCount={props.rowCount}
      rowsPerPageOptions={props.rowsPerPageOptions}
      page={props.page}
      pageSize={props.pageSize}
      onPageChange={props.onPageChange}
      onPageSizeChange={props.onPageSizeChange}
      style={props.style}
      onSelectionModelChange={props.onSelectionModelChange}
      selectionModel={props.selectionModel}
    />
  );
}
