/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typo from "../../../common_components/typo";
import PhotoQuality from "../../../images/photo_requirements.png";

const useStyles = makeStyles((theme) => ({
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: "bold",
    textAlign: "left",
    color: "#cdcece",
  },
  textFieldCustom: {
    width: "350px",
    color: "#676767",
    border: "solid 1px #c5c5c5",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
  },
  divImg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "144px",
    height: "144px",
    borderRadius: "8px",
    backgroundColor: "#2d3034",
    cursor: "pointer",
  },
  trackVertical: {
    height: "95%",
    right: 0,
    width: "10px !important",
  },
  thumbVertical: {
    backgroundColor: "#2d3034",
    height: "100%",
    right: 0,
    width: "8px !important",
    borderRadius: "8px",
  },
  photo: {
    width: "120px",
    height: "120px",
    borderRadius: "8px",
    aspectRatio: 1,
    // position: "absolute",
    objectFit: "cover",
  },
}));

export default function DialogPhotoQuality(props) {
  const classes = useStyles();
  // const [disableButton, setDisableButton] = useState(false);

  const handleOpenDialog = () => {
    props.setOpenDialogPhoto(!props.openDialogPhoto);
  };

  return (
    <Dialog
      open={props.openDialogPhoto}
      onClose={handleOpenDialog}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        style: {
          width: "483px",
          height: "647px",
          paddingTop: 10,
          textColor: "#fsf",
          backgroundColor: "#171a1e",
          borderRadius: "8px",
        },
      }}
    >
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 18,
              textAlign: "center",
              color: "#fff",
              fontWeight: "bold",
            }}
          >
            Error en carga de foto
          </Typography>

          <Grid
            container
            justifyContent="flex-end"
            style={{ position: "absolute" }}
          >
            <Button onClick={handleOpenDialog}>
              <FontAwesomeIcon
                icon={faTimes}
                style={{
                  width: "20px",
                  height: "20px",
                  color: "#fff",
                }}
              />
            </Button>
          </Grid>
        </Grid>
        <div
          style={{
            width: "100%",
            height: 1,
            background: "#707070",
            marginTop: 8,
            padding: 0,
          }}
        ></div>
      </DialogTitle>

      <DialogContent>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typo
            fontSize={18}
            color={"#fff"}
            textAlign={"center"}
            text={
              "Revisa la calidad y la siguientes características se cumplan con la foto que deseas subir"
            }
          />

          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{
              marginTop: 50,
            }}
          >
            <Grid
              item
              xs={5}
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{
                  width: "144px",
                  height: "168px",
                  borderRadius: "12px",
                  backgroundColor: "#2d3034",
                }}
              >
                <img
                  // src={props.employeeData ? props.employeeData.fotoCache : ""}
                  src={PhotoQuality}
                  className={classes.photo}
                  alt="photo"
                />
              </Grid>
            </Grid>

            <Grid
              item
              xs={7}
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Typo
                fontSize={16}
                color={"#c9c9c9"}
                textAlign={"center"}
                text={"Fondo blanco en foto"}
              />
              <Typo
                fontSize={16}
                color={"#c9c9c9"}
                textAlign={"center"}
                text={"Rostro sin lentes y despejado"}
                marginTop={10}
              />
              <Typo
                fontSize={16}
                color={"#c9c9c9"}
                textAlign={"center"}
                text={"Foto tomada de frente"}
                marginTop={10}
              />
              <Typo
                fontSize={16}
                color={"#c9c9c9"}
                textAlign={"center"}
                text={"No mayor a 10Mb"}
                marginTop={10}
              />
              <Typo
                fontSize={16}
                color={"#c9c9c9"}
                textAlign={"center"}
                text={"Buena iluminación en la foto"}
                marginTop={10}
              />
            </Grid>
          </Grid>

          <Typo
            fontSize={18}
            color={"#fff"}
            textAlign={"center"}
            text={"¿Tu foto cumple con esos puntos?"}
            marginTop={60}
          />

          <Button
            variant="contained"
            // disabled={disableButton}
            onClick={() => {
              props.setOpenDialogPhoto(!props.openDialogPhoto);
              props.setOpenDialogSibling(!props.openDialogSibling);
            }}
            style={{
              width: "320px",
              height: "48px",
              borderRadius: "8px",
              backgroundColor: "#ac0bce",
              textTransform: "none",
              marginTop: 30,
            }}
          >
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 18,
                textAlign: "left",
                color: "#fff",
              }}
            >
              Si
            </Typography>
          </Button>

          <Button
            variant="contained"
            // disabled={disableButton}
            onClick={() => {
              props.setDisableButton(false);
              props.setOpenDialogPhoto(!props.openDialogPhoto);
            }}
            style={{
              width: "320px",
              height: "48px",
              borderRadius: "8px",
              backgroundColor: "#8f8f8f",
              textTransform: "none",
              marginTop: 30,
            }}
          >
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 20,
                textAlign: "left",
                color: "#fff",
              }}
            >
              No
            </Typography>
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
