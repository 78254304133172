/*eslint-disable*/
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import photo from "../../../../images/profile@2x.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook } from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles((theme) => ({
  divMain: {
    maxWidth: 345,
  },
  card: {
    width: "auto",
    minWidth: 173,
    minHeight: 40,
    height: "auto",
    borderRadius: 8,
    backgroundColor: "#4f5257",
    padding: 4,
  },
  griCard: {
    padding: theme.spacing(2),
  },
  noWrap: {
    textOverflow: "ellipsis",
    wordWrap: "anywhere",
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
  },
}));

export default function CardAttendanceEventsExtra(props) {
  const classes = useStyles();
  const { data } = props;

  return (
    <Card className={classes.card}>
      <Grid container direction="row">
        <Grid item xs={3}>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <FontAwesomeIcon
              icon={faBook}
              style={{
                width: 13,
                height: 14,
                color: "#fff",
                marginTop: 10,
              }}
            />
          </Grid>
        </Grid>

        <Grid item xs={8}>
          <Grid container direction="column">
            <Typography
            className={classes.noWrap}
              style={{
                fontFamily: "Roboto",
                fontSize: 14,
                textAlign: "left",
                color: "#fff",
                fontWeight: 500,
                lineHeight: 1.3,
                width: "95%"
              }}
            >
              {data?.tipoEvento}
            </Typography>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 14,
                textAlign: "left",
                color: "#acacac",
                lineHeight: 1.3,
              }}
            >
              {new Date(data?.datetime).toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'})}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}
