/*eslint-disable*/
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { Grid, Typography, Button } from "@material-ui/core";
import Lottie from "react-lottie";
import SecurityAnimation from "../../animations/techton_security.json";

const useStyles = makeStyles((theme) => ({
  divMain: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "#171a1e",
  },
  logo: {
    marginLeft: "50px",
    width: "230px",
    height: "120px",
  },
  gridMain: {
    position: "absolute",
    top: "20%",
    backgroundColor: "#171a1e",
  },
}));

export default function Unauthorized(props) {
  const classes = useStyles();
  const history = useHistory();

  const backDashboard = () => {
    history.goBack();
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: SecurityAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className={classes.divMain}>
      <Grid
        item
        xs={12}
        container
        direction="row"
        justifyContent="flex-start"
        className={classes.gridMain}
      >
        <Grid item xs={2}></Grid>
        <Grid item xs={5} container direction="column" justifyContent="center">
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 40,
              fontWeight: 500,
              color: "#fff",
            }}
          >
            UPS, SIN AUTORIZACIÓN
          </Typography>
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 22,
              color: "#aaa",
              paddingBottom: "20px",
            }}
          >
            ERROR 403
          </Typography>
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 26,
              fontWeight: 300,
              color: "#bebebe",
              paddingBottom: "20px",
            }}
          >
            Parece ser que no tienes los permisos para ver esta pagina
          </Typography>
          <Button
            variant="contained"
            onClick={backDashboard}
            style={{
              width: "240px",
              height: "48px",
              borderRadius: "8px",
              backgroundColor: "#2d3034",
              textTransform: "none",
            }}
          >
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 18,
                color: "#ffffff",
              }}
            >
              Regresar
            </Typography>
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Lottie options={defaultOptions} height={400} width={400} />
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
    </div>
  );
}
