/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import AssignSiteComponent from "./AssignSiteComponent";
import ScrollBars from "../../common_components/ScrollBars";
import Lottie from "react-lottie";
import LottieNotFound from "../../animations/lottie_not_found.json";
import { ValidatePermissions } from "../../utils/permissions";
import axios from "axios";
import axiosInstance from "../../utils/axiosInstance";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    // height: "100%",
    // backgroundColor: "red",
  },
  gridFile: {
    padding: theme.spacing(3),
    height: 425,
    borderRadius: "16px",
    backgroundColor: "#2d3034",
  },
}));

export default function CardAssignmentsEnterprise(props) {
  const classes = useStyles();
  const history = useHistory();
  const [trabajadoresSub, setTrabajadoresSub] = useState([]);

  const handleView = () => {
    history.push(`/dashboard/obras`);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LottieNotFound,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    // console.log(props.proyectos);
    if (props.proyectos) {
      const promisearray = props.proyectos.map((obra, index) => {
        // console.log(obra);
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        var params = new URLSearchParams();
        params.append("limit", 1);
        params.append("page", 0);
        params.append("proyectos_id", obra?._id);
        params.append("subcontratistas_id", props.id);

        return axiosInstance()
          .get(`/api/v1/rules/trabajadores/filter`, {
            params,
            cancelToken: source.token,
          })
          .then((response) => {
            // console.log(response);
            return response;
          })
          .catch((e) => {
            console.log(e.response?.data);
            return;
          });
      });

      Promise.all(promisearray)
        .then((response) => {
          var array = [];
          array = response?.map((res) => res?.data?.data?.Total);
          setTrabajadoresSub(array);
        })
        .catch((err) => console.log(err));
    }
  }, [props.proyectos]);

  return (
    <div className={classes.divMain}>
      <Grid item xs={12} className={classes.gridFile}>
        <Grid container direction="row" justifyContent="flex-start">
          <Grid
            item
            xs={10}
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 22,
                fontWeight: 500,
                color: "#fff",
                marginRight: 10,
              }}
            >
              Obras
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "37px",
                height: "24px",
                borderRadius: "8px",
                backgroundColor: "#dedede",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 14,
                  lineHeight: 1.36,
                  textAlign: "left",
                  color: "#d233ff",
                }}
              >
                {props.proyectos ? props.proyectos?.length : 0}
              </Typography>
            </div>
          </Grid>

          {ValidatePermissions("editar") && (
            // user?.Subcontractor_id !=
            //   localStorage.getItem("idSubcontractor") &&
            <Grid
              item
              xs={2}
              container
              direction="row"
              justifyContent="flex-end"
            >
              <Typography
                onClick={handleView}
                style={{
                  fontFamily: "Roboto",
                  fontSize: 18,
                  fontWeight: 500,
                  color: "#d233ff",
                  cursor: "pointer",
                }}
              >
                Ver
              </Typography>
            </Grid>
          )}
        </Grid>

        {props.proyectos?.length > 0 ? (
          <ScrollBars style={{ height: 320, marginTop: 20 }} color="#212429">
            {props.proyectos?.map((proyecto, index) => (
              <AssignSiteComponent
                key={index}
                site={proyecto.proyecto}
                address={proyecto.domicilio}
                employees={
                  trabajadoresSub.length > 0 ? trabajadoresSub[index] : 0
                }
              />
            ))}
          </ScrollBars>
        ) : (
          <Grid container direction="column" style={{ margin: "auto" }}>
            <Lottie options={defaultOptions} height={150} width={164} />
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 16,
                textAlign: "center",
                color: "#c1c1c1",
              }}
            >
              Sin obras de asignadas
            </Typography>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
