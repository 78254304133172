/*eslint-disable*/
import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import ShowMoreButton from "../../components/ShowMoreButton";
import {
  ValidatePermissions,
  ValidateStatus,
} from "../../../../utils/permissions";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    // height: "100%",
    // backgroundColor: "red",
  },
  gridHealth: {
    padding: theme.spacing(3),
    // height: "320px",
    height: "100%",
    borderRadius: "16px",
    backgroundColor: "#2d3034",
  },
}));

export default function CardHealthyDataEmployee(props) {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useSelector((state) => state.user);
  const [moreData, setMoreData] = useState(false);

  const handleMoreData = () => {
    setMoreData(!moreData);
  };

  return (
    <div className={classes.divMain}>
      <Grid item xs={12} className={classes.gridHealth}>
        <Grid container direction="row">
          <Grid item xs={10}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 22,
                fontWeight: 500,
                color: "#fff",
              }}
            >
              Datos de salud
            </Typography>
          </Grid>

          {ValidatePermissions("editar") &&
            ValidateStatus(props.employeeData.statusDemek, user) && (
              <Grid
                item
                xs={2}
                container
                direction="row"
                justifyContent="flex-end"
              >
                <Typography
                  onClick={() =>
                    history.push(
                      `/dashboard/empleados/editar/${localStorage.getItem(
                        "idEmployee"
                      )}`
                    )
                  }
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 18,
                    fontWeight: 500,
                    color: "#d233ff",
                    cursor: "pointer",
                  }}
                >
                  Editar
                </Typography>
              </Grid>
            )}
        </Grid>

        <Grid container direction="row" style={{ marginTop: 20 }}>
          <Grid item xs={6}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 16,
                color: "#c1c1c1",
              }}
            >
              Tipo de sangre
            </Typography>
          </Grid>
          <Grid item xs={6} container direction="row" justifyContent="flex-end">
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 18,
                fontWeight: 500,
                color: "#fff",
              }}
            >
              {props.employeeData.sangre}
            </Typography>
          </Grid>
        </Grid>

        <Grid container direction="row" style={{ marginTop: 10 }}>
          <Grid
            item
            xs={6}
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 16,
                color: "#c1c1c1",
                marginRight: 5,
              }}
            >
              Número IMSS
            </Typography>
            {(user?.EnterpriseData?.ValidateImss &&
              props.employeeData?.estatusImss?.estatusActual ==
                "Información Incorrecta" &&
              props.employeeData?.estatusImss?.messageErrorBot ==
                `El NSS ${props.employeeData.imss} no fue localizado en el IMSS.`) ||
            props.employeeData?.estatusImss?.messageErrorBot ==
              "El NSS capturado no coincide con la CURP." ? (
              <FontAwesomeIcon
                icon={faExclamationCircle}
                style={{
                  width: "16px",
                  height: "16px",
                  color: "#ff0000",
                  paddingBottom: 2,
                }}
              />
            ) : null}
          </Grid>
          <Grid item xs={6} container direction="row" justifyContent="flex-end">
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 18,
                fontWeight: 500,
                color: "#fff",
              }}
            >
              {props.employeeData.imss}
            </Typography>
          </Grid>
        </Grid>

        <Grid container direction="row" style={{ marginTop: 10 }}>
          <Grid item xs={6}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 16,
                color: "#c1c1c1",
              }}
            >
              Nombre contacto emergencia
            </Typography>
          </Grid>
          <Grid item xs={6} container direction="row" justifyContent="flex-end">
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 18,
                fontWeight: 500,
                color: "#fff",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {props.employeeData.contactoEmergencia}
            </Typography>
          </Grid>
        </Grid>

        <Grid container direction="row" style={{ marginTop: 10 }}>
          <Grid item xs={6}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 16,
                color: "#c1c1c1",
              }}
            >
              Teléfono contacto emergencia
            </Typography>
          </Grid>
          <Grid item xs={6} container direction="row" justifyContent="flex-end">
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 18,
                fontWeight: 500,
                color: "#fff",
              }}
            >
              {props.employeeData.telefono1}
            </Typography>
          </Grid>
        </Grid>

        <Grid container direction="row" style={{ marginTop: 10 }}>
          <Grid item xs={6}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 16,
                color: "#c1c1c1",
              }}
            >
              Teléfono contacto emergencia 2
            </Typography>
          </Grid>
          <Grid item xs={6} container direction="row" justifyContent="flex-end">
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 18,
                fontWeight: 500,
                color: "#fff",
              }}
            >
              {props.employeeData.telefono2}
            </Typography>
          </Grid>
        </Grid>

        {moreData ? (
          <Fragment>
            <Grid container direction="row" style={{ marginTop: 10 }}>
              <Grid item xs={6}>
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 16,
                    color: "#c1c1c1",
                  }}
                >
                  Fuma
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                container
                direction="row"
                justifyContent="flex-end"
              >
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 18,
                    fontWeight: 500,
                    color: "#fff",
                  }}
                >
                  {props.employeeData.fuma}
                </Typography>
              </Grid>
            </Grid>

            <Grid container direction="row" style={{ marginTop: 10 }}>
              <Grid item xs={6}>
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 16,
                    color: "#c1c1c1",
                  }}
                >
                  Bebe alcohol
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                container
                direction="row"
                justifyContent="flex-end"
              >
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 18,
                    fontWeight: 500,
                    color: "#fff",
                  }}
                >
                  {props.employeeData.bebeAlcol}
                </Typography>
              </Grid>
            </Grid>

            <Grid container direction="row" style={{ marginTop: 10 }}>
              <Grid item xs={6}>
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 16,
                    color: "#c1c1c1",
                  }}
                >
                  Ha usado drogas
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                container
                direction="row"
                justifyContent="flex-end"
              >
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 18,
                    fontWeight: 500,
                    color: "#fff",
                  }}
                >
                  {props.employeeData.haUsadoDrogas}
                </Typography>
              </Grid>
            </Grid>

            <Grid container direction="row" style={{ marginTop: 10 }}>
              <Grid item xs={6}>
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 16,
                    color: "#c1c1c1",
                  }}
                >
                  Actividad física
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                container
                direction="row"
                justifyContent="flex-end"
              >
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 18,
                    fontWeight: 500,
                    color: "#fff",
                  }}
                >
                  {props.employeeData.actividadFisica}
                </Typography>
              </Grid>
            </Grid>

            <Grid container direction="row" style={{ marginTop: 10 }}>
              <Grid item xs={6}>
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 16,
                    color: "#c1c1c1",
                  }}
                >
                  Peso (Kg)
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                container
                direction="row"
                justifyContent="flex-end"
              >
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 18,
                    fontWeight: 500,
                    color: "#fff",
                  }}
                >
                  {props.employeeData.peso}
                </Typography>
              </Grid>
            </Grid>

            <Grid container direction="row" style={{ marginTop: 10 }}>
              <Grid item xs={6}>
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 16,
                    color: "#c1c1c1",
                  }}
                >
                  Altura (Cm)
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                container
                direction="row"
                justifyContent="flex-end"
              >
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 18,
                    fontWeight: 500,
                    color: "#fff",
                  }}
                >
                  {props.employeeData.altura}
                </Typography>
              </Grid>
            </Grid>

            <Grid container direction="row" style={{ marginTop: 10 }}>
              <Grid item xs={6}>
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 16,
                    color: "#c1c1c1",
                  }}
                >
                  Alergias
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                container
                direction="row"
                justifyContent="flex-end"
              >
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 18,
                    fontWeight: 500,
                    color: "#fff",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {props.employeeData.alergias == "Si"
                    ? props.employeeData.tipoAlergia
                    : props.employeeData.alergias}
                </Typography>
              </Grid>
            </Grid>

            <Grid container direction="row" style={{ marginTop: 10 }}>
              <Grid item xs={6}>
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 16,
                    color: "#c1c1c1",
                  }}
                >
                  Enfermedad crónica
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                container
                direction="row"
                justifyContent="flex-end"
              >
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 18,
                    fontWeight: 500,
                    color: "#fff",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {props.employeeData.enfermedadCronica == "Si"
                    ? props.employeeData.tipoEnfermedadCronica
                    : props.employeeData.enfermedadCronica}
                </Typography>
              </Grid>
            </Grid>

            <Grid container direction="row" style={{ marginTop: 10 }}>
              <Grid item xs={6}>
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 16,
                    color: "#c1c1c1",
                  }}
                >
                  Tratamiento médico
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                container
                direction="row"
                justifyContent="flex-end"
              >
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 18,
                    fontWeight: 500,
                    color: "#fff",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {props.employeeData.tratamientoMedico == "Si"
                    ? props.employeeData.tipoTratamientoMedico
                    : props.employeeData.tratamientoMedico}
                </Typography>
              </Grid>
            </Grid>

            <Grid container direction="row" style={{ marginTop: 10 }}>
              <Grid item xs={6}>
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 16,
                    color: "#c1c1c1",
                  }}
                >
                  Medicamento
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                container
                direction="row"
                justifyContent="flex-end"
              >
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 18,
                    fontWeight: 500,
                    color: "#fff",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {props.employeeData.medicamento == "Si"
                    ? props.employeeData.tipoMedicamento
                    : props.employeeData.medicamento}
                </Typography>
              </Grid>
            </Grid>

            <Grid container direction="row" style={{ marginTop: 10 }}>
              <Grid item xs={6}>
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 16,
                    color: "#c1c1c1",
                  }}
                >
                  Lentes de seguridad con graduación
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                container
                direction="row"
                justifyContent="flex-end"
              >
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 18,
                    fontWeight: 500,
                    color: "#fff",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {props.employeeData.lentes == "Si"
                    ? props.employeeData.tipoLentes
                    : props.employeeData.lentes}
                </Typography>
              </Grid>
            </Grid>
          </Fragment>
        ) : (
          <div></div>
        )}

        <Grid
          item
          xs={12}
          container
          direction="row"
          justifyContent="center"
          style={{ marginTop: 10 }}
        >
          <ShowMoreButton onClick={handleMoreData} moreData={moreData} />
        </Grid>
      </Grid>
    </div>
  );
}
