import { types } from "../types/types";
// colores absa del mas ligero al mas fuerte
// #2E9AFE, #0C6BD8, #0040FF

const initialState = {
  colors: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#0c6bd8",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      // light: will be calculated from palette.primary.main,
      main: "#555555",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    neutral: {
      primary: "#fff",
      secondary: "#000",
      tertiary: "#2e2e2e",
      fourth: "#aeaeae",
      fifth: "#acacac",
      sixth: "#dbdbdb",
      seventh: "#e8e8e8",
      eighth: "#787878",
    },
  },
  typo: {},
};

export const configReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.loadConfig:
      return {
        ...state,
        config: action.payload,
      };

    case types.loadColors:
      return {
        ...state,
        colors: action.payload,
      };

    case types.reset:
      return initialState;

    default:
      return state;
  }
};
