/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import InfoProfile from "./components/InfoProfile";
import InfoPassword from "./components/InfoPassword";
import InfoEnterprise from "./components/InfoEnterprise";
import FlowComponent from "./components/FlowComponent";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../utils/axiosInstance";
import DialogBackScreen from "./components/DialogBackScreen";
import { CapitalizeString } from "../../utils/CapitalizeString";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    paddingLeft: "30px",
    // backgroundColor: "blue",
  },
  divBack: {
    cursor: "pointer",
    marginBottom: "20px",
  },
  back: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 500,
    textAlign: "left",
    color: "#ffffff",
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 24,
    fontWeight: 500,
    textAlign: "left",
    color: "#ffffff",
  },
  subtitle: {
    fontFamily: "Roboto",
    fontSize: 14,
    textAlign: "left",
    color: "#ffffff",
  },
  icon: {
    width: "35px",
    height: "35px",
    color: "white",
    backgroundColor: "#a2a2a2",
    borderRadius: "15px",
  },
  gridMargin: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 20,
    },
  },
  gridMargin2: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
}));

export default function ProfileScreen(props) {
  const classes = useStyles();
  const { user } = useSelector((state) => state.user);
  const { screenProfile } = useSelector((state) => state.screen);
  const [tab, setTab] = useState();
  const [disableButton, setDisableButton] = useState(false);
  const [changesFlag, setChangesFlag] = useState(false);
  const [mandatoryFlag, setMandatoryFlag] = useState(false);
  const [openDialogBack, setOpendialogBack] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [colorButtonSave, setColorButtonSave] = useState({
    backColor: "#474A4E",
    text: "#919191",
  });
  const [userData, setUserData] = useState({
    idUser: "",
    foto: "",
    fotoCache: "",
    logo: "",
    logoCache: "",
    nombre: "",
    correo: "",
    telefono: "",
    empresa: "",
    direccion: "",
    password: "",
    newPassword: "",
    confirmPassword: "",
    fotoReq: false,
  });

  const renderRegisterScreen = () => {
    if (screenProfile == 1)
      return (
        <InfoProfile
          userData={userData}
          setUserData={setUserData}
          setChangesFlag={setChangesFlag}
          setMandatoryFlag={setMandatoryFlag}
        />
      );
    if (screenProfile == 2)
      return (
        <InfoPassword
          userData={userData}
          setUserData={setUserData}
          setChangesFlag={setChangesFlag}
          setMandatoryFlag={setMandatoryFlag}
        />
      );
    // if (screenProfile == 3) return <InfoFiles />;
    if (screenProfile == 4)
      return (
        <InfoEnterprise
          userData={userData}
          setUserData={setUserData}
          setChangesFlag={setChangesFlag}
          setMandatoryFlag={setMandatoryFlag}
        />
      );
    // if (screenProfile == 5) return <InfoFiles />;
  };

  const updateDataUser = async () => {
    // console.log(userData);
    if (mandatoryFlag == false) {
      enqueueSnackbar("Favor de llenar campos obligatorios", {
        variant: "error",
      });
      return;
    }

    // setDisableButton(true);
    if (screenProfile == 1) {
      if (!(await updateUser())) {
        enqueueSnackbar("Error al actualizar información de usuario", {
          variant: "error",
        });
        return false;
      }
    }
    if (screenProfile == 2) {
      if (!(await changePassword())) return false;
    }
    if (screenProfile == 4) {
      if (!(await updateEnterprise())) {
        enqueueSnackbar("Error al actualizar información de usuario", {
          variant: "error",
        });
        return false;
      }
    }

    setChangesFlag(false);
    // setTimeout(() => {
    //   setDisableButton(false);
    // }, 2000);
    return true;
  };

  const updateUser = async () => {
    // console.log(typeof userData.foto);
    if (typeof userData.foto === "string") {
      let body = {
        Name: CapitalizeString(userData.nombre),
        Email: userData.correo,
        Phone: userData.telefono,
        foto: userData.foto,
        // PhotoReq: false,
      };

      return await axiosInstance()
        .patch(`/api/v1/security/user/profile/`, body)
        .then((response) => {
          // console.log(response.data);
          enqueueSnackbar("Usuario actualizado correctamente", {
            variant: "success",
          });
          return true;
        })
        .catch((e) => {
          console.log(e.response);
          return false;
        });
    } else {
      let formData = new FormData();
      formData.append("file", userData.foto);
      formData.append(
        "data",
        JSON.stringify({
          Name: CapitalizeString(userData.nombre),
          Email: userData.correo,
          Phone: userData.telefono,
          // PhotoReq: false,
        })
      );

      return await axiosInstance()
        .put(`/api/v1/security/user/profile/`, formData)
        .then((response) => {
          // console.log(response.data);
          enqueueSnackbar("Usuario actualizado correctamente", {
            variant: "success",
          });
          return true;
        })
        .catch((e) => {
          console.log(e);
          return false;
        });
    }
  };

  const login = async () => {
    // if (user) return false;

    return await axiosInstance()
      .post(`/api/v1/security/user/login/`, {
        Email: user?.Email,
        Password: userData.password,
      })
      .then((response) => {
        // console.log(response.data);
        return true;
      })
      .catch((e) => {
        console.log(e.response);
        return false;
      });
  };

  const changePassword = async () => {
    if (
      userData.password == "" &&
      userData.newPassword == "" &&
      userData.confirmPassword == ""
    ) {
      enqueueSnackbar("Favor de llenar los campos obligatorios", {
        variant: "error",
      });
      return false;
    }

    if (!(await login())) {
      enqueueSnackbar("La contraseña actual no coincide, favor de verificar", {
        variant: "error",
      });
      return false;
    }

    if (userData.newPassword != userData.confirmPassword) {
      enqueueSnackbar("La contraseña nueva no coincide, favor de verificar", {
        variant: "error",
      });
      return false;
    }

    return await axiosInstance()
      .post(`/api/v1/security/user/password/change/`, {
        Password: userData.newPassword,
      })
      .then((response) => {
        // console.log(response.data);
        enqueueSnackbar("Usuario actualizado correctamente", {
          variant: "success",
        });
        return true;
      })
      .catch((e) => {
        console.log(e);
        enqueueSnackbar("Error al actualizar información de usuario", {
          variant: "error",
        });
        return false;
      });
  };

  const updateEnterprise = async () => {
    // console.log(typeof userData.logo);
    if (typeof userData.logo === "string") {
      let body = {
        // EnterpriseName: CapitalizeString(userData.empresa),
        EnterpriseName: userData.empresa,
        // LicenseId: code,
        Location: [
          {
            LocationName: CapitalizeString(userData.empresa),
            Address: userData.direccion,
            // Latitude: "",
            // Longitude: "",
          },
        ],
      };

      return await axiosInstance()
        .patch(`/api/v1/security/enterprise/`, body)
        .then((response) => {
          // console.log(response.data.data);
          enqueueSnackbar("Usuario actualizado correctamente", {
            variant: "success",
          });
          return true;
        })
        .catch((e) => {
          console.log(e.response);
          return false;
        });
    } else {
      let formData = new FormData();
      formData.append("file", userData.logo);
      formData.append(
        "data",
        JSON.stringify({
          EnterpriseName: CapitalizeString(userData.empresa),
          // LicenseId: code,
          Location: [
            {
              LocationName: CapitalizeString(userData.empresa),
              Address: userData.direccion,
              Latitude: "",
              Longitude: "",
            },
          ],
        })
      );

      return await axiosInstance()
        .put(`/api/v1/security/enterprise/`, formData)
        .then((response) => {
          // console.log(response.data.data);
          enqueueSnackbar("Usuario actualizado correctamente", {
            variant: "success",
          });
          return true;
        })
        .catch((e) => {
          console.log(e.response);
          return false;
        });
    }
  };

  const restartUserData = async () => {
    return await axiosInstance()
      .get(`/api/v1/security/user/profile/`)
      .then((response) => {
        // console.log("load user", response.data.data);
        setUserData({
          ...userData,
          idUser: response.data.data._id,
          foto: response.data.data.foto
            ? response.data.data.foto
            : userData.foto,
          fotoCache: response.data.data.foto
            ? response.data.data.foto
            : userData.foto,
          nombre: response.data.data.Name
            ? response.data.data.Name
            : userData.nombre,
          correo: response.data.data.Email
            ? response.data.data.Email
            : userData.correo,
          telefono: response.data.data.Phone
            ? response.data.data.Phone
            : userData.telefono,
          logo: response.data.data.EnterpriseData.foto
            ? response.data.data.EnterpriseData.foto
            : userData.logo,
          logoCache: response.data.data.EnterpriseData.foto
            ? response.data.data.EnterpriseData.foto
            : userData.logo,
          empresa: response.data.data.EnterpriseData.EnterpriseName
            ? response.data.data.EnterpriseData.EnterpriseName
            : userData.empresa,
          direccion: response.data.data.EnterpriseData.Location[0]
            ? response.data.data.EnterpriseData.Location[0].Address
            : userData.direccion,
          fotoReq: response.data.data.PhotoReq
            ? response.data.data.PhotoReq
            : userData.fotoReq,
        });
      })
      .catch((e) => {
        console.log(e.response);
      });
  };

  useEffect(() => {
    if (mandatoryFlag == true) {
      setColorButtonSave({
        ...colorButtonSave,
        backColor: "#ac0bce",
        text: "#fff",
      });
    } else {
      setColorButtonSave({
        ...colorButtonSave,
        backColor: "#474A4E",
        text: "#919191",
      });
    }
  }, [mandatoryFlag]);

  useEffect(() => {
    restartUserData();
  }, []);

  return (
    <div className={classes.divMain}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        className={classes.gridMargin}
      >
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
          <FlowComponent
            setTab={setTab}
            changesFlag={changesFlag}
            setChangesFlag={setChangesFlag}
            openDialogBack={openDialogBack}
            setOpendialogBack={setOpendialogBack}
            mandatoryFlag={mandatoryFlag}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={9}
          lg={9}
          xl={9}
          className={classes.gridMargin2}
        >
          {renderRegisterScreen()}
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        style={{
          marginTop: 50,
          // backgroundColor: "red",
        }}
      >
        <Grid item md={3} lg={3} xl={3}></Grid>
        <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
          <Button
            variant="contained"
            //   disabled={disableButton}
            onClick={updateDataUser}
            style={{
              width: "160px",
              height: "48px",
              borderRadius: "8px",
              backgroundColor: colorButtonSave.backColor,
            }}
          >
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 21,
                textAlign: "left",
                color: colorButtonSave.text,
                textTransform: "none",
              }}
            >
              Guardar
            </Typography>
          </Button>
        </Grid>
      </Grid>

      <DialogBackScreen
        tab={tab}
        updateDataUser={updateDataUser}
        restartUserData={restartUserData}
        setChangesFlag={setChangesFlag}
        openDialogBack={openDialogBack}
        setOpendialogBack={setOpendialogBack}
      />
    </div>
  );
}
