/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Checkbox, Typography } from "@material-ui/core";
import { Grid, TextField } from "@material-ui/core";
import { Select, FormControl, MenuItem } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faPlus } from "@fortawesome/free-solid-svg-icons";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import { useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";

const useStyles = makeStyles((theme) => ({
  divImg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "160px",
    height: "160px",
    borderRadius: "16px",
    border: "solid 1px #707070",
    backgroundColor: "#212429",
    cursor: "pointer",
    marginBottom: 15,
  },
  photo: {
    width: "90%",
    height: "90%",
    borderRadius: "8px",
    aspectRatio: 1,
    // position: "absolute",
    objectFit: "cover",
  },
  icon: {
    width: "40px",
    height: "40px",
    color: "white",
    marginBottom: "20px",
  },
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: "bold",
    lineHeight: 1.35,
    textAlign: "left",
    color: "#cdcece",
    marginBottom: 2,
  },
  textFieldCustom: {
    width: "100%",
    color: "#676767",
    border: "solid 1px #c5c5c5",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
  },
  formControl: {
    width: "100%",
  },
  select: {
    width: "100%",
    height: "40px",
    borderRadius: "6px",
    backgroundColor: "#fff",
  },
  button: {
    width: "320px",
    height: "48px",
    borderRadius: "8px",
    backgroundColor: "#ac0bce",
    textTransform: "none",
  },
  typoButton: {
    fontFamily: "Roboto",
    fontSize: 21,
    lineHeight: 1.33,
    textAlign: "left",
    color: "#fff",
  },
  typoCheckbox: {
    fontFamily: "Roboto",
    fontSize: 18,
    fontWeight: "bold",
    lineHeight: 1.33,
    textAlign: "left",
    color: "#cdcece",
  },
  checkbox: {
    // padding: 2,
    color: "#484848",
    "&.Mui-checked": {
      color: "#d233ff",
    },
    "& .MuiSvgIcon-root": { fontSize: 32 },
  },
  buttonAdd: {
    width: "100%",
    height: "48px",
    borderRadius: 8,
    border: "solid 2px #707070",
    borderStyle: "dashed",
    textTransform: "none",
    marginTop: 10,
  },
  gridMargin: {
    marginBottom: 15,
  },
}));

export default function DialogNewWarehouse(props) {
  const classes = useStyles();
  const { user } = useSelector((state) => state.user);
  const [questionType, setQuestionType] = useState("");
  //   const { books, hasMore, loading, error } = useUnitSearch(query, unitsFlag);

  const handleOpenDialog = () => {
    props.setOpenDialogNewWarehouse(!props.openDialogNewWarehouse);
  };

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    noKeyboard: true,
    accept: "image/*",
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      acceptedFiles.map((file) => processImage(file));
    },
    // noClick: true
  });

  const processImage = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let fileURL = window.URL.createObjectURL(file);
      // props.setEmployeeData({
      //   ...props.employeeData,
      //   foto: file,
      //   fotoCache: fileURL,
      // });
      // props.setChangesFlag(true);
    };
  };

  const handleChange = (e) => {
    const value = e.target.value;
    // setQuery({
    //   ...query,
    //   [e.target.name]: value,
    // });
  };

  // useEffect(() => {
  //   console.log(questionType);
  // }, [questionType]);

  return (
    <div>
      <Dialog
        // open={true}
        open={props.openDialogNewWarehouse}
        onClose={handleOpenDialog}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            backgroundColor: "#171a1e",
            textColor: "#fsf",
            width: "483px",
            height: "691px",
            padding: "15px 10px 0px 10px ",
            borderRadius: 8,
          },
        }}
      >
        <DialogTitle>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 22,
                textAlign: "center",
                color: "#fff",
                fontWeight: "bold",
              }}
            >
              Nuevo almacén
            </Typography>

            <Grid
              container
              justifyContent="flex-end"
              style={{ position: "absolute" }}
            >
              <Button onClick={handleOpenDialog}>
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{
                    width: "20px",
                    height: "20px",
                    color: "#fff",
                  }}
                />
              </Button>
            </Grid>
          </Grid>
          <div
            style={{
              width: "100%",
              height: 1,
              background: "#707070",
              marginTop: 8,
              padding: 0,
            }}
          ></div>
        </DialogTitle>

        <DialogContent>
          <Grid
            container
            // direction="column"
            // justifyContent="flex-start"
            style={{
              height: 510,
              //   backgroundColor: "red",
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              //   style={{
              //     backgroundColor: "blue",
              //   }}
            >
              <div className={classes.divImg} /* {...getRootProps() */>
                {/* <input {...getInputProps()} />

                {props.employeeData.fotoCache ? (
                  <img
                    src={props.employeeData.fotoCache}
                    className={classes.photo}
                    alt="fotoCache"
                  />
                ) : ( */}
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <AddToPhotosIcon className={classes.icon} />
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 16,
                      fontWeight: 500,
                      lineHeight: "1.13",
                      textAlign: "center",
                      color: "#d9d9d9",
                      htmlFor: "fileInput",
                      marginBottom: 5,
                    }}
                  >
                    Subir imagen
                  </Typography>
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 12,
                      fontWeight: 300,
                      lineHeight: 1.5,
                      textAlign: "center",
                      color: "#aeaeae",
                    }}
                  >
                    (Opcional)
                  </Typography>
                </Grid>
                {/* )} */}
              </div>

              <Grid container direction="column" justifyContent="space-between">
                <Grid item xs={12} className={classes.gridMargin}>
                  <Typography className={classes.textFieldLetter}>
                    Nombre de almacén
                  </Typography>
                  <TextField
                    // placeholder="Buscar..."
                    name="Nombre"
                    size="small"
                    variant="outlined"
                    // value={query?.Nombre}
                    className={classes.textFieldCustom}
                    // onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} className={classes.gridMargin}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Typography className={classes.textFieldLetter}>
                      Obra
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontSize: 12,
                        fontWeight: 300,
                        lineHeight: 1.5,
                        textAlign: "left",
                        color: "#aeaeae",
                        marginLeft: 5,
                      }}
                    >
                      (Opcional)
                    </Typography>
                  </Grid>

                  <FormControl className={classes.formControl}>
                    <Select
                      // name="gradoEstudios"
                      value={questionType}
                      onChange={(e) => setQuestionType(e.target.value)}
                      inputProps={{ "aria-label": "Without label" }}
                      className={classes.select}
                    >
                      <MenuItem value="Cuadro de texto simple">
                        &ensp;Cuadro de texto simple
                      </MenuItem>
                      <MenuItem value="Opción multiple">
                        &ensp;Opción multiple
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} className={classes.gridMargin}>
                  <Typography className={classes.textFieldLetter}>
                    Dirección
                  </Typography>
                  <TextField
                    // placeholder="Buscar..."
                    name="Nombre"
                    size="small"
                    variant="outlined"
                    // value={query?.Nombre}
                    className={classes.textFieldCustom}
                    // onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-end"
            >
              <Button variant="contained" className={classes.button}>
                <Typography className={classes.typoButton}>Guardar</Typography>
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
