/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles((theme) => ({
  divMain: {
    // height: "24px",
    // minWidth: "200px",
    // maxWidth: "350px",
    // margin: "0px 5px 5px 0px",
    width: "90%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "16px",
    // backgroundColor: "blue",
    // backgroundColor: "#2d3034",
  },
  typo: {
    fontFamily: "Roboto",
    fontSize: 12,
    textAlign: "left",
    color: "#fff",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    maxWidth: "100%",
  },
}));

export default function IncidentComponent(props) {
  const classes = useStyles();

  return (
    <div className={classes.divMain}>
      <Typography className={classes.typo}>{props.incident}</Typography>
      {/* <FontAwesomeIcon
        icon={faTimesCircle}
        style={{
          width: "12px",
          height: "12px",
          marginLeft: "5px",
          color: "#272727",
          borderRadius: "50%",
          backgroundColor: "#fff",
        }}
      /> */}
    </div>
  );
}
