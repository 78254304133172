/*eslint-disable*/
import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import svgTrabajador from "../../../../images/usuario_trabajador.svg";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    width: "100%",
    // backgroundColor: "red",
  },
  divBackIcon: {
    width: "50px",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    backgroundColor: "#3a3d43",
  },
  // gridMargin: {
  //   [theme.breakpoints.up("md")]: {
  //     paddingLeft: 15,
  //   },
  // },
}));

export default function AssignSiteComponent(props) {
  const classes = useStyles();

  return (
    <div className={classes.divMain}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        style={{ marginBottom: 15 }}
      >
        <Grid item xs={3} sm={3} md={2} lg={2} xl={2}>
          <div className={classes.divBackIcon}>
            <FontAwesomeIcon
              icon={faBuilding}
              style={{
                width: "20px",
                height: "20px",
                color: "#d233ff",
              }}
            />
          </div>
        </Grid>

        <Grid
          item
          xs={9}
          sm={9}
          md={10}
          lg={10}
          xl={10}
          container
          direction="column"
          // className={classes.gridMargin}
        >
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 18,
              textAlign: "left",
              color: "#fff",
            }}
          >
            {props.site}
          </Typography>
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 14,
              textAlign: "left",
              color: "#b9b9b9",
            }}
          >
            {props.address}
          </Typography>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <img
              src={svgTrabajador}
              style={{
                width: "12px",
                height: "12px",
                marginRight: 5,
              }}
              alt="techton logo"
            />
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 14,
                lineHeight: 1.36,
                textAlign: "left",
                color: "#fff",
              }}
            >
              {props.employees == 1
                ? props.employees + " Trabajador"
                : props.employees + " Trabajadores"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
