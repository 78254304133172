/*eslint-disable*/
import { types } from "../types/types";

const initialState = {
  obras: [],
  selectedObras: null,
  selectedContratistasId: null,
};

export const obrasReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.obraLoad:
      return {
        ...state,
        ...action.payload,
      };

    case types.obraSelected:
      return {
        ...state,
        selectedObras: action.payload,
      };

    case types.obrasSelectedContratista:
      return {
        ...state,
        selectedContratistasId: action.payload,
      };

    case types.obrasUpdate:
      return {
        ...state,
        obras: state.obras.map((e) =>
          e._id === action.payload._id ? action.payload : e
        ),
      };

    case types.reset:
      return initialState;

    default:
      return state;
  }
};
