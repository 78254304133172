/*eslint-disable*/
import React, { useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import BackGroundLogin from "../../common_components/side_login";
import ForgotPassword from "./components/ForgotPassword";
import RecoveryCode from "./components/RecoveryCode";
import NewPassword from "./components/NewPassword";
import PasswordChanged from "./components/PasswordChanged";

const useStyles = makeStyles((theme) => ({
  divMain: {
    // display: "flex",
    minHeight: "100vh",
    justifyContent: "center",
    backgroundColor: theme.palette.primary.light,
    // [theme.breakpoints.down("md")]: {
    //   display: "flex",
    // },
    // [theme.breakpoints.up("md")]: {
    //   height: "100vh",
    // },
  },
  divPaper: {
    width: "800px",
    height: "auto",
    maxHeight: "746px",
    boxShadow: "0 8px 20px 0 rgba(0, 0, 0, 0.16)",
    borderRadius: "8px",
    backgroundColor: theme.palette.neutral.primary,
    marginBottom: "5%",
  },
}));

export default function PasswordResetScreen(props) {
  const classes = useStyles();
  const [step, setStep] = useState(1);
  const [passwordData, setPasswordData] = useState({
    email: "",
    code: "",
    token: "",
    password: "",
    confirmPassword: "",
  });

  const renderScreen = () => {
    if (step == 1)
      return (
        <ForgotPassword
          setStep={setStep}
          passwordData={passwordData}
          setPasswordData={setPasswordData}
        />
      );
    if (step == 2)
      return (
        <RecoveryCode
          setStep={setStep}
          passwordData={passwordData}
          setPasswordData={setPasswordData}
        />
      );
    if (step == 3)
      return (
        <NewPassword
          setStep={setStep}
          passwordData={passwordData}
          setPasswordData={setPasswordData}
        />
      );
    if (step == 4) return <PasswordChanged />;
  };

  return (
    <Grid container className={classes.divMain}>
      <CssBaseline />
      <Grid item xs={12}>
        <BackGroundLogin />
      </Grid>
      <Grid item xs={12} sm={10} md={8} lg={6} className={classes.divPaper}>
        {renderScreen()}
      </Grid>
    </Grid>
  );
}
