/*eslint-disable*/
import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
// import ShowMoreButton from "../../components/ShowMoreButton";
import {ValidatePermissions} from "../../../../utils/permissions";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
  },
  gridBehavior: {
    padding: 26,
    height: "100%",
    borderRadius: 16,
    backgroundColor: "#2d3034",
  },
}));

export default function HoursWorked(props) {
  const classes = useStyles();

  return (
    <Grid
      container
      item
      xs={12}
      direction="row-reverse"
      justifyContent="center"
      className={classes.gridBehavior}
    >
      <Grid container item lg={4} xs={12} justifyContent="flex-end">
        {ValidatePermissions("editar") && (
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 18,
              fontWeight: 500,
              color: "#d233ff",
              cursor: "pointer",
            }}
          >
            Ver reporte
          </Typography>
        )}
      </Grid>

      <Grid container item xs={8} direction="column">
        <Typography
          style={{
            fontFamily: "Roboto",
            fontSize: 22,
            fontWeight: 500,
            color: "#fff",
          }}
        >
          Horas trabajadas
        </Typography>

        <Typography
          style={{
            fontFamily: "Roboto",
            fontSize: 18,
            color: "#c1c1c1",
          }}
        >
          Esta semana Nov 29 - Dic 5
        </Typography>
      </Grid>

      <Grid container direction="row" style={{ marginTop: 25 }}>
        <Grid item xs={12} lg={3}>
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 18,
              color: "#fff",
              fontWeight: 500,
            }}
          >
            Total de horas
          </Typography>
        </Grid>

        <Typography
          style={{
            fontFamily: "Roboto",
            fontSize: 18,
            color: "#fff",
            fontWeight: 500,
          }}
        >
          48
        </Typography>
        <Typography
          style={{
            fontFamily: "Roboto",
            fontSize: 18,
            color: "#c1c1c1",
            marginLeft: 10,
          }}
        >
          horas
        </Typography>
      </Grid>

      <Grid container direction="row" style={{ marginTop: 10 }}>
        <Grid item xs={12} lg={3}>
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 18,
              color: "#fff",
              fontWeight: 500,
            }}
          >
            Horas directas
          </Typography>
        </Grid>

        <Typography
          style={{
            fontFamily: "Roboto",
            fontSize: 18,
            color: "#fff",
            fontWeight: 500,
          }}
        >
          48
        </Typography>
        <Typography
          style={{
            fontFamily: "Roboto",
            fontSize: 18,
            color: "#c1c1c1",
            marginLeft: 10,
          }}
        >
          horas
        </Typography>
      </Grid>

      <Grid container direction="row" style={{ marginTop: 10 }}>
        <Grid item xs={12} lg={3}>
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 18,
              color: "#fff",
              fontWeight: 500,
            }}
          >
            Horas indirectas
          </Typography>
        </Grid>

        <Typography
          style={{
            fontFamily: "Roboto",
            fontSize: 18,
            color: "#fff",
            fontWeight: 500,
          }}
        >
          48
        </Typography>
        <Typography
          style={{
            fontFamily: "Roboto",
            fontSize: 18,
            color: "#c1c1c1",
            marginLeft: 10,
          }}
        >
          horas
        </Typography>
      </Grid>
    </Grid>
  );
}
