/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import svgTrabajador from "../../../images/usuario_trabajador.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMedkit, faImage } from "@fortawesome/free-solid-svg-icons";
import WorkIcon from "@material-ui/icons/Work";
import { ValidatePermissions } from "../../../utils/permissions";

const useStyles = makeStyles((theme) => ({
  // divPhoto: {
  //   display: "flex",
  //   justifyContent: "center",
  //   alignItems: "center",
  //   width: "120px",
  //   height: "96px",
  //   borderRadius: "8px",
  //   backgroundColor: "#828282",
  // },
  // photo: {
  //   width: "110px",
  //   height: "100px",
  //   objectFit: "contain",
  //   borderRadius: "16px",
  //   objectFit: "contain",
  // },
  gridPaper: {
    height: "71px",
    borderRadius: "16px",
    backgroundColor: "#2d3034",
    padding: theme.spacing(2, 3, 2, 3),
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  },
  gridMargin: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
  gridMargin2: {
    paddingLeft: 10,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 20,
    },
  },
  icon: {
    color: "white",
  },
}));

export default function CardEnterpriseSubcontractors(props) {
  const classes = useStyles();
  const history = useHistory();

  const handleEdit = () => {
    history.push(`/dashboard/contratistas`);
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      className={classes.gridPaper}
    >
      <Grid
        item
        xs={1}
        // sm={4}
        // md={4}
        // lg={4}
        // xl={4}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.gridMargin}
        // style={{
        //   backgroundColor: "red",
        // }}
      >
        <WorkIcon className={classes.icon} />
      </Grid>

      <Grid
        item
        xs={9}
        // sm={8}
        // md={8}
        // lg={8}
        // xl={8}
        container
        direction="row"
        justifyContent="flex-start"
        className={classes.gridMargin2}
        // style={{
        //   backgroundColor: "blue",
        // }}
      >
        <Typography
          style={{
            fontFamily: "Roboto",
            fontSize: 24,
            lineHeight: 1.33,
            color: "#fff",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {props.subcontractors + " subcontratistas"}
        </Typography>
      </Grid>

      {ValidatePermissions("editar") ? (
        <Grid
          item
          xs={2}
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-start"
          style={{
            height: "40px",
          }}
        >
          <Typography
            onClick={handleEdit}
            style={{
              fontFamily: "Roboto",
              fontSize: 18,
              fontWeight: 500,
              color: "#d233ff",
              cursor: "pointer",
            }}
          >
            Ver
          </Typography>
        </Grid>
      ) : (
        <div></div>
      )}
    </Grid>
  );
}
