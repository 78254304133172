/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Grid, Typography } from "@material-ui/core";
import CardEmployee from "./CardEmployee";
import photox from "../../../images/profile@2x.png";

const useStyles = makeStyles((theme) => ({
  card: {
    width: 332,
    height: 349,
    padding: theme.spacing(2),
    borderRadius: 16,
    backgroundColor: "#2d3034",
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.33,
    textAlign: "left",
    color: "#fff",
    // display: "-webkit-box",
    // WebkitBoxOrient: "vertical",
    // WebkitLineClamp: 2,
    // textOverflow: "ellipsis",
    // overflow: "hidden",
    // maxWidth: "80%",
  },
  typoWatch: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 1.33,
    textAlign: "left",
    color: "#d233ff",
    cursor: "pointer",
  },
  divBackIcon: {
    width: 32,
    height: 32,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
    backgroundImage: "linear-gradient(91deg, #d233ff 1%, #ac0bce 99%)",
  },
  typoWorkersNumber: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.31,
    textAlign: "left",
    color: "#fff",
    marginRight: 5,
  },
  typoWorkersLabel: {
    fontFamily: "Roboto",
    fontSize: 14,
    lineHeight: 1.36,
    textAlign: "left",
    color: "#acacac",
  },
}));

export default function MediumCard(props) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{
          marginBottom: 5,
        }}
      >
        <Typography className={classes.title}>{props.title}</Typography>
        <Typography className={classes.typoWatch}>Ver</Typography>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-end"
        style={{
          marginBottom: 20,
        }}
      >
        <Typography className={classes.typoWorkersNumber}>
          {props.number}
        </Typography>
        <Typography className={classes.typoWorkersLabel}>
          {props.active}
        </Typography>
      </Grid>

      <Grid container direction="column">
        <CardEmployee
          urlPhoto={photox}
          name={"Javier Medina Cazares"}
          enterprise={"Grupo Absa"}
        />

        <CardEmployee
          urlPhoto={photox}
          name={"Javier Medina Cazares"}
          enterprise={"Grupo Absa"}
        />

        <CardEmployee
          urlPhoto={photox}
          name={"Javier Medina Cazares"}
          enterprise={"Grupo Absa"}
        />

        <CardEmployee
          urlPhoto={photox}
          name={"Javier Medina Cazares"}
          enterprise={"Grupo Absa"}
        />
      </Grid>
    </Card>
  );
}
