import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  logo: {
    width: 530,
    height: 130,
    marginBottom: 25,
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 40,
    fontWeight: 600,
    lineHeight: 1.33,
    color: theme.palette.neutral.primary,
  },
  subtitle: {
    fontFamily: "Roboto",
    fontSize: 20,
    fontWeight: 600,
    lineHeight: 1.35,
    color: theme.palette.secondary.dark,
  },
  submit: {
    color: "#FFFFFF",
    backgroundColor: "#50B9FF",
    height: "50px",
    fontSize: 14,
    fontWeight: "bolder",
    marginTop: "20px",
    margin: theme.spacing(3, 0, 3),
    "&:hover": {
      backgroundColor: "#50B9FF",
      color: "#FFF",
    },
  },
  textFieldCustom: {
    "& label.Mui-focused": {
      color: "#6C6C6C",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "yellow",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#E6E6E6",
      },
      "&:hover fieldset": {
        borderColor: "#6C6C6C",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#50B9FF",
      },
    },
    borderRadius: 8,
    backgroundColor: "#ffffff",
    boxShadow: "0 0 16px 0 rgba(6, 5, 5, 0.22)",
    marginTop: 4,
  },
  textFieldLabel: {
    fontFamily: "Roboto",
    fontSize: 20,
    lineHeight: 1.35,
    color: theme.palette.neutral.primary,
  },
  typoLink: {
    fontFamily: "Roboto",
    fontSize: 18,
    textAlign: "center",
    textDecoration: "none",
    color: theme.palette.neutral.primary,
    cursor: "pointer",
    marginTop: 5,
  },
  typoLabel: {
    width: "50%",
    fontFamily: "Roboto",
    fontSize: 18,
    textAlign: "center",
    textDecoration: "none",
    color: theme.palette.secondary.dark,
  },
  typoLink2: {
    marginTop: 5,
    textTransform: "none",
  },
  typoButtonRegister: {
    fontFamily: "Roboto",
    fontSize: 24,
    fontWeight: 300,
    textAlign: "center",
    color: theme.palette.neutral.primary,
  },
  divStyle: {
    width: "25%",
    height: 1,
    // border: "solid 1px #707070",
    backgroundColor: theme.palette.secondary.dark,
  },
  button: {
    height: 56,
    borderRadius: 8,
    backgroundColor: theme.palette.primary.main,
    textTransform: "none",
    marginTop: "10%",
  },
  typoButton: {
    fontFamily: "Roboto",
    fontSize: 20,
    fontWeight: 300,
    lineHeight: 1.35,
    color: theme.palette.neutral.primary,
  },
}));

export default useStyles;
