/*eslint-disable*/
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import DataGrid from "../../../../common_components/Filters/DataGrid";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .header": {
      color: "white",
      fontFamily: "Roboto",
      fontSize: "18px",
      fontWeight: 500,
      display: "flex",
    },
  },
  photo: {
    width: "40px",
    height: "40px",
    borderRadius: "8px",
    margin: "auto",
    aspectRatio: 1,
  },
}));

export default function DataGridEntryPass(props) {
  const classes = useStyles();

  const columns = [
    {
      field: "OrdenCompra",
      headerName: "#Orden de compra",
      headerAlign: "center",
      flex: 1,
      sortable: false,
      headerClassName: "header",
    },
    {
      field: "Proveedor",
      headerName: "Proveedor",
      headerAlign: "center",
      flex: 1,
      sortable: false,
      headerClassName: "header",
    },
    {
      field: "Almacenista",
      headerName: "Almacenista",
      headerAlign: "center",
      flex: 1,
      sortable: false,
      headerClassName: "header",
    },
    {
      field: "Entregado",
      headerName: "Entregado por",
      headerAlign: "center",
      flex: 1,
      sortable: false,
      headerClassName: "header",
    },
    {
      field: "Articulos",
      headerName: "Número de artículos",
      headerAlign: "center",
      flex: 1,
      sortable: false,
      headerClassName: "header",
    },
    // {
    //   field: "Estatus",
    //   headerName: "Estatus",
    //   headerAlign: "center",
    //   flex: 0.8,
    //   sortable: false,
    //   headerClassName: "header",

    //   renderCell: (params) => {
    //     return (
    //       <div style={{ margin: "auto" }}>
    //         {params.row.Status == "Activo" ? (
    //           <Grid container direction="row">
    //             <FontAwesomeIcon
    //               icon={faCheckCircle}
    //               style={{
    //                 width: "18px",
    //                 height: "18px",
    //                 color: "#ac0bce",
    //                 marginTop: 2,
    //                 // marginRight: 8,
    //               }}
    //             />
    //             <Typography
    //               style={{
    //                 fontFamily: "Roboto",
    //                 fontSize: 16,
    //                 color: "#fff",
    //                 marginLeft: 6,
    //               }}
    //             >
    //               Activo
    //             </Typography>
    //           </Grid>
    //         ) : params.row.Status == "Desactivado" ? (
    //           <Grid container direction="row">
    //             <FontAwesomeIcon
    //               icon={faTimesCircle}
    //               style={{
    //                 width: "18px",
    //                 height: "18px",
    //                 color: "#eb1d1d",
    //                 marginTop: 2,
    //                 // marginRight: 8,
    //               }}
    //             />
    //             <Typography
    //               style={{
    //                 fontFamily: "Roboto",
    //                 fontSize: 16,
    //                 color: "#fff",
    //                 marginLeft: 6,
    //               }}
    //             >
    //               Desactivado
    //             </Typography>
    //           </Grid>
    //         ) : params.row.Status == "Invitación enviada" ? (
    //           <Grid container direction="column">
    //             <Typography
    //               style={{
    //                 fontFamily: "Roboto",
    //                 fontSize: 16,
    //                 color: "#fff",
    //               }}
    //             >
    //               Invitación enviada
    //             </Typography>
    //             <div
    //               onClick={() => {
    //                 props.setComplete(true);
    //                 props.setOpendialog(true);
    //               }}
    //               style={{ cursor: "pointer" }}
    //             >
    //               <Typography
    //                 style={{
    //                   fontFamily: "Roboto",
    //                   fontSize: 12,
    //                   color: "#d233ff",
    //                   marginLeft: 6,
    //                 }}
    //               >
    //                 Volver a enviar invitación
    //               </Typography>
    //             </div>
    //           </Grid>
    //         ) : (
    //           <div></div>
    //         )}
    //       </div>
    //     );
    //   },
    // },
    {
      field: "Fecha",
      headerName: "Fecha",
      headerAlign: "center",
      flex: 0.8,
      sortable: false,
      headerClassName: "header",
    },
  ];

  return (
    <DataGrid
      rows={props.rows}
      columns={columns}
      loading={props.loading}
      rowCount={props.rowCount}
      rowsPerPageOptions={props.rowsPerPageOptions}
      page={props.page}
      pageSize={props.pageSize}
      onPageChange={props.onPageChange}
      onPageSizeChange={props.onPageSizeChange}
      style={props.style}
      onSelectionModelChange={props.onSelectionModelChange}
      selectionModel={props.selectionModel}
    />
  );
}
