/*eslint-disable*/
import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
  useTheme,
  TextField,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import * as Yup from "yup";
// import DialogPayment from "./DialogPayment";
// import DialogClient from "./DialogClient";
// import DialogRegisterClient from "./DialogRegisterClient";
// import DialogSearchClient from "./DialogSearchClient";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "80%",
    height: "auto",
    padding: theme.spacing(3),
    border: "solid 1px #707070",
    borderRadius: 16,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: 10,
    },
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.3,
    textAlign: "left",
    color: "#fff",
  },
  subtitle: {
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: 1.33,
    textAlign: "left",
    color: "#acacac",
    marginTop: 5,
  },
  typoFolio: {
    fontFamily: "Roboto",
    fontSize: 18,
    fontWeight: 500,
    lineHeight: 1.33,
    textAlign: "left",
    color: "#fff",
  },
  typoUserData: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.31,
    textAlign: "left",
    color: "#fff",
  },
  typoLabel: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 500,
    color: "#fff",
  },
  textFieldLabel: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.31,
    textAlign: "left",
    color: "#fff",
    marginTop: 30,
  },
  buttonOption: {
    width: "100%",
    height: 48,
    borderRadius: 8,
    backgroundColor: "#2d3034",
    textTransform: "none",
  },
  textFieldCustom: {
    width: "100%",
    borderRadius: 8,
    backgroundColor: "#2d3034",
  },
  button: {
    width: "100%",
    height: 48,
    borderRadius: 8,
    backgroundColor: "#ac0bce",
    textTransform: "none",
    marginTop: 40,
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  },
  typoButton: {
    fontFamily: "Roboto",
    fontSize: 18,
    lineHeight: 1.33,
    textAlign: "left",
    color: "#fff",
  },
}));

export default function DetailsCard(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { purchaseOrder } = props;
  const { user } = useSelector((state) => state.user);
  //   const [openDialogPay, setOpenDialogPay] = useState(false);
  //   const [openDialogClient, setOpenDialogClient] = useState(false);
  //   const [openDialogRegister, setOpenDialogRegister] = useState(false);
  //   const [openDialogSearch, setOpenDialogSearch] = useState(false);

  function stringAvatar(names) {
    let initials = "";
    names.split(" ").map((name) => {
      initials = initials + name[0];
    });

    if (initials.length > 2) {
      initials = initials.slice(0, 2);
    }

    return initials;
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      className={classes.card}
    >
      <Typography className={classes.title}>
        Detalles orden de compra
      </Typography>

      {purchaseOrder.id == "" ? (
        <Typography className={classes.subtitle}>
          Completa los campos para generar la orden de compra
        </Typography>
      ) : (
        <Grid container direction="column">
          <Grid container direction="row">
            <Typography className={classes.typoFolio}>Folio</Typography>
            <a
              style={{
                color: "#d233ff",
              }}
            >
              #
            </a>
            <Typography
              className={classes.typoFolio}
              style={{ marginBottom: 10 }}
            >
              {purchaseOrder.folio}
            </Typography>
          </Grid>

          <Typography className={classes.typoLabel}>Generada por</Typography>

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            style={{
              marginBottom: 10,
            }}
          >
            <div
              style={{
                width: "24px",
                height: "24px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",
                backgroundColor: "#d233ff",
                marginRight: 10,
              }}
            >
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 12,
                  fontWeight: 500,
                  color: "#fff",
                }}
              >
                {stringAvatar(user ? user.Name : "")}
              </Typography>
            </div>

            <Typography className={classes.typoUserData}>
              Javier Medina Cazares
            </Typography>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography
              className={classes.typoLabel}
              style={{ marginRight: 5 }}
            >
              Fecha de creación
            </Typography>

            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 16,
                fontWeight: 500,
                color: "#aeaeae",
              }}
            >
              24 de Septiembre 12:23
            </Typography>
          </Grid>
        </Grid>
      )}

      <Typography className={classes.textFieldLabel}>Proveedor</Typography>
      <Button
        variant="contained"
        onClick={() => props.setOpenDialogSupplier((prev) => !prev)}
        className={classes.buttonOption}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          {/* <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 16,
              textAlign: "center",
              color: "#919191",
            }}
          >
            Seleccionar cliente
          </Typography> */}
          <FontAwesomeIcon
            icon={faAngleRight}
            style={{
              width: "15px",
              height: "25px",
              color: "#a4a4a4",
            }}
          />
        </Grid>
      </Button>

      <Typography className={classes.textFieldLabel}>
        Almacen a llegar
      </Typography>
      <Button
        variant="contained"
        // onClick={() => setOpenDialogPay(!openDialogPay)}
        className={classes.buttonOption}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          {/* <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 16,
              textAlign: "center",
              color: "#919191",
            }}
          >
            Seleccionar método de pago
          </Typography> */}
          <FontAwesomeIcon
            icon={faAngleRight}
            style={{
              width: "15px",
              height: "25px",
              color: "#a4a4a4",
            }}
          />
        </Grid>
      </Button>

      <Typography className={classes.textFieldLabel}>Día de entrega</Typography>
      <Button
        variant="contained"
        // onClick={() => setOpenDialogPay(!openDialogPay)}
        className={classes.buttonOption}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          {/* <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 16,
              textAlign: "center",
              color: "#919191",
            }}
          >
            Cotizar envio
          </Typography> */}
          <FontAwesomeIcon
            icon={faAngleRight}
            style={{
              width: "15px",
              height: "25px",
              color: "#a4a4a4",
            }}
          />
        </Grid>
      </Button>

      <Typography className={classes.textFieldLabel}>Notas</Typography>
      <TextField
        id="notes"
        name="notes"
        size="small"
        multiline
        minRows="3"
        variant="outlined"
        className={classes.textFieldCustom}
        // value={formik.values.notes}
        // onChange={formik.handleChange}
        // onBlur={formik.handleBlur}
      />
      {/* {formik.touched.userName && formik.errors.userName && (
                <Typography
                  style={{
                    fontSize: 16,
                    textAlign: "left",
                    color: "#FF1717",
                    width: "65%",
                    marginTop: 4,
                  }}
                >
                  {formik.errors.userName}
                </Typography>
              )} */}

      <Grid container>
        <Button
          variant="contained"
          onClick={() => {}}
          className={classes.button}
        >
          <Typography className={classes.typoButton}>
            Confirmar cotización
          </Typography>
        </Button>
      </Grid>

      {/* <DialogPayment
        openDialogPay={openDialogPay}
        setOpenDialogPay={setOpenDialogPay}
      />

      <DialogClient
        openDialogClient={openDialogClient}
        setOpenDialogClient={setOpenDialogClient}
        openDialogRegister={openDialogRegister}
        setOpenDialogRegister={setOpenDialogRegister}
        openDialogSearch={openDialogSearch}
        setOpenDialogSearch={setOpenDialogSearch}
      />

      <DialogRegisterClient
        openDialogRegister={openDialogRegister}
        setOpenDialogRegister={setOpenDialogRegister}
      />

      <DialogSearchClient
        openDialogSearch={openDialogSearch}
        setOpenDialogSearch={setOpenDialogSearch}
      /> */}
    </Grid>
  );
}
