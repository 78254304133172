/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import CardStockistEntry from "./CardStockistEntry";
import CardProduct from "../../ExitPass/components/cardProduct";
import axiosInstance from "../../../../utils/axiosInstance";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flex: 1,
    // backgroundColor: "#171a1e",
  },
  gridSigns: {
    height: "100%",
    [theme.breakpoints.down("md")]: {
      marginTop: 10,
      paddingLeft: 0,
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 40,
    },
  },
  gridSigns2: {
    height: "100%",
    [theme.breakpoints.down("lg")]: {
      marginTop: 10,
    },
  },
  divBack: {
    cursor: "pointer",
    marginBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
  back: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 500,
    textAlign: "left",
    color: "#ffffff",
  },
  photo: {
    width: "120px",
    height: "120px",
    aspectRatio: 1,
    objectFit: "cover",
    borderRadius: 12,
    marginTop: "32px",
  },
  divRectangle: {
    width: "370px",
    height: "151px",
    border: "solid 2px #707070",
    borderStyle: "dashed",
  },
  divLine: {
    height: "145px",
    border: "solid 1px #707070",
    borderStyle: "dashed",
    borderRightWidth: "2px",
  },
  photoFirm: {
    width: "134px",
    height: "88px",
    aspectRatio: 1,
    objectFit: "cover",
    borderRadius: 2,
    marginTop: "20px",
  },
  photoSupplier: {
    width: "80px",
    height: "80px",
    aspectRatio: 1,
    objectFit: "cover",
    borderRadius: 12,
    marginTop: "20px",
  },
}));
const tam = ["", "", "", "", "", "", "", "", "", "", "", ""];

export default function EntryPassSpecificScreen(props) {
  const classes = useStyles();
  const history = useHistory();

  const [dataCards, setDataCards] = useState({
    ordenCompra: "",
    provedor: "",
    articulos: "",
    almacenista: "",
    entregadoX: "",
    estatus: "",
    caducidad: "",
    fecha: "",
  });
  const url =
    "https://ca-times.brightspotcdn.com/dims4/default/deca070/2147483647/strip/true/crop/1135x744+0+0/resize/1135x744!/quality/90/?url=https%3A%2F%2Fcalifornia-times-brightspot.s3.amazonaws.com%2F90%2F82%2F4ad71dc542d2a9120bbefbcadf70%2Fquiere-la-roca-pelea-938977.JPG";
  const urlFirm =
    "https://www.consumer.es/app/uploads/fly-images/110784/img_firma-3-1200x550-cc.jpg";

  // useEffect(() => {
  //   console.log(window.location.href.split("/").pop());
  //   const id = window.location.href.split("/").pop();
  //   axiosInstance()
  //     .get(`/api/v1/rules/pase-salida/${id}`)
  //     .then((response) => {
  //       let result = response.data.data[0];
  //       // console.log(result);
  //       setDataCards({
  //         ...dataCards,
  //         fecha: "22 de enero",
  //         ordenCompra: result.folioPaseSalida,
  //         provedor: "",
  //         articulos: result.articulos.length,
  //         almacenista: "",
  //         entregadoX: "",
  //         estatus: result.status,
  //         caducidad: result.caducidad,
  //       });
  //     })
  //     .catch((e) => {
  //       console.log("error: ", e);
  //     });
  // }, []);

  return (
    <div className={classes.divMain}>
      <Grid
        item
        xs={6}
        sm={4}
        md={2}
        lg={2}
        xl={2}
        container
        direction="row"
        alignItems="center"
        onClick={() => history.push(`/dashboard/almacen/pase-entrada`)}
        className={classes.divBack}
      >
        <Grid item>
          <FontAwesomeIcon
            icon={faArrowAltCircleLeft}
            style={{
              width: "20px",
              height: "20px",
              marginRight: "10px",
              color: "#d6d6d6",
            }}
          />
        </Grid>
        <Grid item>
          <Typography className={classes.back}>Regresar</Typography>
        </Grid>
      </Grid>

      <Grid container direction="column">
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
            <CardStockistEntry
              date={"12 de diciembre"}
              hour={"17:20"}
              folio={"#" + "123123"}
              subcontratistas={"Plomeria Westeros"}
              solicitadoX={"Daniel"}
              articulos={2}
              entregadoA={"Esteban"}
              estatus={"Recolectado"}
              // evidencia={url}
              numeroEvidencia={"6"}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={7}
            lg={4}
            xl={3}
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            className={classes.gridSigns}
          >
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: "18px",
                fontWeight: 500,
                lineHeight: 1.33,
                textAlign: "left",
                color: "#fff",
              }}
            >
              Firma de proveedor
            </Typography>
            <Grid container direction="row" className={classes.divRectangle}>
              <Grid
                item
                xs={6}
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                style={{
                  width: "175px",
                  height: "141px",
                  /* backgroundColor: "#FF8000", */
                }}
              >
                <img src={urlFirm} className={classes.photoFirm} alt="photo" />
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "12px",
                    fontWeight: 500,
                    lineHeight: 1.33,
                    textAlign: "center",
                    color: "#fff",
                    marginTop: "6px",
                  }}
                >
                  Firma de proveedor
                </Typography>
              </Grid>
              <Grid className={classes.divLine} />
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                style={{
                  width: "175px",
                  height: "141px",
                  /* backgroundColor: "#FF8000", */
                }}
              >
                <img src={url} className={classes.photoSupplier} alt="photo" />
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "12px",
                    fontWeight: 500,
                    lineHeight: 1.33,
                    textAlign: "center",
                    color: "#fff",
                  }}
                >
                  Foto de proveedor (opcional)
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={4}
            xl={3}
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            className={classes.gridSigns2}
            // style={{
            //   backgroundColor: "red",
            // }}
          >
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: "18px",
                fontWeight: 500,
                lineHeight: 1.33,
                color: "#fff",
              }}
            >
              Firma del almacenista
            </Typography>
            <Grid container direction="row" className={classes.divRectangle}>
              <Grid
                item
                xs={6}
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                style={{
                  width: "175px",
                  height: "141px",
                }}
              >
                <img src={urlFirm} className={classes.photoFirm} alt="photo" />
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "12px",
                    fontWeight: 500,
                    lineHeight: 1.33,
                    textAlign: "center",
                    color: "#fff",
                    marginTop: "6px",
                  }}
                >
                  Firma de almacenista
                </Typography>
              </Grid>
              <Grid className={classes.divLine} />
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                style={{
                  width: "175px",
                  height: "141px",
                }}
              >
                <img src={url} className={classes.photoSupplier} alt="photo" />
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "12px",
                    fontWeight: 500,
                    lineHeight: 1.33,
                    textAlign: "center",
                    color: "#fff",
                  }}
                >
                  Foto de almacenista
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid style={{ marginTop: 20, marginBottom: 10 }}>
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: "20px",
              fontWeight: 500,
              lineHeight: 1.3,
              textAlign: "left",
              color: "#fff",
            }}
          >
            Articulos
          </Typography>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid
            item
            xs={12}
            spacing={3}
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            {tam.map((it, index) => {
              return (
                <Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={3}>
                  <CardProduct
                    articulo={"Chaleco de Seguridad"}
                    talla={"M"}
                    color={"Verde"}
                    elementoPEP={"MI02939201920"}
                    cantidad={12}
                  />
                </Grid>
              );
            })}
          </Grid>

          {/* <Grid
            item
            xs={12} //pantalla chica +110%
            sm={12}
            md={12} //110%
            lg={2}
            xl={2} //pantalla muy grande -110%
            container
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            style={{
              width: "20%",
              alignItems: "left",
            }}
          >
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: "20px",
                fontWeight: 500,
                lineHeight: 1.3,
                textAlign: "left",
                color: "#fff",
              }}
            >
              Evidencia fotográfica
            </Typography>
            <img src={url} className={classes.photo} alt="photo" />
            <img src={url} className={classes.photo} alt="photo" />
            <img src={url} className={classes.photo} alt="photo" />
            <img src={url} className={classes.photo} alt="photo" />
          </Grid> */}
        </Grid>
      </Grid>
    </div>
  );
}
