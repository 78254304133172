/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button } from "@material-ui/core";
import Typo from "../../../common_components/typo";

const useStyles = makeStyles((theme) => ({
  divMain: {
    width: "95%",
    height: "100px",
    padding: theme.spacing(1),
    marginTop: 10,
    cursor: "pointer",
    borderRadius: 12,
    backgroundColor: "#2d3034",
    "&:hover": {
      background: "#3B3D40",
      boxShadow: "0 0px 16px 0 rgba(90, 90, 90, 0.2)",
    },
  },
  photo: {
    width: "56px",
    height: "56px",
    aspectRatio: 1,
    objectFit: "cover",
    borderRadius: 8,
  },
  gridMargin: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
}));

function stringAvatar(names) {
  let initials = "";
  names.split(" ").map((name) => {
    initials = initials + name[0];
  });

  if (initials.length > 2) {
    initials = initials.slice(0, 2);
  }

  return initials;
}

export default function CardUser(props) {
  const classes = useStyles();

  const setPhotoProfile = (urlPhoto) => {
    props.setUserData({
      ...props.userData,
      foto: urlPhoto,
      fotoCache: urlPhoto,
    });
    props.setOpendialogSearch(!props.openDialogSearch);
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      className={classes.divMain}
      onClick={() => props.setOpenDialogSearchUser(!props.openDialogSearchUser)}
    >
      <Grid
        item
        xs={3}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        {props.urlPhoto != undefined ? (
          <img src={props.urlPhoto} className={classes.photo} alt="photo" />
        ) : (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{
              width: "48px",
              height: "48px",
              backgroundColor: "#d233ff",
              borderRadius: "50px",
            }}
          >
            <Typo
              fontSize={"24px"}
              fontWeight={500}
              color={"#fff"}
              lineHeight={1.33}
              textAlign={"center"}
              text={stringAvatar(props.name)}
            />
          </Grid>
        )}
      </Grid>
      <Grid item xs={9} container direction="column">
        <Typo fontSize={16} fontWeight={500} color={"#fff"} text={props.name} />

        <Typo fontSize={14} color={"#acacac"} text={props.email} />
      </Grid>
    </Grid>
  );
}
