/*eslint-disable*/
import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  button: {
    width: "200px",
    height: "44px",
    borderRadius: "8px",
    backgroundColor: "#8f8f8f",
    textTransform: "none",
  },
  typo: {
    fontFamily: "Roboto",
    fontSize: 18,
    color: "#fff",
  },
}));

export default function ShowMoreButton(props) {
  const classes = useStyles();

  return (
    <Button className={classes.button}>
      <Typography className={classes.typo}>Mostrar Más</Typography>
    </Button>
  );
}
