/*eslint-disable*/
import React, { useState } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import WelcomeScreen from "./components/WelcomeScreen";
import CreateCompany from "./components/CreateCompany";
import JoinCompany from "./components/JoinCompany";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Logo from "../../images/absa-industrial.png";

const drawerWidth = 248;
const useStyles = makeStyles((theme) => ({
  divMain: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.neutral.primary,
  },
  logo: {
    width: "12%",
    height: "2%",
    marginTop: "1%",
    marginLeft: "4%",
  },
  divPaper: {
    width: "100%",
    height: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "2%",
  },
  divPaperInside: {
    width: "945px",
    height: "auto",
    padding: "2% 70px 4% 70px",
    border: `solid 1px ${theme.palette.secondary.main}`,
    boxShadow: "0 0px 16px 0 rgba(90, 90, 90, 0.2)",
    borderRadius: "8px",
  },
  icon: {
    width: "27px",
    height: "27px",
    color: theme.palette.neutral.primary,
  },
  button: {
    height: "50px",
    marginTop: "1%",
    marginLeft: "2%",
    color: "#e8e8e8",
    textTransform: "none",
    backgroundColor: theme.palette.primary.main,
  },
  typoButton: {
    fontFamily: "Roboto",
    fontSize: 23,
    fontWeight: 500,
    color: theme.palette.neutral.primary,
  },
}));

export default function ChooseScreen(props) {
  const classes = useStyles();
  const history = useHistory();
  const [step, setStep] = useState(1);

  const renderScreen = () => {
    // console.log(step);
    if (step == 1) {
      return <WelcomeScreen step={step} setStep={setStep} />;
    }
    if (step == 2) {
      return <CreateCompany step={step} setStep={setStep} />;
    }
    if (step == 3) {
      return <JoinCompany step={step} setStep={setStep} />;
    }
  };

  return (
    <div className={classes.divMain}>
      <Grid
        container
        direction="row"
        justifycontent="flex-start"
        alignItems="center"
        style={{
          height: "15%",
          // backgroundColor: "red",
        }}
      >
        <Grid item xs={11}>
          <img src={Logo} className={classes.logo} alt="techton logo" />
        </Grid>
        <Grid item xs={1}>
          <Button
            variant="contained"
            size="large"
            disableElevation
            className={classes.button}
            startIcon={<ExitToAppIcon className={classes.icon} />}
            onClick={() => {
              localStorage.removeItem("JWT");
              history.push("/inicio_sesion");
            }}
          >
            <Typography className={classes.typoButton}>Salir</Typography>
          </Button>
        </Grid>
      </Grid>
      <div className={classes.divPaper}>
        <div className={classes.divPaperInside}>{renderScreen()}</div>
      </div>
    </div>
  );
}
