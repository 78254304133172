/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faArrowAltCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import { UploadDocuments } from "../../../actions/file";
import DialogLoadingUploadDocuments from "./DialogLoadingUploadDocuments";
import Typo from "../../../common_components/typo";
import axiosInstance from "../../../utils/axiosInstance";
import { ipServer } from "../../../config/vars";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: "bold",
    textAlign: "left",
    color: "#cdcece",
  },
  textFieldCustom: {
    width: "350px",
    color: "#676767",
    border: "solid 1px #c5c5c5",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
  },
  divImg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "356px",
    height: "290px",
    borderRadius: "8px",
    border: "solid 1px #707070",
    backgroundColor: "#212429",
    cursor: "pointer",
  },
  trackVertical: {
    height: "95%",
    right: 0,
    width: "10px !important",
  },
  thumbVertical: {
    backgroundColor: "#2d3034",
    height: "100%",
    right: 0,
    width: "8px !important",
    borderRadius: "8px",
  },
  photo: {
    width: "80px",
    height: "80px",
    borderRadius: "8px",
    aspectRatio: 1,
    // position: "absolute",
    objectFit: "cover",
  },
}));

export default function DialogSiblings(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { files } = useSelector((state) => state.file);
  const [disableButton, setDisableButton] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleOpenDialog = () => {
    props.setOpenDialogSibling(!props.openDialogSibling);
  };

  const saveEmployee = async () => {
    setDisableButton(true);
    props.setOpenDialogSibling(false);
    props.setDisableButton(true);
    props.setOpenBackdrop(true);
    var _obj = {};
    if (props.employeeData.idUser == "") {
      _obj = await registerEmployee();
      if (_obj.flag == false) return;
      await assignEmployeeSubcontractor(_obj.id);
      await assignEmployeeProyect(_obj.id);
    } else {
      if (props.subcontractorFlag) {
        if (!(await updateSubcontractor(props.employeeData.idUser))) {
          enqueueSnackbar("Error al actualizar subcontratista", {
            variant: "error",
          });
          return;
        }
      }
      if (props.siteFlag) {
        if (!(await updateProyect(props.employeeData.idUser))) {
          enqueueSnackbar("Error al actualizar obra", {
            variant: "error",
          });
          return;
        }
      }
      if (!(await updateEmployee(props.employeeData.idUser))) return;
      await assignEmployeeSubcontractor(props.employeeData.idUser);
      await assignEmployeeProyect(props.employeeData.idUser);
    }

    const documents = files.some(
      (file) => file.process != "db" && file.url === null
    );
    if (documents) {
      dispatch(
        UploadDocuments(
          props.employeeData.idUser != "" ? props.employeeData.idUser : _obj.id,
          files,
          "trabajadores",
          props.setChangesFile
        )
      );
    }

    props.setSubcontractorFlag(false);
    props.setSiteFlag(false);
    props.setChangesFlag(false);

    setTimeout(() => {
      setDisableButton(false);
      props.setDisableButton(false);
    }, 2000);

    return documents;
  };

  const registerEmployee = async () => {
    var _obj = {
      id: "",
      flag: false,
    };

    await axiosInstance()
      .get(`/api/v1/rules/confirm/${props.idConfirm}`)
      .then((response) => {
        // console.log(response.data);
        _obj.id = response.data.extra._id;
        _obj.flag = true;
        props.setEmployeeData({
          ...props.employeeData,
          idUser: response.data.extra._id,
          foto: response.data.extra.faceIndex.imageIndex,
          porcentajePerfil: response.data.extra.percentage.total,
        });
        props.setOpenBackdrop(false);
        enqueueSnackbar("Empleado registrado correctamente", {
          variant: "success",
        });
        console.log("Empleado registrado correctamente!");
      })
      .catch((e) => {
        _obj.flag = false;
        setDisableButton(false);
        props.setDisableButton(false);
        props.setOpenBackdrop(false);

        props.setErrorData({
          title: "Error al registrar empleado",
          description: "No se pudo registrar el trabajador en la base de datos",
        });
        props.setOpenDialogUnsavedEmployee(true);

        console.log(e.response);
      });

    return _obj;
  };

  const updateEmployee = async () => {
    return await axiosInstance()
      .get(`/api/v1/rules/confirm/${props.idConfirm}`)
      .then((response) => {
        // console.log(response.data);
        props.setEmployeeData({
          ...props.employeeData,
          idUser: response.data.extra._id,
          porcentajePerfil: response.data.extra.percentage,
        });
        props.setOpenBackdrop(false);
        enqueueSnackbar("Empleado actualizado correctamente", {
          variant: "success",
        });
        console.log("Empleado actualizado correctamente!");
        return true;
      })
      .catch((e) => {
        setDisableButton(false);
        props.setDisableButton(false);
        props.setOpenBackdrop(false);

        console.log(e.response);

        props.setErrorData({
          title: "Error al registrar empleado",
          description: "No se pudo registrar el trabajador en la base de datos",
        });
        props.setOpenDialogUnsavedEmployee(true);
        return false;
      });
  };

  const assignEmployeeSubcontractor = async (idUser) => {
    var trabajadores = [];
    var subcontratistas = [];

    trabajadores.push(idUser);
    subcontratistas.push(props.employeeData.subcontratistaId);

    return await axios
      .post(
        `${ipServer}/api/v1/rules/assing`,
        {
          trabajadores: trabajadores,
          subcontratistas: subcontratistas,
        },
        {
          headers: {
            Authorization: localStorage.getItem("JWT"),
          },
        }
      )
      .then((response) => {
        // console.log(response.data);
        // enqueueSnackbar("Usuario registrado correctamente", {
        //   variant: "success",
        // });
        console.log("Empleado asignado a un subcontratista");
        return true;
      })
      .catch((e) => {
        console.log(e);
        return false;
        // if (
        //   e.response.data.message ==
        //   "Could not upload the file. TypeError: Cannot read property '0' of undefined"
        // ) {
        //   console.log(e.response.data);
        //   enqueueSnackbar("Favor de seleccionar foto de perfil", {
        //     variant: "error",
        //   });
        // }
      });
  };

  const updateSubcontractor = async (idUser) => {
    var trabajadores = [];
    var subcontratistas = [];

    trabajadores.push(idUser);
    subcontratistas.push(props.employeeData.oldSubcontratistaId);

    return await axios
      .delete(`${ipServer}/api/v1/rules/assing`, {
        headers: {
          Authorization: localStorage.getItem("JWT"),
        },
        data: {
          trabajadores: trabajadores,
          subcontratistas: subcontratistas,
        },
      })
      .then((response) => {
        // console.log("delete assign", response.data);
        console.log("subcontratista desasignado correctamente");
        //validar respuesta
        return true;
      })
      .catch((e) => {
        console.log(e.response);
        return false;
      });
  };

  const assignEmployeeProyect = async (idUser) => {
    var trabajadores = [];
    var proyectos = [];

    trabajadores.push(idUser);
    proyectos.push(props.employeeData.proyectoObraId);

    return await axios
      .post(
        `${ipServer}/api/v1/rules/assing`,
        {
          trabajadores: trabajadores,
          proyectos: proyectos,
        },
        {
          headers: {
            Authorization: localStorage.getItem("JWT"),
          },
        }
      )
      .then((response) => {
        // console.log(response.data);
        // enqueueSnackbar("Usuario registrado correctamente", {
        //   variant: "success",
        // });
        console.log("Empleado asignado a un proyecto");
        return true;
      })
      .catch((e) => {
        console.log(e);
        return false;
        // if (
        //   e.response.data.message ==
        //   "Could not upload the file. TypeError: Cannot read property '0' of undefined"
        // ) {
        //   console.log(e.response.data);
        //   enqueueSnackbar("Favor de seleccionar foto de perfil", {
        //     variant: "error",
        //   });
        // }
      });
  };

  const updateProyect = async (idUser) => {
    var trabajadores = [];
    var proyectos = [];

    trabajadores.push(idUser);
    proyectos.push(props.employeeData.oldProyectoObraId);

    return await axios
      .delete(`${ipServer}/api/v1/rules/assing`, {
        headers: {
          Authorization: localStorage.getItem("JWT"),
        },
        data: {
          trabajadores: trabajadores,
          proyectos: proyectos,
        },
      })
      .then((response) => {
        // console.log("delete assign", response.data);
        console.log("obra desasignada correctamente");
        //validar respuesta
        return true;
      })
      .catch((e) => {
        console.log(e.response);
        return false;
      });
  };

  return (
    <Dialog
      open={props.openDialogSibling}
      onClose={handleOpenDialog}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        style: {
          width: "585px",
          height: "800px",
          paddingTop: 10,
          textColor: "#fsf",
          backgroundColor: "#171a1e",
          borderRadius: "8px",
        },
      }}
    >
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 18,
              textAlign: "center",
              color: "#fff",
              fontWeight: "bold",
            }}
          >
            Error en carga de foto
          </Typography>

          <Grid
            container
            justifyContent="flex-end"
            style={{ position: "absolute" }}
          >
            <Button onClick={handleOpenDialog}>
              <FontAwesomeIcon
                icon={faTimes}
                style={{
                  width: "20px",
                  height: "20px",
                  color: "#fff",
                }}
              />
            </Button>
          </Grid>
        </Grid>
        <div
          style={{
            width: "100%",
            height: 1,
            background: "#707070",
            marginTop: 8,
            padding: 0,
          }}
        ></div>
      </DialogTitle>

      <DialogContent>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{
            marginTop: "30px",
          }}
        >
          <Typo
            fontSize={18}
            color={"#fff"}
            textAlign={"center"}
            text={
              "Se ha detectado un empleado con una foto, similar a la que se desea guardar"
            }
          />

          <Typo
            fontSize={14}
            color={"#b4b4b4"}
            textAlign={"center"}
            text={
              "Este error puede suceder en caso de que las personas sean familiares o tengas rasgos parecidos"
            }
            marginTop={15}
          />

          <Grid
            container
            direction="row"
            alignItems="center"
            style={{
              marginTop: 50,
            }}
          >
            <Grid
              item
              xs={5}
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{
                  width: "144px",
                  height: "168px",
                  borderRadius: "12px",
                  backgroundColor: "#2d3034",
                }}
              >
                <img
                  src={props.employeeData ? props.employeeData.fotoCache : ""}
                  className={classes.photo}
                  alt="photo"
                />
                <Typo
                  fontSize={16}
                  fontWeight={500}
                  color={"#fff"}
                  textAlign={"center"}
                  text={props.employeeData ? props.employeeData.nombre : ""}
                  marginTop={10}
                />
              </Grid>
              <Typo
                fontSize={18}
                color={"#fff"}
                textAlign={"center"}
                text={"Empleado a subir"}
                //   marginTop={}
              />
            </Grid>

            <Grid
              item
              xs={2}
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <FontAwesomeIcon
                icon={faArrowAltCircleRight}
                style={{
                  width: "35px",
                  height: "35px",
                  color: "#f8f8f8",
                }}
              />
            </Grid>

            <Grid
              item
              xs={5}
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{
                  width: "144px",
                  height: "168px",
                  borderRadius: "12px",
                  backgroundColor: "#2d3034",
                }}
              >
                <img
                  src={props.faceMacth[0] ? props.faceMacth[0].foto : ""}
                  className={classes.photo}
                  alt="photo"
                />
                <Typo
                  fontSize={16}
                  fontWeight={500}
                  color={"#fff"}
                  textAlign={"center"}
                  text={props.faceMacth[0] ? props.faceMacth[0].nombre : ""}
                  marginTop={10}
                />
              </Grid>
              <Typo
                fontSize={18}
                color={"#fff"}
                textAlign={"center"}
                text={"Rostro similar"}
                //   marginTop={}
              />
            </Grid>
          </Grid>

          <Typo
            fontSize={18}
            color={"#fff"}
            textAlign={"center"}
            text={"¿Son personas diferentes?"}
            marginTop={60}
          />

          <Button
            variant="contained"
            disabled={disableButton}
            onClick={saveEmployee}
            style={{
              width: "320px",
              height: "48px",
              borderRadius: "8px",
              backgroundColor: "#ac0bce",
              textTransform: "none",
              marginTop: 30,
            }}
          >
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 18,
                textAlign: "left",
                color: "#fff",
              }}
            >
              Si, subir empleado
            </Typography>
          </Button>

          <Button
            variant="contained"
            disabled={disableButton}
            onClick={() => {
              props.setDisableButton(false);
              props.setOpenDialogSibling(!props.openDialogSibling);
            }}
            style={{
              width: "320px",
              height: "48px",
              borderRadius: "8px",
              backgroundColor: "#8f8f8f",
              textTransform: "none",
              marginTop: 30,
            }}
          >
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 20,
                textAlign: "left",
                color: "#fff",
              }}
            >
              Cancelar
            </Typography>
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
