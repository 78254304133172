/*eslint-disable*/
import React, { useState, useEffect, Fragment } from "react";
import {
  Grid,
  Typography,
  Button,
  Card,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  makeStyles,
  Checkbox,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { ValidatePermissions } from "../../utils/permissions";
import ScrollBars from "../../common_components/ScrollBars";
import MaterialTable from "material-table";
import axiosInstance from "../../utils/axiosInstance";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import DialogNewRole from "./DialogNewRole";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiInputBase-input": {
      color: "676767 !important",
    },
    "& .MuiIcon-root": {
      color: "#fff",
    },
    "& .MuiTable-root": {
      outline: "none !important",
      borderColor: "red",
    },
    "& .MuiPaper-elevation2": {
      boxShadow: "none",
    },
  },
  divMain: {
    flexGrow: 1,
    paddingTop: 10,
    paddingLeft: 20,
    height: "100%",
  },
  divBack: {
    cursor: "pointer",
    marginBottom: "10px",
  },
  back: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 500,
    textAlign: "left",
    color: "#fff",
  },
  card: {
    minHeight: 484,
    height: "auto",
    borderRadius: 19,
    backgroundColor: "#2d3034",
    padding: theme.spacing(2),
  },

  cardRole: {
    minWidth: 200,
    minHeight: 545,
    height: "auto",
    borderRadius: 19,
    backgroundColor: "#2d3034",
  },
  activeListItem: {
    paddingLeft: "45px",
    color: "#4a4a4a !important",
    borderRightWidth: 8,
    borderRightColor: theme.palette.primary.main,
    borderRightStyle: "solid",
    backgroundImage: "linear-gradient(to right, #414246 49%, #27292d 100%)",
    cursor: "pointer",
  },
  inactiveListItem: {
    paddingLeft: "45px",
    color: "#4a4a4a !important",
    cursor: "pointer",
  },
}));

export default function RolesScreen(props) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState();
  const [selectedPermission, setSelectedPermission] = useState();
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const { modules, keyPermission } = useSelector(
    (status) => status.permissions
  );

  const backStep = () => {
    history.push("/dashboard/usuarios");
  };

  const getRoles = async () => {
    await axiosInstance()
      .get(`/api/v1/security/role`)
      .then((response) => {
        let data = response.data.data;
        setRoles(data);
        setSelectedRole(data[0]);
        setSelectedPermission(flattenPermisions(data[0].Permisions));
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const updateRoles = async (id, Permisions) => {
    return await axiosInstance()
      .patch(`/api/v1/security/role/${id}`, { Permisions })
      .then((response) => {
        return true;
      })
      .catch((e) => {
        console.log(e);
        return false;
      });
  };

  var flattenPermisions = (permisions) => {
    return Object.entries(permisions).map(([name, obj]) => ({
      name,
      ...obj,
    }));
  };

  const handleSelectedRole = (index) => {
    setSelectedRole(roles[index]);
    setSelectedPermission(flattenPermisions(roles[index].Permisions));
  };

  useEffect(() => {
    getRoles();
  }, [update]);

  const handleUpdateRole = async () => {
    setUpdateLoading(true);
    let permisos = selectedPermission.reduce(function (result, item) {
      let data = { ...item };
      delete data.name;
      result[item["name"]] = data;
      return result;
    }, {});

    const update = await updateRoles(selectedRole._id, permisos);

    if (update) {
      setRoles((data) => {
        let newArr = [...data];
        const index = newArr.findIndex(
          (x) => x.RoleName == selectedRole.RoleName
        );
        newArr[index].Permisions = permisos;
        return newArr;
      });

      enqueueSnackbar("Role actualizado correctamente", {
        variant: "success",
      });
    } else {
      enqueueSnackbar("Error al actualizar role", {
        variant: "error",
      });
    }
    setUpdateLoading(false);
  };

  useEffect(() => {
    if (keyPermission && selectedPermission) {
      let colum = [{ title: "", field: "name" }];
      keyPermission.map((key) => {
        colum.push({
          title: key,
          field: key,
          render: (rowData) => {
            const check =
              selectedPermission?.find((x) => x.name == rowData.name)?.[key] ??
              false;
            return rowData[key] ? (
              <Checkbox
                size="small"
                disabled={rowData[key] ? false : true}
                style={{
                  color: check ? "#d233ff" : "#cecece",
                }}
                checked={check}
                onChange={(e) => handleChange(e, rowData.name, key)}
              />
            ) : (
              <Grid
                disabled={true}
                style={{
                  width: 15,
                  height: 15,
                  backgroundColor: "#3e3e3e",
                  borderRadius: "10%",
                  marginLeft: 10,
                  userSelect: "none",
                }}
              />
            );
          },
        });
      });
      setColumns(colum);
    }
  }, [keyPermission, selectedPermission]);

  useEffect(() => {
    if (modules) {
      setData(
        modules.map((mod) => ({
          ...mod.acciones,
          name: mod.nombre,
          padre: mod.padre,
        }))
      );
    }
  }, [modules]);

  const handleChange = (e, name, key) => {
    const index = selectedPermission.findIndex((x) => x.name == name);
    const module = data.find((x) => x.name == name);
    let newArr = [...selectedPermission];

    if (index == -1) {
      setSelectedPermission([
        ...newArr,
        {
          name,
          [key]: e.target.checked,
          ...(module?.padre && { padre: module?.padre }),
          ...(module?.submodulo && { submodulo: module?.submodulo }),
        },
      ]);
    } else {
      newArr[index][key] = e.target.checked;
      setSelectedPermission(newArr);
    }
  };

  return (
    <div className={classes.divMain}>
      <Grid container direction="column">
        <Grid
          item
          xs={12}
          container
          direction="row"
          alignItems="center"
          onClick={backStep}
          className={classes.divBack}
        >
          <Grid item>
            <FontAwesomeIcon
              icon={faArrowAltCircleLeft}
              style={{
                width: "20px",
                height: "20px",
                marginRight: "10px",
                color: "#d6d6d6",
              }}
            />
          </Grid>
          <Grid item>
            <Typography className={classes.back}>Regresar</Typography>
          </Grid>
        </Grid>

        <Grid
          item
          container
          direction="row"
          alignItems="flex-end"
          style={{
            marginBottom: 15,
            marginTop: 10,
          }}
        >
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 30,
              fontWeight: 500,
              textAlign: "left",
              color: "#fff",
            }}
          >
            Administración de roles
          </Typography>

          {ValidatePermissions("crear") && (
            <Grid>
              <Button
                onClick={() => setOpenDialog(true)}
                style={{
                  width: 140,
                  height: 40,
                  borderRadius: 8,
                  backgroundColor: "#ac0bce",
                  textTransform: "none",
                  marginLeft: 20,
                }}
              >
                <Typography
                  style={{
                    fontSize: 18,
                    color: "#fff",
                    fontWeight: 500,
                    textAlign: "left",
                  }}
                >
                  + Nuevo rol
                </Typography>
              </Button>
            </Grid>
          )}
        </Grid>

        <Grid item>
          <Grid item container direction="row" style={{ marginTop: 30 }}>
            <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
              <Card className={classes.cardRole}>
                <Typography
                  style={{
                    fontSize: 20,
                    color: "#fff",
                    textAlign: "left",
                    fontWeight: "bold",
                    padding: 20,
                  }}
                >
                  Roles
                </Typography>

                <ScrollBars style={{ height: 370 }}>
                  <List>
                    {roles?.map((rol, index) => (
                      <ListItem
                        key={index}
                        alignItems="center"
                        className={
                          selectedRole?._id === rol?._id
                            ? classes.activeListItem
                            : classes.inactiveListItem
                        }
                        onClick={() => handleSelectedRole(index)}
                      >
                        <ListItemText
                          disableTypography
                          primary={
                            <Typography
                              style={{
                                fontSize: 14,
                                color: "#bcbcbc",
                              }}
                            >
                              {rol.RoleName}
                            </Typography>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </ScrollBars>
              </Card>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              lg={8}
              xl={8}
              style={{ marginLeft: 30 }}
            >
              <Card className={classes.card} style={{marginBottom: 50 }}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignContent="center"
                  style={{ marginTop: 10, marginBottom: 10 }}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Typography
                      style={{
                        fontSize: 20,
                        color: "#fff",
                        textAlign: "left",
                        fontWeight: "bold",
                        padding: 20,
                      }}
                    >
                      Permisos
                    </Typography>

                    {ValidatePermissions("editar") && (
                      <Grid item style={{ padding: 10 }}>
                        <Button
                          onClick={handleUpdateRole}
                          disabled={updateLoading}
                          style={{
                            width: 177,
                            height: 40,
                            borderRadius: 4,
                            backgroundColor: "#ac0bce",
                            textTransform: "none",
                            // marginTop: 20,
                          }}
                        >
                          {!updateLoading ? (
                            <Typography
                              style={{
                                fontSize: 16,
                                color: "#fff",
                                fontWeight: 500,
                                textAlign: "left",
                              }}
                            >
                              Guardar
                            </Typography>
                          ) : (
                            <CircularProgress
                              style={{ color: "white" }}
                              size={24}
                            />
                          )}
                        </Button>
                      </Grid>
                    )}
                  </Grid>

                  <div className={classes.root} style={{ width: "100%"}}>
                    <MaterialTable
                      data={data}
                      columns={columns}
                      parentChildData={(row, rows) =>
                        rows.find((a) => a.name === row?.padre)
                      }
                      options={{
                        selection: false,
                        search: false,
                        paging: false,
                        showTitle: false,
                        toolbar: false,
                        headerStyle: {
                          backgroundColor: "#2d3034",
                          color: "#fff",
                        },
                        rowStyle: {
                          backgroundColor: "#2d3034",
                          color: "#fff",
                        },
                      }}
                    />
                  </div>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <DialogNewRole
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        setUpdate={setUpdate}
      />
    </div>
  );
}
