/*eslint-disable*/
import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  divMain: {
    // width: "100px",
    // height: "100px",
    // backgroundColor: "#36393C",
  },
}));

export default function ReportEmployeeScreen(props) {
  const classes = useStyles();

  return <div className={classes.divMain}></div>;
}
