/*eslint-disable*/
import React from "react";
import DataGrid from "../../../common_components/Filters/DataGrid";
import { Grid, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { makeStyles } from "@material-ui/styles";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .header": {
      color: "white",
      fontFamily: "Roboto",
      fontSize: "18px",
      fontWeight: 500,
      display: "flex",
    },
  },
  avatar: {
    marginTop: 8,
    width: 35,
    height: 35,
    backgroundColor: theme.palette.primary.main,
  },
  typoStatus: {
    fontFamily: "Roboto",
    fontSize: 16,
    color: theme.palette.secondary.main,
  },
  iconStatus: {
    width: "18px",
    height: "18px",
    color: theme.palette.primary.main,
    marginTop: 2,
    marginRight: 5,
  },
  typoRetry: {
    fontFamily: "Roboto",
    fontSize: 12,
    color: theme.palette.primary.main,
    marginLeft: 6,
  },
}));

export default function DataGridUsers(props) {
  const classes = useStyles();

  function stringAvatar(names) {
    let initials = "";
    names.split(" ").map((name) => {
      initials = initials + name[0];
    });

    if (initials.length > 2) {
      initials = initials.slice(0, 2);
    }

    return {
      children: initials,
    };
  }

  const columns = [
    {
      field: "photo",
      headerName: " ",
      headerAlign: "center",
      flex: 0.5,
      sortable: false,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          params.row.Name != "" && (
            <div style={{ margin: "auto" }}>
              <Avatar
                className={classes.avatar}
                {...stringAvatar(params.row.Name)}
              />
            </div>
          )
        );
      },
    },
    {
      field: "Name",
      headerName: "Nombre",
      headerAlign: "center",
      flex: 1,
      sortable: false,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <div style={{ margin: "auto" }}>
            {params.row.Name ? params.row.Name : "--"}
          </div>
        );
      },
    },
    {
      field: "Email",
      headerName: "Email",
      headerAlign: "center",
      flex: 1.5,
      sortable: false,
      headerClassName: "header",
    },
    {
      field: "RoleName",
      headerName: "Rol",
      headerAlign: "center",
      flex: 1,
      sortable: false,
      headerClassName: "header",
    },
    // {
    //   field: "SubcontractorName",
    //   headerName: "Subcontratista",
    //   headerAlign: "center",
    //   flex: 1,
    //   sortable: false,
    //   headerClassName: "header",
    // },
    {
      field: "Status",
      headerName: "Estatus",
      headerAlign: "center",
      flex: 0.8,
      sortable: false,
      headerClassName: "header",

      renderCell: (params) => {
        return (
          <div style={{ margin: "auto" }}>
            {params.row.Status == "Activo" ? (
              <Grid container direction="row">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className={classes.iconStatus}
                />
                <Typography className={classes.typoStatus}>Activo</Typography>
              </Grid>
            ) : params.row.Status == "Desactivado" ? (
              <Grid container direction="row">
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  className={classes.iconStatus}
                  style={{
                    color: "#eb1d1d",
                  }}
                />
                <Typography className={classes.typoStatus}>
                  Desactivado
                </Typography>
              </Grid>
            ) : params.row.Status == "Invitación enviada" ? (
              <Grid container direction="column">
                <Typography className={classes.typoStatus}>
                  Invitación enviada
                </Typography>
                <div
                  onClick={() => {
                    props.setComplete(true);
                    props.setOpendialog(true);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <Typography className={classes.typoRetry}>
                    Volver a enviar invitación
                  </Typography>
                </div>
              </Grid>
            ) : (
              <div></div>
            )}
          </div>
        );
      },
    },
    // {
    //   field: "obras",
    //   headerName: "N° de obras",
    //   headerAlign: "center",
    //   flex: 0.8,
    //   sortable: false,
    //   headerClassName: "header",
    //   renderCell: (params) => {
    //     return <div style={{ margin: "auto" }}>{params.row.ObrasId.length()}</div>;
    //   },
    // },
  ];

  return (
    <DataGrid
      rows={props.rows}
      columns={columns}
      loading={props.loading}
      rowCount={props.rowCount}
      rowsPerPageOptions={props.rowsPerPageOptions}
      page={props.page}
      pageSize={props.pageSize}
      onPageChange={props.onPageChange}
      onPageSizeChange={props.onPageSizeChange}
      style={props.style}
      onSelectionModelChange={props.onSelectionModelChange}
      selectionModel={props.selectionModel}
    />
  );
}
