/*eslint-disable*/
import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Typography, TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    // backgroundColor: "blue",
  },
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: "bold",
    textAlign: "left",
    color: "#cdcece",
  },
  textFieldCustom: {
    width: "90%",
    color: "#676767",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
  },
  select: {
    width: "230px",
    height: "40px",
    borderRadius: "6px",
    backgroundColor: "#fff",
  },
  gridMargin: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
}));

export default function InfoAdvance(props) {
  const classes = useStyles();

  const handleChange = (e) => {
    props.setChangesFlag(true);
    const value = e.target.value;
    props.setSubcontractorData({
      ...props.subcontractorData,
      [e.target.name]: value,
    });
    // console.log(props.subcontractorData);
  };

  return (
    <Grid container className={classes.divMain}>
      <Grid
        item
        lg={12}
        xl={12}
        style={{
          height: "80px",
        }}
      />

      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
        xl={4}
        className={classes.gridMargin}
      >
        <Typography className={classes.textFieldLetter}>
          Banco a depositar
        </Typography>
        <TextField
          name="banco"
          size="small"
          variant="outlined"
          value={props.subcontractorData.banco}
          className={classes.textFieldCustom}
          onChange={handleChange}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
        xl={4}
        className={classes.gridMargin}
      >
        <Typography className={classes.textFieldLetter}>
          Clabe interbancaria
        </Typography>
        <TextField
          type="number"
          name="clabe"
          size="small"
          variant="outlined"
          value={props.subcontractorData.clabe}
          className={classes.textFieldCustom}
          onChange={handleChange}
          onInput={(e) => {
            e.target.value = e.target.value.slice(0, 18);
            // e.target.value = Math.max(0, parseInt(e.target.value))
            //   .toString()
            //   .slice(0, 19);
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
        xl={4}
        className={classes.gridMargin}
      >
        <Typography className={classes.textFieldLetter}>
          Número de cuenta bancaria
        </Typography>
        <TextField
          type="number"
          name="cuentaBancaria"
          size="small"
          variant="outlined"
          value={props.subcontractorData.cuentaBancaria}
          className={classes.textFieldCustom}
          onChange={handleChange}
          onInput={(e) => {
            e.target.value = e.target.value.slice(0, 10);
            // e.target.value = Math.max(0, parseInt(e.target.value))
            //   .toString()
            //   .slice(0, 10);
          }}
        />
      </Grid>
    </Grid>
  );
}
