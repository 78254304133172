/*eslint-disable*/
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import {
  Typography,
  TextField,
  Button,
  Grid,
  InputAdornment,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faArrowAltCircleLeft,
} from "@fortawesome/free-solid-svg-icons";
import { ipServer } from "../../../config/vars";
import axios from "axios";
const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@”]+(\.[^<>()\[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/;

const useStyles = makeStyles((theme) => ({
  divMain: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    padding: theme.spacing(5),
    // backgroundColor: "blue",
  },
  divBack: {
    cursor: "pointer",
    marginBottom: "20px",
  },
  iconBack: {
    width: "34px",
    height: "34px",
    color: "#ADADAD",
    // color: theme.palette.secondary.light,
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 32,
    fontWeight: 600,
    color: theme.palette.secondary.dark,
    marginTop: "6%",
  },
  subtitle: {
    fontFamily: "Roboto",
    fontSize: 18,
    color: theme.palette.secondary.light,
  },
  textFieldLabel: {
    fontFamily: "Roboto",
    fontSize: 25,
    color: theme.palette.secondary.light,
    marginTop: "15%",
  },
  textFieldCustom: {
    "& label.Mui-focused": {
      color: "#6C6C6C",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "yellow",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#E6E6E6",
      },
      "&:hover fieldset": {
        borderColor: "#6C6C6C",
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.primary.main,
      },
    },
    boxShadow: "0 0 10px 0 rgba(155, 155, 155, 0.22)",
    borderRadius: "4px",
    backgroundColor: "#fff",
  },
  button: {
    height: "56px",
    marginTop: "20%",
    marginBottom: "20%",
    borderRadius: "4px",
    backgroundColor: theme.palette.primary.main,
    textTransform: "none",
  },
  typoButton: {
    fontFamily: "Roboto",
    fontSize: 28,
    fontWeight: 300,
    color: theme.palette.neutral.primary,
  },
}));

export default function ForgotPassword(props) {
  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const backStep = () => {
    history.push("/inicio_sesion");
  };

  const handleChange = (e) => {
    // console.log(e.target.value);
    props.setPasswordData({
      ...props.passwordData,
      [e.target.name]: e.target.value,
    });
  };

  const errorTextField = (email) => {
    if (email == "") {
      enqueueSnackbar("Favor de ingresar los datos requeridos", {
        variant: "error",
      });
      return false;
    }
    return true;
  };

  const errorEmailFormat = (email) => {
    // console.log(emailRegex.test(email));
    if (!emailRegex.test(email)) {
      enqueueSnackbar("Favor de ingresar un correo valido", {
        variant: "error",
      });
      return false;
    }
    return true;
  };

  const onSubmit = () => {
    if (!errorTextField(props.passwordData.email)) return;
    if (!errorEmailFormat(props.passwordData.email)) return;

    axios
      .post(`${ipServer}/api/v1/security/user/password/renewcode/`, {
        Email: props.passwordData.email,
      })
      .then((response) => {
        // console.log(response.data);
        props.setStep(2);
        enqueueSnackbar("El codigo se ha enviado a tu correo", {
          variant: "success",
        });
      })
      .catch((e) => {
        console.log(e.response?.data);
        if (e.response?.data?.errors == "User not exist") {
          enqueueSnackbar(
            "El correo no se encuentra registrado, favor de verificar",
            {
              variant: "error",
            }
          );
        }
      });
  };

  return (
    <div className={classes.divMain}>
      <Grid container direction="row" justifyContent="flex-start">
        <div className={classes.divBack} onClick={backStep}>
          <FontAwesomeIcon
            icon={faArrowAltCircleLeft}
            className={classes.iconBack}
          />
        </div>
      </Grid>

      <Grid
        item
        xs={10}
        container
        direction="column"
        alignItems="flex-start"
        style={{
          width: "503px",
          height: "100%",
        }}
      >
        <Typography className={classes.title}>
          ¿Olvidaste tu contraseña?
        </Typography>
        <Typography className={classes.subtitle}>
          Ingresa el correo para recibir un código de recuperación
        </Typography>

        <Typography className={classes.textFieldLabel}>Email</Typography>
        <TextField
          id="email"
          type="email"
          //   defaultValue="taylor.gonzalez@knesysplus.com"
          variant="outlined"
          fullWidth
          name="email"
          size="medium"
          autoComplete="email"
          onChange={handleChange}
          className={classes.textFieldCustom}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              onSubmit();
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className={classes.iconForm}
                  style={{
                    width: "24px",
                    height: "24px",
                    color: "#ADADAD",
                  }}
                />
              </InputAdornment>
            ),
          }}
        />

        <Button
          fullWidth
          variant="contained"
          onClick={onSubmit}
          className={classes.button}
        >
          <Typography className={classes.typoButton}>Siguiente</Typography>
        </Button>
      </Grid>
    </div>
  );
}
