/*eslint-disable*/
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import DataGrid from "../../../../common_components/Filters/DataGrid";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .header": {
      color: "white",
      fontFamily: "Roboto",
      fontSize: "18px",
      fontWeight: 500,
      display: "flex",
    },
  },
  photo: {
    width: "40px",
    height: "40px",
    borderRadius: "8px",
    margin: "auto",
    aspectRatio: 1,
  },
}));

export default function DataGridInventory(props) {
  const classes = useStyles();

  const columns = [
    {
      field: "stockNumber",
      headerName: "Número de stock",
      headerAlign: "center",
      flex: 0.8,
      sortable: false,
      headerClassName: "header",
    },
    {
      field: "name",
      headerName: "Material",
      headerAlign: "center",
      flex: 1.2,
      sortable: false,
      headerClassName: "header",
    },
    {
      field: "description",
      headerName: "Descripción",
      headerAlign: "center",
      flex: 1.2,
      sortable: false,
      headerClassName: "header",
    },
    {
      field: "unit",
      headerName: "Unidad",
      headerAlign: "center",
      flex: 0.8,
      sortable: false,
      headerClassName: "header",
    },
    {
      field: "stock",
      headerName: "Stock disponible",
      headerAlign: "center",
      flex: 0.8,
      sortable: false,
      headerClassName: "header",
    },
  ];

  return (
    <DataGrid
      rows={props.rows}
      columns={columns}
      loading={props.loading}
      rowCount={props.rowCount}
      rowsPerPageOptions={props.rowsPerPageOptions}
      page={props.page}
      pageSize={props.pageSize}
      onPageChange={props.onPageChange}
      onPageSizeChange={props.onPageSizeChange}
      style={props.style}
      onSelectionModelChange={props.onSelectionModelChange}
      selectionModel={props.selectionModel}
    />
  );
}
