/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import ShowMoreButton from "../../Components/ShowMoreButton";
import { ValidatePermissions } from "../../../../utils/permissions";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    // height: "100%",
    // backgroundColor: "red",
  },
  gridBasic: {
    padding: theme.spacing(3),
    height: "450px",
    borderRadius: "16px",
    backgroundColor: "#2d3034",
  },
}));

export default function CardDetailSubcontractor(props) {
  const classes = useStyles();
  const history = useHistory();

  const handleEdit = () => {
    history.push(
      `/dashboard/contratistas/editar/${localStorage.getItem(
        "idSubcontractor"
      )}`
    );
  };

  return (
    <div className={classes.divMain}>
      <Grid item xs={12} className={classes.gridBasic}>
        <Grid container direction="row">
          <Grid item xs={10}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 22,
                fontWeight: 500,
                color: "#fff",
              }}
            >
              Datos subcontratista
            </Typography>
          </Grid>
          {ValidatePermissions("editar") ? (
            <Grid
              item
              xs={2}
              container
              direction="row"
              justifyContent="flex-end"
            >
              <Typography
                onClick={handleEdit}
                style={{
                  fontFamily: "Roboto",
                  fontSize: 18,
                  fontWeight: 500,
                  color: "#d233ff",
                  cursor: "pointer",
                }}
              >
                Editar
              </Typography>
            </Grid>
          ) : (
            <div></div>
          )}
        </Grid>

        <Grid
          style={{
            height: "40px",
          }}
        />

        <Grid container direction="row">
          <Grid item xs={6}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 16,
                color: "#c1c1c1",
              }}
            >
              Nombre empresa
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 16,
                color: "#c1c1c1",
              }}
            >
              RFC
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 18,
                color: "#fff",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                maxWidth: "90%",
              }}
            >
              {props.name}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 18,
                color: "#fff",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                maxWidth: "90%",
              }}
            >
              {props.rfc.toUpperCase()}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          style={{
            height: "20px",
          }}
        />

        <Grid container direction="row">
          <Grid item xs={6}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 16,
                color: "#c1c1c1",
              }}
            >
              Razón social
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 16,
                color: "#c1c1c1",
              }}
            >
              Registro patronal IMSS
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 18,
                color: "#fff",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                maxWidth: "90%",
              }}
            >
              {props.businessName}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 18,
                color: "#fff",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                maxWidth: "90%",
              }}
            >
              {props.securityNumber}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          style={{
            height: "20px",
          }}
        />

        <Grid container direction="row">
          <Grid item xs={6}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 16,
                color: "#c1c1c1",
              }}
            >
              Unidad
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 16,
                color: "#c1c1c1",
              }}
            >
              Banco
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 18,
                color: "#fff",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                maxWidth: "90%",
              }}
            >
              {props.unit}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 18,
                color: "#fff",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                maxWidth: "90%",
              }}
            >
              {props.bank}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          style={{
            height: "20px",
          }}
        />

        <Grid container direction="row">
          <Grid item xs={6}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 16,
                color: "#c1c1c1",
              }}
            >
              Nombre representante
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 16,
                color: "#c1c1c1",
              }}
            >
              Teléfono contacto
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 18,
                color: "#fff",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                maxWidth: "90%",
              }}
            >
              {props.agent}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 18,
                color: "#fff",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                maxWidth: "90%",
              }}
            >
              {props.telephone}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          style={{
            height: "20px",
          }}
        />

        {/* <Grid item xs={12} container direction="row" justifyContent="center">
          <ShowMoreButton></ShowMoreButton>
        </Grid> */}
      </Grid>
    </div>
  );
}
