/*eslint-disable*/
import { useSnackbar } from "notistack";
import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { SetScreenProfile } from "../../../actions/screen";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import Settings from "@material-ui/icons/Settings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey, faBuilding } from "@fortawesome/free-solid-svg-icons";
import Typo from "../../../common_components/typo";
import { ValidatePermissionsModule } from "../../../utils/permissions";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
  },
  icon: {
    color: "white",
  },
  textInfo: {
    fontFamily: "Roboto",
    fontSize: 20,
    fontWeight: 500,
    textAlign: "left",
    color: "#ffffff",
    marginBottom: 50,
    cursor: "pointer",
  },
  divIconActive: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "202px",
    height: "40px",
    padding: "16px",
    marginBottom: 10,
    borderRadius: "8px",
    backgroundColor: "#2d3034",
    cursor: "pointer",
  },
  divIconInactive: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "202px",
    height: "40px",
    padding: "16px",
    marginBottom: 10,
    borderRadius: "8px",
    cursor: "pointer",
  },
  divDivider: {
    width: "70%",
    height: 1,
    marginBottom: 5,
    backgroundColor: "#fff",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));

export default function FlowComponent(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { screenProfile } = useSelector((state) => state.screen);

  const changeTab = (tab) => {
    if (props.changesFlag || !props.mandatoryFlag) {
      props.setTab(tab);
      props.setOpendialogBack(!props.openDialogBack);
      return;
    }

    dispatch(SetScreenProfile(tab));
  };

  return (
    <div className={classes.divMain}>
      <Grid
        container
        direction="column"
        alignItems="flex-start"
        justifyContent="center"
        // style={{
        //   backgroundColor: "red",
        // }}
      >
        <div
          onClick={() => changeTab(1)}
          className={
            screenProfile === 1
              ? classes.divIconActive
              : classes.divIconInactive
          }
        >
          <PersonOutlineIcon className={classes.icon} />
          <Typo fontSize={20} color={"#fff"} text={"Perfil"} marginLeft={12} />
        </div>

        <div
          onClick={() => changeTab(2)}
          className={
            screenProfile === 2
              ? classes.divIconActive
              : classes.divIconInactive
          }
        >
          <FontAwesomeIcon
            icon={faKey}
            style={{
              width: "20px",
              height: "20px",
              color: "#fff",
              marginRight: 3,
            }}
          />
          <Typo
            fontSize={20}
            color={"#fff"}
            text={"Contraseña"}
            marginLeft={12}
          />
        </div>

        {/* <div
          // onClick={() => changeTab(3)}
          className={
            screenProfile === 3
              ? classes.divIconActive
              : classes.divIconInactive
          }
        >
          <Settings className={classes.icon} />
          <Typo
            fontSize={20}
            color={"#fff"}
            text={"Configuración"}
            marginLeft={12}
          />
        </div> 

        <div className={classes.divDivider} /> */}

        {ValidatePermissionsModule("Read", "PerfilSubcontratista") && (
          <div
            onClick={() => changeTab(4)}
            className={
              screenProfile === 4
                ? classes.divIconActive
                : classes.divIconInactive
            }
          >
            <FontAwesomeIcon
              icon={faBuilding}
              style={{
                width: "20px",
                height: "20px",
                color: "#fff",
                marginRight: 3,
              }}
            />
            <Typo
              fontSize={20}
              color={"#fff"}
              text={"Perfil empresa"}
              marginLeft={12}
            />
          </div>
        )}

        {/* <div
          // onClick={() => changeTab(5)}
          className={
            screenProfile === 5
              ? classes.divIconActive
              : classes.divIconInactive
          }
        >
          <FontAwesomeIcon
            icon={faKey}
            style={{
              width: "20px",
              height: "20px",
              color: "#fff",
              marginRight: 3,
            }}
          />
          <Typo
            fontSize={20}
            color={"#fff"}
            text={"Licencia"}
            marginLeft={12}
          />
        </div> */}
      </Grid>
    </div>
  );
}
