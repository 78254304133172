/*eslint-disable*/
import React from "react";
import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";

export const BarChart = ({ chartData }) => {
  return (
    <div>
      <Bar
        data={chartData}
        options={{
          plugins: {
            title: {
              display: false,
              text: "Cryptocurrency prices",
            },
            legend: {
              display: false,
              position: "bottom",
            },
          },
        }}
        style={{
          width: "550px",
          // height: "150px",
        }}
      />
    </div>
  );
};
