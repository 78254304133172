/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Button, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  mainDiv: {
    height: "100vh",
    backgroundColor: "#1d2024",
  },
  inactiveListItem: {
    width: "140px",
    height: "48px",
    marginRight: "10px",
    color: "#b4b4b4 !important",
    textTransform: "none",
    // backgroundColor: "blue",
  },
  activeListItem: {
    width: "140px",
    height: "48px",
    marginRight: "10px",
    borderRadius: "25px",
    backgroundColor: "#2d3034",
    textTransform: "none",
    "&.Mui-selected": {
      color: "white !important",
    },
    "&:hover": {
      backgroundColor: "#45494E",
    },
  },
  typografy: {
    fontFamily: "Roboto",
    fontSize: 18,
    fontWeight: 500,
    textAlign: "center",
    color: "#fff",
  },
}));

export default function TopTabComponent(props) {
  const classes = useStyles();

  return (
    <div>
      <Grid item xs={12} container direction="row">
        <Button
          onClick={() => props.setTab(1)}
          className={
            props.tab === 1 ? classes.activeListItem : classes.inactiveListItem
          }
        >
          <Typography className={classes.typografy}>Perfil</Typography>
        </Button>

        <Button
          onClick={() => props.setTab(2)}
          className={
            props.tab === 2 ? classes.activeListItem : classes.inactiveListItem
          }
        >
          <Typography className={classes.typografy}>Asistencia</Typography>
        </Button>

        {/*<Button
          onClick={() => props.setTab(3)}
          className={
            props.tab === 3 ? classes.activeListItem : classes.inactiveListItem
          }
        >
          <Typography className={classes.typografy}>Reportes</Typography>
        </Button>*/}
      </Grid>
    </div>
  );
}
