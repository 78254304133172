/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Typography, Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleLeft,
  faEllipsisV,
} from "@fortawesome/free-solid-svg-icons";
import TopTabComponent from "./components/TopTab";
import ObrasProfileScreen from "./ProfileScreen/ObrasProfileScreen";
import DialogDeleteObras from "./components/DialogDeleteObras";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DeleteIcon from "@material-ui/icons/Delete";
import { useDispatch, useSelector } from "react-redux";
import SubcontractorProfileScreen from "../ContractorDetailScreen/SubcontractorProfileScreen/SubcontractorProfileScreen";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    paddingLeft: 15,
  },
  divBack: {
    cursor: "pointer",
    marginBottom: "10px",
  },
  back: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 500,
    textAlign: "left",
    color: "#fff",
  },
  icon: {
    color: "white",
    marginRight: 6,
    marginTop: -2,
  },
}));

export default function DetailObrasScreen(props) {
  const classes = useStyles();
  const history = useHistory();
  const [tab, setTab] = useState(1);
  const [openDialog, setOpendialog] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    setOpendialog(true);
    setAnchorEl(null);
  };

  const backStep = () => {
    history.push(`/dashboard/obras`);
  };

  const renderScreen = () => {
    if (tab == 1) return <ObrasProfileScreen />;
  };

  return (
    <div className={classes.divMain}>
      <Grid container>
        <Grid
          item
          xs={1}
          container
          direction="row"
          alignItems="center"
          onClick={backStep}
          className={classes.divBack}
        >
          <Grid item>
            <FontAwesomeIcon
              icon={faArrowAltCircleLeft}
              style={{
                width: "20px",
                height: "20px",
                marginRight: "10px",
                color: "#d6d6d6",
              }}
            />
          </Grid>
          <Grid item>
            <Typography className={classes.back}>Regresar</Typography>
          </Grid>
        </Grid>
        <Grid item xs={11}></Grid>
      </Grid>

      <Grid container direction="row">
        <Grid item xs={8}>
          <TopTabComponent tab={tab} setTab={setTab}></TopTabComponent>
        </Grid>

        {/* <Grid
          container
          item
          xs={4}
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button
            aria-label="more"
            id="long-button"
            aria-controls="long-menu"
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            style={{
              backgroundColor: "#2d3034",
              maxWidth: "48px",
              maxHeight: "48px",
              minWidth: "48px",
              minHeight: "48px",
              borderRadius: 8,
            }}
          >
            <FontAwesomeIcon
              icon={faEllipsisV}
              style={{
                width: "20px",
                height: "25px",
                color: "#fff",
              }}
            />
          </Button>
          <Menu
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
            id="long-menu"
            getContentAnchorEl={null}
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleClose}
            PaperProps={{
              style: {
                backgroundColor: "#27292d",
              },
            }}
          >
            <MenuItem
              key={"Eliminar"}
              style={{ color: "white" }}
              onClick={handleDelete}
            >
              <DeleteIcon className={classes.icon} />
              {"Eliminar obra"}
            </MenuItem>
          </Menu>
        </Grid> */}
      </Grid>

      <DialogDeleteObras
        openDialog={openDialog}
        setOpendialog={setOpendialog}
      />
      {renderScreen()}
    </div>
  );
}
