/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  InputAdornment,
  Grid,
  TextField,
  Button,
  // Select,
  // FormControl,
  // MenuItem,
  CircularProgress,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MultiSelect from "../../../common_components/Filters/MutiSelect";
import * as Yup from "yup";
import { useFormik } from "formik";
import axiosInstance from "../../../utils/axiosInstance";
import { CapitalizeString } from "../../../utils/CapitalizeString";

const useStyles = makeStyles((theme) => ({
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: "bold",
    textAlign: "left",
    color: "#acacac",
  },
  textFieldCustom: {
    width: "400px",
    color: "#acacac",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
  },
  multi: {
    color: "white",
    padding: 2,
    border: 0,
    borderRadius: "8px",
    maxWidth: 400,

    "&.rmsc .dropdown-heading ": {
      backgroundColor: "white",
      color: "#acacac",
      borderRadius: 5,
    },
  },
  select: {
    width: "400px",
    height: "40px",
    borderRadius: "6px",
    backgroundColor: "#fff",
    color: "#acacac",
  },
}));

export default function DialogInviteSubcontratista(props) {
  const classes = useStyles();
  const { setOpendialog, openDialog, formik } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [proyectos, setProyectos] = useState(null);
  const [disableButton, setDisableButton] = useState(false);

  // function rfcValido(rfc, aceptarGenerico = true) {
  //   const re =
  //     /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
  //   var validado = rfc.match(re);

  //   if (!validado) return false;

  //   const digitoVerificador = validado.pop(),
  //     rfcSinDigito = validado.slice(1).join(""),
  //     len = rfcSinDigito.length,
  //     diccionario = "0123456789ABCDEFGHIJKLMN&OPQRSTUVWXYZ Ñ",
  //     indice = len + 1;
  //   var suma, digitoEsperado;

  //   if (len == 12) suma = 0;
  //   else suma = 481;

  //   for (var i = 0; i < len; i++)
  //     suma += diccionario.indexOf(rfcSinDigito.charAt(i)) * (indice - i);
  //   digitoEsperado = 11 - (suma % 11);
  //   if (digitoEsperado == 11) digitoEsperado = 0;
  //   else if (digitoEsperado == 10) digitoEsperado = "A";

  //   if (
  //     digitoVerificador != digitoEsperado &&
  //     (!aceptarGenerico || rfcSinDigito + digitoVerificador != "XAXX010101000")
  //   )
  //     return false;
  //   else if (
  //     !aceptarGenerico &&
  //     rfcSinDigito + digitoVerificador == "XEXX010101000"
  //   )
  //     return false;
  //   return rfcSinDigito + digitoVerificador;
  // }

  // Yup.addMethod(Yup.string, "ValidateRFC", function (errorMessage) {
  //   return this.test(`test-card-length`, errorMessage, function (value) {
  //     const { path, createError } = this;

  //     return (
  //       (value && rfcValido(value)) ||
  //       createError({ path, message: errorMessage })
  //     );
  //   });
  // });

  // const schema = Yup.object({
  //   correo: Yup.string()
  //     .required("Campo Obligatorio")
  //     .email("Formato Incorrecto"),
  //   empresa: Yup.string().required("Campo Obligatorio"),
  //   unidad: Yup.string().required("Campo Obligatorio"),
  //   numeroSeguro: Yup.string()
  //     .required("Campo Obligatorio")
  //     .matches(/^[a-zA-Z]{1}(\d{10})?$/, "Registro Patronal Invalido"),
  //   obras: Yup.array().required("Campo Obligatorio"),
  //   rfc: Yup.string().required("Campo Obligatorio").ValidateRFC("RFC Invalido"),
  // });

  // const formik = useFormik({
  //   initialValues: {
  //     correo: "",
  //     empresa: "",
  //     unidad: " ",
  //     rfc: "",
  //     numeroSeguro: "",
  //     obras: [],
  //   },
  //   validationSchema: schema,
  // });

  // const validateData = async () => {
  //   return await schema.isValid(formik.values);
  // };

  const handleInvite = () => {
    setOpendialog(!openDialog);
  };

  const registerSubcontractor = async (
    correo,
    empresa,
    rfc,
    unidad,
    numeroSeguro
  ) => {
    return await axiosInstance()
      .post("/api/v1/rules/subcontratistas", {
        correo: correo,
        empresa: empresa,
        rfc: rfc.toUpperCase(),
        numeroSeguro: numeroSeguro,
        unidad: unidad,
        firstTime: true,
      })
      .then((response) => {
        // console.log("subcontratistas", response.data.data);
        return response.data.data._id;
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response.data.message == "Validacion de datos duplicados") {
            enqueueSnackbar(
              `Subcontratista con ` +
                error.response.data?.data[0]?.name +
                ` registrado previamente`,
              {
                variant: "error",
              }
            );
          }
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }

        setDisableButton(false);
        // console.log(e);
        return null;
      });
  };

  const assignSubcontractor = async (idSubcontractor, proyectos) => {
    var subcontratistas = [];

    subcontratistas.push(idSubcontractor);

    return await axiosInstance()
      .post("/api/v1/rules/assing", {
        proyectos: proyectos,
        subcontratistas: subcontratistas,
      })
      .then((response) => {
        // console.log(response.data);
        return true;
      })
      .catch((e) => {
        console.log(e);
        return false;
      });
  };

  const invite = async (correo, projects, idSubcontractor, RoleId) => {
    return await axiosInstance()
      .post("/api/v1/security/user/invite/", {
        Email: correo,
        SubcontractorId: idSubcontractor,
        ObrasId: projects,
        RoleId: RoleId,
      })
      .then((response) => {
        // console.log(response.data);
        return true;
      })
      .catch((e) => {
        console.log(e);
        return false;
      });
  };

  const getRolesId = async (Role) => {
    return await axiosInstance()
      .get("/api/v1/security/role/")
      .then((response) => {
        // console.log(response.data);
        return response.data.data.find((role) => role.RoleName === Role)?._id;
      })
      .catch((e) => {
        console.log(e);
        return null;
      });
  };

  const deleteSubcontractor = async (subcontractorId) => {
    return await axiosInstance()
      .delete(`/api/v1/rules/subcontratistas/${subcontractorId}`)
      .then((response) => {
        return true;
      })
      .catch((e) => {
        console.log(e);
        return false;
      });
  };

  const inviteSubcontractor = async () => {
    setDisableButton(true);
    const { correo, empresa, rfc, numeroSeguro, unidad, obras } = formik.values;

    let projects = [];
    obras.map((subcontractor, index) => {
      projects.push(subcontractor.value);
    });

    if (projects.length === 0) {
      enqueueSnackbar("Seleccionar al menos una obra", {
        variant: "error",
      });
      setDisableButton(false);
      return;
    }

    if (!(await props.validateData())) {
      enqueueSnackbar("Llenar todos los campos obligatorios", {
        variant: "error",
      });
      setDisableButton(false);
      return;
    }

    const idSubcontractor = await registerSubcontractor(
      correo,
      empresa,
      rfc,
      unidad,
      numeroSeguro
    );

    if (idSubcontractor) {
      const RoleId = await getRolesId("Administrador Subcontratista");
      if (RoleId && (await assignSubcontractor(idSubcontractor, projects))) {
        if (
          correo == "" ||
          (await invite(correo, projects, idSubcontractor, RoleId))
        ) {
          enqueueSnackbar("Subcontratista registrado correctamente", {
            variant: "success",
          });
          setOpendialog(!openDialog);

          setTimeout(() => {
            location.reload();
          }, 500);
        } else {
          await deleteSubcontractor(idSubcontractor);
          enqueueSnackbar("Error al registrar subcontratista", {
            variant: "error",
          });
        }
      } else {
        await deleteSubcontractor(idSubcontractor);
        enqueueSnackbar("Error al registrar subcontratista", {
          variant: "error",
        });
      }
    } else {
      enqueueSnackbar("Error al registrar subcontratista", {
        variant: "error",
      });
    }

    setTimeout(() => {
      setDisableButton(false);
    }, 2000);
  };

  const handleQueryChange = (queryElement) => (e) => {
    formik.setFieldValue(queryElement, e);
  };

  const getProyectsList = () => {
    axiosInstance()
      .get("/api/v1/rules/proyectos")
      .then((response) => {
        const proyects = response.data.data.map((object) => {
          return { label: object.proyecto, value: object._id };
        });
        setProyectos(proyects);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getProyectsList();
  }, []);

  useEffect(() => {
    if (!openDialog) {
      setDisableButton(false);
      formik.resetForm();
    }
  }, [openDialog]);

  return (
    <Dialog
      open={openDialog}
      onClose={handleInvite}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        style: {
          backgroundColor: "#171a1e",
          textColor: "#fsf",
          width: "483px",
          height: "691px",
          paddingTop: 15,
        },
      }}
    >
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 20,
              textAlign: "center",
              color: "#fff",
              fontWeight: "bold",
            }}
          >
            Invitación subcontratista
          </Typography>

          <Grid
            container
            justifyContent="flex-end"
            style={{ position: "absolute" }}
          >
            <Button onClick={handleInvite}>
              <FontAwesomeIcon
                icon={faTimes}
                style={{
                  width: "20px",
                  height: "20px",
                  color: "#fff",
                }}
              />
            </Button>
          </Grid>
        </Grid>
        <div
          style={{
            width: "100%",
            height: 1,
            background: "#707070",
            marginTop: 8,
            padding: 0,
          }}
        ></div>
      </DialogTitle>

      <DialogContent>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid>
            <Typography className={classes.textFieldLetter}>
              Email subcontratista
            </Typography>
            <TextField
              // placeholder="Obligatorio"
              id="correo"
              name="correo"
              type="email"
              size="small"
              variant="outlined"
              className={classes.textFieldCustom}
              value={formik.values.correo.toLowerCase()}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              inputProps={{
                style: {
                  textTransform: formik.values.correo != "" && "lowercase",
                },
              }}
            />
            {formik.touched.correo && formik.errors.correo ? (
              <Typography style={{ color: "#FF1717", marginBottom: -20 }}>
                {formik.errors.correo}
              </Typography>
            ) : null}

            <Typography
              className={classes.textFieldLetter}
              style={{ marginTop: 20 }}
            >
              Nombre empresa
            </Typography>
            <TextField
              placeholder="Obligatorio"
              id="empresa"
              name="empresa"
              type="text"
              size="small"
              variant="outlined"
              className={classes.textFieldCustom}
              value={CapitalizeString(formik.values.empresa)}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              inputProps={{
                maxLength: 50,
              }}
            />
            {formik.touched.empresa && formik.errors.empresa ? (
              <Typography style={{ color: "#FF1717", marginBottom: -20 }}>
                {formik.errors.empresa}
              </Typography>
            ) : null}
            <Typography
              className={classes.textFieldLetter}
              style={{ marginTop: 20 }}
            >
              RFC
            </Typography>
            <TextField
              placeholder="Obligatorio"
              id="rfc"
              name="rfc"
              type="text"
              size="small"
              variant="outlined"
              className={classes.textFieldCustom}
              value={formik.values.rfc.toUpperCase()}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              inputProps={{
                style: {
                  textTransform: formik.values.rfc != "" && "uppercase",
                },
              }}
            />
            {formik.touched.rfc && formik.errors.rfc ? (
              <Typography style={{ color: "#FF1717", marginBottom: -20 }}>
                {formik.errors.rfc}
              </Typography>
            ) : null}
            <Typography
              className={classes.textFieldLetter}
              style={{ marginTop: 20 }}
            >
              Registro patronal IMSS
            </Typography>
            <TextField
              placeholder="Obligatorio"
              id="numeroSeguro"
              name="numeroSeguro"
              type="text"
              size="small"
              variant="outlined"
              className={classes.textFieldCustom}
              value={formik.values.numeroSeguro.toUpperCase()}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              inputProps={{
                maxLength: 11,
                style: {
                  textTransform:
                    formik.values.numeroSeguro != "" && "uppercase",
                },
              }}
            />
            {formik.touched.numeroSeguro && formik.errors.numeroSeguro ? (
              <Typography style={{ color: "#FF1717", marginBottom: -20 }}>
                {formik.errors.numeroSeguro}
              </Typography>
            ) : null}
            <Typography
              className={classes.textFieldLetter}
              style={{ marginTop: 20 }}
            >
              Obras asignadas
            </Typography>

            <MultiSelect
              options={proyectos ? proyectos : []}
              value={formik.values.obras}
              onChange={handleQueryChange("obras")}
              className={classes.multi}
            />
            {formik.touched.obras && formik.errors.obras ? (
              <Typography style={{ color: "#FF1717" }}>
                {formik.errors.obras}
              </Typography>
            ) : null}

            <Typography
              className={classes.textFieldLetter}
              style={{ marginTop: 20 }}
            >
              Unidad
            </Typography>

            <TextField
              name="unidad"
              size="small"
              variant="outlined"
              value={formik.values.unidad}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={classes.textFieldCustom}
              // onChange={handleChange}
              onClick={() => props.setOpenDialogUnits(!props.openDialogUnits)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <FontAwesomeIcon
                      icon={faCaretDown}
                      className={classes.iconForm}
                      style={{
                        width: "24px",
                        height: "24px",
                        color: "#ADADAD",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
            {/* <FormControl className={classes.formControl}>
              <Select
                name="unidad"
                value={formik.values.unidad}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                defaultValue=""
                inputProps={{ "aria-label": "Without label" }}
                className={classes.select}
              >
                <MenuItem value=" " disabled>
                  &ensp;Obligatorio
                </MenuItem>
                <MenuItem value="HVAC">&ensp;HVAC</MenuItem>
                <MenuItem value="Mecánico">&ensp;Mecánico</MenuItem>
                <MenuItem value="Eléctrico">&ensp;Eléctrico</MenuItem>
              </Select>
            </FormControl> */}
            {formik.touched.unidad && formik.errors.unidad ? (
              <Typography style={{ color: "#FF1717", marginBottom: -20 }}>
                {formik.errors.unidad}
              </Typography>
            ) : null}
          </Grid>

          <Button
            onClick={inviteSubcontractor}
            disabled={disableButton}
            style={{
              width: "400px",
              height: "40px",
              borderRadius: "8px",
              color: "#ffffff",
              backgroundColor: "#ac0bce",
              textTransform: "none",
              marginTop: 30,
            }}
          >
            {!disableButton ? (
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 21,
                  color: "#ffffff",
                }}
              >
                Invitar
              </Typography>
            ) : (
              <CircularProgress style={{ color: "white" }} size={24} />
            )}
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
