/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { Grid, Typography, TextField, Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleLeft,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import InputAdornment from "@material-ui/core/InputAdornment";
import { ipServer } from "../../../config/vars";
import axios from "axios";
import useAssistanceSearch from "./useAssistanceSearch";
import MultiSelect from "../../../common_components/Filters/MutiSelect";
import DateRange from "../../../common_components/Filters/DateRange";
import DataGridAssitance from "./DataGridAssitance";
import { useDispatch, useSelector } from "react-redux";
import { FiltersAssistance } from "../../../actions/filters";
import axiosInstance from "../../../utils/axiosInstance";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { CSVLink } from "react-csv";
import DialogReportAssitance from "./DialogReportAssitance";
import DialogMapsView from "../../../common_components/Dialogs/DialogMapsView";
import { dateFilter } from "../../../utils/getRangeDateFilter";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiInputBase-input": {
      color: "676767 !important",
    },
  },
  divMain: {
    flexGrow: 1,
    paddingTop: 10,
    height: "100%",
  },
  divBack: {
    cursor: "pointer",
    marginBottom: "10px",
  },
  back: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 500,
    textAlign: "left",
    color: "#fff",
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 32,
    fontWeight: 500,
    textAlign: "left",
    color: "#ffffff",
  },
  adornedStart: {
    backgroundColor: "red",
    height: "5rem",
    maxHeight: "8rem",
  },
}));

export default function AssistanceReport(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const csvLink = useRef();
  const { user } = useSelector((state) => state.user);
  const { assistance } = useSelector((state) => state.filters);
  const [subcontratistas, setSubcontratistas] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  const [proyectos, setProyectos] = useState(null);
  const [unidades, setUnidades] = useState(null);
  const [events, setEvents] = useState(null);
  const [dataCSV, setDataCSV] = useState([]);
  const [selectionRows, setSelectionRows] = useState([]);
  const [openDialog, setOpendialog] = useState(false);
  const [openDialogReportAssitance, setOpenDialogReportAssitance] =
    useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: assistance ? assistance.intialDate : new Date(),
      endDate: assistance ? assistance.finalDate : new Date(),
      key: "selection",
    },
  ]);
  const [query, setQuery] = useState({
    Empleado: assistance?.Empleado ?? "",
    Proyectos: assistance?.Proyectos ?? [],
    Subcontratistas: assistance?.Subcontratistas ?? [],
    Unidades: assistance?.Unidades ?? [],
    Eventos: assistance?.Eventos ?? [],
    intialDate: assistance?.intialDate ?? new Date(),
    finalDate: assistance?.finalDate ?? new Date(),
    pageSize: 10,
    rowsPerPageOptions: [5, 10, 15],
    page: 1,
  });

  const { rows, totalRows, loading, error } = useAssistanceSearch(
    query,
    0,
    loadingData,
    subcontratistas,
    proyectos
  );

  const handleQueryChange = (queryElement) => (e) => {
    const value =
      queryElement === "Proyectos" ||
      queryElement === "Subcontratistas" ||
      queryElement === "Unidades" ||
      queryElement === "Eventos"
        ? e
        : e.target.value;
    return setQuery((prevQuery) => {
      return { ...prevQuery, ...{ [queryElement]: value } };
    });
  };

  const handleSelect = (ranges) => {
    setDateRange([ranges.selection]);
    updateData("intialDate", ranges.selection.startDate);
    updateData("finalDate", ranges.selection.endDate);
  };

  const updateData = (k, v) => {
    setQuery((prev) => ({ ...prev, [k]: v }));
  };

  const backStep = () => {
    history.push("/dashboard/reportes");
  };

  const getDateFilter = async () => {
    try {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      var params = new URLSearchParams();
      params.append("type_string", "Calendarios_default");
      params.append("name_string", "Reportes_eventos_calendario");

      return axiosInstance()
        .get(`/api/v1/rules/config`, {
          params,
          cancelToken: source.token,
        })
        .then((response) => {
          // console.log(response.data.data[0]);
          const rangeDate = dateFilter(response.data.data[0].rangoFechaDefault);

          setQuery((prev) => {
            return {
              ...prev,
              intialDate: rangeDate.initialDate,
              finalDate: rangeDate.finalDate,
            };
          });
          setDateRange((prev) => {
            return [
              {
                ...prev,
                startDate: rangeDate.initialDate,
                endDate: rangeDate.finalDate,
                key: "selection",
              },
            ];
          });
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const getSubcontractorList = async () => {
    try {
      var params = new URLSearchParams();
      params.append("showdeleted", true);
      params.append("showoculta", true);
      params.append("sortMongoAsc", "empresa");

      return await axiosInstance()
        .get(
          `${ipServer}/api/v1/rules/reporte-de-eventos/comboboxes/subcontratistas`,
          {
            params,
          }
        )
        .then((response) => {
          const empresa = response.data.data.map((object) => {
            return { label: object.empresa, value: object.empresa };
          });

          setSubcontratistas(empresa);
          return empresa;
        })
        .catch((e) => {
          console.log(e);
          return false;
        });
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const getProyectsList = async () => {
    try {
      var params = new URLSearchParams();
      params.append("sortMongoAsc", "proyecto");

      return await axiosInstance()
        .get("/api/v1/rules/reporte-de-eventos/comboboxes/obras", { params })
        .then((response) => {
          const proyects = response.data.data.map((object) => {
            return { label: object.proyecto, value: object.proyecto };
          });

          setProyectos(proyects);
          return proyects;
        })
        .catch((e) => {
          console.log(e);
          return false;
        });
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const getUnidadesList = async () => {
    try {
      var params = new URLSearchParams();
      params.append("sortMongoAsc", "name");

      return await axiosInstance()
        .get("/api/v1/rules/reporte-de-eventos/comboboxes/unidad", { params })
        .then((response) => {
          let unidades = response.data.data.map((object) => {
            return { label: object.name, value: object._id };
          });

          setUnidades(unidades);
          return unidades;
        })
        .catch((e) => {
          console.log(e);
          return false;
        });
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const getEventsList = async () => {
    try {
      var params = new URLSearchParams();
      params.append("sortMongoAsc", "name");

      return await axiosInstance()
        .get("/api/v1/rules/reporte-de-eventos/comboboxes/eventos", { params })
        .then((response) => {
          let events = response.data.data.map((object) => {
            return { label: object.name, value: object._id };
          });

          setEvents(events);
          return events;
        })
        .catch((e) => {
          console.log(e);
          return false;
        });
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  useEffect(() => {
    if (user && user?.EnterpriseData?.Db == "GrupoGarzaPonce903228") {
      setDateRange([
        {
          startDate: assistance ? assistance.intialDate : new Date(),
          endDate: assistance ? assistance.finalDate : new Date(),
          key: "selection",
        },
      ]);
    }
  }, [user]);

  useEffect(() => {
    dispatch(FiltersAssistance(query));
  }, [query]);

  useEffect(() => {
    if (selectionRows.length > 0) {
      setOpenDialogReportAssitance(!openDialogReportAssitance);
    }
  }, [selectionRows]);

  useEffect(() => {
    const fetchData = async () => {
      setLoadingData(false);

      await getSubcontractorList();
      await getProyectsList();
      await getUnidadesList();
      await getEventsList();
      await getDateFilter();

      // setQuery({
      //   ...query,
      //   Proyectos: _proyectos,
      //   Subcontratistas: _subcontratistas,
      // });

      setLoadingData(true);
    };

    fetchData();
  }, [user]);

  const getAllAssistance = async () => {
    var params = new URLSearchParams();

    let Subcontratistas =
      query.Subcontratistas.length > 0
        ? query.Subcontratistas
        : subcontratistas;
    let Proyectos = query.Proyectos.length > 0 ? query.Proyectos : proyectos;

    params.append("page", 0);
    params.append("limit", 0);
    params.append("trabajadores_nombre_partial", query.Empleado);
    params.append("lookup", "catalogos");

    params.append(
      "initialDate",
      new Date(query.intialDate.setHours(0, 0, 0, 0))
    );
    params.append(
      "finalDate",
      new Date(query.finalDate.setHours(23, 59, 59, 999))
    );

    Proyectos?.map((proyecto) => {
      params.append("proyectos_proyecto_string", proyecto.label);
    });

    Subcontratistas?.map((subcontratista) => {
      params.append("subcontratistas_empresa_string", subcontratista.label);
    });

    query?.Unidades?.map((unidad) => {
      params.append("subcontratistas_unidad_string", unidad.label);
    });

    query?.Eventos?.map((evento) => {
      params.append("catalogos_id", evento.value);
    });

    return await axiosInstance()
      .get("/api/v1/rules/asistencias/filter", {
        params,
      })
      .then((response) => {
        let result = response.data.data;

        result = result.map((data, index) => ({
          Empleado: data.trabajadores_nombre,
          Obra: data.proyectos_proyecto,
          Contratista: data.subcontratistas_empresa,
          Unidad: data.subcontratistas_unidad,
          "Fecha Y Hora": new Date(data.datetime),
          Evento: data.tipoEvento,
        }));

        return result;
      })
      .catch((e) => {
        console.log("error");
      });
  };

  const handleExportExcel = async () => {
    setDataCSV(await getAllAssistance());
    csvLink.current.link.click();
  };

  return (
    <div className={classes.divMain}>
      <Grid container direction="column">
        <Grid
          item
          xs={12}
          container
          direction="row"
          alignItems="center"
          onClick={backStep}
          className={classes.divBack}
        >
          <Grid item>
            <FontAwesomeIcon
              icon={faArrowAltCircleLeft}
              style={{
                width: "20px",
                height: "20px",
                marginRight: "10px",
                color: "#d6d6d6",
              }}
            />
          </Grid>
          <Grid item>
            <Typography className={classes.back}>Regresar</Typography>
          </Grid>
        </Grid>

        <Typography
          style={{
            marginBottom: 25,
            marginTop: 10,
            fontFamily: "Roboto",
            fontSize: 30,
            fontWeight: 500,
            textAlign: "left",
            color: "#fff",
          }}
        >
          Reporte de eventos
        </Typography>

        <Grid container direction="row">
          <Grid item>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 16,
                textAlign: "left",
                color: "#dedcdc",
              }}
            >
              Fecha
            </Typography>

            <DateRange value={dateRange} onChange={handleSelect} />
          </Grid>

          <Grid item xs={2} style={{ marginLeft: 30 }}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 16,
                textAlign: "left",
                color: "#dedcdc",
              }}
            >
              Obra
            </Typography>
            <div>
              <MultiSelect
                options={proyectos ? proyectos : []}
                value={query.Proyectos}
                onChange={handleQueryChange("Proyectos")}
              />
            </div>
          </Grid>

          <Grid item xs={2} style={{ marginLeft: 30 }}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 16,
                textAlign: "left",
                color: "#dedcdc",
              }}
            >
              Subcontratista
            </Typography>
            <div>
              <MultiSelect
                options={subcontratistas ? subcontratistas : []}
                value={query.Subcontratistas}
                onChange={handleQueryChange("Subcontratistas")}
              />
            </div>
          </Grid>

          <Grid item xs={2} style={{ marginLeft: 30 }}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 16,
                textAlign: "left",
                color: "#dedcdc",
              }}
            >
              Unidades
            </Typography>
            <div>
              <MultiSelect
                options={unidades ? unidades : []}
                value={query.Unidades}
                onChange={handleQueryChange("Unidades")}
              />
            </div>
          </Grid>

          <Grid item xs={2} style={{ marginLeft: 30 }}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 16,
                textAlign: "left",
                color: "#dedcdc",
              }}
            >
              Tipo de evento
            </Typography>
            <div>
              <MultiSelect
                options={events ? events : []}
                value={query.Eventos}
                onChange={handleQueryChange("Eventos")}
              />
            </div>
          </Grid>

          <Grid item xs={3}>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Buscar empleado por nombre"
              value={query?.Empleado}
              onChange={handleQueryChange("Empleado")}
              className={classes.root}
              style={{
                width: "100%",
                background: "white",
                borderRadius: "8px",
                marginTop: 25,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon
                      icon={faSearch}
                      style={{
                        width: "18px",
                        height: "18px",
                        color: "#676767",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid
            item
            xs={2}
            container
            direction="row"
            alignItems="flex-end"
            style={{ marginLeft: 10 }}
          >
            <Button
              onClick={handleExportExcel}
              variant="contained"
              style={{
                minWidth: 137,
                height: 40,
                borderRadius: 8,
                backgroundColor: "#d233ff",
                textTransform: "none",
              }}
            >
              <FileDownloadIcon style={{ color: "white" }} />
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 16,
                  textAlign: "left",
                  color: "#fff",
                  marginLeft: 4,
                }}
              >
                Exportar
              </Typography>
            </Button>

            <CSVLink
              data={dataCSV}
              filename="data.csv"
              className="hidden"
              ref={csvLink}
              target="_blank"
            />
          </Grid>
        </Grid>

        <Grid container>
          <DataGridAssitance
            openDialogReportAssitance={openDialogReportAssitance}
            setOpenDialogReportAssitance={setOpenDialogReportAssitance}
            rows={rows ? rows : []}
            loading={loading}
            rowCount={totalRows}
            rowsPerPageOptions={query.rowsPerPageOptions}
            page={query.page}
            pageSize={query.pageSize}
            selectionModel={selectionRows}
            onSelectionModelChange={(ids) => {
              setSelectionRows(ids);
            }}
            onPageChange={(data) => {
              updateData("page", data + 1);
            }}
            onPageSizeChange={(data) => {
              updateData("page", 1);
              updateData("pageSize", data);
            }}
            style={{ height: 680, width: "100%", display: "flex" }}
          />
        </Grid>
      </Grid>

      <DialogReportAssitance
        openDialog={openDialog}
        setOpendialog={setOpendialog}
        openDialogReportAssitance={openDialogReportAssitance}
        setOpenDialogReportAssitance={setOpenDialogReportAssitance}
        rows={rows ? rows : []}
        selectionRows={selectionRows}
        setSelectionRows={setSelectionRows}
      />

      <DialogMapsView
        setOpendialog={setOpendialog}
        openDialog={openDialog}
        rows={rows ? rows : []}
        selectionRows={selectionRows}
        setSelectionRows={setSelectionRows}
        // obrasData={props.obrasData}
        // setObrasData={props.setObrasData}
      />
    </div>
  );
}
