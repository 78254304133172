/*eslint-disable*/
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Popover } from "@material-ui/core";
import { Calendar } from "react-date-range";
import { es } from "date-fns/locale";
import InputAdornment from "@material-ui/core/InputAdornment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiInputBase-input": {
      color: "#dfdfdf !important",
      marginLeft: 5,
      // textAlign: "center !important",
    },
  },
  textFieldCustom: {
    width: "90%",
    color: "#dfdfdf",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
  },
}));

export default function DateComponent(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState("Seleccionar");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleSelect = (date) => {
    // console.log(date);
    props.setDate(date);

    const monthNames = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];

    var day = date.getDate();
    var month = date.getMonth();
    var year = date.getFullYear();
    const dateFormat = day + " de " + monthNames[month] + " " + year;
    // console.log(dateFormat);
    setValue(dateFormat);
    // props.setChangesFlag(true);
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <TextField
        name={props.name}
        size="small"
        variant="outlined"
        value={value}
        className={classes.textFieldCustom}
        // onChange={handleChange}
        onClick={handleClick}
        style={{
          width: props.width ? props.width : "324px",
          borderRadius: "8px",
          backgroundColor: "#27292d",
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment
              position="start"
              component="div"
              style={{ paddingLeft: "-14px" }}
            >
              <div
                style={{
                  background: "#68656a",
                  borderBottomLeftRadius: 6,
                  borderTopLeftRadius: 6,
                  marginLeft: "-14px",
                  padding: "10px 13px",
                  width: "43px",
                }}
              >
                <FontAwesomeIcon
                  icon={faCalendarAlt}
                  style={{
                    width: "17px",
                    height: "17px",
                    color: "#dfdfdf",
                  }}
                ></FontAwesomeIcon>
              </div>
            </InputAdornment>
          ),
        }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Calendar
          color="#D233FF"
          locale={es}
          date={props.date}
          onChange={handleSelect}
          maxDate={
            props.name == "fechaNacimiento"
              ? new Date(new Date().getFullYear() - 18, 12, 0)
              : new Date(new Date().getFullYear() - 0, 12, 0)
          }
          minDate={
            props.name == "fechaNacimiento"
              ? new Date(new Date().getFullYear() - 100, 12, 0)
              : new Date(new Date().getFullYear() - 80, 12, 0)
          }
        />
      </Popover>
    </div>
  );
}
