/*eslint-disable*/

export const CapitalizeString = (str) => {
  var nextCapital = true;
  var newString = "";
  for (var i = 0; i < str.length; i++) {
    var cur = str.charAt(i);
    // console.log(cur);
    if (nextCapital) {
      newString += cur.toUpperCase();
    } else {
      newString += cur.toLowerCase();
    }
    if (cur === " ") {
      nextCapital = true;
    } else {
      nextCapital = false;
    }
  }
  // console.log(newString);
  return newString;

  // return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};
