/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import PropTypes from "prop-types";
import LinearProgress from "@material-ui/core/LinearProgress";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faList,
  faBriefcaseMedical,
  faExclamationCircle,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles((theme) => ({
  divMain: {
    maxWidth: 345,
  },
  card: {
    maxWidth: "320px",
    // height: "419px",
    borderRadius: "24px",
    cursor: "pointer",
    backgroundColor: "#2d3034",
    "&:hover": {
      background: "#3B3D40",
      boxShadow: "0 0px 16px 0 rgba(90, 90, 90, 0.2)",
    },
  },
  griCard: {
    width: "100%",
    height: "100%",
  },
  photo: {
    width: "80px",
    height: "80px",
    marginTop: "28px",
    borderRadius: "8px",
    aspectRatio: 1,
    objectFit: "cover",
  },
  root: {
    width: "60%",
    borderRadius: "20px",
  },
  boxProgress: {
    "& .MuiLinearProgress-colorPrimary": {
      backgroundColor: "#6d6d6d",
    },
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#d233ff",
    },
  },
}));

function LinearProgressWithLabel(props) {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1} className={classes.boxProgress}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export default function CardEmpleado(props) {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useSelector((state) => state.user);
  const [progress, setProgress] = useState(0);
  const [openBackdropBasico, setOpenBackdropBasico] = useState(false);
  // const [imageUploaded, setImageUploaded] = useState("");
  // const [selectedFile, setSelectedFile] = useState({
  //   preview: "",
  //   raw: "",
  // });

  const detailEmployee = () => {
    localStorage.setItem("idEmployee", props.idUser);
    localStorage.setItem("statusDemek", props.statusDemek);
    history.push(props.url);
  };

  // const handleUploadClick = (event) => {
  //   console.log();
  //   var file = event.target.files[0];
  //   setImageUploaded(1);
  //   setSelectedFile({
  //     preview: URL.createObjectURL(file),
  //     raw: file,
  //   });
  //   console.log("file preview: " + selectedFile.preview);
  //   console.log("file raw: " + selectedFile.raw);
  // };

  useEffect(() => {
    setProgress(props.percentage.total);
  }, []);

  return (
    <div>
      <Card
        onClick={detailEmployee}
        className={classes.card}
        style={{
          // height: "419px",
          height: user?.EnterpriseData?.ValidateImss ? "440px" : "370px",
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          className={classes.griCard}
        >
          <img src={props.urlPhoto} className={classes.photo} alt="photo" />
          <Grid
            item
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: "5%" }}
          >
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 16,
                fontWeight: 500,
                textAlign: "center",
                color: "#fff",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                maxWidth: "80%",
                lineHeight: 1.2,
              }}
            >
              {`${props.name} ${props.lastName}`}
            </Typography>
            {props.numeroEmpleado && (
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 14,
                  textAlign: "center",
                  color: "#acacac",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  maxWidth: "80%",
                  lineHeight: 1.2,
                }}
              >
                {`Num Emp: ${props.numeroEmpleado}`}
              </Typography>
            )}

            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 16,
                textAlign: "center",
                color: "#acacac",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                maxWidth: "80%",
                lineHeight: 1.2,
              }}
            >
              {props.role ? props.role : "Sin puesto asignado"}
            </Typography>
          </Grid>

          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: "5%" }}
          >
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 16,
                textAlign: "center",
                color: "#fff",
                fontWeight: 500,
                maxWidth: "80%",
                lineHeight: 1.2,
              }}
            >
              {props.statusDemek}
            </Typography>

            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 10,
                textAlign: "center",
                color: "#acacac",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                maxWidth: "80%",
                lineHeight: 1.2,
              }}
            >
              Estatus
            </Typography>
          </Grid>

          {props.incidents ? (
            <Grid
              container
              direction="row"
              justifyContent="center"
              style={{
                marginTop: 5,
              }}
            >
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 12,
                  fontWeight: 500,
                  textAlign: "left",
                  color: "#e52222",
                }}
              >
                {props.incidents + " reportes de conducta"}
              </Typography>
            </Grid>
          ) : null}

          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: "5%" }}
          >
            {props.subcontractor == "" || props.subcontractor == null ? (
              <div
                style={{
                  height: "12px",
                  width: "60%",
                  backgroundColor: "#525151",
                  marginTop: "16px",
                }}
              />
            ) : (
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 16,
                  fontWeight: 500,
                  textAlign: "center",
                  color: "#fff",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  maxWidth: "70%",
                  lineHeight: 1.2,
                }}
              >
                {props.subcontractor}
              </Typography>
            )}

            {props.proyect == "" || props.proyect == null ? (
              <div
                style={{
                  height: "12px",
                  width: "70%",
                  backgroundColor: "#525151",
                  marginTop: "25px",
                }}
              />
            ) : (
              <Grid
                container
                direction="row"
                justifyContent="center"
                style={{ marginTop: 10 }}
              >
                <FontAwesomeIcon
                  icon={faBuilding}
                  style={{
                    width: "14px",
                    height: "18px",
                    color: "#d233ff",
                  }}
                />

                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 14,
                    textAlign: "center",
                    color: "#fff",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    maxWidth: "70%",
                    marginLeft: 10,
                    lineHeight: 1.2,
                  }}
                >
                  {props.proyect}
                </Typography>
              </Grid>
            )}
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: 30 }}
          >
            <FontAwesomeIcon
              icon={faList}
              style={{
                width: "14px",
                height: "18px",
                color: "#6d6d6d",
              }}
            />
            <Grid
              style={{
                width: "10px",
              }}
            />
            <div className={classes.root}>
              <LinearProgressWithLabel value={progress ? progress : 0} />
            </div>
          </Grid>

          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 12,
              textAlign: "center",
              color: "#acacac",
              marginBottom: "15px",
              lineHeight: 1.2,
            }}
          >
            {progress ? progress : 0}% completado
          </Typography>

          {user?.EnterpriseData?.ValidateImss ? (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              style={{ marginTop: 15 }}
            >
              {props.statusImss == "IMSS Vigente" ? (
                <FontAwesomeIcon
                  icon={faBriefcaseMedical}
                  style={{
                    width: "17px",
                    height: "16px",
                    color: "#d233ff",
                    marginRight: 5,
                  }}
                />
              ) : props.statusImss == "IMSS No Vigente" ? (
                <FontAwesomeIcon
                  icon={faExclamationCircle}
                  style={{
                    width: "16px",
                    height: "16px",
                    color: "#d60101",
                    marginRight: 5,
                  }}
                />
              ) : props.statusImss == "Información Incorrecta" ? (
                <FontAwesomeIcon
                  icon={faExclamationCircle}
                  style={{
                    width: "20px",
                    height: "18px",
                    color: "#fff",
                    marginRight: 5,
                  }}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  style={{
                    width: "20px",
                    height: "18px",
                    color: "#e1bd2f",
                    marginRight: 5,
                  }}
                />
              )}

              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 16,
                  textAlign: "left",
                  // lineHeight: 1.31,
                  color: "#fff",
                }}
              >
                {props.statusImss}
              </Typography>
            </Grid>
          ) : null}
        </Grid>
      </Card>

      <Dialog
        open={openBackdropBasico}
        maxWidth="md"
        aria-labelledby="form-dialog-title"
      >
        <Backdrop open={openBackdropBasico}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>
    </div>
  );
}
