/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import DateRange from "../../../common_components/Filters/DateRange";
import DataGridAttendance from "./DataGridAttendance";
import useAttendanceSearch from "./useAttendanceSearch";
import useStadisticsSearch from "./useStadisticsSearch";
import EmployeeStadisticsCard from "./EmployeeStadisticsCard";
import DialogReportAssitance from "./DialogReportAssitance";
import DialogMapsView from "../../../common_components/Dialogs/DialogMapsView";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    paddingTop: 10,
    height: "100%",
  },
}));

export default function AttendanceScreen(props) {
  const classes = useStyles();
  const [selectionRows, setSelectionRows] = useState([]);
  const [openDialog, setOpendialog] = useState(false);
  const [openDialogReportAssitance, setOpenDialogReportAssitance] =
    useState(false);

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(Date.now() - 604800000),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [query, setQuery] = useState({
    pageSize: 10,
    rowsPerPageOptions: [5, 10, 15],
    page: 1,
    intialDate: new Date(Date.now() - 604800000),
    finalDate: new Date(),
  });

  const { rows, totalRows, loading, error } = useAttendanceSearch(query, 0);

  const { hours, error2 } = useStadisticsSearch(query);

  const handleSelect = (ranges) => {
    setDateRange([ranges.selection]);
    updateData("intialDate", ranges.selection.startDate);
    updateData("finalDate", ranges.selection.endDate);
  };

  const updateData = (k, v) => {
    setQuery((prev) => ({ ...prev, [k]: v }));
  };

  useEffect(() => {
    if (selectionRows.length > 0) {
      setOpenDialogReportAssitance(!openDialogReportAssitance);
    }
  }, [selectionRows]);

  return (
    <Grid container>
      <Grid item xs={12} container direction="row">
        <Grid item>
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 16,
              textAlign: "left",
              color: "#dedcdc",
            }}
          >
            Fecha
          </Typography>

          <DateRange value={dateRange} onChange={handleSelect} />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
          <DataGridAttendance
            openDialogReportAssitance={openDialogReportAssitance}
            setOpenDialogReportAssitance={setOpenDialogReportAssitance}
            rows={rows ? rows : []}
            loading={loading}
            rowCount={totalRows}
            rowsPerPageOptions={query.rowsPerPageOptions}
            page={query.page}
            pageSize={query.pageSize}
            selectionModel={selectionRows}
            onSelectionModelChange={(ids) => {
              setSelectionRows(ids);
            }}
            onPageChange={(data) => {
              updateData("page", data + 1);
            }}
            onPageSizeChange={(data) => {
              updateData("page", 1);
              updateData("pageSize", data);
            }}
            style={{ height: 680, width: "100%", display: "flex" }}
          />
        </Grid>

        <Grid item xs={8} sm={7} md={6} lg={3} xl={3}>
          <EmployeeStadisticsCard hours={hours} />
        </Grid>
      </Grid>

      <DialogReportAssitance
        openDialog={openDialog}
        setOpendialog={setOpendialog}
        openDialogReportAssitance={openDialogReportAssitance}
        setOpenDialogReportAssitance={setOpenDialogReportAssitance}
        rows={rows ? rows : []}
        selectionRows={selectionRows}
        setSelectionRows={setSelectionRows}
      />

      <DialogMapsView
        setOpendialog={setOpendialog}
        openDialog={openDialog}
        rows={rows ? rows : []}
        selectionRows={selectionRows}
        setSelectionRows={setSelectionRows}
        // obrasData={props.obrasData}
        // setObrasData={props.setObrasData}
      />
    </Grid>
  );
}
