/*eslint-disable*/
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  divMain: {
    // flexGrow: 1,
    // height: "100%",
    // backgroundColor: "red",
  },
  gridBehavior: {
    padding: theme.spacing(2, 0, 0, 3),
    height: 110,
    borderRadius: 16,
    backgroundColor: "#2d3034",
  },
}));

export default function BehaviorProfileCard(props) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.divMain}>
      <Grid item xs={12} className={classes.gridBehavior}>
        <Grid container direction="row">
          <Grid item xs={11}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 22,
                fontWeight: 500,
                color: "#fff",
              }}
            >
              Perfil de conducta
            </Typography>
          </Grid>
          <Grid
            item
            xs={1}
            container
            direction="row"
            justifyContent="flex-start"
            style={{ marginLeft: -9 }}
          >
            <Typography
              onClick={() =>
                history.push(
                  `/dashboard/empleados/conducta/${localStorage.getItem(
                    "idEmployee"
                  )}`
                )
              }
              style={{
                fontFamily: "Roboto",
                fontSize: 18,
                fontWeight: 500,
                color: "#d233ff",
                cursor: "pointer",
              }}
            >
              Ver
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          alignItems="center"
          alignContent="center"
          style={{ marginTop: 5 }}
        >
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 24,
              color: "#fff",
              fontWeight: 500,
            }}
          >
            {props.reports}
          </Typography>
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 18,
              color: "#c1c1c1",
              marginLeft: 8,
            }}
          >
            {props.reports == 1 ? "reporte" : "reportes"}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
