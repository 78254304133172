/*eslint-disable*/
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import useStyles from "../styles/form_register_styles";
import { useSnackbar } from "notistack";
import Lottie from "react-lottie";
import animationVerification from "../../../animations/verification_animationV2.json";
import {
  TextField,
  Button,
  Typography,
  Grid,
  InputAdornment,
} from "@material-ui/core";
import { useHistory, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey } from "@fortawesome/free-solid-svg-icons";
import { ipServer } from "../../../config/vars";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import Logo from "../../../images/ABSA-INDUSTRIAL-LOGO-BLANCO.png";

const useStyles = makeStyles((theme) => ({
  logo: {
    width: 530,
    height: 130,
    marginBottom: 40,
  },
  textFieldLabel: {
    fontFamily: "Roboto",
    fontSize: 20,
    color: "#fff",
    lineHeight: 1.35,
    marginTop: "7%",
    color: theme.palette.neutral.primary,
  },
  textFieldCustom: {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& label.Mui-focused": {
      color: "#6C6C6C",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "yellow",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#E6E6E6",
      },
      "&:hover fieldset": {
        borderColor: "#6C6C6C",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#50B9FF",
      },
    },
    borderRadius: 8,
    backgroundColor: "#ffffff",
    boxShadow: "0 0 16px 0 rgba(6, 5, 5, 0.22)",
  },
  typoLink: {
    fontFamily: "Roboto",
    fontSize: 18,
    textAlign: "center",
    textDecoration: "none",
    color: theme.palette.neutral.primary,
  },
  typoLabel: {
    fontFamily: "Roboto",
    fontSize: 18,
    textAlign: "justify",
    textDecoration: "none",
    color: theme.palette.secondary.dark,
    marginTop: "8%",
  },
  typoEmail: {
    fontFamily: "Roboto",
    fontSize: 18,
    textAlign: "justify",
    textDecoration: "none",
    color: theme.palette.neutral.primary,
    marginTop: "8%",
  },
  button: {
    width: "100%",
    height: 56,
    marginTop: "14%",
    borderRadius: 8,
    backgroundColor: theme.palette.primary.main,
    textTransform: "none",
  },
  typoButton: {
    fontFamily: "Roboto",
    fontSize: 20,
    fontWeight: 300,
    lineHeight: 1.35,
    color: theme.palette.neutral.primary,
  },
}));
export default function ValidateAccount(props) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const schema = Yup.object({
    code: Yup.string()
      .required("Favor de ingresar código de verificación")
      .min(6, "El código de verificación debe tener 6 digitos"),
  });

  const formik = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: schema,
  });

  const validateData = async () => {
    return await schema.isValid(formik.values);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationVerification,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const onSubmit = async () => {
    const { code } = formik.values;

    if (!(await validateData())) {
      formik.errors.code &&
        enqueueSnackbar(formik.errors.code, {
          variant: "error",
        });
      return;
    }

    axios
      .post(`${ipServer}/api/v1/security/user/activate/`, {
        ActivationCode: code,
        Email: props.email,
      })
      .then((response) => {
        // console.log(response.data);
        axios
          .post(`${ipServer}/api/v1/security/user/login/`, {
            Email: props.email,
            Password: props.password,
          })
          .then((response) => {
            // console.log(response.data);
            var exp = new Date();
            exp.setSeconds(exp.getSeconds() + response.data.data.expiresIn);
            localStorage.setItem("JWT", response.data.data.token);
            localStorage.setItem("token-exp", exp.getTime());
            history.push("/bienvenido");
            enqueueSnackbar("Usuario verificado correctamente", {
              variant: "success",
            });
          })
          .catch((e) => {
            if (e.response.data) {
              console.log(e.response.data);
            } else {
              console.log(e.response);
            }
          });
      })
      .catch((e) => {
        if (e.response.data) {
          console.log(e.response.data);
          if (e.response.data.errors == "Activation code not valid") {
            enqueueSnackbar("Codigo de Activación no valido", {
              variant: "error",
            });
          } else {
            enqueueSnackbar("Error al activar usuario", {
              variant: "error",
            });
          }
        } else {
          console.log(e.response);
          enqueueSnackbar("Error al activar usuario", {
            variant: "error",
          });
        }
      });
  };

  const ReSendCode = () => {
    axios
      .post(`${ipServer}/api/v1/security/user/resendcode/`, {
        Email: props.email,
      })
      .then((response) => {
        // console.log(response.data);
        if (response.status == 200 || response.data.status == "ok") {
          enqueueSnackbar("Código de verificación reenviado", {
            variant: "success",
          });
        } else {
          console.log(response.data.message);
          enqueueSnackbar("Error al enviar el código de verificación", {
            variant: "error",
          });
        }
      })
      .catch((e) => {
        console.log(e.response);
        enqueueSnackbar("Error al enviar el código de verificación", {
          variant: "error",
        });
      });
  };

  useEffect(() => {
    // se activa formik sin darle clic al textfield
    formik.setFieldValue("code", "");
  }, {});

  return (
    <Grid item xs={10} style={{ width: "503px", height: "100" }}>
      <img src={Logo} className={classes.logo} alt="techton logo" />

      <Lottie options={defaultOptions} height={163} width={191} />
      <Typography className={classes.typoLabel}>
        Hola, se ha enviado un código de verificación al correo:{" "}
        <b className={classes.typoEmail}>{props.email}</b>, favor de ingresar el
        código para verificar tu cuenta.
      </Typography>

      <Grid item xs={12}>
        <Typography className={classes.textFieldLabel}>
          Código de verificación
        </Typography>

        <TextField
          id="code"
          type="number"
          variant="outlined"
          fullWidth
          name="code"
          className={classes.textFieldCustom}
          value={formik.values.code}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          onInput={(e) => {
            e.target.value = Math.max(0, parseInt(e.target.value))
              .toString()
              .slice(0, 6);
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              onSubmit();
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon
                  icon={faKey}
                  className={classes.iconForm}
                  style={{
                    width: 24,
                    height: 24,
                    color: "#9b9b9b",
                  }}
                />
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Typography
        style={{
          marginTop: "2%",
        }}
      >
        <Link to="#" onClick={ReSendCode} className={classes.typoLink}>
          Reenviar código de verificación
        </Link>
      </Typography>

      <Button variant="contained" onClick={onSubmit} className={classes.button}>
        <Typography className={classes.typoButton}>Verificar cuenta</Typography>
      </Button>
    </Grid>
  );
}
