/*eslint-disable*/
import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  Popover,
  Button,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import { DateRangePicker } from "react-date-range";
import { es } from "date-fns/locale";
import { defaultStaticRanges } from "react-date-range/dist/defaultRanges";
import { saveAs } from "file-saver";
import axios from "axios";
import axiosInstance from "../../../../utils/axiosInstance";
import { useSelector } from "react-redux";
import MultiSelect from "../../../../common_components/Filters/MutiSelect";
import { dateFilter } from "../../../../utils/getRangeDateFilter";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiInputBase-input": {
      color: "#dfdfdf !important",
      textAlign: "center !important",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  root2: {
    border: 0,
    "& .MuiInputBase-root": {
      color: "white",
    },
    "& .MuiSelect-icon": {
      color: "white",
    },
  },
  multi: {
    color: "white",
    padding: 2,
    border: 0,
    borderRadius: "8px",
  },
  formControl: {
    width: "90%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  select: {
    width: "100%",
    height: "40px",
    borderRadius: "6px",
    backgroundColor: "#27292d",
    // "&:before": {
    //   borderColor: "ac0bce",
    // },
    // "&:after": {
    //   borderColor: "blue",
    // },
  },
  icon: {
    fill: "white",
  },
  button: {
    width: "189px",
    height: "40px",
    borderRadius: "8px",
    backgroundColor: "#ac0bce",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#4f5257",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: 20,
    },
  },
  gridMargin: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
}));

export default function FiltersComponent(props) {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const { user } = useSelector((state) => state.user);
  const { workingTime } = useSelector((state) => state.filters);

  function getMonday(last) {
    const date = !last
      ? new Date()
      : new Date(new Date().setDate(new Date().getDate() - 7));
    var day = date.getDay(),
      diff = date.getDate() - day + (day == 0 ? -6 : 1);
    return new Date(date.setDate(diff));
  }

  function getSunday(last) {
    const date = !last
      ? new Date()
      : new Date(new Date().setDate(new Date().getDate() - 7));
    const today = date.getDate();
    const dayOfTheWeek = date.getDay();
    const newDate = date.setDate(today - dayOfTheWeek + 7);
    return new Date(newDate);
  }

  const [dateRange, setDateRange] = useState([
    {
      startDate: workingTime ? workingTime.initialDate : getMonday(),
      endDate: workingTime ? workingTime.finalDate : getSunday(),
      key: "selection",
    },
  ]);

  const setDefaultWeeks = () => {
    defaultStaticRanges.map((staticRange) => {
      if (staticRange.label == "This Week") {
        staticRange.range = () => ({
          startDate: getMonday(false),
          endDate: getSunday(false),
        });
      }
      if (staticRange.label == "Last Week") {
        staticRange.range = () => ({
          startDate: getMonday(true),
          endDate: getSunday(true),
        });
      }
    });
  };

  const updateData = (k, v) => {
    props.setQuery((prev) => ({ ...prev, [k]: v }));
  };

  const handleSelect = (ranges) => {
    setDateRange([ranges.selection]);
    updateData("initialDate", ranges.selection.startDate);
    updateData("finalDate", ranges.selection.endDate);
    if (ranges.selection.startDate != ranges.selection.endDate)
      setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const staticRangesLabels = {
    Today: "Hoy",
    Yesterday: "Ayer",
    "This Week": "Esta semana",
    "Last Week": "Anterior semana",
    "This Month": "Este mes",
    "Last Month": "Último mes",
  };

  function translateRange(dictionary) {
    return (item) =>
      dictionary[item.label]
        ? { ...item, label: dictionary[item.label] }
        : item;
  }

  const esStaticRanges = defaultStaticRanges.map(
    translateRange(staticRangesLabels)
  );

  const MONTHS = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  const formatDateToStringMMM = (date) => {
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    var formatted_date;
    formatted_date = day + "/";
    formatted_date += MONTHS[month]?.substr(0, 3) + "/";
    return formatted_date + year;
  };

  const formatDateDisplay = (ranges) => {
    // console.log(ranges);
    var date1 = ranges?.startDate;
    var date2 = ranges?.endDate;
    if (!date1 || !date2) return "";
    return `${formatDateToStringMMM(date1)} - ${formatDateToStringMMM(date2)}`;
  };

  const exportReport = () => {
    if (
      !Object.values(props.query).some((x) => x == "") &&
      !Object.values(props.query).some((x) => x == null)
    ) {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      var params = new URLSearchParams();
      params.append(
        "initialDate",
        new Date(props.query.initialDate.setHours(0, 0, 0, 0))
      );
      params.append(
        "finalDate",
        new Date(props.query.finalDate.setHours(23, 59, 59, 999))
      );

      // console.log(props.query);

      props.query.proyectos?.map((proyecto) => {
        params.append("proyecto_string", proyecto.label);
      });

      props.query.unidades?.map((unidad) => {
        params.append("unidad_string", unidad.label);
      });

      props.query.subcontratistas?.map((subcontratista) => {
        params.append("subcontratista_string", subcontratista.label);
      });

      axiosInstance()
        .get("/api/v1/rules/workingtime/excelV2", {
          params,
          cancelToken: source.token,
        })
        .then((res) => {
          console.log(res.data);
          saveAs(res.data.data.url, res.data.data.fileName);
        })
        .catch((e) => {
          console.log(e.response);
          enqueueSnackbar("Error al generar reporte", {
            variant: "error",
          });
          if (axios.isCancel(e)) return;
        });
    } else {
      enqueueSnackbar("Favor de llenar los filtros", {
        variant: "error",
      });
    }
  };

  const getDateFilter = async () => {
    try {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      var params = new URLSearchParams();
      params.append("type_string", "Calendarios_default");
      params.append("name_string", "Reportes_workingTime_calendario");

      return axiosInstance()
        .get(`/api/v1/rules/config`, {
          params,
          cancelToken: source.token,
        })
        .then((response) => {
          // console.log(response.data.data[0]);
          const rangeDate = dateFilter(response.data.data[0].rangoFechaDefault);

          props.setQuery((prev) => {
            return {
              ...prev,
              initialDate: rangeDate.initialDate,
              finalDate: rangeDate.finalDate,
            };
          });

          // props.setQuery({
          //   ...props.query,
          //   initialDate: rangeDate.initialDate,
          //   finalDate: rangeDate.finalDate,
          // });

          setDateRange((prev) => {
            return [
              {
                ...prev,
                startDate: rangeDate.initialDate,
                endDate: rangeDate.finalDate,
                key: "selection",
              },
            ];
          });
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  useEffect(() => {
    if (user && user?.EnterpriseData?.Db == "GrupoGarzaPonce903228") {
      setDateRange([
        {
          startDate: workingTime ? workingTime.initialDate : new Date(),
          endDate: workingTime ? workingTime.finalDate : new Date(),
          key: "selection",
        },
      ]);
    }
  }, [user]);

  useEffect(() => {
    // setDefaultWeeks();
    getDateFilter();
    !workingTime &&
      props.setQuery((query) => {
        return {
          ...query,
          initialDate: dateRange[0].startDate,
          finalDate: dateRange[0].endDate,
        };
      });
  }, []);

  return (
    <Grid container direction="row" justifyContent="flex-start" spacing={4}>
      <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
        <Typography
          style={{
            fontFamily: "Roboto",
            fontSize: 16,
            textAlign: "left",
            color: "#dedcdc",
          }}
        >
          Fecha
        </Typography>

        <Grid container direction="row">
          <TextField
            // name="fechaNacimiento"
            size="small"
            variant="outlined"
            // value={"17/01/2022 - 23/01/2022"}
            value={formatDateDisplay(dateRange[0])}
            readOnly
            className={classes.root}
            onClick={handleClick}
            style={{
              borderRadius: "6px",
              backgroundColor: "#27292d",
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  component="div"
                  style={{ paddingLeft: "-14px" }}
                >
                  <div
                    style={{
                      background: "#2d3034",
                      borderBottomLeftRadius: 6,
                      borderTopLeftRadius: 6,
                      marginLeft: "-14px",
                      padding: "10px 13px",
                      width: "43px",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faCalendarAlt}
                      style={{
                        width: "17px",
                        height: "17px",
                        color: "#dfdfdf",
                      }}
                    ></FontAwesomeIcon>
                  </div>
                </InputAdornment>
              ),
            }}
          />
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <DateRangePicker
              ranges={dateRange}
              locale={es}
              staticRanges={esStaticRanges}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={2}
              direction="horizontal"
              onChange={handleSelect}
              inputRanges={[]}
            />
          </Popover>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        md={2}
        lg={2}
        xl={2}
        className={classes.gridMargin}
      >
        <Typography
          style={{
            fontFamily: "Roboto",
            fontSize: 16,
            textAlign: "left",
            color: "#dedcdc",
          }}
        >
          Obra
        </Typography>
        <MultiSelect
          disabled={props.loading}
          options={props.proyectos ? props.proyectos : []}
          value={props.query.proyectos ? props.query.proyectos : []}
          onChange={props.handleQueryChange("proyectos")}
        />
      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        md={2}
        lg={2}
        xl={2}
        className={classes.gridMargin}
      >
        <Typography
          style={{
            fontFamily: "Roboto",
            fontSize: 16,
            textAlign: "left",
            color: "#dedcdc",
          }}
        >
          Unidad
        </Typography>
        <MultiSelect
          disabled={props.loading}
          options={props.unidades ? props.unidades : []}
          value={props.query.unidades ? props.query.unidades : []}
          onChange={props.handleQueryChange("unidades")}
        />
      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        md={2}
        lg={2}
        xl={2}
        className={classes.gridMargin}
      >
        <Typography
          style={{
            fontFamily: "Roboto",
            fontSize: 16,
            textAlign: "left",
            color: "#dedcdc",
          }}
        >
          Subcontratista
        </Typography>
        <MultiSelect
          disabled={props.loading}
          options={props.subcontratistas ? props.subcontratistas : []}
          value={props.query.subcontratistas ? props.query.subcontratistas : []}
          onChange={props.handleQueryChange("subcontratistas")}
        />
      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        md={2}
        lg={2}
        xl={2}
        className={classes.gridMargin}
        container
        direction="row"
        alignItems="flex-end"
      >
        <Button onClick={exportReport} className={classes.button}>
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 18,
              textAlign: "left",
              color: "#fff",
            }}
          >
            Exportar reporte
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
}
