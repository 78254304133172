/*eslint-disable*/
import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import {
  faTimes,
  faCog,
  faPen,
  faTrashAlt,
  faPlusCircle,
  faArrowAltCircleLeft,
} from "@fortawesome/free-solid-svg-icons";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ScrollBars from "../ScrollBars";
import useCatalogSearch from "../useCatalogSearch";
import { useSelector } from "react-redux";
import { CapitalizeString } from "../../utils/CapitalizeString";
import axiosInstance from "../../utils/axiosInstance";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Roboto",
    fontSize: 22,
    fontWeight: "bold",
    textAlign: "center",
    color: "#fff",
  },
  divDivider: {
    width: "100%",
    height: 1,
    background: "#707070",
    marginTop: 8,
    padding: 0,
  },
  gridItem: {
    paddingTop: "5%",
    paddingLeft: "5%",
  },
  gridItem2: {
    paddingRight: "5%",
  },
  typoItem: {
    fontFamily: "Roboto",
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "left",
    color: "#fff",
  },
  iconEdit: {
    width: "15px",
    height: "15px",
    color: "#fff",
    cursor: "pointer",
  },
  iconBack: {
    width: "20px",
    height: "20px",
    marginRight: "10px",
    color: "#d6d6d6",
  },
  iconClose: {
    width: "20px",
    height: "20px",
    color: "#fff",
    cursor: "pointer",
  },
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: "bold",
    textAlign: "left",
    color: "#cdcece",
  },
  textFieldCustom: {
    width: "90%",
    color: "#676767",
    border: "solid 1px #c5c5c5",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
  },
  textFieldCustom2: {
    width: "100%",
    color: "#676767",
    border: "solid 1px #c5c5c5",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
  },
  button: {
    width: "80%",
    height: "48px",
    borderRadius: "8px",
    backgroundColor: "#ac0bce",
  },
  typoButton: {
    fontFamily: "Roboto",
    fontSize: 21,
    textAlign: "left",
    color: "#fff",
    textTransform: "none",
  },
}));

export default function DialogCatalogList(props) {
  const {
    formik,
    catalogItem,
    setCatalogItem,
    data,
    setData,
    openDialog,
    setOpenDialog,
    itemFlag,
    setItemFlag,
    setChangesFlag,
  } = props;
  const classes = useStyles();
  const [dialog, setDialog] = useState(1);
  const { user } = useSelector((state) => state.user);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [query, setQuery] = useState({
    Nombre: "",
  });
  const { books, hasMore, loading, error } = useCatalogSearch(
    catalogItem?.type ?? "unidad",
    query,
    itemFlag
  );

  const handleOpenDialog = () => {
    setOpenDialog(!openDialog);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setQuery({
      ...query,
      [e.target.name]: value,
    });
  };

  const update = async (id) => {
    if (catalogItem.id == "") {
      await addNew();
    } else {
      await edit(id);
    }

    setItemFlag(!itemFlag);
  };

  const addNew = async () => {
    return await axiosInstance()
      .post(`/api/v1/rules/catalogos`, {
        type: catalogItem.type,
        description: catalogItem.description,
        name: catalogItem.name,
      })
      .then((response) => {
        // console.log(response.data);
        enqueueSnackbar("Unidad agregada correctamente", {
          variant: "success",
        });
        setDialog(1);
        return true;
      })
      .catch((e) => {
        enqueueSnackbar("Error al agregar unidad", {
          variant: "error",
        });
        console.log(e);
        return false;
      });
  };

  const edit = async (id) => {
    return await axiosInstance()
      .patch(`/api/v1/rules/catalogos/${id}`, {
        type: catalogItem.type,
        description: catalogItem.description,
        name: catalogItem.name,
      })
      .then((response) => {
        // console.log(response.data);
        enqueueSnackbar("Unidad actualizada correctamente", {
          variant: "success",
        });
        setDialog(1);
        return true;
      })
      .catch((e) => {
        enqueueSnackbar("Error al editar unidad", {
          variant: "error",
        });
        console.log(e);
        return false;
      });
  };

  const deleteItem = async (id) => {
    return await axiosInstance()
      .delete(`/api/v1/rules/catalogos/${id}`)
      .then((response) => {
        // console.log(response.data);
        enqueueSnackbar("Unidad removida correctamente", {
          variant: "success",
        });
        setItemFlag(!itemFlag);
        setDialog(1);
        return true;
      })
      .catch((e) => {
        enqueueSnackbar("Error al remover unidad", {
          variant: "error",
        });
        console.log(e);
        return false;
      });
  };

  const list = () =>
    books.map((item, index) => {
      return (
        <Grid
          key={index}
          container
          direction="row"
          justifyContent="flex-start"
          className={classes.gridItem}
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            {
              formik
                ? formik.setFieldValue(catalogItem.type, item.name)
                : setData({
                    ...data,
                    unidad: item.name,
                  });
            }
            setChangesFlag(true);
            setOpenDialog(!openDialog);
          }}
        >
          <Grid item xs={10}>
            <Typography className={classes.typoItem}>{item.name}</Typography>
          </Grid>

          <Grid
            item
            xs={2}
            container
            direction="row"
            justifyContent="flex-end"
            className={classes.gridItem2}
          >
            <FontAwesomeIcon
              icon={faChevronRight}
              className={classes.iconEdit}
            />
          </Grid>
        </Grid>
      );
    });

  const listAdmin = () =>
    books.map((item, index) => {
      return (
        <Grid
          key={index}
          container
          direction="row"
          justifyContent="flex-start"
          className={classes.gridItem}
        >
          <Grid item xs={10}>
            <Typography className={classes.typoItem}>{item.name}</Typography>
          </Grid>

          <Grid
            item
            xs={2}
            container
            direction="row"
            justifyContent="flex-end"
            className={classes.gridItem2}
          >
            <FontAwesomeIcon
              icon={faPen}
              className={classes.iconEdit}
              onClick={() => {
                setCatalogItem({
                  ...catalogItem,
                  id: item._id,
                  name: item.name,
                });
                setDialog(3);
              }}
            />

            <Grid
              style={{
                width: "10px",
              }}
            />

            <FontAwesomeIcon
              icon={faTrashAlt}
              className={classes.iconEdit}
              onClick={() => deleteItem(item._id)}
            />
          </Grid>
        </Grid>
      );
    });

  return (
    <Dialog
      open={openDialog}
      onClose={handleOpenDialog}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        style: {
          backgroundColor: "#171a1e",
          textColor: "#fsf",
          width: "483px",
          height: "600px",
          paddingTop: 15,
        },
      }}
    >
      <DialogTitle>
        {dialog == 1 ? (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={11}>
              <Typography className={classes.title}>
                {CapitalizeString(catalogItem?.type ?? "unidad")}
              </Typography>
            </Grid>

            <Grid
              item
              xs={1}
              container
              direction="row"
              justifyContent="flex-end"
            >
              <FontAwesomeIcon
                icon={faTimes}
                className={classes.iconClose}
                onClick={handleOpenDialog}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              xs={1}
              onClick={() => setDialog(dialog == 3 ? 2 : 1)}
              className={classes.divBack}
            >
              <FontAwesomeIcon
                icon={faArrowAltCircleLeft}
                className={classes.iconBack}
              />
            </Grid>

            <Grid item xs={10}>
              <Typography className={classes.title}>
                {`Administración de ${catalogItem?.type ?? "unidad"}`}
              </Typography>
            </Grid>

            <Grid
              item
              xs={1}
              container
              direction="row"
              justifyContent="flex-end"
            >
              <FontAwesomeIcon
                icon={faTimes}
                className={classes.iconClose}
                onClick={handleOpenDialog}
              />
            </Grid>
          </Grid>
        )}
        <div className={classes.divDivider} />
      </DialogTitle>

      <DialogContent>
        {dialog == 1 ? (
          <Grid container direction="column" justifyContent="center">
            {user?.Subcontractor[0]?.empresaPadre ? (
              <Grid container direction="row">
                <Grid item xs={10}>
                  <TextField
                    placeholder="Buscar..."
                    name="Nombre"
                    size="small"
                    variant="outlined"
                    value={query?.Nombre}
                    className={classes.textFieldCustom2}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button onClick={() => setDialog(2)}>
                    <FontAwesomeIcon
                      icon={faCog}
                      style={{
                        width: "30px",
                        height: "30px",
                        color: "#fff",
                      }}
                    />
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <TextField
                  placeholder="Buscar..."
                  name="Nombre"
                  size="small"
                  variant="outlined"
                  value={query?.Nombre}
                  className={classes.textFieldCustom}
                  onChange={handleChange}
                />
              </Grid>
            )}

            <ScrollBars style={{ height: 410 }}>{list()}</ScrollBars>
          </Grid>
        ) : dialog == 2 ? (
          <Grid container direction="column" justifyContent="center">
            <Grid container direction="row" justifyContent="flex-end">
              <Grid item xs={10}>
                <TextField
                  placeholder="Buscar..."
                  name="Nombre"
                  size="small"
                  variant="outlined"
                  value={query?.Nombre}
                  className={classes.textFieldCustom2}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  onClick={() => {
                    setCatalogItem({
                      ...catalogItem,
                      id: "",
                      name: "",
                    });
                    setDialog(3);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    style={{
                      width: "30px",
                      height: "30px",
                      color: "#d233ff",
                      backgroundColor: "#fff",
                      borderRadius: "50%",
                    }}
                  />
                </Button>
              </Grid>
            </Grid>

            <ScrollBars style={{ height: 410 }}>{listAdmin()}</ScrollBars>
          </Grid>
        ) : (
          <Grid container direction="row" justifyContent="center">
            <Grid
              item
              xs={12}
              style={{
                height: "100px",
              }}
            />

            <Grid
              item
              xs={12}
              container
              direction="row"
              justifyContent="center"
            >
              <Typography className={classes.textFieldLetter}>
                {`Nombre de ${catalogItem?.type ?? "unidad"}`}
              </Typography>

              <TextField
                name="name"
                size="small"
                variant="outlined"
                value={catalogItem.name}
                className={classes.textFieldCustom}
                onChange={(e) => {
                  setCatalogItem({
                    ...catalogItem,
                    [e.target.name]: e.target.value,
                  });
                }}
                inputProps={{
                  maxLength: 50,
                  // style: { textTransform: "capitalize" },
                }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                height: "120px",
              }}
            />

            <Grid
              item
              xs={12}
              container
              direction="row"
              justifyContent="center"
            >
              <Button
                variant="contained"
                className={classes.button}
                onClick={() => update(catalogItem.id)}
              >
                <Typography className={classes.typoButton}>Guardar</Typography>
              </Button>
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
}
