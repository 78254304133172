/*eslint-disable*/
import { useEffect, useState } from "react";
import axios from "axios";
import axiosInstance from "../../../utils/axiosInstance";
import {
  DateFormatString,
  DateFormatString3,
} from "../../../utils/DateFormatString";
import readableBytes from "../../../utils/convertBytesToMb";

export default function useVideosSearch(query, pageNumber) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);

  useEffect(() => {
    setRows([]);
  }, [query]);

  useEffect(() => {
    var params = new URLSearchParams();
    // params.append("page", query.page - 1);
    // params.append("limit", query.pageSize);
    params.append("order_partial", query.Order);
    params.append("propertydatefilter", "insert_datetime");
    params.append("initialDate", query.intialDate);
    params.append("finalDate", query.finalDate);
    // params.append(
    //   "initialDate",
    //   new Date(query.intialDate.setHours(0, 0, 0, 0))
    // );
    // params.append(
    //   "finalDate",
    //   new Date(query.finalDate.setHours(23, 59, 59, 999))
    // );

    setLoading(true);
    setError(false);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    axiosInstance()
      .get("/api/v1/rules/videos", {
        params,
        cancelToken: source.token,
      })
      .then((response) => {
        // console.log(response.data.data);
        let result = response.data.data;

        result.map((video) => {
          // console.log(video);
          video.id = video._id;
          // video.id = video.Key;
          video.dateString = DateFormatString(video.insert_datetime);
          video.size = readableBytes(video.size);
          video.dateWeek = DateFormatString3(video.insert_datetime);
        });

        setRows(result);
        setTotalRows(parseInt(response.data.data.length));
        // setTotalRows(parseInt(response.data.data.Total));
        setLoading(false);
      })
      .catch((e) => {
        // console.log(e);
        if (axios.isCancel(e)) return;
        setError(true);
      });
    return () => source.cancel();
  }, [query, pageNumber]);

  return { loading, error, rows, totalRows };
}
