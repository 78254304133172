/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import svgTrabajador from "../../../images/usuario_trabajador.svg";
import { faMapMarkerAlt, faImage } from "@fortawesome/free-solid-svg-icons";
import WorkIcon from "@material-ui/icons/Work";
import { useDispatch, useSelector } from "react-redux";
import { getObra } from "../../../actions/obras";

const useStyles = makeStyles((theme) => ({
  divMain: {
    maxWidth: 345,
  },
  card: {
    maxWidth: "100%",
    minHeight: "360px",
    borderRadius: "16px",
    cursor: "pointer",
    backgroundColor: "#2d3034",
    "&:hover": {
      background: "#3B3D40",
      boxShadow: "0 0px 16px 0 rgba(90, 90, 90, 0.2)",
    },
  },
  griCard: {
    paddingLeft: 20,
    paddingBottom: 20,
    paddingRight: 20,
  },
  noWrap: {
    textOverflow: "ellipsis",
    wordWrap: "anywhere",
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
  },
}));

export default function CardObras(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { data } = props;

  const detailObras = () => {
    dispatch(getObra(data._id));
    history.push(`/dashboard/obras/${data._id}`);
  };

  return (
    <div onClick={detailObras}>
      <Card className={classes.card}>
        <Grid container direction="row">
          {data?.foto ? (
            <img
              src={data?.foto.url}
              width="100%"
              height="184px"
              style={{ objectFit: "cover" }}
            />
          ) : (
            <Grid
              container
              justifyContent="center"
              alignContent="center"
              style={{
                width: "100%",
                height: "184px",
                backgroundColor: "#a5a5a5",
              }}
            >
              <FontAwesomeIcon
                icon={faImage}
                style={{
                  width: 98,
                  height: 68,
                  color: "#717171",
                  // borderRadius: "8px",
                }}
              />
            </Grid>
          )}

          <Grid className={classes.griCard}>
            <div>
              <Typography
                className={classes.noWrap}
                style={{
                  marginTop: 15,
                  fontFamily: "Roboto",
                  fontWeight: 500,
                  fontSize: 18,
                  textAlign: "left",
                  color: "#fff",
                  maxWidth: "90%",
                }}
              >
                {`${data.proyecto}`}
              </Typography>

              <Grid container direction="row" justifyContent="flex-start">
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  style={{
                    width: "11px",
                    height: "15px",
                    color: "#d233ff",
                    marginTop: 2,
                  }}
                />
                <Typography
                  className={classes.noWrap}
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 14,
                    color: "#fff",
                    // textOverflow: "ellipsis",
                    // whiteSpace: "nowrap",
                    // overflow: "hidden",
                    maxWidth: "90%",
                    marginLeft: 8,
                  }}
                >
                  {data.domicilio}
                </Typography>
              </Grid>
            </div>

            <div style={{ marginTop: 40 }}>
              <Grid container direction="row" justifyContent="flex-start">
                <WorkIcon
                  style={{
                    color: "#fff",
                    width: "13.6px",
                    height: "12.5px",
                    marginTop: 2,
                  }}
                />

                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 14,
                    color: "#fff",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    maxWidth: "90%",
                    marginLeft: 6,
                  }}
                >
                  {`${data.obrasCount} subcontratistas`}
                </Typography>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                style={{ marginTop: 10 }}
              >
                <img
                  src={svgTrabajador}
                  style={{
                    width: "14px",
                    height: "14px",
                    marginTop: 1,
                  }}
                  alt="techton logo"
                />
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 14,
                    color: "#fff",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    maxWidth: "90%",
                    marginLeft: 6,
                  }}
                >
                  {`${data.trabajadoresCount} trabajadores`}
                </Typography>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
}
