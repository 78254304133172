/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileMedical } from "@fortawesome/free-solid-svg-icons";
import DialogUploadDocuments from "./DialogUploadDocuments";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    // paddingLeft: "30px",
    // padding: theme.spacing(1),
    // width: "100%",
    // height: "100%",
    backgroundColor: "blue",
  },
  divFile: {
    width: "200px",
    height: "200px",
    borderRadius: "8px",
    backgroundColor: "#2d3034",
  },
  icon: {
    width: "40px",
    height: "40px",
    color: "white",
    marginBottom: "20px",
  },
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: "bold",
    textAlign: "left",
    color: "#cdcece",
  },
}));

export default function AddNewFileButton(props) {
  const classes = useStyles();
  const [openDialog, setOpendialog] = useState(false);

  const handleNewDocument = () => {
    setOpendialog(!openDialog);
  };

  return (
    <div>
      <Button
        // type="submit"
        variant="contained"
        onClick={handleNewDocument}
        style={{
          width: "200px",
          height: "48px",
          borderRadius: "8px",
          color: "#ffffff",
          backgroundColor: "#2d3034",
        }}
      >
        <div
          style={{
            background: "#d233ff",
            position: "absolute",
            width: "10px",
            height: "10px",
            marginRight: 140,
            marginTop: 5
          }}
        ></div>
        <FontAwesomeIcon
          icon={faFileMedical}
          style={{
            width: "20px",
            height: "20px",
            marginRight: "5px",
            color: "#fff",
            zIndex: 99
            // backgroundColor: "#d233ff",
            // borderRadius: "40%",
          }}
        />
        <Typography
          style={{
            fontFamily: "Roboto",
            fontSize: 12,
            fontWeight: 500,
            color: "#fff",
            textTransform: "none",
          }}
        >
          Agregar documento extra
        </Typography>
      </Button>

      <DialogUploadDocuments
        setOpendialog={setOpendialog}
        openDialog={openDialog}
      />
    </div>
  );
}
