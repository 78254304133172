/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Typography,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    // backgroundColor: "blue",
  },
  divImg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "160px",
    height: "160px",
    borderRadius: "16px",
    border: "solid 1px #707070",
    backgroundColor: "#212429",
    cursor: "pointer",
  },
  photo: {
    width: "90%",
    height: "90%",
    borderRadius: "8px",
    aspectRatio: 1,
    // position: "absolute",
    objectFit: "contain",
  },
  icon: {
    width: "40px",
    height: "40px",
    color: "white",
    marginBottom: "20px",
  },
  iconFont: {
    width: "22px",
    height: "32px",
    color: "#fff",
    cursor: "pointer",
  },
  iconMenu: {
    width: "20px",
    height: "25px",
    color: "#fff",
    cursor: "pointer",
    marginRight: 10,
  },
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: "bold",
    textAlign: "left",
    color: "#cdcece",
  },
  textFieldCustom: {
    width: "80%",
    color: "#676767",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
    marginRight: 5,
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
  },
  textFieldCustom2: {
    width: "95%",
    color: "#676767",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  typoLogo: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "1.13",
    textAlign: "center",
    color: "#d9d9d9",
    htmlFor: "fileInput",
  },
  formControl: {
    width: "90%",
  },
  select: {
    width: "100%",
    height: "40px",
    borderRadius: "6px",
    backgroundColor: "#fff",
  },
  gridMargin: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
  buttonAdd: {
    width: "80%",
    height: "48px",
    borderRadius: 8,
    border: "solid 2px #707070",
    borderStyle: "dashed",
    textTransform: "none",
  },
}));

export default function InfoBasic(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    setOpendialog(true);
    setAnchorEl(null);
  };

  const handleEdit = () => {};

  const handleChange = (e) => {
    props.setChangesFlag(true);
    const value = e.target.value;
    props.setSubcontractorData({
      ...props.subcontractorData,
      [e.target.name]: value,
    });
  };

  return (
    <Grid container className={classes.divMain}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        className={classes.gridMargin}
      >
        <div className={classes.divImg}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <AddToPhotosIcon className={classes.icon} />
            <Typography className={classes.typoLogo}>Subir logotipo</Typography>
          </Grid>
        </div>
      </Grid>

      <Grid
        item
        xs={12}
        style={{
          height: "30px",
        }}
      />

      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <Typography className={classes.textFieldLetter}>
          Nombre empresa
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <TextField
            // placeholder="Obligatorio"
            // name="empresa"
            size="small"
            variant="outlined"
            // value={props.subcontractorData.empresa}
            className={classes.textFieldCustom}
            // onChange={handleChange}
            inputProps={{
              maxLength: 50,
              style: { textTransform: "capitalize" },
            }}
          />
          <FontAwesomeIcon
            icon={faEllipsisV}
            className={classes.iconFont}
            onClick={handleClick}
          />
          <Menu
            // classes={{ paper: classes.menuPaper }}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
            id="long-menu"
            getContentAnchorEl={null}
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleClose}
            PaperProps={{
              style: {
                backgroundColor: "#27292d",
              },
            }}
          >
            <MenuItem
              key={"Editar"}
              style={{ color: "white" }}
              // onClick={handleEdit}
            >
              <EditIcon className={classes.iconMenu} />
              {"Editar"}
            </MenuItem>
            <MenuItem
              key={"Eliminar"}
              style={{ color: "white" }}
              // onClick={handleDelete}
            >
              <DeleteIcon className={classes.iconMenu} />
              {"Eliminar"}
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
        xl={4}
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-end"
      >
        <Button
          className={classes.buttonAdd}
          onClick={() =>
            props.setOpenDialogNewQuestion(!props.openDialogNewQuestion)
          }
        >
          <FontAwesomeIcon
            icon={faPlus}
            style={{
              width: "12px",
              height: "12px",
              color: "#ac0bce",
              marginRight: "10px",
            }}
          />
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 17,
              lineHeight: 1.35,
              textAlign: "left",
              color: "#e5e5e5",
            }}
          >
            Nueva pregunta
          </Typography>
        </Button>
      </Grid>

      <Grid
        item
        xs={12}
        style={{
          height: "30px",
        }}
      />

      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <Typography className={classes.textFieldLetter}>
          Nombre empresa
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <TextField
            // placeholder="Obligatorio"
            // name="empresa"
            size="small"
            variant="outlined"
            // value={props.subcontractorData.empresa}
            className={classes.textFieldCustom}
            // onChange={handleChange}
            inputProps={{
              maxLength: 50,
              style: { textTransform: "capitalize" },
            }}
          />
          <FontAwesomeIcon
            icon={faEllipsisV}
            className={classes.iconFont}
            onClick={handleClick}
          />
          <Menu
            // classes={{ paper: classes.menuPaper }}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
            id="long-menu"
            getContentAnchorEl={null}
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleClose}
            PaperProps={{
              style: {
                backgroundColor: "#27292d",
              },
            }}
          >
            <MenuItem
              key={"Editar"}
              style={{ color: "white" }}
              // onClick={handleEdit}
            >
              <EditIcon className={classes.iconMenu} />
              {"Editar"}
            </MenuItem>
            <MenuItem
              key={"Eliminar"}
              style={{ color: "white" }}
              // onClick={handleDelete}
            >
              <DeleteIcon className={classes.iconMenu} />
              {"Eliminar"}
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
    </Grid>
  );
}
