/*eslint-disable*/
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { TextField, Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faChartLine,
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { clearFiles } from "../../../actions/file";
import { ValidatePermissions } from "../../../utils/permissions";
import MultiSelect from "../../../common_components/Filters/MutiSelect";
import axiosInstance from "../../../utils/axiosInstance";
import CardSettingsSection from "./CardSettingsSection";
import WorkIcon from "@material-ui/icons/Work";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    padding: 15,
  },
  buttonSearch: {
    width: "152px",
    height: "40px",
    borderRadius: "8px",
    color: "#ffffff",
    backgroundColor: "#ac0bce",
    textTransform: "none",
    // [theme.breakpoints.down("md")]: {
    //   marginTop: 10,
    //   marginLeft: 0,
    // },
    // [theme.breakpoints.up("md")]: {
    //   marginTop: 0,
    //   marginLeft: 10,
    // },
  },
  divBack: {
    cursor: "pointer",
    marginBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
  back: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 500,
    textAlign: "left",
    color: "#ffffff",
    cursor: "pointer",
  },
  multi: {
    color: "white",
    padding: 2,
    border: 0,
    borderRadius: "8px",
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 28,
    fontWeight: 600,
    textAlign: "left",
    color: "#ffffff",
    marginRight: 20,
  },
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 16,
    textAlign: "left",
    color: "#676767",
  },
  textFieldCustom: {
    width: "478px",
    color: "#676767",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
  },
}));

export default function MyEnterpriseSettings(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [pageNumber, setPageNumber] = useState(0);
  const [subcontratistas, setSubcontratistas] = useState(null);
  const [proyectos, setProyectos] = useState(null);
  const [status, setStatus] = useState(null);
  const [statusImss, setStatusImss] = useState(null);
  const [loadingData, setLoadingData] = useState(false);

  const [query, setQuery] = useState({
    Empleado: "",
    Proyectos: [],
    Subcontratistas: [],
    Status: [],
    EstatusImss: [],
  });

  const registerEmployee = () => {
    localStorage.setItem("idEmployee", "");
    dispatch(clearFiles());
    history.push(`/dashboard/empleados/crear`);
  };

  return (
    <Grid className={classes.divMain}>
      <Grid
        item
        xs={6}
        sm={4}
        md={2}
        lg={2}
        xl={2}
        container
        direction="row"
        alignItems="center"
        onClick={() => history.push(`/dashboard/miempresa`)}
        className={classes.divBack}
      >
        <Grid item>
          <FontAwesomeIcon
            icon={faArrowAltCircleLeft}
            style={{
              width: "20px",
              height: "20px",
              marginRight: "10px",
              color: "#d6d6d6",
            }}
          />
        </Grid>
        <Grid item>
          <Typography className={classes.back}>Regresar</Typography>
        </Grid>
      </Grid>
      <Grid item xs={6} sm={8} md={10} lg={10} xl={10}></Grid>

      <Grid
        item
        xs={12}
        style={{
          height: "10px",
        }}
      />

      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Typography className={classes.title}>
          Configuraciones de empresa
        </Typography>
      </Grid>

      <Grid
        item
        xs={12}
        style={{
          height: "20px",
        }}
      />

      <Grid container spacing={3} direction="row" justifyContent="flex-start">
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <CardSettingsSection
            url={"/dashboard/miempresa/configuracion/reportes"}
            icon={
              <FontAwesomeIcon
                icon={faChartLine}
                style={{
                  width: "24px",
                  height: "18px",
                  color: "#fff",
                }}
              />
            }
            title={"Reportes"}
            subtitle={
              "Configura los días por default seleccionados al abrir un reporte"
            }
          />
        </Grid>
        {/* <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <CardSettingsSection
            url={"/dashboard/miempresa/configuracion/6275742a5b06bd002b954b31"}
            icon={
              <WorkIcon
                style={{
                  width: "24px",
                  height: "22px",
                  color: "#fff",
                }}
              />
            }
            title={"Tipos de empresas"}
            subtitle={"Configura los tipos de empresas"}
          />
        </Grid> */}
      </Grid>
    </Grid>
  );
}
