/*eslint-disable*/
import React, { useState, useEffect } from "react";
import DataGrid from "../../../../common_components/Filters/DataGrid";
import { Grid, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faCalendarAlt,
  faExclamationCircle,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { makeStyles } from "@material-ui/styles";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .header": {
      color: "white",
      fontFamily: "Roboto",
      fontSize: "18px",
      fontWeight: 500,
    },
  },
  photo: {
    width: "40px",
    height: "40px",
    borderRadius: "8px",
    margin: "auto",
    aspectRatio: 1,
  },
}));

export default function DataGridWorkingTime(props) {
  const classes = useStyles();
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  function stringAvatar(names) {
    let initials = "";
    names.split(" ").map((name) => {
      initials = initials + name[0];
    });

    if (initials.length > 2) {
      initials = initials.slice(0, 2);
    }

    return {
      children: initials,
    };
  }

  function secondsToHms(d) {
    d = Number(d);
    var hours = Math.floor(d / 3600);
    var minutes = Math.floor((d % 3600) / 60);
    var seconds = Math.floor((d % 3600) % 60);
    return {
      hours,
      minutes,
      seconds,
    };
  }

  const columns = [
    {
      field: "photo",
      headerName: " ",
      headerAlign: "center",
      width: 100,
      sortable: false,
      headerClassName: "header",
      renderCell: (params) => {
        return params.row.photo ? (
          <div style={{ margin: "auto" }}>
            <Avatar
              src={params.row.photo}
              style={{
                backgroundColor: "#d233ff",
                marginTop: 8,
                width: 35,
                height: 35,
              }}
            />
          </div>
        ) : (
          params.row.Name != "" && (
            <div style={{ margin: "auto" }}>
              <Avatar
                style={{
                  backgroundColor: "#d233ff",
                  marginTop: 8,
                  width: 35,
                  height: 35,
                }}
                {...stringAvatar(params.row.Name)}
              />
            </div>
          )
        );
      },
    },
    {
      field: "Name",
      headerName: "Empleado",
      headerAlign: "center",
      width: 250,
      sortable: false,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Grid container direction="column" justifyContent="center">
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 16,
                color: "#fff",
              }}
            >
              {params.row.Name ? params.row.Name : "--"}
            </Typography>

            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 16,
                color: "#acacac",
              }}
            >
              {params.row.Puesto ? params.row.Puesto : "--"}
            </Typography>
          </Grid>
        );
      },
    },
    {
      field: "Hours",
      headerName: "Horas trabajadas",
      headerAlign: "center",
      width: 180,
      sortable: false,

      renderCell: (params) => (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 16,
              color: "#fff",
              marginLeft: 6,
            }}
          >
            {`${secondsToHms(params.row.Hours).hours} Hrs ${
              secondsToHms(params.row.Hours).minutes
            } min`}
          </Typography>
        </Grid>
      ),

      renderHeader: () => (
        <Grid
          container
          direction="column"
          alignItems="center"
          style={{ padding: 15 }}
        >
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 18,
              color: "#fff",
              fontWeight: 500,
            }}
          >
            Horas
          </Typography>

          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 18,
              color: "#fff",
              fontWeight: 500,
            }}
          >
            trabajadas
          </Typography>
        </Grid>
      ),
    },
  ];

  if (props.dynamicColumns) {
    props.dynamicColumns.map((dynamicColumns) => {
      columns.push({
        field: dynamicColumns,
        headerName: dynamicColumns,
        headerAlign: "center",
        width: 130,
        sortable: false,
        renderHeader: (params) => {
          return (
            <Grid
              container
              direction="column"
              alignItems="center"
              style={{ padding: 15 }}
            >
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 22,
                  color: "#d233ff",
                  fontWeight: "bold",
                }}
              >
                {params.field.split("_")[2]}
              </Typography>

              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 14,
                  color: "#fff",
                  fontWeight: 500,
                }}
              >
                {months[params.field.split("_")[1]]}
              </Typography>
            </Grid>
          );
        },
        renderCell: (params) => {
          return params.row[dynamicColumns].totalsegundosAlDia > 0 ? (
            <Grid container direction="row" justifyContent="center">
              <Grid
                container
                direction="column"
                style={{
                  width: 72,
                  height: 72,
                  margin: 20,
                  borderRadius: 6,
                  backgroundImage:
                    "linear-gradient(45deg, #d233ff 3%, #bf1fe7 51%, #d233ff 97%)",
                }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  style={{ height: "38.5%" }}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{ marginTop: 5 }}
                  >
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontSize: 16,
                        color: "#ff",
                        fontWeight: "bold",
                      }}
                    >
                      {
                        secondsToHms(
                          params.row[dynamicColumns].totalsegundosAlDia
                        ).hours
                      }
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontSize: 10,
                        color: "#fff",
                        marginLeft: 6,
                      }}
                    >
                      Hrs
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  style={{ height: "38.5%" }}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{ marginTop: 5 }}
                  >
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontSize: 16,
                        color: "#ff",
                        fontWeight: "bold",
                      }}
                    >
                      {
                        secondsToHms(
                          params.row[dynamicColumns].totalsegundosAlDia
                        ).minutes
                      }
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontSize: 10,
                        color: "#fff",
                        marginLeft: 6,
                      }}
                    >
                      min
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="center">
                  <Grid
                    onClick={(event) =>
                      props.menu(
                        event,
                        params.row[dynamicColumns].entradaSalida
                      )
                    }
                    style={{
                      backgroundColor: "#ac0bce",
                      width: 44,
                      height: 16,
                      borderTopLeftRadius: 4,
                      borderTopRightRadius: 4,
                      cursor: "pointer",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faAngleDown}
                      style={{
                        width: 22,
                        height: 14,
                        color: "#fff",
                        marginTop: 2,
                        marginLeft: -11,
                        position: "fixed",
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid container direction="row" justifyContent="center">
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{
                  width: 72,
                  height: 72,
                  margin: 20,
                  borderRadius: 6,
                  backgroundColor: "#2d3034",
                  border: "dashed 2px #707070",
                }}
              >
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  style={{
                    width: 16,
                    height: 14,
                    color: "#e3c817",
                  }}
                />

                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 12,
                    color: "#bbbbbb",
                    marginTop: 6,
                  }}
                >
                  Sin registro
                </Typography>
              </Grid>
            </Grid>
          );
        },
      });
    });
  }
  return (
    <DataGrid
      rows={props.rows}
      columns={columns}
      loading={props.loading}
      rowCount={props.rowCount}
      rowsPerPageOptions={props.rowsPerPageOptions}
      page={props.page}
      pageSize={props.pageSize}
      onPageChange={props.onPageChange}
      onPageSizeChange={props.onPageSizeChange}
      style={props.style}
      onSelectionModelChange={props.onSelectionModelChange}
      selectionModel={props.selectionModel}
      rowHeight={100}
    />
  );
}
