/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import MyEnterpriseScreen from "./MyEnterpriseScreen";
import ContractorDetailScreen from "../ContractorDetailScreen/ContractorDetailScreen";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    padding: 15,
  },
}));

export default function MyEnterpriseChooseScreen(props) {
  const classes = useStyles();
  const { user } = useSelector((state) => state.user);
  const [parentCompany, setParentCompany] = useState(true);

  useEffect(() => {
    if (user) {
      //   console.log(user);
      if (user.Subcontractor[0]?.hasOwnProperty("empresaPadre"))
        setParentCompany(true);
      else setParentCompany(false);
    }
  }, [user]);

  return (
    <div className={classes.divMain}>
      {user && parentCompany ? (
        <MyEnterpriseScreen />
      ) : (
        <ContractorDetailScreen />
      )}
    </div>
  );
}
