/*eslint-disable*/
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useDropzone } from "react-dropzone";
import Lottie from "react-lottie";
import UploadAnimation from "../../../animations/upload_document.json";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { addFile } from "../../../actions/file";

const useStyles = makeStyles((theme) => ({
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: "bold",
    textAlign: "left",
    color: "#cdcece",
  },
  textFieldCustom: {
    width: "350px",
    color: "#676767",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
  },
  divImg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "356px",
    height: "290px",
    borderRadius: "8px",
    border: "solid 1px #707070",
    backgroundColor: "#212429",
    cursor: "pointer",
  },
}));

export default function DialogUploadDocument(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [data, setData] = useState();
  const { setOpendialog, openDialog } = props;
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    noKeyboard: true,
    maxFiles: 1,
  });

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: UploadAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleOpenDialog = () => {
    setData({ nombre: "" });
    acceptedFiles.pop();
    setOpendialog(!openDialog);
  };

  const handleUploadDocument = () => {
    if (acceptedFiles?.[0] && data?.nombre) {
      dispatch(
        addFile({
          file: acceptedFiles[0],
          name: data.nombre,
          url: null,
          process: "new",
          id: data.nombre + new Date().getTime(),
          date: new Date().toISOString(),
        })
      );
      handleOpenDialog();
    } else {
      console.log("error");
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setData({
      ...setData,
      [e.target.name]: value,
    });
  };

  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleOpenDialog}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            backgroundColor: "#171a1e",
            textColor: "#fsf",
            width: "483px",
            height: "691px",
            paddingTop: 15,
          },
        }}
      >
        <DialogTitle>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 20,
                textAlign: "center",
                color: "#fff",
                fontWeight: "bold",
              }}
            >
              Nuevo archivo
            </Typography>

            <Grid
              container
              justifyContent="flex-end"
              style={{ position: "absolute" }}
            >
              <Button onClick={handleOpenDialog}>
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{
                    width: "20px",
                    height: "20px",
                    color: "#fff",
                  }}
                />
              </Button>
            </Grid>
          </Grid>
          <div
            style={{
              width: "100%",
              height: 1,
              background: "#707070",
              marginTop: 8,
              padding: 0,
            }}
          ></div>
        </DialogTitle>

        <DialogContent>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ marginTop: 8 }}
          >
            <div className={classes.divImg} {...getRootProps()}>
              <input {...getInputProps()} />

              <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                {acceptedFiles.length > 0 ? (
                  <Grid container item justifyContent="center">
                    <div
                      style={{
                        background: "#d233ff",
                        position: "absolute",
                        width: "30px",
                        height: "35px",
                        marginTop: 20,
                      }}
                    ></div>
                    <FontAwesomeIcon
                      icon={faFileAlt}
                      className={classes.iconForm}
                      style={{
                        width: "48px",
                        height: "64px",
                        color: "#fff",
                        zIndex: 99,
                        marginBottom: 30,
                      }}
                    ></FontAwesomeIcon>
                  </Grid>
                ) : (
                  <Lottie options={defaultOptions} height={230} width={308} />
                )}

                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 16,
                    fontWeight: "bold",
                    lineHeight: "1.13",
                    textAlign: "center",
                    color: "#d9d9d9",
                    width: "80%",
                    // htmlFor: "fileInput",
                  }}
                >
                  {acceptedFiles.length > 0
                    ? acceptedFiles[0].name
                    : "Subir archivo"}
                </Typography>

                {acceptedFiles.length > 0 ? (
                  <Button
                    variant="contained"
                    onClick={open}
                    style={{
                      width: "119px",
                      height: "32px",
                      borderRadius: "8px",
                      backgroundColor: "#ac0bce",
                      textTransform: "none",
                      marginTop: 15,
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontSize: 18,
                        textAlign: "left",
                        color: "#ffffff",
                      }}
                    >
                      Modificar
                    </Typography>
                  </Button>
                ) : (
                  <div></div>
                )}

                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 14,
                    textAlign: "center",
                    color: "#a4a4a4",
                    marginTop: 3,
                  }}
                >
                  {acceptedFiles.length > 0
                    ? ""
                    : "Sube o arrastra el archivo a subir"}
                </Typography>
              </Grid>
            </div>

            <Grid>
              <Typography
                className={classes.textFieldLetter}
                style={{ marginTop: 40, marginBottom: 8 }}
              >
                Identificador de archivo
              </Typography>
              <TextField
                name="nombre"
                size="small"
                variant="outlined"
                // align="center"
                value={data?.nombre}
                className={classes.textFieldCustom}
                onChange={handleChange}
              />
            </Grid>
            <Button
              onClick={handleUploadDocument}
              style={{
                width: "350px",
                height: "40px",
                borderRadius: "8px",
                color: "#ffffff",
                backgroundColor: "#ac0bce",
                textTransform: "none",
                marginTop: 50,
              }}
            >
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 21,
                  color: "#ffffff",
                }}
              >
                Subir archivo
              </Typography>
            </Button>
          </Grid>

          {/* <Grid container direction="row" justifyContent="flex-end">
            <Button
              onClick={handleOpenDialog}
              style={{
                textTransform: "none",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 20,
                  color: "#fff",
                  fontWeight: "bold",
                  marginTop: 60,
                }}
              >
                Cerrar
              </Typography>
            </Button>
          </Grid> */}
        </DialogContent>
      </Dialog>
    </div>
  );
}
