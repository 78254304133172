/*eslint-disable*/
import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import ShowMoreButton from "../../components/ShowMoreButton";

const useStyles = makeStyles((theme) => ({
  divMain: {
    // flexGrow: 1,
    // height: "100%",
    // backgroundColor: "red",
  },
  gridBehavior: {
    padding: theme.spacing(1, 1, 1, 2),
    // padding: theme.spacing(2),
    height: 110,
    borderRadius: 16,
    backgroundColor: "#2d3034",
  },
}));

export default function HoursWorked(props) {
  const classes = useStyles();

  return (
    <div className={classes.divMain}>
      <Grid item xs={12} className={classes.gridBehavior}>
        <Grid container direction="row">
          <Grid item xs={12}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 22,
                fontWeight: 500,
                color: "#fff",
              }}
            >
              Horas trabajadas
            </Typography>
          </Grid>
        </Grid>

        <Grid container direction="row" alignItems="center">
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 20,
              color: "#fff",
              fontWeight: 500,
            }}
          >
            -- Hrs
          </Typography>
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 18,
              color: "#c1c1c1",
              marginLeft: 8,
            }}
          >
            esta semana
          </Typography>
        </Grid>

        <Grid
          container
          direction="row"
          alignItems="center"
          // style={{ marginTop: 2 }}
        >
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 20,
              color: "#fff",
              fontWeight: 500,
            }}
          >
            -- Hrs
          </Typography>
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 18,
              color: "#c1c1c1",
              marginLeft: 8,
            }}
          >
            este mes
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
