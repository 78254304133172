/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Grid, Typography, TextField } from "@material-ui/core";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import { useDropzone } from "react-dropzone";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    // backgroundColor: "red",
  },
  divImg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "160px",
    height: "160px",
    borderRadius: "16px",
    border: "solid 1px #707070",
    backgroundColor: "#212429",
    cursor: "pointer",
  },
  photo: {
    width: "90%",
    height: "90%",
    borderRadius: "8px",
    aspectRatio: 1,
    // position: "absolute",
    objectFit: "cover",
  },
  icon: {
    width: "40px",
    height: "40px",
    color: "white",
    marginBottom: "20px",
  },
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: "bold",
    textAlign: "left",
    color: "#cdcece",
  },
  textFieldCustom: {
    width: "100%",
    color: "#676767",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  formControl: {
    width: "90%",
  },
  select: {
    width: "100%",
    height: "40px",
    borderRadius: "6px",
    backgroundColor: "#fff",
    // color: "#acacac",
  },
  gridMargin: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
  gridPhoto: {
    width: "30%",
    cursor: "pointer",
    marginTop: 5,
    // backgroundColor: "red",
    [theme.breakpoints.down("sm")]: {
      width: "60%",
    },
  },
}));

export default function InfoBasic(props) {
  const { setOpenDialogJobs } = props;
  const classes = useStyles();
  const { user } = useSelector((state) => state.user);

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    noKeyboard: true,
    accept: "image/*",
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      acceptedFiles.map((file) => processImage(file));
    },
    // noClick: true
  });

  const processImage = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let fileURL = window.URL.createObjectURL(file);
      props.setEmployeeData({
        ...props.employeeData,
        foto: file,
        fotoCache: fileURL,
      });
      props.setChangesFlag(true);
    };
  };

  const handleChange = (e) => {
    props.setChangesFlag(true);
    const value = e.target.value;
    props.setEmployeeData({
      ...props.employeeData,
      [e.target.name]: value,
    });
    // console.log(props.employeeData);
  };

  useEffect(() => {
    if (
      props.employeeData.foto != "" &&
      props.employeeData.nombre != "" &&
      props.employeeData.apellidos != "" &&
      props.employeeData.subcontratista != "" &&
      props.employeeData.proyectoObra != "" &&
      // props.employeeData.curp != "" &&
      // props.employeeData.imss != "" &&
      (user?.EnterpriseData?.Db == "Dicoma680603" ||
        (user?.EnterpriseData?.Db != "Dicoma680603" &&
          props.employeeData.imss != "")) &&
      (user?.EnterpriseData?.Db != "GrupoGarzaPonce903228" ||
        (user?.EnterpriseData?.Db == "GrupoGarzaPonce903228" &&
          props.employeeData.numeroEmpleado != "")) &&
      (user?.EnterpriseData?.Db == "Dicoma680603" ||
        (user?.EnterpriseData?.Db != "Dicoma680603" &&
          props.employeeData.curp != ""))
    ) {
      if (props.employeeData.subcontratista == user?.Subcontractor[0].empresa) {
        if (props.employeeData.unidad != "") {
          props.setMandatoryFlag(true);
        } else {
          props.setMandatoryFlag(false);
        }
      } else {
        props.setMandatoryFlag(true);
        props.setEmployeeData({
          ...props.employeeData,
          unidad: "",
        });
      }
    } else {
      props.setMandatoryFlag(false);
    }
  }, [
    props.employeeData.foto,
    props.employeeData.nombre,
    props.employeeData.apellidos,
    props.employeeData.subcontratista,
    props.employeeData.proyectoObra,
    props.employeeData.curp,
    props.employeeData.imss,
    props.employeeData.unidad,
    props.employeeData.numeroEmpleado,
  ]);

  return (
    <Grid container className={classes.divMain}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.gridMargin}
      >
        {props.userData.fotoReq ? (
          <div
            className={classes.divImg}
            onClick={() => {
              props.setInfoDialog(false);
              props.setOpenDialogPhotoReq(!props.openDialogPhotoReq);
            }}
          >
            {props.employeeData.fotoCache ? (
              <img
                src={props.employeeData.fotoCache}
                className={classes.photo}
                alt="fotoCache"
              />
            ) : (
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <AddToPhotosIcon className={classes.icon} />
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 16,
                    fontWeight: 500,
                    lineHeight: "1.13",
                    textAlign: "center",
                    color: "#d9d9d9",
                    htmlFor: "fileInput",
                  }}
                >
                  Subir imagen
                </Typography>
              </Grid>
            )}
          </div>
        ) : (
          <div className={classes.divImg} {...getRootProps()}>
            <input {...getInputProps()} />

            {props.employeeData.fotoCache ? (
              <img
                src={props.employeeData.fotoCache}
                className={classes.photo}
                alt="fotoCache"
              />
            ) : (
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <AddToPhotosIcon className={classes.icon} />
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 16,
                    fontWeight: 500,
                    lineHeight: "1.13",
                    textAlign: "center",
                    color: "#d9d9d9",
                    htmlFor: "fileInput",
                  }}
                >
                  Subir imagen
                </Typography>
              </Grid>
            )}
          </div>
        )}

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          onClick={() => {
            props.setInfoDialog(true);
            props.setOpenDialogPhotoReq(!props.openDialogPhotoReq);
          }}
          className={classes.gridPhoto}
        >
          <FontAwesomeIcon
            icon={faQuestionCircle}
            style={{
              width: "16px",
              height: "16px",
              color: "#fff",
              marginRight: 5,
            }}
          />
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 14,
              fontWeight: 500,
              textAlign: "center",
              color: "#d9d9d9",
            }}
          >
            Indicaciones de foto
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        style={{ marginTop: 30 }}
        spacing={3}
      >
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Typography className={classes.textFieldLetter}>
            Nombre (s)
          </Typography>
          <TextField
            placeholder="Obligatorio"
            name="nombre"
            size="small"
            variant="outlined"
            value={props.employeeData.nombre}
            className={classes.textFieldCustom}
            onChange={handleChange}
            inputProps={{
              maxLength: 50,
              style: { textTransform: "capitalize" },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Typography className={classes.textFieldLetter}>
            Apellido (s)
          </Typography>
          <TextField
            placeholder="Obligatorio"
            name="apellidos"
            size="small"
            variant="outlined"
            value={props.employeeData.apellidos}
            className={classes.textFieldCustom}
            onChange={handleChange}
            inputProps={{
              maxLength: 50,
              style: { textTransform: "capitalize" },
            }}
          />
        </Grid>
        {/* {user?.EnterpriseData?.Db == "GrupoGarzaPonce903228" && ( */}
        {user?.EnterpriseData?.Db == "GrupoGarzaPonce903228" && (
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            className={classes.gridMargin}
          >
            <Typography className={classes.textFieldLetter}>
              Especialidad
            </Typography>
            <TextField
              name="especialidad"
              size="small"
              variant="outlined"
              value={props.employeeData.especialidad}
              className={classes.textFieldCustom}
              onClick={() =>
                props.setOpenDialogSpecialty(!props.openDialogSpecialty)
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <FontAwesomeIcon
                      icon={faCaretDown}
                      className={classes.iconForm}
                      style={{
                        width: "24px",
                        height: "24px",
                        color: "#ADADAD",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          className={classes.gridMargin}
        >
          <Typography className={classes.textFieldLetter}>
            {user?.EnterpriseData?.Db == "GrupoGarzaPonce903228"
              ? "Cargo"
              : "Puesto"}
          </Typography>
          <TextField
            name="puesto"
            size="small"
            variant="outlined"
            value={props.employeeData.puesto}
            className={classes.textFieldCustom}
            onClick={() => setOpenDialogJobs((prev) => !prev)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <FontAwesomeIcon
                    icon={faCaretDown}
                    className={classes.iconForm}
                    style={{
                      width: "24px",
                      height: "24px",
                      color: "#ADADAD",
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          className={classes.gridMargin}
        >
          <Typography className={classes.textFieldLetter}>
            Subcontratista
          </Typography>
          <TextField
            placeholder="Obligatorio"
            name="subcontratista"
            size="small"
            variant="outlined"
            value={props.employeeData.subcontratista}
            className={classes.textFieldCustom}
            onClick={() => props.setOpendialog(!props.openDialog)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <FontAwesomeIcon
                    icon={faCaretDown}
                    className={classes.iconForm}
                    style={{
                      width: "24px",
                      height: "24px",
                      color: "#ADADAD",
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          className={classes.gridMargin}
        >
          <Typography className={classes.textFieldLetter}>Obra</Typography>
          <TextField
            placeholder="Obligatorio"
            name="proyectoObra"
            size="small"
            variant="outlined"
            value={props.employeeData.proyectoObra}
            className={classes.textFieldCustom}
            onClick={() => props.setOpendialogSite(!props.openDialogSite)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <FontAwesomeIcon
                    icon={faCaretDown}
                    className={classes.iconForm}
                    style={{
                      width: "24px",
                      height: "24px",
                      color: "#ADADAD",
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Typography className={classes.textFieldLetter}>
            Número de empleado
          </Typography>
          <TextField
            placeholder={
              user &&
              user?.EnterpriseData?.Db == "GrupoGarzaPonce903228" &&
              "Obligatorio"
            }
            name="numeroEmpleado"
            size="small"
            variant="outlined"
            value={props.employeeData.numeroEmpleado}
            className={classes.textFieldCustom}
            onChange={handleChange}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          className={classes.gridMargin}
        >
          <Typography className={classes.textFieldLetter}>N°IMSS</Typography>
          <TextField
            placeholder="Obligatorio"
            type="number"
            name="imss"
            size="small"
            variant="outlined"
            // disabled={
            //   localStorage.getItem("idEmployee") == ""
            //     ? false
            //     : props.employeeData.estatusImss.estatusActual ==
            //       "Información Incorrecta"
            //     ? false
            //     : true
            // }
            value={props.employeeData.imss}
            className={classes.textFieldCustom}
            onChange={handleChange}
            onInput={(e) => {
              e.target.value = e.target.value.slice(0, 11);
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          className={classes.gridMargin}
        >
          <Typography className={classes.textFieldLetter}>CURP</Typography>
          <TextField
            placeholder={
              user?.EnterpriseData?.Db != "Dicoma680603" && "Obligatorio"
            }
            name="curp"
            size="small"
            variant="outlined"
            // disabled={
            //   localStorage.getItem("idEmployee") == ""
            //     ? false
            //     : props.employeeData.estatusImss.estatusActual ==
            //       "Información Incorrecta"
            //     ? false
            //     : true
            // }
            value={props.employeeData.curp.toUpperCase()}
            className={classes.textFieldCustom}
            onChange={handleChange}
            inputProps={{
              maxLength: 18,
              style: {
                textTransform: props.employeeData.curp != "" && "uppercase",
              },
            }}
          />
        </Grid>
        {props.employeeData.subcontratista ==
          user?.Subcontractor[0].empresa && (
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            className={classes.gridMargin}
          >
            <Typography className={classes.textFieldLetter}>Unidad</Typography>
            <TextField
              name="unidad"
              size="small"
              variant="outlined"
              value={props.employeeData.unidad}
              className={classes.textFieldCustom}
              onClick={() => props.setOpenDialogUnits(!props.openDialogUnits)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <FontAwesomeIcon
                      icon={faCaretDown}
                      className={classes.iconForm}
                      style={{
                        width: "24px",
                        height: "24px",
                        color: "#ADADAD",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
