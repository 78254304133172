/*eslint-disable*/
import React, { useEffect } from "react";
import DataGrid from "../../../common_components/Filters/DataGrid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { makeStyles } from "@material-ui/styles";
import { Grid, Typography, Checkbox } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .header": {
      color: "white",
      fontFamily: "Roboto",
      fontSize: 18,
      fontWeight: 500,
      display: "flex",
    },
  },
  photo: {
    width: 40,
    height: 40,
    borderRadius: 8,
    margin: "auto",
    aspectRatio: 1,
    objectFit: "cover",
  },
}));

export default function DataGridVideos(props) {
  const classes = useStyles();
  const check = true;

  const columns = [
    {
      field: "order",
      headerName: "Orden de compra",
      headerAlign: "center",
      flex: 0.7,
      // sortable: false,
      headerClassName: "header",
      //   renderCell: (params) => {
      //     return (
      //       <Grid container direction="row" justifyContent="center">
      //         <Checkbox
      //           size="small"
      //           disabled={rowData[key] ? false : true}
      //           style={{
      //             color: check ? "#d233ff" : "#cecece",
      //           }}
      //           checked={check}
      //           onChange={(e) => handleChange(e, rowData.name, key)}
      //         />
      //       </Grid>
      //     );
      //   },
    },
    {
      field: "Key",
      headerName: "Nombre",
      headerAlign: "center",
      flex: 1.2,
      // sortable: false,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <FontAwesomeIcon
              icon={faFile}
              style={{
                width: "18px",
                height: "18px",
                color: "#2E9AFE",
                marginRight: 5,
              }}
            />
            <Typography style={{}}>{params.row?.Key.slice(0, -4)}</Typography>
          </Grid>
        );
      },
    },
    {
      field: "Type",
      headerName: "Tipo",
      headerAlign: "center",
      flex: 0.3,
      sortable: false,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          <div style={{ margin: "auto" }}>{params.row?.Key.substr(-3)}</div>
        );
      },
    },
    {
      field: "size",
      headerName: "Tamaño",
      headerAlign: "center",
      flex: 0.5,
      // sortable: false,
      headerClassName: "header",
      renderCell: (params) => {
        return <div style={{ margin: "auto" }}>{params.row?.size}</div>;
      },
    },
    {
      field: "dateString",
      headerName: "Fecha",
      headerAlign: "center",
      flex: 0.7,
      // sortable: false,
      headerClassName: "header",
      renderCell: (params) => {
        return <div style={{ margin: "auto" }}>{params.row?.dateString}</div>;
      },
    },
    {
      field: "cameraName",
      headerName: "Mesa",
      headerAlign: "center",
      flex: 0.4,
      // sortable: false,
      headerClassName: "header",
      renderCell: (params) => {
        return <div style={{ margin: "auto" }}>{params.row?.cameraName}</div>;
      },
    },
    // {
    //   field: "Size",
    //   headerName: "Tamaño",
    //   headerAlign: "center",
    //   flex: 0.5,
    //   sortable: false,
    //   headerClassName: "header",
    //   renderCell: (params) => {
    //     return <div style={{ margin: "auto" }}>{params.row.Size + " mb"}</div>;
    //   },
    // },
    // {
    //   field: "StorageClass",
    //   headerName: "Clase",
    //   headerAlign: "center",
    //   flex: 0.5,
    //   sortable: false,
    //   headerClassName: "header",
    //   renderCell: (params) => {
    //     let date = new Date(params.row.timestamp).toLocaleString();
    //     return <div style={{ margin: "auto" }}>{date.split(" ")[0]}</div>;
    //   },
    // },
    // {
    //   field: "tipoEvento",
    //   headerName: "Tipo de evento",
    //   headerAlign: "center",
    //   flex: 0.8,
    //   sortable: false,
    //   headerClassName: "header",
    //   renderCell: (params) => {
    //     return (
    //       <Grid container direction="column" justifyContent="center">
    //         <Grid
    //           container
    //           direction="row"
    //           justifyContent="center"
    //           alignItems="center"
    //         >
    //           <img
    //             src={params.row.catalogos[0]?.iconUrl}
    //             style={{
    //               width: 14,
    //               height: 16,
    //               marginRight: 8,
    //             }}
    //           />
    //           <Typography
    //             style={{
    //               fontFamily: "Roboto",
    //               fontSize: 14,
    //               lineHeight: 1.36,
    //               textAlign: "left",
    //               color: "#fff",
    //             }}
    //           >
    //             {params.row.tipoEvento}
    //           </Typography>
    //         </Grid>

    //         {params.row.status == "manual assistance" ? (
    //           <Grid
    //             container
    //             direction="row"
    //             justifyContent="center"
    //             alignItems="center"
    //             style={{
    //               marginTop: 2,
    //             }}
    //           >
    //             <FontAwesomeIcon
    //               icon={faUserEdit}
    //               style={{
    //                 width: "12.5px",
    //                 height: "10px",
    //                 color: "#d233ff",
    //                 marginRight: 5,
    //                 marginBottom: 2,
    //               }}
    //             />
    //             <Typography
    //               style={{
    //                 fontFamily: "Roboto",
    //                 fontSize: 12,
    //                 lineHeight: 1.33,
    //                 textAlign: "left",
    //                 color: "#acacac",
    //               }}
    //             >
    //               Asistencia manual
    //             </Typography>
    //           </Grid>
    //         ) : null}
    //       </Grid>
    //     );
    //   },
    // },
  ];

  return (
    <DataGrid
      rows={props.rows}
      columns={columns}
      loading={props.loading}
      rowCount={props.rowCount}
      rowsPerPageOptions={props.rowsPerPageOptions}
      page={props.page}
      pageSize={props.pageSize}
      onPageChange={props.onPageChange}
      onPageSizeChange={props.onPageSizeChange}
      style={props.style}
      onSelectionModelChange={props.onSelectionModelChange}
      selectionModel={props.selectionModel}
      paginationMode={props.paginationMode}
    />
  );
}
