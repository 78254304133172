/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
// import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import DialogLoadingUploadDocuments from "./DialogLoadingUploadDocuments";
import { el } from "date-fns/locale";

// const useStyles = makeStyles((theme) => ({
//   textFieldLetter: {
//     fontFamily: "Roboto",
//     fontSize: 17,
//     fontWeight: "bold",
//     textAlign: "left",
//     color: "#cdcece",
//   },
//   textFieldCustom: {
//     width: "350px",
//     color: "#676767",
//     border: "solid 1px #c5c5c5",
//     borderRadius: "6px",
//     backgroundColor: "#ffffff",
//   },
//   divImg: {
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     width: "356px",
//     height: "290px",
//     borderRadius: "8px",
//     border: "solid 1px #707070",
//     backgroundColor: "#212429",
//     cursor: "pointer",
//   },
//   trackVertical: {
//     height: "95%",
//     right: 0,
//     width: "10px !important",
//   },
//   thumbVertical: {
//     backgroundColor: "#2d3034",
//     height: "100%",
//     right: 0,
//     width: "8px !important",
//     borderRadius: "8px",
//   },
// }));

export default function DialogBackScreen(props) {
  // const classes = useStyles();
  const history = useHistory();
  const { selectedObras } = useSelector((state) => state.obras);

  const handleOpenDialog = () => {
    props.setOpendialogBack(!props.openDialogBack);
  };

  const handleBackScreen = () => {
    props.setOpendialogBack(!props.openDialogBack);

    if (selectedObras) {
      history.push(`/dashboard/obras/${selectedObras?._id}`);
    } else {
      history.push(`/dashboard/obras`);
    }
  };

  const updateObra = async () => {
    props.setOpendialogBack(!props.openDialogBack);

    if (!(await props.saveObra())) {
      if (selectedObras) {
        history.push(`/dashboard/obras/${selectedObras?._id}`);
      } else {
        history.push(`/dashboard/obras`);
      }
    }
  };

  return (
    <div>
      <Dialog
        open={props.openDialogBack}
        onClose={handleOpenDialog}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            width: "490px",
            height: "320px",
            paddingTop: 10,
            textColor: "#fsf",
            backgroundColor: "#171a1e",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 18,
                textAlign: "center",
                color: "#fff",
                fontWeight: "bold",
              }}
            >
              ¿Estas seguro de salir sin guardar cambios?
            </Typography>

            <Grid
              container
              justifyContent="flex-end"
              style={{ position: "absolute" }}
            >
              <Button onClick={handleOpenDialog}>
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{
                    width: "20px",
                    height: "20px",
                    color: "#fff",
                  }}
                />
              </Button>
            </Grid>
          </Grid>
          <div
            style={{
              width: "100%",
              height: 1,
              background: "#707070",
              marginTop: 8,
              padding: 0,
            }}
          ></div>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{
              marginTop: "30px",
            }}
          >
            <Button
              onClick={updateObra}
              style={{
                width: "224px",
                height: "48px",
                borderRadius: "8px",
                backgroundColor: "#ac0bce",
                textTransform: "none",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 18,
                  textAlign: "left",
                  color: "#fff",
                }}
              >
                Guardar cambios
              </Typography>
            </Button>

            <Grid
              style={{
                height: "30px",
              }}
            />

            <Button
              onClick={handleBackScreen}
              style={{
                width: "224px",
                height: "48px",
                borderRadius: "8px",
                backgroundColor: "#8f8f8f",
                textTransform: "none",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 20,
                  textAlign: "left",
                  color: "#fff",
                }}
              >
                Salir
              </Typography>
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>

      <DialogLoadingUploadDocuments type="proyectos" />
    </div>
  );
}
