/*eslint-disable*/
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  divMain: {
    width: "18px",
    height: "18px",
    marginRight: 3,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "4px",
    // overflow: "hidden",
    // backgroundColor: "blue",
  },
  photo: {
    width: "100%",
    height: "100%",
    // objectFit: "contain",
    borderRadius: "4px",
  },
}));

export default function PhotoEmployee(props) {
  const classes = useStyles();

  return (
    <div className={classes.divMain}>
      <img
        src={props.employeesPhoto}
        className={classes.photo}
        alt="techton logo"
      />
    </div>
  );
}
