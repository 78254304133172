/*eslint-disable*/
import React from "react";
import { Grid, TextField, Popover } from "@material-ui/core";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { makeStyles } from "@material-ui/styles";
import { DateRangePicker } from "react-date-range";
import { defaultStaticRanges } from "react-date-range/dist/defaultRanges";
import InputAdornment from "@material-ui/core/InputAdornment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { es } from "date-fns/locale";
import "./styles.css";
import "./default.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "288px",
    borderRadius: "6px",
    backgroundColor: theme.palette.primary.light,
    "& .MuiInputBase-input": {
      color: theme.palette.neutral.primary,
      textAlign: "center !important",
    },
  },
  divIconCalendar: {
    background: theme.palette.primary.light,
    borderBottomLeftRadius: 6,
    borderTopLeftRadius: 6,
    marginLeft: "-14px",
    padding: "10px 13px",
    width: "43px",
  },
}));

export default function DateRange(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  function getMonday(last) {
    const date = !last ? new Date() : new Date(new Date().setDate(-7));
    var day = date.getDay(),
      diff = date.getDate() - day + (day == 0 ? -6 : 1);
    return new Date(date.setDate(diff));
  }

  function getSunday(last) {
    const date = !last ? new Date() : new Date(new Date().setDate(-7));
    const today = date.getDate();
    const dayOfTheWeek = date.getDay();
    const newDate = date.setDate(today - dayOfTheWeek + 7);
    return new Date(newDate);
  }

  const MONTHS = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  const staticRangesLabels = {
    Today: "Hoy",
    Yesterday: "Ayer",
    "This Week": "Esta semana",
    "Last Week": "Anterior semana",
    "This Month": "Este mes",
    "Last Month": "Último mes",
  };

  function translateRange(dictionary) {
    return (item) =>
      dictionary[item.label]
        ? { ...item, label: dictionary[item.label] }
        : item;
  }

  const setDefaultWeeks = () => {
    defaultStaticRanges.map((staticRange) => {
      if (staticRange.label == "This Week") {
        staticRange.range = () => ({
          startDate: getMonday(),
          endDate: getSunday(),
        });
      }
      if (staticRange.label == "Last Week") {
        staticRange.range = () => ({
          startDate: getMonday(true),
          endDate: getSunday(true),
        });
      }
    });
  };

  const esStaticRanges = defaultStaticRanges.map(
    translateRange(staticRangesLabels)
  );

  if (props.InitialWeek) {
    setDefaultWeeks();
  }

  const formatDateToStringMMM = (date) => {
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    var formatted_date;
    formatted_date = day + "/";
    formatted_date += MONTHS[month].substr(0, 3) + "/";
    return formatted_date + year;
  };

  const formatDateDisplay = (ranges) => {
    var date1 = ranges?.startDate;
    var date2 = ranges?.endDate;
    if (!date1 || !date2) return "";
    return `${formatDateToStringMMM(date1)} - ${formatDateToStringMMM(date2)}`;
  };

  // const handleQueryChange = (e) => {
  //   props.setValue(e);
  // };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid container direction="row">
      <TextField
        name="fechaNacimiento"
        size="small"
        variant="outlined"
        // value={"28/09/2021 - 30/09/2021"}
        value={formatDateDisplay(props.value[0])}
        readOnly
        className={classes.root}
        // onChange={handleChange}
        onClick={handleClick}
        InputProps={{
          startAdornment: (
            <InputAdornment
              position="start"
              component="div"
              style={{ paddingLeft: "-14px" }}
            >
              <div className={classes.divIconCalendar}>
                <FontAwesomeIcon
                  icon={faCalendarAlt}
                  style={{
                    width: "17px",
                    height: "17px",
                    color: "#fff",
                  }}
                />
              </div>
            </InputAdornment>
          ),
        }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <DateRangePicker
          ranges={props.value}
          locale={es}
          staticRanges={esStaticRanges}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          direction="horizontal"
          onChange={props.onChange}
          inputRanges={[]}
        />
      </Popover>
    </Grid>
  );
}
