/*eslint-disable*/
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import {
  Grid,
  Button,
  TextField,
  Typography,
  InputAdornment,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft, faKey } from "@fortawesome/free-solid-svg-icons";
import { ipServer } from "../../../config/vars";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "../../../utils/axiosInstance";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Roboto",
    fontSize: 32,
    fontWeight: "bold",
    color: theme.palette.secondary.dark,
    marginTop: "1%",
  },
  subtitle: {
    fontFamily: "Roboto",
    fontSize: 21,
    color: theme.palette.secondary.main,
    marginTop: "2%",
  },
  textFieldLabel: {
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: "bold",
    color: "#e2e2e2",
    color: theme.palette.secondary.main,
  },
  divMain: {
    margin: theme.spacing(1),
  },
  textFieldCustom: {
    width: "100%",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& label.Mui-focused": {
      color: "#d233ff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "yellow",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#E6E6E6",
      },
      "&:hover fieldset": {
        borderColor: "#6C6C6C",
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  button: {
    width: "353px",
    height: "56px",
    marginTop: "20%",
    marginBottom: "10%",
    borderRadius: "8px",
    backgroundColor: theme.palette.primary.main,
    textTransform: "none",
  },
  typoButton: {
    fontFamily: "Roboto",
    fontSize: 21,
    fontWeight: 500,
    color: theme.palette.neutral.primary,
  },
}));

export default function JoinCompany(props) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const schema = Yup.object({
    code: Yup.string()
      .required("Favor de ingresar código de verificación")
      .min(6, "El código de verificación debe tener 6 digitos"),
  });

  const formik = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: schema,
  });

  const validateData = async () => {
    return await schema.isValid(formik.values);
  };

  const backStep = () => {
    props.setStep(1);
  };

  const onSubmit = async () => {
    const { code } = formik.values;

    if (!errorTextField(code)) return;

    axiosInstance()
      .post(`${ipServer}/api/v1/security/user/join/`, {
        Code: code.toString(),
      })
      .then((response) => {
        // console.log(response);
        enqueueSnackbar("Usuario agregado a la empresa correctamente", {
          variant: "success",
        });
        history.push("/dashboard/");
      })
      .catch((e) => {
        if (e.response.data) {
          console.log(e.response.data);
          if (e.response.data.errors == "Code not valid") {
            enqueueSnackbar("Favor de ingresar un codigo valido", {
              variant: "error",
            });
          }
        } else {
          console.log(e.response);
          enqueueSnackbar("Error al agregar usuario", {
            variant: "error",
          });
        }
      });
  };

  const errorTextField = (code) => {
    if (code == "") {
      enqueueSnackbar("Favor de ingresar los datos requeridos", {
        variant: "error",
      });
      return false;
    }
    return true;
  };

  return (
    <div className={classes.divMain}>
      <div onClick={backStep}>
        <FontAwesomeIcon
          icon={faArrowAltCircleLeft}
          style={{
            width: "27px",
            height: "27px",
            marginBottom: "20px",
            color: "#9D9D9D",
            cursor: "pointer",
          }}
        />
      </div>

      <Typography className={classes.title}>
        Unirme a equipo de trabajo
      </Typography>

      <Typography className={classes.subtitle}>
        Algún compañero de trabajo debe enviarte un código de invitación.
        {/* o puedes escanear el código de invitación utilizando nuestra aplicación
        disponible en */}{" "}
        {/* <a
          href={"https://knesysplus.com/"}
          target="_blank"
          style={{
            textDecoration: "none",
            color: "#d233ff",
            backgroundColor: "#2d3034",
          }}
        >
          Android{" "}
        </a>{" "}
        y{" "}
        <a
          href={"https://knesysplus.com/"}
          target="_blank"
          style={{
            textDecoration: "none",
            color: "#d233ff",
            backgroundColor: "#2d3034",
          }}
        >
          IOS
        </a> 
        .*/}
      </Typography>

      <Grid
        container
        direction="column"
        alignItems="center"
        style={{
          marginTop: "15%",
        }}
      >
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          style={{ width: "531px" }}
        >
          <Typography className={classes.textFieldLabel}>
            Código de invitación
          </Typography>
          <TextField
            id="code"
            type="number"
            variant="outlined"
            name="code"
            // autoComplete="code"
            // onChange={onChangeField}
            className={classes.textFieldCustom}
            value={formik.values.code}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 6);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon
                    icon={faKey}
                    style={{
                      width: "24px",
                      height: "24px",
                      color: "#ADADAD",
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />

          <Grid container direction="row" justifyContent="center">
            <Button
              fullWidth
              variant="contained"
              onClick={onSubmit}
              className={classes.button}
            >
              <Typography className={classes.typoButton}>Unirme</Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
