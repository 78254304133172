/*eslint-disable*/
import { useEffect, useState } from "react";
import axios from "axios";
import axiosInstance from "../../../../utils/axiosInstance";

export default function useBookSearch(query, pageNumber) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [books, setBooks] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setBooks([]);
  }, [query]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    var params = new URLSearchParams();
    params.append("limit", 10);
    params.append("page", pageNumber);
    params.append("lookup", "subcontratistas");
    params.append("propertydatefilter", "fecha_datetime");
    params.append(
      "initialDate",
      new Date(query.initialDate.setHours(0, 0, 0, 0))
    );
    params.append(
      "finalDate",
      new Date(query.finalDate.setHours(23, 59, 59, 999))
    );

    axiosInstance()
      .get("/api/v1/rules/wtobservaciones", {
        params,
        cancelToken: source.token,
      })
      .then((res) => {
        setBooks((prevBooks) => {
          return [...new Set([...prevBooks, ...res.data.data.Results])];
        });

        setHasMore(res.data.data.Results.length > 0);
        setLoading(false);
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;
        setError(true);
      });
    return () => source.cancel();
  }, [query, pageNumber]);

  return { loading, error, books, hasMore };
}
