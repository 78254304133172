/*eslint-disable*/
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, IconButton, Grid } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles((theme) => ({
  divMain: {
    width: 90,
    height: 22,
    // padding: theme.spacing(2),
    // borderRadius: 16,
    marginTop: 5,
    marginBottom: 4,
  },
  typo: {
    fontFamily: "Roboto",
    fontSize: 12,
    fontWeight: 500,
    // lineHeight: 1.36,
    textAlign: "left",
    color: "#aeaeae",
  },
}));
export default function Incrementer(props) {
  const classes = useStyles();
  const { available, quantity, onIncrementQuantity, onDecrementQuantity, min } =
    props;

  return (
    <div className={classes.divMain}>
      <Grid
        container
        // direction="row"
        // justifyContent="flex-start"
        // alignItems="center"
        //
      >
        <Grid
          item
          xs={4}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{
            border: "solid 1px #a9a9a9",
            backgroundColor: "#c2c2c2",
          }}
        >
          <FontAwesomeIcon
            icon={faMinus}
            style={{
              width: 9,
              height: 16,
              color: "#fff",
              cursor: "pointer",
            }}
          />
        </Grid>

        <Grid
          item
          xs={4}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{
            border: "solid 1px #a9a9a9",
            backgroundColor: "#fff",
          }}
        >
          <Typography className={classes.typo}>12</Typography>
        </Grid>

        <Grid
          item
          xs={4}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{
            border: "solid 1px #a9a9a9",
            backgroundColor: "#d233ff",
          }}
        >
          <FontAwesomeIcon
            icon={faPlus}
            style={{
              width: 9,
              height: 15,
              color: "#000",
              cursor: "pointer",
            }}
          />
        </Grid>
      </Grid>
    </div>

    // <Box
    //   sx={{
    //     py: 0.5,
    //     px: 0.75,
    //     border: 1,
    //     lineHeight: 0,
    //     display: "flex",
    //     alignItems: "center",
    //     borderRadius: 1,
    //     borderColor: "solid 1px #a9a9a9",
    //     backgroundColor: "#fff",
    //   }}
    // >
    //   <IconButton
    //     size="small"
    //     color="inherit"
    //     // disabled={quantity <= min ?? 1}
    //     onClick={onDecrementQuantity}
    //     style={{
    //       width: "33%",
    //     }}
    //   >
    //     -
    //   </IconButton>

    //   <Typography
    //     variant="body2"
    //     component="span"
    //     sx={{ width: "33%", textAlign: "center" }}
    //   >
    //     {quantity}
    //   </Typography>

    //   <IconButton
    //     size="small"
    //     color="inherit"
    //     // disabled={quantity >= available}
    //     onClick={onIncrementQuantity}
    //     style={{
    //       width: "33%",
    //     }}
    //   >
    //     +
    //   </IconButton>
    // </Box>
  );
}
