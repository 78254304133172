/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { Grid, Typography, Button, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import Incrementer from "../../../../common_components/Incrementer";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "95%",
    height: "94px",
    padding: theme.spacing(2),
    borderRadius: 16,
    backgroundColor: "#2d3034",
    marginBottom: 10,
    // "& .MuiOutlinedInput-input": {
    //   padding: "15px 14px",
    // },
    // [theme.breakpoints.down("md")]: {
    //   width: "360px",
    // },
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  },
  typoProduct: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.31,
    textAlign: "left",
    color: "#fff",
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxWidth: "100%",
  },
  typoDescription: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.36,
    textAlign: "left",
    color: "#aeaeae",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxWidth: "100%",
  },
  typoId: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.36,
    textAlign: "left",
    color: "#fff",
    marginRight: 10,
  },
  gridMargin: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
}));

const ProductCard = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      className={classes.card}
    >
      <Grid
        item
        xs={6}
        sm={6}
        md={4}
        lg={4}
        xl={4}
        container
        direction="column"
        justifyContent="flex-start"
      >
        <Typography className={classes.typoProduct}>
          Chaleco de seguridad
        </Typography>

        <Typography className={classes.typoDescription}>
          Chaleco de seguridad para cumplir con la Norma N-0523-ASJ
        </Typography>
      </Grid>

      <Grid
        item
        xs={6}
        sm={6}
        md={4}
        lg={4}
        xl={4}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.gridMargin}
      >
        <Typography className={classes.typoId}>Identificador</Typography>
        <Typography className={classes.typoDescription}>
          MI02939201920
        </Typography>
      </Grid>

      <Grid
        item
        xs={6}
        sm={6}
        md={3}
        lg={3}
        xl={3}
        container
        direction="column"
        justifyContent="center"
        // alignItems="center"
        className={classes.gridMargin}
      >
        <Typography className={classes.typoId}>Cantidad</Typography>
        <Incrementer
          quantity={12}
          // quantity={getQuantity(id, _inventory._id)}
          available={1}
          // available={
          //   _inventory?.stock +
          //   (maxEdit?.find((x) => x._id == _inventory?._id)?.stock ?? 0)
          // }
          onIncrementQuantity={() => {}}
          onDecrementQuantity={() => {}}
          // onIncrementQuantity={() => setQuantity(true, _inventory._id)}
          // onDecrementQuantity={() => setQuantity(false, _inventory._id)}
          min={0}
        />
        <Typography className={classes.typoDescription}>piezas</Typography>
      </Grid>

      <Grid
        item
        xs={6}
        sm={6}
        md={1}
        lg={1}
        xl={1}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.gridMargin}
      >
        <FontAwesomeIcon
          icon={faTrashAlt}
          style={{
            width: 21,
            height: 24,
            color: "#b7b7b7",
            cursor: "pointer",
          }}
        />
      </Grid>
    </Grid>
  );
};

export default ProductCard;
