/*eslint-disable*/
import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  Fragment,
} from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Button,
  Box,
  Menu,
  MenuItem,
} from "@material-ui/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleLeft,
  faBuilding,
  faFileExcel,
  faFilePdf,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import CardHistory from "./components/CardHistory";
import axiosInstance from "../../utils/axiosInstance";
import JsPdf from "jspdf";
import html2canvas from "html2canvas";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { CSVLink } from "react-csv";
import useHistoryEmployee from "./components/useHistoryEmployee";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    paddingLeft: "30px",
  },
  divBack: {
    cursor: "pointer",
    marginBottom: "20px",
  },
  back: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 500,
    textAlign: "left",
    color: "#ffffff",
  },
  noWrap: {
    textOverflow: "ellipsis",
    wordWrap: "anywhere",
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
  },
}));

export default function HistoryEmployeeScreen(props) {
  const classes = useStyles();
  const history = useHistory();
  const [employeeData, setEmployeeData] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [limit, setLimit] = useState(8);
  const [loadingExportPDF, setLoadingExportPDF] = useState(false);
  const [loadingExportExcel, setLoadingExportExcel] = useState(false);
  const [dataCSV, setDataCSV] = useState([]);

  const { books, hasMore, loading, error, total } = useHistoryEmployee(
    limit,
    pageNumber,
    localStorage.getItem("idEmployee")
  );

  const ITEM_HEIGHT = 48;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const ref = React.createRef();
  const csvLink = useRef();

  useEffect(() => {
    async function PDFexport() {
      if (loadingExportPDF && ref.current.childNodes.length > total) {
        setLoadingExportPDF(false);

        const source = ref || this.targetRef;
        const targetComponent = source.current || source;

        html2canvas(targetComponent, {
          logging: false,
          useCORS: true,
          scale: 1.1,
        })
          .then((canvas) => {
            var pdf = new JsPdf("p", "pt", "letter");

            for (var i = 0; i <= targetComponent.clientHeight / 980; i++) {
              var srcImg = canvas;
              var sX = 0;
              var sY = 980 * i;
              var sWidth = 900;
              var sHeight = 980;
              var dX = 0;
              var dY = 0;
              var dWidth = 900;
              var dHeight = 980;

              window.onePageCanvas = document.createElement("canvas");
              onePageCanvas.setAttribute("width", 900);
              onePageCanvas.setAttribute("height", 980);
              var ctx = onePageCanvas.getContext("2d");

              ctx.drawImage(
                srcImg,
                sX,
                sY,
                sWidth,
                sHeight,
                dX,
                dY,
                dWidth,
                dHeight
              );

              var canvasDataURL = onePageCanvas.toDataURL("image/png");
              var width = onePageCanvas.width;
              var height = onePageCanvas.clientHeight;

              if (i > 0) {
                pdf.addPage();
              }
              pdf.setPage(i + 1);
              pdf.addImage(
                canvasDataURL,
                "PNG",
                40,
                40,
                width * 0.67,
                height * 0.7
              );
            }
            pdf.save("Test.pdf");
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            }

            console.log(error);
          });
      }
    }

    PDFexport();
  }, [loadingExportPDF, ref]);

  useEffect(() => {
    if (loadingExportExcel && books.length >= total) {
      setLoadingExportExcel(false);
      let result = books.map((data, index) => ({
        Acción: data.Action,
        Usuario: data.User.Name,
        Email: data.User.Email,
        Fecha: data.Date,
      }));

      setDataCSV(result);

      setTimeout(() => {
        csvLink.current.link.click();
      }, 300);
    }
  }, [loadingExportExcel, books]);

  const handleCSV = async () => {
    handleClose();
    setLimit(0);
    setLoadingExportExcel(true);
  };

  const handleExportPDF = () => {
    handleClose();
    setLimit(0);
    setLoadingExportPDF(true);
  };

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const backStep = () => {
    history.push(`/dashboard/empleados/${localStorage.getItem("idEmployee")}`);
  };

  const getEmployee = async () => {
    await axiosInstance()
      .get(`/api/v1/rules/trabajadores/${localStorage.getItem("idEmployee")}`)
      .then((response) => {
        setEmployeeData(response.data.data[0]);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const renderHistory = () => {
    const listRender = books.map((history, index) => (
      <Grid
        item
        ref={lastBookElementRef}
        key={index}
        xs={12}
        style={{ marginBottom: 16 }}
      >
        <CardHistory data={history} />
      </Grid>
    ));

    return listRender;
  };

  useEffect(() => {
    getEmployee();
  }, []);

  return (
    <Fragment>
      <div className={classes.divMain}>
        <Grid
          container
          direction="row"
          alignItems="center"
          onClick={backStep}
          className={classes.divBack}
        >
          <FontAwesomeIcon
            icon={faArrowAltCircleLeft}
            style={{
              width: "20px",
              height: "20px",
              marginRight: "10px",
              color: "#d6d6d6",
            }}
          />
          <Typography className={classes.back}>Regresar</Typography>
        </Grid>

        <Grid
          container
          direction="column"
          style={{
            width: 720,
            margin: "auto",
            marginBottom: 10,
          }}
        >
          <Grid container direction="row" justifyContent="flex-end">
            <Button
              onClick={handleClick}
              variant="contained"
              style={{
                width: 137,
                height: 32,
                borderRadius: 8,
                backgroundColor: "#d233ff",
                textTransform: "none",
              }}
            >
              <FileDownloadIcon style={{ color: "white" }} />
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 16,
                  textAlign: "left",
                  color: "#fff",
                  marginLeft: 4,
                }}
              >
                Exportar
              </Typography>
            </Button>
          </Grid>
        </Grid>

        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          open={openMenu}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: 137,
              backgroundColor: "#212429",
              borderRadius: 4,
            },
          }}
        >
          <MenuItem
            key={"DescargarPDF"}
            style={{ color: "white" }}
            onClick={handleExportPDF}
          >
            <FontAwesomeIcon
              icon={faFilePdf}
              style={{
                width: 12,
                height: 16,
                color: "#fff",
              }}
            />

            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 14,
                textAlign: "left",
                color: "#fff",
                marginLeft: 8,
                fontWeight: 500,
              }}
            >
              Archivo .PDF
            </Typography>
          </MenuItem>

          <MenuItem
            key={"Descargar"}
            style={{ color: "white" }}
            onClick={handleCSV}
          >
            <FontAwesomeIcon
              icon={faFileExcel}
              style={{
                width: 12,
                height: 16,
                color: "#fff",
              }}
            />

            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 14,
                textAlign: "left",
                color: "#fff",
                marginLeft: 8,
                fontWeight: 500,
              }}
            >
              Archivo .XCL
            </Typography>
          </MenuItem>
        </Menu>
        <CSVLink
          data={dataCSV}
          filename="data.csv"
          className="hidden"
          ref={csvLink}
          target="_blank"
        />
        <Grid
          ref={ref}
          container
          direction="column"
          style={{
            width: 720,
            minHeight: "82vh",
            margin: "auto",
          }}
        >
          <Grid item>
            <Grid
              container
              direction="row"
              alignItems="center"
              style={{
                backgroundColor: "#2d3034",
                borderRadius: 8,
                padding: 20,
              }}
            >
              <Grid item xs={2}>
                <img
                  src={employeeData?.foto}
                  style={{
                    width: 70,
                    height: 70,
                    borderRadius: 8,
                    aspectRatio: 1,
                    objectFit: "cover",
                  }}
                  alt="photo"
                />
              </Grid>

              <Grid
                item
                xs={4}
                container
                direction="column"
                style={{ marginLeft: -30 }}
              >
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 16,
                    textAlign: "left",
                    color: "#fff",
                    fontWeight: 500,
                  }}
                >
                  {employeeData?.nombre}
                </Typography>

                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 14,
                    textAlign: "left",
                    color: "#acacac",
                    marginTop: 8,
                  }}
                >
                  {employeeData?.puesto
                    ? employeeData?.puesto
                    : "Sin puesto asignado"}
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 14,
                    textAlign: "left",
                    color: "#fff",
                    fontWeight: 500,
                  }}
                >
                  {employeeData?.subcontratistas[0]
                    ? employeeData?.subcontratistas[0]?.empresa
                    : ""}
                </Typography>
              </Grid>

              <Grid
                item
                xs={6}
                container
                direction="column"
                style={{ marginTop: 5 }}
              >
                <Grid container direction="row">
                  <FontAwesomeIcon
                    icon={faBuilding}
                    style={{
                      width: 12,
                      height: 14,
                      color: "#d233ff",
                      marginTop: 2,
                    }}
                  />
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 14,
                      textAlign: "left",
                      color: "#fff",
                      marginLeft: 8,
                    }}
                  >
                    {employeeData?.proyectos[0]
                      ? employeeData?.proyectos[0].proyecto
                      : ""}
                  </Typography>
                </Grid>

                <Grid container direction="row" style={{ marginTop: 4 }}>
                  <FontAwesomeIcon
                    icon={faMapMarkerAlt}
                    style={{
                      width: 11,
                      height: 16,
                      color: "#d233ff",
                      marginTop: 2,
                    }}
                  />
                  <Typography
                    className={classes.noWrap}
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 14,
                      textAlign: "left",
                      color: "#fff",
                      marginLeft: 8,
                      width: "90%",
                    }}
                  >
                    {employeeData?.proyectos[0]
                      ? employeeData?.proyectos[0].domicilio
                      : ""}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item style={{ marginTop: 30 }}>
            {/* agregar boton de exportar */}
            <Grid container direction="row">
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 32,
                  textAlign: "left",
                  color: loadingExportPDF ? "black" : "#fff",
                  fontWeight: 500,
                }}
              >
                Historial
              </Typography>
            </Grid>
          </Grid>

          {books?.length > 0 && renderHistory()}
        </Grid>
      </div>
    </Fragment>
  );
}
