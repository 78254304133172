/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ipServer } from "../../../../config/vars";
import axios from "axios";
// import { MultiSelect } from "react-multi-select-component";
import MultiSelect from "../../../../common_components/Filters/MutiSelect";

const useStyles = makeStyles((theme) => ({
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: "bold",
    textAlign: "left",
    color: "#cdcece",
  },
  textFieldCustom: {
    width: "276px",
    color: "#676767",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
  },
  multi: {
    color: "white",
    padding: 2,
    border: 0,
    borderRadius: "8px",
    width: 276,
    maxWidth: 276,

    "&.rmsc .dropdown-heading ": {
      backgroundColor: "white",
      color: "#676767",
      borderRadius: 5,
    },
  },
}));

export default function DialogEditObra(props) {
  const classes = useStyles();
  const { setOpendialog, openDialog } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [proyectos, setProyectos] = useState(null);
  const [subcontractorData, setSubcontractorData] = useState({
    obras: [],
  });

  const handleInvite = () => {
    setOpendialog(!openDialog);
  };

  const deleteSubcontractor = async (subcontractorId, oldProjects) => {
    var subcontratista = [];
    subcontratista.push(subcontractorId);

    return await axios
      .delete(`${ipServer}/api/v1/rules/assing`, {
        headers: {
          Authorization: localStorage.getItem("JWT"),
        },
        data: {
          proyectos: oldProjects,
          subcontratistas: subcontratista,
        },
      })
      .then((response) => {
        console.log("assign", response);

        //validar respuesta
        return true;
      })
      .catch((e) => {
        console.log(e.response);
        return false;
      });
  };

  const deleteSubcontractorUsers = async (subcontractorId, oldProjects) => {
    return await axios
      .post(
        `${ipServer}/api/v1/security/user/unassign/`,
        {
          Collection: "Users",
          Match: {
            Name: "Subcontractor_id",
            Value: subcontractorId,
          },
          ItemsToRemove: {
            Name: "Obras_id",
            Values: oldProjects,
          },
        },
        {
          headers: {
            Authorization: localStorage.getItem("JWT"),
          },
        }
      )
      .then((response) => {
        console.log("assign", response.data);
        //validar respuesta
        return true;
      })
      .catch((e) => {
        console.log(e.response);
        return false;
      });
  };

  const assignSubcontractor = async (subcontractorId, projects) => {
    var subcontratista = [];
    subcontratista.push(subcontractorId);

    return await axios
      .post(
        `${ipServer}/api/v1/rules/assing`,
        {
          proyectos: projects,
          subcontratistas: subcontratista,
        },
        {
          headers: {
            Authorization: localStorage.getItem("JWT"),
          },
        }
      )
      .then((response) => {
        console.log("assign", response);

        //validar respuesta
        return true;
      })
      .catch((e) => {
        console.log(e);
        return false;
      });
  };

  const updateSubcontractor = async () => {
    let projects = [];

    subcontractorData.obras.map((subcontractor, index) => {
      projects.push(subcontractor.value);
    });

    // let projects = [];
    // subcontractorData.obras.map((subcontractor, index) => {
    //   projects.push(subcontractor.value);
    // });

    let newProjects = subcontractorData.obras.map((id) => id.value);
    let oldProjects = [];

    props.data.map(async (oldProject) => {
      if (!newProjects.includes(oldProject)) oldProjects.push(oldProject);
    });

    if (
      !(await deleteSubcontractor(
        props.subcontractorData.subcontractorId,
        oldProjects
      ))
    ) {
      enqueueSnackbar("Error al actualizar subcontratista", {
        variant: "error",
      });
      return;
    }

    if (
      !(await deleteSubcontractorUsers(
        props.subcontractorData.subcontractorId,
        oldProjects
      ))
    ) {
      enqueueSnackbar("Error al desasignar usuarios", {
        variant: "error",
      });
      return;
    }

    if (
      !(await assignSubcontractor(
        props.subcontractorData.subcontractorId,
        projects
      ))
    ) {
      enqueueSnackbar("Error al actualizar subcontratista", {
        variant: "error",
      });
      return;
    }

    setOpendialog(!openDialog);
    enqueueSnackbar("Subcontratista actualizado correctamente", {
      variant: "success",
    });
    setTimeout(() => {
      location.reload();
    }, 1000);
  };

  const handleQueryChange = (queryElement) => (e) => {
    const value = queryElement === "obras" ? e : e.target.value;
    return setSubcontractorData((prevQuery) => {
      return { ...prevQuery, ...{ [queryElement]: value } };
    });
  };

  const getProyectsList = () => {
    axios
      .get(`${ipServer}/api/v1/rules/miempresa/comboboxes/obras`, {
        headers: { Authorization: localStorage.getItem("JWT") },
      })
      .then((response) => {
        const proyects = response.data.data.map((object) => {
          return { label: object.proyecto, value: object._id };
        });

        setProyectos(proyects);

        setSubcontractorData({
          ...subcontractorData,
          obras: proyects.filter((o1) =>
            props.data?.some((o2) => o1.value === o2)
          ),
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (props.data != null) {
      getProyectsList();
    }
  }, [props.data, openDialog]);

  return (
    <Dialog
      open={openDialog}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        style: {
          backgroundColor: "#171a1e",
          textColor: "#fsf",
          width: "483px",
          height: "370px",
          paddingTop: 15,
        },
      }}
    >
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 20,
              textAlign: "center",
              color: "#fff",
              fontWeight: "bold",
            }}
          >
            Obras asignadas
          </Typography>

          <Grid
            container
            justifyContent="flex-end"
            style={{ position: "absolute" }}
          >
            <Button onClick={handleInvite}>
              <FontAwesomeIcon
                icon={faTimes}
                style={{
                  width: "20px",
                  height: "20px",
                  color: "#fff",
                }}
              />
            </Button>
          </Grid>
        </Grid>
        <div
          style={{
            width: "100%",
            height: 1,
            background: "#707070",
            marginTop: 8,
            padding: 0,
          }}
        ></div>
      </DialogTitle>

      <DialogContent>
        <Grid
          container
          direction="column"
          alignItems="center"
          // justifyContent="center"
        >
          <Grid
            item
            container
            direction="row"
            justifyContent="flex-start"
            style={{ width: "63%" }}
          >
            <Typography
              className={classes.textFieldLetter}
              style={{ marginTop: 30 }}
            >
              Obras asignadas
            </Typography>
          </Grid>

          <MultiSelect
            options={proyectos ? proyectos : []}
            value={subcontractorData?.obras}
            onChange={handleQueryChange("obras")}
            className={classes.multi}
          />

          <Button
            onClick={updateSubcontractor}
            style={{
              width: "276px",
              height: "40px",
              borderRadius: "8px",
              color: "#ffffff",
              backgroundColor: "#ac0bce",
              textTransform: "none",
              marginTop: 50,
            }}
          >
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 21,
                color: "#ffffff",
              }}
            >
              Actualizar
            </Typography>
          </Button>
        </Grid>

        {/* <Grid container justifyContent="flex-end">
          <Button
            onClick={handleInvite}
            style={{
              textTransform: "none",
            }}
          >
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 20,
                color: "#fff",
                fontWeight: "bold",
                marginTop: 50,
              }}
            >
              Cerrar
            </Typography>
          </Button>
        </Grid> */}
      </DialogContent>
    </Dialog>
  );
}
