/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  root: {
    fontFamily: "Roboto",
    fontSize: (props) => props.fontSize,
    fontWeight: (props) => props.fontWeight,
    fontStretch: (props) => props.fontStretch,
    fontStyle: (props) => props.fontStyle,
    lineHeight: (props) => props.lineHeight,
    letterSpacing: (props) => props.lineHeight,
    textAlign: (props) => props.textAlign,
    textOverflow: (props) => props.textOverflow,
    whiteSpace: (props) => props.whiteSpace,
    overflow: (props) => props.overflow,
    maxWidth: (props) => props.maxWidth,
    display: (props) => props.display,
    WebkitBoxOrient: (props) => props.WebkitBoxOrient,
    WebkitLineClamp: (props) => props.WebkitLineClamp,
    color: (props) => props.color,
    marginTop: (props) => props.marginTop,
    marginLeft: (props) => props.marginLeft,
    marginRight: (props) => props.marginRight,
    marginBottom: (props) => props.marginBottom,
  },
});

export default function Typo(props) {
  const classes = useStyles(props);

  // useEffect(() => {
  //   console.log(props);
  // }, []);

  return <Typography className={classes.root}>{props.text}</Typography>;
}
