/*eslint-disable*/
import { useEffect, useState } from "react";
import axios from "axios";
import axiosInstance from "../../../utils/axiosInstance";

export default function useBookSearch(
  query,
  pageNumber,
  loadingData,
  proyectos,
  subcontratistas
) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [books, setBooks] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setBooks([]);
  }, [query]);

  useEffect(() => {
    if (loadingData) {
      setLoading(true);
      setError(false);
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      let Subcontratistas =
        query.Subcontratistas.length > 0
          ? query.Subcontratistas
          : subcontratistas;
      let Proyectos = query.Proyectos.length > 0 ? query.Proyectos : proyectos;

      var params = new URLSearchParams();
      params.append("limit", 8);
      params.append("page", pageNumber);
      params.append("lookup", "subcontratistas");
      params.append("lookup", "proyectos");
      params.append("lookup", "incidencia");
      params.append("fullName_concat", `["$nombre", " ", "$apellidos"]`);
      params.append("fullName_partial", query.Empleado);
      params.append("numeroEmpleado_partial", query.Empleado);

      Proyectos?.map((proyecto) => {
        params.append("proyectos_id", proyecto.value);
      });

      Subcontratistas?.map((subcontratista) => {
        params.append("subcontratistas_id", subcontratista.value);
      });

      query.Status.map((status) => {
        params.append("statusDemek_string", status.label);
      });

      query.EstatusImss.map((status) => {
        params.append("estatusImss.estatusActual_string", status.label);
      });

      axiosInstance()
        .get("/api/v1/rules/trabajadores/filter", {
          params,
          cancelToken: source.token,
        })
        .then((res) => {
          // console.log(res.data.data.Results);
          setBooks((prevBooks) => {
            return [...new Set([...prevBooks, ...res.data.data.Results])];
          });
          setHasMore(res.data.data.Results.length > 0);
          setLoading(false);
        })
        .catch((e) => {
          if (axios.isCancel(e)) return;
          setError(true);
        });
      return () => source.cancel();
    }
  }, [query, pageNumber, loadingData]);

  return { loading, error, books, hasMore };
}
