/*eslint-disable*/
import React from "react";
import { Button, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";

export default function ShowMoreButton(props) {
  return (
    <div>
      {!props.moreData ? (
        <Button
          style={{
            width: "200px",
            height: "44px",
            borderRadius: "8px",
            backgroundColor: "#8f8f8f",
            textTransform: "none",
          }}
          onClick={props.onClick}
        >
          <Typography
            style={{ fontFamily: "Roboto", fontSize: 18, color: "#fff" }}
          >
            Mostrar más
          </Typography>
        </Button>
      ) : (
        <Button
          style={{
            width: "200px",
            height: "44px",
            borderRadius: "8px",
            backgroundColor: "#2d3034",
            textTransform: "none",
          }}
          onClick={props.onClick}
        >
          <Typography
            style={{ fontFamily: "Roboto", fontSize: 18, color: "#fff" }}
          >
            Mostrar menos
          </Typography>

          <FontAwesomeIcon
            icon={faChevronUp}
            style={{
              width: 23,
              height: 14,
              color: "#fff",
              marginLeft: 6,
              marginTop: 4,
            }}
          />
        </Button>
      )}
    </div>
  );
}
