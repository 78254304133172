/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faTimes, faCog } from "@fortawesome/free-solid-svg-icons";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ScrollBars from "../../../../common_components/ScrollBars";
// import useUnitSearch from "../useUnitSearch";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Roboto",
    fontSize: 22,
    fontWeight: "bold",
    textAlign: "center",
    color: "#fff",
  },
  divDivider: {
    width: "100%",
    height: 1,
    background: "#707070",
    marginTop: 8,
    padding: 0,
  },
  textFieldCustom: {
    width: "100%",
    color: "#676767",
    border: "solid 1px #c5c5c5",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
  },
  // textFieldCustom2: {
  //   width: "100%",
  //   color: "#676767",
  //   border: "solid 1px #c5c5c5",
  //   borderRadius: "6px",
  //   backgroundColor: "#ffffff",
  // },
}));

export default function DialogList(props) {
  const classes = useStyles();
  const { user } = useSelector((state) => state.user);
  const { unitsFlag } = props;
  const [query, setQuery] = useState({
    Type: "unidad",
    Nombre: "",
  });
  // const { books, hasMore, loading, error } = useUnitSearch(query, unitsFlag);

  const handleOpenDialog = () => {
    props.setOpenDialogSupplier(!props.openDialogSupplier);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setQuery({
      ...query,
      [e.target.name]: value,
    });
  };

  const list = () =>
    props.list?.map((item, index) => {
      return (
        <Grid
          key={index}
          // onClick={() => {
          //   {
          //     props.formik
          //       ? props.formik.setFieldValue("unidad", item.name)
          //       : props.setData({
          //           ...props.data,
          //           unidad: item.name,
          //         });
          //   }
          //   props.setChangesFlag(true);
          //   props.setOpenDialogUnits(!props.openDialogUnits);
          // }}
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          style={{
            cursor: "pointer",
            paddingTop: "5%",
          }}
        >
          <Grid item xs={10}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 20,
                fontWeight: "bold",
                textAlign: "left",
                color: "#fff",
              }}
            >
              {item.name}
            </Typography>
          </Grid>

          <Grid
            item
            xs={2}
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <FontAwesomeIcon
              icon={faChevronRight}
              style={{
                width: "12px",
                height: "20px",
                color: "#fff",
              }}
            />
          </Grid>
        </Grid>
      );
    });

  return (
    <div>
      <Dialog
        open={props.openDialogSupplier}
        onClose={handleOpenDialog}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            backgroundColor: "#171a1e",
            textColor: "#fsf",
            width: props.width ?? 483,
            height: props.height ?? 600,
            paddingTop: 15,
          },
        }}
      >
        <DialogTitle>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Typography className={classes.title}>{props.title}</Typography>

            <Grid
              container
              justifyContent="flex-end"
              style={{ position: "absolute" }}
            >
              <Button onClick={handleOpenDialog}>
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{
                    width: "20px",
                    height: "20px",
                    color: "#fff",
                  }}
                />
              </Button>
            </Grid>
          </Grid>
          <div className={classes.divDivider} />
        </DialogTitle>

        <DialogContent>
          <Grid
            container
            direction="column"
            justifyContent="center"
            // alignItems="flex-end"
          >
            {/* {user?.Subcontractor[0]?.empresaPadre ? (
              <Grid
                container
                direction="row"
                // justifyContent="flex-end"
              >
                <Grid item xs={10}>
                  <TextField
                    placeholder="Buscar..."
                    name="Nombre"
                    size="small"
                    variant="outlined"
                    value={query?.Nombre}
                    className={classes.textFieldCustom2}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button
                    onClick={() => {
                      props.setOpenDialogUnits(!props.openDialogUnits);
                      props.setOpenDialogUnitsAdmin(
                        !props.openDialogUnitsAdmin
                      );
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faCog}
                      style={{
                        width: "30px",
                        height: "30px",
                        color: "#fff",
                      }}
                    />
                  </Button>
                </Grid>
              </Grid>
            ) : ( */}
            <Grid
              item
              xs={12}
              container
              direction="row"
              justifyContent="center"
            >
              <TextField
                placeholder="Buscar..."
                name="Nombre"
                size="small"
                variant="outlined"
                // value={query?.Nombre}
                className={classes.textFieldCustom}
                // onChange={handleChange}
              />
            </Grid>
            {/* )} */}

            <ScrollBars style={{ height: 410 }}>{list()}</ScrollBars>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
