/*eslint-disable*/
import React, { useState } from "react";
import useStyles from "../styles/form_login_styles";
import { useSnackbar } from "notistack";
import {
  TextField,
  Button,
  Typography,
  Grid,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useHistory, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { login } from "../../../actions/auth";
import axios from "axios";
import { ipServer } from "../../../config/vars";
import Logo from "../../../images/ABSA-INDUSTRIAL-LOGO-BLANCO.png";

export default function LoginComponents(props) {
  const history = useHistory();
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const schema = Yup.object({
    email: Yup.string().required("Favor de ingresar correo"),
    password: Yup.string().required("Favor de ingresar contraseña"),
  });

  const formik = useFormik({
    initialValues: {
      email: localStorage.getItem("userEmail") ?? "",
      password: "",
    },
    validationSchema: schema,
  });

  const validateData = async () => {
    return await schema.isValid(formik.values);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async () => {
    const { email, password } = formik.values;

    props.setEmail(email);
    props.setPassword(password);

    if (!(await validateData())) {
      formik.errors.email &&
        enqueueSnackbar(formik.errors.email, {
          variant: "error",
        });

      formik.errors.password &&
        enqueueSnackbar(formik.errors.password, {
          variant: "error",
        });
      return;
    }

    axios
      .post(`${ipServer}/api/v1/security/user/login/`, {
        Email: email,
        Password: password,
      })
      .then((response) => {
        // console.log("login", response.data.data);
        var exp = new Date();
        exp.setSeconds(exp.getSeconds() + response.data.data.expiresIn);
        localStorage.setItem("JWT", response.data.data.token);
        localStorage.setItem("token-exp", exp.getTime());
        localStorage.setItem("userEmail", response.data.data.user.Email);
        dispatch(
          login(
            response.data.data.user._id,
            response.data.data.user.Name,
            response.data.data.token
          )
        );
        if (response.data.data.user.Enterprise_id == null) {
          history.push("/bienvenido");
        } else {
          if (response.data.data.user.Status == "Invitación enviada") {
            history.push("/bienvenido");
          } else {
            // if (response.data.data.user.RoleName == "Almacenista")
            //   history.push("/dashboard/almacen");
            // if (
            //   response.data.data.user.RoleName == "Administrador Subcontratista"
            // ) {
            //   localStorage.setItem(
            //     "idSubcontractor",
            //     response.data.data.user.Subcontractor_id
            //   );
            //   history.push(
            //     `/dashboard/miempresa/${localStorage.getItem(
            //       "idSubcontractor"
            //     )}`
            //   );
            // } else
            history.push("/dashboard/");
          }
        }
      })
      .catch((e) => {
        if (e.response?.data) {
          console.log(e.response.data);
          if (e.response.data.errors == "User not active") {
            props.setStep(true);
          }
          if (e.response.data.errors == "Cuenta de usuario eliminada") {
            enqueueSnackbar("Usuario desactivado", {
              variant: "error",
            });
          }
          if (e.response.data.errors == "User not found") {
            enqueueSnackbar(
              "Usuario no encontrado, revisa tu email y contraseña",
              {
                variant: "error",
              }
            );
          }
          if (e.response.data.errors == "License not active") {
            enqueueSnackbar(
              "Licencia caducada, favor de obtener una nueva licencia",
              {
                variant: "error",
              }
            );
          }
          if (e.response.data.errors == "You entered the wrong password") {
            enqueueSnackbar(
              "Usuario no encontrado, revisa tu email y contraseña",
              {
                variant: "error",
              }
            );
          }
        } else {
          console.log(e.response);
          enqueueSnackbar("Error al iniciar sesion", {
            variant: "error",
          });
        }
      });
  };

  return (
    <Grid container spacing={5} style={{ width: "100%" }}>
      <Grid item xs={12}>
        <img src={Logo} className={classes.logo} alt="techton logo" />
      </Grid>

      <Grid item xs={12}>
        <Typography className={classes.title}>Bienvenido</Typography>
        <Typography className={classes.subtitle}>Inicia sesión</Typography>
      </Grid>

      <Grid item xs={12} container direction="row" justifyContent="flex-start">
        <Typography className={classes.textFieldLabel}>Email</Typography>
        <TextField
          id="email"
          type="email"
          name="email"
          size="medium"
          autoComplete="email"
          variant="outlined"
          fullWidth
          className={classes.textFieldCustom}
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              onSubmit();
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon
                  icon={faEnvelope}
                  style={{
                    width: 24,
                    height: 24,
                    color: "#9b9b9b",
                  }}
                />
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography className={classes.textFieldLabel}>Password</Typography>
        <TextField
          id="password"
          type={showPassword ? "text" : "password"}
          name="password"
          size="medium"
          autoComplete="current-password"
          variant="outlined"
          fullWidth
          className={classes.textFieldCustom}
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              onSubmit();
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon
                  icon={faLock}
                  style={{
                    width: 24,
                    height: 24,
                    color: "#9b9b9b",
                  }}
                />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="start">
                <IconButton
                  onClick={handleClickShowPassword}
                  style={{
                    width: 24,
                    height: 24,
                    color: "#9b9b9b",
                  }}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Grid container direction="row" justifyContent="flex-end">
          <Typography>
            <Link to="/recuperacion_contraseña" className={classes.typoLink}>
              ¿Olvidaste tu contraseña?
            </Link>
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Button
          fullWidth
          variant="contained"
          onClick={onSubmit}
          className={classes.button}
        >
          <Typography className={classes.typoButton}>Iniciar sesión</Typography>
        </Button>
      </Grid>

      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <div className={classes.divStyle} />
          <Typography className={classes.typoLabel}>
            ¿No tienes una cuenta aún?
          </Typography>
          <div className={classes.divStyle} />
        </Grid>

        <Grid container direction="row" justifyContent="center">
          <Button
            // variant="contained"
            onClick={() => history.push(`/registro`)}
            className={classes.typoLink2}
          >
            <Typography className={classes.typoButtonRegister}>
              Registrarse
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
