/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Checkbox, Typography } from "@material-ui/core";
import { Grid, TextField } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faPlus } from "@fortawesome/free-solid-svg-icons";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: "bold",
    lineHeight: 1.35,
    textAlign: "left",
    color: "#cdcece",
    marginBottom: 5,
  },
  textFieldCustom: {
    width: "100%",
    color: "#676767",
    border: "solid 1px #c5c5c5",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
  },
  formControl: {
    width: "100%",
  },
  select: {
    width: "100%",
    height: "40px",
    borderRadius: "6px",
    backgroundColor: "#fff",
  },
  button: {
    width: "320px",
    height: "48px",
    borderRadius: "8px",
    backgroundColor: "#ac0bce",
    textTransform: "none",
  },
  typoButton: {
    fontFamily: "Roboto",
    fontSize: 21,
    lineHeight: 1.33,
    textAlign: "left",
    color: "#fff",
  },
  typoCheckbox: {
    fontFamily: "Roboto",
    fontSize: 18,
    fontWeight: "bold",
    lineHeight: 1.33,
    textAlign: "left",
    color: "#cdcece",
  },
  checkbox: {
    // padding: 2,
    color: "#484848",
    "&.Mui-checked": {
      color: "#d233ff",
    },
    "& .MuiSvgIcon-root": { fontSize: 32 },
  },
  buttonAdd: {
    width: "100%",
    height: "48px",
    borderRadius: 8,
    border: "solid 2px #707070",
    borderStyle: "dashed",
    textTransform: "none",
    marginTop: 10,
  },
  gridMargin: {
    marginBottom: 12,
  },
}));

export default function DialogNewSection(props) {
  const classes = useStyles();
  const { user } = useSelector((state) => state.user);
  const [questionType, setQuestionType] = useState("Opción multiple");
  //   const { books, hasMore, loading, error } = useUnitSearch(query, unitsFlag);

  const handleOpenDialog = () => {
    props.setOpenDialogNewSection(!props.openDialogNewSection);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    // setQuery({
    //   ...query,
    //   [e.target.name]: value,
    // });
  };

  // useEffect(() => {
  //   console.log(questionType);
  // }, [questionType]);

  return (
    <div>
      <Dialog
        // open={true}
        open={props.openDialogNewSection}
        onClose={handleOpenDialog}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            backgroundColor: "#171a1e",
            textColor: "#fsf",
            width: "483px",
            height: "380px",
            padding: "15px 10px 0px 10px ",
            borderRadius: 8,
          },
        }}
      >
        <DialogTitle>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 22,
                textAlign: "center",
                color: "#fff",
                fontWeight: "bold",
              }}
            >
              Nueva sección
            </Typography>

            <Grid
              container
              justifyContent="flex-end"
              style={{ position: "absolute" }}
            >
              <Button onClick={handleOpenDialog}>
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{
                    width: "20px",
                    height: "20px",
                    color: "#fff",
                  }}
                />
              </Button>
            </Grid>
          </Grid>
          <div
            style={{
              width: "100%",
              height: 1,
              background: "#707070",
              marginTop: 8,
              padding: 0,
            }}
          ></div>
        </DialogTitle>

        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            //   style={{
            //     backgroundColor: "blue",
            //   }}
          >
            <Grid item xs={12}>
              <Typography className={classes.textFieldLetter}>
                Nombre de sección
              </Typography>
              <TextField
                placeholder="Buscar..."
                name="Nombre"
                size="small"
                variant="outlined"
                // value={query?.Nombre}
                className={classes.textFieldCustom}
                // onChange={handleChange}
              />
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-end"
            style={{
              marginTop: 50,
            }}
          >
            <Button variant="contained" className={classes.button}>
              <Typography className={classes.typoButton}>Guardar</Typography>
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
