/*eslint-disable*/
import { useEffect, useState } from "react";
import axios from "axios";
import axiosInstance from "../../../utils/axiosInstance";
import { useSelector } from "react-redux";

export default function useWorkingtimeDetail(query) {
  const { workingTime } = useSelector((state) => state.filters);
  const { workingTimeSelected } = useSelector((state) => state.workingTime);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const [dynamicColumns, setDynamicColumns] = useState([]);

  useEffect(() => {
    setRows([]);
  }, [query]);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    var params = new URLSearchParams();
    params.append("timezone", "America/Mexico_City");
    params.append("page", query.page - 1);
    params.append("limit", query.pageSize);
    params.append("subcontratista_string", workingTimeSelected?.enterprise);
    params.append("initialDate", workingTime?.initialDate);
    params.append("finalDate", workingTime?.finalDate);

    workingTime.proyectos?.map((proyecto) => {
      params.append("proyecto_string", proyecto.label);
    });

    setLoading(true);
    setError(false);

    axiosInstance()
      .get("/api/v1/rules/workingtime/detalle", {
        params,
        cancelToken: source.token,
      })
      .then((response) => {
        // console.log(response.data.data.Results);
        const data = response.data.data.Results;
        let result = [];
        let _dynamicColumns = [];

        data?.map((working, index) => {
          let object = {
            id: index,
            Name: working?.nombre,
            Hours: working?.totalsegundosPersona,
            // parseFloat(working?.totalsegundosPersona / 3600).toFixed(2),
            Puesto: working?.puesto,
            photo: working?.imageIndex,
          };

          working?.fechasWT?.map((_workingtime, index) => {
            const date = new Date(_workingtime.fecha);

            if (
              _dynamicColumns.indexOf(
                `Date_${date.getMonth()}_${date.getDate()}`
              ) == -1
            ) {
              _dynamicColumns.push(`Date_${date.getMonth()}_${date.getDate()}`);
            }

            object = {
              ...object,
              [`Date_${date.getMonth()}_${date.getDate()}`]: _workingtime,
            };
          });

          result.push(object);
        });

        setRows(result);
        setDynamicColumns(_dynamicColumns);
        setTotal(response.data.data.Total);
        setLoading(false);
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;
        setError(true);
      });
    return () => source.cancel();
  }, [query]);

  return { loading, error, rows, total, dynamicColumns };
}
