/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faArrowAltCircleLeft,
} from "@fortawesome/free-solid-svg-icons";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "axios";
import { ipServer } from "../../../config/vars";
import { CapitalizeString } from "../../../utils/CapitalizeString";

const useStyles = makeStyles((theme) => ({
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: "bold",
    textAlign: "left",
    color: "#cdcece",
    marginLeft: "18px",
  },
  textFieldCustom: {
    width: "100%",
    color: "#676767",
    border: "solid 1px #c5c5c5",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
  },
  divImg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "356px",
    height: "290px",
    borderRadius: "8px",
    border: "solid 1px #707070",
    backgroundColor: "#212429",
    cursor: "pointer",
  },
  divBack: {
    width: "15%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    // marginBottom: "20px",
    // backgroundColor: "blue",
  },
}));

export default function DialogAddNewIncident(props) {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleOpenDialog = () => {
    props.setOpenDialogAddNewIncident(!props.openDialogAddNewIncident);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    props.setIncident({
      ...props.incident,
      [e.target.name]: value,
    });
  };

  const backStep = () => {
    props.setOpenDialogAddNewIncident(!props.openDialogAddNewIncident);
    props.setOpenDialogIncidentsAdmin(!props.openDialogIncidentsAdmin);
  };

  const updateIncident = async (id) => {
    // console.log(props.incident);
    if (props.incident.id == "") {
      await addNewIncident();
    } else {
      await editIncident(id);
    }

    props.setIncidentFlag(!props.incidentFlag);
  };

  const addNewIncident = async () => {
    return await axios
      .post(
        `${ipServer}/api/v1/rules/catalogos`,
        {
          type: props.incident.type,
          name: props.incident.name,
        },
        {
          headers: {
            Authorization: localStorage.getItem("JWT"),
          },
        }
      )
      .then((response) => {
        // console.log(response.data);
        enqueueSnackbar("Incidencia agregada correctamente", {
          variant: "success",
        });
        props.setOpenDialogAddNewIncident(!props.openDialogAddNewIncident);
        return true;
      })
      .catch((e) => {
        enqueueSnackbar("Error al agregar Incidencia", {
          variant: "error",
        });
        console.log(e);
        return false;
      });
  };

  const editIncident = async (id) => {
    return await axios
      .patch(
        `${ipServer}/api/v1/rules/catalogos/${id}`,
        {
          type: props.incident.type,
          name: props.incident.name,
        },
        {
          headers: {
            Authorization: localStorage.getItem("JWT"),
          },
        }
      )
      .then((response) => {
        // console.log(response.data);
        enqueueSnackbar("Incidencia actualizada correctamente", {
          variant: "success",
        });
        props.setOpenDialogAddNewIncident(!props.openDialogAddNewIncident);
        return true;
      })
      .catch((e) => {
        enqueueSnackbar("Error al editar Incidencia", {
          variant: "error",
        });
        console.log(e);
        return false;
      });
  };

  return (
    <div>
      <Dialog
        open={props.openDialogAddNewIncident}
        onClose={handleOpenDialog}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            backgroundColor: "#171a1e",
            textColor: "#fsf",
            width: "483px",
            height: "600px",
            paddingTop: 15,
          },
        }}
      >
        <DialogTitle>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={1} onClick={backStep} className={classes.divBack}>
              <FontAwesomeIcon
                icon={faArrowAltCircleLeft}
                style={{
                  width: "20px",
                  height: "20px",
                  marginRight: "10px",
                  color: "#d6d6d6",
                }}
              />
            </Grid>

            <Grid item xs={9}>
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 22,
                  textAlign: "center",
                  color: "#fff",
                  fontWeight: "bold",
                }}
              >
                Administración de incidencias
              </Typography>
            </Grid>

            <Grid item xs={2}>
              <Button onClick={handleOpenDialog}>
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{
                    width: "20px",
                    height: "20px",
                    color: "#fff",
                  }}
                />
              </Button>
            </Grid>
          </Grid>
          <div
            style={{
              width: "100%",
              height: 1,
              background: "#707070",
              marginTop: 8,
              padding: 0,
            }}
          ></div>
        </DialogTitle>

        <DialogContent>
          <Grid container direction="row" justifyContent="center">
            <Grid
              item
              xs={12}
              style={{
                height: "100px",
              }}
            />

            <Grid
              item
              xs={12}
              container
              direction="row"
              justifyContent="center"
            >
              <Typography className={classes.textFieldLetter}>
                Incidencia
              </Typography>

              <TextField
                name="name"
                size="small"
                variant="outlined"
                value={CapitalizeString(props.incident.name)}
                className={classes.textFieldCustom}
                onChange={handleChange}
                inputProps={{
                  maxLength: 50,
                }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                height: "120px",
              }}
            />

            <Grid
              item
              xs={12}
              container
              direction="row"
              justifyContent="center"
            >
              <Button
                variant="contained"
                onClick={() => updateIncident(props.incident.id)}
                style={{
                  width: "320px",
                  height: "48px",
                  borderRadius: "8px",
                  backgroundColor: "#ac0bce",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 21,
                    textAlign: "left",
                    color: "#fff",
                    textTransform: "none",
                  }}
                >
                  Guardar
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
