/*eslint-disable*/
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { TextField, Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import CardObras from "./CardObras";
import useObrasSearch from "./useObrasSearch";
import CircularProgress from "@material-ui/core/CircularProgress";
import NotObrasFound from "./NotObrasFound";
import { useDispatch, useSelector } from "react-redux";
import { clearFiles } from "../../../actions/file";
import { FiltersProyects } from "../../../actions/filters";
import { SelectedObras } from "../../../actions/obras";
import { ValidatePermissions } from "../../../utils/permissions";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    padding: 15,
  },
  buttonSearch: {
    width: "232px",
    height: "40px",
    borderRadius: "8px",
    color: "#ffffff",
    backgroundColor: "#ac0bce",
    textTransform: "none",
    marginLeft: 30,
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
      marginLeft: 0,
    },
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 28,
    fontWeight: 600,
    textAlign: "left",
    color: "#ffffff",
    marginBottom: 25,
  },
  textFieldCustom: {
    width: "387px",
    color: "#676767",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
  },
}));

export default function SearchObrasScreen(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { proyects } = useSelector((state) => state.filters);
  const [pageNumber, setPageNumber] = useState(0);
  const [query, setQuery] = useState({
    Obras: proyects?.Obras ?? "",
  });

  const { rows, totalRows, loading, error, hasMore } = useObrasSearch(
    query,
    pageNumber,
    dispatch
  );

  const registerObras = () => {
    dispatch(clearFiles());
    dispatch(SelectedObras(null));
    localStorage.removeItem("idSite");
    history.push(`/dashboard/obras/crear`);
  };

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  useEffect(() => {
    setPageNumber(0);
    dispatch(FiltersProyects(query));
  }, [query]);

  return (
    <div className={classes.divMain}>
      <Typography className={classes.title}>Obras</Typography>
      <Grid container direction="row">
        <TextField
          variant="outlined"
          size="small"
          placeholder="Buscar por nombre de obra"
          value={query?.Obras}
          onChange={(e) => setQuery({ ...query, Obras: e.target.value })}
          className={classes.textFieldCustom}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon
                  icon={faSearch}
                  style={{
                    width: "18px",
                    height: "18px",
                    color: "#676767",
                  }}
                />
              </InputAdornment>
            ),
          }}
        />

        {ValidatePermissions("crear") ? (
          <Button
            variant="contained"
            className={classes.buttonSearch}
            onClick={registerObras}
          >
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 18,
                textAlign: "left",
                color: "#fff",
              }}
            >
              Registrar obra
            </Typography>
          </Button>
        ) : (
          <div></div>
        )}
      </Grid>

      <Box
        style={{ marginTop: 30 }}
        sx={{
          display: "grid",
          gap: 40,
          gridTemplateColumns: "repeat(auto-fill, minmax(330px, 1fr))",
        }}
      >
        {rows.length == 0 && !loading ? (
          <NotObrasFound />
        ) : (
          rows.map((obra, index) => (
            <Grid ref={lastBookElementRef} key={index}>
              <CardObras setStep={props.setStep} data={obra} />
            </Grid>
          ))
        )}
      </Box>

      <Box
        display="flex"
        m={1}
        p={1}
        alignItems="center"
        justifyContent="center"
      >
        {loading && (
          <CircularProgress disableShrink size={100} color="secondary" />
        )}
      </Box>
    </div>
  );
}
