/*eslint-disable*/
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button } from "@material-ui/core";
import Lottie from "react-lottie";
import animationCompany from "../../../animations/animation_company_dark_mode.json";

const useStyles = makeStyles((theme) => ({
  div: {
    width: "100%",
    height: "100%",
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 32,
    fontWeight: "bold",
    color: theme.palette.secondary.dark,
  },
  subtitle: {
    fontFamily: "Roboto",
    fontSize: 21,
    color: theme.palette.secondary.main,
    marginTop: "2%",
  },
  buttonCreate: {
    width: "353px",
    height: "53px",
    borderRadius: "8px",
    backgroundColor: theme.palette.primary.main,
    textTransform: "none",
  },
  typoButtonCreate: {
    fontFamily: "Roboto",
    fontSize: 21,
    fontWeight: 500,
    color: theme.palette.neutral.primary,
  },
  buttonJoin: {
    width: "353px",
    height: "53px",
    borderRadius: "8px",
    backgroundColor: theme.palette.primary.main,
    textTransform: "none",
  },
  typoButtonJoin: {
    fontFamily: "Roboto",
    fontSize: 21,
    fontWeight: 500,
    color: theme.palette.neutral.primary,
  },
}));

export default function WelcomeScreen(props) {
  const classes = useStyles();

  const createCompany = () => {
    props.setStep(2);
  };

  const joinCompany = () => {
    props.setStep(3);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationCompany,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className={classes.div}>
      <Typography className={classes.title}>¡Bienvenido!</Typography>
      <Typography className={classes.subtitle}>
        El siguiente paso es unirte a un equipo de trabajo.
      </Typography>
      <Lottie
        options={defaultOptions}
        width={430}
        height={335}
        style={{
          marginTop: "4%",
          marginBottom: "6%",
        }}
      />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        {/* <Grid item xs={6}>
          <Button
            variant="contained"
            onClick={createCompany}
            className={classes.buttonCreate}
          >
            <Typography className={classes.typoButtonCreate}>
              Crear empresa
            </Typography>
          </Button>
        </Grid> */}

        {/* <Grid item xs={6} container direction="row" justifyContent="flex-end"> */}
        <Button
          variant="contained"
          onClick={joinCompany}
          className={classes.buttonJoin}
        >
          <Typography className={classes.typoButtonJoin}>
            Unirme a equipo
          </Typography>
        </Button>
        {/* </Grid> */}
      </Grid>
    </div>
  );
}
