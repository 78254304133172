/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import DetailsCard from "./DetailsCard";
import Animation from "../../../../animations/pases_de_entrada_animationjson.json";
import NotFoundAnimation from "../../../../common_components/NotFoundAnimation";
import DialogAddNewProduct from "./DialogAddNewProduct";
import DialogSupplier from "./DialogList";
import ProductCard from "./ProductCard";
import axiosInstance from "../../../../utils/axiosInstance";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    padding: theme.spacing(0, 3, 0, 3),
  },
  divBack: {
    cursor: "pointer",
    marginBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
  back: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 500,
    textAlign: "left",
    color: "#ffffff",
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 32,
    fontWeight: 500,
    lineHeight: 1.34,
    textAlign: "left",
    color: "#fff",
    marginBottom: 8,
  },
  buttonAdd: {
    width: 170,
    height: 48,
    borderRadius: 8,
    color: "#ffffff",
    backgroundColor: "#ac0bce",
    textTransform: "none",
  },
  typoButtonAdd: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 1.31,
    textAlign: "left",
    color: "#fff",
  },
}));

export default function NewPurchaseOrderScreen(props) {
  const classes = useStyles();
  const history = useHistory();
  const [openDialogNewProduct, setOpenDialogNewProduct] = useState(false);
  const [openDialogSupplier, setOpenDialogSupplier] = useState(false);
  const [purchaseOrder, setPurchaseOrder] = useState({
    id: "631f97b8da92f5d581617ea4",
    folio: "232423",
  });

  const x = [
    {
      name: "Carpinteria Gomez",
    },
    {
      name: "Plomeria y Asociados",
    },
    {
      name: "Electrica Avanzada",
    },
    {
      name: "Carpinteria Gomez",
    },
  ];

  //   const getSuppliers = () => {};

  const getPurchase = () => {
    return axiosInstance()
      .get(`/api/v1/rules/${window.location.pathname.split("/").pop()}`)
      .then((response) => {
        console.log(response.data);
        // setPurchaseOrder({
        //   ...purchaseOrder,
        //   id: response.data.data[0]._id ?? purchaseOrder.id,
        // });
      })
      .cacth((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    // console.log(window.location.pathname.split("/").pop());
    // getPurchase();
  }, []);

  return (
    <div className={classes.divMain}>
      <Grid
        item
        xs={6}
        sm={4}
        md={2}
        lg={2}
        xl={2}
        container
        direction="row"
        alignItems="center"
        onClick={() => history.push(`/dashboard/almacen/ordenes-compra`)}
        className={classes.divBack}
      >
        <Grid item>
          <FontAwesomeIcon
            icon={faArrowAltCircleLeft}
            style={{
              width: "20px",
              height: "20px",
              marginRight: "10px",
              color: "#d6d6d6",
            }}
          />
        </Grid>
        <Grid item>
          <Typography className={classes.back}>Regresar</Typography>
        </Grid>
      </Grid>
      <Grid item xs={6} sm={8} md={10} lg={10} xl={10}></Grid>

      <Grid container>
        <Typography className={classes.title}>Nueva orden de compra</Typography>

        <Grid
          item
          xs={12}
          container
          direction="row"
          alignContent="center"
          style={{ marginBottom: 20 }}
        >
          <Button
            variant="contained"
            onClick={() => setOpenDialogNewProduct((prev) => !prev)}
            className={classes.buttonAdd}
          >
            <Typography className={classes.typoButtonAdd}>
              Agregar producto
            </Typography>
          </Button>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
          {/* <NotFoundAnimation
            width={213}
            height={207}
            animation={Animation}
            title={"Agrega productos"}
            subtitle={"Agrega productos para poder generar la orden de compra"}
          /> */}
          <ProductCard />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          lg={4}
          xl={4}
          container
          direction="row"
          justifyContent="center"
        >
          <DetailsCard
            purchaseOrder={purchaseOrder}
            openDialogSupplier={openDialogSupplier}
            setOpenDialogSupplier={setOpenDialogSupplier}
          />
        </Grid>
      </Grid>

      <DialogAddNewProduct
        openDialogNewProduct={openDialogNewProduct}
        setOpenDialogNewProduct={setOpenDialogNewProduct}
      />

      <DialogSupplier
        title={"Proveedor"}
        width={483}
        height={600}
        list={x}
        openDialogSupplier={openDialogSupplier}
        setOpenDialogSupplier={setOpenDialogSupplier}
      />
    </div>
  );
}
