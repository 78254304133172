/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import CardReport from "./components/CardReport";
import AnimationExitPass from "../../animations/animation_pase_de_salida.json";
import AnimationEntryPass from "../../animations/pases_de_entrada_animationjson.json";
import AnimationDevolutions from "../../animations/animation_devoluciones.json";
import AnimationInventory from "../../animations/inventario_animation.json";
import AnimationWarehouse from "../../animations/almacenes.json";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    padding: theme.spacing(3),
    // width: "100px",
    // height: "100px",
    // backgroundColor: "#36393C",
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 32,
    fontWeight: 500,
    textAlign: "left",
    color: "#ffffff",
  },
}));

export default function AlmacenScreen(props) {
  const classes = useStyles();

  const { permissions, modules } = useSelector((state) => state.permissions);
  const permissionsReport = permissions?.filter(
    (x) => x.padre == "Almacén" && x.habilitar == true
  );
  const moduleAlmacén = permissionsReport?.map((_module) => {
    return modules.find((x) => x.nombre == _module.name);
  });

  const getAnimation = (titulo) => {
    if (titulo == "Almacenes") return AnimationWarehouse;
    if (titulo == "Pase de entrada") return AnimationEntryPass;
    if (titulo == "Pase de salida") return AnimationExitPass;
    if (titulo == "Ordenes de compra") return AnimationInventory;
    if (titulo == "Proveedores") return AnimationInventory;
    if (titulo == "Devoluciones") return AnimationDevolutions;
    if (titulo == "Inventario") return AnimationInventory;

  };

  return (
    <div className={classes.divMain}>
      <Grid item>
        <Typography
          className={classes.title}
          style={{ marginBottom: 30, marginTop: 10 }}
        >
          Almacén
        </Typography>
      </Grid>

      <Grid container spacing={4}>
        {moduleAlmacén
          ?.sort((a, b) => (a.posicion > b.posicion ? 1 : -1))
          .map((almacen, index) => (
            <Grid item xs={12} md={12} lg={6} xl={6} sm={12} key={index}>
              <CardReport
                animation={getAnimation(almacen.nombre)}
                title={almacen.titulo}
                body={almacen.descripcion}
                url={almacen.url}
              />
            </Grid>
          ))}
      </Grid>
    </div>
  );
}
