/*eslint-disable*/
import React from "react";
import DataGrid from "../../../common_components/Filters/DataGrid";
import { Grid, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { makeStyles } from "@material-ui/styles";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .header": {
      color: "white",
      fontFamily: "Roboto",
      fontSize: "18px",
      fontWeight: 500,
      display: "flex",
    },
  },
  photo: {
    width: "40px",
    height: "40px",
    borderRadius: "8px",
    margin: "auto",
    aspectRatio: 1,
  },
}));

export default function DataGridTopUsers(props) {
  const classes = useStyles();

  function stringAvatar(names) {
    let initials = "";
    names.split(" ").map((name) => {
      initials = initials + name[0];
    });

    if (initials.length > 2) {
      initials = initials.slice(0, 2);
    }

    return {
      children: initials,
    };
  }

  const columns = [
    {
      field: "photo",
      headerName: " ",
      headerAlign: "center",
      flex: 0.5,
      sortable: false,
      headerClassName: "header",
      renderCell: (params) => {
        return (
          params.row.Name != "" && (
            <div style={{ margin: "auto" }}>
              <Avatar
                style={{
                  backgroundColor: "#d233ff",
                  marginTop: 8,
                  // marginLeft: 20,
                  width: 35,
                  height: 35,
                }}
                // {...stringAvatar(params.row.Name)}
                {...stringAvatar("Javier Medina")}
              />
            </div>
          )
        );
      },
    },
    {
      field: "Email",
      headerName: "Email",
      headerAlign: "center",
      flex: 1.5,
      sortable: false,
      headerClassName: "header",
      //   renderCell: (params) => {
      //     return (
      //       <div style={{ margin: "auto" }}>
      //         {params.row.Name ? params.row.Name : "--"}
      //       </div>
      //     );
      //   },
    },
    {
      field: "AltaEmpleado",
      headerName: "Alta de empleado",
      headerAlign: "center",
      flex: 1,
      sortable: false,
      headerClassName: "header",
    },
    {
      field: "AltaEmpresas",
      headerName: "Alta empresas",
      headerAlign: "center",
      flex: 1,
      sortable: false,
      headerClassName: "header",
    },
    {
      field: "ActualizacionEmpleado",
      headerName: "Actualización Empleado",
      headerAlign: "center",
      flex: 1,
      sortable: false,
      headerClassName: "header",
    },
    {
      field: "Reconocimientos",
      headerName: "Reconocimientos",
      headerAlign: "center",
      flex: 1,
      sortable: false,
      headerClassName: "header",
    },
  ];

  return (
    <DataGrid
      rows={props.rows}
      columns={columns}
      loading={props.loading}
      rowCount={props.rowCount}
      rowsPerPageOptions={props.rowsPerPageOptions}
      page={props.page}
      pageSize={props.pageSize}
      onPageChange={props.onPageChange}
      onPageSizeChange={props.onPageSizeChange}
      style={props.style}
      onSelectionModelChange={props.onSelectionModelChange}
      selectionModel={props.selectionModel}
    />
  );
}
0;
