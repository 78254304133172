/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../../utils/axiosInstance";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme) => ({
  card: {
    width: 570,
    height: 56,
    padding: theme.spacing(2),
    borderRadius: 8,
    backgroundColor: "#2d3034",
    marginBottom: 20,
  },
  typoLabel: {
    fontFamily: "Roboto",
    fontSize: 20,
    lineHeight: 1.3,
    color: "#fff",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  icon: {
    width: "20px",
    height: "25px",
    color: "#fff",
    cursor: "pointer",
    marginRight: 10,
  },
}));

export default function CardEnterpriseType(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    setOpendialog(true);
    setAnchorEl(null);
  };

  const handleEdit = () => {};

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      className={classes.card}
    >
      <Grid item xs={11}>
        <Typography className={classes.typoLabel}>
          {props.enterprise}
        </Typography>
      </Grid>

      <Grid item xs={1} container direction="row" justifyContent="flex-end">
        <FontAwesomeIcon
          icon={faEllipsisV}
          className={classes.icon}
          onClick={handleClick}
        />
        <Menu
          // classes={{ paper: classes.menuPaper }}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          id="long-menu"
          getContentAnchorEl={null}
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleClose}
          PaperProps={{
            style: {
              backgroundColor: "#27292d",
            },
          }}
        >
          <MenuItem
            key={"Editar"}
            style={{ color: "white" }}
            // onClick={handleEdit}
          >
            <EditIcon className={classes.icon} />
            {"Editar"}
          </MenuItem>
          <MenuItem
            key={"Eliminar"}
            style={{ color: "white" }}
            // onClick={handleDelete}
          >
            <DeleteIcon className={classes.icon} />
            {"Eliminar"}
          </MenuItem>
        </Menu>
      </Grid>
    </Grid>
  );
}
