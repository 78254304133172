/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useHistory, Route } from "react-router-dom";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Switch } from "react-router-dom";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import ProtectedRoute from "../ProtectedRoute";
import ListDrawer from "./components/ListDrawer";
import Dashboard from "../Dashboard/Dashboard";
import SearchSubcontratistaScreen from "../ContractorScreen/Components/SearchSubcontratistaScreen";
import ContractorDetailScreen from "../ContractorDetailScreen/ContractorDetailScreen";
import RegisterSubcontractorScreen from "../RegisterSubcontractorScreen/RegisterSubcontractorScreen";
import HistorySubcontractorScreen from "../HistorySubcontractorScreen/HistorySubcontractorScreen";
import AlmacenScreen from "../AlmacenScreen/AlmacenScreen";
import WarehouseScren from "../AlmacenScreen/Warehouse/WarehouseScreen";
import ExitPassScreen from "../AlmacenScreen/ExitPass/ExitPassScreen";
import ExitPassSpecificScreen from "../AlmacenScreen/ExitPass/components/ExitPassSpecificScreen";
import EntryPassScreen from "../AlmacenScreen/EntryPass/EntryPass";
import EntryPassSpecificScreen from "../AlmacenScreen/EntryPass/components/EntryPassScpecificScreen";
import InventoryScreen from "../AlmacenScreen/Inventory/InventoryScreen";
import PurchaseOrdersScreen from "../AlmacenScreen/PurchaseOrders/PurchaseOrdersScreen";
import NewPurchaseOrderScreen from "../AlmacenScreen/PurchaseOrders/components/NewPurchaseOrderScreen";
import SuppliersScreen from "../AlmacenScreen/Suppliers/SuppliersScreen";
import RegisterSupplierScreen from "../RegisterSupplierScreen/RegisterSupplierScreen";
import DevolutionsScreen from "../AlmacenScreen/Devolutions/DevolutionsScreen";
import SearchEmployeeScreen from "../EmpleadosScreen/Components/SearchEmployeeScreen";
import RegisterEmployeeScreen from "../RegisterEmployeeScreen/RegisterEmployeeScreen";
import DetailEmployeeScreen from "../DetailEmployeeScreen/DetailEmployeeScreen";
import BehaviorProfileScreen from "../DetailEmployeeScreen/BehaviorProfileScreen/BehaviorProfileScreen";
import HistoryEmployeeScreen from "../HistoryEmployeeScreen/HistoryEmployeeScreen";
import SelectedReport from "../ReportScreen/Components/SelectedReport";
import WorkingTime from "../ReportScreen/WorkingTimeScreen/WorkingTime";
import WorkingTimeDetailScreen from "../ReportScreen/WorkingTimeDetailScreen/WorkingTimeDetailScreen";
import AssistanceReport from "../ReportScreen/Assistance/AssistanceReport";
import AttendanceList from "../ReportScreen/attendance/AttendanceList";
import UsersScreen from "../UsersScreen/UsersScreen";
import SearchObrasScreen from "../ObrasScreen/components/SearchObrasScreen";
import DetailObrasScreen from "../DetailObrasScreen/DetailObrasScreen";
import RegisterObrasScreen from "../RegisterObrasScreen/RegisterObrasScreen";
import EnterpriseSettings from "../MyEnterprise/components/EnterpriseSettings";
import MyEnterpriseChooseScreen from "../MyEnterprise/MyEnterpriseChooseScreen";
import MyEnterpriseSettingsScreen from "../MyEnterprise/components/MyEnterpriseSettingsScreen";
import ReportSettingsScreen from "../MyEnterprise/components/ReportSettingsScreen";
import RegisterEnterpriseScreen from "../RegisterEnterpriseScreen/RegisterEnterpriseScreen";
import RegisterEnterpriseTypeScreen from "../RegisterEnterpriseType/RegisterEnterpriseTypeScreen";
import Enterprise from "../Enterprise";
import GatewayScreen from "../DeviceScreen/Gateway";
import CamerasScreen from "../DeviceScreen/Cameras";
import DeviceScreen from "../DeviceScreen";
import ClientsScreen from "../ClientsScreen/ClientsScreen";
import VideosScreen from "../VideosScreen/VideosScreen";
import VideoDetailScreen from "../VideosScreen/components/VideoDetailScreen";

import NavBar from "./components/NavBar";
import axios from "axios";
import axiosInstance from "../../utils/axiosInstance";
import ProfileScreen from "../ProfileScreen/ProfileScreen";
import RolesScreen from "../RolesScreen";

const drawerWidth = 248;
const useStyles = makeStyles((theme) => ({
  divMain: {
    display: "flex",
    height: "100%",
    minHeight: "100vh",
    backgroundColor: theme.palette.neutral.primary,
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  drawerPaper: {
    width: drawerWidth,
    height: "100%",
    minHeight: "100vh",
    // backgroundColor: theme.palette.primary.light,
  },
  content: {
    paddingLeft: 24,
    paddingRight: 24,
    minHeight: "90vh",
  },
}));

function LayoutScreen(props) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [userInfo, setUserInfo] = useState({
    name: "noAuth",
  });

  const ReloadUser = () => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    axiosInstance()
      .get("/api/v1/security/user/profile/", {
        cancelToken: source.token,
      })
      .then((response) => {
        // console.log(response.data);
        setUserInfo({
          // username: response.data.data.Username,
          // role: response.data.data.Role,
          name: response.data.data.Name,
        });
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;
        console.log(e);
        localStorage.removeItem("JWT");
        history.push("/inicio_sesion");
      });
  };

  useEffect(() => {
    ReloadUser();
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  if (userInfo.username == "noAuth") {
    return (
      <Box
        display="flex"
        height="100vh"
        alignItems="center"
        justifyContent="center"
      >
        {" "}
        <CircularProgress disableShrink size={150} color="secondary" />
      </Box>
    );
  }

  return (
    <div className={classes.divMain}>
      <CssBaseline />
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <ListDrawer UserInfo={userInfo} />
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <ListDrawer UserInfo={userInfo} />
          </Drawer>
        </Hidden>
      </nav>
      {/* <div
        style={{
          height: 55,
          background: "#50b9ff",
          width: "100%",
          // position: "fixed",
          boxShadow: "0 0px 16px 0 rgba(90, 90, 90, 0.2)",
        }}
      > */}
      <NavBar>
        <main className={classes.content}>
          <Switch>
            <ProtectedRoute
              exact
              path="/dashboard/dashboard"
              component={Dashboard}
            />

            <ProtectedRoute
              exact
              path="/dashboard/contratistas"
              component={SearchSubcontratistaScreen}
            />

            <ProtectedRoute
              exact
              path="/dashboard/contratistas/:id"
              component={ContractorDetailScreen}
            />

            <ProtectedRoute
              exact
              path="/dashboard/contratistas/editar/:id"
              component={RegisterSubcontractorScreen}
            />

            <ProtectedRoute
              exact
              path="/dashboard/contratistas/historial/:id"
              component={HistorySubcontractorScreen}
            />

            <ProtectedRoute
              exact
              path="/dashboard/almacen"
              component={AlmacenScreen}
            />

            <ProtectedRoute
              exact
              path="/dashboard/almacen/almacenes"
              component={WarehouseScren}
            />

            <ProtectedRoute
              exact
              path="/dashboard/almacen/pase-entrada"
              component={EntryPassScreen}
            />

            <ProtectedRoute
              exact
              path="/dashboard/almacen/pase-entrada/:id"
              component={EntryPassSpecificScreen}
            />

            <ProtectedRoute
              exact
              path="/dashboard/almacen/pase-salida"
              component={ExitPassScreen}
            />

            <ProtectedRoute
              exact
              path="/dashboard/almacen/pase-salida/:id"
              component={ExitPassSpecificScreen}
            />

            <ProtectedRoute
              exact
              path="/dashboard/almacen/inventario"
              component={InventoryScreen}
            />

            <ProtectedRoute
              exact
              path="/dashboard/almacen/ordenes-compra"
              component={PurchaseOrdersScreen}
            />

            <ProtectedRoute
              exact
              path="/dashboard/almacen/ordenes-compra/crear"
              component={NewPurchaseOrderScreen}
            />

            <ProtectedRoute
              exact
              path="/dashboard/almacen/ordenes-compra/editar/:id"
              component={NewPurchaseOrderScreen}
            />

            <ProtectedRoute
              exact
              path="/dashboard/almacen/proveedores"
              component={SuppliersScreen}
            />

            <ProtectedRoute
              exact
              path="/dashboard/almacen/proveedores/crear"
              component={RegisterSupplierScreen}
            />

            <ProtectedRoute
              exact
              path="/dashboard/almacen/devoluciones"
              component={DevolutionsScreen}
            />

            <ProtectedRoute
              exact
              path="/dashboard/empleados"
              component={SearchEmployeeScreen}
            />

            <ProtectedRoute
              exact
              path="/dashboard/empleados/crear"
              component={RegisterEmployeeScreen}
            />

            <ProtectedRoute
              exact
              path="/dashboard/empleados/editar/:id"
              component={RegisterEmployeeScreen}
            />

            <ProtectedRoute
              exact
              path="/dashboard/empleados/:id"
              component={DetailEmployeeScreen}
            />

            <ProtectedRoute
              exact
              path="/dashboard/empleados/conducta/:id"
              component={BehaviorProfileScreen}
            />

            <ProtectedRoute
              exact
              path="/dashboard/empleados/historial/:id"
              component={HistoryEmployeeScreen}
            />

            <ProtectedRoute
              exact
              path="/dashboard/reportes"
              component={SelectedReport}
            />

            <ProtectedRoute
              exact
              path="/dashboard/reportes/tiempo-trabajo"
              component={WorkingTime}
            />

            <ProtectedRoute
              exact
              path="/dashboard/reportes/tiempo-trabajo/:id"
              component={WorkingTimeDetailScreen}
            />

            <ProtectedRoute
              exact
              path="/dashboard/reportes/asistencias"
              component={AssistanceReport}
            />

            <ProtectedRoute
              exact
              path="/dashboard/reportes/lista-asistencias"
              component={AttendanceList}
            />

            <ProtectedRoute
              exact
              path="/dashboard/usuarios"
              component={UsersScreen}
            />

            <ProtectedRoute
              exact
              path="/dashboard/usuarios/roles"
              component={RolesScreen}
            />

            <ProtectedRoute
              exact
              path="/dashboard/obras"
              component={SearchObrasScreen}
            />

            <ProtectedRoute
              exact
              path="/dashboard/obras/crear"
              component={RegisterObrasScreen}
            />

            <ProtectedRoute
              exact
              path="/dashboard/obras/editar/:id"
              component={RegisterObrasScreen}
            />

            <ProtectedRoute
              exact
              path="/dashboard/obras/:id"
              component={DetailObrasScreen}
            />

            <ProtectedRoute
              exact
              path="/dashboard/miempresa"
              component={MyEnterpriseChooseScreen}
            />
            <ProtectedRoute
              exact
              path="/dashboard/miempresa/configuracion"
              component={EnterpriseSettings}
            />

            <ProtectedRoute
              exact
              path="/dashboard/miempresa/configuracion/crear"
              component={RegisterEnterpriseTypeScreen}
            />

            {/* <ProtectedRoute
              exact
              path="/dashboard/miempresa/configuracion/editar/:id"
              component={RegisterEnterpriseTypeScreen}
            /> */}

            <ProtectedRoute
              exact
              path="/dashboard/miempresa/configuracion/reportes"
              component={ReportSettingsScreen}
            />

            <ProtectedRoute
              exact
              path="/dashboard/miempresa/configuracion/:id"
              component={MyEnterpriseSettingsScreen}
            />

            <ProtectedRoute
              exact
              path="/dashboard/miempresa/historial/:id"
              component={HistorySubcontractorScreen}
            />

            <ProtectedRoute
              exact
              path="/dashboard/miempresa/:id"
              component={MyEnterpriseChooseScreen}
            />

            <ProtectedRoute
              exact
              path="/dashboard/miempresa/editar/:id"
              component={RegisterEnterpriseScreen}
            />

            <ProtectedRoute
              exact
              path="/dashboard/miempresa/historial/:id"
              component={HistorySubcontractorScreen}
            />

            <ProtectedRoute
              exact
              path="/dashboard/perfil"
              component={ProfileScreen}
            />

            <ProtectedRoute
              exact
              path="/dashboard/dispositivos"
              component={DeviceScreen}
            />

            <ProtectedRoute
              exact
              path="/dashboard/dispositivos/gateway"
              component={GatewayScreen}
            />

            <ProtectedRoute
              exact
              path="/dashboard/dispositivos/camaras"
              component={CamerasScreen}
            />

            <ProtectedRoute
              exact
              path="/dashboard/clientes"
              component={ClientsScreen}
            />

            <ProtectedRoute
              exact
              path="/dashboard/empresas"
              component={Enterprise}
            />

            <ProtectedRoute
              exact
              path="/dashboard/videos"
              component={VideosScreen}
            />

            <ProtectedRoute
              exact
              path="/dashboard/videos/:id"
              component={VideoDetailScreen}
            />
          </Switch>
        </main>
      </NavBar>
    </div>
    // </div>
  );
}

LayoutScreen.propTypes = {
  window: PropTypes.func,
};

export default LayoutScreen;
