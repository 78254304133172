/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, TextField, InputAdornment } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import { SetScreenProfile } from "../../../actions/screen";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faSearch } from "@fortawesome/free-solid-svg-icons";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import useEmployeeSearch from "./useEmployeeSearch";
import Lottie from "react-lottie";
import SelfieAnimation from "../../../animations/selfie_animation.json";
import BacgeEmployeeAnimation from "../../../animations/bacge_employee.json";
import LottieNotFound from "../../../animations/lottie_not_found.json";
import CardEmployee from "./CardEmployee";
import ScrollBars from "../../../common_components/ScrollBars";

const useStyles = makeStyles((theme) => ({
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: "bold",
    textAlign: "left",
    color: "#cdcece",
  },
  textFieldCustom: {
    width: "90%",
    color: "#676767",
    border: "solid 1px #c5c5c5",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
  },
  divImg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "356px",
    height: "290px",
    borderRadius: "8px",
    border: "solid 1px #707070",
    backgroundColor: "#212429",
    cursor: "pointer",
  },
  trackVertical: {
    height: "95%",
    right: 0,
    width: "10px !important",
  },
  thumbVertical: {
    backgroundColor: "#2d3034",
    height: "100%",
    right: 0,
    width: "8px !important",
    borderRadius: "8px",
  },
}));

export default function DialogSearchEmployee(props) {
  const classes = useStyles();
  const [pageNumber, setPageNumber] = useState(0);
  const [loadingData, setLoadingData] = useState(false);

  const handleOpenDialog = () => {
    props.setOpendialogSearch(!props.openDialogSearch);
  };

  const [query, setQuery] = useState({
    Empleado: "",
  });

  useEffect(() => {
    setPageNumber(0);
  }, [query]);

  const { books, hasMore, loading, error } = useEmployeeSearch(
    query,
    pageNumber,
    loadingData
  );

  const handleQueryChange = (queryElement) => (e) => {
    const value = e.target.value;
    return setQuery((prevQuery) => {
      return { ...prevQuery, ...{ [queryElement]: value } };
    });
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: SelfieAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: LottieNotFound,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div>
      <Dialog
        open={props.openDialogSearch}
        onClose={handleOpenDialog}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            width: "490px",
            height: "540px",
            paddingTop: 10,
            textColor: "#fsf",
            backgroundColor: "#171a1e",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 18,
                textAlign: "center",
                color: "#fff",
                fontWeight: "bold",
              }}
            >
              Foto de perfil
            </Typography>

            <Grid
              container
              justifyContent="flex-end"
              style={{ position: "absolute" }}
            >
              <Button onClick={handleOpenDialog}>
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{
                    width: "20px",
                    height: "20px",
                    color: "#fff",
                  }}
                />
              </Button>
            </Grid>
          </Grid>
          <div
            style={{
              width: "100%",
              height: 1,
              background: "#707070",
              marginTop: 8,
              padding: 0,
            }}
          ></div>
        </DialogTitle>

        <DialogContent>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <TextField
              variant="outlined"
              size="small"
              placeholder="Buscar empleado"
              onChange={handleQueryChange("Empleado")}
              className={classes.textFieldCustom}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon
                      icon={faSearch}
                      style={{
                        width: "18px",
                        height: "18px",
                        color: "#676767",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          {query.Empleado == "" ? (
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              style={{
                height: "350px",
              }}
            >
              <div>
                <Lottie options={defaultOptions} height={126} width={149} />
                <Typography
                  style={{
                    fontSize: 16,
                    fontWeight: 600,
                    textAlign: "center",
                    color: "#fff",
                    marginTop: 10,
                  }}
                >
                  Busca un empleado para usar su foto
                </Typography>
                <Typography
                  style={{
                    fontSize: 14,
                    fontWeight: "bold",
                    textAlign: "center",
                    color: "#8f8f8f",
                  }}
                >
                  Con la foto podrás generar firmas digitales
                </Typography>
              </div>
            </Grid>
          ) : (
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              style={{
                height: "350px",
              }}
            >
              {books.length == 0 ? (
                <div>
                  <Lottie options={defaultOptions2} height={126} width={149} />
                  <Typography
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      textAlign: "center",
                      color: "#fff",
                      marginTop: 10,
                    }}
                  >
                    No se han encontrado empleados
                  </Typography>
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: "bold",
                      textAlign: "center",
                      color: "#8f8f8f",
                    }}
                  >
                    No hemos encontrado empleados en la búsqueda
                  </Typography>
                </div>
              ) : (
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  style={{
                    marginTop: 10,
                    paddingLeft: 20,
                  }}
                >
                  <ScrollBars style={{ width: "100%", height: "35vh" }}>
                    {books.map((event, index) => {
                      return (
                        <CardEmployee
                          key={index}
                          userData={props.userData}
                          setUserData={props.setUserData}
                          openDialogSearch={props.openDialogSearch}
                          setOpendialogSearch={props.setOpendialogSearch}
                          urlPhoto={event.foto}
                          name={event.nombre + " " + event.apellidos}
                          role={event.puesto}
                          enterprise={event.subcontratistas[0].empresa}
                          unit={event.unidad ? event.unidad : ""}
                        />
                      );
                    })}
                  </ScrollBars>
                </Grid>
              )}
            </Grid>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
