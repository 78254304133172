/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { Grid, Typography, Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleLeft,
  faEllipsisV,
} from "@fortawesome/free-solid-svg-icons";
import TopTabComponent from "./Components/TopTab";
import SubcontractorProfileScreen from "./SubcontractorProfileScreen/SubcontractorProfileScreen";
import DialogDeleteSubcontractor from "./Components/DialogDeleteSubcontractor";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DeleteIcon from "@material-ui/icons/Delete";
import { ValidatePermissions } from "../../utils/permissions";
import WelcomeSubcontractor from "../ContractorScreen/Components/WelcomeSubcontractor";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    // height: "100%",
    // backgroundColor: "red",
  },
  divBack: {
    cursor: "pointer",
    marginBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
  back: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 500,
    textAlign: "left",
    color: "#fff",
  },
}));

export default function ContractorDetailScreen(props) {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useSelector((state) => state.user);
  const [tab, setTab] = useState(1);
  const [openDialog, setOpendialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    setOpendialog(true);
    setAnchorEl(null);
  };

  const backStep = () => {
    history.push(`/dashboard/contratistas`);
  };

  const renderScreen = () => {
    if (
      user?.RoleName == "Administrador Subcontratista" &&
      user?.Subcontractor[0]?.firstTime
    ) {
      return <WelcomeSubcontractor />;
    } else {
      if (tab == 1) return <SubcontractorProfileScreen />;
    }
  };

  return (
    <div className={classes.divMain}>
      <Grid container>
        {user?.RoleName == "Administrador Subcontratista" ? (
          <div />
        ) : (
          <Grid
            item
            xs={6}
            sm={4}
            md={2}
            lg={2}
            xl={2}
            container
            direction="row"
            alignItems="center"
            onClick={backStep}
            className={classes.divBack}
          >
            <Grid item>
              <FontAwesomeIcon
                icon={faArrowAltCircleLeft}
                style={{
                  width: "20px",
                  height: "20px",
                  marginRight: "10px",
                  color: "#d6d6d6",
                }}
              />
            </Grid>
            <Grid item>
              <Typography className={classes.back}>Regresar</Typography>
            </Grid>
            <Grid item xs={6} sm={8} md={10} lg={10} xl={10}></Grid>
          </Grid>
        )}
      </Grid>

      <Grid container direction="row">
        {user?.RoleName == "Administrador Subcontratista" &&
        user?.Subcontractor[0]?.firstTime ? null : (
          <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
            <TopTabComponent tab={tab} setTab={setTab} />
          </Grid>
        )}

        {ValidatePermissions("eliminar") && (
          <Grid
            item
            xs={6}
            sm={6}
            md={8}
            lg={8}
            xl={8}
            container
            alignItems="center"
            justifyContent="flex-end"
          >
            <Button
              aria-label="more"
              id="long-button"
              aria-controls="long-menu"
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              style={{
                backgroundColor: "#2d3034",
                maxWidth: "48px",
                maxHeight: "48px",
                minWidth: "48px",
                minHeight: "48px",
                borderRadius: 8,
              }}
            >
              <FontAwesomeIcon
                icon={faEllipsisV}
                style={{
                  width: "20px",
                  height: "25px",
                  color: "#fff",
                  // position: "absolute",
                }}
              />
            </Button>
            <Menu
              // classes={{ paper: classes.menuPaper }}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              transformOrigin={{ vertical: "top", horizontal: "center" }}
              id="long-menu"
              getContentAnchorEl={null}
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleClose}
              PaperProps={{
                style: {
                  backgroundColor: "#27292d",
                },
              }}
            >
              <MenuItem
                key={"Eliminar"}
                style={{ color: "white" }}
                onClick={handleDelete}
              >
                <DeleteIcon className={classes.icon} />
                {"Eliminar subcontratista"}
              </MenuItem>
            </Menu>
          </Grid>
        )}
      </Grid>

      <DialogDeleteSubcontractor
        openDialog={openDialog}
        setOpendialog={setOpendialog}
      />

      {renderScreen()}
    </div>
  );
}
