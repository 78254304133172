/*eslint-disable*/
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";

import InputAdornment from "@material-ui/core/InputAdornment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";

// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import TimePicker from "@mui/lab/TimePicker";

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from "@mui/x-date-pickers";
import { TimePicker } from '@mui/x-date-pickers'

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-input": {
      paddingTop: 5,
      paddingBottom: 5,
      color: "#acacac",
      fontSize: 14,
      marginLeft: -5 
    },
  },
}));

export default function TimeIconComponent(props) {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const { value, setValue } = props;

  return (
    <div className={classes.root}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TimePicker
          label="Basic example"
          variant="outlined"
          open={isOpen}
        //   ampm={false}
          value={value}
          onClose={() => setIsOpen(false)}
          onChange={(newValue) => {
            // setIsOpen(false);
            setValue(newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              style={{
                backgroundColor: "#fff",
                color: "#acacac",
                borderRadius: 6,
                border: "solid 1px #c5c5c5",
                width: 100,
              }}
              // InputProps={{
              //   classes: {
              //     input: classes.resize,
              //   },
              // }}
              onClick={() => setIsOpen(true)}
              variant="outlined"
              label={""}
              readOnly
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    component="div"
                    style={{ marginLeft: -8 }}
                  >
                    <FontAwesomeIcon
                      icon={faClock}
                      style={{
                        width: 16,
                        height: 16,
                        color: "#c5c5c5",
                      }}
                    ></FontAwesomeIcon>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </LocalizationProvider>
    </div>
  );
}
