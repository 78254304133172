/*eslint-disable*/
import React, { Fragment, useEffect, useState } from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { GoogleMap, Marker } from "@react-google-maps/api";
import Geocode from "react-geocode";
import { api_key } from "../../../config/vars";
Geocode.setApiKey(api_key);
const addressRegex =
  /^([a-z A-Z . - 0-9 \u00E0-\u00FC]*), ([a-z A-Z . 0-9 \u00E0-\u00FC]*), (\d*) ([a-z A-Z . 0-9 \u00E0-\u00FC]*), ([a-z A-Z . 0-9 \u00E0-\u00FC]*), ([a-z A-Z . 0-9 \u00E0-\u00FC]*)$/;

const useStyles = makeStyles((theme) => ({
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: "bold",
    textAlign: "left",
    color: "#cdcece",
  },
  textFieldCustom: {
    width: "276px",
    color: "#676767",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
  },
  formControl: {
    width: "90%",
  },
  select: {
    width: "100%",
    height: "40px",
    borderRadius: "6px",
    backgroundColor: "#fff",
  },
  multi: {
    color: "white",
    padding: 2,
    border: 0,
    borderRadius: "8px",

    "&.rmsc .dropdown-heading ": {
      backgroundColor: "white !important",
      color: "#676767",
      borderRadius: 5,
    },
  },
}));

export default function DialogMaps(props) {
  const classes = useStyles();
  const { setOpendialog, openDialog } = props;
  const [query, setQuery] = useState("");
  const [placePredictionsAuto, setPlacePredictionsAuto] = useState([]);
  const googleMapsScriptBaseUrl = `https://maps.googleapis.com/maps/api/js?key=${api_key}&v=weekly&callback=initMap`;
  const [address, setaddress] = useState({
    calle: "",
    colonia: "",
    codigoPostal: "",
    ciudad: "",
    estado: "",
  });
  const [state, setState] = useState({
    address: "",
    city: "",
    area: "",
    state: "",
    mapPosition: {
      lat: 20.7093647,
      lng: -103.41164,
    },
    markerPosition: {
      lat: 0,
      lng: 0,
    },
  });

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    // apiKey: api_key,
    googleMapsScriptBaseUrl,
  });

  const getAddress = (add) => {
    let match = addressRegex.exec(add);
    setaddress({
      ...address,
      calle: match ? match[1] : "",
      colonia: match ? match[2] : "",
      codigoPostal: match ? match[3] : "",
      ciudad: match ? match[4] : "",
      estado: match ? match[5] : "",
    });
  };

  const handleOnSearch = (string, results) => {
    setQuery(string);
  };

  const handleOnSelect = (item) => {
    placesService?.getDetails(
      {
        placeId: item.place_id,
      },
      (placeDetails) => onPlaceSelected(placeDetails)
    );
  };

  const formatResult = (item) => {
    return <p>{item.description}</p>;
  };

  useEffect(() => {
    getPlacePredictions({ input: query });
  }, [query]);

  useEffect(() => {
    if (props.obrasData.latitud != "" && props.obrasData.longitud != "") {
      UpdateMap(props.obrasData.latitud, props.obrasData.longitud);
    }
  }, [props.obrasData]);

  useEffect(() => {
    if (placePredictions.length > 0) {
      placePredictions.map((place, index) => {
        place.name = place.description;
        place.id = index;
      });

      setPlacePredictionsAuto(placePredictions);
    }
  }, [placePredictions]);

  const handleDialog = () => {
    setOpendialog(!openDialog);
  };

  const handleSave = () => {
    props.setObrasData({
      ...props.obrasData,
      address: state.address,
      latitud: state.mapPosition.lat,
      longitud: state.mapPosition.lng,
    });

    setOpendialog(!openDialog);
  };

  const center = React.useMemo(
    function memo() {
      return {
        lat: state.mapPosition.lat,
        lng: state.mapPosition.lng,
      };
    },
    [state.mapPosition]
  );

  const getCity = (addressArray) => {
    let city = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "administrative_area_level_2" === addressArray[i].types[0]
      ) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };

  const getArea = (addressArray) => {
    let area = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if (
            "sublocality_level_1" === addressArray[i].types[j] ||
            "locality" === addressArray[i].types[j]
          ) {
            area = addressArray[i].long_name;
            return area;
          }
        }
      }
    }
  };

  const getState = (addressArray) => {
    let state = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "administrative_area_level_1" === addressArray[i].types[0]
        ) {
          state = addressArray[i].long_name;
          return state;
        }
      }
    }
  };

  const onPlaceSelected = (place) => {
    const address = place.formatted_address,
      addressArray = place.address_components,
      city = getCity(addressArray),
      area = getArea(addressArray),
      state = getState(addressArray),
      latValue = place.geometry.location.lat(),
      lngValue = place.geometry.location.lng();

    getAddress(address);
    setState({
      address: address ? address : "",
      area: area ? area : "",
      city: city ? city : "",
      state: state ? state : "",
      markerPosition: {
        lat: latValue,
        lng: lngValue,
      },
      mapPosition: {
        lat: latValue,
        lng: lngValue,
      },
    });
  };

  const UpdateMap = (newLat, newLng) => {
    Geocode.fromLatLng(newLat, newLng).then(
      (response) => {
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          city = getCity(addressArray),
          area = getArea(addressArray),
          state = getState(addressArray);

        getAddress(address);

        setState({
          address: address ? address : "",
          area: area ? area : "",
          city: city ? city : "",
          state: state ? state : "",
          markerPosition: {
            lat: newLat,
            lng: newLng,
          },
          mapPosition: {
            lat: newLat,
            lng: newLng,
          },
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const onMarkerDragEnd = (event) => {
    let newLat = event.latLng.lat(),
      newLng = event.latLng.lng();

    Geocode.fromLatLng(newLat, newLng).then(
      (response) => {
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          city = getCity(addressArray),
          area = getArea(addressArray),
          state = getState(addressArray);

        getAddress(address);

        setState({
          address: address ? address : "",
          area: area ? area : "",
          city: city ? city : "",
          state: state ? state : "",
          markerPosition: {
            lat: newLat,
            lng: newLng,
          },
          mapPosition: {
            lat: newLat,
            lng: newLng,
          },
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  return (
    <Dialog
      open={openDialog}
      aria-labelledby="form-dialog-title"
      onClose={handleDialog}
      disableEnforceFocus
      PaperProps={{
        style: {
          backgroundColor: "#171a1e",
          textColor: "#fsf",
          minWidth: 1001,
          width: 1001,
          height: 716,
          minHeight: 716,
          paddingTop: 15,
          zIndex: 1,
        },
      }}
    >
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 22,
              textAlign: "center",
              color: "#fff",
              fontWeight: "bold",
            }}
          >
            Localización
          </Typography>

          <Grid
            container
            justifyContent="flex-end"
            style={{ position: "absolute" }}
          >
            <Button onClick={handleDialog}>
              <FontAwesomeIcon
                icon={faTimes}
                style={{
                  width: "20px",
                  height: "20px",
                  color: "#fff",
                }}
              />
            </Button>
          </Grid>
        </Grid>
        <div
          style={{
            width: "100%",
            height: 1,
            background: "#707070",
            marginTop: 8,
            padding: 0,
          }}
        ></div>
      </DialogTitle>
      <DialogContent>
        <Grid container direction="row" justifyContent="center">
          {address?.calle != "" && (
            <Grid
              container
              direction="row"
              style={{
                width: 280,
                height: "auto",
                padding: 2,
                backgroundColor: "#fff",
                zIndex: 1000,
                marginTop: 375,
                position: "absolute",
                borderRadius: 8,
                border: "1px solid #e6e6e6",
              }}
            >
              <Grid
                item
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                xs={3}
              >
                <div
                  style={{
                    width: 40,
                    height: 40,
                    borderRadius: "50%",
                    backgroundColor: "#e6e6e6",
                    margin: "auto",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faMapMarkerAlt}
                    style={{
                      width: 16,
                      height: 22,
                      color: "#d233ff",
                      margin: "auto",
                      marginLeft: 12,
                      marginTop: 8,
                    }}
                  />
                </div>
              </Grid>

              <Grid
                item
                container
                direction="column"
                xs={9}
                style={{ padding: 8 }}
              >
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 14,
                    textAlign: "left",
                    color: "#2d2d2d",
                    fontWeight: "bold",
                  }}
                >
                  {address.calle}
                </Typography>

                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 14,
                    textAlign: "left",
                    color: "#757575",
                  }}
                >
                  {address.colonia}
                </Typography>

                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 14,
                    textAlign: "left",
                    color: "#757575",
                  }}
                >
                  {`CP ${address.codigoPostal}`}
                </Typography>

                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 14,
                    textAlign: "left",
                    color: "#757575",
                  }}
                >
                  {`${address.ciudad}, ${address.estado}`}
                </Typography>
              </Grid>
            </Grid>
          )}

          <GoogleMap
            mapContainerStyle={{
              width: 801,
              height: 495,
            }}
            zoom={15}
            center={center}
          >
            <Grid
              style={{ width: 480, height: 40, margin: "auto", marginTop: 20 }}
            >
              <ReactSearchAutocomplete
                items={placePredictionsAuto}
                onSearch={handleOnSearch}
                onSelect={handleOnSelect}
                formatResult={formatResult}
              />
            </Grid>

            <Marker draggable onDragEnd={onMarkerDragEnd} position={center} />
          </GoogleMap>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          style={{ marginTop: 30, paddingRight: 70 }}
        >
          <Button
            variant="contained"
            onClick={handleDialog}
            style={{
              width: 198,
              height: 40,
              borderRadius: 8,
              backgroundColor: "#3a3c40",
              textTransform: "none",
            }}
          >
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 18,
                textAlign: "center",
                color: "#fff",
              }}
            >
              Cancelar
            </Typography>
          </Button>

          <Button
            variant="contained"
            onClick={handleSave}
            style={{
              width: 198,
              height: 40,
              borderRadius: 8,
              backgroundColor: "#ac0bce",
              textTransform: "none",
              marginLeft: 15,
            }}
          >
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 18,
                textAlign: "center",
                color: "#fff",
              }}
            >
              Guardar
            </Typography>
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
