/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import CardDetailEmployee from "./Components/CardDetailEmployee";
import CardBasicDataEmployee from "./Components/CardBasicDataEmployee";
import CardHealthyDataEmployee from "./Components/CardHealthyDataEmployee";
import CardFileEmployee from "./Components/CardFileEmployee";
import BehaviorProfileCard from "./Components/BehaviorProfileCard";
import HoursWorked from "./Components/HoursWorked";
import { useDispatch } from "react-redux";
import { addFile, clearFiles } from "../../../actions/file";
import axiosInstance from "../../../utils/axiosInstance";

const useStyles = makeStyles((theme) => ({
  divBack: {
    cursor: "pointer",
    marginBottom: "10px",
  },
  back: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 500,
    textAlign: "left",
    color: "#fff",
  },
  gridMargin: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 20,
    },
  },
}));

export default function EmployeeProfileScreen(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [employeeData, setEmployeeData] = useState({
    foto: "",
    nombre: "",
    apellidos: "",
    subcontratista: "--",
    subcontratistaUnidad: "--",
    proyectoObra: "--",
    unidad: "--",
    genero: "--",
    fechaNacimiento: "--",
    fechaNacimientoString: "--",
    puesto: "--",
    curp: "--",
    fechaIngreso: "--",
    fechaIngresoString: "--",
    lugarNacimiento: "--",
    escribir: "--",
    leer: "--",
    domicilio: "--",
    gradoEstudios: "--",
    contactoEmergencia: "--",
    telefono1: "--",
    telefono2: "--",
    imss: "--",
    sangre: "--",
    fuma: "--",
    bebeAlcol: "--",
    haUsadoDrogas: "--",
    actividadFisica: "--",
    peso: "--",
    altura: "--",
    enfermedadCronica: "--",
    tipoEnfermedadCronica: "--",
    tratamientoMedico: "--",
    tipoTratamientoMedico: "--",
    medicamento: "--",
    tipoMedicamento: "--",
    lentes: "--",
    tipoLentes: "--",
    alergias: "--",
    tipoAlergia: "--",
    documentos: [],
    porcentajePerfil: "0",
    reportes: 0,
    statusDemek: "",
    estatusImss: "",
    numeroEmpleado: "",
    especialidad: "--",
    cameras: [],
  });

  useEffect(() => {
    axiosInstance()
      .get(`/api/v1/rules/trabajadores/${localStorage.getItem("idEmployee")}`)
      .then((response) => {
        // console.log("get employee", response.data.data[0]);
        setEmployeeData({
          ...employeeData,
          foto: response.data.data[0].foto
            ? response.data.data[0].foto
            : employeeData.foto,
          nombre: response.data.data[0].nombre
            ? response.data.data[0].nombre
            : employeeData.nombre,
          apellidos: response.data.data[0].apellidos
            ? response.data.data[0].apellidos
            : employeeData.apellidos,
          numeroEmpleado: response.data.data[0].numeroEmpleado
            ? response.data.data[0].numeroEmpleado
            : employeeData.numeroEmpleado,
          subcontratista: response.data.data[0].subcontratistas[0]
            ? response.data.data[0].subcontratistas[0].empresa
            : employeeData.subcontratista,
          subcontratistaUnidad: response.data.data[0]?.subcontratistas[0]
            ? response.data.data[0]?.subcontratistas[0]?.unidad
            : employeeData.subcontratistaUnidad,
          proyectoObra: response.data.data[0].proyectos[0]
            ? response.data.data[0].proyectos[0].proyecto
            : employeeData.proyectoObra,
          unidad: response.data.data[0].unidad
            ? response.data.data[0].unidad
            : employeeData.unidad,
          domicilioObra: response.data.data[0].proyectos[0]
            ? response.data.data[0].proyectos[0].domicilio
            : employeeData.domicilioObra,
          fechaNacimientoString: response.data.data[0].fechaNacimientoString
            ? response.data.data[0].fechaNacimientoString
            : employeeData.fechaNacimientoString,
          fechaNacimiento: response.data.data[0].fechaNacimiento
            ? response.data.data[0].fechaNacimiento
            : employeeData.fechaNacimiento,
          puesto: response.data.data[0].puesto
            ? response.data.data[0].puesto
            : employeeData.puesto,
          curp: response.data.data[0].curp
            ? response.data.data[0].curp
            : employeeData.curp,
          fechaIngreso: response.data.data[0].fechaIngreso
            ? response.data.data[0].fechaIngreso
            : employeeData.fechaIngreso,
          fechaIngresoString: response.data.data[0].fechaIngresoString
            ? response.data.data[0].fechaIngresoString
            : employeeData.fechaIngresoString,
          lugarNacimiento: response.data.data[0].lugarNacimiento
            ? response.data.data[0].lugarNacimiento
            : employeeData.lugarNacimiento,
          escribir: response.data.data[0].escribir
            ? response.data.data[0].escribir
            : employeeData.escribir,
          leer: response.data.data[0].leer
            ? response.data.data[0].leer
            : employeeData.leer,
          domicilio: response.data.data[0].domicilio
            ? response.data.data[0].domicilio
            : employeeData.domicilio,
          contactoEmergencia: response.data.data[0].emergencias
            .contactoEmergencia
            ? response.data.data[0].emergencias.contactoEmergencia
            : employeeData.contactoEmergencia,
          telefono1: response.data.data[0].emergencias.telefono1
            ? response.data.data[0].emergencias.telefono1
            : employeeData.telefono1,
          telefono2: response.data.data[0].emergencias.telefono2
            ? response.data.data[0].emergencias.telefono2
            : employeeData.telefono2,
          imss: response.data.data[0].imss
            ? response.data.data[0].imss
            : employeeData.imss,
          sangre: response.data.data[0].cuestionarioSalud.tipoSangre
            ? response.data.data[0].cuestionarioSalud.tipoSangre
            : employeeData.sangre,
          documentos: response.data.data[0].documentos
            ? response.data.data[0].documentos
            : employeeData.documentos,
          gradoEstudios: response.data.data[0].gradoEstudios
            ? response.data.data[0].gradoEstudios
            : employeeData.gradoEstudios,
          fuma: response.data.data[0].cuestionarioSalud.fuma
            ? response.data.data[0].cuestionarioSalud.fuma
            : employeeData.fuma,
          bebeAlcol: response.data.data[0].cuestionarioSalud.bebeAlcol
            ? response.data.data[0].cuestionarioSalud.bebeAlcol
            : employeeData.bebe,
          haUsadoDrogas: response.data.data[0].cuestionarioSalud.haUsadoDrogas
            ? response.data.data[0].cuestionarioSalud.haUsadoDrogas
            : employeeData.drogas,
          actividadFisica: response.data.data[0].cuestionarioSalud
            .actividadFisica
            ? response.data.data[0].cuestionarioSalud.actividadFisica
            : employeeData.actividadFisica,
          peso: response.data.data[0].cuestionarioSalud.peso
            ? response.data.data[0].cuestionarioSalud.peso
            : employeeData.peso,
          altura: response.data.data[0].cuestionarioSalud.altura
            ? response.data.data[0].cuestionarioSalud.altura
            : employeeData.altura,
          enfermedadCronica: response.data.data[0].cuestionarioSalud
            .enfermedadCronica
            ? response.data.data[0].cuestionarioSalud.enfermedadCronica
            : employeeData.enfermedadCronica,
          tipoEnfermedadCronica: response.data.data[0].cuestionarioSalud
            .tipoEnfermedadCronica
            ? response.data.data[0].cuestionarioSalud.tipoEnfermedadCronica
            : employeeData.tipoEnfermedadCronica,
          tratamientoMedico: response.data.data[0].cuestionarioSalud
            .tratamientoMedico
            ? response.data.data[0].cuestionarioSalud.tratamientoMedico
            : employeeData.tratamientoMedico,
          tipoTratamientoMedico: response.data.data[0].cuestionarioSalud
            .tipoTratamientoMedico
            ? response.data.data[0].cuestionarioSalud.tipoTratamientoMedico
            : employeeData.tipoTratamientoMedico,
          medicamento: response.data.data[0].cuestionarioSalud.medicamento
            ? response.data.data[0].cuestionarioSalud.medicamento
            : employeeData.medicamento,
          tipoMedicamento: response.data.data[0].cuestionarioSalud
            .tipoMedicamento
            ? response.data.data[0].cuestionarioSalud.tipoMedicamento
            : employeeData.tipoMedicamento,
          lentes: response.data.data[0].cuestionarioSalud.lentes
            ? response.data.data[0].cuestionarioSalud.lentes
            : employeeData.lentes,
          tipoLentes: response.data.data[0].cuestionarioSalud.tipoLentes
            ? response.data.data[0].cuestionarioSalud.tipoLentes
            : employeeData.tipoLentes,
          alergias: response.data.data[0].cuestionarioSalud.alergias
            ? response.data.data[0].cuestionarioSalud.alergias
            : employeeData.alergias,
          tipoAlergia: response.data.data[0].cuestionarioSalud.tipoAlergia
            ? response.data.data[0].cuestionarioSalud.tipoAlergia
            : employeeData.tipoAlergia,
          porcentajePerfil: response.data.data[0].percentage
            ? response.data.data[0].percentage
            : employeeData.porcentajePerfil,
          reportes: response.data.data[0].incidencia_id
            ? response.data.data[0].incidencia_id.length
            : employeeData.reportes,
          statusDemek: response.data.data[0].statusDemek
            ? response.data.data[0].statusDemek
            : employeeData.statusDemek,
          estatusImss: response.data.data[0].estatusImss
            ? response.data.data[0].estatusImss
            : employeeData.estatusImss,
          especialidad: response.data.data[0].especialidad
            ? response.data.data[0].especialidad
            : employeeData.especialidad,
          cameras: response.data.data[0].cameras
            ? response.data.data[0].cameras
            : employeeData.cameras,
        });

        dispatch(clearFiles());
        const doc = response.data.data[0].documentos
          ? response.data.data[0].documentos
          : [];

        doc.map((file, index) =>
          dispatch(
            addFile({
              name: file.filename,
              url: file.file,
              originalName: file.originalName,
              process: "db",
              date: file.datetime,
            })
          )
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={6}
        xl={6}
        className={classes.gridMargin}
      >
        <div>
          <CardDetailEmployee
            urlPhoto={employeeData.foto}
            name={employeeData.nombre}
            lastName={employeeData.apellidos}
            role={employeeData.puesto}
            subcontractor={employeeData.subcontratista}
            proyect={employeeData.proyectoObra}
            address={employeeData.domicilioObra}
            progress={employeeData.porcentajePerfil.total}
            statusImss={employeeData.estatusImss}
            unit={employeeData.unidad}
            subcontratistaUnidad={employeeData.subcontratistaUnidad}
            numeroEmpleado={employeeData.numeroEmpleado}
            cameras={employeeData.cameras}
          />
        </div>

        <div style={{ marginTop: 10, marginBottom: 10 }}>
          <CardBasicDataEmployee employeeData={employeeData} />
        </div>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <BehaviorProfileCard reports={employeeData.reportes} />
          </Grid>

          {/* <Grid item xs={12} sm={6} md={6} lg={6}>
            <HoursWorked />
          </Grid> */}
        </Grid>

        <div style={{ marginTop: 10 }}>
          <CardHealthyDataEmployee employeeData={employeeData} />
        </div>

        <div style={{ marginTop: 10, marginBottom: 10 }}>
          <CardFileEmployee
            files={employeeData.documentos}
            statusDemek={employeeData.statusDemek}
          />
        </div>
      </Grid>
    </Grid>
  );
}
