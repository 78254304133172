/*eslint-disable*/
import { useEffect, useState } from "react";
import axios from "axios";
import axiosInstance from "../../../utils/axiosInstance";

export default function useBookSearch(limit, pageNumber) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [books, setBooks] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    setBooks([]);
  }, [limit]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    var params = new URLSearchParams();
    params.append("limit", limit);
    params.append("page", pageNumber);
    params.append(
      "subcontratistas_id",
      localStorage.getItem("idSubcontractor")
    );

    axiosInstance()
      .get("/api/v1/rules/historial", { params, cancelToken: source.token })
      .then((res) => {
        if (limit == 0) {
          setBooks(res.data.data);
          setHasMore(false);
        } else {
          setBooks((prevBooks) => {
            return [...new Set([...prevBooks, ...res.data.data.Results])];
          });
          setHasMore(res.data.data.Results.length > 0);
          setTotal(res.data.data.Total);
        }

        setLoading(false);
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;
        setError(true);
      });
    return () => source.cancel();
  }, [pageNumber, limit]);

  return { loading, error, books, hasMore, total };
}
