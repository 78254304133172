/*eslint-disable*/
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { SnackbarProvider } from "notistack";
import ProtectedRoute from "../ProtectedRoute";
import LoginScreen from "../LoginScreen/LoginScreenV2";
import RegisterScreen from "../RegisterScreen/RegisterScreenV2";
import PasswordResetScreen from "../PasswordResetScreen/PasswordResetScreen";
import LayoutScreen from "../LayoutScreen/LayoutScreen";
import ChooseScreen from "../WelcomeScreen/ChooseScreen";
import NotFoundScreen from "../NotFoundScreen/NotFoundScreen";
import Fade from "@material-ui/core/Fade";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
  faExclamationTriangle,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";

// const useStyles = makeStyles((theme) => ({
//   error: { backgroundColor: "#2d3034" },
//   info: { backgroundColor: "#2d3034" },
//   success: { backgroundColor: "#2d3034" },
//   warning: { backgroundColor: "#2d3034" },
// }));

// const GetToken = () => {
//   return localStorage.getItem("JWT");
// };

// function HandleAuth() {
//   console.log("entre al handle out");
//   return localStorage.getItem("JWT");
// }

// const AdminRoutes = () => {
//   return (
//     <BrowserRouter>
//       <Switch>
//         <Route
//           path="/inicio_sesion"
//           render={(props) => <LoginScreen {...props} HandleAuth={HandleAuth} />}
//         ></Route>
//         <Route
//           path="/"
//           render={(props) => <LoginScreen {...props} HandleAuth={HandleAuth} />}
//         ></Route>
//         <Route path="/register" component={RegisterScreen}></Route>
//         <ProtectedRoute path="/welcome" user={true} component={ChooseScreen} />
//         <ProtectedRoute
//           path="/dashboard/dashboard"
//           user={true}
//           component={LayoutScreen}
//         />

//         <Route path="*" exact={true} component={NotFoundScreen} />
//       </Switch>
//     </BrowserRouter>
//   );
// };

// const NoAuthRoutes = () => {
//   return (
//     <BrowserRouter>
//       <Switch>
//         <Route
//           path="/inicio_sesion"
//           render={(props) => <LoginScreen {...props} HandleAuth={HandleAuth} />}
//         ></Route>
//         <Route
//           path="/"
//           render={(props) => <LoginScreen {...props} HandleAuth={HandleAuth} />}
//         ></Route>
//         <Route path="/register" component={RegisterScreen}></Route>
//         <Route path="*" exact={true} component={NotFoundScreen} />
//       </Switch>
//     </BrowserRouter>
//   );
// };

// const Permissions = {
//   admin: AdminRoutes(),
//   noathu: NoAuthRoutes(),
// };

const useStyles = makeStyles((theme) => ({
  error: { color: "#000", backgroundColor: "#fff" },
  info: { color: "#000", backgroundColor: "#fff" },
  success: { color: "#000", backgroundColor: "#fff" },
  warning: { color: "#000", backgroundColor: "#fff" },
}));

function App() {
  const classes = useStyles();
  const { colors } = useSelector((state) => state.config);

  const theme = createTheme({
    palette: colors,
    overrides: {
      // MuiSnackbarContent: {
      //   root: {
      //     '&[class*="variantSuccess"]': {
      //       backgroundColor: "#fff",
      //       color: "#2b2b2b",
      //     },
      //     '&[class*="variantError"]': {
      //       backgroundColor: "#fff",
      //       color: "#2b2b2b",
      //     },
      //     '&[class*="variantWarning"]': {
      //       backgroundColor: "#fff",
      //       color: "#2b2b2b",
      //     },
      //     '&[class*="variantInfo"]': {
      //       backgroundColor: "#fff",
      //       color: "#2b2b2b",
      //     },
      //   },
      // },
      MuiCircularProgress: {
        colorPrimary: {
          color: "#50b9ff",
          // backgroundColor: "#6d6d6d",
        },
      },
      MuiLinearProgress: {
        root: {
          height: "8px",
          borderRadius: "20px",
        },
        colorPrimary: {
          backgroundColor: "#bcbcbc",
        },
        barColorPrimary: {
          color: "#50b9ff",
        },
      },
      // MuiDrawer: {
      //   // Name of the rule
      //   paper: {
      //     Some CSS
      //     backgroundColor: "#50B9FF",
      //     paddingTop: "24px",
      //   },
      // },
      MuiListItem: {
        root: {
          color: "black",
        },
      },
      MuiLink: {
        root: {
          activeClassName: {
            backgroundColor: "#50B9FF",
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        // autoHideDuration={null}
        // persist={true}
        TransitionComponent={Fade}
        classes={{
          variantSuccess: classes.success,
          variantError: classes.error,
          variantWarning: classes.warning,
          variantInfo: classes.info,
        }}
        iconVariant={{
          success: (
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{
                width: "20px",
                height: "20px",
                color: "#0C6BD8",
                marginRight: "10px",
              }}
            />
          ),
          error: (
            <FontAwesomeIcon
              icon={faTimesCircle}
              style={{
                width: "20px",
                height: "20px",
                color: "#d60101",
                marginRight: "10px",
              }}
            />
          ),
          warning: (
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              style={{
                width: "20px",
                height: "20px",
                color: "#e2bc00",
                marginRight: "10px",
              }}
            />
          ),
          info: (
            <FontAwesomeIcon
              icon={faInfoCircle}
              style={{
                width: "20px",
                height: "20px",
                color: "#8e8e8e",
                marginRight: "10px",
              }}
            />
          ),
        }}
      >
        <BrowserRouter>
          <Switch>
            <Route path="/inicio_sesion" component={LoginScreen} />
            <Route exact path="/login">
              <Redirect to="/inicio_sesion" />
            </Route>
            <Route exact path="/">
              {localStorage.getItem("JWT") ? (
                <Redirect to="/dashboard/" />
              ) : (
                <Redirect to="/inicio_sesion" />
              )}
            </Route>
            <Route path="/registro" component={RegisterScreen} />
            <Route exact path="/register">
              <Redirect to="/registro" />
            </Route>
            <Route
              path="/recuperacion_contraseña"
              component={PasswordResetScreen}
            />
            <ProtectedRoute path="/dashboard" component={LayoutScreen} />
            <ProtectedRoute path="/bienvenido" component={ChooseScreen} />
            <Route exact path="/welcome">
              <Redirect to="/bienvenido" />
            </Route>
            <Route path="*" exact={true} component={NotFoundScreen} />
            {/* <Route path="/dashboard/*" exact={true} component={NotFoundScreen} /> */}
          </Switch>
        </BrowserRouter>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
