/*eslint-disable*/
import { useEffect, useState } from "react";
import axios from "axios";
import axiosInstance from "../../../../utils/axiosInstance";

export default function useBookSearch(query, proyectos, subcontratistas) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [books, setBooks] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setBooks([]);
  }, [query]);

  useEffect(() => {
    if (
      !Object.values(query).some((x) => x == "") &&
      !Object.values(query).some((x) => x == null)
    ) {
      let Subcontratistas =
        query.subcontratistas.length > 0
          ? query.subcontratistas
          : subcontratistas;
      let Proyectos = query.proyectos.length > 0 ? query.proyectos : proyectos;

      setLoading(true);
      setError(false);
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      // console.log("query: ", query);

      var params = new URLSearchParams();
      params.append(
        "initialDate",
        new Date(query.initialDate.setHours(0, 0, 0, 0))
      );
      params.append(
        "finalDate",
        new Date(query.finalDate.setHours(23, 59, 59, 999))
      );

      Proyectos?.map((proyecto) => {
        params.append("proyecto_string", proyecto.label);
      });

      query.unidades?.map((unidad) => {
        params.append("unidad_string", unidad.label);
      });

      Subcontratistas?.map((subcontratista) => {
        params.append("subcontratista_string", subcontratista.label);
      });

      axiosInstance()
        .get("/api/v1/rules/workingtime/resumenV2", {
          params,
          cancelToken: source.token,
        })
        .then((res) => {
          // console.log(res.data.data);
          setBooks(res.data.data);
          // setHasMore(res.data.data.Results.length > 0);
          setLoading(false);
        })
        .catch((e) => {
          setError(true);
          setLoading(false);
          if (axios.isCancel(e)) return;
        });
      return () => source.cancel();
    }
  }, [query]);

  return { loading, error, books, hasMore };
}
