/*eslint-disable*/
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { Typography, TextField, Button, Grid } from "@material-ui/core";
import { ipServer } from "../../../config/vars";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  divMain: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    padding: theme.spacing(5),
    // backgroundColor: "blue",
  },
  divBack: {
    cursor: "pointer",
    marginBottom: "20px",
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 32,
    fontWeight: 600,
    color: theme.palette.secondary.dark,
    marginTop: "12%",
  },
  subtitle: {
    fontFamily: "Roboto",
    fontSize: 18,
    color: theme.palette.secondary.light,
  },
  textFieldLabel: {
    fontFamily: "Roboto",
    fontSize: 25,
    color: theme.palette.secondary.light,
    marginTop: "15%",
  },
  textFieldCustom: {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& label.Mui-focused": {
      color: "#6C6C6C",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "yellow",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#E6E6E6",
      },
      "&:hover fieldset": {
        borderColor: "#6C6C6C",
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.primary.main,
      },
    },
    boxShadow: "0 0 10px 0 rgba(155, 155, 155, 0.22)",
    borderRadius: "4px",
    backgroundColor: "#fff",
  },
  button: {
    height: "56px",
    marginTop: "20%",
    marginBottom: "20%",
    borderRadius: "4px",
    backgroundColor: theme.palette.primary.main,
    textTransform: "none",
  },
  typoButton: {
    fontFamily: "Roboto",
    fontSize: 28,
    fontWeight: 300,
    color: theme.palette.neutral.primary,
  },
}));

export default function RecoveryCode(props) {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleChange = (e) => {
    // console.log(e.target.value);
    props.setPasswordData({
      ...props.passwordData,
      [e.target.name]: e.target.value,
    });
  };

  const checkCode = (code) => {
    if (code == "") {
      enqueueSnackbar("Favor de ingresar el codigo de recuperación", {
        variant: "error",
      });
      return false;
    }
    return true;
  };

  const onSubmit = () => {
    if (!checkCode(props.passwordData.code)) return;

    axios
      .post(
        `${ipServer}/api/v1/security/user/password/checkcodepasswordrenew/`,
        {
          Email: props.passwordData.email,
          Code: props.passwordData.code,
        }
      )
      .then((response) => {
        // console.log(response.data);
        props.setStep(3);
        props.setPasswordData({
          ...props.passwordData,
          token: response.data.data.token,
        });
      })
      .catch((e) => {
        if (e.response.data) {
          console.log(e.response.data);
          if (
            e.response.data.errors == "Wrong code, 2 attempts left" ||
            e.response.data.errors == "Wrong code, 1 attempts left" ||
            e.response.data.errors == "Wrong code, 0 attempts left"
          ) {
            enqueueSnackbar(
              "Codigo incorrecto, favor de ingresar el codigo correcto",
              {
                variant: "error",
              }
            );
          }
          if (
            e.response.data.errors == "Your attempts are over, resend new code"
          ) {
            enqueueSnackbar(
              "Haz excedido el numero de intentos permitidos, favor de enviar el codigo a tu correo",
              {
                variant: "error",
              }
            );
            setTimeout(() => {
              location.reload();
            }, 3000);
          }
        } else {
          console.log(e.response);
        }
      });
  };

  return (
    <div className={classes.divMain}>
      <Grid
        item
        xs={10}
        container
        direction="column"
        alignItems="flex-start"
        style={{
          width: "503px",
          height: "100%",
        }}
      >
        <Typography className={classes.title}>
          Ingresa el código de recuperación
        </Typography>
        <Typography className={classes.subtitle}>
          Ingresa el código de recuperación para reestablecer tu contraseña
        </Typography>

        <Typography className={classes.textFieldLabel}>
          Código de recuperación
        </Typography>
        <TextField
          id="code"
          type="number"
          variant="outlined"
          fullWidth
          name="code"
          size="medium"
          autoComplete="code"
          onChange={handleChange}
          className={classes.textFieldCustom}
          onInput={(e) => {
            e.target.value = Math.max(0, parseInt(e.target.value))
              .toString()
              .slice(0, 6);
          }}
        />

        <Button
          fullWidth
          variant="contained"
          onClick={onSubmit}
          className={classes.button}
        >
          <Typography className={classes.typoButton}>Siguiente</Typography>
        </Button>
      </Grid>
    </div>
  );
}
