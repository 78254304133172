/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, TextField, InputAdornment } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faArrowAltCircleLeft,
} from "@fortawesome/free-solid-svg-icons";
import DateRange from "../../../common_components/Filters/DateRange";
import DataGridEntryPass from "./components/DataGridEntryPass";
import EntryPassSpecificScreen from "./components/EntryPassScpecificScreen";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    padding: theme.spacing(3),
    // width: "100px",
    // height: "100px",
    // backgroundColor: "#36393C",
  },
  divBack: {
    cursor: "pointer",
    marginBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
  back: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 500,
    textAlign: "left",
    color: "#ffffff",
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 32,
    fontWeight: 500,
    lineHeight: 1.34,
    textAlign: "left",
    color: "#fff",
    marginBottom: 20,
  },
  textFieldCustom: {
    width: 466,
    color: "#676767",
    borderRadius: "8px",
    backgroundColor: "#ffffff",
  },
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 16,
    textAlign: "left",
    color: "#dedcdc",
  },
}));

export default function EntryPassScreen(props) {
  const classes = useStyles();
  const history = useHistory();
  const [selectionRows, setSelectionRows] = useState([]);
  const [query, setQuery] = useState({
    Empleado: "",
    Subcontratistas: [],
    Obras: [],
    pageSize: 10,
    rowsPerPageOptions: [5, 10, 15],
    page: 1,
  });
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(Date.now() - 604800000),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  // const { rows, totalRows, loading, error } = useUserSearch(
  //   query,
  //   loadingData,
  //   subcontratistas,
  //   obras
  // );

  const rows = [
    {
      id: 1,
      OrdenCompra: "#232",
      Proveedor: "Plomeria Juanito",
      Almacenista: "Javier Medina ",
      Entregado: "Alberto Martinez",
      Articulos: 0,
      // Estatus: "Recolectado",
      Fecha: "11 de Sept 2020",
    },
  ];

  const handleSelect = (ranges) => {
    setDateRange([ranges.selection]);
    updateData("intialDate", ranges.selection.startDate);
    updateData("finalDate", ranges.selection.endDate);
  };

  return (
    <div className={classes.divMain}>
      <Grid
        item
        xs={6}
        sm={4}
        md={2}
        lg={2}
        xl={2}
        container
        direction="row"
        alignItems="center"
        onClick={() => history.push(`/dashboard/almacen`)}
        className={classes.divBack}
      >
        <Grid item>
          <FontAwesomeIcon
            icon={faArrowAltCircleLeft}
            style={{
              width: "20px",
              height: "20px",
              marginRight: "10px",
              color: "#d6d6d6",
            }}
          />
        </Grid>
        <Grid item>
          <Typography className={classes.back}>Regresar</Typography>
        </Grid>
      </Grid>
      <Grid item xs={6} sm={8} md={10} lg={10} xl={10}></Grid>

      <Grid container direction="column">
        <Typography className={classes.title}>Pases de entrada</Typography>

        <Grid container direction="row" alignContent="center">
          <Grid item style={{ marginBottom: 24 }}>
            <Typography className={classes.textFieldLetter}>Fecha</Typography>

            <DateRange value={dateRange} onChange={handleSelect} />
          </Grid>

          {/*<Grid item xs={2}>
    //     <Typography
    //       style={{
    //         fontFamily: "Roboto",
    //         fontSize: 16,
    //         textAlign: "left",
    //         color: "#dedcdc",
    //       }}
    //     >
    //       Obras
    //     </Typography>
    //     <div>
    //       <MultiSelect
    //         options={obras ? obras : []}
    //         value={query.Obras}
    //         onChange={handleQueryChange("Obras")}
    //       />
    //     </div>
    //   </Grid>

    //   <Grid item xs={2} style={{ marginLeft: 30 }}>
    //     <Typography
    //       style={{
    //         fontFamily: "Roboto",
    //         fontSize: 16,
    //         textAlign: "left",
    //         color: "#dedcdc",
    //       }}
    //     >
    //       Subcontratista
    //     </Typography>
    //     <div>
    //       <MultiSelect
    //         options={subcontratistas ? subcontratistas : []}
    //         value={query.Subcontratistas}
    //         onChange={handleQueryChange("Subcontratistas")}
    //       />
    //     </div>
    //   </Grid>*/}
        </Grid>

        <Grid>
          <TextField
            placeholder="Buscar por número de Orden de compra"
            variant="outlined"
            size="small"
            // onChange={handleQueryChange("Empleado")}
            className={classes.textFieldCustom}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon
                    icon={faSearch}
                    style={{
                      width: "18px",
                      height: "18px",
                      color: "#676767",
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <DataGridEntryPass
          rows={rows ?? []}
          // loading={loading}
          // rowCount={totalRows}
          rowCount={rows.length}
          rowsPerPageOptions={query.rowsPerPageOptions}
          page={query.page}
          pageSize={query.pageSize}
          selectionModel={selectionRows}
          onSelectionModelChange={(ids) => {
            setSelectionRows(ids);
            // history.push(`/dashboard/almacen/pase-entrada/${ids}`);
            history.push(
              `/dashboard/almacen/pase-entrada/62b4fac854b8f89b4350fedb`
            );
          }}
          // onPageChange={(data) => {
          //   updateData("page", data + 1);
          // }}
          // onPageSizeChange={(data) => {
          //   updateData("page", 1);
          //   updateData("pageSize", data);
          // }}
          style={{ height: 680, width: "100%", display: "flex" }}
        />
      </Grid>
    </div>
  );
}
