/*eslint-disable*/
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { isObjectId } from "./CheckObjectId";

export const ValidatePermissions = (permission) => {
  const { permissions, modules, keyPermission } = useSelector((state) => state.permissions);
  const location = useLocation();
  const pathArray = location.pathname.split("/");
  let path = pathArray.pop();

  if (path && permissions && modules) {
    if (isObjectId(path)) {
      path = pathArray.pop();
    }

    if(keyPermission.includes(path)) {
      path = pathArray.pop();
    }

    const keyModule = modules.find(
      (_module) => _module.url.split("/").pop() == path
    );

    return permissions.find((x) => x.name == keyModule.nombre)[permission];
  }

  return false;
};

export const ValidatePermissionsFn = (permission, permissions, modules, keyPermission, location) => {
  // const { permissions, modules, keyPermission } = useSelector((state) => state.permissions);
  // const location = useLocation();
  const pathArray = location.split("/");
  let path = pathArray.pop();

  if (path && permissions && modules) {
    if (isObjectId(path)) {
      path = pathArray.pop();
    }

    if(keyPermission.includes(path)) {
      path = pathArray.pop();
    }

    const keyModule = modules.find(
      (_module) => _module.url.split("/").pop() == path
    );

    return permissions.find((x) => x.name == keyModule.nombre)[permission];
  }

  return false;
};


export const ValidatePermissionsModule = (permission, module) => {
  const { permissions } = useSelector((state) => state.permissions);

  if (module && permissions) {
    const keyModule = permissions.find(x => x.name.toLowerCase() == module.toLowerCase())
    return keyModule && keyModule[permission]
    // const keyModule = Object.keys(permissions).find(
    //   (_permissions) => _permissions.toLowerCase() == module.toLowerCase()
    // );
    // const keyPermissions =
    //   keyModule &&
    //   Object.keys(permissions[keyModule]).find(
    //     (_permissions) => _permissions.toLowerCase() == permission.toLowerCase()
    //   );

    // return (
    //   keyModule && keyPermissions && permissions[keyModule][keyPermissions]
    // );
  }

  return false;
};

// export const ValidatePermissionsModule = (permission, module) => {
//   const { permissions } = useSelector((state) => state.permissions);

//   if (module && permissions) {
//     const keyModule = Object.keys(permissions).find(
//       (_permissions) => _permissions.toLowerCase() == module.toLowerCase()
//     );
//     const keyPermissions =
//       keyModule &&
//       Object.keys(permissions[keyModule]).find(
//         (_permissions) => _permissions.toLowerCase() == permission.toLowerCase()
//       );

//     return (
//       keyModule && keyPermissions && permissions[keyModule][keyPermissions]
//     );
//   }

//   return false;
// };

export const ValidateStatus = (status, user) => {
  if (status === "Vetado" || status === "Boletinado") return false;

  if (
    status === "Borrador" ||
    status === "Información rechazada" ||
    (user && user.Subcontractor[0].empresaPadre)
  ) {
    return true;
  }

  return false;
};
