/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Typography, Popover } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleLeft,
  faArrowRight,
  faAngleDown,
  faAngleUp,
} from "@fortawesome/free-solid-svg-icons";
import { makeStyles } from "@material-ui/styles";
import svgMedkit from "../../../images/icono_info_medica.svg";
import svgTrabajador from "../../../images/usuario_trabajador.svg";
import DataGridWorkingTime from "./Components/DataGridWorkingTime";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import { useSelector } from "react-redux";
import useWorkingtimeDetail from "./useWorkingtimeDetail";
import CardAttendanceEvents from "../attendance/Components/CardAttendanceEvents";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiAvatarGroup-avatar ": {
      width: 20,
      height: 20,
    },
  },
  divMain: {
    flexGrow: 1,
    paddingTop: 10,
    height: "100%",
  },
  divBack: {
    maxWidth: "120px",
    cursor: "pointer",
    marginBottom: "10px",
  },
  back: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 500,
    textAlign: "left",
    color: "#fff",
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 32,
    fontWeight: 500,
    textAlign: "left",
    color: "#ffffff",
  },
}));

export default function WorkingTimeDetailScreen(props) {
  const classes = useStyles();
  const history = useHistory();
  const { workingTimeSelected } = useSelector((state) => state.workingTime);
  const { workingTime } = useSelector((state) => state.filters);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dataMenu, setDataMenu] = useState(null);
  const [more, setMore] = useState(false);
  const [query, setQuery] = useState({
    pageSize: 10,
    rowsPerPageOptions: [5, 10, 15],
    page: 1,
  });

  const { loading, error, rows, total, dynamicColumns } =
    useWorkingtimeDetail(query);

  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);
    setDataMenu(data);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setDataMenu([]);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const updateData = (k, v) => {
    setQuery((prev) => ({ ...prev, [k]: v }));
  };

  const backStep = () => {
    history.push(`/dashboard/reportes/tiempo-trabajo`);
  };

  function secondsToHms(d) {
    d = Number(d);
    var hours = Math.floor(d / 3600);
    var minutes = Math.floor((d % 3600) / 60);
    var seconds = Math.floor((d % 3600) % 60);
    return {
      hours,
      minutes,
      seconds,
    };
  }

  // useEffect(() => {
  //   console.log(query);
  //   console.log(workingTime);
  //   console.log(workingTimeSelected);
  // }, [query]);

  return (
    <div className={classes.divMain}>
      <Grid container direction="column">
        <Grid
          container
          direction="row"
          alignItems="center"
          onClick={backStep}
          className={classes.divBack}
        >
          <Grid item>
            <FontAwesomeIcon
              icon={faArrowAltCircleLeft}
              style={{
                width: "20px",
                height: "20px",
                marginRight: "10px",
                color: "#d6d6d6",
              }}
            />
          </Grid>
          <Grid item>
            <Typography className={classes.back}>Regresar</Typography>
          </Grid>
        </Grid>

        <Typography
          onClick={handleClick}
          style={{
            marginBottom: 10,
            marginTop: 10,
            fontFamily: "Roboto",
            fontSize: 30,
            fontWeight: 500,
            textAlign: "left",
            color: "#fff",
          }}
        >
          Working time
        </Typography>

        <Grid container direction="column">
          <Grid
            container
            direction="row"
            alignItems="center"
            style={{
              backgroundColor: "#2d3034",
              width: 602,
              height: 104,
              borderRadius: 8,
              marginTop: 30,
            }}
          >
            <Grid item xs={2} container direction="row" justifyContent="center">
              <img
                src={workingTimeSelected?.urlPhoto}
                style={{
                  width: 64,
                  height: 64,
                  borderRadius: 5,
                  aspectRatio: 1,
                  objectFit: "contain",
                }}
                alt="photo"
              />
            </Grid>
            <Grid item xs={4} container direction="column">
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 20,
                  textAlign: "left",
                  color: "#fff",
                  fontWeight: 500,
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  maxWidth: "90%",
                }}
              >
                {workingTimeSelected?.enterprise
                  ? workingTimeSelected?.enterprise
                  : "--"}
              </Typography>

              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 16,
                  textAlign: "left",
                  color: "#b2b2b2",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  maxWidth: "90%",
                }}
              >
                {workingTimeSelected?.businessName
                  ? workingTimeSelected?.businessName
                  : "--"}
              </Typography>

              <Grid container direction="row">
                <img
                  src={svgMedkit}
                  style={{
                    width: 13,
                    height: 13,
                    marginTop: 1,
                  }}
                  alt="techton logo"
                />
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 12,
                    textAlign: "left",
                    color: "#fff",
                    marginLeft: 6,
                  }}
                >
                  {workingTimeSelected?.securityNumber
                    ? workingTimeSelected?.securityNumber
                    : "--"}
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={3} container direction="column">
              <Grid container direction="row" alignItems="flex-end">
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 32,
                    textAlign: "left",
                    color: "#fff",
                  }}
                >
                  {secondsToHms(workingTimeSelected?.seconds).hours}
                </Typography>

                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 12,
                    textAlign: "left",
                    color: "#cccccc",
                    marginLeft: 6,
                    marginBottom: 7,
                  }}
                >
                  Hrs
                </Typography>

                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 32,
                    textAlign: "left",
                    color: "#fff",
                    marginLeft: 6,
                  }}
                >
                  {secondsToHms(workingTimeSelected?.seconds).minutes}
                </Typography>

                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 12,
                    textAlign: "left",
                    color: "#cccccc",
                    marginLeft: 6,
                    marginBottom: 7,
                  }}
                >
                  min
                </Typography>
              </Grid>

              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 12,
                  textAlign: "left",
                  color: "#cccccc",
                }}
              >
                Total horas trabajadas
              </Typography>
            </Grid>

            <Grid
              item
              xs={3}
              container
              direction="column"
              className={classes.root}
            >
              <Grid container>
                <AvatarGroup max={4}>
                  <Avatar
                    style={{
                      width: 20,
                      height: 20,
                    }}
                    alt="Remy Sharp"
                    src="/static/images/avatar/1.jpg"
                  />
                  <Avatar
                    style={{
                      width: 20,
                      height: 20,
                    }}
                    alt="Travis Howard"
                    src="/static/images/avatar/2.jpg"
                  />
                  <Avatar
                    style={{
                      width: 20,
                      height: 20,
                    }}
                    alt="Cindy Baker"
                    src="/static/images/avatar/3.jpg"
                  />
                  <Avatar
                    style={{
                      width: 20,
                      height: 20,
                    }}
                    alt="Agnes Walker"
                    src="/static/images/avatar/4.jpg"
                  />
                  <Avatar
                    style={{
                      width: 20,
                      height: 20,
                    }}
                    alt="Trevor Henderson"
                    src="/static/images/avatar/5.jpg"
                  />
                </AvatarGroup>
              </Grid>

              <Grid container direction="row" style={{ marginTop: 8 }}>
                <img
                  src={svgTrabajador}
                  style={{
                    width: 12,
                    height: 14,
                  }}
                  alt="techton logo"
                />

                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 12,
                    textAlign: "left",
                    color: "#fff",
                    marginLeft: 6,
                  }}
                >
                  {`${workingTimeSelected?.employees} trabajadores`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid container>
            <DataGridWorkingTime
              menu={handleClick}
              rows={rows ? rows : []}
              loading={loading}
              rowCount={total}
              rowsPerPageOptions={query.rowsPerPageOptions}
              page={query.page}
              pageSize={query.pageSize}
              dynamicColumns={dynamicColumns}
              onPageChange={(data) => {
                updateData("page", data + 1);
              }}
              onPageSizeChange={(data) => {
                updateData("page", 1);
                updateData("pageSize", data);
              }}
              style={{ height: 680, width: "100%", display: "flex" }}
            />
          </Grid>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            PaperProps={{
              style: {
                width: 305,
                height: "auto",
                borderRadius: 12,
                boxShadow: "0 4px 10px 0 rgba(94, 140, 148, 0.16)",
                backgroundColor: "#212429",
                padding: "15px 15px 0px 15px",
                marginTop: 8,
              },
            }}
          >
            {dataMenu
              ?.slice(0, more ? Object.keys(dataMenu).length : 2)
              ?.map((_attendance, index) => (
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  style={{ marginBottom: 8 }}
                >
                  <Grid item xs={4}>
                    <CardAttendanceEvents
                      data={_attendance.entrada > 0 && _attendance.entrada}
                      event="Entrada"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      style={{
                        width: 25,
                        height: 25,
                        color: "#fff",
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CardAttendanceEvents
                      data={_attendance.salida > 0 && _attendance.salida}
                      event="Salida"
                    />
                  </Grid>
                </Grid>
              ))}

            {dataMenu?.length > 2 && (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                onClick={() => setMore(!more)}
                style={{
                  backgroundColor: "#4f5257",
                  width: "40%",
                  height: 26,
                  padding: 5,
                  borderTopLeftRadius: 8,
                  borderTopRightRadius: 8,
                  cursor: "pointer",
                  bottom: 0,
                  margin: "auto",
                  marginTop: 20,
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 13,
                    textAlign: "center",
                    color: "#fff",
                  }}
                >
                  {!more ? "Ver más" : "Ver menos"}
                </Typography>

                <FontAwesomeIcon
                  icon={!more ? faAngleDown : faAngleUp}
                  style={{
                    width: 22,
                    height: 14,
                    color: "#fff",
                    marginTop: 2,
                  }}
                />
              </Grid>
            )}
          </Popover>
        </Grid>
      </Grid>
    </div>
  );
}
