/*eslint-disable*/
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { Grid, Typography, Button } from "@material-ui/core";
import Lottie from "react-lottie";
import Logo from "../../images/absa-industrial.png";
import NotFoundAnimation from "../../animations/techton_not_found.json";

const useStyles = makeStyles((theme) => ({
  divMain: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.neutral.primary,
  },
  logo: {
    marginTop: 30,
    marginLeft: 50,
    width: "230px",
    height: "120px",
  },
  gridMain: {
    position: "absolute",
    top: "20%",
    // backgroundColor: "red",
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 40,
    fontWeight: 500,
    color: theme.palette.secondary.dark,
  },
  subtitle: {
    fontFamily: "Roboto",
    fontSize: 22,
    color: theme.palette.secondary.light,
    marginBottom: "20px",
  },
  typoError: {
    fontFamily: "Roboto",
    fontSize: 26,
    fontWeight: 300,
    color: theme.palette.secondary.light,
    marginBottom: "20px",
  },
  button: {
    width: "240px",
    height: "48px",
    borderRadius: "8px",
    backgroundColor: theme.palette.primary.main,
    textTransform: "none",
  },
  typoButton: {
    fontFamily: "Roboto",
    fontSize: 18,
    color: theme.palette.neutral.primary,
  },
}));

export default function NotFoundScreen(props) {
  const classes = useStyles();
  const history = useHistory();

  const backDashboard = () => {
    if (localStorage.getItem("JWT")) {
      history.push("/dashboard/videos");
    } else {
      history.push("/inicio_sesion");
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: NotFoundAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className={classes.divMain}>
      <Grid container direction="row" justifyContent="flex-start">
        <img src={Logo} className={classes.logo} alt="logo" />
      </Grid>

      <Grid
        item
        xs={12}
        container
        direction="row"
        justifyContent="flex-start"
        className={classes.gridMain}
      >
        <Grid item xs={2}></Grid>
        <Grid item xs={5} container direction="column" justifyContent="center">
          <Typography className={classes.title}>
            PAGINA NO ENCONTRADA
          </Typography>
          <Typography className={classes.subtitle}>ERROR 404</Typography>
          <Typography className={classes.typoError}>
            Parece ser que la pagina que buscas no existe
          </Typography>
          <Button
            variant="contained"
            onClick={backDashboard}
            className={classes.button}
          >
            <Typography className={classes.typoButton}>Regresar</Typography>
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Lottie options={defaultOptions} height={400} width={400} />
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
    </div>
  );
}
