/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import InfoBasic from "./Components/infoBasic";
import InfoAdvance from "./Components/infoAdvance";
import InfoFiles from "./Components/infoFiles";
import FlowComponent from "./Components/FlowComponent";
import BarProgressComponent from "./Components/BarProgressComponent";
import { useDispatch, useSelector } from "react-redux";
import { UploadDocuments } from "../../actions/file";
import { LoadUser } from "../../actions/user";
import DialogBackScreen from "./Components/DialogBackScreen";
import ScrollBars from "../../common_components/ScrollBars";
import { ipServer } from "../../config/vars";
import axios from "axios";
import DialogLoadingUploadDocuments from "../RegisterEmployeeScreen/components/DialogLoadingUploadDocuments";
import DialogUnitsList from "../../common_components/Dialogs/DialogUnitsList";
import DialogAdminUnitsList from "../../common_components/Dialogs/DialogAdminUnitsList";
import DialogAddNewUnit from "../../common_components/Dialogs/DialogAddNewUnit";
import { CapitalizeString } from "../../utils/CapitalizeString";
const registroPatronalRegex = /^[a-zA-Z]{1}(\d{10})?$/;

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    paddingLeft: "30px",
    // backgroundColor: "blue",
  },
  divBack: {
    cursor: "pointer",
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
  back: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 500,
    textAlign: "left",
    color: "#ffffff",
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 24,
    fontWeight: 500,
    textAlign: "left",
    color: "#ffffff",
  },
  subtitle: {
    fontFamily: "Roboto",
    fontSize: 14,
    textAlign: "left",
    color: "#ffffff",
  },
  icon: {
    width: "35px",
    height: "35px",
    color: "white",
    backgroundColor: "#a2a2a2",
    borderRadius: "15px",
  },
  gridMargin: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
  gridMargin2: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 20,
    },
  },
}));

export default function RegisterSubcontractorScreen(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { files } = useSelector((state) => state.file);
  const { user } = useSelector((state) => state.user);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [screen, setScreen] = useState(1);
  const [openDialogBack, setOpendialogBack] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [mandatoryFlag, setMandatoryFlag] = useState(false);
  const [changesFlag, setChangesFlag] = useState(false);
  const [changesFile, setChangesFile] = useState(0);
  const [openDialogUnits, setOpenDialogUnits] = useState(false);
  const [openDialogUnitsAdmin, setOpenDialogUnitsAdmin] = useState(false);
  const [openDialogAddNewUnit, setOpenDialogAddNewUnit] = useState(false);
  const [unitsFlag, setUnitsFlag] = useState(false);
  const [colorButtonSave, setColorButtonSave] = useState({
    backColor: "#474A4E",
    text: "#919191",
  });
  const [colorButtonNext, setColorButtonNext] = useState({
    backColor: "#474a4e",
    text: "#919191",
    backArrow: "#676767",
    arrow: "#919191",
  });
  const [subcontractorData, setSubcontractorData] = useState({
    idSubcontractor: "",
    foto: "",
    fotoCache: "",
    empresa: "",
    razonSocial: "",
    descripcion: "",
    rfc: "",
    unidad: "",
    correo: "",
    representante: "",
    telefono: "",
    numeroSeguro: "",
    firstTime: "",
    obra: "",
    activo: "",
    banco: "",
    clabe: "",
    cuentaBancaria: "",
    empleados: "",
    porcentajePerfil: 0,
  });
  const [unit, setUnit] = useState({
    id: "",
    type: "unidad",
    name: "",
    description: "",
  });
  const [userData, setUserData] = useState({
    role: "",
  });

  const backStep = () => {
    if (
      (changesFlag && mandatoryFlag) ||
      files.some((file) => file.process != "db" && file.url === null)
    ) {
      setOpendialogBack(!openDialogBack);
    } else {
      if (user?.RoleName == "Administrador Subcontratista") {
        history.push(
          `/dashboard/miempresa/${localStorage.getItem("idSubcontractor")}`
        );
      } else
        history.push(
          `/dashboard/contratistas/${localStorage.getItem("idSubcontractor")}`
        );
    }
  };

  const nextStep = () => {
    if (screen == 1 && mandatoryFlag == false) {
      enqueueSnackbar("Favor de llenar campos obligatorios", {
        variant: "error",
      });
      return;
    }
    setScreen(screen + 1);
  };

  const lastStep = () => {
    setScreen(screen - 1);
  };

  const renderRegisterScreen = () => {
    if (screen == 1)
      return (
        <InfoBasic
          subcontractorData={subcontractorData}
          setSubcontractorData={setSubcontractorData}
          setMandatoryFlag={setMandatoryFlag}
          setChangesFlag={setChangesFlag}
          openDialogUnits={openDialogUnits}
          setOpenDialogUnits={setOpenDialogUnits}
        />
      );
    if (screen == 2)
      return (
        <InfoAdvance
          subcontractorData={subcontractorData}
          setSubcontractorData={setSubcontractorData}
          setChangesFlag={setChangesFlag}
        />
      );
    if (screen == 3) return <InfoFiles />;
  };

  function rfcValido(rfc, aceptarGenerico = true) {
    const re =
      /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
    var validado = rfc.match(re);

    if (!validado) return false;

    const digitoVerificador = validado.pop(),
      rfcSinDigito = validado.slice(1).join(""),
      len = rfcSinDigito.length,
      diccionario = "0123456789ABCDEFGHIJKLMN&OPQRSTUVWXYZ Ñ",
      indice = len + 1;
    var suma, digitoEsperado;

    if (len == 12) suma = 0;
    else suma = 481;

    for (var i = 0; i < len; i++)
      suma += diccionario.indexOf(rfcSinDigito.charAt(i)) * (indice - i);
    digitoEsperado = 11 - (suma % 11);
    if (digitoEsperado == 11) digitoEsperado = 0;
    else if (digitoEsperado == 10) digitoEsperado = "A";

    if (
      digitoVerificador != digitoEsperado &&
      (!aceptarGenerico || rfcSinDigito + digitoVerificador != "XAXX010101000")
    )
      return false;
    else if (
      !aceptarGenerico &&
      rfcSinDigito + digitoVerificador == "XEXX010101000"
    )
      return false;
    return rfcSinDigito + digitoVerificador;
  }

  const checkRfcFormat = (rfc) => {
    // console.log(curpRegex.test(rfc));
    if (!rfcValido(rfc)) {
      enqueueSnackbar("Favor de ingresar un RFC valido", {
        variant: "error",
      });
      return false;
    }
    return true;
  };

  const checkRegistoPatronal = (numeroSeguro) => {
    // console.log(curpRegex.test(numeroSeguro));
    if (!registroPatronalRegex.test(numeroSeguro)) {
      enqueueSnackbar("Favor de ingresar un Registro Patronal valido", {
        variant: "error",
      });
      return false;
    }
    return true;
  };

  const saveSubcontractor = async () => {
    // console.log(subcontractorData);

    if (mandatoryFlag == false) {
      enqueueSnackbar("Favor de llenar campos obligatorios", {
        variant: "error",
      });
      return;
    }

    if (!checkRfcFormat(subcontractorData.rfc)) return;
    if (subcontractorData.numeroSeguro != "") {
      if (!checkRegistoPatronal(subcontractorData.numeroSeguro)) return;
    }

    setDisableButton(true);
    if (!(await updateSubcontractor()) || !(await savePhotoSubcontractor())) {
      enqueueSnackbar("Error al actualizar Subcontratista", {
        variant: "error",
      });
      return;
    }

    const documents = files.some(
      (file) => file.process != "db" && file.url === null
    );
    if (documents) {
      dispatch(
        UploadDocuments(
          localStorage.getItem("idSubcontractor"),
          files,
          "subcontratistas",
          setChangesFile
        )
      );
    }

    setChangesFlag(false);
    setTimeout(() => {
      setDisableButton(false);
    }, 2000);

    enqueueSnackbar("Subcontratista actualizado correctamente", {
      variant: "success",
    });

    return documents;
  };

  const updateSubcontractor = async () => {
    return axios
      .patch(
        `${ipServer}/api/v1/rules/subcontratistas/` +
          localStorage.getItem("idSubcontractor"),
        {
          empresa: CapitalizeString(subcontractorData.empresa),
          razonSocial: subcontractorData.razonSocial,
          descripcion: subcontractorData.descripcion,
          rfc: subcontractorData.rfc,
          unidad: subcontractorData.unidad,
          correo: subcontractorData.correo,
          representante: CapitalizeString(subcontractorData.representante),
          telefono: subcontractorData.telefono,
          numeroSeguro: subcontractorData.numeroSeguro,
          firstTime:
            userData.role == "Administrador Subcontratista"
              ? false
              : subcontractorData.firstTime,
          banco: subcontractorData.banco,
          cuentaBancaria: subcontractorData.cuentaBancaria,
          clabe: subcontractorData.clabe,
        },
        {
          headers: {
            Authorization: localStorage.getItem("JWT"),
          },
        }
      )
      .then((response) => {
        // console.log("update", response.data.data);
        setSubcontractorData({
          ...subcontractorData,
          porcentajePerfil: response.data.data.percentage,
        });
        // dispatch(LoadUser({ ...user, Subcontractor: [response.data.data] }));
        return true;
      })
      .catch((e) => {
        console.log(e);
        return false;
      });
  };

  const savePhotoSubcontractor = async () => {
    // console.log(typeof subcontractorData.foto);
    if (typeof subcontractorData.foto === "string") {
      // console.log("foto es string");
      return true;
    }

    let formData = new FormData();
    formData.append("file", subcontractorData.foto);
    formData.append(
      "data",
      JSON.stringify({
        name: "foto",
      })
    );

    return await axios
      .patch(
        `${ipServer}/api/v1/rules/subcontratistas/` +
          localStorage.getItem("idSubcontractor") +
          `/document`,
        formData,
        {
          headers: {
            Authorization: localStorage.getItem("JWT"),
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        // console.log(response.data.data);
        return true;
      })
      .catch((e) => {
        console.log(e);
        return false;
      });
  };

  useEffect(() => {
    if (mandatoryFlag == true) {
      setColorButtonSave({
        ...colorButtonSave,
        backColor: "#ac0bce",
        text: "#ffffff",
      });
      setColorButtonNext({
        ...colorButtonNext,
        backColor: "#2d3034",
        text: "#fff",
        backArrow: "#d233ff",
        arrow: "#fff",
      });
    } else {
      setColorButtonSave({
        ...colorButtonSave,
        backColor: "#474A4E",
        text: "#919191",
      });
      setColorButtonNext({
        ...colorButtonNext,
        backColor: "#474a4e",
        text: "#919191",
        backArrow: "#676767",
        arrow: "#919191",
      });
    }
  }, [mandatoryFlag]);

  useEffect(() => {
    if (screen != 1) {
      setDisableButton(false);
      setMandatoryFlag(true);
    }

    if (user) {
      setUserData({
        ...userData,
        role: user.RoleName,
      });
    }

    axios
      .get(
        `${ipServer}/api/v1/rules/subcontratistas/` +
          localStorage.getItem("idSubcontractor"),
        {
          headers: {
            Authorization: localStorage.getItem("JWT"),
          },
        }
      )
      .then((response) => {
        // console.log("load", response.data.data[0]);
        setSubcontractorData({
          ...subcontractorData,
          idSubcontractor: response.data.data[0]._id,
          foto: response.data.data[0].foto
            ? response.data.data[0].foto.url
            : subcontractorData.foto,
          fotoCache: response.data.data[0].foto
            ? response.data.data[0].foto.url
            : subcontractorData.fotoCache,
          empresa: response.data.data[0].empresa
            ? response.data.data[0].empresa
            : subcontractorData.empresa,
          razonSocial: response.data.data[0].razonSocial
            ? response.data.data[0].razonSocial
            : subcontractorData.razonSocial,
          descripcion: response.data.data[0].descripcion
            ? response.data.data[0].descripcion
            : subcontractorData.descripcion,
          rfc: response.data.data[0].rfc
            ? response.data.data[0].rfc
            : subcontractorData.rfc,
          unidad: response.data.data[0].unidad
            ? response.data.data[0].unidad
            : subcontractorData.unidad,
          correo: response.data.data[0].correo,
          representante: response.data.data[0].representante
            ? response.data.data[0].representante
            : subcontractorData.representante,
          telefono: response.data.data[0].telefono
            ? response.data.data[0].telefono
            : subcontractorData.telefono,
          numeroSeguro: response.data.data[0].numeroSeguro
            ? response.data.data[0].numeroSeguro
            : subcontractorData.numeroSeguro,
          firstTime: response.data.data[0].firstTime
            ? response.data.data[0].firstTime
            : subcontractorData.firstTime,
          banco: response.data.data[0].banco
            ? response.data.data[0].banco
            : subcontractorData.banco,
          clabe: response.data.data[0].clabe
            ? response.data.data[0].clabe
            : subcontractorData.clabe,
          cuentaBancaria: response.data.data[0].cuentaBancaria
            ? response.data.data[0].cuentaBancaria
            : subcontractorData.cuentaBancaria,
          porcentajePerfil: response.data.data[0].percentage
            ? response.data.data[0].percentage
            : subcontractorData.porcentajePerfil,
        });
      })
      .catch((e) => {
        console.log(e.response);
      });
  }, []);

  useEffect(() => {
    if (changesFile != 0) {
      setSubcontractorData({
        ...subcontractorData,
        porcentajePerfil: changesFile,
      });
    }
  }, [subcontractorData.porcentajePerfil, changesFile]);

  return (
    <div className={classes.divMain}>
      <Grid container>
        <Grid
          item
          xs={6}
          sm={4}
          md={2}
          lg={2}
          xl={2}
          container
          direction="row"
          alignItems="center"
          onClick={backStep}
          className={classes.divBack}
        >
          <Grid item>
            <FontAwesomeIcon
              icon={faArrowAltCircleLeft}
              style={{
                width: "20px",
                height: "20px",
                marginRight: "10px",
                color: "#d6d6d6",
              }}
            />
          </Grid>
          <Grid item>
            <Typography className={classes.back}>Regresar</Typography>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={8} md={10} lg={10} xl={10}></Grid>

        <Grid item xs={12}>
          <Typography className={classes.title}>
            Editar subcontratista
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.subtitle}>
            Actualiza los datos de la empresa subcontratista
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          <FlowComponent
            screen={screen}
            setScreen={setScreen}
            subcontractorData={subcontractorData}
            mandatoryFlag={mandatoryFlag}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
          <ScrollBars style={{ width: "100%", height: "60vh" }}>
            {renderRegisterScreen()}
          </ScrollBars>
        </Grid>

        <Grid
          item
          xs={12}
          container
          direction="row"
          justifyContent="flex-end"
          style={{
            paddingRight: "38px",
          }}
          className={classes.gridMargin2}
        >
          {screen > 1 ? (
            <Button
              variant="contained"
              onClick={lastStep}
              style={{
                width: "160px",
                height: "48px",
                borderRadius: "8px",
                color: "#ffffff",
                backgroundColor: "#2d3034",
                textTransform: "none",
              }}
            >
              <FontAwesomeIcon
                icon={faArrowAltCircleLeft}
                style={{
                  width: "20px",
                  height: "20px",
                  marginRight: "10px",
                  color: "#fff",
                  backgroundColor: "#d233ff",
                  borderRadius: "50%",
                }}
              />
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 21,
                  textAlign: "left",
                  color: "#ffffff",
                  paddingRight: "10px",
                }}
              >
                Anterior
              </Typography>
            </Button>
          ) : (
            <div></div>
          )}

          {screen == 3 ? (
            <div></div>
          ) : (
            <Button
              variant="contained"
              disabled={disableButton}
              onClick={nextStep}
              style={{
                width: "160px",
                height: "48px",
                borderRadius: "8px",
                color: "#ffffff",
                backgroundColor: colorButtonNext.backColor,
                marginLeft: "30px",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 21,
                  textAlign: "left",
                  color: colorButtonNext.text,
                  textTransform: "none",
                }}
              >
                Siguiente
              </Typography>
              <FontAwesomeIcon
                icon={faArrowAltCircleRight}
                style={{
                  width: "20px",
                  height: "20px",
                  marginLeft: "10px",
                  color: colorButtonNext.arrow,
                  backgroundColor: colorButtonNext.backArrow,
                  borderRadius: "50%",
                }}
              />
            </Button>
          )}
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          style={{
            paddingRight: "38px",
          }}
        >
          <Grid item className={classes.gridMargin}>
            <BarProgressComponent
              progress={
                subcontractorData.porcentajePerfil.total
                  ? subcontractorData.porcentajePerfil.total
                  : 0
              }
            />
          </Grid>

          <Grid
            item
            style={{
              marginLeft: "30%",
            }}
            className={classes.gridMargin}
          >
            <Button
              variant="contained"
              disabled={disableButton}
              onClick={saveSubcontractor}
              style={{
                width: "160px",
                height: "48px",
                borderRadius: "8px",
                backgroundColor: colorButtonSave.backColor,
              }}
            >
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 21,
                  textAlign: "left",
                  color: colorButtonSave.text,
                  textTransform: "none",
                }}
              >
                Actualizar
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <DialogUnitsList
        data={subcontractorData}
        setData={setSubcontractorData}
        unitsFlag={unitsFlag}
        setChangesFlag={setChangesFlag}
        openDialogUnits={openDialogUnits}
        setOpenDialogUnits={setOpenDialogUnits}
        openDialogUnitsAdmin={openDialogUnitsAdmin}
        setOpenDialogUnitsAdmin={setOpenDialogUnitsAdmin}
      />

      <DialogAdminUnitsList
        unit={unit}
        setUnit={setUnit}
        unitsFlag={unitsFlag}
        setUnitsFlag={setUnitsFlag}
        openDialogUnits={openDialogUnits}
        setOpenDialogUnits={setOpenDialogUnits}
        openDialogUnitsAdmin={openDialogUnitsAdmin}
        setOpenDialogUnitsAdmin={setOpenDialogUnitsAdmin}
        openDialogAddNewUnit={openDialogAddNewUnit}
        setOpenDialogAddNewUnit={setOpenDialogAddNewUnit}
      />

      <DialogAddNewUnit
        unit={unit}
        setUnit={setUnit}
        unitsFlag={unitsFlag}
        setUnitsFlag={setUnitsFlag}
        openDialogUnitsAdmin={openDialogUnitsAdmin}
        setOpenDialogUnitsAdmin={setOpenDialogUnitsAdmin}
        openDialogAddNewUnit={openDialogAddNewUnit}
        setOpenDialogAddNewUnit={setOpenDialogAddNewUnit}
      />

      <DialogBackScreen
        saveSubcontractor={saveSubcontractor}
        openDialogBack={openDialogBack}
        setOpendialogBack={setOpendialogBack}
      />

      <DialogLoadingUploadDocuments type="subcontratistas" />
    </div>
  );
}
