/*eslint-disable*/
import { types } from "../types/types";

const initialState = {
    contractorSelected: null,
};

export const contractorReducer = (state = initialState, action) => {
  switch (action.type) {

    case types.ContractorSelectedDetails:
      return {
        ...state,
        contractorSelected: action.payload,
      };

    case types.reset:
      return initialState;

    default:
      return state;
  }
};
