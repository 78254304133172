/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import SmallCard from "./SmallCard";
import RecognitionCard from "./RecognitionCard";
import BigCardGraphics from "./BigCardGraphics";
import DateRange from "../../../common_components/Filters/DateRange";
import DataGridTopUsers from "./DataGridTopUsers";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Roboto",
    fontSize: 28,
    fontWeight: 600,
    textAlign: "left",
    color: "#ffffff",
  },
  subtitle: {
    fontFamily: "Roboto",
    fontSize: 18,
    lineHeight: 1.33,
    textAlign: "left",
    color: "#fff",
    marginBottom: 10,
  },
}));

export default function StadisticsScreen(props) {
  const classes = useStyles();
  const [selectionRows, setSelectionRows] = useState([]);

  const rows = [
    {
      id: 1,
      Email: "javier.medina@grupoabsa.com",
      AltaEmpleado: 0,
      AltaEmpresas: 0,
      ActualizacionEmpleado: 0,
      Reconocimientos: 0,
    },
  ];

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [query, setQuery] = useState({
    // Empleado: attendance?.Empleado ?? "",
    // Proyectos: attendance?.Proyectos ?? [],
    // Subcontratistas: attendance?.Subcontratistas ?? [],
    // Unidad: attendance?.Unidad ?? [],
    intialDate: new Date(),
    finalDate: new Date(),
    // update: attendance?.update ?? false,
    pageSize: 10,
    rowsPerPageOptions: [5, 10, 15],
    page: 1,
  });

  const updateData = (k, v) => {
    setQuery((prev) => ({ ...prev, [k]: v }));
  };

  const handleSelect = (ranges) => {
    setDateRange([ranges.selection]);
    updateData("intialDate", ranges.selection.startDate);
    updateData("finalDate", ranges.selection.endDate);
  };

  //   useEffect(() => {
  //     setPageNumber(0);
  //     dispatch(FiltersAttendance(query));
  //   }, [query]);

  return (
    <Grid container direction="row">
      <Grid
        item
        xs={12}
        container
        direction="column"
        justifyContent="flex-start"
        style={{
          marginBottom: 10,
        }}
      >
        <Typography
          style={{
            fontFamily: "Roboto",
            fontSize: 16,
            textAlign: "left",
            color: "#dedcdc",
          }}
        >
          Fecha
        </Typography>
        <DateRange value={dateRange} onChange={handleSelect} />
      </Grid>

      <Grid
        item
        xs={12}
        spacing={3}
        container
        direction="row"
        justifyContent="flex-start"
        style={{
          marginBottom: 10,
        }}
      >
        <Grid item>
          <SmallCard
            title={"Empleados activos"}
            icon={"fa-clock"}
            active={"empleados"}
            number={150}
            new={5}
          />
        </Grid>

        <Grid item>
          <SmallCard
            title={"Número de empresas"}
            icon={"fa-suitcase"}
            active={"empresas"}
            number={12}
            new={1}
          />
        </Grid>

        <Grid item>
          <RecognitionCard
            title={"Número de reconocimientos"}
            icon={"fa-mobile-screen-button"}
            active={"reconocimientos"}
            number={"230,000"}
          />
        </Grid>

        <Grid item>
          <SmallCard
            title={"Reportes de conducta"}
            icon={"fa-circle-exclamation"}
            active={"reporte"}
            number={1}
            new={1}
          />
        </Grid>

        <Grid item>
          <SmallCard
            title={"Empleados sin IMSS"}
            icon={"fa-circle-exclamation"}
            active={"empleado"}
            number={1}
            new={1}
          />
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        spacing={1}
        container
        direction="row"
        justifyContent="flex-start"
      >
        <Grid item>
          <BigCardGraphics
            title={"Reconocimientos"}
            subtitle={"26 de noviembre"}
          />
        </Grid>

        <Grid item>
          <BigCardGraphics
            title={"Alta de empleados"}
            subtitle={"26 de noviembre"}
          />
        </Grid>

        <Grid item>
          <BigCardGraphics
            title={"Alta de subcontratista"}
            subtitle={"26 de noviembre"}
          />
        </Grid>

        <Grid item>
          <BigCardGraphics
            title={"Actualización de empleados"}
            subtitle={"26 de noviembre"}
          />
        </Grid>

        <Grid item>
          <BigCardGraphics
            title={"Asistencias por día"}
            subtitle={"26 de noviembre"}
          />
        </Grid>

        <Grid item>
          <BigCardGraphics
            title={"Empleados sin IMSS"}
            subtitle={"26 de noviembre"}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Typography
          style={{
            fontFamily: "Roboto",
            fontSize: 28,
            fontWeight: "bold",
            lineHeight: 1.32,
            textAlign: "left",
            color: "#dedcdc",
            marginTop: 20,
          }}
        >
          Top usuarios
        </Typography>

        <DataGridTopUsers
          rows={rows ? rows : []}
          //   loading={loading}
          //   rowCount={totalRows}
          rowsPerPageOptions={query.rowsPerPageOptions}
          page={query.page}
          pageSize={query.pageSize}
          // selectionModel={selectionRows}
          // onSelectionModelChange={(ids) => {
          //   setSelectionRows(ids);
          //   if (permissionsUpdate) {
          //     setSelectedUser(rows.find((row) => row.id == ids[0]));
          //     setOpendialogEdit(true);
          //   }
          // }}
          // onPageChange={(data) => {
          //   updateData("page", data + 1);
          // }}
          // onPageSizeChange={(data) => {
          //   updateData("page", 1);
          //   updateData("pageSize", data);
          // }}
          style={{
            height: 680,
            width: "100%",
            display: "flex",
            marginTop: -20,
          }}
        />
      </Grid>
    </Grid>
  );
}
