/*eslint-disable*/
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Lottie from "react-lottie";
import UploadingAnimation from "../../../animations/lottie_delete.json";
import { useSnackbar } from "notistack";
import axiosInstance from "../../../utils/axiosInstance";

export default function DialogDeleteEvent(props) {
  const history = useHistory();
  const { setOpendialog, openDialog, data, selectUpdateEvent, setSelectUpdateEvent } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleClose = () => {
    setOpendialog(!openDialog);
  };

  const handleDelete = async () => {
    const result = await deleteEvent(selectUpdateEvent?._id);

    if (result?.status != "ok") {
      enqueueSnackbar("Error no se pudo eliminar el evento", {
        variant: "error",
      });

      return;
    }

    let arrayEvents = data?.evento;
    arrayEvents = arrayEvents.filter(x => x._id != selectUpdateEvent._id)
    data.evento = arrayEvents;
    data.key == Math.random(); //

    
    handleClose();
    setSelectUpdateEvent(null);
    enqueueSnackbar("Evento eliminadaocorrectamente", {
      variant: "success",
    });
  };

  const deleteEvent = async (id) => {
    return await axiosInstance()
      .delete(`/api/v1/rules/asistencias/manual_event/Evento/${id}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        return null;
      });
  };

  const AnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: UploadingAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };


  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        style: {
          backgroundColor: "#171a1e",
          textColor: "#fsf",
          width: "483px",
          height: "476px",
          paddingTop: 15,
          borderRadius: 18,
        },
      }}
    >
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 20,
              textAlign: "center",
              color: "#fff",
              fontWeight: "bold",
            }}
          >
            Eliminar evento
          </Typography>

          <Grid
            container
            justifyContent="flex-end"
            style={{ position: "absolute" }}
          >
            <Button onClick={handleClose}>
              <FontAwesomeIcon
                icon={faTimes}
                style={{
                  width: "15px",
                  height: "22px",
                  color: "#fff",
                }}
              />
            </Button>
          </Grid>
        </Grid>
        <div
          style={{
            width: "100%",
            height: 1,
            background: "#707070",
            marginTop: 8,
            padding: 0,
          }}
        ></div>
      </DialogTitle>

      <DialogContent>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Lottie
            options={AnimationOptions}
            style={{ marginTop: 25 }}
            height={135}
            width={127}
          />

          <Typography
            style={{
              marginTop: 20,
              fontFamily: "Roboto",
              fontSize: 16,
              color: "#bebebe",
            }}
          >
            ¿Estas seguro de querer eliminar el evento?
          </Typography>

          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-evenly"
            style={{ marginTop: 15 }}
          >
            <Button
              onClick={handleClose}
              style={{
                width: "174px",
                height: "40px",
                borderRadius: "8px",
                color: "#ffffff",
                backgroundColor: "#3a3c40",
                textTransform: "none",
                marginTop: 50,
              }}
            >
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 18,
                  color: "#fff",
                }}
              >
                Cancelar
              </Typography>
            </Button>

            <Button
              onClick={handleDelete}
              style={{
                width: "174px",
                height: "40px",
                borderRadius: "8px",
                color: "#ffffff",
                backgroundColor: "#ac0bce",
                textTransform: "none",
                marginTop: 50,
              }}
            >
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 18,
                  color: "#fff",
                }}
              >
                Continuar
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
