/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faArrowAltCircleLeft,
} from "@fortawesome/free-solid-svg-icons";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "axios";
import { ipServer } from "../../../config/vars";
import { CapitalizeString } from "../../../utils/CapitalizeString";

const useStyles = makeStyles((theme) => ({
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: "bold",
    textAlign: "left",
    color: "#cdcece",
    marginLeft: "18px",
  },
  textFieldCustom: {
    width: "400px",
    color: "#676767",
    border: "solid 1px #c5c5c5",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
  },
  divImg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "356px",
    height: "290px",
    borderRadius: "8px",
    border: "solid 1px #707070",
    backgroundColor: "#212429",
    cursor: "pointer",
  },
  divBack: {
    width: "15%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    // marginBottom: "20px",
    // backgroundColor: "blue",
  },
}));

export default function DialogAddNewJob(props) {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleOpenDialog = () => {
    props.setOpenNewDialog(!props.openNewDialog);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    props.setStatus({
      ...props.status,
      [e.target.name]: value,
    });
  };

  const backStep = () => {
    props.setOpenNewDialog(!props.openNewDialog);
    props.setOpenAdminDialog(!props.openAdminDialog);
  };

  const updateJob = async (id) => {
    console.log(id);
    if (props.status.id == "") {
      await addNewJob();
    } else {
      await editJob(id);
    }
  };

  const addNewJob = async () => {
    return await axios
      .post(
        `${ipServer}/api/v1/rules/catalogos`,
        {
          type: props.status.type,
          name: props.status.name,
        },
        {
          headers: {
            Authorization: localStorage.getItem("JWT"),
          },
        }
      )
      .then((response) => {
        enqueueSnackbar("Puesto agregado correctamente", {
          variant: "success",
        });
        props.setQuery({ ...props.query, update: !props.query.update });
        props.setOpenAdminDialog(!props.openAdminDialog);
        props.setOpenNewDialog(!props.openNewDialog);
        props.setUpdate(!props.update);
        return true;
      })
      .catch((e) => {
        enqueueSnackbar("Error al agregar puesto", {
          variant: "error",
        });
        console.log(e);
        return false;
      });
  };

  const editJob = async (id) => {
    return await axios
      .patch(
        `${ipServer}/api/v1/rules/catalogos/${id}`,
        {
          type: props.status.type,
          name: props.status.name,
        },
        {
          headers: {
            Authorization: localStorage.getItem("JWT"),
          },
        }
      )
      .then((response) => {
        enqueueSnackbar("Puesto actualizado correctamente", {
          variant: "success",
        });
        props.setQuery({ ...props.query, update: !props.query.update });
        props.setOpenAdminDialog(!props.openAdminDialog);
        props.setOpenNewDialog(!props.openNewDialog);
        props.setUpdate(!props.update);
        return true;
      })
      .catch((e) => {
        enqueueSnackbar("Error al editar puesto", {
          variant: "error",
        });
        console.log(e);
        return false;
      });
  };

  return (
    <div>
      <Dialog
        open={props.openNewDialog}
        onClose={handleOpenDialog}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            backgroundColor: "#171a1e",
            textColor: "#fsf",
            width: "483px",
            height: "600px",
            paddingTop: 15,
          },
        }}
      >
        <DialogTitle>
          <Grid container direction="row" justifyContent="space-between">
            <div onClick={backStep} className={classes.divBack}>
              <FontAwesomeIcon
                icon={faArrowAltCircleLeft}
                style={{
                  width: "20px",
                  height: "20px",
                  marginRight: "10px",
                  color: "#d6d6d6",
                }}
              />
            </div>

            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 22,
                textAlign: "center",
                color: "#fff",
                fontWeight: "bold",
              }}
            >
              Adminsitración de Estatus
            </Typography>

            <Button onClick={handleOpenDialog}>
              <FontAwesomeIcon
                icon={faTimes}
                style={{
                  width: "20px",
                  height: "20px",
                  color: "#fff",
                }}
              />
            </Button>
          </Grid>
          <div
            style={{
              width: "100%",
              height: 1,
              background: "#707070",
              marginTop: 8,
              padding: 0,
            }}
          ></div>
        </DialogTitle>

        <DialogContent>
          <Grid
            style={{
              height: "100px",
            }}
          />

          <Typography className={classes.textFieldLetter}>Estatus</Typography>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <TextField
              name="name"
              size="small"
              variant="outlined"
              value={CapitalizeString(props.status.name)}
              className={classes.textFieldCustom}
              onChange={handleChange}
              inputProps={{
                maxLength: 50,
              }}
            />

            <Grid
              style={{
                height: "120px",
              }}
            />

            <Button
              variant="contained"
              onClick={() => updateJob(props.status.id)}
              style={{
                width: "320px",
                height: "48px",
                borderRadius: "8px",
                backgroundColor: "#ac0bce",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 21,
                  textAlign: "left",
                  color: "#fff",
                  textTransform: "none",
                }}
              >
                Guardar
              </Typography>
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
