/*eslint-disable*/
import React, { Fragment, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import UploadFileComponent from "./uploadFileComponent";
import AddNewFileButton from "./addNewFileButton";
import { useSelector } from "react-redux";
import Lottie from "react-lottie";
import LottieNotFound from "../../../animations/lottie_not_found.json";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
  },
}));

export default function InfoFiles(props) {
  const classes = useStyles();
  const { files } = useSelector((state) => state.file);
  const predefinedDocuments = ["Registro patronal", "Registro obra SIROC"];

  const filesFilter = [
    ...new Map(files.map((item) => [item["name"], item])).values(),
  ];

  function difference(a1, a2) {
    return a1.filter((o1) => a2.filter((o2) => o2 === o1.name).length === 0);
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LottieNotFound,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    if (
      props.obrasData.name != "" &&
      props.obrasData.address != "" &&
      props.obrasData.client != ""
    ) {
      props.setMandatoryFlag(true);
    } else {
      props.setMandatoryFlag(false);
    }
  }, [props.obrasData]);

  return (
    <Fragment>
      <div className={classes.divMain}>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          style={{
            paddingRight: 38,
            marginBottom: 25,
          }}
        >
          <AddNewFileButton />
        </Grid>

        <Grid
          container
          spacing={4}
          style={{ paddingRight: 38, marginBottom: 20 }}
        >
          {predefinedDocuments.length == 0 && files.length == 0 && (
            <Grid
              container
              direction="column"
              alignContent="center"
              justifyContent="center"
              item
              xs={12}
              style={{ width: "100%", height: "100%" }}
            >
              <Lottie options={defaultOptions} height={237} width={238} />
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 26,
                  textAlign: "center",
                  color: "#c1c1c1",
                  fontWeight: 500,
                }}
              >
                Sin expediente de archivos
              </Typography>
            </Grid>
          )}

          {predefinedDocuments.map((predefined, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={index}>
              <UploadFileComponent tag={predefined}/>
            </Grid>
          ))}

          {files &&
            difference(filesFilter, predefinedDocuments).map(
              (document, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  xl={3}
                  key={index + predefinedDocuments.length}
                >
                  <UploadFileComponent tag={document.name} />
                </Grid>
              )
            )}
        </Grid>
      </div>
    </Fragment>
  );
}
