/*eslint-disable*/
import { useEffect, useState } from "react";
import axios from "axios";
import axiosInstance from "../../../utils/axiosInstance";

export default function useBookSearch(
  query,
  pageNumber,
  loadingData,
  proyectos,
  unidades
) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [books, setBooks] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setBooks([]);
  }, [query]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    let Proyectos = query.Proyectos.length > 0 ? query.Proyectos : proyectos;
    let Unidades = query.Unidades.length > 0 ? query.Unidades : unidades;

    var params = new URLSearchParams();
    params.append("limit", 8);
    params.append("page", pageNumber);
    params.append("empresa_partial", query.Empresa);

    Proyectos?.map((proyecto) => {
      params.append("proyectos_id", proyecto.value);
    });

    Unidades?.map((unidad) => {
      params.append("unidad_string", unidad.label);
    });

    axiosInstance()
      .get("/api/v1/rules/subcontratistas/filter", {
        params,
        cancelToken: source.token,
      })
      .then((res) => {
        setBooks((prevBooks) => {
          return [...new Set([...prevBooks, ...res.data.data.Results])];
        });

        setHasMore(res.data.data.Results.length > 0);
        setLoading(false);
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;
        setError(true);
      });
    return () => source.cancel();
  }, [query, pageNumber, loadingData]);

  return { loading, error, books, hasMore };
}
