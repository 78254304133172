/*eslint-disable*/
import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import Unauthorized from "./Unauthorized";
import NotFoundScreen from "./NotFoundScreen/NotFoundScreen";
import { isObjectId } from "../utils/CheckObjectId";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { permissions, modules, keyPermission } = useSelector(
    (state) => state.permissions
  );
  var pathArray = window.location.href.split("/");

  const isPermission = (url) => {
    return keyPermission.includes(url);
  };

  return (
    <Route
      {...rest}
      render={(props) => {
        if (localStorage.getItem("JWT")) {
          let path = pathArray.pop();
          let permision = "habilitar";

          if (permissions && modules && keyPermission && path) {
            if (isObjectId(path)) {
              path = pathArray.pop();
            }

            if (isPermission(path)) {
              permision = path;
              path = pathArray.pop();
            }

            const key = modules
              .filter((_module) => _module.url.split("/").pop() == path)
              .map((x) => x.nombre);

            if (key.length == 0) return <NotFoundScreen />;

            const modulePermission = permissions.filter((x) =>
              key.includes(x.name)
            );

            if (
              modulePermission?.length == 1 &&
              !modulePermission[0][permision]
            )
              return <Unauthorized />;

            const padre = pathArray.pop();

            if (
              modulePermission?.length > 1 &&
              !modulePermission.find((x) => x.padre?.toLowerCase() == padre)[
                permision
              ]
            )
              return <Unauthorized />;
          }

          return <Component {...rest} {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/inicio_sesion",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};

export default ProtectedRoute;
