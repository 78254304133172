/*eslint-disable*/
import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button } from "@material-ui/core";
import Lottie from "react-lottie";
import BuildMyEnterprise from "../../../animations/lottie_build_mi_empresa.json";
import Typo from "../../../common_components/typo";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  divMain: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "90vh",
    backgroundColor: "#171a1e",
  },
  gridMain: {
    // [theme.breakpoints.down("md")]: {
    //   marginLeft: 250,
    // },
    width: "100%",
    height: "100%",
    // backgroundColor: "red",
  },
  gridTypo: {
    padding: theme.spacing(3),
  },
}));

export default function WelcomeSubcontractor(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { screenRedux } = useSelector((state) => state.screen);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: BuildMyEnterprise,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const setFisrtTime = () => {
    history.push(
      `/dashboard/miempresa/registro/${localStorage.getItem("idSubcontractor")}`
    );
    // dispatch(SetScreen(3));
  };

  return (
    <div className={classes.divMain}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        className={classes.gridMain}
      >
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Lottie options={defaultOptions} height={377} width={433} />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          container
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          className={classes.gridTypo}
        >
          <Typo
            fontSize={40}
            fontWeight={"bold"}
            color={"#fff"}
            text={"BIENVENIDO"}
          />
          <Typo fontSize={32} color={"#cecece"} text={user?.Name} />
          <Typo
            fontSize={32}
            color={"#fff"}
            text={"Iniciemos llenando la información de tu empresa"}
            marginTop={"2%"}
          />
          <Button
            variant="contained"
            onClick={setFisrtTime}
            style={{
              width: "146px",
              height: "48px",
              borderRadius: "8px",
              backgroundColor: "#ac0bce",
              textTransform: "none",
              marginTop: "2%",
            }}
          >
            <Typo fontSize={21} color={"#fff"} text={"Iniciar"} />
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
