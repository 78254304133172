/*eslint-disable*/
import { useEffect, useState } from "react";
import axios from "axios";
import axiosInstance from "../../../utils/axiosInstance";

export default function useDeviceSearch(
  query,
  pageNumber,
  loadingData,
  proyectos
) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [rows, setRows] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setRows([]);
  }, [query]);

  useEffect(() => {
    if (loadingData) {
      setLoading(true);
      setError(false);
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      let Proyectos = query.proyectos.length > 0 ? query.proyectos : proyectos;

      var params = new URLSearchParams();
      params.append("limit", 8);
      params.append("page", pageNumber);
      params.append("DeviceName_partial", query.search);
      params.append("SerialNumber_partial", query.search);
      params.append("MacAddress_partial", query.search);

      Proyectos?.map((obra) => {
        params.append("Obra_id", obra.value);
      });

      axiosInstance()
        .get("/api/v1/facedn/device/filter", {
          params,
          cancelToken: source.token,
        })
        .then((res) => {
          let result = res.data.data.Results;
          console.log("resut", result)

          setRows((prevBooks) => {
            return [...new Set([...prevBooks, ...result])];
          });

          setHasMore(res.data.data.Results.length > 0);
          setLoading(false);
        })
        .catch((e) => {
          if (axios.isCancel(e)) return;
          setError(true);
        });
      return () => source.cancel();
    }
  }, [query, pageNumber, loadingData]);

  return { loading, error, rows, hasMore };
}
