/*eslint-disable*/
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { TextField, Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { addFile, clearFiles } from "../../actions/file";
import { SelectedDetailsContractor } from "../../actions/contractor";
import { ValidatePermissions } from "../../utils/permissions";
import MultiSelect from "../../common_components/Filters/MutiSelect";
import axios from "axios";
import axiosInstance from "../../utils/axiosInstance";
import CardSummaryEnterprise from "../../common_components/Cards/CardSummaryEnterprise";
import CardDetailEnterprise from "../../common_components/Cards/CardDetailEnterprise";
import CardAssignmentsEnterprise from "../../common_components/Cards/CardAssignmentsEnterprise";
import CardEnterpriseEmployee from "./components/CardEnterpriseEmployee";
import CardEnterpriseSubcontractors from "./components/CardEnterpriseSubcontractors";
import CardHistoryEnterprise from "../../common_components/Cards/CardHistoryEnterprise";
import CardFileEnterprise from "../../common_components/Cards/CardFileEnterprise";
// import DialogEditObra from "../../common_components/Dialogs/DialogEditObra";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    padding: 15,
  },
  button: {
    width: "152px",
    height: "40px",
    borderRadius: "8px",
    color: "#ffffff",
    backgroundColor: "#ac0bce",
    textTransform: "none",
  },
  typoButton: {
    fontFamily: "Roboto",
    fontSize: 18,
    textAlign: "left",
    color: "#fff",
  },
  multi: {
    color: "white",
    padding: 2,
    border: 0,
    borderRadius: "8px",
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 28,
    fontWeight: 600,
    textAlign: "left",
    color: "#ffffff",
    marginRight: 20,
  },
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 16,
    textAlign: "left",
    color: "#676767",
  },
  textFieldCustom: {
    width: "478px",
    color: "#676767",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
  },
}));

export default function MyEnterpriseScreen(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [openDialog, setOpendialog] = useState(false);
  const [subcontractors, setSubcontractors] = useState(null);
  const [enterpriseData, setEnterpriseData] = useState({
    id: "",
    foto: "",
    empresa: "--",
    razonSocial: "--",
    rfc: "--",
    empleados: "--",
    numeroSeguro: "--",
    representante: "--",
    telefono: "--",
    direccion: "--",
    // subcontratistas: [],
    proyectos: [],
    proyectos_id: [],
  });
  const [statusEmployee, setStatusEmployee] = useState({
    Activo: null,
    Inactivo: null,
    Boletinado: null,
    Vetado: null,
  });

  const getEnterprise = () => {
    return axiosInstance()
      .get(`/api/v1/rules/miempresa/${user?.Subcontractor_id}`)
      .then((response) => {
        // console.log("load", response.data.data[0]);
        setEnterpriseData({
          ...enterpriseData,
          id: response.data.data[0]._id ?? enterpriseData.id,
          foto: response.data.data[0].foto
            ? response.data.data[0].foto?.url
            : enterpriseData.foto,
          empresa: response.data.data[0].empresa ?? enterpriseData.empresa,
          razonSocial:
            response.data.data[0].razonSocial ?? enterpriseData.razonSocial,
          rfc: response.data.data[0].rfc ?? enterpriseData.rfc,
          numeroSeguro:
            response.data.data[0].numeroSeguro ?? enterpriseData.numeroSeguro,
          representante:
            response.data.data[0].representante ?? enterpriseData.representante,
          direccion:
            response.data.data[0].direccion ?? enterpriseData.direccion,
          telefono: response.data.data[0].telefono ?? enterpriseData.telefono,
          correo: response.data.data[0].correo ?? enterpriseData.correo,
          documentos: response.data.data[0].documentos
            ? response.data.data[0].documentos
            : enterpriseData.documentos,
          proyectos: response.data.data[0].proyectos
            ? response.data.data[0].proyectos
            : enterpriseData.proyectos,
          empleados: response.data.data[0].trabajadores_id
            ? response.data.data[0].trabajadores_id.length
            : enterpriseData.empleados,
          proyectos_id: response.data.data[0].proyectos_id
            ? response.data.data[0].proyectos_id
            : enterpriseData.proyectos_id,
        });

        dispatch(SelectedDetailsContractor(response?.data?.data[0]));

        dispatch(clearFiles());
        const doc = response.data.data[0].documentos
          ? response.data.data[0].documentos
          : [];
        doc.map((file, index) =>
          dispatch(
            addFile({
              name: file.filename,
              url: file.file,
              originalName: file.originalName,
              process: "db",
              date: file.datetime,
            })
          )
        );
      })
      .catch((e) => {
        console.log(e.response);
      });
  };

  const getSubcontractorsTotal = () => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    var params = new URLSearchParams();
    params.append("limit", 1);
    params.append("page", 0);

    return axiosInstance()
      .get(`/api/v1/rules/subcontratistas/filter`, {
        params,
        cancelToken: source.token,
      })
      .then((response) => {
        // console.log("total subcontractors: ", response.data.data);
        setSubcontractors(response.data.data.Total);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getEmployeesStatus = () => {
    var array = ["Activo", "Inactivo", "Boletinado", "Vetado"];

    const response = array.map((status, index) => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      var params = new URLSearchParams();
      params.append("limit", 1);
      params.append("page", 0);
      params.append("subcontratistas_id", user?.Subcontractor_id);
      params.append("statusDemek_string", status);

      return axiosInstance()
        .get(`/api/v1/rules/trabajadores/filter`, {
          params,
          cancelToken: source.token,
        })
        .then((response) => {
          // console.log([status] + ": " + response.data.data.Total);
          setStatusEmployee((statusEmployee) => {
            return {
              ...statusEmployee,
              [status]: response.data.data.Total,
            };
          });
        })
        .catch((e) => {
          console.log(e.response);
        });
    });

    return response;
  };

  useEffect(() => {
    if (user) {
      // console.log(user);
      localStorage.setItem("idSubcontractor", user?.Subcontractor_id);

      getEnterprise();
      getSubcontractorsTotal();
      getEmployeesStatus();
    }
  }, [user]);

  return (
    <div className={classes.divMain}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Typography className={classes.title}>Mi empresa</Typography>

        <Button
          variant="contained"
          onClick={() => history.push(`/dashboard/miempresa/configuracion`)}
          className={classes.button}
        >
          <Typography className={classes.typoButton}>Configuración</Typography>
        </Button>
      </Grid>

      <Grid
        style={{
          height: "20px",
        }}
      />

      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={4}
          xl={4}
          className={classes.gridMargin}
        >
          <CardSummaryEnterprise
            urlPhoto={enterpriseData.foto}
            name={enterpriseData.empresa}
            businessName={enterpriseData.razonSocial}
            rfc={enterpriseData.rfc}
            securityNumber={enterpriseData.numeroSeguro}
            employees={enterpriseData.empleados}
          />

          <Grid
            style={{
              height: "10px",
            }}
          />

          <CardDetailEnterprise
            name={enterpriseData.empresa}
            rfc={enterpriseData.rfc}
            businessName={enterpriseData.razonSocial}
            securityNumber={enterpriseData.numeroSeguro}
            address={enterpriseData.direccion}
            contact={enterpriseData.representante}
            telephone={enterpriseData.telefono}
            email={enterpriseData.correo}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          <CardEnterpriseEmployee
            id={enterpriseData.id}
            enterprise={enterpriseData.empresa}
            employees={enterpriseData.empleados}
            active={statusEmployee.Activo ?? 0}
            inactive={statusEmployee.Inactivo ?? 0}
            bulletin={statusEmployee.Boletinado ?? 0}
            veto={statusEmployee.Vetado ?? 0}
          />

          <Grid
            style={{
              height: "10px",
            }}
          />

          <CardEnterpriseSubcontractors subcontractors={subcontractors} />

          <Grid
            style={{
              height: "10px",
            }}
          />

          <CardAssignmentsEnterprise
            id={enterpriseData.id}
            proyectos={enterpriseData.proyectos}
            setOpendialog={setOpendialog}
            openDialog={openDialog}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          <CardHistoryEnterprise />

          <Grid
            style={{
              height: "10px",
            }}
          />

          <CardFileEnterprise files={enterpriseData.documentos} />
        </Grid>
      </Grid>

      {/* <DialogEditObra
        setOpendialog={setOpendialog}
        openDialog={openDialog}
        subcontractorData={enterpriseData}
        setSubcontractorData={setEnterpriseData}
        data={enterpriseData.proyectos_id}
      /> */}
    </div>
  );
}
