/*eslint-disable*/
import React, { Fragment, useEffect, useState } from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { GoogleMap, Marker } from "@react-google-maps/api";

export default function DialogMapsView(props) {
  const { setOpendialog, openDialog } = props;
  const [address, setaddress] = useState({
    calle: "",
    colonia: "",
    codigoPostal: "",
    ciudad: "",
    estado: "",
  });
  const [state, setState] = useState({
    mapPosition: {
      lat: 20.7093647,
      lng: -103.41164,
    },
    markerPosition: {
      lat: 0,
      lng: 0,
    },
  });

  useEffect(() => {
    props.rows.map((event) => {
      if (event.geolocation && event._id == props.selectionRows[0]) {
        const fullAdress = event?.geolocation?.fullAdress?.split(",");

        setaddress({
          calle: fullAdress ? fullAdress[0]?.trim() : "",
          colonia: fullAdress ? fullAdress[1]?.trim() : "",
          codigoPostal: fullAdress
            ? fullAdress[2]?.trim()?.substring(0, 5)
            : "",
          ciudad: fullAdress ? fullAdress[2]?.trim()?.substring(6) : "",
          estado: fullAdress ? fullAdress[3]?.trim() : "",
        });

        setState({
          markerPosition: {
            lat: parseFloat(event.geolocation.latitude),
            lng: parseFloat(event.geolocation.longitude),
          },
          mapPosition: {
            lat: parseFloat(event.geolocation.latitude),
            lng: parseFloat(event.geolocation.longitude),
          },
        });
      }
    });
  }, [props.selectionRows]);

  const handleDialog = () => {
    setOpendialog(!openDialog);
  };

  const center = React.useMemo(
    function memo() {
      return {
        lat: state.mapPosition.lat,
        lng: state.mapPosition.lng,
      };
    },
    [state.mapPosition]
  );

  return (
    <Dialog
      open={openDialog}
      aria-labelledby="form-dialog-title"
      onClose={handleDialog}
      disableEnforceFocus
      PaperProps={{
        style: {
          backgroundColor: "#171a1e",
          textColor: "#fsf",
          minWidth: 1001,
          width: 1001,
          height: 716,
          minHeight: 716,
          paddingTop: 15,
          zIndex: 1,
        },
      }}
    >
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 22,
              textAlign: "center",
              color: "#fff",
              fontWeight: "bold",
            }}
          >
            Localización
          </Typography>

          <Grid
            container
            justifyContent="flex-end"
            style={{ position: "absolute" }}
          >
            <Button onClick={handleDialog}>
              <FontAwesomeIcon
                icon={faTimes}
                style={{
                  width: "20px",
                  height: "20px",
                  color: "#fff",
                }}
              />
            </Button>
          </Grid>
        </Grid>
        <div
          style={{
            width: "100%",
            height: 1,
            background: "#707070",
            marginTop: 8,
            padding: 0,
          }}
        ></div>
      </DialogTitle>
      <DialogContent>
        <Grid container direction="row" justifyContent="center">
          {address?.calle != "" && (
            <Grid
              container
              direction="row"
              style={{
                width: 280,
                height: "auto",
                padding: 2,
                backgroundColor: "#fff",
                zIndex: 1000,
                marginTop: 375,
                position: "absolute",
                borderRadius: 8,
                border: "1px solid #e6e6e6",
              }}
            >
              <Grid
                item
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                xs={3}
              >
                <div
                  style={{
                    width: 40,
                    height: 40,
                    borderRadius: "50%",
                    backgroundColor: "#e6e6e6",
                    margin: "auto",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faMapMarkerAlt}
                    style={{
                      width: 16,
                      height: 22,
                      color: "#d233ff",
                      margin: "auto",
                      marginLeft: 12,
                      marginTop: 8,
                    }}
                  />
                </div>
              </Grid>

              <Grid
                item
                container
                direction="column"
                xs={9}
                style={{ padding: 8 }}
              >
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 14,
                    textAlign: "left",
                    color: "#2d2d2d",
                    fontWeight: "bold",
                  }}
                >
                  {address.calle}
                </Typography>

                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 14,
                    textAlign: "left",
                    color: "#757575",
                  }}
                >
                  {address.colonia}
                </Typography>

                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 14,
                    textAlign: "left",
                    color: "#757575",
                  }}
                >
                  {`CP ${address.codigoPostal}`}
                </Typography>

                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 14,
                    textAlign: "left",
                    color: "#757575",
                  }}
                >
                  {`${address.ciudad}, ${address.estado}`}
                </Typography>
              </Grid>
            </Grid>
          )}

          <GoogleMap
            mapContainerStyle={{
              width: 801,
              height: 495,
            }}
            zoom={15}
            center={center}
          >
            <Grid
              style={{ width: 480, height: 40, margin: "auto", marginTop: 20 }}
            ></Grid>

            <Marker position={center} />
          </GoogleMap>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
