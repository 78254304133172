/*eslint-disable*/
import { useEffect, useState } from "react";
import axios from "axios";
import axiosInstance from "../../../utils/axiosInstance";

export default function useBookSearch(query, pageNumber, dispatch) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [rows, setRows] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setRows([]);
  }, [query]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    var params = new URLSearchParams();
    params.append("limit", 8);
    params.append("page", pageNumber);
    params.append("proyecto_partial", query.Obras);

    axiosInstance()
      .get("/api/v1/rules/proyectos/filter", {
        params,
        cancelToken: source.token,
      })
      .then((res) => {
        // console.log(res.data.data.Results);
        let result = res.data.data.Results;

        result.map((obras) => {
          obras.obrasCount = obras.subcontratistas_id
            ? obras.subcontratistas_id.length - 2
            : 0;

          obras.trabajadoresCount = obras.trabajadores_id
            ? obras.trabajadores_id.length
            : 0;
        });

        setRows((prevBooks) => {
          return [...new Set([...prevBooks, ...result])];
        });

        setHasMore(res.data.data.Results.length > 0);
        setLoading(false);
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;
        setError(true);
      });
    return () => source.cancel();
  }, [query, pageNumber]);

  return { loading, error, rows, hasMore };
}
