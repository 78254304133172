/*eslint-disable*/
import React, { useEffect, useState, useRef, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { TextField, Box } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faArrowAltCircleLeft,
} from "@fortawesome/free-solid-svg-icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import useDeviceSearch from "./useDeviceSearch";
import CardDevice from "./components/CardDevice";
import { FiltersGateway } from "../../../actions/filters";
import MultiSelect from "../../../common_components/Filters/MutiSelect";
import axiosInstance from "../../../utils/axiosInstance";
import { useHistory } from "react-router-dom";
import NotRecordsFound from "../../../common_components/NotRecordsFound";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    padding: 15,
  },
  buttonSearch: {
    width: "232px",
    height: "40px",
    borderRadius: "8px",
    color: "#ffffff",
    backgroundColor: "#ac0bce",
    textTransform: "none",
    marginLeft: 30,
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
      marginLeft: 0,
    },
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 28,
    fontWeight: 600,
    textAlign: "left",
    color: "#ffffff",
    marginBottom: 25,
  },
  textFieldCustom: {
    width: 387,
    color: "#676767",
    borderRadius: 6,
    backgroundColor: "#fff",
    height: 40,
  },
  divBack: {
    cursor: "pointer",
    marginBottom: 10,
  },
  back: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 500,
    textAlign: "left",
    color: "#fff",
    marginRight: 2,
  },
}));

export default function Cameras(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { gateways } = useSelector((state) => state.filters);
  const [pageNumber, setPageNumber] = useState(0);
  const [proyectos, setProyectos] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  const [query, setQuery] = useState({
    search: gateways?.search ?? "",
    proyectos: gateways?.psroyectos ?? [],
  });

  const { rows, totalRows, loading, error, hasMore } = useDeviceSearch(
    query,
    pageNumber,
    loadingData,
    proyectos
  );

  const getProyectsList = () => {
    return new Promise((resolve, reject) => {
      var params = new URLSearchParams();
      params.append("sortMongoAsc", "proyecto");

      axiosInstance()
        .get(`/api/v1/rules/proyectos/filter`, { params })
        .then((response) => {
          let proyects = response.data.data.map((object) => {
            return { label: object.proyecto, value: object._id };
          });

          setProyectos(proyects);
          resolve(proyects);
        })
        .catch((e) => {
          console.log(e);
          resolve(false);
        });
    });
  };

  const handleQueryChange = (queryElement) => (e) => {
    const value = queryElement === "proyectos" ? e : e.target.value;
    return setQuery((prevQuery) => {
      return { ...prevQuery, ...{ [queryElement]: value } };
    });
  };

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  useEffect(() => {
    setPageNumber(0);
    dispatch(FiltersGateway(query));
  }, [query]);

  useEffect(() => {
    const fetchData = async () => {
      setLoadingData(false);
      await getProyectsList();
      setLoadingData(true);
    };

    fetchData();
  }, []);

  function getMinDiff(startDate, endDate) {
    const msInMinute = 60 * 1000;

    return Math.round(Math.abs(endDate - startDate) / msInMinute);
  }

  const backStep = () => {
    history.push("/dashboard/dispositivos");
  };

  return (
    <div className={classes.divMain}>
      <Grid
        item
        xs={12}
        container
        direction="row"
        alignItems="center"
        onClick={backStep}
        className={classes.divBack}
      >
        <Grid item>
          <FontAwesomeIcon
            icon={faArrowAltCircleLeft}
            style={{
              width: 20,
              height: 20,
              marginRight: 10,
              color: "#d6d6d6",
            }}
          />
        </Grid>
        <Grid item>
          <Typography className={classes.back}>Regresar</Typography>
        </Grid>
      </Grid>

      <Typography className={classes.title}>Camaras</Typography>

      <Grid container direction="row" alignItems="center">
        <TextField
          variant="outlined"
          size="small"
          placeholder="Buscar camara por nombre o mac address"
          value={query?.search}
          onChange={(e) => setQuery({ ...query, search: e.target.value })}
          className={classes.textFieldCustom}
          style={{ marginTop: 15 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon
                  icon={faSearch}
                  style={{
                    width: "18px",
                    height: "18px",
                    color: "#676767",
                  }}
                />
              </InputAdornment>
            ),
          }}
        />

        <Grid item xs={12} sm={6} md={4} lg={2} style={{ marginLeft: 20 }}>
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 16,
              textAlign: "left",
              color: "#dedcdc",
            }}
          >
            Obra
          </Typography>
          <div>
            <MultiSelect
              options={proyectos ?? []}
              value={query?.proyectos ?? []}
              onChange={handleQueryChange("proyectos")}
            />
          </div>
        </Grid>
      </Grid>

      <Box
        style={{ marginTop: 30 }}
        sx={{
          display: "grid",
          gap: 40,
          gridTemplateColumns: "repeat(auto-fill, minmax(330px, 1fr))",
        }}
      >
        {rows.length == 0 && !loading ? (
          <NotRecordsFound
            title={"No se han encontrado camaras"}
            subtitle={"No hemos encontrado camaras en la búsqueda"}
          />
        ) : (
          rows.map((device, index) => (
            <Grid ref={lastBookElementRef} key={index}>
              <CardDevice
                name={device?.DeviceName}
                status={
                  !(getMinDiff(new Date(device?.LastUpdated), new Date()) > 5)
                }
                ip={device?.IP}
                mac={device?.MacAddress}
                serial={device?.SerialNumber}
                obra={proyectos.find((x) => x.value == device?.Obra_id)?.label}
              />
            </Grid>
          ))
        )}
      </Box>

      <Box
        display="flex"
        m={1}
        p={1}
        alignItems="center"
        justifyContent="center"
      >
        {loading && (
          <CircularProgress disableShrink size={100} color="secondary" />
        )}
      </Box>
    </div>
  );
}
