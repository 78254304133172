/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Typography, Button, Box } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import InfoBasic from "./components/infoBasic";
// import InfoAdvance from "./Components/infoAdvance";
import InfoFiles from "./components/infoFiles";
import FlowComponent from "./components/FlowComponent";
import { useDispatch, useSelector } from "react-redux";
import { UploadDocuments } from "../../actions/file";
import { ipServer } from "../../config/vars";
import DialogBackScreen from "./components/DialogBackScreen";
import axios from "axios";
import ScrollBars from "../../common_components/ScrollBars";
import { addFile, clearFiles } from "../../actions/file";
import { getObra } from "../../actions/obras";
import DialogLoadingUploadDocuments from "./components/DialogLoadingUploadDocuments";
import { CapitalizeString } from "../../utils/CapitalizeString";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    paddingLeft: "30px",
    // backgroundColor: "blue",
  },
  divBack: {
    cursor: "pointer",
    marginBottom: "20px",
  },
  back: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 500,
    textAlign: "left",
    color: "#ffffff",
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 24,
    fontWeight: 500,
    textAlign: "left",
    color: "#ffffff",
  },
  subtitle: {
    fontFamily: "Roboto",
    fontSize: 14,
    textAlign: "left",
    color: "#ffffff",
  },
  icon: {
    width: "35px",
    height: "35px",
    color: "white",
    backgroundColor: "#a2a2a2",
    borderRadius: "15px",
  },
}));

export default function RegisterObrasScreen(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { files } = useSelector((state) => state.file);
  const { selectedObras } = useSelector((state) => state.obras);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [screen, setScreen] = useState(1);
  const [openDialogBack, setOpendialogBack] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [mandatoryFlag, setMandatoryFlag] = useState(false);
  const [changeDialog, setChangeDialog] = useState(false);
  const [colorButtonSave, setColorButtonSave] = useState({
    backColor: "#474A4E",
    text: "#919191",
  });
  const [colorButtonNext, setColorButtonNext] = useState({
    backColor: "#474a4e",
    text: "#919191",
    backArrow: "#676767",
    arrow: "#919191",
  });
  const [obrasData, setObrasData] = useState({
    id: "",
    photo: "",
    photoCache: "",
    name: "",
    address: "",
    latitud: "",
    longitud: "",
    client: "",
    claveProyecto: "",
    registroPatronal: "",
    registroSIROC: "",
    delegacionIMSS: "",
    subdelegacionIMSS: "",
  });

  const backStep = () => {
    if (
      changeDialog ||
      files.some((file) => file.process != "db" && file.url === null)
    ) {
      setOpendialogBack(!openDialogBack);
    } else {
      if (selectedObras) {
        dispatch(getObra(selectedObras._id));
        history.push(`/dashboard/obras/${selectedObras._id}`);
      } else {
        history.push(`/dashboard/obras`);
      }
    }
  };

  const nextStep = () => {
    if (screen == 1 && mandatoryFlag == false) {
      enqueueSnackbar("Favor de llenar campos obligatorios", {
        variant: "error",
      });
      return;
    }
    setScreen(screen + 1);
  };

  const lastStep = () => {
    setScreen(screen - 1);
  };

  const renderRegisterScreen = () => {
    if (screen == 1)
      return (
        <InfoBasic
          obrasData={obrasData}
          setObrasData={setObrasData}
          setMandatoryFlag={setMandatoryFlag}
          setChangeDialog={setChangeDialog}
        />
      );
    if (screen == 2)
      return (
        <InfoFiles setMandatoryFlag={setMandatoryFlag} obrasData={obrasData} />
      );
  };

  useEffect(() => {
    if (localStorage.getItem("idSite")) {
      axios
        .get(
          `${ipServer}/api/v1/rules/proyectos/` +
            localStorage.getItem("idSite"),
          {
            headers: {
              Authorization: localStorage.getItem("JWT"),
            },
          }
        )
        .then((response) => {
          // console.log("load", response.data.data[0]);
          setObrasData({
            id: response.data.data[0]._id,
            photo: response.data.data[0].foto
              ? response.data.data[0].foto?.url
              : "",
            photoCache: response.data.data[0].foto
              ? response.data.data[0].foto?.url
              : "",
            name: response.data.data[0].proyecto ?? "",
            address: response.data.data[0].domicilio ?? "",
            client: response.data.data[0].cliente ?? "",
            claveProyecto: response.data.data[0].claveProyecto ?? "",
            registroPatronal: response.data.data[0].registroPatronal ?? "",
            registroSIROC: response.data.data[0].registroSIROC ?? "",
            delegacionIMSS: response.data.data[0].delegacionIMSS ?? "",
            subdelegacionIMSS: response.data.data[0].subdelegacionIMSS ?? "",
            latitud: response.data.data[0].latitud ?? "",
            longitud: response.data.data[0].longitud ?? "",
          });

          dispatch(clearFiles());
          response.data.data[0]?.documentos?.map((file, index) =>
            dispatch(
              addFile({
                name: file.filename,
                url: file.file,
                originalName: file.originalName,
                process: "db",
                date: file.datetime,
              })
            )
          );
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [selectedObras]);

  useEffect(() => {
    if (mandatoryFlag == true) {
      setColorButtonSave({
        ...colorButtonSave,
        backColor: "#ac0bce",
        text: "#ffffff",
      });
      setColorButtonNext({
        ...colorButtonNext,
        backColor: "#2d3034",
        text: "#fff",
        backArrow: "#d233ff",
        arrow: "#fff",
      });
    } else {
      setColorButtonSave({
        ...colorButtonSave,
        backColor: "#474A4E",
        text: "#919191",
      });
      setColorButtonNext({
        ...colorButtonNext,
        backColor: "#474a4e",
        text: "#919191",
        backArrow: "#676767",
        arrow: "#919191",
      });
    }
  }, [mandatoryFlag]);

  const registerObra = async () => {
    return axios
      .post(
        `${ipServer}/api/v1/rules/proyectos`,
        {
          proyecto: obrasData.name,
          domicilio: obrasData.address,
          cliente: obrasData.client,
          claveProyecto: obrasData.claveProyecto,
          registroPatronal: obrasData.registroPatronal,
          registroSIROC: obrasData.registroSIROC,
          delegacionIMSS: obrasData.delegacionIMSS,
          subdelegacionIMSS: obrasData.subdelegacionIMSS,
          latitud: obrasData.latitud,
          longitud: obrasData.longitud,
        },
        {
          headers: {
            Authorization: localStorage.getItem("JWT"),
          },
        }
      )
      .then((response) => {
        // console.log("register", response.data.data);
        setObrasData({ ...obrasData, id: response.data.data._id });
        return response.data.data._id;
      })
      .catch((error) => {
        console.log(error?.response?.data);
        enqueueSnackbar(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : `Error al registrar obra`,
          {
            variant: "error",
          }
        );

        return null;
      });
  };

  const UpdateObra = async () => {
    return axios
      .patch(
        `${ipServer}/api/v1/rules/proyectos/${obrasData.id}`,
        {
          // proyecto: CapitalizeString(obrasData.name),
          domicilio: CapitalizeString(obrasData.address),
          cliente: CapitalizeString(obrasData.client),
          claveProyecto: obrasData.claveProyecto,
          registroPatronal: obrasData.registroPatronal,
          registroSIROC: obrasData.registroSIROC,
          delegacionIMSS: obrasData.delegacionIMSS,
          subdelegacionIMSS: obrasData.subdelegacionIMSS,
        },
        {
          headers: {
            Authorization: localStorage.getItem("JWT"),
          },
        }
      )
      .then((response) => {
        return response.data.data._id;
      })
      .catch((e) => {
        console.log(e);
        return null;
      });
  };

  const savePhotoObra = async (id) => {
    if (typeof obrasData.photo === "string") {
      return true;
    }

    let formData = new FormData();
    formData.append("file", obrasData.photo);
    formData.append(
      "data",
      JSON.stringify({
        name: "foto",
      })
    );

    return await axios
      .patch(`${ipServer}/api/v1/rules/proyectos/${id}/document`, formData, {
        headers: {
          Authorization: localStorage.getItem("JWT"),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        // console.log(response.data.data);
        return true;
      })
      .catch((e) => {
        console.log(e);
        return false;
      });
  };

  const saveObra = async () => {
    setDisableButton(true);
    const process = obrasData.id != "" ? "actualizar" : "registrar";
    let id = obrasData.id != "" ? obrasData.id : null;

    if (mandatoryFlag == false) {
      enqueueSnackbar("Favor de llenar campos obligatorios", {
        variant: "error",
      });

      setDisableButton(false);
      return;
    }

    if (!id) {
      id = await registerObra();
    } else {
      await UpdateObra();
    }

    if (id) {
      if (obrasData.photo && !(await savePhotoObra(id))) {
        enqueueSnackbar(`Error al ${process} obra`, {
          variant: "error",
        });

        setDisableButton(false);
        return;
      }
    } else {
      // enqueueSnackbar(`Error al ${process} obra`, {
      //   variant: "error",
      // });

      setDisableButton(false);
      return;
    }

    const documents = files.some(
      (file) => file.process != "db" && file.url === null
    );
    if (documents) {
      dispatch(UploadDocuments(id, files, "proyectos"));
    }

    enqueueSnackbar(
      `Obra ${
        process == "registrar" ? "registrada" : "actualizada"
      } correctamente`,
      {
        variant: "success",
      }
    );

    setChangeDialog(false);
    setDisableButton(false);

    return documents;
  };

  return (
    <div className={classes.divMain}>
      <Grid container>
        <Grid
          item
          xs={1}
          container
          direction="row"
          alignItems="center"
          onClick={backStep}
          className={classes.divBack}
        >
          <Grid item>
            <FontAwesomeIcon
              icon={faArrowAltCircleLeft}
              style={{
                width: "20px",
                height: "20px",
                marginRight: "10px",
                color: "#d6d6d6",
              }}
            />
          </Grid>
          <Grid item>
            <Typography className={classes.back}>Regresar</Typography>
          </Grid>
        </Grid>
        <Grid item xs={11}></Grid>

        <Grid item xs={12}>
          <Typography className={classes.title}>{`${
            !selectedObras ? "Registrar" : "Actualizar"
          } obra`}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.subtitle}>
            {`${
              !selectedObras
                ? "Registra las obras que pertenezcan a tu empresa"
                : "Edita la información perteneciente a la obra"
            }`}
          </Typography>
        </Grid>

        <FlowComponent
          screen={screen}
          setScreen={setScreen}
          mandatoryFlag={mandatoryFlag}
        />

        <Grid
          item
          xs={8}
          style={{
            height: "100%",
          }}
        >
          <ScrollBars style={{ width: "100%", height: "60vh" }}>
            {renderRegisterScreen()}
          </ScrollBars>
        </Grid>

        <Grid
          container
          direction="column"
          style={{
            bottom: 0,
            right: 0,
            marginTop: 20,
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            style={{
              paddingRight: "38px",
            }}
          >
            {screen > 1 ? (
              <Button
                // type="submit"
                variant="contained"
                onClick={lastStep}
                style={{
                  width: "160px",
                  height: "48px",
                  borderRadius: "8px",
                  color: "#ffffff",
                  backgroundColor: "#2d3034",
                  textTransform: "none",
                }}
              >
                <FontAwesomeIcon
                  icon={faArrowAltCircleLeft}
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "10px",
                    color: "#fff",
                    backgroundColor: "#d233ff",
                    borderRadius: "50%",
                  }}
                />
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 21,
                    textAlign: "left",
                    color: "#ffffff",
                    paddingRight: "10px",
                  }}
                >
                  Anterior
                </Typography>
              </Button>
            ) : (
              <div></div>
            )}

            {screen == 2 ? (
              <div></div>
            ) : (
              <Button
                variant="contained"
                disabled={disableButton}
                onClick={nextStep}
                style={{
                  width: "160px",
                  height: "48px",
                  borderRadius: "8px",
                  color: "#ffffff",
                  backgroundColor: colorButtonNext.backColor,
                  marginLeft: "30px",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 21,
                    textAlign: "left",
                    color: colorButtonNext.text,
                    textTransform: "none",
                  }}
                >
                  Siguiente
                </Typography>
                <FontAwesomeIcon
                  icon={faArrowAltCircleRight}
                  style={{
                    width: "20px",
                    height: "20px",
                    marginLeft: "10px",
                    color: colorButtonNext.arrow,
                    backgroundColor: colorButtonNext.backArrow,
                    borderRadius: "50%",
                  }}
                />
              </Button>
            )}
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            style={{
              paddingRight: "38px",
              marginTop: 30,
            }}
          >
            {/* <BarProgressComponent /> */}

            <Button
              variant="contained"
              disabled={disableButton}
              onClick={saveObra}
              style={{
                width: "160px",
                height: "48px",
                borderRadius: "8px",
                backgroundColor: colorButtonSave.backColor,
              }}
            >
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 21,
                  textAlign: "left",
                  color: colorButtonSave.text,
                  textTransform: "none",
                }}
              >
                {!selectedObras ? "Guardar" : "Actualizar"}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <DialogBackScreen
        saveObra={saveObra}
        openDialogBack={openDialogBack}
        setOpendialogBack={setOpendialogBack}
      />

      <DialogLoadingUploadDocuments type="proyectos" />
    </div>
  );
}
