/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, TextField } from "@material-ui/core";
import Typo from "../../../common_components/typo";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
  },
  divImg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "160px",
    height: "160px",
    borderRadius: "16px",
    border: "solid 1px #707070",
    backgroundColor: "#212429",
    cursor: "pointer",
  },
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: "bold",
    textAlign: "left",
    color: "#cdcece",
  },
  textFieldCustom: {
    width: "70%",
    color: "#676767",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
}));

export default function InfoPassword(props) {
  const classes = useStyles();
  const { screenProfile } = useSelector((state) => state.screen);

  const handleChange = (e) => {
    props.setChangesFlag(true);
    const value = e.target.value;
    props.setUserData({
      ...props.userData,
      [e.target.name]: value,
    });
  };

  useEffect(() => {
    if (screenProfile == 2) props.setMandatoryFlag(true);
  }, []);

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <Typo
            fontSize={24}
            fontWeight={500}
            text={"Cambio de contraseña"}
            color={"#fff"}
            marginBottom={5}
          />
          <Typo
            fontSize={16}
            text={
              "Puedes asignar una nueva contraseña para tu cuenta de usuario"
            }
            color={"#d5d5d5"}
            marginBottom={50}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Typography className={classes.textFieldLetter}>
            Contraseña actual
          </Typography>
          <TextField
            placeholder="Obligatorio"
            name="password"
            size="small"
            variant="outlined"
            type="password"
            value={props.userData.password}
            className={classes.textFieldCustom}
            onChange={handleChange}
            inputProps={{
              maxLength: 50,
            }}
          />
        </Grid>
        <Grid item md={6} lg={6} xl={6}></Grid>

        <Grid
          item
          xs={12}
          style={{
            height: "30px",
          }}
        ></Grid>

        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Typography className={classes.textFieldLetter}>
            Nueva contraseña
          </Typography>
          <TextField
            placeholder="Obligatorio"
            name="newPassword"
            size="small"
            variant="outlined"
            type="password"
            value={props.userData.newPassword}
            className={classes.textFieldCustom}
            onChange={handleChange}
            inputProps={{
              maxLength: 12,
              style: { textTransform: "lowercase" },
            }}
          />
        </Grid>
        <Grid item md={6} lg={6} xl={6}></Grid>

        <Grid
          item
          xs={12}
          style={{
            height: "30px",
          }}
        ></Grid>

        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Typography className={classes.textFieldLetter}>
            Repetir contraseña
          </Typography>
          <TextField
            placeholder="Obligatorio"
            name="confirmPassword"
            size="small"
            variant="outlined"
            type="password"
            value={props.userData.confirmPassword}
            className={classes.textFieldCustom}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </div>
  );
}
