/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Checkbox, Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  typo: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 1.31,
    textAlign: "left",
    color: "#cdcece",
  },
  checkbox: {
    padding: 2,
    color: "#484848",
    "&.Mui-checked": {
      color: "#d233ff",
    },
  },
}));

export default function CheckboxComponent(props) {
  const { text, value, reportName, reportSettings, setReportSettings } = props;
  const classes = useStyles();

  const handleChange = (e) => {
    // console.log(e.target.checked);
    // console.log(e.target.name);
    let index = reportSettings.findIndex((x) => x.name == reportName);
    let newArr = [...reportSettings]; // copying the old datas array
    // a deep copy is not needed as we are overriding the whole object below, and not setting a property of it. this does not mutate the state.
    newArr[index].rangoFechaDefault = e.target.name; // replace e.target.value with whatever you want to change it to
    setReportSettings(newArr);
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Checkbox
        name={text}
        checked={value}
        onChange={handleChange}
        className={classes.checkbox}
        inputProps={{ "aria-label": "controlled" }}
      />
      <Typography className={classes.typo}>{text}</Typography>
    </Grid>
  );
}
