/*eslint-disable*/
import React, { useEffect, useState } from "react";
import useStyles from "../styles/form_register_styles";
import { useSnackbar } from "notistack";
import {
  TextField,
  Button,
  Typography,
  Grid,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLock,
  faUser,
  faArrowAltCircleLeft,
} from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import { ipServer } from "../../../config/vars";
import LicensePolicy from "../../../archives/politica_licencamiento.pdf";
import NoticeOfPrivacy from "../../../archives/aviso_privacidad_2_2.pdf";
import { useHistory } from "react-router-dom";
import Logo from "../../../images/ABSA-INDUSTRIAL-LOGO-BLANCO.png";

export default function CreateAccount(props) {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();

  const schema = Yup.object({
    name: Yup.string().required("Favor de ingresar nombre"),
    email: Yup.string()
      .required("Favor de ingresar correo")
      .email("Favor de ingresar un correo valido"),
    password: Yup.string()
      .required("Favor de ingresar contraseña")
      .min(8, "Contraseña muy corta, debe tener almenos 8 caracteres")
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z@$!%*#?&]{8,}$/,
        "La contraseña debe tener 1 letra minúscula, 1 letra mayúscula y 1 digito"
      ),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
    },
    validationSchema: schema,
  });

  const validateData = async () => {
    return await schema.isValid(formik.values);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async () => {
    const { name, email, password } = formik.values;

    props.setEmail(email);
    props.setPassword(password);

    if (!(await validateData())) {
      formik.errors.email &&
        enqueueSnackbar(formik.errors.email, {
          variant: "error",
        });

      formik.errors.password &&
        enqueueSnackbar(formik.errors.password, {
          variant: "error",
        });

      formik.errors.name &&
        enqueueSnackbar(formik.errors.name, {
          variant: "error",
        });
      return;
    }

    axios
      .post(`${ipServer}/api/v1/security/user/register/`, {
        Email: email,
        Password: password,
        Name: name,
      })
      .then((response) => {
        // console.log(response.data);
        props.setStep(true);
        enqueueSnackbar("Usuario registrado correctamente", {
          variant: "success",
        });
      })
      .catch((e) => {
        if (e.response.data) {
          console.log(e.response);
          if (
            e.response.data.errors == "User is already active" ||
            e.response.data.errors == "User already exist"
          ) {
            enqueueSnackbar("Usuario registrado anteriormente", {
              variant: "error",
            });
          } else {
            enqueueSnackbar("Error al registrar usuario", {
              variant: "error",
            });
          }
        } else {
          console.log(e.response);
          enqueueSnackbar("Error al registrar usuario", {
            variant: "error",
          });
        }
      });
  };

  useEffect(() => {
    // se activa formik sin darle clic al textfield
    formik.setFieldValue("name", "");
  }, []);

  return (
    <Grid container spacing={4} direction="column" style={{ width: "100%" }}>
      <Grid item xs={12}>
        <img src={Logo} className={classes.logo} alt="techton logo" />
      </Grid>

      <Grid item xs={12} container direction="column">
        <FontAwesomeIcon
          icon={faArrowAltCircleLeft}
          onClick={() => history.push(`/inicio_sesion`)}
          style={{
            width: 20,
            height: 20,
            color: "#d6d6d6",
            cursor: "pointer",
          }}
        />
        <Typography className={classes.title}>Crea cuenta</Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography className={classes.textFieldLabel}>Nombre</Typography>
        <TextField
          id="name"
          type="text"
          name="name"
          size="medium"
          variant="outlined"
          fullWidth
          className={classes.textFieldCustom}
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              onSubmit();
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon
                  icon={faUser}
                  style={{
                    width: 24,
                    height: 24,
                    color: "#9b9b9b",
                  }}
                />
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography className={classes.textFieldLabel}>Email</Typography>
        <TextField
          id="email"
          name="email"
          size="medium"
          autoComplete="email"
          variant="outlined"
          fullWidth
          className={classes.textFieldCustom}
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              onSubmit();
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon
                  icon={faEnvelope}
                  style={{
                    width: 24,
                    height: 24,
                    color: "#9b9b9b",
                  }}
                />
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography className={classes.textFieldLabel}>Password</Typography>
        <TextField
          id="password"
          type={showPassword ? "text" : "password"}
          name="password"
          size="medium"
          autoComplete="current-password"
          variant="outlined"
          fullWidth
          className={classes.textFieldCustom}
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              onSubmit();
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon
                  icon={faLock}
                  style={{
                    width: 24,
                    height: 24,
                    color: "#9b9b9b",
                  }}
                />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="start">
                <IconButton
                  className={classes.iconForm}
                  onClick={handleClickShowPassword}
                  style={{
                    width: "24px",
                    height: "24px",
                    color: "#ADADAD",
                  }}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      {/* <Grid item xs={12}>
        <Typography variant="body1" className={classes.typoLink}>
          {`Al hacer click en "Registrarse" acepto la `}
          <a href={LicensePolicy} target="_blank" className={classes.typoLink1}>
            política de licenciamiento
          </a>
          {` y el `}
          <a
            href={NoticeOfPrivacy}
            target="_blank"
            className={classes.typoLink1}
          >
            aviso de privacidad
          </a>
        </Typography>
      </Grid> */}

      <Grid item xs={12}>
        <Button
          fullWidth
          variant="contained"
          onClick={onSubmit}
          className={classes.button}
        >
          <Typography className={classes.typoButton}>Registrarse</Typography>
        </Button>
      </Grid>

      <Grid
        item
        xs={12}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Typography className={classes.typoLabel}>
          ¿Ya tienes una cuenta?
        </Typography>

        <Button
          // variant="contained"
          onClick={() => history.push(`/inicio_sesion`)}
          className={classes.typoLink2}
        >
          <Typography className={classes.typoButtonLogin}>
            Registrarse
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
}
