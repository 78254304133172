/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import svgTrabajador from "../../images/usuario_trabajador.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMedkit, faImage } from "@fortawesome/free-solid-svg-icons";
import RViewerJS from "viewerjs-react";
import "viewerjs-react/dist/index.css";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    // height: "100%",
    // backgroundColor: "red",
  },
  divPhoto: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "120px",
    height: "120px",
    borderRadius: "8px",
    backgroundColor: "#828282",
  },
  photo: {
    width: "110px",
    height: "100px",
    objectFit: "contain",
    borderRadius: "16px",
    objectFit: "contain",
  },
  gridPaper: {
    height: "178px",
    borderRadius: "16px",
    backgroundColor: "#2d3034",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  },
  gridMargin: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
  gridMargin2: {
    [theme.breakpoints.down("sm")]: {
      marginfLeft: 20,
    },
  },
}));

export default function CardSummaryEnterprise(props) {
  const classes = useStyles();

  return (
    <div className={classes.divMain}>
      <Grid container>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={classes.gridPaper}
        >
          <Grid
            item
            xs={4}
            sm={4}
            md={4}
            lg={4}
            xl={4}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className={classes.gridMargin}
            // style={{
            //   backgroundColor: "red",
            // }}
          >
            {props.urlPhoto == "" || props.urlPhoto == null ? (
              <div className={classes.divPhoto}>
                <FontAwesomeIcon
                  icon={faImage}
                  style={{
                    width: "36px",
                    height: "28px",
                    color: "#2d3034",
                    borderRadius: "8px",
                  }}
                />
              </div>
            ) : (
              <RViewerJS>
                <img
                  src={props.urlPhoto}
                  className={classes.photo}
                  alt="photo"
                />
              </RViewerJS>
            )}
          </Grid>

          <Grid
            item
            xs={8}
            sm={8}
            md={8}
            lg={8}
            xl={8}
            className={classes.gridMargi2}
            // style={{
            //   backgroundColor: "blue",
            // }}
          >
            <Grid container direction="column" spacing={1}>
              <Grid item xs>
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 20,
                    fontWeight: 500,
                    color: "#fff",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {props.name}
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 14,
                    color: "#acacac",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {props.businessName}
                </Typography>
                {/* <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 14,
                    color: "#acacac",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {props.unit}
                </Typography> */}
              </Grid>

              <Grid item xs>
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 14,
                    color: "#acacac",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    maxWidth: "90%",
                  }}
                >
                  {props.rfc}
                </Typography>
                <Grid container direction="row" justifyContent="flex-start">
                  <FontAwesomeIcon
                    icon={faMedkit}
                    style={{
                      width: "12px",
                      height: "12px",
                      color: "#fff",
                      marginTop: 2,
                      zIndex: 99,
                    }}
                  />

                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 12,
                      color: "#fff",
                      marginLeft: 5,
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      maxWidth: "90%",
                    }}
                  >
                    {props.securityNumber}
                  </Typography>
                </Grid>

                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  style={{
                    // backgroundColor: "green",
                    marginTop: 5,
                  }}
                >
                  <Grid item>
                    <img
                      src={svgTrabajador}
                      alt="techton logo"
                      style={{
                        width: "12px",
                        height: "12px",
                        marginRight: 5,
                      }}
                    />
                  </Grid>
                  <Grid item xs>
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontSize: 14,
                        textAlign: "left",
                        color: "#fff",
                      }}
                    >
                      {props.employees == "" || props.employees == null
                        ? "0 Trabajadores"
                        : props.employees == 1
                        ? props.employees + " Trabajador"
                        : props.employees + " Trabajadores"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {/* <Grid item xs>
                <Grid item xs container direction="column">
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 16,
                      color: "#fff",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      maxWidth: "90%",
                    }}
                  >
                    {props.agent}
                  </Typography>

                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 16,
                      color: "#fff",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      maxWidth: "90%",
                    }}
                  >
                    {props.telephone}
                  </Typography>
                </Grid>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
