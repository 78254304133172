/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ScrollBars from "../../../common_components/ScrollBars";
import useProyectSearch from "./useProyectSearch";

const useStyles = makeStyles((theme) => ({
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: "bold",
    textAlign: "left",
    color: "#cdcece",
  },
  textFieldCustom: {
    width: "400px",
    color: "#676767",
    border: "solid 1px #c5c5c5",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
  },
  divImg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "356px",
    height: "290px",
    borderRadius: "8px",
    border: "solid 1px #707070",
    backgroundColor: "#212429",
    cursor: "pointer",
  },
}));

export default function DialogSitesList(props) {
  const classes = useStyles();
  const [query, setQuery] = useState({
    ObraName: "",
    Obras: [],
  });

  const { books, hasMore, loading, error } = useProyectSearch(query);

  const handleOpenDialog = () => {
    props.setOpendialogSite(!props.openDialogSite);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setQuery({
      ...query,
      [e.target.name]: value,
    });
  };

  const proyectsList = () => {
    // console.log(books);
    // console.log(props.employeeData);
    const items = books.map((proyecto, index) => {
      return (
        <Grid
          key={index}
          onClick={() => {
            props.setEmployeeData({
              ...props.employeeData,
              proyectoObra: proyecto.proyecto,
              proyectoObraId: proyecto._id,
            });
            props.setChangesFlag(true);
            props.setSiteFlag(true);
            props.setOpendialogSite(!props.openDialogSite);
          }}
          container
          direction="row"
          justifyContent="flex-start"
          style={{ cursor: "pointer", paddingTop: "5%", paddingLeft: "5%" }}
        >
          <Grid item xs={10}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 20,
                fontWeight: "bold",
                textAlign: "left",
                color: "#fff",
              }}
            >
              {proyecto.proyecto}
            </Typography>
          </Grid>

          <Grid
            item
            xs={2}
            container
            direction="row"
            justifyContent="flex-end"
            style={{
              paddingRight: "5%",
              // backgroundColor: "red"
            }}
          >
            <FontAwesomeIcon
              icon={faChevronRight}
              className={classes.iconForm}
              style={{
                width: "10px",
                height: "20px",
                color: "#fff",
              }}
            />
          </Grid>
        </Grid>
      );
    });
    return items;
  };

  useEffect(() => {
    proyectsList();
  }, [props.proyects]);

  useEffect(() => {
    setQuery({ ...query, Obras: props.employeeData.ObrasTemp, ObraName: "" });
    // props.setEmployeeData({
    //   ...props.employeeData,
    //   proyectoObra: "",
    //   proyectoObraId: "",
    // });
  }, [props.employeeData.ObrasTemp]);

  return (
    <div>
      <Dialog
        open={props.openDialogSite}
        onClose={handleOpenDialog}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            backgroundColor: "#171a1e",
            textColor: "#fsf",
            width: "483px",
            height: "600px",
            paddingTop: 15,
          },
        }}
      >
        <DialogTitle>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 22,
                textAlign: "center",
                color: "#fff",
                fontWeight: "bold",
              }}
            >
              Obras
            </Typography>

            <Grid
              container
              justifyContent="flex-end"
              style={{ position: "absolute" }}
            >
              <Button onClick={handleOpenDialog}>
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{
                    width: "20px",
                    height: "20px",
                    color: "#fff",
                  }}
                />
              </Button>
            </Grid>
          </Grid>
          <div
            style={{
              width: "100%",
              height: 1,
              background: "#707070",
              marginTop: 8,
              padding: 0,
            }}
          ></div>
        </DialogTitle>

        <DialogContent>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid>
              <TextField
                placeholder="Buscar..."
                name="ObraName"
                size="small"
                variant="outlined"
                value={query?.ObraName}
                className={classes.textFieldCustom}
                onChange={handleChange}
              />
            </Grid>

            <ScrollBars style={{ height: 410 }}>{proyectsList()}</ScrollBars>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
