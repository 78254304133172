/*eslint-disable*/
import React, { Fragment, useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import CardObras from "./components/CardObras";
import HoursWorked from "./components/HoursWorked";
import CardFileObras from "./components/CardFileObras";
import CardSubcontratista from "./components/CardSubcontratista";
import ScrollBars from "../../../common_components/ScrollBars";
import { useDispatch, useSelector } from "react-redux";
import DialogEditSubcontratistas from "./components/DialogEditSubcontratistas";
import { ValidatePermissions } from "../../../utils/permissions";
import Lottie from "react-lottie";
import LottieNotFound from "../../../animations/lottie_not_found.json";
import axios from "axios";
import axiosInstance from "../../../utils/axiosInstance";

const useStyles = makeStyles((theme) => ({
  divBack: {
    cursor: "pointer",
    marginBottom: "10px",
  },
  back: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 500,
    textAlign: "left",
    color: "#fff",
  },
}));

export default function ObrasProfileScreen(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { selectedObras } = useSelector((state) => state.obras);
  const [openDialog, setOpendialog] = useState(false);
  const [trabajadoresSub, setTrabajadoresSub] = useState([]);

  const handleEdit = () => {
    setOpendialog(!openDialog);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LottieNotFound,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    if (selectedObras) {
      const promisearray = selectedObras?.subcontratistas
        ?.filter(
          (_subcontratistas) =>
            !_subcontratistas.oculta && _subcontratistas.status != "deleted"
        )
        ?.map((obra, index) => {
          // console.log(`proyectos_id`, selectedObras?._id);
          // console.log(`subcontratistas_id`, obra._id);
          const CancelToken = axios.CancelToken;
          const source = CancelToken.source();

          var params = new URLSearchParams();
          params.append("limit", 1);
          params.append("page", 0);
          params.append("proyectos_id", selectedObras?._id);
          params.append("subcontratistas_id", obra._id);

          return axiosInstance()
            .get(`/api/v1/rules/trabajadores/filter`, {
              params,
              cancelToken: source.token,
            })
            .then((response) => {
              // console.log(response.data);
              return response;
            })
            .catch((e) => {
              console.log(e.response?.data);
              return;
            });
        });

      Promise.all(promisearray)
        .then((response) => {
          var array = [];
          array = response?.map((res) => res?.data?.data?.Total);
          setTrabajadoresSub(array);
        })
        .catch((err) => console.log(err));
    }
  }, [selectedObras]);

  return (
    <Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12} lg={7}>
          <CardObras data={selectedObras} />
        </Grid>

        <Grid item xs={12} lg={5}>
          {/* <div>
            <HoursWorked />
          </div> */}

          <CardFileObras
            data={selectedObras}
            files={selectedObras?.documentos}
          />
        </Grid>

        <Grid container>
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 24,
              color: "#fff",
              fontWeight: 500,
              marginLeft: 30,
              marginTop: 10,
            }}
          >
            Subcontratistas
          </Typography>

          {ValidatePermissions("editar") && (
            <Typography
              onClick={handleEdit}
              style={{
                fontFamily: "Roboto",
                fontSize: 20,
                fontWeight: 500,
                color: "#d233ff",
                cursor: "pointer",
                marginLeft: 15,
                marginTop: 15,
              }}
            >
              Editar
            </Typography>
          )}
        </Grid>

        <Grid container item xs={12} lg={7} spacing={2}>
          <ScrollBars
            style={{ height: "400px", marginLeft: 4, width: "100%" }}
            spacing={4}
            padding={15}
          >
            {selectedObras?.subcontratistas?.filter(
              (_subcontratistas) => !_subcontratistas.oculta
            ).length > 0 ? (
              selectedObras?.subcontratistas
                ?.filter(
                  (_subcontratistas) =>
                    !_subcontratistas.oculta &&
                    _subcontratistas.status != "deleted"
                )
                ?.map((sub, index) => (
                  <Grid key={index} item xs={12} lg={6}>
                    <CardSubcontratista
                      idSubcontractor={sub._id}
                      urlPhoto={sub.foto ? sub.foto.url : ""}
                      enterprise={sub.empresa}
                      businessName={sub.razonSocial}
                      description={sub.descripcion}
                      rfc={sub.rfc}
                      employees={
                        trabajadoresSub.length > 0 ? trabajadoresSub[index] : 8
                      }
                      securityNumber={sub.numeroSeguro}
                      agent={sub.representante}
                      telephone={sub.telefono}
                    />
                  </Grid>
                ))
            ) : (
              <Grid
                container
                direction="column"
                alignItems="center"
                style={{ marginTop: 30 }}
              >
                <Lottie options={defaultOptions} height={200} width={200} />
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 26,
                    fontWeight: 500,
                    color: "#fff",
                  }}
                >
                  No se han encontrado subcontratistas
                </Typography>
              </Grid>
            )}
          </ScrollBars>
        </Grid>
      </Grid>

      <DialogEditSubcontratistas
        setOpendialog={setOpendialog}
        openDialog={openDialog}
        data={selectedObras?.subcontratistas}
        obraId={selectedObras?._id}
      />
    </Fragment>
  );
}
