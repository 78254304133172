/*eslint-disable*/
import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  TextField,
  Select,
  FormControl,
  MenuItem,
} from "@material-ui/core";
import DateComponent from "../../../common_components/DateComponent";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
  },
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: "bold",
    textAlign: "left",
    color: "#cdcece",
  },
  textFieldCustom: {
    width: "90%",
    color: "#676767",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
  },
  formControl: {
    width: "90%",
  },
  select: {
    width: "100%",
    height: "40px",
    borderRadius: "6px",
    backgroundColor: "#fff",
  },
  gridMargin: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
}));

export default function InfoAdvance(props) {
  const classes = useStyles();
  const [admissionDate, setAdmissionDate] = useState(new Date());
  const [birthDate, setBirthDate] = useState(
    new Date(new Date().setFullYear(new Date().getFullYear() - 18))
  );

  const handleChange = (e) => {
    props.setChangesFlag(true);
    const value = e.target.value;
    props.setEmployeeData({
      ...props.employeeData,
      [e.target.name]: value,
    });
    // console.log(props.employeeData);
  };

  return (
    <Grid container className={classes.divMain}>
      <Grid
        item
        lg={12}
        xl={12}
        style={{
          height: "80px",
        }}
      />

      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
        xl={4}
        className={classes.gridMargin}
      >
        <Typography className={classes.textFieldLetter}>Género</Typography>
        <FormControl className={classes.formControl}>
          <Select
            name="genero"
            value={props.employeeData.genero}
            onChange={handleChange}
            defaultValue=""
            inputProps={{ "aria-label": "Without label" }}
            className={classes.select}
          >
            <MenuItem value=" "> </MenuItem>
            <MenuItem value="Hombre">&ensp;Hombre</MenuItem>
            <MenuItem value="Mujer">&ensp;Mujer</MenuItem>
            <MenuItem value="No especificado">&ensp;No especificado</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
        xl={4}
        className={classes.gridMargin}
      >
        <Typography className={classes.textFieldLetter}>
          Fecha de nacimiento
        </Typography>
        <DateComponent
          name={"fechaNacimiento"}
          value={props.employeeData.fechaNacimientoString}
          date={birthDate}
          setDate={setBirthDate}
          setChangesFlag={props.setChangesFlag}
          employeeData={props.employeeData}
          setEmployeeData={props.setEmployeeData}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
        xl={4}
        className={classes.gridMargin}
      >
        <Typography className={classes.textFieldLetter}>
          Domicilio actual
        </Typography>
        <TextField
          name="domicilio"
          size="small"
          variant="outlined"
          value={props.employeeData.domicilio}
          className={classes.textFieldCustom}
          onChange={handleChange}
          inputProps={{
            maxLength: 80,
            style: { textTransform: "capitalize" },
          }}
        />
      </Grid>

      <Grid
        item
        xs={12}
        style={{
          height: "30px",
        }}
      ></Grid>

      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
        xl={4}
        className={classes.gridMargin}
      >
        <Typography className={classes.textFieldLetter}>
          Grado de estudios
        </Typography>
        <FormControl className={classes.formControl}>
          <Select
            name="gradoEstudios"
            value={props.employeeData.gradoEstudios}
            onChange={handleChange}
            defaultValue=""
            inputProps={{ "aria-label": "Without label" }}
            className={classes.select}
          >
            <MenuItem value=" "> </MenuItem>
            <MenuItem value="No aplica">&ensp;No aplica</MenuItem>
            <MenuItem value="Primaria">&ensp;Primaria</MenuItem>
            <MenuItem value="Secundaria">&ensp;Secundaria</MenuItem>
            <MenuItem value="Preparatoria">&ensp;Preparatoria</MenuItem>
            <MenuItem value="Licenciatura">&ensp;Licenciatura</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
        xl={4}
        className={classes.gridMargin}
      >
        <Typography className={classes.textFieldLetter}>
          Fecha de Ingreso
        </Typography>
        <DateComponent
          name={"fechaIngreso"}
          value={props.employeeData.fechaIngresoString}
          date={admissionDate}
          setDate={setAdmissionDate}
          setChangesFlag={props.setChangesFlag}
          employeeData={props.employeeData}
          setEmployeeData={props.setEmployeeData}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
        xl={4}
        className={classes.gridMargin}
      >
        <Typography className={classes.textFieldLetter}>
          Lugar de nacimiento
        </Typography>
        <TextField
          name="lugarNacimiento"
          size="small"
          variant="outlined"
          value={props.employeeData.lugarNacimiento}
          className={classes.textFieldCustom}
          onChange={handleChange}
          inputProps={{
            maxLength: 80,
            style: { textTransform: "capitalize" },
          }}
        />
      </Grid>

      <Grid
        item
        xs={12}
        style={{
          height: "30px",
        }}
      />

      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
        xl={4}
        className={classes.gridMargin}
      >
        <Typography className={classes.textFieldLetter}>
          Sabe escribir
        </Typography>
        <FormControl className={classes.formControl}>
          <Select
            name="escribir"
            value={props.employeeData.escribir}
            onChange={handleChange}
            defaultValue=""
            inputProps={{ "aria-label": "Without label" }}
            className={classes.select}
          >
            <MenuItem value=" "> </MenuItem>
            <MenuItem value="Si">&ensp;Si</MenuItem>
            <MenuItem value="No">&ensp;No</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
        xl={4}
        className={classes.gridMargin}
      >
        <Typography className={classes.textFieldLetter}>Sabe leer</Typography>
        <FormControl className={classes.formControl}>
          <Select
            name="leer"
            value={props.employeeData.leer}
            onChange={handleChange}
            defaultValue=""
            inputProps={{ "aria-label": "Without label" }}
            className={classes.select}
          >
            <MenuItem value=" "> </MenuItem>
            <MenuItem value="Si">&ensp;Si</MenuItem>
            <MenuItem value="No">&ensp;No</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}
