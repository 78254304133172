/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Typography, TextField, Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
// import useUserSearch from "./Components/useUserSearch";
import DataGridClients from "./components/DataGridClients";
import DialogNewClient from "./components/DialogNewClient";
import {
  ValidatePermissions,
  ValidatePermissionsModule,
} from "../../utils/permissions";
import { useSelector } from "react-redux";
import axiosInstance from "../../utils/axiosInstance";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    height: "100%",
    // paddingTop: 10,
    paddingLeft: 15,
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 32,
    textAlign: "left",
    color: "#ffffff",
    fontWeight: 500,
    marginBottom: 10,
  },
  typoLabel: {
    fontFamily: "Roboto",
    fontSize: 16,
    textAlign: "left",
    color: "#dedcdc",
  },
  textFieldCustom: {
    width: "100%",
    height: 40,
    background: "white",
    borderRadius: 8,
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
  button: {
    width: "170px",
    height: 40,
    borderRadius: 8,
    color: "#ffffff",
    backgroundColor: "#d233ff",
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
  typoButton: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 1.31,
    textAlign: "left",
    color: "#ffffff",
  },
  gridMargin: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
}));

export default function ClientsScreen(props) {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useSelector((state) => state.user);
  const [subcontratistas, setSubcontratistas] = useState(null);
  const [obras, setObras] = useState(null);
  const [openDialog, setOpendialog] = useState(false);
  const [openDialogEdit, setOpendialogEdit] = useState(false);
  const [complete, setComplete] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const permissionsUpdate = ValidatePermissions("editar");
  const [selectionRows, setSelectionRows] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const [query, setQuery] = useState({
    Empleado: "",
    Subcontratistas: [],
    Obras: [],
    pageSize: 10,
    rowsPerPageOptions: [5, 10, 15],
    page: 1,
  });

  const rows = [
    {
      id: 1,
      Cliente: "Javier Medina",
      ObrasActivas: 12,
      CostosModulo: "1,200",
      CostoReconocimiento: "1",
      Facturacion: "32,000",
      Estatus: "Pagado",
    },
  ];

  //   const { rows, totalRows, loading, error } = useUserSearch(
  //     query,
  //     loadingData,
  //     subcontratistas,
  //     obras
  //   );

  //   const handleQueryChange = (queryElement) => (e) => {
  //     const value =
  //       queryElement === "Subcontratistas" || queryElement === "Obras"
  //         ? e
  //         : e.target.value;
  //     return setQuery((prevQuery) => {
  //       return { ...prevQuery, ...{ [queryElement]: value } };
  //     });
  //   };

  //   const updateData = (k, v) => {
  //     setQuery((prev) => ({ ...prev, [k]: v }));
  //   };

  const getProyectsList = async (contractor) => {
    try {
      var params = new URLSearchParams();
      params.append("sortMongoAsc", "proyecto");

      return await axiosInstance()
        .get(`/api/v1/rules/subcontratistas/${contractor?._id}/proyectos`, {
          params,
        })
        .then((response) => {
          let proyects = response.data.data.map((object) => {
            return { label: object.proyecto, value: object._id };
          });

          setObras(proyects);
          return proyects;
        })
        .catch((e) => {
          console.log(e);
          return false;
        });
    } catch (error) {
      return false;
    }
  };

  const getSubcontractorList = async (contractor) => {
    try {
      var params = new URLSearchParams();
      params.append("showoculta", true);
      params.append("sortMongoAsc", "empresa");

      return await axiosInstance()
        .get("/api/v1/rules/subcontratistas", { params })
        .then((response) => {
          let empresa = response.data.data.map((object) => {
            return { label: object.empresa, value: object._id };
          });

          if (!contractor.empresaPadre) {
            empresa = empresa.filter(
              (empresa) => empresa.value === contractor?._id
            );
          }

          setSubcontratistas(empresa);
          return empresa;
        })
        .catch((e) => {
          console.log(e);
          return false;
        });
    } catch (error) {
      return false;
    }
  };

  //   useEffect(() => {
  //     const fetchData = async () => {
  //       setLoadingData(false);
  //       let contractor = user ? user.Subcontractor[0] : null;

  //       if (contractor) {
  //         const _subcontratistas = await getSubcontractorList(contractor);
  //         const _proyectos = await getProyectsList(contractor);

  //         // setQuery({
  //         //   ...query,
  //         //   Proyectos: _proyectos,
  //         //   Subcontratistas: _subcontratistas,
  //         // });

  //         setLoadingData(true);
  //       }
  //     };

  //     fetchData();
  //   }, [user]);

  //   useEffect(() => {
  //     if (openDialog) {
  //       setOpendialogEdit(false);
  //     }
  //   }, [openDialogEdit]);

  return (
    <div className={classes.divMain}>
      <Grid container direction="column">
        <Typography className={classes.title}>Clientes</Typography>

        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          {/* <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={3}
            xl={3}
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-end"
          >
            <TextField
              variant="outlined"
              size="small"
              placeholder="Buscar usuario"
              //   onChange={handleQueryChange("Empleado")}
              className={classes.textFieldCustom}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon
                      icon={faSearch}
                      style={{
                        width: "18px",
                        height: "18px",
                        color: "#676767",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Grid> */}

          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            lg={2}
            xl={2}
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-end"
          >
            {ValidatePermissions("crear") && (
              <Button
                variant="contained"
                onClick={() => setOpendialog((prev) => !prev)}
                className={classes.button}
              >
                <Typography className={classes.typoButton}>
                  Nuevo cliente
                </Typography>
              </Button>
            )}
          </Grid>
        </Grid>

        <Grid container>
          <DataGridClients
            // setOpendialog={setOpendialog}
            // setOpendialogEdit={setOpendialogEdit}
            // setComplete={setComplete}
            rows={rows ? rows : []}
            // loading={loading}
            // rowCount={totalRows}
            rowsPerPageOptions={query.rowsPerPageOptions}
            page={query.page}
            pageSize={query.pageSize}
            selectionModel={selectionRows}
            // onSelectionModelChange={(ids) => {
            //   setSelectionRows(ids);
            //   if (permissionsUpdate) {
            //     setSelectedUser(rows.find((row) => row.id == ids[0]));
            //     setOpendialogEdit(true);
            //   }
            // }}
            // onPageChange={(data) => {
            //   updateData("page", data + 1);
            // }}
            // onPageSizeChange={(data) => {
            //   updateData("page", 1);
            //   updateData("pageSize", data);
            // }}
            style={{
              height: 680,
              width: "100%",
              display: "flex",
              marginTop: -10,
            }}
          />
        </Grid>
      </Grid>

      <DialogNewClient
        openDialog={openDialog}
        setOpendialog={setOpendialog}
        // complete={complete}
        // data={selectedUser}
        // setData={setSelectedUser}
        // setComplete={setComplete}
      />
    </div>
  );
}
