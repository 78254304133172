/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Typography, Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleLeft,
  faEllipsisV,
  faAngleDown,
  faHistory,
} from "@fortawesome/free-solid-svg-icons";
import TopTabComponent from "./components/TopTab";
import EmployeeProfileScreen from "./ProfileScreen/EmployeeProfileScreen";
import { useSnackbar } from "notistack";
import AttendanceScreen from "./AttendanceScreen/AttendanceScreen";
import ReportEmployeeScreen from "./ReportEmployeeScreen/ReportEmployeeScreen";
import DialogDeleteEmployee from "./components/DialogDeleteEmployee";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DeleteIcon from "@material-ui/icons/Delete";
import { ValidatePermissions, ValidatePermissionsFn } from "../../utils/permissions";
import { useSelector } from "react-redux";
import DialogStatus from "./components/DialogStatus";
import axiosInstance from "../../utils/axiosInstance";
import { saveAs } from "file-saver";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    // height: "100%",
    // backgroundColor: "red",
  },
  divBack: {
    cursor: "pointer",
    marginBottom: "10px",
    // backgroundColor: "red",
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
  back: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 500,
    textAlign: "left",
    color: "#fff",
    marginRight: 2,
  },
  icon: {
    color: "white",
    marginRight: 6,
    marginTop: -2,
  },
  gridMargin: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
  buttonSearch: {
    width: "220px",
    height: "40px",
    borderRadius: "8px",
    color: "#ffffff",
    backgroundColor: "#ac0bce",
    textTransform: "none",
    marginLeft: 30,
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
      marginLeft: 0,
    },
  },
}));

export default function DetailEmployeeScreen(props) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { user } = useSelector((state) => state.user);
  const { permissions, modules, keyPermission } = useSelector((state) => state.permissions);
  const [statusDemek, setStatusDemek] = useState("");
  const [tab, setTab] = useState(1);
  const [openDialog, setOpendialog] = useState(false);
  const [openDialogStatus, setOpendialogStatus] = useState(false);
  const [openDialogSavingEmployee, setOpenDialogSavingEmployee] =
    useState(false);
  const [openDialogUnsavedEmployee, setOpenDialogUnsavedEmployee] =
    useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    setOpendialog(true);
    setAnchorEl(null);
  };

  const handleStatus = () => {
    const location = window.location.pathname;
    if (ValidatePermissionsFn("editar", permissions, modules, keyPermission, location)) {
      setOpendialogStatus(true);
    } else {
      enqueueSnackbar("Necesita permiso para realizar esta acción", {
        variant: "error",
      });
    }
  };

  const renderScreen = () => {
    if (tab == 1)
      return (
        <EmployeeProfileScreen
        // setStep={props.setStep}
        // setScreen={props.setScreen}
        // userId={props.userId}
        // statusDemek={props.statusDemek}
        />
      );
    if (tab == 2) return <AttendanceScreen />;
    // if (tab == 3) return <ReportEmployeeScreen tab={tab} setTab={setTab} />;
  };

  const sendStatus = async (idUser) => {
    return await axiosInstance()
      .post(`/api/v1/rules/trabajadores/${idUser}/revision`)
      .then((response) => {
        // console.log(response.data);
        return true;
      })
      .catch((e) => {
        console.log(e);
        return false;
      });
  };

  const handleSendStatus = async () => {
    await sendStatus(localStorage.getItem("idEmployee"));
    setStatusDemek("En espera de aprobación");
    // setOpendialogStatus(false);
  };

  const exportReport = () => {
    ///console.log("EXCEL DE EMPLEADO:::", props.userId);
    ///const app = `/api/v1/rules/trabajadores/excel/${props.userId}`;

    return axiosInstance()
      .get(`/api/v1/rules/trabajadores/excel/${props.userId}`)
      .then((response) => {
        ///console.log(response.data);
        saveAs(response.data.data.url, response.data.data.fileName);
        return true;
      })
      .catch((e) => {
        console.log(e);
        enqueueSnackbar("Error al generar excel", {
          variant: "error",
        });
        return false;
      });
  };

  useEffect(() => {
    setStatusDemek(localStorage.getItem("statusDemek"));
  }, []);

  return (
    <div className={classes.divMain}>
      <Grid
        item
        xs={6}
        sm={4}
        md={2}
        lg={2}
        xl={2}
        container
        direction="row"
        alignItems="center"
        onClick={() => history.push(`/dashboard/empleados`)}
        className={classes.divBack}
      >
        <Grid item>
          <FontAwesomeIcon
            icon={faArrowAltCircleLeft}
            style={{
              width: "20px",
              height: "20px",
              marginRight: "10px",
              color: "#d6d6d6",
            }}
          />
        </Grid>
        <Grid item>
          <Typography className={classes.back}>Regresar</Typography>
        </Grid>
        <Grid item xs={6} sm={8} md={10} lg={10} xl={10}></Grid>
      </Grid>

      <Grid
        container
        direction="row"
        style={{
          marginTop: 20,
          marginBottom: 20,
        }}
      >
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <TopTabComponent tab={tab} setTab={setTab} />
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          lg={8}
          xl={8}
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          {/* <Button onClick={exportReport} className={classes.buttonSearch}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 18,
                textAlign: "center",
                color: "#fff",
              }}
            >
              Descargar Información
            </Typography>
          </Button> */}

          {(statusDemek === "Borrador" ||
            statusDemek === "Información rechazada") &&
            !user.Subcontractor[0].empresaPadre && (
              <Grid item xs={12} className={classes.gridMargin}>
                <Button
                  onClick={handleSendStatus}
                  style={{
                    width: 216,
                    height: 45,
                    backgroundColor: "#ac0bce",
                    borderRadius: 8,
                    marginTop: 10,
                    marginRight: 15,
                    textTransform: "none",
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 16,
                      textAlign: "center",
                      color: "#fff",
                    }}
                  >
                    Enviar a aprobación
                  </Typography>
                </Button>
              </Grid>
            )}

          {statusDemek !== "Borrador" && (
            <Grid
              item
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              style={{
                cursor: user?.Subcontractor[0]?.empresaPadre && "pointer",
                width: "auto",
                paddingLeft: 16,
                paddingRight: 16,
                height: 48,
                backgroundColor: "#2d3034",
                borderRadius: 16,
                marginLeft: 15,
              }}
              className={classes.gridMargin}
              onClick={user?.Subcontractor[0]?.empresaPadre && handleStatus}
            >
              <Grid item container direction="row">
                <Typography
                  style={{
                    display: "inline-block",
                    fontFamily: "Roboto",
                    fontSize: 16,
                    textAlign: "center",
                    color: "#fff",
                  }}
                >
                  {statusDemek ?? ""}
                </Typography>

                {user?.Subcontractor[0]?.empresaPadre && (
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    style={{
                      width: 28,
                      height: 18,
                      color: "#c9c9c9",
                      marginLeft: 8,
                      marginTop: 5,
                    }}
                  />
                )}
              </Grid>
            </Grid>
          )}

          <Grid className={classes.gridMargin}>
            <Button
              onClick={() =>
                history.push(
                  `/dashboard/empleados/historial/${localStorage.getItem(
                    "idEmployee"
                  )}`
                )
              }
              style={{
                backgroundColor: "#2d3034",
                width: 168,
                height: 48,
                borderRadius: 8,
                textTransform: "none",
                marginLeft: 15,
              }}
            >
              <FontAwesomeIcon
                icon={faHistory}
                style={{
                  width: 18,
                  height: 18,
                  color: "#fff",
                }}
              />
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 20,
                  textAlign: "left",
                  color: "#fff",
                  marginLeft: 12,
                }}
              >
                Historial
              </Typography>
            </Button>
          </Grid>

          {ValidatePermissions("eliminar") &&
            statusDemek != "Vetado" &&
            statusDemek != "Boletinado" && (
              <Grid className={classes.gridMargin}>
                <Button
                  aria-label="more"
                  id="long-button"
                  aria-controls="long-menu"
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                  style={{
                    backgroundColor: "#2d3034",
                    maxWidth: "48px",
                    maxHeight: "48px",
                    minWidth: "48px",
                    minHeight: "48px",
                    borderRadius: 8,
                    marginLeft: 15,
                  }}
                >
                  <FontAwesomeIcon
                    icon={faEllipsisV}
                    style={{
                      width: "20px",
                      height: "25px",
                      color: "#fff",
                    }}
                  />
                </Button>
                <Menu
                  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                  transformOrigin={{ vertical: "top", horizontal: "center" }}
                  id="long-menu"
                  getContentAnchorEl={null}
                  MenuListProps={{
                    "aria-labelledby": "long-button",
                  }}
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      backgroundColor: "#27292d",
                    },
                  }}
                >
                  <MenuItem
                    key={"Eliminar"}
                    style={{ color: "white" }}
                    onClick={handleDelete}
                  >
                    <DeleteIcon className={classes.icon} />
                    {"Eliminar empleado"}
                  </MenuItem>
                </Menu>
              </Grid>
            )}
        </Grid>
      </Grid>

      <DialogDeleteEmployee
        openDialog={openDialog}
        setOpendialog={setOpendialog}
      />

      <DialogStatus
        openDialog={openDialogStatus}
        setOpendialog={setOpendialogStatus}
        statusDemek={statusDemek}
        setStatusDemek={setStatusDemek}
      />
      {renderScreen()}
    </div>
  );
}
