/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import Grid from "@material-ui/core/Grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import svgTrabajador from "../../../../images/usuario_trabajador.svg";
import { faMedkit, faImage } from "@fortawesome/free-solid-svg-icons";
import PhotoEmployee from "./PhotoEmployee";
import { useDispatch, useSelector } from "react-redux";
import { SelectedDetailsWorkingTime } from "../../../../actions/workingTime";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "100%",
    height: "250px",
    borderRadius: "12px",
    backgroundColor: "#2d3034",
    cursor: "pointer",
    "&:hover": {
      background: "#3B3D40",
      boxShadow: "0 0px 16px 0 rgba(90, 90, 90, 0.2)",
    },
  },
  griCard: {
    padding: theme.spacing(2),
  },
  photo: {
    width: "70px",
    height: "70px",
    objectFit: "contain",
    borderRadius: "5px",
    aspectRatio: 1,
  },
  root: {
    width: "60%",
    borderRadius: "20px",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
  input: {
    display: "none",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
}));

export default function CardEnterprise(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const detailWorkingTime = () => {
    history.push(`/dashboard/reportes/tiempo-trabajo/${props.id}`);
    dispatch(
      SelectedDetailsWorkingTime({
        urlPhoto: props.urlPhoto,
        enterprise: props.enterprise,
        businessName: props.businessName,
        securityNumber: props.securityNumber,
        employees: props.employees,
        employeesPhoto: props.employeesPhoto,
        seconds: props.seconds,
      })
    );
  };

  const renderEmployeesPhoto = () => {
    const items = props.employeesPhoto.map((foto, index) => {
      if (index < 5) {
        return <PhotoEmployee key={index} employeesPhoto={foto} />;
      }
      if (index == 5) {
        return (
          <div
            key={index}
            style={{
              width: "18px",
              height: "18px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "4px",
              backgroundColor: "#919191",
            }}
          >
            <Typography
              style={{ fontFamily: "Roboto", fontSize: 12, color: "#fff" }}
            >
              +5
            </Typography>
          </div>
        );
      }
    });
    return items;
  };

  function secondsToHms(d) {
    d = Number(d);
    var hours = Math.floor(d / 3600);
    var minutes = Math.floor((d % 3600) / 60);
    var seconds = Math.floor((d % 3600) % 60);
    return {
      hours,
      minutes,
      seconds,
    };
  }

  return (
    <div onClick={detailWorkingTime}>
      <Card className={classes.card}>
        <Grid className={classes.griCard}>
          <Grid container direction="row" justifyContent="flex-start">
            <Grid item xs={3}>
              {props.urlPhoto == "" || props.urlPhoto == null ? (
                <FontAwesomeIcon
                  icon={faImage}
                  style={{
                    width: "52px",
                    height: "60px",
                    color: "#a5a5a5",
                    borderRadius: "5px",
                  }}
                />
              ) : (
                <img
                  src={props.urlPhoto}
                  className={classes.photo}
                  alt="photo"
                />
              )}
            </Grid>
            <Grid item xs={9}>
              <Grid container direction="column">
                {props.enterprise == "" || props.enterprise == null ? (
                  <Typography
                    style={{
                      fontSize: 16,
                      color: "#b2b2b2",
                      marginLeft: 15,
                    }}
                  >
                    --
                  </Typography>
                ) : (
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      textAlign: "left",
                      color: "#fff",
                      fontSize: 18,
                      fontWeight: 500,
                      marginLeft: 15,
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      maxWidth: "90%",
                    }}
                  >
                    {props.enterprise}
                  </Typography>
                )}

                {props.businessName == "" || props.businessName == null ? (
                  <Typography
                    style={{
                      fontSize: 16,
                      color: "#b2b2b2",
                      marginLeft: 15,
                    }}
                  >
                    --
                  </Typography>
                ) : (
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 16,
                      textAlign: "left",
                      color: "#b2b2b2",
                      marginLeft: 15,
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      maxWidth: "90%",
                    }}
                  >
                    {props.businessName}
                  </Typography>
                )}

                {props.securityNumber == "" || props.securityNumber == null ? (
                  <Typography
                    style={{
                      fontSize: 16,
                      color: "#b2b2b2",
                      marginLeft: 15,
                    }}
                  >
                    --
                  </Typography>
                ) : (
                  <Grid container direction="row">
                    <FontAwesomeIcon
                      icon={faMedkit}
                      style={{
                        width: "12px",
                        height: "12px",
                        color: "#fff",
                        marginTop: 4,
                        marginLeft: 15,
                        zIndex: 99,
                      }}
                    />

                    <div
                      style={{
                        background: "#d233ff",
                        position: "absolute",
                        width: "6px",
                        height: "6px",
                        marginTop: 8,
                        marginLeft: 18,
                      }}
                    ></div>

                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontSize: 10,
                        textAlign: "left",
                        color: "#fff",
                        marginTop: 2,
                        marginLeft: 4,
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        maxWidth: "50%",
                      }}
                    >
                      {props.securityNumber}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-end"
            style={{
              marginTop: 18,
              // backgroundColor: "blue",
            }}
          >
            {props.seconds == "" || props.seconds == null ? (
              <Typography
                style={{
                  fontSize: 30,
                  color: "white",
                  textAlign: "left",
                  marginRight: 10,
                  // backgroundColor: "red",
                }}
              >
                {"--"}
              </Typography>
            ) : (
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 30,
                  textAlign: "left",
                  color: "#fff",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  marginRight: 10,
                  // backgroundColor: "red",
                }}
              >
                {secondsToHms(props.seconds)?.hours}
              </Typography>
            )}

            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 12,
                textAlign: "left",
                color: "#ccc",
                marginRight: 10,
                // backgroundColor: "black",
              }}
            >
              Hrs
            </Typography>

            {props.seconds == "" || props.seconds == null ? (
              <Typography
                style={{
                  fontSize: 30,
                  color: "white",
                  marginRight: 10,
                }}
              >
                {"--"}
              </Typography>
            ) : (
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 30,
                  textAlign: "left",
                  color: "#fff",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  marginRight: 10,
                }}
              >
                {secondsToHms(props.seconds)?.minutes}
              </Typography>
            )}

            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 12,
                textAlign: "left",
                color: "#ccc",
              }}
            >
              Min
            </Typography>
          </Grid>

          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 12,
              textAlign: "left",
              color: "#ccc",
            }}
          >
            Total horas trabajadas
          </Typography>

          <Grid
            item
            xs={8}
            container
            direction="row"
            justifyContent="flex-start"
            style={{
              marginTop: 20,
              // backgroundColor: "green",
            }}
          >
            {renderEmployeesPhoto()}
          </Grid>

          <Grid container>
            <Grid item xs={1}>
              <img
                src={svgTrabajador}
                style={{
                  width: "12px",
                  height: "12px",
                  marginTop: 10,
                }}
                alt="techton logo"
              />
            </Grid>
            <Grid item xs>
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 14,
                  textAlign: "left",
                  color: "#fff",
                  marginTop: 6,
                }}
              >
                {props.employees == 0
                  ? "-- trabajadores"
                  : props.employees == 1
                  ? props.employees + " trabajador"
                  : props.employees + " trabajadores"}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
}
