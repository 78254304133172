/*eslint-disable*/
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import {
  Button,
  TextField,
  Grid,
  Typography,
  InputAdornment,
} from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleLeft,
  faKey,
  faBuilding,
  faMapMarkedAlt,
} from "@fortawesome/free-solid-svg-icons";
import { ipServer } from "../../../config/vars";
import axios from "axios";
import Typo from "../../../common_components/typo";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Roboto",
    fontSize: 32,
    fontWeight: "bold",
    color: theme.palette.secondary.dark,
    marginTop: "1%",
  },
  subtitle: {
    fontFamily: "Roboto",
    fontSize: 21,
    color: theme.palette.secondary.main,
    marginTop: "2%",
  },
  textFieldLabel: {
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: "bold",
    color: "#e2e2e2",
    marginTop: "5%",
    color: theme.palette.secondary.main,
  },
  textFieldCustom: {
    width: "100%",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& label.Mui-focused": {
      color: "#d233ff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "yellow",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#E6E6E6",
      },
      "&:hover fieldset": {
        borderColor: "#6C6C6C",
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.primary.main,
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "14px",
    },
  },
  button: {
    width: "100%",
    height: "56px",
    marginTop: "8%",
    marginBottom: "7%",
    borderRadius: "8px",
    backgroundColor: theme.palette.primary.main,
    textTransform: "none",
  },
  typoButton: {
    fontFamily: "Roboto",
    fontSize: 24,
    fontWeight: 500,
    color: "#fff",
  },
}));

export default function CreateCompany(props) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { register, handleSubmit, errors } = useForm();

  const backStep = () => {
    props.setStep(1);
  };

  const GetToken = () => {
    return localStorage.getItem("JWT");
  };

  const onSubmit = (inputLogin) => {
    const enterprise = inputLogin.enterprise;
    const address = inputLogin.address;
    const code = inputLogin.code;

    if (!errorTextField(enterprise, address, code)) return;

    axiosInstance()
      .post(`${ipServer}/api/v1/security/enterprise/register/`, {
        EnterpriseName: enterprise,
        LicenseId: code,
        Location: [
          {
            LocationName: enterprise,
            Address: address,
            // Latitude: "",
            // Longitude: "",
          },
        ],
      })
      .then((response) => {
        // console.log(response.data);
        history.push("/dashboard/");
        enqueueSnackbar("Empresa registrada correctamente", {
          variant: "success",
        });
      })
      .catch((e) => {
        console.log(e);
        // console.log(e.response.data);
        // setError(true);
        // setErrorMessage("Usuario no encontrado, revisa tu email y contraseña");
        enqueueSnackbar("Error al registrar Empresa", {
          variant: "error",
        });
      });
  };

  const errorTextField = (enterprise, address, code) => {
    if (enterprise == "" || address == "" || code == "") {
      enqueueSnackbar("Favor de ingresar los datos requeridos", {
        variant: "error",
      });
      return false;
    }
    return true;
  };

  return (
    <div>
      <div onClick={backStep}>
        <FontAwesomeIcon
          icon={faArrowAltCircleLeft}
          style={{
            width: "27px",
            height: "27px",
            marginBottom: "20px",
            color: "#9D9D9D",
            cursor: "pointer",
          }}
        />
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction="column" alignItems="center">
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            style={{
              width: "566px",
              // backgroundColor: "red",
            }}
          >
            <Typography className={classes.title}>Crear empresa</Typography>

            <Typography className={classes.subtitle}>
              Crea tu empresa para agregar a tus empleados y subcontratistas
            </Typography>

            <Typography className={classes.textFieldLabel}>
              Nombre de empresa
            </Typography>
            <TextField
              id="enterprise"
              variant="outlined"
              name="enterprise"
              inputRef={register({ required: false })}
              autoComplete="enterprise"
              // onChange={onChangeField}
              className={classes.textFieldCustom}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon
                      icon={faBuilding}
                      style={{
                        width: "24px",
                        height: "24px",
                        color: "#ADADAD",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />

            <Typography className={classes.textFieldLabel}>
              Dirección de empresa
            </Typography>
            <TextField
              id="address"
              variant="outlined"
              name="address"
              inputRef={register({ required: false })}
              autoComplete="address"
              // onChange={onChangeField}
              className={classes.textFieldCustom}
              inputProps={{
                maxLength: 80,
                // style: { textTransform: "capitalize" },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon
                      icon={faMapMarkedAlt}
                      style={{
                        width: "24px",
                        height: "24px",
                        color: "#ADADAD",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />

            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Typography
                className={classes.textFieldLabel}
                style={{ marginRight: 7 }}
              >
                Código de licencia de software
              </Typography>
              <HelpIcon
                style={{
                  width: "18px",
                  height: "18px",
                  color: "#acacac",
                  paddingTop: 25,
                }}
              />
            </Grid>
            <TextField
              id="code"
              // type="number"
              variant="outlined"
              name="code"
              inputRef={register({ required: false })}
              // autoComplete="address"
              // onChange={onChangeField}
              className={classes.textFieldCustom}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon
                      icon={faKey}
                      style={{
                        width: "24px",
                        height: "24px",
                        color: "#ADADAD",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />

            <Button
              type="submit"
              variant="contained"
              className={classes.button}
            >
              <Typography className={classes.typoButton}>
                Crear empresa
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
