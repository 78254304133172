/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { Grid, Typography, TextField, Button } from "@material-ui/core";
import axios from "axios";
import axiosInstance from "../../../utils/axiosInstance";
import BackdropComponent from "./Backdrop";
import { saveAs } from "file-saver";
import { ipServer } from "../../../config/vars";
import {
  DateFormatString,
  DateFormatString3,
} from "../../../utils/DateFormatString";
import readableBytes from "../../../utils/convertBytesToMb";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    height: "100%",
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 32,
    fontWeight: 500,
    textAlign: "left",
    color: theme.palette.secondary.dark,
  },
  root: {
    width: "100%",
    background: "white",
    borderRadius: "8px",
    "& .MuiInputBase-input": {
      color: "676767 !important",
    },
  },
}));

export default function VideoDetailScreen(props) {
  const classes = useStyles();
  const history = useHistory();
  const [percentage, setPercentage] = useState(0);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [video, setVideo] = useState({});
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const getVideoData = (id) => {
    const params = new URLSearchParams();
    // params.append("page", query.page - 1);
    // params.append("limit", query.pageSize);
    params.append("_id", id);

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    axiosInstance()
      .get("/api/v1/rules/videos", {
        params,
        cancelToken: source.token,
      })
      .then((response) => {
        // console.log(response.data.data);
        let result = response.data.data;

        result.map((video) => {
          //   console.log(video);
          video.id = video._id;
          video.dateString = DateFormatString(video.insert_datetime);
          video.size = readableBytes(video.size);
          video.dateWeek = DateFormatString3(video.insert_datetime);
        });

        downloadVideo(result);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const downloadVideo = (video) => {
    try {
      setOpenBackdrop(true);
      return axios
        .get(`${ipServer}/api/v1/rules/fs/files/${video[0].Key}`, {
          responseType: "blob",
          headers: {
            Authorization: `${localStorage.getItem("JWT")}`, // Reemplaza 'token' con tu valor de token válido
          },
          onDownloadProgress: (progressEvent) => {
            // console.log(progressEvent);
            const total = parseFloat(progressEvent.total);
            const current = progressEvent.loaded;
            let percentCompleted = Math.floor((current / total) * 100);
            // console.log("completed: ", percentCompleted);
            setPercentage(percentCompleted);
          },
        })
        .then((response) => {
          // console.log(response);
          // Let the user save the file.
          saveAs(response.data, video[0].dateWeek + "-" + video[0].Key);
          history.push(`/dashboard/videos`);
          setOpenBackdrop(false);
          setPercentage(0);
          enqueueSnackbar("Video descargado correctamente.", {
            variant: "success",
          });
        })
        .catch((response) => {
          console.error("Error al descargar video.", response);
          history.push(`/dashboard/videos`);
          setOpenBackdrop(false);
          setPercentage(0);
          enqueueSnackbar("Error al descargar video.", {
            variant: "error",
          });
        });
    } catch (error) {
      console.error("Error al descargar video.", error);
      history.push(`/dashboard/videos`);
      setOpenBackdrop(false);
      setPercentage(0);
      enqueueSnackbar("Error al descargar video.", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    // console.log(window.location.href.split("/").pop());
    getVideoData(window.location.href.split("/").pop());
  }, []);

  return (
    <div className={classes.divMain}>
      <Grid container>
        {/* <Typography className={classes.title}>Videos</Typography> */}
      </Grid>

      <BackdropComponent
        percentage={percentage}
        openBackdrop={openBackdrop}
        setOpenBackdrop={setOpenBackdrop}
      />
    </div>
  );
}
