import axios from "axios";
import { ipServer } from "../config/vars";

export default () => {
  const baseURL = ipServer;
  const token = localStorage.getItem("JWT") || null;
  const expToken = localStorage.getItem("token-exp") || null;
  let headers = {};

  const handleExit = () => {
    // localStorage.clear();
    localStorage.removeItem("JWT");
    localStorage.removeItem("idEmployee");
    localStorage.removeItem("statusDemek");
    localStorage.removeItem("token-exp");
    localStorage.removeItem("idSubcontractor");

    setTimeout(() => {
      window.location.reload();
    }, 100);
  };

  if (token && new Date().getTime() < expToken) {
    headers = { Authorization: token };
  } else {
    handleExit();
  }

  const axiosInstance = axios.create({
    baseURL: baseURL,
    headers,
  });

  axiosInstance.interceptors.response.use(
    (response) =>
      new Promise((resolve, reject) => {
        resolve(response);
      }),
    (error) => {
      if (!error.response) {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }

      if (error.response.status === 401) {
        handleExit();
      } else {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
    }
  );

  return axiosInstance;
};
