/*eslint-disable*/
import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  Fragment,
} from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Button,
  Box,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleLeft,
  faImage,
  faCheckCircle,
  faFileExcel,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
import CardHistory from "./components/CardHistory";
import useHistorySerach from "./components/useHistory";
import JsPdf from "jspdf";
import html2canvas from "html2canvas";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import svgMedkit from "../../images/icono_info_medica.svg";
import LinearProgress from "@material-ui/core/LinearProgress";
import svgTrabajador from "../../images/usuario_trabajador.svg";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    paddingLeft: "30px",
  },
  divBack: {
    cursor: "pointer",
    marginBottom: "20px",
  },
  divPhoto: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "60px",
    height: "60px",
    borderRadius: "8px",
    backgroundColor: "#828282",
  },
  back: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 500,
    textAlign: "left",
    color: "#ffffff",
  },
  noWrap: {
    textOverflow: "ellipsis",
    wordWrap: "anywhere",
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
  },
  root: {
    // width: "60%",
    borderRadius: 20,
  },
  boxProgress: {
    "& .MuiLinearProgress-colorPrimary": {
      backgroundColor: "#6d6d6d",
      height: 10,
      borderRadius: 20,
    },
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#d233ff",
      height: 10,
      borderRadius: 20,
    },
  },
}));

function LinearProgressWithLabel(props) {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1} className={classes.boxProgress}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      {/* <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box> */}
    </Box>
  );
}

export default function HistorySubcontractorScreen(props) {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useSelector((state) => state.user);
  const [pageNumber, setPageNumber] = useState(0);
  const [limit, setLimit] = useState(8);
  const [loadingExportPDF, setLoadingExportPDF] = useState(false);
  const [loadingExportExcel, setLoadingExportExcel] = useState(false);
  const [dataCSV, setDataCSV] = useState([]);
  const { contractorSelected } = useSelector((status) => status.contractor);

  const { books, hasMore, loading, error, total } = useHistorySerach(
    limit,
    pageNumber
  );

  const ITEM_HEIGHT = 48;
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const ref = React.createRef();
  const csvLink = useRef();

  useEffect(() => {
    async function PDFexport() {
      if (loadingExportPDF && ref.current.childNodes.length > total) {
        setLoadingExportPDF(false);

        const source = ref || this.targetRef;
        const targetComponent = source.current || source;

        html2canvas(targetComponent, {
          logging: false,
          useCORS: true,
          scale: 1.1,
        })
          .then((canvas) => {
            var pdf = new JsPdf("p", "pt", "letter");

            for (var i = 0; i <= targetComponent.clientHeight / 980; i++) {
              var srcImg = canvas;
              var sX = 0;
              var sY = 980 * i;
              var sWidth = 900;
              var sHeight = 980;
              var dX = 0;
              var dY = 0;
              var dWidth = 900;
              var dHeight = 980;

              window.onePageCanvas = document.createElement("canvas");
              onePageCanvas.setAttribute("width", 900);
              onePageCanvas.setAttribute("height", 980);
              var ctx = onePageCanvas.getContext("2d");

              ctx.drawImage(
                srcImg,
                sX,
                sY,
                sWidth,
                sHeight,
                dX,
                dY,
                dWidth,
                dHeight
              );

              var canvasDataURL = onePageCanvas.toDataURL("image/png");
              var width = onePageCanvas.width;
              var height = onePageCanvas.clientHeight;

              if (i > 0) {
                pdf.addPage();
              }
              pdf.setPage(i + 1);
              pdf.addImage(
                canvasDataURL,
                "PNG",
                40,
                40,
                width * 0.67,
                height * 0.7
              );
            }
            pdf.save("Test.pdf");
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            }

            console.log(error);
          });
      }
    }

    PDFexport();
  }, [loadingExportPDF, ref]);

  useEffect(() => {
    if (loadingExportExcel && books.length >= total) {
      setLoadingExportExcel(false);
      let result = books.map((data, index) => ({
        Acción: data.Action,
        Usuario: data.User.Name,
        Email: data.User.Email,
        Fecha: data.Date,
      }));

      setDataCSV(result);

      setTimeout(() => {
        csvLink.current.link.click();
      }, 300);
    }
  }, [loadingExportExcel, books]);

  const handleCSV = async () => {
    handleClose();
    setLimit(0);
    setLoadingExportExcel(true);
  };

  const handleExportPDF = () => {
    handleClose();
    setLimit(0);
    setLoadingExportPDF(true);
  };

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const renderHistory = () => {
    const listRender = books.map((history, index) => (
      <Grid
        item
        ref={lastBookElementRef}
        key={index}
        xs={12}
        style={{ marginBottom: 16 }}
      >
        <CardHistory data={history} />
      </Grid>
    ));

    return listRender;
  };

  const backStep = () => {
    if (
      user?.RoleName == "Administrador Subcontratista" ||
      contractorSelected?.empresaPadre
    ) {
      history.push(
        `/dashboard/miempresa/${localStorage.getItem("idSubcontractor")}`
      );
    } else
      history.push(
        `/dashboard/contratistas/${localStorage.getItem("idSubcontractor")}`
      );
  };

  return (
    <Fragment>
      <div className={classes.divMain}>
        <Grid
          container
          direction="row"
          alignItems="center"
          onClick={backStep}
          className={classes.divBack}
        >
          <FontAwesomeIcon
            icon={faArrowAltCircleLeft}
            style={{
              width: "20px",
              height: "20px",
              marginRight: "10px",
              color: "#d6d6d6",
            }}
          />
          <Typography className={classes.back}>Regresar</Typography>
        </Grid>

        <Grid
          container
          direction="column"
          style={{
            width: 720,
            margin: "auto",
            marginBottom: 10,
          }}
        >
          <Grid container direction="row" justifyContent="flex-end">
            <Button
              onClick={handleClick}
              variant="contained"
              style={{
                width: 137,
                height: 32,
                borderRadius: 8,
                backgroundColor: "#d233ff",
                textTransform: "none",
              }}
            >
              <FileDownloadIcon style={{ color: "white" }} />
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 16,
                  textAlign: "left",
                  color: "#fff",
                  marginLeft: 4,
                }}
              >
                Exportar
              </Typography>
            </Button>
          </Grid>
        </Grid>

        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          open={openMenu}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: 137,
              backgroundColor: "#212429",
              borderRadius: 4,
            },
          }}
        >
          <MenuItem
            key={"DescargarPDF"}
            style={{ color: "white" }}
            onClick={handleExportPDF}
          >
            <FontAwesomeIcon
              icon={faFilePdf}
              style={{
                width: 12,
                height: 16,
                color: "#fff",
              }}
            />

            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 14,
                textAlign: "left",
                color: "#fff",
                marginLeft: 8,
                fontWeight: 500,
              }}
            >
              Archivo .PDF
            </Typography>
          </MenuItem>

          <MenuItem
            key={"Descargar"}
            style={{ color: "white" }}
            onClick={handleCSV}
          >
            <FontAwesomeIcon
              icon={faFileExcel}
              style={{
                width: 12,
                height: 16,
                color: "#fff",
              }}
            />

            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 14,
                textAlign: "left",
                color: "#fff",
                marginLeft: 8,
                fontWeight: 500,
              }}
            >
              Archivo .XCL
            </Typography>
          </MenuItem>
        </Menu>
        <CSVLink
          data={dataCSV}
          filename="data.csv"
          className="hidden"
          ref={csvLink}
          target="_blank"
        />
        <Grid
          ref={ref}
          container
          direction="column"
          style={{
            width: 720,
            minHeight: "82vh",
            margin: "auto",
          }}
        >
          <Grid item>
            <Grid
              container
              direction="row"
              alignItems="center"
              style={{
                backgroundColor: "#2d3034",
                borderRadius: 8,
                padding: 20,
              }}
            >
              <Grid item xs={2}>
                {contractorSelected?.foto ? (
                  <img
                    src={contractorSelected?.foto?.url}
                    style={{
                      width: 70,
                      height: 70,
                      borderRadius: 8,
                      aspectRatio: 1,
                      objectFit: "contain",
                    }}
                    alt="photo"
                  />
                ) : (
                  <div className={classes.divPhoto}>
                    <FontAwesomeIcon
                      icon={faImage}
                      style={{
                        width: "36px",
                        height: "28px",
                        color: "#2d3034",
                        borderRadius: "8px",
                      }}
                    />
                  </div>
                )}
              </Grid>

              <Grid
                item
                xs={4}
                container
                direction="column"
                style={{ marginLeft: -15 }}
              >
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 18,
                    textAlign: "left",
                    color: "#fff",
                    fontWeight: 500,
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    maxWidth: "75%",
                  }}
                >
                  {contractorSelected?.empresa}
                </Typography>

                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 16,
                    textAlign: "left",
                    color: "#b2b2b2",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    maxWidth: "75%",
                  }}
                >
                  {contractorSelected?.razonSocial
                    ? contractorSelected?.razonSocial
                    : "--"}
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 14,
                    textAlign: "left",
                    color: "#b2b2b2",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    maxWidth: "75%",
                  }}
                >
                  {contractorSelected?.unidad
                    ? contractorSelected?.unidad
                    : "--"}
                </Typography>
              </Grid>

              <Grid
                item
                xs={3}
                container
                direction="column"
                style={{ marginLeft: -50 }}
              >
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 16,
                    textAlign: "left",
                    color: "#b2b2b2",
                  }}
                >
                  {contractorSelected?.rfc ? contractorSelected?.rfc : "--"}
                </Typography>

                <Grid container direction="row">
                  <img
                    src={svgMedkit}
                    style={{
                      width: 13,
                      height: 13,
                      marginTop: 2,
                    }}
                    alt="techton logo"
                  />
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 14,
                      textAlign: "left",
                      color: "#fff",
                      marginLeft: 6,
                    }}
                  >
                    {contractorSelected?.numeroSeguro
                      ? contractorSelected?.numeroSeguro
                      : "--"}
                  </Typography>
                </Grid>

                <Grid container direction="row" style={{ marginTop: 4 }}>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{
                      width: 14,
                      height: 14,
                      color: "#d233ff",
                      marginTop: 2,
                    }}
                  />
                  <Typography
                    className={classes.noWrap}
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 14,
                      textAlign: "left",
                      color: "#fff",
                      marginLeft: 6,
                    }}
                  >
                    Empresa aprobada
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                xs={3}
                container
                direction="column"
                style={{ marginLeft: 20 }}
              >
                <div className={classes.root}>
                  <LinearProgressWithLabel
                    value={
                      contractorSelected?.percentage
                        ? contractorSelected?.percentage?.total
                        : 0
                    }
                  />
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 12,
                      textAlign: "left",
                      color: "#acacac",
                      marginTop: 5,
                    }}
                  >
                    {contractorSelected?.percentage
                      ? contractorSelected?.percentage?.total
                      : 0}
                    % completado
                  </Typography>
                </div>

                <Grid container direction="row" style={{ marginTop: 8 }}>
                  <img
                    src={svgTrabajador}
                    style={{
                      width: 13,
                      height: 13,
                      marginTop: 2,
                    }}
                    alt="techton logo"
                  />
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 14,
                      textAlign: "left",
                      color: "#fff",
                      marginLeft: 6,
                    }}
                  >
                    {contractorSelected?.trabajadores_id?.length
                      ? contractorSelected?.trabajadores_id?.length
                      : 0}{" "}
                    trabajadores
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item style={{ marginTop: 30 }}>
            {/* agregar boton de exportar */}
            <Grid container direction="row">
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 32,
                  textAlign: "left",
                  color: loadingExportPDF ? "black" : "#fff",
                  fontWeight: 500,
                }}
              >
                Historial
              </Typography>
            </Grid>
          </Grid>

          {books?.length > 0 && renderHistory()}
        </Grid>
      </div>
    </Fragment>
  );
}
