/*eslint-disable*/
import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import axiosInstance from "../../../utils/axiosInstance";

const GetToken = () => {
  return localStorage.getItem("JWT");
};

export default function useBookSearch(query, pageNumber) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [books, setBooks] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    setBooks([]);
  }, [query]);

  useEffect(() => {
    if (user) {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      var params = new URLSearchParams();
      params.append("limit", 1000);
      params.append("page", 0);
      params.append("proyecto_partial", query.ObraName);

      const obrasFilter = user?.Obras_id?.filter((o1) =>
        query?.Obras?.some((o2) => o1 === o2)
      );

      if (query?.Obras?.length > 0) {
        obrasFilter?.map((obra) => {
          params.append("_id", obra);
        });
      } else {
        user?.Obras_id?.map((obra) => {
          params.append("_id", obra);
        });
      }

      setLoading(true);
      setError(false);

      axiosInstance()
        .get("/api/v1/rules/proyectos/filter", {
          params,
          cancelToken: source.token,
        })
        .then((res) => {
          let result = res.data.data.Results;
          setBooks(result);
          setLoading(false);
        })
        .catch((e) => {
          if (axios.isCancel(e)) return;
          setError(true);
        });
      return () => source.cancel();
    }
  }, [query, pageNumber, user]);

  return { loading, error, books, hasMore };
}
