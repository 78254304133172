/*eslint-disable*/
import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Box } from "@material-ui/core";
import UploadFileComponent from "./uploadFileComponent";
import AddNewFileButton from "../../RegisterEmployeeScreen/components/addNewFileButton";
import { useSelector } from "react-redux";
// import DialogLoadingUploadDocuments from "../../RegisterEmployeeScreen/components/DialogLoadingUploadDocuments";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
  },
  divFile: {
    width: "200px",
    height: "200px",
    borderRadius: "8px",
    backgroundColor: "#2d3034",
  },
  icon: {
    width: "40px",
    height: "40px",
    color: "white",
    marginBottom: "20px",
  },
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: "bold",
    textAlign: "left",
    color: "#cdcece",
  },
}));

export default function InfoFiles(props) {
  const classes = useStyles();
  const { files } = useSelector((state) => state.file);
  const predefinedDocuments = [
    "Acta constitutiva y/o poder de Representante legal",
    "Identificación Oficial",
    "Último estado de cuenta bancaria",
    "Último pago de cuotas obrero-patronales IMSS",
    "CVU",
    "Referencias de clientes",
    "Equipo y herramienta de subcontratista",
  ];

  const filesFilter = [
    ...new Map(files.map((item) => [item["name"], item])).values(),
  ];

  function difference(a1, a2) {
    return a1.filter((o1) => a2.filter((o2) => o2 === o1.name).length === 0);
  }

  return (
    <Fragment>
      <div className={classes.divMain}>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          style={{
            paddingRight: 38,
            marginBottom: 25,
          }}
        >
          <AddNewFileButton />
        </Grid>
          <Grid
            container
            spacing={4}
            style={{ paddingRight: 38, marginBottom: 20 }}
          >
            {predefinedDocuments.map((predefined, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={index}>
                <UploadFileComponent tag={predefined}/>
              </Grid>
            ))}

            {files && (
              difference(filesFilter, predefinedDocuments).map((document, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  xl={3}
                  key={index + predefinedDocuments.length}
                >
                  <UploadFileComponent
                    tag={document.name}
                  />
                </Grid>
              ))
            ) }
          </Grid>
      </div>

      {/* <DialogLoadingUploadDocuments type="subcontratistas" /> */}
    </Fragment>
  );
}
