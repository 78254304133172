/*eslint-disable*/
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  divMain: {
    width: "100%",
    height: "auto",
    borderRadius: 12,
    backgroundColor: "#2d3034",
    padding: theme.spacing(2),
  },
  typoLabelTitle: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: 1.31,
    textAlign: "left",
    color: "#fff",
  },
  typoLabelSubtitle: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: 1.36,
    textAlign: "left",
    color: "#aeaeae",
  },
  typoLabelQuantity: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: 1.36,
    textAlign: "left",
    color: "#aeaeae",
  },
}));

export default function CardProduct(props) {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      className={classes.divMain}
    >
      <Grid item xs={8} container direction="column">
        <Typography className={classes.typoLabelTitle}>
          {props.articulo}
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Typography className={classes.typoLabelTitle}>Talla</Typography>
          <Typography
            className={classes.typoLabelTitle}
            style={{
              marginLeft: 5,
            }}
          >
            {props.talla}
          </Typography>
        </Grid>
        <Typography className={classes.typoLabelTitle}>
          {props.color}
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Typography className={classes.typoLabelTitle}>
            Elemento PEP
          </Typography>
          <Typography className={classes.typoLabelSubtitle}>
            {props.elementoPEP.toUpperCase()}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        xs={4}
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography
          className={classes.typoLabelQuantity}
          style={{
            marginBottom: "10px",
          }}
        >
          cantidad
        </Typography>
        <Typography className={classes.typoLabelQuantity}>
          {`${props.cantidad}` +
            " " +
            (props.cantidad > 1 ? "piezas" : "pieza")}
        </Typography>
      </Grid>
    </Grid>
  );
}
