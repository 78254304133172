/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import CardStockist from "./cardStockist";
import CardProduct from "./cardProduct";
import axiosInstance from "../../../../utils/axiosInstance";
import useExitPassSearch from "./useExitPassSearch";
import { DateFormatString } from "../../../../utils/DateFormatString";
import DialogSignatureExitPass from "./DialogSignatureExitPass";
import DialogPhotoExitPass from "./DialogPhotoExitPass";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flex: 1,
    // backgroundColor: "#171a1e",
  },
  gridSigns: {
    height: "100%",
    [theme.breakpoints.down("md")]: {
      marginTop: 10,
      paddingLeft: 0,
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 40,
    },
  },
  gridSigns2: {
    height: "100%",
    [theme.breakpoints.down("lg")]: {
      marginTop: 10,
    },
  },
  divBack: {
    cursor: "pointer",
    marginBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
  back: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 500,
    textAlign: "left",
    color: "#ffffff",
  },
  photo: {
    width: "120px",
    height: "120px",
    aspectRatio: 1,
    objectFit: "cover",
    borderRadius: 12,
    marginTop: "10px",
    marginBottom: "32px",
  },
  divRectangle: {
    width: "375px",
    height: "151px",
    border: "solid 2px #707070",
    borderStyle: "dashed",
  },
  divLine: {
    height: "145px",
    border: "solid 1px #707070",
    borderStyle: "dashed",
    borderRightWidth: "2px",
  },
  photoFirm: {
    width: "134px",
    height: "88px",
    aspectRatio: 1,
    objectFit: "cover",
    borderRadius: 2,
    marginTop: "20px",
  },
  photoSupplier: {
    width: "80px",
    height: "80px",
    aspectRatio: 1,
    objectFit: "cover",
    borderRadius: 12,
    marginTop: "20px",
  },
}));

const tam = ["", "", "", "", "", "", "", "", "", "", "", ""];

export default function ExitPassSpecificScreen(props) {
  const classes = useStyles();
  const history = useHistory();
  const [pageNumber, setPageNumber] = useState(0);
  const [query, setQuery] = useState({
    Folio: 1,
    pageSize: 10,
    rowsPerPageOptions: [5, 10, 15],
    page: 1,
    intialDate: new Date(Date.now() - 604800000),
    finalDate: new Date(),
  });
  const [dataCards, setDataCards] = useState({
    ordenCompra: "",
    provedor: "",
    articulos: "",
    almacenista: "",
    entregadoX: "",
    estatus: "",
    caducidad: "",
    fecha: "",
  });
  const [articulos, setArticulos] = useState([]);
  const [evidenciaFotografica, serEvidenciaFotografica] = useState([]);
  const [openDialogSignatureExitPass, setOpenDialogSignatureExitPass] =
    useState(false);
  const [openDialogPhotoExitPass, setOpenDialogPhotoExitPass] = useState(false);

  const [dataDialog, setDataDialog] = useState({
    photoURL: "",
    name: "",
    email: "",
    date: "",
  });

  const handleOpenDialog = {
    provedor: {
      firma: () => {
        console.log("firma provedor");
        setOpenDialogSignatureExitPass(!openDialogSignatureExitPass);
        setDataDialog({
          ...dataDialog,
          photoURL: imgs.firm[1],
          name: "Juan Carlos DLP",
          email: "",
          date: "21/04/2022 17:30",
        });
      },
      foto: () => {
        console.log("foto provedor");
        setOpenDialogPhotoExitPass(!openDialogPhotoExitPass);
        setDataDialog({
          ...dataDialog,
          photoURL: imgs.person[1],
          name: "Juan Carlos DLP",
          email: "juan.delapaz@knesysplus.com",
          date: "21/04/2022 17:30",
        });
      },
    },
    almacenista: {
      firma: () => {
        console.log("firma almacenista");
        setOpenDialogSignatureExitPass(!openDialogSignatureExitPass);
        setDataDialog({
          ...dataDialog,
          photoURL: imgs.firm[2],
          name: "Daniel Lopez Ramirez",
          email: "",
          date: "21/04/2022 17:30",
        });
      },
      foto: () => {
        console.log("foto almacenista");
        setOpenDialogPhotoExitPass(!openDialogPhotoExitPass);
        setDataDialog({
          ...dataDialog,
          photoURL: imgs.person[2],
          name: "Daniel Lopez Ramirez",
          email: "daniel.lopez@knesysplus.com",
          date: "21/04/2022 17:30",
        });
      },
    },
  };

  const url =
    "https://ichef.bbci.co.uk/news/640/amz/worldservice/live/assets/images/2015/04/17/150417194023_einstein-lengua.jpg";

  const imgs = {
    person: {
      1: "https://upload.wikimedia.org/wikipedia/commons/8/85/Elon_Musk_Royal_Society_%28crop1%29.jpg",
      2: "https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/facebook-el-reinado-de-mark-zuckerberg-en-peligro-1539971586.jpg?resize=480:*",
    },
    firm: {
      1: "https://i.pinimg.com/736x/06/6d/fc/066dfc3d307b8fc00f1b1f756cc91bcb.jpg",
      2: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCm4Zci_Dad5rQ2d6texkf9viJhCY9n7lU__NzVOxQxlcGY1FDM51JzsnP2_joKrSP_oc&usqp=CAU",
    },
  };

  useEffect(() => {
    ///console.log(window.location.href.split("/").pop());
    const id = window.location.href.split("/").pop();
    axiosInstance()
      .get(`/api/v1/rules/pase-salida/${id}`)
      .then((response) => {
        let result = response.data.data[0];
        // console.log(result);
        // if (result.documentos) {
        //   console.log("docuementos: ", result.documentos);
        //   serEvidenciaFotografica(result.documentos);
        // }
        // setArticulos(result.articulos);
        // setDataCards({
        //   ...dataCards,
        //   fecha: DateFormatString(result?.datetime),
        //   ordenCompra: result.folioPaseSalida,
        //   provedor: "",
        //   articulos: result.articulos.length,
        //   almacenista: "",
        //   entregadoX: "",
        //   estatus: result?.status,
        //   caducidad: DateFormatString(result?.caducidad),
        // });
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  /**useEffect(() => {
    setPageNumber(0);
  }, [query]); */

  /** const { rows, totalRows, loading, error } = useExitPassSearch(
    query,
    pageNumber
  );
  useEffect(() => {
    console.log("rows::::: ", rows);
    console.log("query:::", query);
    const id = window.location.href.split("/").pop();
    if (rows.length > 0) {
      rows.map((item) => {
        if (item._id == id) {
          console.log(item);
          setDataCards({
            ...dataCards,
            fecha: DateFormatString(item?.datetime),
            ordenCompra: item?.folioPaseSalida,
            provedor: "",
            articulos: item?.articulos?.length,
            almacenista: "",
            entregadoX: "",
            estatus: item?.status,
            ///estatus: "Recolectado",
            caducidad: DateFormatString(item.caducidad),
          });
        }
      });
    }
  }, [totalRows]); */

  return (
    <div className={classes.divMain}>
      <Grid
        item
        xs={6}
        sm={4}
        md={2}
        lg={2}
        xl={2}
        container
        direction="row"
        alignItems="center"
        onClick={() => history.push(`/dashboard/almacen/pase-salida`)}
        className={classes.divBack}
      >
        <Grid item>
          <FontAwesomeIcon
            icon={faArrowAltCircleLeft}
            style={{
              width: "20px",
              height: "20px",
              marginRight: "10px",
              color: "#d6d6d6",
            }}
          />
        </Grid>
        <Grid item>
          <Typography className={classes.back}>Regresar</Typography>
        </Grid>
      </Grid>

      <Grid container direction="column">
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
            <CardStockist
              date={"02 de Febrero"}
              hour={"17:20"}
              ordenCompra={"#123242323"}
              provedor={"Jose Ruperto Arguelles Angel"}
              articulos={6}
              almacenista={"Javier Medina"}
              entregadoX={"Alberto Martinez"}
              estatus={"Entregado"}
              // caducidad={dataCards.caducidad}
              // evidencia={url}
              numeroEvidencia={"6"}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={7}
            lg={4}
            xl={3}
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            className={classes.gridSigns}
          >
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: "18px",
                fontWeight: 500,
                lineHeight: 1.33,
                textAlign: "left",
                color: "#fff",
              }}
            >
              Firma de receptor
            </Typography>
            <Grid container direction="row" className={classes.divRectangle}>
              <Grid
                item
                xs={6}
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                style={{
                  width: "175px",
                  height: "141px",
                  /* backgroundColor: "#FF8000", */
                }}
                onClick={handleOpenDialog.provedor.firma}
              >
                <img
                  src={imgs.firm[1]}
                  className={classes.photoFirm}
                  alt="photo"
                />
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "12px",
                    fontWeight: 500,
                    lineHeight: 1.33,
                    textAlign: "center",
                    color: "#fff",
                    marginTop: "6px",
                  }}
                >
                  Firma de receptor
                </Typography>
              </Grid>
              <Grid className={classes.divLine} />
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                style={{
                  width: "175px",
                  height: "141px",
                  /* backgroundColor: "#FF8000", */
                }}
                onClick={handleOpenDialog.provedor.foto}
              >
                <img
                  src={imgs.person[1]}
                  className={classes.photoSupplier}
                  alt="photo"
                />
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "12px",
                    fontWeight: 500,
                    lineHeight: 1.33,
                    textAlign: "center",
                    color: "#fff",
                    marginTop: "6px",
                  }}
                >
                  Foto de receptor (opcional)
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={4}
            xl={3}
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            className={classes.gridSigns2}
          >
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: "18px",
                fontWeight: 500,
                lineHeight: 1.33,
                color: "#fff",
              }}
            >
              Firma del almacenista
            </Typography>
            <Grid container direction="row" className={classes.divRectangle}>
              <Grid
                item
                xs={6}
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                style={{
                  width: "175px",
                  height: "141px",
                  /* backgroundColor: "#FF8000", */
                }}
                onClick={handleOpenDialog.almacenista.firma}
              >
                <img
                  src={imgs.firm[2]}
                  className={classes.photoFirm}
                  alt="photo"
                />
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "12px",
                    fontWeight: 500,
                    lineHeight: 1.33,
                    textAlign: "center",
                    color: "#fff",
                    marginTop: "6px",
                  }}
                >
                  Firma de almacenista
                </Typography>
              </Grid>
              <Grid className={classes.divLine} />
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                style={{
                  width: "175px",
                  height: "141px",
                  /* backgroundColor: "#FF8000", */
                }}
                onClick={handleOpenDialog.almacenista.foto}
              >
                <img
                  src={imgs.person[2]}
                  className={classes.photoSupplier}
                  alt="photo"
                />
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: "12px",
                    fontWeight: 500,
                    lineHeight: 1.33,
                    textAlign: "center",
                    color: "#fff",
                    marginTop: "6px",
                  }}
                >
                  Foto de almacenista
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid style={{ marginTop: 20, marginBottom: 10 }}>
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: "20px",
              fontWeight: 500,
              lineHeight: 1.3,
              textAlign: "left",
              color: "#fff",
            }}
          >
            Articulos
          </Typography>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid
            item
            xs={12}
            spacing={3}
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            {tam.map((it, index) => {
              return (
                <Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={3}>
                  <CardProduct
                    articulo={"Chaleco de Seguridad"}
                    talla={"M"}
                    color={"Verde"}
                    elementoPEP={"MI02939201920"}
                    cantidad={12}
                  />
                </Grid>
              );
            })}
          </Grid>

          {/*<Grid
            item
            xs={12} //pantalla chica +110%
            sm={12}
            md={12} //110%
            lg={2}
            xl={2} //pantalla muy grande -110%
            container
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            style={{
              width: "20%",
              alignItems: "left",
            }}
          >
             <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: "20px",
                fontWeight: 500,
                lineHeight: 1.3,
                textAlign: "left",
                color: "#fff",
              }}
            >
              Evidencia fotográfica
            </Typography> 
            {evidenciaFotografica.map((item, index) => {
              return (
                <img
                  src={item.file}
                  className={classes.photo}
                  alt="photo"
                  key={index}
                />
              );
            })}
          </Grid>*/}
        </Grid>
      </Grid>

      <DialogSignatureExitPass
        openDialogSignatureExitPass={openDialogSignatureExitPass}
        setOpenDialogSignatureExitPass={setOpenDialogSignatureExitPass}
        dataDialog={dataDialog}
        setDataDialog={setDataDialog}
      />
      <DialogPhotoExitPass
        openDialogPhotoExitPass={openDialogPhotoExitPass}
        setOpenDialogPhotoExitPass={setOpenDialogPhotoExitPass}
        dataDialog={dataDialog}
        setDataDialog={setDataDialog}
      />
    </div>
  );
}
