/*eslint-disable*/
import { useSnackbar } from "notistack";
import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Typography, Button, Box } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faBuilding,
  faFolderOpen,
  faEllipsisV,
} from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    [theme.breakpoints.down("md")]: {
      marginTop: 20,
    },
  },
  textInfo: {
    fontFamily: "Roboto",
    fontSize: 20,
    fontWeight: 500,
    textAlign: "left",
    color: "#ffffff",
    cursor: "pointer",
    marginBottom: 50,
  },
  textInfoSub: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 300,
    textAlign: "left",
    color: "#bebebe",
    marginBottom: "28px",
  },
  divIconActive: {
    width: "40px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50px",
    backgroundColor: "#d233ff",
    // marginBottom: "40px",
    cursor: "pointer",
  },
  divIconInactive: {
    width: "40px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50px",
    backgroundColor: "#a2a2a2",
    // marginBottom: "40px",
    cursor: "pointer",
  },
  icon: {
    width: "20px",
    height: "25px",
    color: "#fff",
    cursor: "pointer",
    marginRight: 10,
  },
  buttonAdd: {
    width: "60%",
    height: "40px",
    borderRadius: 8,
    border: "solid 2px #707070",
    borderStyle: "dashed",
    cursor: "pointer",
    textTransform: "none",
    [theme.breakpoints.down("md")]: {
      marginBottom: 30,
    },
  },
}));

export default function FlowComponent(props) {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const setScreenBasic = () => {
    props.setScreen(1);
  };

  const setScreenFile = () => {
    // if (props.screen == 1 && props.mandatoryFlag == false) {
    //   enqueueSnackbar("Favor de llenar campos obligatorios", {
    //     variant: "error",
    //   });
    //   return;
    // }
    props.setScreen(2);
  };

  return (
    <Grid container className={classes.divMain}>
      <Grid
        item
        lg={12}
        xl={12}
        style={{
          height: "100px",
        }}
      ></Grid>

      <Grid item xs={9}>
        <Grid container direction="row" justifyContent="flex-start">
          <FontAwesomeIcon icon={faEllipsisV} className={classes.icon} />
          <Typography onClick={setScreenBasic} className={classes.textInfo}>
            Perfil empresa
          </Typography>
        </Grid>

        <Grid container direction="row" justifyContent="flex-start">
          <FontAwesomeIcon icon={faEllipsisV} className={classes.icon} />
          <Typography onClick={setScreenFile} className={classes.textInfo}>
            Expediente archivos
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={3} container direction="column" alignItems="center">
        <div
          onClick={setScreenBasic}
          className={
            props.screen === 1 ? classes.divIconActive : classes.divIconInactive
          }
        >
          <FontAwesomeIcon
            icon={faBuilding}
            style={{
              width: "18px",
              height: "18px",
              color: "#fff",
            }}
          />
        </div>
        <Grid container direction="row" justifyContent="center">
          <div
            style={{
              width: "1px",
              height: "40px",
              backgroundColor: "#a2a2a2",
            }}
          />
        </Grid>

        <div
          onClick={setScreenFile}
          className={
            props.screen === 3 ? classes.divIconActive : classes.divIconInactive
          }
        >
          <FontAwesomeIcon
            icon={faFolderOpen}
            style={{
              width: "18px",
              height: "18px",
              color: "#fff",
            }}
          />
        </div>
      </Grid>

      <Grid container direction="row" justifyContent="center">
        <Button
          className={classes.buttonAdd}
          onClick={() =>
            props.setOpenDialogNewSection(!props.openDialogNewSection)
          }
        >
          <FontAwesomeIcon
            icon={faPlus}
            style={{
              width: "12px",
              height: "12px",
              color: "#ac0bce",
              marginRight: "10px",
            }}
          />
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 17,
              lineHeight: 1.35,
              textAlign: "left",
              color: "#e5e5e5",
            }}
          >
            Nueva sección
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
}
