/*eslint-disable*/
import React, { useState } from "react";
import useStyles from "../styles/form_register_styles";
import CreateAccount from "./CreateAccount";
import ValidateAccount from "./ValidateAccount";

export default function FormRegister(props) {
  const classes = useStyles();
  const [step, setStep] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const renderScreen = (step) => {
    return step == false ? (
      <CreateAccount
        setStep={setStep}
        setEmail={setEmail}
        setPassword={setPassword}
      />
    ) : (
      <ValidateAccount email={email} password={password}></ValidateAccount>
    );
  };

  return <div className={classes.paper}>{renderScreen(step)}</div>;
}
