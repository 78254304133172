/*eslint-disable*/
import { useEffect, useState } from "react";
import axios from "axios";
import axiosInstance from "../../../utils/axiosInstance";

export default function useBooksSearch(query, pageNumber) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);

  useEffect(() => {
    setRows([]);
  }, [query]);

  useEffect(() => {
    var params = new URLSearchParams();
    params.append("page", query.page - 1);
    params.append("limit", query.pageSize);
    params.append("trabajadores_id", localStorage.getItem("idEmployee"));
    params.append("lookup", "catalogos");
    params.append("lookup", "respuestasformularios");

    params.append(
      "initialDate",
      new Date(query.intialDate.setHours(0, 0, 0, 0))
    );
    params.append(
      "finalDate",
      new Date(query.finalDate.setHours(23, 59, 59, 999))
    );

    setLoading(true);
    setError(false);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    axiosInstance()
      .get("/api/v1/rules/trabajadores/asistencias/filter", {
        params,
        cancelToken: source.token,
      })
      .then((response) => {
        // console.log(response.data.data.Results);
        let result = response.data.data.Results;

        result.map((asistencia) => {
          asistencia.id = asistencia._id;
        });

        setRows(result);
        setTotalRows(parseInt(response.data.data.Total));
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        if (axios.isCancel(e)) return;
        setError(true);
      });
    return () => source.cancel();
  }, [query, pageNumber]);

  return { loading, error, rows, totalRows };
}
