/*eslint-disable*/
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faArrowAltCircleLeft,
} from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../../utils/axiosInstance";
import WorkIcon from "@material-ui/icons/Work";
import CardEnterpriseType from "./CardEnterpriseType";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    padding: 15,
  },
  card: {
    width: 570,
    height: 121,
    padding: theme.spacing(4),
    borderRadius: 16,
    backgroundColor: "#2d3034",
  },
  divBack: {
    cursor: "pointer",
    marginBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
  back: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 500,
    textAlign: "left",
    color: "#ffffff",
    cursor: "pointer",
  },
  icon: {
    width: "20px",
    height: "20px",
    color: "#d6d6d6",
    marginRight: "10px",
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 24,
    lineHeight: 1.33,
    color: "#fff",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  subtitle: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.36,
    color: "#a4a4a4",
    // textOverflow: "ellipsis",
    // whiteSpace: "nowrap",
    // overflow: "hidden",
  },
  button: {
    width: 209,
    height: 40,
    borderRadius: 8,
    backgroundColor: "#ac0bce",
    textTransform: "none",
  },
  typoButton: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 1.31,
    textAlign: "left",
    color: "#fff",
  },
  typoLabel: {
    fontFamily: "Roboto",
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.3,
    textAlign: "left",
    color: "#fff",
    marginBottom: 10,
  },
}));

export default function MyEnterpriseSettingsScreen(props) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid className={classes.divMain}>
      <Grid
        item
        xs={6}
        sm={4}
        md={2}
        lg={2}
        xl={2}
        container
        direction="row"
        alignItems="center"
        onClick={() => history.push(`/dashboard/miempresa/configuracion`)}
        className={classes.divBack}
      >
        <Grid item>
          <FontAwesomeIcon
            icon={faArrowAltCircleLeft}
            className={classes.icon}
          />
        </Grid>
        <Grid item>
          <Typography className={classes.back}>Regresar</Typography>
        </Grid>
      </Grid>
      <Grid item xs={6} sm={8} md={10} lg={10} xl={10}></Grid>

      <Grid
        item
        xs={12}
        style={{
          height: "10px",
        }}
      />

      <Grid container direction="row" justifyContent="flex-start">
        <Grid item sm={1} md={2} lg={4} xl={4} />

        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          lg={4}
          xl={4}
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            className={classes.card}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              style={{
                marginBottom: 20,
                //   backgroundColor: "red",
              }}
            >
              <Grid item xs={2}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "57px",
                    height: "55px",
                    borderRadius: 8,
                    backgroundColor: "#d233ff",
                  }}
                >
                  <WorkIcon
                    style={{
                      width: "24px",
                      height: "22px",
                      color: "#fff",
                    }}
                  />
                </div>
              </Grid>

              <Grid
                item
                xs={10}
                container
                direction="column"
                style={{ paddingLeft: 20 }}
              >
                <Typography className={classes.title}>
                  Tipos de empresas
                </Typography>

                <Typography className={classes.subtitle}>
                  Configura los tipos de empresas
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              height: "20px",
            }}
          />

          <Button
            variant="contained"
            className={classes.button}
            onClick={() =>
              history.push(`/dashboard/miempresa/configuracion/crear`)
            }
          >
            <Typography className={classes.typoButton}>
              Nuevo tipo de empresa
            </Typography>
          </Button>

          <Grid
            item
            xs={12}
            style={{
              height: "20px",
            }}
          />

          <CardEnterpriseType enterprise={"Subcontratista"} />
          <CardEnterpriseType enterprise={"Destajo"} />
          <CardEnterpriseType enterprise={"Raya"} />
        </Grid>

        <Grid item sm={1} md={2} lg={4} xl={4} />
      </Grid>
    </Grid>
  );
}
