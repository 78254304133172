/*eslint-disable*/

export const DateFormatString = (str) => {
  const monthNames = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  var day = new Date(str).getDate();
  var month = new Date(str).getMonth();
  var year = new Date(str).getFullYear();
  const dateFormat = day + " de " + monthNames[month] + " " + year;
  return dateFormat.toString();
};

export const DateFormatString2 = (str) => {
  const monthNames = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  var day = new Date(str).getDate();
  var month = new Date(str).getMonth();

  const dateFormat = day + " de " + monthNames[month];
  return dateFormat;
};

export const DateFormatString3 = (str) => {
  const year = new Date(str).getFullYear();

  const startDate = new Date(new Date(str).getFullYear(), 0, 1);
  var days = Math.floor((new Date(str) - startDate) / (24 * 60 * 60 * 1000));
  var weekNumber = Math.ceil(days / 7);

  const dateFormat = year + "Semana" + weekNumber;
  // console.log(dateFormat);
  return dateFormat;
};
