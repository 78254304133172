/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { SetScreenProfile } from "../../../actions/screen";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Lottie from "react-lottie";
import UserFirmasAnimation from "../../../animations/users_firmas.json";
import WorkersTechtonAnimation from "../../../animations/workers_techton.json";

const useStyles = makeStyles((theme) => ({
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: "bold",
    textAlign: "left",
    color: "#cdcece",
  },
  textFieldCustom: {
    width: "350px",
    color: "#676767",
    border: "solid 1px #c5c5c5",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
  },
  divImg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "356px",
    height: "290px",
    borderRadius: "8px",
    border: "solid 1px #707070",
    backgroundColor: "#212429",
    cursor: "pointer",
  },
  trackVertical: {
    height: "95%",
    right: 0,
    width: "10px !important",
  },
  thumbVertical: {
    backgroundColor: "#2d3034",
    height: "100%",
    right: 0,
    width: "8px !important",
    borderRadius: "8px",
  },
}));

export default function DialogChooseSign(props) {
  const classes = useStyles();

  const handleOpenDialog = () => {
    props.setOpenDialogChooseSign(!props.openDialogChooseSign);
  };

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    noKeyboard: true,
    accept: "image/*",
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      acceptedFiles.map((file) => processImage(file));
    },
    // noClick: true
  });

  const processImage = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let fileURL = window.URL.createObjectURL(file);
      props.setUserData({
        ...props.userData,
        foto: file,
        fotoCache: fileURL,
        fotoReq: false,
      });
      props.setOpenDialogChooseSign(!props.openDialogChooseSign);
    };
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: UserFirmasAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: WorkersTechtonAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div>
      <Dialog
        open={props.openDialogChooseSign}
        onClose={handleOpenDialog}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            width: "490px",
            height: "540px",
            paddingTop: 10,
            textColor: "#fsf",
            backgroundColor: "#171a1e",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                lineHeight: 1.3,
                textAlign: "left",
                color: "#fff",
              }}
            >
              Asignación de firma
            </Typography>

            <Grid
              container
              justifyContent="flex-end"
              style={{ position: "absolute" }}
            >
              <Button onClick={handleOpenDialog}>
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{
                    width: "20px",
                    height: "20px",
                    color: "#fff",
                  }}
                />
              </Button>
            </Grid>
          </Grid>
          <div
            style={{
              width: "100%",
              height: 1,
              background: "#707070",
              marginTop: 8,
              padding: 0,
            }}
          ></div>
        </DialogTitle>

        <DialogContent>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              style={{
                height: "200px",
                cursor: "pointer",
              }}
              onClick={() => {
                props.setOpenDialogSearchUser(!props.openDialogSearchUser);
                props.setOpenDialogChooseSign(!props.openDialogChooseSign);
              }}
            >
              <Grid item xs={5}>
                <Lottie options={defaultOptions} height={130} width={173} />
              </Grid>
              <Grid item xs={7} container direction="column">
                <Typography
                  style={{
                    fontSize: "18px",
                    lineHeight: 1.33,
                    textAlign: "left",
                    color: "#fff",
                    // fontWeight: "bold",
                  }}
                >
                  Buscar usuarios
                </Typography>
                <Typography
                  style={{
                    fontSize: "14px",
                    lineHeight: 1.36,
                    textAlign: "left",
                    color: "#929292",
                  }}
                >
                  Busca entre todos los usuarios de tu empresa
                </Typography>
              </Grid>
            </Grid>

            <div
              style={{
                width: "100%",
                height: 1,
                background: "#707070",
                marginTop: 8,
                padding: 0,
              }}
            ></div>

            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              style={{
                height: "200px",
                cursor: "pointer",
              }}
              onClick={() => {
                props.setOpenDialogSearchEmployee(
                  !props.openDialogSearchEmployee
                );
                props.setOpenDialogChooseSign(!props.openDialogChooseSign);
              }}
            >
              <Grid item xs={5}>
                <Lottie options={defaultOptions2} height={144} width={146} />
              </Grid>
              <Grid item xs={7} container direction="column">
                <Typography
                  style={{
                    fontSize: "18px",
                    fontWeight: "normal",
                    lineHeight: 1.33,
                    textAlign: "left",
                    color: "#fff",
                  }}
                >
                  Buscar empleados
                </Typography>
                <Typography
                  style={{
                    fontSize: "14px",
                    lineHeight: 1.36,
                    textAlign: "left",
                    color: "#929292",
                  }}
                >
                  Busca entre todos los empleados de las obras
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
