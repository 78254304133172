/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Typography,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faFileUpload,
  faArrowAltCircleLeft,
} from "@fortawesome/free-solid-svg-icons";
import DateRange from "../../../common_components/Filters/DateRange";
import DataGridInventory from "./components/DataGridInventory";
import DialogUploadDocument from "./components/DialogUploadDocuments";
import useInventorySearch from "./components/useInventorySearch";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    padding: theme.spacing(0, 3, 0, 3),
    // width: "100px",
    // height: "100px",
    // backgroundColor: "#36393C",
  },
  divBack: {
    cursor: "pointer",
    marginBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
  back: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 500,
    textAlign: "left",
    color: "#ffffff",
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 32,
    fontWeight: 500,
    lineHeight: 1.34,
    textAlign: "left",
    color: "#fff",
    marginBottom: 20,
  },
  textFieldCustom: {
    width: 466,
    color: "#676767",
    borderRadius: "8px",
    backgroundColor: "#ffffff",
  },
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 16,
    textAlign: "left",
    color: "#dedcdc",
  },
}));

export default function InventoryScreen(props) {
  const classes = useStyles();
  const history = useHistory();
  const [update, setUpdate] = useState(false);
  const [openDialog, setOpendialog] = useState(false);
  const [selectionRows, setSelectionRows] = useState([]);
  const [query, setQuery] = useState({
    Stock: "",
    pageSize: 10,
    rowsPerPageOptions: [5, 10, 15],
    page: 1,
  });

  const { rows, totalRows, loading, error } = useInventorySearch(query, update);

  const handleQueryChange = (queryElement) => (e) => {
    // const value = queryElement === "Stock" ? e : e.target.value;
    const value = e.target.value;
    return setQuery((prevQuery) => {
      return { ...prevQuery, ...{ [queryElement]: value } };
    });
  };

  const updateData = (k, v) => {
    setQuery((prev) => ({ ...prev, [k]: v }));
  };

  return (
    <div className={classes.divMain}>
      <Grid
        item
        xs={6}
        sm={4}
        md={2}
        lg={2}
        xl={2}
        container
        direction="row"
        alignItems="center"
        onClick={() => history.push(`/dashboard/almacen`)}
        className={classes.divBack}
      >
        <Grid item>
          <FontAwesomeIcon
            icon={faArrowAltCircleLeft}
            style={{
              width: "20px",
              height: "20px",
              marginRight: "10px",
              color: "#d6d6d6",
            }}
          />
        </Grid>
        <Grid item>
          <Typography className={classes.back}>Regresar</Typography>
        </Grid>
      </Grid>
      <Grid item xs={6} sm={8} md={10} lg={10} xl={10}></Grid>

      <Grid container direction="column">
        <Typography className={classes.title}>Inventario</Typography>

        <Grid container direction="row" alignContent="center">
          <TextField
            placeholder="Buscar por número de stock"
            variant="outlined"
            size="small"
            onChange={handleQueryChange("Stock")}
            className={classes.textFieldCustom}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon
                    icon={faSearch}
                    style={{
                      width: "18px",
                      height: "18px",
                      color: "#676767",
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />

          <Button
            variant="contained"
            onClick={() => setOpendialog(!openDialog)}
            style={{
              width: "216px",
              height: "40px",
              borderRadius: "8px",
              color: "#ffffff",
              backgroundColor: "#d233ff",
              textTransform: "none",
              marginLeft: 12,
            }}
          >
            <FontAwesomeIcon
              icon={faFileUpload}
              style={{
                width: "13px",
                height: "18px",
                color: "#fff",
                marginRight: 5,
              }}
            />
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 16,
                lineHeight: 1.31,
                textAlign: "left",
                color: "#ffffff",
              }}
            >
              Importar productos
            </Typography>
          </Button>
        </Grid>

        <DataGridInventory
          rows={rows ?? []}
          loading={loading}
          rowCount={totalRows}
          rowsPerPageOptions={query.rowsPerPageOptions}
          page={query.page}
          pageSize={query.pageSize}
          selectionModel={selectionRows}
          onSelectionModelChange={(ids) => {
            setSelectionRows(ids);
            // history.push(`/dashboard/almacen/pase-salida/${ids}`);
            // history.push(
            //   `/dashboard/almacen/pase-salida/627eb5c83aee3696436380ea`
            // );
          }}
          onPageChange={(data) => {
            updateData("page", data + 1);
          }}
          onPageSizeChange={(data) => {
            updateData("page", 1);
            updateData("pageSize", data);
          }}
          style={{ height: 680, width: "100%", display: "flex" }}
        />
      </Grid>

      <DialogUploadDocument
        setUpdate={setUpdate}
        openDialog={openDialog}
        setOpendialog={setOpendialog}
      />
    </div>
  );
}
