/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Backdrop } from "@material-ui/core";
import BarProgressComponent from "../../UsersScreen/Components/BarProgressComponent";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: theme.palette.neutral.primary,
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 18,
    textAlign: "center",
    color: theme.palette.secondary.dark,
    marginTop: "25px",
  },
  subtitle: {
    fontFamily: "Roboto",
    fontSize: "14px",
    textAlign: "center",
    color: "#b9b9b9",
    marginTop: "12px",
  },
}));

export default function BackdropComponent(props) {
  const classes = useStyles();
  const { percentage, openBackdrop, setOpenBackdrop } = props;

  // const handleClose = () => {
  //   setOpenBackdrop(!openBackdrop);
  // };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Backdrop
        className={classes.backdrop}
        open={openBackdrop}
        /* onClick={handleClose} */
      >
        <Grid
          direction="column"
          justifyContent="center"
          alignItems="center"
          container
          style={{ marginTop: "90px" }}
        >
          <BarProgressComponent progress={percentage} />
          <Typography className={classes.title}>Descargando video</Typography>

          {/* <Typography className={classes.subtitle}>
            Espera a que se guarde el perfil del empleado
          </Typography> */}
        </Grid>
      </Backdrop>
    </Grid>
  );
}
