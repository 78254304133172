/*eslint-disable*/
import React, { useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDropzone } from "react-dropzone";
import {
  faFileUpload,
  faFileAlt,
  faFileDownload,
  faTimes,
  faEllipsisV,
  faHistory,
} from "@fortawesome/free-solid-svg-icons";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { addFile, deleteFile } from "../../../actions/file";
import { saveAs } from "file-saver";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import History from "../../../common_components/DocumentHistory";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flex: 1,
  },
  divFile: {
    margin: "auto",
    width: "100%",
    height: "200px",
    borderRadius: "8px",
    backgroundColor: "#2d3034",
  },
  icon: {
    width: "40px",
    height: "40px",
    color: "white",
    marginBottom: "20px",
  },
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: "bold",
    textAlign: "left",
    color: "#cdcece",
  },
  typography: {
    marginTop: "10px",
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: 500,
    color: "#fff",
    textAlign: "center",
    textOverflow: "ellipsis",
    wordWrap: "anywhere",
    overflow: "hidden",

    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",

    "&:hover": {
      display: "block",
      textAlign: "center",
      overflow: "visible",
      whiteSpace: "normal",
      height: "auto",
    },
  },
  noWrap: {
    textOverflow: "ellipsis",
    wordWrap: "anywhere",
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
  },
}));

export default function UploadFileComponent(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  var { tag } = props;
  const { files } = useSelector((state) => state.file);
  const file = files.filter((file) => file.name === tag)?.reverse()[0]
  const url = file?.url;
  const originalName = file?.originalName;
  const ITEM_HEIGHT = 48;
  const [openHistory, setOpenHistory] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  const onDrop = useCallback((acceptedFiles) => {
    let process = "new";
    dispatch(
      addFile({ file: acceptedFiles[0], name: tag, url: null, process,  id: tag + new Date().getTime(), date: new Date().toISOString()})
    );
  }, []);

  var { getRootProps, getInputProps, acceptedFiles, open } = useDropzone({
    noKeyboard: true,
    noClick: true,
    maxFiles: 1,
    onDrop,
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenHistory = () => {
    setOpenHistory(!openHistory);
    setAnchorEl(null);
  };

  const handleDelete = () => {
    acceptedFiles = [];
    dispatch(deleteFile(tag));
  };

  return (
    <div className={classes.divMain}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <div className={classes.divFile} {...getRootProps()}>
          <input {...getInputProps()} />

          <Grid
            container
            direction="column"
            justifyContent="space-evenly"
            alignItems="center"
            style={{
              height: "100%",
              paddingBottom: "10px",
            }}
          >
            {file && !url && (
              <Grid container justifyContent="flex-end">
                <Button onClick={() => handleDelete()}>
                  <FontAwesomeIcon
                    icon={faTimes}
                    style={{
                      width: "15px",
                      height: "15px",
                      color: "#737373",
                      position: "absolute",
                      marginTop: 20,
                    }}
                  />
                </Button>
              </Grid>
            )}

            {url && (
              <Grid container justifyContent="flex-end">
                <Button
                  aria-label="more"
                  id="long-button"
                  aria-controls="long-menu"
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <FontAwesomeIcon
                    icon={faEllipsisV}
                    style={{
                      width: 20,
                      height: 20,
                      color: "#737373",
                      position: "absolute",
                    }}
                  />
                </Button>
                <Menu
                  classes={{ paper: classes.menuPaper }}
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button",
                  }}
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      width: 271,
                      backgroundColor: "#27292d",
                    },
                  }}
                >
                  <MenuItem
                    key={"Descargar"}
                    style={{ color: "white" }}
                    onClick={() => saveAs(url, originalName)}
                  >
                    <FileDownloadIcon />

                    <Typography
                      className={classes.noWrap}
                      style={{
                        fontFamily: "Roboto",
                        fontSize: 19,
                        textAlign: "left",
                        color: "#fff",
                        marginLeft: 8,
                      }}
                    >
                      Descargar documento
                    </Typography>
                  </MenuItem>

                  <MenuItem
                    key={"Historial"}
                    style={{ color: "white" }}
                    onClick={handleOpenHistory}
                  >
                    <FontAwesomeIcon
                      icon={faHistory}
                      style={{
                        width: 18,
                        height: 18,
                        color: "#fff",
                        marginLeft: 4,
                      }}
                    />

                    <Typography
                      className={classes.noWrap}
                      style={{
                        fontFamily: "Roboto",
                        fontSize: 19,
                        textAlign: "left",
                        color: "#fff",
                        marginLeft: 12,
                      }}
                    >
                      Historial documento
                    </Typography>
                  </MenuItem>
                </Menu>
              </Grid>
            )}

            <Grid
              container
              item
              justifyContent="center"
              style={{ position: "relative" }}
            >
              <div
                style={{
                  background: "#d233ff",
                  position: "absolute",
                  width: "15px",
                  height: "17px",
                  marginTop: 15,
                }}
              ></div>

              <FontAwesomeIcon
                icon={url ? faFileDownload : file ? faFileAlt : faFileUpload}
                className={classes.iconForm}
                style={{
                  width: "27px",
                  height: "36px",
                  color: "#fff",
                  zIndex: 99,
                }}
              ></FontAwesomeIcon>
            </Grid>

            <Typography
              className={classes.noWrap}
              style={{
                fontFamily: "Roboto",
                fontSize: 14,
                lineHeight: "1.29",
                textAlign: "center",
                color: "#d9d9d9",
                width: "80%",
              }}
            >
              {url
                ? originalName
                : file
                ? file.file.name
                : "Arrastra el documento"}
            </Typography>
            {!url && !file ? (
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 14,
                  lineHeight: "1.29",
                  textAlign: "center",
                  color: "#d9d9d9",
                }}
              >
                Ó
              </Typography>
            ) : (
              <div></div>
            )}

            <Button
              variant="contained"
              onClick={() => open()}
              style={{
                width: "119px",
                height: "32px",
                borderRadius: "8px",
                backgroundColor: "#ac0bce",
                textTransform: "none",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 18,
                  textAlign: "left",
                  color: "#ffffff",
                }}
              >
                {url ? "Actualizar" : file ? "Modificar" : "Buscar"}
              </Typography>
            </Button>
          </Grid>
        </div>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{
            margin: "auto",
            height: "52px",
          }}
        >
          <Typography
            className={classes.typography}
            style={{ marginTop: 10, width: "80%" }}
          >
            {tag}
          </Typography>
        </Grid>
      </Grid>

      <History
        openHistory={openHistory}
        setOpenHistory={setOpenHistory}
        data={files.filter((file) => file.name === tag)}
        tag={tag}
      />
    </div>
  );
}
