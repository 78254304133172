/*eslint-disable*/
import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import axiosInstance from "../../../utils/axiosInstance";

export default function useBookSearch(query, jobsFlag) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [books, setBooks] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setBooks([]);
  }, [query]);

  useEffect(() => {
    var params = new URLSearchParams();
    params.append("type_string", "trabajador_puestos");
    params.append("name_partial", query.Nombre);
    params.append("sortMongoAsc", "name");

    setLoading(true);
    setError(false);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    axiosInstance()
      .get("/api/v1/rules/catalogos/filter", {
        params,
        cancelToken: source.token,
      })
      .then((response) => {
        // console.log(response.data.data);
        setBooks(response.data.data);
        setLoading(false);
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;
        setError(true);
      });
    return () => source.cancel();
  }, [query, jobsFlag]);

  return { loading, error, books, hasMore };
}
