/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  gridPaper: {
    height: "121px",
    cursor: "pointer",
    borderRadius: "16px",
    backgroundColor: "#2d3034",
    padding: theme.spacing(3),
    "&:hover": {
      background: "#3B3D40",
      boxShadow: "0 0px 16px 0 rgba(90, 90, 90, 0.2)",
    },
    [theme.breakpoints.down("md")]: {
      height: "auto",
    },
  },
  gridMargin: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
  gridMargin2: {
    paddingLeft: 10,
  },
  divPaper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "57px",
    height: "55px",
    borderRadius: 8,
    backgroundColor: "#d233ff",
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 24,
    lineHeight: 1.33,
    color: "#fff",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    maxWidth: "100%",
  },
  subtitle: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.36,
    color: "#a4a4a4",
  },
}));

export default function CardSettingsSection(props) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      className={classes.gridPaper}
      onClick={() => history.push(props.url)}
    >
      <Grid
        item
        xs={2}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.gridMargin}
      >
        <div className={classes.divPaper}>{props.icon}</div>
      </Grid>

      <Grid
        item
        xs={10}
        container
        direction="column"
        justifyContent="center"
        className={classes.gridMargin2}
      >
        <Typography className={classes.title}>{props.title}</Typography>

        <Typography className={classes.subtitle}>{props.subtitle}</Typography>
      </Grid>
    </Grid>
  );
}
