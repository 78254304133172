/*eslint-disable*/
import React, { Fragment, useEffect, useState } from "react";
import {
  Grid,
  Typography,
  makeStyles,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faUserEdit,
  faBuilding,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import CardVitalSings from "./CardVitalSings";
import ScrollBars from "../../../common_components/ScrollBars";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  divMain: {
    width: "95%",
    height: "100px",
    padding: theme.spacing(1),
    marginTop: 10,
    cursor: "pointer",
    borderRadius: 12,
    backgroundColor: "#2d3034",
    "&:hover": {
      background: "#3B3D40",
      boxShadow: "0 0px 16px 0 rgba(90, 90, 90, 0.2)",
    },
  },
  photo: {
    width: "120px",
    height: "120px",
    aspectRatio: 1,
    objectFit: "cover",
    borderRadius: 16,
  },
  gridMargin: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
}));

export default function DialogResportAssitance(props) {
  const classes = useStyles();
  const { user } = useSelector((state) => state.user);
  const [viewVitalSignsData, setViewVitalSignsData] = useState({
    Evento: "",
    TensionArterial: "",
    FrecuenciaCardiaca: "",
    FrecuenciaRespiratoria: "",
    Temperatura: "",
    Oxigenacion: "",
    Comentarios: "",
  });
  const [employeeData, setEmployeeData] = useState({
    foto: "",
    nombre: "",
    empresa: "",
    obra: "",
    direccion: "",
    latitud: "",
    longitud: "",
    fecha: "",
    hora: "",
    estatus: "",
  });
  const [heightContainer, setHeightContainer] = useState(false);

  const handleDialog = () => {
    props.setOpenDialogReportAssitance(!props.openDialogReportAssitance);
    setViewVitalSignsData({
      ...viewVitalSignsData,
      TensionArterial: "",
      FrecuenciaCardiaca: "",
      FrecuenciaRespiratoria: "",
      Temperatura: "",
      Oxigenacion: "",
      Comentarios: "",
    });

    setEmployeeData({
      ...employeeData,
      foto: "",
      nombre: "",
      empresa: "",
      obra: "",
      direccion: "",
      latitud: "",
      longitud: "",
      fecha: "",
      hora: "",
      estatus: "",
    });
  };

  useEffect(() => {
    // console.log(props.rows);
    props.rows.map(async (item, index) => {
      // console.log("_ID:::", item._id);
      // console.log("selectionRow:", props.selectionRows[0]);
      if (item._id == props.selectionRows[0]) {
        if (item.respuestasformularios.length > 0) {
          setViewVitalSignsData({
            ...viewVitalSignsData,
            Evento: props.rows[index]?.tipoEvento,
            TensionArterial:
              props.rows[index]?.respuestasformularios[0].Responses[0],
            FrecuenciaCardiaca:
              props.rows[index]?.respuestasformularios[0].Responses[1],
            FrecuenciaRespiratoria:
              props.rows[index]?.respuestasformularios[0].Responses[2],
            Temperatura:
              props.rows[index]?.respuestasformularios[0].Responses[3],
            Oxigenacion:
              props.rows[index]?.respuestasformularios[0].Responses[4],
            Comentarios:
              props.rows[index]?.respuestasformularios[0].Responses[5],
          });

          props.setSelectionRows([]);
          setHeightContainer(true);
        } else {
          setViewVitalSignsData({
            ...viewVitalSignsData,
            Evento: props.rows[index]?.tipoEvento,
          });
          setHeightContainer(false);
        }

        setEmployeeData({
          ...employeeData,
          foto: props.rows[index]?.foto ?? employeeData.foto,
          nombre: props.rows[index]?.trabajadores_nombre ?? employeeData.nombre,
          empresa:
            props.rows[index]?.subcontratistas_empresa ?? employeeData.empresa,
          obra: props.rows[index]?.proyectos_proyecto ?? employeeData.obra,
          direccion: props.rows[index]?.geolocation
            ? props.rows[index]?.geolocation.fullAdress
            : employeeData.direccion,
          latitud: props.rows[index]?.geolocation
            ? props.rows[index]?.geolocation.latitude
            : employeeData.latitud,
          longitud: props.rows[index]?.geolocation
            ? props.rows[index]?.geolocation.longitude
            : employeeData.longitud,
          fecha: props.rows[index]?.date ?? employeeData.date,
          hora: props.rows[index]?.time ?? employeeData.hora,
          estatus: props.rows[index]?.status ?? employeeData.estatus,
        });
      }
    });
  }, [props.selectionRows]);

  return (
    <Dialog
      open={props.openDialogReportAssitance}
      aria-labelledby="form-dialog-title"
      onClose={handleDialog}
      PaperProps={{
        style: {
          width: "483px",
          height: heightContainer ? "820px" : "530px",
          borderRadius: "8px",
          backgroundColor: "#171a1e",
        },
      }}
    >
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 20,
              textAlign: "center",
              color: "#fff",
            }}
          >
            {viewVitalSignsData.Evento}
          </Typography>

          <Grid
            container
            justifyContent="flex-end"
            style={{ position: "absolute" }}
          >
            <IconButton onClick={handleDialog}>
              <FontAwesomeIcon
                icon={faTimes}
                style={{
                  width: "20px",
                  height: "20px",
                  color: "#fff",
                  marginLeft: -50,
                }}
              />
            </IconButton>
          </Grid>
        </Grid>
        <div
          style={{
            width: "100%",
            height: 1,
            background: "#707070",
            marginTop: 8,
            padding: 0,
          }}
        />
      </DialogTitle>

      <DialogContent>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          {employeeData.estatus == "manual assistance" ? (
            <Grid
              container
              direccion="row"
              justifyContent="center"
              alignItems="center"
              style={{
                marginBottom: 25,
              }}
            >
              <FontAwesomeIcon
                icon={faUserEdit}
                style={{
                  width: "12.5px",
                  height: "10px",
                  marginRight: 5,
                  color: "#d233ff",
                }}
              />
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 12,
                  lineHeight: 1.33,
                  textAlign: "left",
                  color: "#acacac",
                }}
              >
                Asistencia manual
              </Typography>
            </Grid>
          ) : (
            <Grid
              style={{
                marginBottom: 30,
              }}
            ></Grid>
          )}

          <Grid>
            <img
              src={employeeData.foto}
              className={classes.photo}
              alt="photo"
            />
          </Grid>

          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: 1.31,
              textAlign: "left",
              color: "#fff",
              marginTop: "10px",
            }}
          >
            {employeeData.nombre}
          </Typography>
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: 1.31,
              textAlign: "left",
              color: "#fff",
              marginTop: "4px",
            }}
          >
            {employeeData.empresa}
          </Typography>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: "8px" }}
          >
            <FontAwesomeIcon
              icon={faBuilding}
              style={{
                width: "14px",
                height: "18px",
                color: "#d233ff",
              }}
            />
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: "14px",
                lineHeight: 1.36,
                color: "#fff",
                marginLeft: "7px",
              }}
            >
              {employeeData.obra}
            </Typography>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{
              marginTop: 30,
            }}
          >
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 14,
                fontWeight: 500,
                lineHeight: 1.36,
                color: "#fff",
              }}
            >
              Fecha
            </Typography>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 14,
                  lineHeight: 1.36,
                  color: "#acacac",
                  marginRight: 5,
                }}
              >
                {employeeData.fecha}
              </Typography>
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 14,
                  lineHeight: 1.36,
                  color: "#acacac",
                }}
              >
                {employeeData.hora}
              </Typography>
            </Grid>
          </Grid>

          {employeeData.estatus != "manual assistance" &&
          employeeData.direccion != "" ? (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              style={{
                marginTop: 20,
              }}
            >
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 14,
                  lineHeight: 1.36,
                  color: "#fff",
                }}
              >
                Asistencia tomada en
              </Typography>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  style={{
                    width: "14px",
                    height: "18px",
                    color: "#d233ff",
                  }}
                />
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 12,
                    lineHeight: 1.33,
                    // color: "#d233ff",
                    // cursor: "pointer",
                    marginLeft: 5,
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    maxWidth: "75%",
                  }}
                >
                  <Link
                    to="/dashboard/reportes/asistencias"
                    onClick={() => {
                      props.setOpenDialogReportAssitance(
                        !props.openDialogReportAssitance
                      );
                      props.setOpendialog(!props.openDialog);
                    }}
                    style={{
                      color: "#d233ff",
                      cursor: "pointer",
                    }}
                  >
                    {employeeData.direccion}
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          ) : null}

          {user?.Email ? (
            <Grid
              container
              direction="column"
              alignItems="center"
              style={{
                marginTop: 20,
              }}
            >
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 14,
                  lineHeight: 1.36,
                  textAlign: "left",
                  color: "#fff",
                }}
              >
                Asistencia tomada por el usuario
              </Typography>
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 14,
                  lineHeight: 1.36,
                  textAlign: "left",
                  color: "#acacac",
                }}
              >
                {user?.Email}
              </Typography>
            </Grid>
          ) : null}

          {heightContainer ? (
            <Fragment>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{
                  marginTop: "43px",
                  paddingLeft: 20,
                  marginBottom: "20px",
                }}
              >
                <ScrollBars style={{ width: "100%", height: "35vh" }}>
                  <CardVitalSings
                    tittle={"Tensión arterial T/A"}
                    info={viewVitalSignsData.TensionArterial ?? ""}
                  />
                  <CardVitalSings
                    tittle={"Frecuencia Cardiaca"}
                    info={viewVitalSignsData.FrecuenciaCardiaca ?? ""}
                  />
                  <CardVitalSings
                    tittle={"Frecuencia respiratoria"}
                    info={viewVitalSignsData.FrecuenciaRespiratoria ?? ""}
                  />
                  <CardVitalSings
                    tittle={"Temperatura"}
                    info={viewVitalSignsData.Temperatura ?? ""}
                  />
                  <CardVitalSings
                    tittle={"Oxigenación"}
                    info={viewVitalSignsData.Oxigenacion ?? ""}
                  />
                  <CardVitalSings
                    tittle={"Comentarios"}
                    info={viewVitalSignsData.Comentarios ?? ""}
                  />

                  {/**info.map((item, index) => {
                    return (
                      <CardVitalSings
                        key={index}
                        tittle={item.tittle}
                        info={item.info}
                      />
                    );
                  })*/}
                </ScrollBars>
              </Grid>
            </Fragment>
          ) : (
            <Grid></Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
