/*eslint-disable*/
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import Lottie from "react-lottie";
import UnderConstruction from "../../animations/under_construction.json";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flex: 1,
    backgroundColor: "#171a1e",
  },
  gridMain: {
    backgroundColor: "#171a1e",
    // backgroundColor: "red",
  },
}));

export default function ScreenOnConstruction(props) {
  const classes = useStyles();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: UnderConstruction,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className={classes.divMain}>
      <Grid
        item
        xs={12}
        container
        direction="row"
        justifyContent="flex-start"
        className={classes.gridMain}
        style={{ marginTop: 150 }}
      >
        <Grid
          item
          xs={6}
          container
          direction="column"
          justifyContent="center"
          style={{
            paddingLeft: "20px",
          }}
        >
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 36,
              fontWeight: 500,
              color: "#fff",
            }}
          >
            PÁGINA EN CONSTRUCCIÓN
          </Typography>

          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 24,
              fontWeight: 300,
              color: "#bebebe",
              paddingBottom: "20px",
            }}
          >
            Estamos trabajando para traerte más innovación
          </Typography>
        </Grid>
        <Grid item xs>
          <Lottie options={defaultOptions} height={500} width={500} />
        </Grid>
      </Grid>
    </div>
  );
}
