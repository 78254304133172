/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Typography, Button, Box } from "@material-ui/core";
import PropTypes from "prop-types";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 18,
    textAlign: "left",
    color: theme.palette.secondary.main,
    marginBottom: 10,
  },
  root: {
    width: "400px",
    // marginTop: 12,
  },
  boxProgress: {
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: theme.palette.primary.light,
      height: "16px",
      borderRadius: "5px",
    },
    "& .MuiLinearProgress-colorPrimary": {
      backgroundColor: theme.palette.secondary.light,
      height: "16px",
      borderRadius: "5px",
    },
  },
}));

function LinearProgressWithLabel(props) {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1} className={classes.boxProgress}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      {/* <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box> */}
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default function BarProgressComponent(props) {
  const classes = useStyles();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (props.progress) {
      setProgress(props.progress);
    }
  }, [props.progress]);

  return (
    <div className={classes.divMain}>
      <Grid container direction="row" justifyContent="flex-end">
        <Grid container direction="column" alignItems="center">
          <Typography className={classes.title}>
            {progress}% completado
          </Typography>

          <div className={classes.root}>
            <LinearProgressWithLabel
              value={progress}
              className={classes.LinearProgress}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
