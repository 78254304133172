/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Typography, TextField } from "@material-ui/core";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faPen } from "@fortawesome/free-solid-svg-icons";
import { useDropzone } from "react-dropzone";
import Typo from "../../../common_components/typo";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
  },
  divImg: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "130px",
    height: "130px",
    cursor: "pointer",
  },
  photo: {
    width: "90%",
    height: "90%",
    borderRadius: "50%",
    aspectRatio: 1,
    // position: "absolute",
    objectFit: "contain",
  },
  icon: {
    width: "40px",
    height: "40px",
    color: "white",
    marginBottom: "20px",
  },
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: "bold",
    textAlign: "left",
    color: "#cdcece",
  },
  textFieldCustom: {
    width: "90%",
    color: "#676767",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
  },
  formControl: {
    width: "90%",
  },
  select: {
    width: "100%",
    height: "40px",
    borderRadius: "6px",
    backgroundColor: "#fff",
  },
  gridMargin: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
}));

export default function InfoEntreprise(props) {
  const classes = useStyles();
  // const [anchorEl, setAnchorEl] = useState(null);
  // const open = Boolean(anchorEl);
  // const id = open ? "simple-popover" : undefined;

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    noKeyboard: true,
    accept: "image/*",
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      acceptedFiles.map((file) => processImage(file));
    },
  });

  const processImage = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let fileURL = window.URL.createObjectURL(file);
      props.setUserData({
        ...props.userData,
        logo: file,
        logoCache: fileURL,
      });
    };
  };

  const handleChange = (e) => {
    props.setChangesFlag(true);
    const value = e.target.value;
    props.setUserData({
      ...props.userData,
      [e.target.name]: value,
    });
  };

  useEffect(() => {
    if (props.userData.empresa != "") {
      props.setMandatoryFlag(true);
    } else {
      props.setMandatoryFlag(false);
    }
  }, [props.userData.empresa]);

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <Typo
            fontSize={24}
            fontWeight={500}
            text={"Logo de empresa"}
            color={"#fff"}
            marginBottom={30}
          />
        </Grid>

        <Grid container direction="row" justifyContent="flex-start">
          <Grid item xs={3}>
            <div className={classes.divImg} {...getRootProps()}>
              <input {...getInputProps()} />

              {props.userData.logoCache ? (
                <img
                  src={props.userData.logoCache}
                  className={classes.photo}
                  alt="photo"
                />
              ) : (
                <div>
                  <div
                    style={{
                      width: "120px",
                      height: "120px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "50%",
                      backgroundColor: "#a5a5a5",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faImage}
                      style={{
                        width: "35px",
                        height: "25px",
                        color: "#717171",
                        borderRadius: "8px",
                      }}
                    />
                  </div>
                  <div style={{ position: "absolute", top: 90, left: 90 }}>
                    <div
                      style={{
                        width: "32px",
                        height: "32px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                        backgroundColor: "#fff",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faPen}
                        style={{
                          width: "16px",
                          height: "16px",
                          color: "#707070",
                          borderRadius: "8px",
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typo
            fontSize={24}
            fontWeight={500}
            text={"Información de empresa"}
            color={"#fff"}
            marginTop={60}
            marginBottom={16}
          />
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            height: "30px",
          }}
        ></Grid>

        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Typography className={classes.textFieldLetter}>
            Nombre de empresa
          </Typography>
          <TextField
            // placeholder="Obligatorio"
            name="empresa"
            size="small"
            variant="outlined"
            value={props.userData.empresa}
            className={classes.textFieldCustom}
            onChange={handleChange}
            inputProps={{
              maxLength: 80,
              // style: { textTransform: "capitalize" },
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          className={classes.gridMargin}
        >
          <Typography className={classes.textFieldLetter}>Dirección</Typography>
          <TextField
            // placeholder="Obligatorio"
            name="direccion"
            size="small"
            variant="outlined"
            value={props.userData.direccion}
            className={classes.textFieldCustom}
            onChange={handleChange}
            inputProps={{
              maxLength: 50,
              style: { textTransform: "capitalize" },
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}
