/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Grid, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import svgOut from "../../../images/salida_icono.svg";
import svgIn from "../../../images/ingreso_icono.svg";
import svgTrabajador from "../../../images/usuario_trabajador.svg";

const useStyles = makeStyles((theme) => ({
  card: {
    width: 674,
    height: 349,
    padding: theme.spacing(2),
    borderRadius: 16,
    backgroundColor: "#2d3034",
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.3,
    textAlign: "left",
    color: "#fff",
    // display: "-webkit-box",
    // WebkitBoxOrient: "vertical",
    // WebkitLineClamp: 2,
    // textOverflow: "ellipsis",
    // overflow: "hidden",
    // maxWidth: "80%",
  },
  subtitle: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 1.31,
    textAlign: "left",
    color: "#afafaf",
  },
  typoWatch: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 1.33,
    textAlign: "left",
    color: "#d233ff",
    cursor: "pointer",
  },
  divBackIcon: {
    width: 32,
    height: 32,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
    backgroundImage: "linear-gradient(91deg, #d233ff 1%, #ac0bce 99%)",
  },
  typoWorkersNumber: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.31,
    textAlign: "left",
    color: "#fff",
    marginRight: 5,
  },
  typoWorkersLabel: {
    fontFamily: "Roboto",
    fontSize: 14,
    lineHeight: 1.36,
    textAlign: "left",
    color: "#acacac",
  },
}));

export default function BigCard(props) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{
          marginBottom: 20,
        }}
      >
        <Grid item xs={10}>
          <Typography className={classes.title}>{props.title}</Typography>
          <Typography className={classes.subtitle}>{props.subtitle}</Typography>
        </Grid>

        <Grid item xs={2} container direction="row" justifyContent="flex-end">
          <FontAwesomeIcon
            icon={"fa-maximize"}
            style={{
              width: "28px",
              height: "28px",
              color: "#fff",
              cursor: "pointer",
            }}
          />
        </Grid>
      </Grid>

      <Grid container direction="row" justifyContent="flex-start">
        <Grid item xs={4} container direction="column">
          <Grid
            container
            direction="column"
            style={{
              width: 160,
              height: 222,
              padding: 8,
              borderRadius: 12,
              border: "solid 1px #707070",
            }}
          >
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 16,
                lineHeight: 1.31,
                textAlign: "right",
                color: "#ac0bce",
                cursor: "pointer",
                marginBottom: 20,
              }}
            >
              Ver
            </Typography>

            <Grid container direction="row" justifyContent="center">
              <img
                src={svgTrabajador}
                style={{
                  width: "35px",
                  height: "35px",
                  textAlign: "center",
                }}
                alt="techton logo"
              />
            </Grid>

            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 32,
                fontWeight: 500,
                lineHeight: 1.34,
                textAlign: "center",
                color: "#fff",
              }}
            >
              {"500"}
            </Typography>

            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 14,
                lineHeight: 1.36,
                textAlign: "center",
                color: "#fff",
                marginBottom: 5,
              }}
            >
              {"Empleados"}
            </Typography>

            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 14,
                lineHeight: 1.36,
                textAlign: "center",
                color: "#bcbcbc",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "100%",
              }}
            >
              {"Se encuentran en la obra"}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          item
          xs={8}
          container
          direction="column"
          justifyContent="space-between"
        >
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            style={{
              width: 353,
              height: 91,
              padding: 5,
              borderRadius: 12,
              border: "solid 1px #707070",
            }}
          >
            <Grid
              item
              xs={2}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <div
                style={{
                  width: 40,
                  height: 40,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 8,
                  backgroundImage:
                    "linear-gradient(91deg, #d233ff 1%, #ac0bce 99%)",
                }}
              >
                <img
                  src={svgIn}
                  style={{
                    width: 21,
                    height: 24,
                  }}
                />
              </div>
            </Grid>

            <Grid
              item
              xs={9}
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 32,
                  fontWeight: 500,
                  lineHeight: 1.34,
                  textAlign: "left",
                  color: "#fff",
                  marginRight: 10,
                }}
              >
                {"570"}
              </Typography>
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 16,
                  lineHeight: 1.31,
                  textAlign: "left",
                  color: "#fff",
                }}
              >
                {"Entradas"}
              </Typography>
            </Grid>

            <Grid
              item
              xs={1}
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
            >
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 16,
                  lineheight: 1.31,
                  textAlign: "left",
                  color: "#ac0bce",
                  cursor: "pointer",
                }}
              >
                Ver
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            style={{
              width: 353,
              height: 91,
              padding: 5,
              borderRadius: 12,
              border: "solid 1px #707070",
            }}
          >
            <Grid
              item
              xs={2}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <div
                style={{
                  width: 40,
                  height: 40,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 8,
                  backgroundImage:
                    "linear-gradient(91deg, #d233ff 1%, #ac0bce 99%)",
                }}
              >
                <img
                  src={svgOut}
                  style={{
                    width: 21,
                    height: 24,
                  }}
                />
              </div>
            </Grid>

            <Grid
              item
              xs={9}
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 32,
                  fontWeight: 500,
                  lineHeight: 1.34,
                  textAlign: "left",
                  color: "#fff",
                  marginRight: 10,
                }}
              >
                {"20"}
              </Typography>
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 16,
                  lineHeight: 1.31,
                  textAlign: "left",
                  color: "#fff",
                }}
              >
                {"Salidas"}
              </Typography>
            </Grid>

            <Grid
              item
              xs={1}
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
            >
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 16,
                  lineheight: 1.31,
                  textAlign: "left",
                  color: "#ac0bce",
                  cursor: "pointer",
                }}
              >
                Ver
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* <Typography className={classes.typoWorkersNumber}>
          {props.number}
        </Typography>
        <Typography className={classes.typoWorkersLabel}>
          {props.active}
        </Typography> */}
      </Grid>
    </Card>
  );
}
