/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import ScreenOnConstruction from "../../ScreenOnConstruction/ScreenOnConstruction";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    padding: theme.spacing(3),
    // width: "100px",
    // height: "100px",
    // backgroundColor: "#36393C",
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 32,
    fontWeight: 500,
    textAlign: "left",
    color: "#ffffff",
  },
}));

export default function DevolutionsScreen(props) {
  const classes = useStyles();

  // return <div className={classes.divMain}></div>;
  return <ScreenOnConstruction></ScreenOnConstruction>;
}
