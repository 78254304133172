/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import {
  Grid,
  Typography,
  makeStyles,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Popover,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faArrowRight,
  faPlus,
  faAngleRight,
  faPen,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import CardAttendanceEventsExtra from "./Components/CardAttendanceEventsExtra";
import CardAttendanceEvents from "./Components/CardAttendanceEvents";

import TimeIconComponent from "../../../common_components/TimeIconComponent";
import ScrollBars from "../../../common_components/ScrollBars";
import axiosInstance from "../../../utils/axiosInstance";

import DialogDeleteAttendance from "./DialogDeleteAttendance";
import DialogDeleteEvent from "./DialogDeleteEvent";

import { ValidatePermissions } from "../../../utils/permissions";

const useStyles = makeStyles((theme) => ({
  menu: {
    cursor: "pointer",
    "&:hover": {
      background: "#3B3D40",
      boxShadow: "0 0px 16px 0 rgba(90, 90, 90, 0.2)",
    },
  },
}));

export default function DialogEditAttendance(props) {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  let { openDialog, setOpenDialog, data, query, setQuery } = props;
  const [anchorElAssistance, setAnchorElAssistance] = useState(null);
  const [anchorElUpdateAssistance, setAnchorElUpdateAssistance] =
    useState(null);
  const [anchorElMenuAssistance, setAnchorElMenuAssistance] = useState(null);
  const [anchorElUpdateEvent, setAnchorElUpdateEvent] = useState(null);
  const [anchorElMenuEvent, setAnchorElMenuEvent] = useState(null);
  const [anchorElEvent, setAnchorElEvent] = useState(null);
  const [anchorElSubEvent, setAnchorElSubEvent] = useState(null);
  const [loadingAssistance, setLoadingAssistance] = useState(false);
  const [loadingEvent, setLoadingEvent] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteEventDialog, setDeleteEventDialog] = useState(false);

  const [registryIn, setRegistryIn] = useState("");
  const [registryOut, setRegistryOut] = useState("");
  const [registryEvent, setRegistryEvent] = useState("");
  const [registryUpdate, setRegistryUpdate] = useState("");
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [events, setEvents] = useState([]);
  const [selectUpdate, setSelectUpdate] = useState(null);
  const [selectUpdateEvent, setSelectUpdateEvent] = useState(null);

  const openAssistance = Boolean(anchorElAssistance);
  const openUpdateAssistance = Boolean(anchorElUpdateAssistance);
  const openMenuAssistance = Boolean(anchorElMenuAssistance);

  const openUpdateEvent = Boolean(anchorElUpdateEvent);
  const openMenuEvent = Boolean(anchorElMenuEvent);

  const openEvent = Boolean(anchorElEvent);
  const openSubEvent = Boolean(anchorElSubEvent);

  const handleOpenSubEvent = (event) => {
    setAnchorElSubEvent(event.currentTarget);
  };

  const handleCloseSubEvent = () => {
    setAnchorElSubEvent(null);
  };

  const handleOpenEvent = (event) => {
    setAnchorElEvent(event.currentTarget);
  };

  const handleCloseEvent = () => {
    setAnchorElEvent(null);
  };

  const handleOpenAssistance = (event) => {
    setAnchorElAssistance(event.currentTarget);
  };

  const handleCloseAssistance = () => {
    setAnchorElAssistance(null);
  };

  const handleOpenUpdateAssistance = (event) => {
    setAnchorElUpdateAssistance(event.currentTarget);
  };

  const handleCloseUpdateAssistance = () => {
    setAnchorElUpdateAssistance(null);
  };

  const handleOpenMenuAssistance = (event) => {
    setAnchorElMenuAssistance(event.currentTarget);
  };

  const handleCloseMenuAssistance = () => {
    setAnchorElMenuAssistance(null);
  };

  const handleOpenUpdateEvent = (event) => {
    setAnchorElUpdateEvent(event.currentTarget);
  };

  const handleCloseUpdateEvent = () => {
    setAnchorElUpdateEvent(null);
  };

  const handleOpenMenuEvent = (event) => {
    setAnchorElMenuEvent(event.currentTarget);
  };

  const handleCloseMenuEvent = () => {
    setAnchorElMenuEvent(null);
  };

  const handleDialog = () => {
    const dialog = !openDialog;

    if (!dialog) {
      setQuery({ ...query, update: !query.update });
    }

    setOpenDialog(dialog);
  };

  const handleRegisterAssistance = async () => {
    if (registryIn == "") {
      enqueueSnackbar("Error, selecciona hora de entrada", {
        variant: "warning",
      });
      return;
    }

    setLoadingAssistance(true);

    let arrayAttendance = data?.entradaSalida;
    let inDate = new Date(registryIn);
    let outDate = new Date(registryOut);

    let timesInt = new Date(data?.fecha);
    timesInt.setHours(inDate.getHours());
    timesInt.setMinutes(inDate.getMinutes());
    timesInt = timesInt.getTime();

    let timesOut = new Date(data?.fecha);
    timesOut.setHours(outDate.getHours());
    timesOut.setMinutes(outDate.getMinutes());
    timesOut = timesOut.getTime();

    if (!isNaN(timesOut) && timesOut < timesInt) {
      enqueueSnackbar(
        "Error, la hora de salida no puede ser mayor a la hora de entrada",
        {
          variant: "warning",
        }
      );
      setLoadingAssistance(false);
      return;
    }

    let objectSave = {
      emulated: false,
      type: "entrada_salida",
      _id: data?._id,
    };
    const resultIn = await postAssistance(data?._id, timesInt, "Entrada");
    // console.log("resultIn", resultIn)

    if (resultIn?.status != "ok") {
      enqueueSnackbar("Error, no se pudo agregar la asistencia", {
        variant: "error",
      });
      setLoadingAssistance(false);
      return;
    }
    objectSave = {
      ...objectSave,
      entrada: timesInt / 1000,
      salida: 0,
      _id: resultIn.data.workingtime_id,
    };

    if (!isNaN(timesOut)) {
      // const resultOut = await postAssistance(data?._id, timesOut, "Salida");

      const resultOut = await updateAssistance(
        resultIn.data.workingtime_id,
        timesOut,
        "Salida"
      );

      if (resultOut?.status != "ok") {
        enqueueSnackbar("Error, no se pudo agregar la asistencia", {
          variant: "error",
        });
        setLoadingAssistance(false);
        return;
      }

      objectSave = { ...objectSave, salida: timesOut / 1000 };
    }

    arrayAttendance.push(objectSave);
    data.entradaSalida = arrayAttendance;
    setLoadingAssistance(false);
    handleCloseAssistance();
    setRegistryIn("");
    setRegistryOut("");
    enqueueSnackbar("Asistencia agregada correctamente", {
      variant: "success",
    });
  };

  const handleRegisterEvent = async () => {
    if (!selectedEvent || registryEvent == "") {
      enqueueSnackbar("Error, selecciona hora de entrada", {
        variant: "warning",
      });
      return;
    }

    setLoadingEvent(true);

    let arrayEvents = data?.evento;
    let eventDate = new Date(registryEvent);
    let timesEvent = new Date(data?.fecha);
    timesEvent.setHours(eventDate.getHours());
    timesEvent.setMinutes(eventDate.getMinutes());
    timesEvent = timesEvent.getTime();

    const result = await postEvents(data?._id, timesEvent, selectedEvent);

    if (result?.status != "ok") {
      enqueueSnackbar("Error, no se pudo agregar el evento", {
        variant: "error",
      });
      setLoadingEvent(false);
      return;
    }

    let objectSave = {
      emulated: false,
      type: "entrada_salida",
      tipoEvento: selectedEvent,
      _id: result?.data?._id,
      datetime: result?.data?.datetime,
    };

    arrayEvents.push(objectSave);
    data.evento = arrayEvents;

    setLoadingEvent(false);
    handleCloseEvent();
    setRegistryEvent("");
    setRegistryOut("");
    enqueueSnackbar("Evento agregado correctamente", {
      variant: "success",
    });
  };

  const handleUpdateAssistance = async () => {
    if (registryUpdate == "") {
      enqueueSnackbar("Error, selecciona hora de entrada", {
        variant: "warning",
      });
      return;
    }

    setLoadingAssistance(true);

    let updateDate = new Date(registryUpdate);

    let timesUpdate = new Date(data?.fecha);
    timesUpdate.setHours(updateDate.getHours());
    timesUpdate.setMinutes(updateDate.getMinutes());
    timesUpdate = timesUpdate.getTime();

    if (
      selectUpdate.type == "Salida" &&
      selectUpdate.entrada > timesUpdate / 1000
    ) {
      enqueueSnackbar(
        "Error, la hora de salida no puede ser mayor a la hora de entrada",
        {
          variant: "warning",
        }
      );
      setLoadingAssistance(false);
      return;
    }

    if (
      selectUpdate.type == "Entrada" &&
      selectUpdate.salida < timesUpdate / 1000
    ) {
      enqueueSnackbar(
        "Error, la hora de entrada no puede ser menor a la hora de salida",
        {
          variant: "warning",
        }
      );
      setLoadingAssistance(false);
      return;
    }

    const result = await updateAssistance(
      selectUpdate.id,
      timesUpdate,
      selectUpdate.type
    );

    if (result?.status != "ok") {
      enqueueSnackbar("Error, no se pudo actualizar la asistencia", {
        variant: "error",
      });
      setLoadingAssistance(false);
      return;
    }

    let arrayAttendance = data?.entradaSalida;
    const objIndex = arrayAttendance.findIndex(
      (obj) => obj._id == selectUpdate.id
    );
    arrayAttendance[objIndex][selectUpdate.type.toLowerCase()] =
      timesUpdate / 1000;

    data.entradaSalida = arrayAttendance;
    data.key == Math.random(); //force component update
    handleCloseUpdateAssistance();
    handleCloseMenuAssistance();
    setSelectUpdate(null);
    setLoadingAssistance(false);
    setRegistryUpdate("");
    enqueueSnackbar("Asistencia actualizada correctamente", {
      variant: "success",
    });
  };

  const handleUpdateEvent = async () => {
    if (!selectedEvent || registryEvent == "") {
      enqueueSnackbar("Error, selecciona hora de entrada", {
        variant: "warning",
      });
      return;
    }

    setLoadingEvent(true);

    let arrayEvents = data?.evento;
    let eventDate = new Date(registryEvent);
    let timesEvent = new Date(data?.fecha);
    timesEvent.setHours(eventDate.getHours());
    timesEvent.setMinutes(eventDate.getMinutes());
    timesEvent = timesEvent.getTime();

    const result = await updateEvent(
      selectUpdateEvent?._id,
      timesEvent,
      selectedEvent
    );

    if (result?.status != "ok") {
      enqueueSnackbar("Error, no se pudo actualizar el evento", {
        variant: "error",
      });
      setLoadingEvent(false);
      return;
    }
    const objIndex = arrayEvents.findIndex(
      (obj) => obj._id == selectUpdateEvent._id
    );

    arrayEvents[objIndex].datetime = new Date(timesEvent);
    arrayEvents[objIndex].tipoEvento = selectedEvent;
    data.evento = arrayEvents;
    data.key == Math.random(); //force component update

    setLoadingEvent(false);
    handleCloseUpdateEvent();
    handleCloseMenuEvent();
    setRegistryEvent("");
    setSelectUpdateEvent(null);
    enqueueSnackbar("Evento actualizado correctamente", {
      variant: "success",
    });
  };

  const postAssistance = async (id, timestamp, type) => {
    return await axiosInstance()
      .post("/api/v1/rules/asistencias/manual_entsal/", {
        timestamp: timestamp,
        trabajadores_id: id,
        tipoEvento: type,
      })
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        return null;
      });
  };

  const updateAssistance = async (id, timestamp, type) => {
    return await axiosInstance()
      .put(`/api/v1/rules/asistencias/manual_entsal/${type}/${id}`, {
        timestamp: timestamp,
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        return null;
      });
  };

  const updateEvent = async (id, timestamp, eventType) => {
    return await axiosInstance()
      .put(`/api/v1/rules/asistencias/manual_event/Evento/${id}`, {
        timestamp: timestamp,
        tipoEvento: eventType,
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        return null;
      });
  };

  const postEvents = async (id, timestamp, type) => {
    return await axiosInstance()
      .post("/api/v1/rules/asistencias/manual_event/", {
        timestamp: timestamp,
        trabajadores_id: id,
        tipoEvento: type,
      })
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        return null;
      });
  };

  const getEvents = async () => {
    try {
      var params = new URLSearchParams();
      params.append("type_string", "asistencias_evento");

      return await axiosInstance()
        .get("/api/v1/rules/catalogos/filter", { params })
        .then((response) => {
          let events = response.data.data.map((object) => {
            return { label: object.name, value: object._id };
          });

          events = events.filter(
            (x) => x.label != "Entrada" && x.label != "Salida"
          );

          setEvents(events);
          return events;
        })
        .catch((e) => {
          console.log(e);
          return null;
        });
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  useEffect(() => {
    getEvents();
  }, []);

  return (
    <Dialog
      disableEnforceFocus
      open={openDialog}
      aria-labelledby="form-dialog-title"
      onClose={handleDialog}
      PaperProps={{
        style: {
          backgroundColor: "#2d3034",
          textColor: "#fsf",
          width: 864,
          minWidth: 864,
          minHeight: 313,
          paddingTop: 15,
          borderRadius: 12,
          paddingBottom: 15,
        },
      }}
    >
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 22,
              textAlign: "center",
              color: "#fff",
              fontWeight: "bold",
            }}
          >
            Edición de asistencia
          </Typography>

          <Grid
            container
            justifyContent="flex-end"
            style={{ position: "absolute" }}
          >
            <IconButton onClick={handleDialog}>
              <FontAwesomeIcon
                icon={faTimes}
                style={{
                  width: "20px",
                  height: "20px",
                  color: "#fff",
                  marginLeft: -50,
                }}
              />
            </IconButton>
          </Grid>
        </Grid>
        <div
          style={{
            width: "100%",
            height: 1,
            background: "#707070",
            marginTop: 8,
            padding: 0,
          }}
        ></div>
      </DialogTitle>

      <DialogContent>
        <Grid
          container
          direction="row"
          style={{ padding: "25px 25px 0px 25px" }}
        >
          <Grid item xs={4}>
            <Grid container direction="row">
              <Grid
                item
                xs={3}
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <img
                  src={data?.imageIndex}
                  style={{
                    width: 56,
                    height: 56,
                    borderRadius: 8,
                    aspectRatio: 1,
                    objectFit: "cover",
                  }}
                  alt="photo"
                />
              </Grid>

              <Grid item xs={9} container direction="column">
                <Grid style={{ marginLeft: 25 }}>
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 16,
                      textAlign: "left",
                      color: "#fff",
                      fontWeight: 500,
                    }}
                  >
                    {data?.nombre}
                  </Typography>

                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 14,
                      textAlign: "left",
                      color: "#acacac",
                    }}
                  >
                    {data?.puesto}
                  </Typography>

                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 14,
                      textAlign: "left",
                      color: "#fff",
                      fontWeight: 500,
                    }}
                  >
                    {data?.empresa}
                  </Typography>

                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 16,
                      textAlign: "left",
                      color: "#acacac",
                      fontWeight: 500,
                    }}
                  >
                    {data?.unidad}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={5}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              style={{ marginBottom: 8, width: 280, marginLeft: 20 }}
            >
              {data.entradaSalida.length > 0 &&
                data?.entradaSalida?.map((_attendance, index) => (
                  <Grid
                    key={_attendance?._id}
                    container
                    direction="row"
                    style={{ marginBottom: 8 }}
                  >
                    <Grid
                      item
                      xs={4}
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        _attendance.entrada > 0
                          ? handleOpenMenuAssistance(e)
                          : handleOpenUpdateAssistance(e);
                        setSelectUpdate({
                          id: _attendance._id,
                          type: "Entrada",
                          entrada: _attendance.entrada,
                          salida: _attendance.salida,
                        });
                        setRegistryUpdate(new Date(_attendance.entrada * 1000));
                      }}
                    >
                      <CardAttendanceEvents
                        data={_attendance.entrada > 0 && _attendance.entrada}
                        event="Entrada"
                        manual={
                          _attendance?.statusEntrada == "manual assistance"
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        style={{
                          width: 25,
                          height: 25,
                          color: "#fff",
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        _attendance.salida > 0
                          ? handleOpenMenuAssistance(e)
                          : handleOpenUpdateAssistance(e);
                        setSelectUpdate({
                          id: _attendance._id,
                          type: "Salida",
                          entrada: _attendance.entrada,
                          salida: _attendance.salida,
                        });
                        setRegistryUpdate(new Date(_attendance.entrada * 1000));
                      }}
                      // onClick={() => console.log("_attendance", _attendance)}
                    >
                      <CardAttendanceEvents
                        data={_attendance.salida > 0 && _attendance.salida}
                        event="Salida"
                        manual={
                          _attendance?.statusSalida == "manual assistance"
                        }
                      />
                    </Grid>
                  </Grid>
                ))}
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="center"
              style={{ marginTop: 30, width: 280, marginLeft: 22 }}
            >
              {ValidatePermissions("crear") && (
                <Grid
                  onClick={handleOpenAssistance}
                  style={{
                    backgroundColor: "#2d3034",
                    border: "dashed 2px #707070",
                    width: 270,
                    height: 40,
                    borderRadius: 8,
                    cursor: "pointer",
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                      height: 36,
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faPlus}
                      style={{
                        width: 12,
                        height: 12,
                        color: "#ac0bce",
                      }}
                    />

                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontSize: 17,
                        textAlign: "left",
                        color: "#e5e5e5",
                        marginLeft: 8,
                      }}
                    >
                      Registrar asistencia
                    </Typography>
                  </Grid>
                </Grid>
              )}

              <Popover
                open={openAssistance}
                anchorEl={anchorElAssistance}
                onClose={handleCloseAssistance}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                PaperProps={{
                  style: {
                    width: 270,
                    height: 148,
                    backgroundColor: "#212429",
                    borderRadius: 6,
                    marginTop: 10,
                  },
                }}
                disableAutoFocus={true}
                disableEnforceFocus={true}
              >
                <Grid
                  container
                  direction="column"
                  style={{ marginTop: 8, padding: 15 }}
                >
                  <Grid container direction="row">
                    <Grid item xs={6} container direction="column">
                      <Typography
                        style={{
                          fontFamily: "Roboto",
                          fontSize: 14,
                          textAlign: "left",
                          color: "#fff",
                        }}
                      >
                        Entrada
                      </Typography>
                      <TimeIconComponent
                        value={registryIn}
                        setValue={setRegistryIn}
                      />
                    </Grid>

                    <Grid item xs={6} container direction="column">
                      <Typography
                        style={{
                          fontFamily: "Roboto",
                          fontSize: 14,
                          textAlign: "left",
                          color: "#fff",
                        }}
                      >
                        Salida
                      </Typography>
                      <TimeIconComponent
                        value={registryOut}
                        setValue={setRegistryOut}
                      />
                    </Grid>
                  </Grid>

                  <div
                    style={{
                      width: "100%",
                      height: 1,
                      background: "#707070",
                      marginTop: 15,
                      padding: 0,
                    }}
                  ></div>

                  <Button
                    onClick={handleRegisterAssistance}
                    disabled={loadingAssistance}
                    style={{
                      backgroundColor: "#212429",
                      textTransform: "none",
                      marginTop: 8,
                    }}
                  >
                    {!loadingAssistance ? (
                      <Typography
                        style={{
                          fontFamily: "Roboto",
                          fontSize: 16,
                          textAlign: "left",
                          color: "#fff",
                        }}
                      >
                        Registrar
                      </Typography>
                    ) : (
                      <CircularProgress style={{ color: "white" }} size={24} />
                    )}
                  </Button>
                </Grid>
              </Popover>
            </Grid>
          </Grid>

          <Grid item xs={3} container direction="column">
            <Grid container direction="row" justifyContent="flex-end">
              {data?.evento.length > 0 &&
                data?.evento?.map((_attendance, index) => (
                  <Grid
                    item
                    // key={index}
                    key={_attendance?._id}
                    style={{
                      marginBottom: 8,
                      margin: "center",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      handleOpenMenuEvent(e);
                      setSelectUpdateEvent(_attendance);
                      setSelectedEvent(_attendance?.tipoEvento);
                      setRegistryEvent(_attendance?.datetime);
                    }}
                  >
                    <CardAttendanceEventsExtra data={_attendance} />
                  </Grid>
                ))}

              {ValidatePermissions("crear") && (
                <Grid
                  item
                  onClick={handleOpenEvent}
                  style={{
                    backgroundColor: "#2d3034",
                    border: "dashed 2px #707070",
                    width: 170,
                    height: 40,
                    borderRadius: 8,
                    cursor: "pointer",
                    marginTop: 30,
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                      height: 36,
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faPlus}
                      style={{
                        width: 12,
                        height: 12,
                        color: "#ac0bce",
                      }}
                    />

                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontSize: 17,
                        textAlign: "left",
                        color: "#e5e5e5",
                        marginLeft: 8,
                      }}
                    >
                      Registrar evento
                    </Typography>
                  </Grid>
                </Grid>
              )}

              <Popover
                open={openEvent}
                anchorEl={anchorElEvent}
                onClose={handleCloseEvent}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                PaperProps={{
                  style: {
                    width: 170,
                    // height: 172,
                    backgroundColor: "#212429",
                    borderRadius: 6,
                    marginTop: 10,
                  },
                }}
                disableAutoFocus={true}
                disableEnforceFocus={true}
              >
                <Grid
                  container
                  direction="column"
                  style={{ marginTop: 8, padding: 15 }}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    style={{ cursor: "pointer" }}
                    onClick={handleOpenSubEvent}
                  >
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontSize: 14,
                        textAlign: "left",
                        color: "#fff",
                        fontWeight: 500,
                      }}
                    >
                      {selectedEvent ?? "Seleccionar"}
                    </Typography>

                    <FontAwesomeIcon
                      icon={faAngleRight}
                      style={{
                        width: 11,
                        height: 16,
                        color: "#a4a4a4",
                      }}
                    />
                  </Grid>
                  <div
                    style={{
                      width: "100%",
                      height: 1,
                      background: "#707070",
                      marginTop: 15,
                      padding: 0,
                    }}
                  ></div>

                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{ marginTop: 10 }}
                  >
                    <TimeIconComponent
                      value={registryEvent}
                      setValue={setRegistryEvent}
                    />
                  </Grid>

                  <div
                    style={{
                      width: "100%",
                      height: 1,
                      background: "#707070",
                      marginTop: 15,
                      padding: 0,
                    }}
                  ></div>

                  <Button
                    onClick={handleRegisterEvent}
                    disabled={loadingEvent}
                    style={{
                      backgroundColor: "#212429",
                      textTransform: "none",
                      marginTop: 8,
                    }}
                  >
                    {!loadingEvent ? (
                      <Typography
                        style={{
                          fontFamily: "Roboto",
                          fontSize: 16,
                          textAlign: "left",
                          color: "#fff",
                        }}
                      >
                        Registrar
                      </Typography>
                    ) : (
                      <CircularProgress style={{ color: "white" }} size={24} />
                    )}
                  </Button>
                </Grid>
              </Popover>

              {/* aqui */}
              <Popover
                open={openSubEvent}
                anchorEl={anchorElSubEvent}
                onClose={handleCloseSubEvent}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                PaperProps={{
                  style: {
                    width: 170,
                    height: "auto",
                    backgroundColor: "#212429",
                    borderRadius: 6,
                    marginTop: -20,
                    marginLeft: 25,
                  },
                }}
                disableAutoFocus={true}
                disableEnforceFocus={true}
              >
                <Grid
                  container
                  direction="column"
                  style={{ marginTop: 8, padding: 15 }}
                >
                  <ScrollBars style={{ height: 150 }}>
                    <Grid container direction="column" spacing={2}>
                      {events?.map((_event, index) => (
                        <Grid
                          key={index}
                          item
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          className={classes.menu}
                          style={{
                            borderRadius: 8,
                          }}
                          onClick={() => {
                            setSelectedEvent(_event.label);
                            handleCloseSubEvent();
                          }}
                        >
                          <Typography
                            style={{
                              fontFamily: "Roboto",
                              fontSize: 14,
                              textAlign: "left",
                              color: "#fff",
                              marginTop: 6,
                            }}
                          >
                            {_event.label}
                          </Typography>
                        </Grid>
                      ))}
                    </Grid>
                  </ScrollBars>
                </Grid>
              </Popover>

              {/* new asistencia */}
              <Popover
                open={openUpdateAssistance}
                anchorEl={anchorElUpdateAssistance}
                onClose={handleCloseUpdateAssistance}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                PaperProps={{
                  style: {
                    backgroundColor: "#212429",
                    borderRadius: 6,
                    marginTop: 10,
                  },
                }}
                disableAutoFocus={true}
                disableEnforceFocus={true}
              >
                <Grid
                  container
                  direction="column"
                  style={{ marginTop: 8, padding: 15 }}
                >
                  <Grid container direction="row">
                    <Grid item xs={12} container direction="column">
                      <TimeIconComponent
                        value={registryUpdate}
                        setValue={setRegistryUpdate}
                      />
                    </Grid>
                  </Grid>

                  <div
                    style={{
                      width: "100%",
                      height: 1,
                      background: "#707070",
                      marginTop: 15,
                      padding: 0,
                    }}
                  ></div>

                  <Button
                    onClick={handleUpdateAssistance}
                    disabled={loadingAssistance}
                    style={{
                      backgroundColor: "#212429",
                      textTransform: "none",
                      marginTop: 8,
                    }}
                  >
                    {!loadingAssistance ? (
                      <Typography
                        style={{
                          fontFamily: "Roboto",
                          fontSize: 16,
                          textAlign: "left",
                          color: "#fff",
                        }}
                      >
                        Registrar
                      </Typography>
                    ) : (
                      <CircularProgress style={{ color: "white" }} size={24} />
                    )}
                  </Button>
                </Grid>
              </Popover>

              {/* x menu */}

              {(ValidatePermissions("editar") ||
                ValidatePermissions("eliminar")) && (
                <Popover
                  open={openMenuAssistance}
                  anchorEl={anchorElMenuAssistance}
                  onClose={handleCloseMenuAssistance}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  PaperProps={{
                    style: {
                      width: 140,
                      height: 90,
                      backgroundColor: "#212429",
                      borderRadius: 6,
                      marginTop: 10,
                    },
                  }}
                  disableAutoFocus={true}
                  disableEnforceFocus={true}
                >
                  <Grid
                    container
                    direction="column"
                    style={{ marginTop: 8, padding: 15 }}
                  >
                    <Grid container direction="column" spacing={4}>
                      {ValidatePermissions("editar") && (
                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                          className={classes.menu}
                          style={{
                            padding: 6,
                            cursor: "pointer",
                          }}
                          onClick={handleOpenUpdateAssistance}
                        >
                          <FontAwesomeIcon
                            icon={faPen}
                            style={{
                              width: 15,
                              height: 15,
                              color: "#fff",
                              marginLeft: 16,
                            }}
                          />
                          <Typography
                            style={{
                              fontFamily: "Roboto",
                              fontSize: 16,
                              textAlign: "left",
                              fontWeight: 500,
                              color: "#fff",
                              marginLeft: 8,
                            }}
                          >
                            Editar
                          </Typography>
                        </Grid>
                      )}

                      {ValidatePermissions("eliminar") && (
                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                          className={classes.menu}
                          style={{ padding: 6 }}
                          onClick={(e) => {
                            handleCloseMenuAssistance(e);
                            setDeleteDialog(!deleteDialog);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faTrash}
                            style={{
                              width: 15,
                              height: 15,
                              color: "#fff",
                              marginLeft: 16,
                            }}
                          />
                          <Typography
                            style={{
                              fontFamily: "Roboto",
                              fontSize: 16,
                              textAlign: "left",
                              fontWeight: 500,
                              color: "#fff",
                              marginLeft: 8,
                            }}
                          >
                            Eliminar
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Popover>
              )}

              {/* x menu event*/}
              <Popover
                open={openMenuEvent}
                anchorEl={anchorElMenuEvent}
                onClose={handleCloseMenuEvent}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                PaperProps={{
                  style: {
                    width: 140,
                    height: 90,
                    backgroundColor: "#212429",
                    borderRadius: 6,
                    marginTop: 10,
                  },
                }}
                disableAutoFocus={true}
                disableEnforceFocus={true}
              >
                <Grid
                  container
                  direction="column"
                  style={{ marginTop: 8, padding: 15 }}
                >
                  <Grid container direction="column" spacing={4}>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      className={classes.menu}
                      style={{
                        padding: 6,
                        cursor: "pointer",
                      }}
                      onClick={handleOpenUpdateEvent}
                    >
                      <FontAwesomeIcon
                        icon={faPen}
                        style={{
                          width: 15,
                          height: 15,
                          color: "#fff",
                          marginLeft: 16,
                        }}
                      />
                      <Typography
                        style={{
                          fontFamily: "Roboto",
                          fontSize: 16,
                          textAlign: "left",
                          fontWeight: 500,
                          color: "#fff",
                          marginLeft: 8,
                        }}
                      >
                        Editar
                      </Typography>
                    </Grid>

                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      className={classes.menu}
                      style={{ padding: 6 }}
                      onClick={(e) => {
                        handleCloseMenuEvent(e);
                        setDeleteEventDialog(!deleteEventDialog);
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faTrash}
                        style={{
                          width: 15,
                          height: 15,
                          color: "#fff",
                          marginLeft: 16,
                        }}
                      />
                      <Typography
                        style={{
                          fontFamily: "Roboto",
                          fontSize: 16,
                          textAlign: "left",
                          fontWeight: 500,
                          color: "#fff",
                          marginLeft: 8,
                        }}
                      >
                        Eliminar
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Popover>

              {/* update event */}
              <Popover
                open={openUpdateEvent}
                anchorEl={anchorElUpdateEvent}
                onClose={handleCloseUpdateEvent}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                PaperProps={{
                  style: {
                    width: 170,
                    // height: 172,
                    backgroundColor: "#212429",
                    borderRadius: 6,
                    marginTop: 10,
                  },
                }}
                disableAutoFocus={true}
                disableEnforceFocus={true}
              >
                <Grid
                  container
                  direction="column"
                  style={{ marginTop: 8, padding: 15 }}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    style={{ cursor: "pointer" }}
                    onClick={handleOpenSubEvent}
                  >
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontSize: 14,
                        textAlign: "left",
                        color: "#fff",
                        fontWeight: 500,
                      }}
                    >
                      {selectedEvent ?? "Seleccionar"}
                    </Typography>

                    <FontAwesomeIcon
                      icon={faAngleRight}
                      style={{
                        width: 11,
                        height: 16,
                        color: "#a4a4a4",
                      }}
                    />
                  </Grid>
                  <div
                    style={{
                      width: "100%",
                      height: 1,
                      background: "#707070",
                      marginTop: 15,
                      padding: 0,
                    }}
                  ></div>

                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{ marginTop: 10 }}
                  >
                    <TimeIconComponent
                      value={registryEvent}
                      setValue={setRegistryEvent}
                    />
                  </Grid>

                  <div
                    style={{
                      width: "100%",
                      height: 1,
                      background: "#707070",
                      marginTop: 15,
                      padding: 0,
                    }}
                  ></div>

                  <Button
                    onClick={handleUpdateEvent}
                    disabled={loadingEvent}
                    style={{
                      backgroundColor: "#212429",
                      textTransform: "none",
                      marginTop: 8,
                    }}
                  >
                    {!loadingEvent ? (
                      <Typography
                        style={{
                          fontFamily: "Roboto",
                          fontSize: 16,
                          textAlign: "left",
                          color: "#fff",
                        }}
                      >
                        Registrar
                      </Typography>
                    ) : (
                      <CircularProgress style={{ color: "white" }} size={24} />
                    )}
                  </Button>
                </Grid>
              </Popover>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogDeleteAttendance
        setOpendialog={setDeleteDialog}
        openDialog={deleteDialog}
        data={data}
        selectUpdate={selectUpdate}
        setSelectUpdate={setSelectUpdate}
      />

      <DialogDeleteEvent
        setOpendialog={setDeleteEventDialog}
        openDialog={deleteEventDialog}
        data={data}
        selectUpdateEvent={selectUpdateEvent}
        setSelectUpdateEvent={setSelectUpdateEvent}
      />
    </Dialog>
  );
}
