/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, TextField, Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { SetScreenProfile } from "../../../actions/screen";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Lottie from "react-lottie";
import UserFirmasAnimation from "../../../animations/users_firmas.json";
import Fail from "../../../animations/lottie_fail.json";
import axios from "axios";
import axiosInstance from "../../../utils/axiosInstance";
import { ipServer } from "../../../config/vars";
import { UploadDocuments } from "../../../actions/file";
import { useSnackbar } from "notistack";

import { faTimes, faUndoAlt } from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles((theme) => ({
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: "bold",
    textAlign: "left",
    color: "#cdcece",
  },
  textFieldCustom: {
    width: "350px",
    color: "#676767",
    border: "solid 1px #c5c5c5",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
  },
  divImg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "356px",
    height: "290px",
    borderRadius: "8px",
    border: "solid 1px #707070",
    backgroundColor: "#212429",
    cursor: "pointer",
  },
  trackVertical: {
    height: "95%",
    right: 0,
    width: "10px !important",
  },
  thumbVertical: {
    backgroundColor: "#2d3034",
    height: "100%",
    right: 0,
    width: "8px !important",
    borderRadius: "8px",
  },
  photo: {
    maxWidth: "164px",
    maxHeight: "164px",
    borderRadius: "8px",
    aspectRatio: 1,
    objectFit: "cover",
  },
  img_profile: {
    width: "56px",
    height: "56px",
    objectFit: "contain",
    borderRadius: "8px",
  },
}));

export default function DialogUnsavedEmployee(props) {
  ///console.log("PROOOOPS.idConfirm:...........: ", props.idConfirm);
  ///const idconfirmFAKE = "62aa59b6245d608d7391795c";
  ///const idconfirmFAKE = "62aa59b6245d608d73917";

  const classes = useStyles();
  const dispatch = useDispatch();
  const { files } = useSelector((state) => state.file);
  const [disableButton, setDisableButton] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleOpenDialog = () => {
    props.setOpenDialogUnsavedEmployee(!props.openDialogUnsavedEmployee);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: UserFirmasAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: Fail,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div>
      <Dialog
        open={props.openDialogUnsavedEmployee}
        onClose={handleOpenDialog}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            width: "444px",
            height: "776px",
            backgroundColor: "#171a1e",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              style={{ position: "absolute" }}
            >
              <Button onClick={handleOpenDialog}>
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{
                    width: "16px",
                    height: "16px",
                    color: "#fff",
                  }}
                />
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              style={{ marginTop: "90px" }}
            >
              <Lottie options={defaultOptions2} height={168} width={167} />

              <Typography
                style={{
                  fontSize: "18px",
                  textAlign: "center",
                  color: "#fff",
                  marginTop: "25px",
                }}
              >
                {props.errorData.title}
                {/* Error al guardar empleado */}
              </Typography>

              <Typography
                style={{
                  fontSize: "14px",
                  textAlign: "center",
                  color: "#b9b9b9",
                  marginTop: "12px",
                }}
              >
                {props.errorData.description}
                {/* Parece ser que ocurrió un error al subir empleado */}
              </Typography>
              <Button
                onClick={props.saveEmployee}
                style={{
                  width: "149px",
                  height: "32px",
                  borderRadius: "8px",
                  backgroundColor: "#2d3034",
                  marginTop: "44px",
                  textTransform: "none",
                }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <FontAwesomeIcon
                    icon={faUndoAlt}
                    style={{
                      width: "13.4px",
                      height: "13.4px",
                      color: "#d233ff",
                      marginLeft: "14.9px",
                    }}
                  />

                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      textAlign: "left",
                      color: "#fff",
                      marginRight: "20px",
                    }}
                  >
                    Reintentar
                  </Typography>
                </Grid>
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
