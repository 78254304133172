/*eslint-disable*/
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { addFile, clearFiles } from "../../../../actions/file";
import Lottie from "react-lottie";
import LottieNotFound from "../../../../animations/lottie_not_found.json";
import ScrollBars from "../../../../common_components/ScrollBars";
import { ValidatePermissions } from "../../../../utils/permissions";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    // height: "100%",
    // backgroundColor: "red",
  },
  gridFile: {
    padding: theme.spacing(3),
    width: "100%",
    // height: "320px",
    borderRadius: "16px",
    backgroundColor: "#2d3034",
  },
}));

export default function CardFileSubcontractor(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { data } = props;

  const files = [
    ...new Map(props.files?.map((item) => [item["filename"], item])).values(),
  ];

  const handleEdit = () => {
    history.push(`/dashboard/obras/editar/${data?._id}`);
    // dispatch(clearFiles());
    // props.files?.map((file, index) =>
    //   dispatch(
    //     addFile({
    //       name: file.filename,
    //       url: file.file,
    //       originalName: file.originalName,
    //       process: "db",
    //     })
    //   )
    // );
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LottieNotFound,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className={classes.divMain}>
      <Grid item xs={12} className={classes.gridFile}>
        <Grid container direction="row-reverse">
          <Grid
            item
            xs={12}
            lg={1}
            container
            direction="row"
            justifyContent="flex-end"
          >
            {ValidatePermissions("editar") ? (
              <Typography
                onClick={handleEdit}
                style={{
                  fontFamily: "Roboto",
                  fontSize: 18,
                  fontWeight: 500,
                  color: "#d233ff",
                  cursor: "pointer",
                }}
              >
                Editar
              </Typography>
            ) : (
              <div></div>
            )}
          </Grid>
          <Grid item xs={11}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 22,
                fontWeight: 500,
                color: "#fff",
              }}
            >
              Expediente obra
            </Typography>
          </Grid>
        </Grid>

        {files.length > 0 ? (
          <ScrollBars style={{ height: 165 }}>
            <Grid container direction="row" style={{ marginTop: 20 }}>
              {files &&
                files.map((file, index) => (
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="space-between"
                    style={{ marginTop: 10, paddingRight: 20 }}
                    key={index}
                  >
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontSize: 18,
                        color: "#c1c1c1",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        maxWidth: "50%",
                      }}
                    >
                      {file.filename}
                    </Typography>

                    <Typography
                      onClick={() => saveAs(file.file, file.originalName)}
                      style={{
                        fontFamily: "Roboto",
                        fontSize: 18,
                        fontWeight: 500,
                        color: "#fff",
                        cursor: "pointer",
                        textDecorationLine: "underline",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        maxWidth: "50%",
                      }}
                    >
                      {file.originalName}
                    </Typography>
                  </Grid>
                ))}
            </Grid>
          </ScrollBars>
        ) : (
          <Grid container direction="column" style={{ margin: "auto" }}>
            <Lottie options={defaultOptions} height={149} width={164} />
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 16,
                textAlign: "center",
                color: "#c1c1c1",
              }}
            >
              Sin expediente de archivos
            </Typography>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
