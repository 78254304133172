/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faMapMarkerAlt,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles((theme) => ({
  gridMain: {
    height: "93px",
    padding: theme.spacing(1),
    borderRadius: 12,
    backgroundColor: "#2d3034",
  },
  photo: {
    maxWidth: "60px",
    maxHeight: "60px",
    borderRadius: "16px",
    aspectRatio: 1,
    objectFit: "cover",
  },
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 500,
    textAlign: "left",
    color: "#fff",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    maxWidth: "90%",
  },
  textFieldLetter2: {
    fontFamily: "Roboto",
    fontSize: 14,
    textAlign: "left",
    color: "#acacac",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    maxWidth: "90%",
  },
  textFieldLetter3: {
    fontFamily: "Roboto",
    fontSize: 14,
    textAlign: "left",
    color: "#fff",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    maxWidth: "90%",
  },
  gridMargin: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
  gridMargin2: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 20,
    },
  },
}));

export default function InfoEmployee(props) {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      className={classes.gridMargin}
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={8}
        lg={8}
        xl={8}
        container
        className={classes.gridMain}
      >
        <Grid
          item
          xs={6}
          sm={6}
          md={2}
          lg={2}
          xl={2}
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          // style={{ marginRight: "10px" }}
        >
          <img
            src={props.employeeData.foto}
            className={classes.photo}
            alt="photo"
          />
        </Grid>

        <Grid
          item
          xs={6}
          sm={6}
          md={3}
          lg={3}
          xl={3}
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Typography className={classes.textFieldLetter}>
            {props.employeeData.nombre}
          </Typography>
          <Typography className={classes.textFieldLetter2}>
            {props.employeeData.puesto}
          </Typography>
          <Typography className={classes.textFieldLetter3}>
            {props.employeeData.subcontratista}
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          lg={5}
          xl={5}
          className={classes.gridMargin}
        >
          <Grid item container direction="row" justifyContent="flex-start">
            <FontAwesomeIcon
              icon={faBuilding}
              style={{
                width: "14px",
                height: "18px",
                color: "#d233ff",
              }}
            />
            <Grid
              style={{
                width: "10px",
              }}
            />
            <Typography className={classes.textFieldLetter}>
              {props.employeeData.proyectoObra}
            </Typography>
          </Grid>

          <Grid item container direction="row" justifyContent="flex-start">
            <FontAwesomeIcon
              icon={faMapMarkerAlt}
              style={{
                width: "14px",
                height: "18px",
                color: "#d233ff",
              }}
            />
            <Grid
              style={{
                width: "10px",
              }}
            />
            <Typography className={classes.textFieldLetter}>
              {props.employeeData.domicilioObra}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        lg={4}
        xl={4}
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
        className={classes.gridMargin}
      >
        {props.reportData.idReport == "" ? (
          <div></div>
        ) : (
          <Button
            onClick={() =>
              props.setOpenDialogDeleteReport(!props.openDialogDeleteReport)
            }
            style={{
              width: "120px",
              height: "35px",
              borderRadius: "8px",
              backgroundColor: "#2d3034",
              textTransform: "none",
              marginRight: 30,
            }}
          >
            <FontAwesomeIcon
              icon={faTrash}
              style={{
                width: "12px",
                height: "12px",
                color: "#fff",
                marginRight: "5px",
              }}
            />
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 18,
                textAlign: "left",
                color: "#fff",
              }}
            >
              Eliminar
            </Typography>
          </Button>
        )}
      </Grid>
    </Grid>
  );
}
