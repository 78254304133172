/*eslint-disable*/
import React, { Fragment, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faPlusCircle,
  faPen,
  faTrashAlt,
  faArrowAltCircleLeft,
} from "@fortawesome/free-solid-svg-icons";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ScrollBars from "../../../common_components/ScrollBars";
import useStatusSearch from "./useStatusSearch";
import axios from "axios";
import { ipServer } from "../../../config/vars";
import DialogAddNewStatus from "./DialogAddNewStatus";

const useStyles = makeStyles((theme) => ({
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: "bold",
    textAlign: "left",
    color: "#cdcece",
  },
  textFieldCustom: {
    width: "400px",
    color: "#676767",
    border: "solid 1px #c5c5c5",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
  },
  textFieldCustom2: {
    width: "350px",
    color: "#676767",
    border: "solid 1px #c5c5c5",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
  },
  divImg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "356px",
    height: "290px",
    borderRadius: "8px",
    border: "solid 1px #707070",
    backgroundColor: "#212429",
    cursor: "pointer",
  },
  divBack: {
    width: "15%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    // marginBottom: "20px",
    // backgroundColor: "blue",
  },
}));

export default function DialogAdminStatusList(props) {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [openNewDialog, setOpenNewDialog] = useState(false);
  const [status, setStatus] = useState({
    id: "",
    name: "",
    type: "trabajador_status",
  });

  const [query, setQuery] = useState({
    Type: "trabajador_status",
    Nombre: "",
    update: false,
  });
  const { books, hasMore, loading, error } = useStatusSearch(query);

  const handleOpenDialog = () => {
    props.setOpenAdminDialog(!props.openAdminDialog);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setQuery({
      ...query,
      [e.target.name]: value,
    });
  };

  const backStep = () => {
    props.setOpenAdminDialog(!props.openAdminDialog);
    props.setOpendialog(!props.openDialog);
  };

  const deleteJob = async (id) => {
    return await axios
      .delete(`${ipServer}/api/v1/rules/catalogos/${id}`, {
        headers: {
          Authorization: localStorage.getItem("JWT"),
        },
      })
      .then((response) => {
        // console.log(response.data);
        enqueueSnackbar("Puesto removido correctamente", {
          variant: "success",
        });
        // props.setOpenDialogJobsAdmin(!props.openDialogJobsAdmin);
        // props.setJobsFlag(!props.jobsFlag);
        return true;
      })
      .catch((e) => {
        enqueueSnackbar("Error al remover puesto", {
          variant: "error",
        });
        console.log(e);
        return false;
      });
  };

  const statusList = () => {
    const items = books.map((_status, index) => {
      return (
        <Grid
          key={index}
          container
          direction="row"
          justifyContent="flex-start"
          style={{
            paddingTop: "5%",
            paddingLeft: "5%",
          }}
        >
          <Grid item xs={10}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 20,
                fontWeight: "bold",
                textAlign: "left",
                color: "#fff",
              }}
            >
              {_status.name}
            </Typography>
          </Grid>

          <Grid
            item
            xs={2}
            container
            direction="row"
            justifyContent="flex-end"
            style={{
              paddingRight: "5%",
            }}
          >
            <FontAwesomeIcon
              icon={faPen}
              onClick={() => {
                setStatus({
                  ...status,
                  id: _status._id,
                  name: _status.name,
                });
                setOpenNewDialog(!openNewDialog);
                props.setOpenAdminDialog(!props.openAdminDialog);
              }}
              className={classes.iconForm}
              style={{
                width: "15px",
                height: "15px",
                color: "#fff",
                cursor: "pointer",
              }}
            />

            <Grid
              style={{
                width: "10px",
              }}
            />

            <FontAwesomeIcon
              icon={faTrashAlt}
              onClick={() => deleteJob(_status._id)}
              className={classes.iconForm}
              style={{
                width: "15px",
                height: "15px",
                color: "#fff",
                cursor: "pointer",
              }}
            />
          </Grid>
        </Grid>
      );
    });
    return items;
  };

  return (
    <Fragment>
      <Dialog
        open={props.openAdminDialog}
        onClose={handleOpenDialog}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            backgroundColor: "#171a1e",
            textColor: "#fsf",
            width: "483px",
            height: "600px",
            paddingTop: 15,
          },
        }}
      >
        <DialogTitle>
          <Grid container direction="row" justifyContent="space-between">
            <div onClick={backStep} className={classes.divBack}>
              <FontAwesomeIcon
                icon={faArrowAltCircleLeft}
                style={{
                  width: "20px",
                  height: "20px",
                  marginRight: "10px",
                  color: "#d6d6d6",
                }}
              />
            </div>

            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 22,
                textAlign: "center",
                color: "#fff",
                fontWeight: "bold",
              }}
            >
              Adminsitración de Estatus
            </Typography>

            <Button onClick={handleOpenDialog}>
              <FontAwesomeIcon
                icon={faTimes}
                style={{
                  width: "20px",
                  height: "20px",
                  color: "#fff",
                }}
              />
            </Button>
          </Grid>
          <div
            style={{
              width: "100%",
              height: 1,
              background: "#707070",
              marginTop: 8,
              padding: 0,
            }}
          ></div>
        </DialogTitle>

        <DialogContent>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item container direction="row" justifyContent="flex-end">
              <div>
                <TextField
                  placeholder="Buscar..."
                  name="Nombre"
                  size="small"
                  variant="outlined"
                  value={query?.Nombre}
                  className={classes.textFieldCustom2}
                  onChange={handleChange}
                />
                <Button
                  onClick={() => {
                    setStatus({
                      ...status,
                      id: "",
                      name: "",
                    });
                    setOpenNewDialog(!openNewDialog);
                    props.setOpenAdminDialog(!props.openAdminDialog);
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    style={{
                      width: "30px",
                      height: "30px",
                      color: "#d233ff",
                      backgroundColor: "#fff",
                      borderRadius: "50%",
                    }}
                  />
                </Button>
              </div>
            </Grid>

            <ScrollBars style={{ height: 410 }}>{statusList()}</ScrollBars>
          </Grid>
        </DialogContent>
      </Dialog>

      <DialogAddNewStatus
        openNewDialog={openNewDialog}
        setOpenNewDialog={setOpenNewDialog}
        setOpenAdminDialog={props.setOpenAdminDialog}
        openAdminDialog={props.openAdminDialog}
        status={status}
        setStatus={setStatus}
        query={query}
        setQuery={setQuery}
        update={props.update}
        setUpdate={props.setUpdate} 
      />
    </Fragment>
  );
}
