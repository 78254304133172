/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import LinearProgress from "@material-ui/core/LinearProgress";

import Lottie from "react-lottie";

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  divMain: {
    // maxWidth: 2,
  },
  card: {
    maxWidth: "100%",
    minHeight: "100%",
    padding: 5,
    borderRadius: "24px",
    cursor: "pointer",
    backgroundColor: "#2d3034",
    "&:hover": {
      background: "#3B3D40",
      boxShadow: "0 0px 16px 0 rgba(90, 90, 90, 0.2)",
    },
  },
  griCard: {
    padding: theme.spacing(4),
  },
}));

export default function CardEmpleado(props) {
  const classes = useStyles();
  const history = useHistory();
  const { animation, title, body } = props;

  const SelectedReport = () => {
    history.push(props.url);
  };

  const AnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    // rendererSettings: {
    //   preserveAspectRatio: "noScale",
    // },
  };

  return (
    <div onClick={SelectedReport}>
      <Card className={classes.card}>
        <Grid container direction="row" className={classes.griCard}>
          <Grid
            container
            direction="column"
            item
            sm={12}
            xs={12}
            md={12}
            lg={8}
            xl={8}
          >
            <Typography
              style={{
                fontFamily: "Roboto",
                textAlign: "left",
                color: "#fff",
                fontSize: 28,
                fontWeight: 500,
                marginLeft: 10,
                marginTop: 5,
              }}
            >
              {title}
            </Typography>

            <Typography
              style={{
                fontFamily: "Roboto",
                textAlign: "left",
                color: "#acacac",
                fontSize: 18,
                marginLeft: 15,
                marginTop: 5,
              }}
            >
              {body}
            </Typography>
          </Grid>

          <Grid item lg={4}>
            <Lottie
              options={AnimationOptions}
              style={{ marginLeft: 20, paddingRight: 10 }}
              height={159}
              width={175}
            />
          </Grid>
        </Grid>
      </Card>
    </div>
  );
}
