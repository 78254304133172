/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ipServer } from "../../../../config/vars";
import axios from "axios";
import MultiSelect from "../../../../common_components/Filters/MutiSelect";
import { useDispatch } from "react-redux";
import { getObra } from "../../../../actions/obras";

const useStyles = makeStyles((theme) => ({
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: "bold",
    textAlign: "left",
    color: "#cdcece",
  },
  textFieldCustom: {
    width: "276px",
    color: "#676767",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
  },
  multi: {
    color: "white",
    padding: 2,
    border: 0,
    borderRadius: "8px",
    width: 276,
    maxWidth: 276,

    "&.rmsc .dropdown-heading ": {
      backgroundColor: "white",
      color: "#676767",
      borderRadius: 5,
    },
  },
}));

export default function DialogEditSubcontratistas(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { setOpendialog, openDialog } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [subcontratistas, setSubcontratistas] = useState(null);
  const [update, setUpdate] = useState({
    contratistas: [],
  });

  const handleDialog = () => {
    setOpendialog(!openDialog);
  };

  const deleteSubcontractor = async (obraId, oldContratistas) => {
    var obras = [];
    obras.push(obraId);

    if (oldContratistas.length === 0) return true;

    return await axios
      .delete(`${ipServer}/api/v1/rules/assing`, {
        headers: {
          Authorization: localStorage.getItem("JWT"),
        },
        data: {
          proyectos: obras,
          subcontratistas: oldContratistas,
        },
      })
      .then((response) => {
        console.log("assign", response);
        return true;
      })
      .catch((e) => {
        console.log(e.response);
        return false;
      });
  };

  const assignSubcontractor = async (obraId, contratistas) => {
    var obras = [];
    obras.push(obraId);

    return await axios
      .post(
        `${ipServer}/api/v1/rules/assing`,
        {
          proyectos: obras,
          subcontratistas: contratistas,
        },
        {
          headers: {
            Authorization: localStorage.getItem("JWT"),
          },
        }
      )
      .then((response) => {
        console.log("assign", response);
        return true;
      })
      .catch((e) => {
        console.log(e);
        return false;
      });
  };

  const updateSubcontractor = async () => {
    let newContratistas = update.contratistas.map((id) => id.value);
    let oldContratistas = [];

    props.data
      ?.filter((_subcontratistas) => !_subcontratistas.oculta)
      ?.map((oldContratista) => {
        if (!newContratistas.includes(oldContratista._id))
          oldContratistas.push(oldContratista._id);
      });

    if (!(await deleteSubcontractor(props.obraId, oldContratistas))) {
      enqueueSnackbar("Error al actualizar obra", {
        variant: "error",
      });
      return;
    }

    if (!(await assignSubcontractor(props.obraId, newContratistas))) {
      enqueueSnackbar("Error al actualizar obra", {
        variant: "error",
      });
      return;
    }

    handleDialog();
    enqueueSnackbar("Obra actualizada correctamente", {
      variant: "success",
    });

    dispatch(getObra(props.obraId));
  };

  const handleQueryChange = (queryElement) => (e) => {
    const value = queryElement === "contratistas" ? e : e.target.value;
    return setUpdate((prevQuery) => {
      return { ...prevQuery, ...{ [queryElement]: value } };
    });
  };

  const getSubcontratistasList = () => {
    axios
      .get(`${ipServer}/api/v1/rules/subcontratistas`, {
        headers: { Authorization: localStorage.getItem("JWT") },
      })
      .then((response) => {
        let subcontratistas = response.data.data.filter(
          (_subcontratistas) => !_subcontratistas.oculta
        );
        subcontratistas = subcontratistas.map((object) => {
          return { label: object.empresa, value: object._id };
        });

        setSubcontratistas(subcontratistas);

        setUpdate({
          ...update,
          contratistas: subcontratistas.filter((o1) =>
            props.data?.some((o2) => o1.value === o2._id)
          ),
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (props.data != null || openDialog) {
      getSubcontratistasList();
    }
  }, [props.data, openDialog]);

  return (
    <Dialog
      open={openDialog}
      onClose={handleDialog}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        style: {
          backgroundColor: "#171a1e",
          textColor: "#fsf",
          width: "483px",
          height: "370px",
          paddingTop: 15,
        },
      }}
    >
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 20,
              textAlign: "center",
              color: "#fff",
              fontWeight: "bold",
            }}
          >
            Subcontratistas asignados
          </Typography>

          <Grid
            container
            justifyContent="flex-end"
            style={{ position: "absolute" }}
          >
            <Button onClick={handleDialog}>
              <FontAwesomeIcon
                icon={faTimes}
                style={{
                  width: "20px",
                  height: "20px",
                  color: "#fff",
                }}
              />
            </Button>
          </Grid>
        </Grid>
        <div
          style={{
            width: "100%",
            height: 1,
            background: "#707070",
            marginTop: 8,
            padding: 0,
          }}
        ></div>
      </DialogTitle>

      <DialogContent>
        <Grid container direction="column" alignItems="center">
          <Grid
            item
            container
            direction="row"
            justifyContent="flex-start"
            style={{ width: "63%" }}
          >
            <Typography
              className={classes.textFieldLetter}
              style={{ marginTop: 30 }}
            >
              Subcontratistas asignados
            </Typography>
          </Grid>

          <MultiSelect
            options={subcontratistas ? subcontratistas : []}
            value={update?.contratistas}
            onChange={handleQueryChange("contratistas")}
            className={classes.multi}
          />

          <Button
            onClick={updateSubcontractor}
            style={{
              width: "276px",
              height: "40px",
              borderRadius: "8px",
              color: "#ffffff",
              backgroundColor: "#ac0bce",
              textTransform: "none",
              marginTop: 50,
            }}
          >
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 21,
                color: "#ffffff",
              }}
            >
              Actualizar
            </Typography>
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
