/*eslint-disable*/
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  divMain: {
    maxWidth: 345,
  },
  card: {
    maxHeight: 140,
    height: 140,
    borderRadius: 16,
    backgroundColor: "#2d3034",
    padding: theme.spacing(2),
  },
  griCard: {
    padding: theme.spacing(2),
  },
  noWrap: {
    textOverflow: "ellipsis",
    wordWrap: "anywhere",
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": 3,
    "-webkit-box-orient": "vertical",
  },
}));

export default function CardObservations(props) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <Grid container direction="column">
        <Grid
          item
          xs={12}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Typography
            style={{
              fontFamily: "Roboto",
              fontWeight: 500,
              fontSize: 18,
              color: "#ffffff",
              textAlign: "left",
            }}
          >
            {props.subcontratista}
          </Typography>
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 14,
              color: "#b2b2b2",
              textAlign: "left",
            }}
          >
            {props.fecha}
          </Typography>
        </Grid>

        <Grid item>
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 14,
              color: "#b2b2b2",
              textAlign: "left",
            }}
          >
            {props.unidad}
          </Typography>
        </Grid>

        <Grid item>
          <Typography
          className={classes.noWrap}
            style={{
              fontFamily: "Roboto",
              fontSize: 12,
              color: "#fff",
              textAlign: "left",
              marginTop: 8,
              width: "90%"
            }}
          >
            {props.descripcion}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
}
