/*eslint-disable*/
import React, { Fragment, useEffect, useState, useCallback } from "react";
import {
  makeStyles,
  Drawer,
  Box,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import CardHistory from "./CardHistory";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDrawer-paper": {
      backgroundColor: "red !important",
    },
  },
  paper: {
    background: "#27292d",
  },
}));

export default function DocumentHistory(props) {
  const classes = useStyles();
  let { openHistory, setOpenHistory, data, tag } = props;

  data = data.sort((a, b) => (a.date < b.date ? 1 : -1));

  const handleOpenHistory = () => {
    setOpenHistory(!openHistory);
  };

  return (
    <Fragment key={"right"}>
      <Drawer
        classes={{ paper: classes.paper }}
        anchor={"right"}
        open={openHistory}
        BackdropProps={{ style: { backgroundColor: "white", opacity: 0 } }}
        onClose={() => setOpenHistory(false)}
      >
        <Grid container direction="column" style={{ width: 525, padding: 25 }}>
          <Grid container direction="row" justifyContent="space-between">
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 32,
                textAlign: "left",
                color: "#fff",
                fontWeight: "bold",
                marginTop: 4,
              }}
            >
              Historial documentación
            </Typography>

            <IconButton onClick={handleOpenHistory}>
              <FontAwesomeIcon
                icon={faTimes}
                style={{
                  width: 35,
                  height: 35,
                  color: "#dbdbdb",
                  //   marginLeft: -50,
                }}
              />
            </IconButton>
          </Grid>

          <Grid item>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 18,
                textAlign: "left",
                color: "#b7b7b7",
                fontWeight: 500,
                lineHeight: 1,
              }}
            >
              {tag}
              {/* Antidoping */}
            </Typography>
          </Grid>

          <Grid style={{ marginTop: 25 }}>
            {data &&
              data.map((document, index) => (
                <Grid key={index} style={{ marginTop: 15 }}>
                  <CardHistory data={document} />
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Drawer>
    </Fragment>
  );
}
