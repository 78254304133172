import { types } from "../types/types";

const initialState = {
  user: null,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.userLoad:
      return {
        ...state,
        user: action.payload,
      };

    case types.reset:
      return initialState;

    default:
      return state;
  }
};
