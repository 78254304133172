/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Button, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  mainDiv: {
    height: "100vh",
    backgroundColor: "#1d2024",
  },
  inactiveListItem: {
    width: "140px",
    height: "48px",
    marginRight: "10px",
    color: "#b4b4b4 !important",
    textTransform: "none",
    // backgroundColor: "blue",
  },
  activeListItem: {
    width: "140px",
    height: "48px",
    marginRight: "10px",
    borderRadius: "25px",
    backgroundColor: "#2d3034",
    textTransform: "none",
    "&.Mui-selected": {
      color: "white !important",
    },
    "&:hover": {
      backgroundColor: "#45494E",
    },
  },
  typografy: {
    fontFamily: "Roboto",
    fontSize: 18,
    fontWeight: 500,
    textAlign: "center",
    color: "#fff",
  },
}));

export default function TopTabComponent(props) {
  const classes = useStyles();
  const [id, setId] = useState("profile");

  const setProfileScreen = () => {
    props.setTab(1);
    setId("profile");
  };

  const setScheduleScreen = () => {
    props.setTab(2);
    setId("schedule");
  };

  const setAttendanceScreen = () => {
    props.setTab(3);
    setId("attendance");
  };

  const setReportEmployeeScreen = () => {
    props.setTab(4);
    setId("reports");
  };

  return (
    <div>
      <Grid
        item
        xs={12}
        container
        direction="row"
        style={{
          padding: "20px 0px 20px 0px",
          // backgroundColor: "red",
        }}
      >
        <Button
          onClick={setProfileScreen}
          className={
            id === "profile" ? classes.activeListItem : classes.inactiveListItem
          }
        >
          <Typography className={classes.typografy}>Perfil</Typography>
        </Button>

        {/* <Button
          onClick={setScheduleScreen}
          className={
            id === "schedule"
              ? classes.activeListItem
              : classes.inactiveListItem
          }
        >
          <Typography className={classes.typografy}>Horarios</Typography>
        </Button> 

        <Button
          onClick={setAttendanceScreen}
          className={
            id === "attendance"
              ? classes.activeListItem
              : classes.inactiveListItem
          }
        >
          <Typography className={classes.typografy}>Asistencia</Typography>
        </Button>

        <Button
          onClick={setReportEmployeeScreen}
          className={
            id === "reports" ? classes.activeListItem : classes.inactiveListItem
          }
        >
          <Typography className={classes.typografy}>Reportes</Typography>
        </Button>*/}
      </Grid>
    </div>
  );
}
