/*eslint-disable*/
import React, { Fragment } from "react";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Lottie from "react-lottie";
import CorrectAnimation from "../../../animations/lottie_correct.json";
import { useDispatch, useSelector } from "react-redux";
import UploadingAnimation from "../../../animations/uploading_files.json";
import CardUploadFiles from "./CardUploadFiles";
import Button from "@material-ui/core/Button";
import FaildAnimation from "../../../animations/lottie_fail.json";
import { faUndoAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  RetryUploadDocuments,
  updateObjectFile,
  fileModalOpen,
} from "../../../actions/file";
import ScrollBars from "../../../common_components/ScrollBars";

export default function DialogUploadDocument(props) {
  const dispatch = useDispatch();
  const { files, fileModal } = useSelector((state) => state.file);

  const handleDialog = () => {
    files.map((file) => {
      if (file?.status === "correct" || file?.progress === 100) {
        dispatch(
          updateObjectFile({
            id: file.id,
            process: "db",
          })
        );
      }
    });

    dispatch(fileModalOpen());
  };

  const handleRetry = () => {
    const retry = files.filter((file) => file.status === "failure");
    dispatch(RetryUploadDocuments(retry, props.type));
  };

  const AnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: UploadingAnimation,
    rendererSettings: {
      preserveAspectRatio: "noScale",
    },
  };

  const FailOptions = {
    loop: true,
    autoplay: true,
    animationData: FaildAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const correctAnimation = {
    loop: false,
    autoplay: true,
    animationData: CorrectAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div>
      <Dialog
        open={fileModal}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            backgroundColor: "#171a1e",
            textColor: "#fsf",
            width: "483px",
            height: "710px",
            paddingTop: 15,
          },
        }}
      >
        <DialogTitle>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 20,
                textAlign: "center",
                color: "#fff",
                fontWeight: "bold",
              }}
            >
              Subiendo archivos
            </Typography>
          </Grid>
          <div
            style={{
              width: "100%",
              height: 1,
              background: "#707070",
              marginTop: 8,
              padding: 0,
            }}
          />
        </DialogTitle>

        <DialogContent>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            {!files.filter((file) => file.status === "upload").length > 0 &&
            files.filter((file) => file.status === "failure").length > 0 ? (
              <Fragment>
                <Lottie
                  options={FailOptions}
                  style={{ marginTop: 3 }}
                  height={103}
                  width={104}
                />

                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 16,
                    textAlign: "center",
                    color: "#eee",
                    marginTop: 5,
                  }}
                >
                  Error al subir archivos
                </Typography>

                <Button
                  variant="contained"
                  onClick={() => handleRetry()}
                  style={{
                    width: "288px",
                    height: "40px",
                    borderRadius: "8px",
                    backgroundColor: "#2d3034",
                    textTransform: "none",
                    marginTop: 15,
                    marginBottom: 30,
                  }}
                >
                  <FontAwesomeIcon
                    icon={faUndoAlt}
                    style={{
                      width: "14px",
                      height: "14px",
                      color: "#d233ff",
                    }}
                  />

                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 16,
                      textAlign: "left",
                      color: "#ffffff",
                      marginLeft: 8,
                    }}
                  >
                    Reintentar subir documentos
                  </Typography>
                </Button>
              </Fragment>
            ) : files.filter((file) => file.status === "correct").length ===
              files.filter((file) => file.process !== "db").length ? (
              <Fragment>
                <Lottie
                  options={correctAnimation}
                  style={{ marginTop: 3 }}
                  height={161}
                  width={167}
                />

                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 18,
                    textAlign: "center",
                    color: "#fff",
                    marginTop: 10,
                    fontWeight: "bold",
                  }}
                >
                  Archivos guardados correctamente
                </Typography>

                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 16,
                    textAlign: "center",
                    color: "#b2b2b2",
                    marginTop: 4,
                    marginBottom: 20,
                  }}
                >
                  Expediente guardado correctamente
                </Typography>
              </Fragment>
            ) : (
              <Fragment>
                <Lottie
                  options={AnimationOptions}
                  style={{ marginTop: 3 }}
                  height={151}
                  width={123}
                />

                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 16,
                    textAlign: "center",
                    color: "#eee",
                    marginTop: 20,
                    marginBottom: 30,
                  }}
                >
                  Espera a que se suban todos los archivos
                </Typography>
              </Fragment>
            )}

            <ScrollBars style={{ height: 300 }}>
              <Grid container spacing={4} justifyContent="center">
                {files &&
                  files
                    .filter(
                      (file) =>
                        file.process === "new" || file.process === "update"
                    )
                    .map((document, index) => (
                      <Grid item key={index}>
                        <CardUploadFiles
                          tag={document.name}
                          type={props.type}
                          id={document.id}
                        />
                      </Grid>
                    ))}
              </Grid>
            </ScrollBars>
          </Grid>
          {!files.filter((file) => file.status === "upload").length > 0 && (
            <Grid container justifyContent="flex-end">
              <Button
                onClick={handleDialog}
                style={{
                  textTransform: "none",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 20,
                    color: "#fff",
                    fontWeight: "bold",
                    marginTop: 10,
                  }}
                >
                  Cerrar
                </Typography>
              </Button>
            </Grid>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
