/*eslint-disable*/
import React, { Fragment, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Grid, FormControl, Select, MenuItem } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ipServer } from "../../../config/vars";
import axios from "axios";
import MultiSelect from "../../../common_components/Filters/MutiSelect";
import Lottie from "react-lottie";
import CorrectAnimation from "../../../animations/lottie_correct.json";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "../../../utils/axiosInstance";

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: "483px",
    textColor: "#fsf",
    paddingTop: 15,
    paddingBottom: 40,
    backgroundColor: theme.palette.neutral.primary,
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 22,
    textAlign: "center",
    color: theme.palette.secondary.main,
    fontWeight: "bold",
  },
  iconClose: {
    width: "20px",
    height: "20px",
    color: theme.palette.secondary.dark,
  },
  divDivider: {
    width: "100%",
    height: 1,
    background: theme.palette.secondary.main,
    marginTop: 8,
    padding: 0,
  },
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: "bold",
    textAlign: "left",
    color: theme.palette.secondary.main,
    marginTop: 35,
  },
  textFieldCustom: {
    width: "276px",
    color: "#676767",
    borderRadius: "6px",
    // backgroundColor: "#ffffff",
  },
  formControl: {
    width: "90%",
  },
  select: {
    width: "100%",
    height: "40px",
    borderRadius: "6px",
    backgroundColor: "#fff",
  },
  multi: {
    color: "white",
    padding: 2,
    border: 0,
    borderRadius: "8px",
    "&.rmsc .dropdown-heading ": {
      backgroundColor: "white !important",
      color: "#676767",
      borderRadius: 5,
    },
  },
  button: {
    width: "400px",
    height: "40px",
    borderRadius: "8px",
    color: "#ffffff",
    backgroundColor: theme.palette.primary.main,
    textTransform: "none",
    marginTop: 50,
  },
  typoButton: {
    fontFamily: "Roboto",
    fontSize: 21,
    color: theme.palette.neutral.primary,
  },
  typoSubtitle: {
    fontFamily: "Roboto",
    fontSize: 20,
    textAlign: "center",
    color: theme.palette.secondary.main,
    fontWeight: "bold",
    marginTop: 35,
  },
  typoDescription: {
    width: "350px",
    fontFamily: "Roboto",
    fontSize: 16,
    textAlign: "center",
    color: theme.palette.secondary.light,
    marginTop: 50,
  },
}));

export default function DialogInviteUser(props) {
  const classes = useStyles();
  const { setOpendialog, openDialog, complete, setComplete, setData } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [proyectos, setProyectos] = useState(null);
  const [roles, setRoles] = useState(null);
  const [empresas, setEmpresas] = useState(null);
  const [Reload, setReload] = useState(false);
  const [disableObras, setDisableObras] = useState(true);

  const schema = Yup.object({
    email: Yup.string()
      .required("Campo Obligatorio")
      .email("Favor de ingresar un correo valido"),
    // empresa: Yup.string().required("Campo Obligatorio"),
    // rol: Yup.string().required("Campo Obligatorio"),
    // obras: Yup.array().required("Campo Obligatorio"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      // empresa: "",
      rol: "",
      // obras: [],
    },
    validationSchema: schema,
  });

  const validateData = async () => {
    return await schema.isValid(formik.values);
  };

  const handleInvite = () => {
    setOpendialog(!openDialog);

    if (Reload) {
      setTimeout(() => {
        location.reload();
      }, 1000);
    }

    setReload(false);
  };

  useEffect(() => {
    if (!openDialog) {
      setTimeout(() => {
        setComplete(false);
        setData(null);
      }, 200);
    }
  }, [openDialog]);

  useEffect(() => {
    if (!!props.data && complete) {
      reenviar(props.data);
    }
  }, [props.data]);

  const correctAnimation = {
    loop: false,
    autoplay: true,
    animationData: CorrectAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const reenviar = async (data) => {
    return await axios
      .post(
        `${ipServer}/api/v1/security/user/resendinvitation/`,
        {
          Email: data.Email,
        },
        {
          headers: {
            Authorization: localStorage.getItem("JWT"),
          },
        }
      )
      .then((response) => {
        return true;
      })
      .catch((e) => {
        console.log(e);
        return false;
      });
  };

  const invite = async (data, obras) => {
    return await axiosInstance()
      .post(`/api/v1/security/user/invite/`, {
        Email: data.email,
        RoleId: data.rol,
        Role_id: data.rol,
        // SubcontractorId: data.empresa,
        // ObrasId: obras,
      })
      .then((response) => {
        // console.log(response.data);
        return true;
      })
      .catch((e) => {
        console.log(e.response);
        if (e.response.data.errors == "User already exist") {
          enqueueSnackbar("El correo ya fue registrado por alguna empresa.", {
            variant: "error",
          });
        }
        return false;
      });
  };

  const inviteUser = async () => {
    let projects = [];
    // formik.values.obras.map((subcontractor, index) => {
    //   projects.push(subcontractor.value);
    // });

    // if (projects.length === 0) {
    //   enqueueSnackbar("Favor de seleccionar al menos una obra", {
    //     variant: "warning",
    //   });
    //   return;
    // }

    if (!(await validateData())) {
      enqueueSnackbar("Favor de llenar todos los campos", {
        variant: "warning",
      });
      return;
    }

    if (await invite(formik.values, projects)) {
      enqueueSnackbar("Invitacion enviada correctamente", {
        variant: "success",
      });

      setComplete(true);
      setReload(true);
    } else {
      enqueueSnackbar("Error al enviar invitacion", {
        variant: "error",
      });
    }
  };

  const handleQueryChange = (queryElement) => (e) => {
    formik.setFieldValue(queryElement, e);
  };

  const getProyectsList = () => {
    const empresa = empresas?.find(
      (empresa) => empresa.id === formik.values.empresa
    );
    axios
      .get(
        `${ipServer}/api/v1/rules/subcontratistas/${formik.values.empresa}/proyectos`,
        {
          headers: { Authorization: localStorage.getItem("JWT") },
        }
      )
      .then((response) => {
        const proyects = response.data.data.map((object) => {
          return { label: object.proyecto, value: object._id };
        });
        setProyectos(proyects);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getRolesList = () => {
    axios
      .get(`${ipServer}/api/v1/security/role/`, {
        headers: { Authorization: localStorage.getItem("JWT") },
      })
      .then((response) => {
        let result = response.data.data.map((object) => {
          return { name: object.RoleName, id: object._id };
        });

        // result = empresas.find(
        //   (empresa) => empresa.id === formik.values.empresa
        // ).padre
        //   ? result.filter((rol) => rol.name !== "Administrador Subcontratista")
        //   : result.filter((rol) => rol.name === "Administrador Subcontratista");

        setRoles(result);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // const getEmpresasList = () => {
  //   var params = new URLSearchParams();
  //   params.append("showoculta", true);

  //   axiosInstance()
  //     .get(`/api/v1/rules/subcontratistas`, params)
  //     .then((response) => {
  //       console.log(response.data);
  //       let result = response.data.data.map((object) => {
  //         return {
  //           name: object.empresa,
  //           id: object._id,
  //           padre: object.empresaPadre ? object.empresaPadre : false,
  //           ocultar: object.oculta ? object.oculta : false,
  //         };
  //       });

  //       result = result.filter(
  //         (empresa) => empresa.name != "Sin subcontratista"
  //       );
  //       setEmpresas(result);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };

  // useEffect(() => {
  //   getEmpresasList();
  // }, []);

  useEffect(() => {
    // if (formik.values.empresa != "") {
    //   getProyectsList();
    getRolesList();
    // formik.setFieldValue("email", "taylor.gonzalez+1@knesysplus.com");
    // formik.setFieldValue("rol", "");
    // formik.setFieldValue("obras", []);
    // }
  }, []);

  const rolesList = () => {
    const items = roles.map((role, index) => {
      return (
        <MenuItem key={index} value={role.id}>
          &ensp;{role.name}
        </MenuItem>
      );
    });
    return items;
  };

  // useEffect(() => {
  //   const { rol } = formik.values;
  //   if (rol != "") {
  //     const admin = roles.find((x) => x.id == rol);
  //     if (admin && admin.name == "Administrador") {
  //       setDisableObras(true);
  //       formik.setFieldValue("obras", proyectos);
  //     } else {
  //       setDisableObras(false);
  //     }
  //   }
  // }, [formik.values]);

  const empresasList = () => {
    const items = empresas.map((empresa, index) => {
      return (
        <MenuItem key={index} value={empresa.id}>
          &ensp;{empresa.name}
        </MenuItem>
      );
    });
    return items;
  };

  return (
    <Dialog
      open={openDialog}
      aria-labelledby="form-dialog-title"
      onClose={handleInvite}
      PaperProps={{ classes: { root: classes.dialog } }}
    >
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Typography className={classes.title}>
            Invitación de usuario
          </Typography>

          <Grid
            container
            justifyContent="flex-end"
            style={{ position: "absolute" }}
          >
            <Button onClick={handleInvite}>
              <FontAwesomeIcon icon={faTimes} className={classes.iconClose} />
            </Button>
          </Grid>
        </Grid>
        <div className={classes.divDivider} />
      </DialogTitle>

      <DialogContent>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          {!complete ? (
            <Fragment>
              <Grid>
                <Typography className={classes.textFieldLetter}>
                  Email
                </Typography>
                <TextField
                  name="email"
                  size="small"
                  variant="outlined"
                  className={classes.textFieldCustom}
                  style={{ width: "400px" }}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.email && formik.errors.email ? (
                  <Typography style={{ color: "#FF1717", marginBottom: -20 }}>
                    {formik.errors.email}
                  </Typography>
                ) : null}

                {/* <Typography className={classes.textFieldLetter}>
                  Empresa
                </Typography>
                <Select
                  name="empresa"
                  inputProps={{ "aria-label": "Without label" }}
                  className={classes.select}
                  value={formik.values.empresa}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    &ensp;Obligatorio
                  </MenuItem>
                  {empresas ? empresasList() : <MenuItem />}
                </Select>
                {formik.touched.empresa && formik.errors.empresa ? (
                  <Typography style={{ color: "#FF1717", marginBottom: -20 }}>
                    {formik.errors.empresa}
                  </Typography>
                ) : null} */}

                <Typography className={classes.textFieldLetter}>Rol</Typography>
                <Select
                  name="rol"
                  inputProps={{ "aria-label": "Without label" }}
                  className={classes.select}
                  value={formik.values.rol}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  displayEmpty
                  // disabled={formik.values.empresa === ""}
                >
                  <MenuItem value="" disabled>
                    &ensp;Obligatorio
                  </MenuItem>
                  {roles ? rolesList() : <MenuItem />}
                </Select>
                {formik.touched.rol && formik.errors.rol ? (
                  <Typography style={{ color: "#FF1717", marginBottom: -20 }}>
                    {formik.errors.rol}
                  </Typography>
                ) : null}

                {!disableObras && (
                  <Fragment>
                    <Typography
                      className={classes.textFieldLetter}
                      style={{ marginTop: 35 }}
                    >
                      Obras asignadas
                    </Typography>

                    <MultiSelect
                      disabled={formik.values.empresa === ""}
                      options={proyectos ? proyectos : []}
                      value={formik.values.obras}
                      onChange={handleQueryChange("obras")}
                      className={classes.multi}
                    />
                    {formik.touched.obras && formik.errors.obras ? (
                      <Typography style={{ color: "#FF1717" }}>
                        {formik.errors.obras}
                      </Typography>
                    ) : null}
                  </Fragment>
                )}
              </Grid>

              <Button onClick={inviteUser} className={classes.button}>
                <Typography className={classes.typoButton}>Enviar</Typography>
              </Button>
            </Fragment>
          ) : (
            <Fragment>
              <Grid>
                <Lottie
                  options={correctAnimation}
                  style={{ marginTop: 80 }}
                  height={143}
                  width={171}
                />
              </Grid>

              <Typography className={classes.typoSubtitle}>
                Invitación enviada correctamente
              </Typography>

              <Typography className={classes.typoDescription}>
                {`La invitación ha sido enviada correctamente al correo
                ${props.data ? props.data.Email : formik.values.email}`}
              </Typography>
            </Fragment>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
