/*eslint-disable*/
import React from "react";
import Chart from "chart.js/auto";
import { Doughnut } from "react-chartjs-2";

export const DoughnutChart = ({ chartData }) => {
  return (
    <div>
      <Doughnut
        data={chartData}
        options={{
          responsive: true,
          plugins: {
            legend: {
              display: true,
              position: "right",
            },
            title: {
              display: false,
              text: "Chart.js Doughnut Chart",
            },
          },
        }}
        style={{
          width: "280px",
        }}
      />
    </div>
  );
};
