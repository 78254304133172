import React from "react";
import { Grid, Typography, TextField, makeStyles } from "@material-ui/core";
import { useField, Field, getIn } from "formik";
import { Fragment } from "react";

const useStyles = makeStyles((theme) => ({
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: "bold",
    textAlign: "left",
    color: "#cdcece",
  },
  textFieldCustom: {
    width: "100%",
    color: "#676767",
    borderRadius: 6,
    backgroundColor: "#ffffff",
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
}));

export const TextFieldCustom = ({ label, errors, touched, ...props }) => {
  const [field] = useField(props);
  const classes = useStyles();

  const getErrors = (name) => {
    if (errors !== {}) {
      return getIn(touched, name) && getIn(errors, name) ? true : false;
    }
  };

  const getHelperText = (name) => {
    if (errors !== {}) {
      return getIn(touched, name) && getIn(errors, name);
    }
  };

  return (
    <Fragment>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <Typography className={classes.textFieldLetter}>{label}</Typography>
        <Field
          {...field}
          as={TextField}
          size="small"
          variant="outlined"
          className={classes.textFieldCustom}
        />
        {getErrors(props.name) && (
          <Typography
            style={{ color: "#FF1717", marginTop: 5, marginBottom: -27 }}
          >
            {getHelperText(props.name)}
          </Typography>
        )}
      </Grid>
    </Fragment>
  );
};
