/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, TextField, Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { SetScreenProfile } from "../../../actions/screen";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Lottie from "react-lottie";
import UserFirmasAnimation from "../../../animations/users_firmas.json";
import WorkersTechtonAnimation from "../../../animations/workers_techton.json";
import {
  faTimes,
  faSearch,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles((theme) => ({
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: "bold",
    textAlign: "left",
    color: "#cdcece",
  },
  textFieldCustom: {
    width: "350px",
    color: "#676767",
    border: "solid 1px #c5c5c5",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
  },
  divImg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "356px",
    height: "290px",
    borderRadius: "8px",
    border: "solid 1px #707070",
    backgroundColor: "#212429",
    cursor: "pointer",
  },
  trackVertical: {
    height: "95%",
    right: 0,
    width: "10px !important",
  },
  thumbVertical: {
    backgroundColor: "#2d3034",
    height: "100%",
    right: 0,
    width: "8px !important",
    borderRadius: "8px",
  },
  photo: {
    maxWidth: "164px",
    maxHeight: "164px",
    borderRadius: "8px",
    aspectRatio: 1,
    objectFit: "cover",
  },
  img_profile: {
    width: "56px",
    height: "56px",
    objectFit: "contain",
    borderRadius: "8px",
  },
}));

export default function DialogGeneratedSignature(props) {
  const classes = useStyles();

  const handleOpenDialog = () => {
    props.setOpenDialogGeneratedSignature(!props.openDialogGeneratedSignature);
  };

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    noKeyboard: true,
    accept: "image/*",
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      acceptedFiles.map((file) => processImage(file));
    },
    // noClick: true
  });

  const processImage = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let fileURL = window.URL.createObjectURL(file);
      props.setUserData({
        ...props.userData,
        foto: file,
        fotoCache: fileURL,
        fotoReq: false,
      });
      props.setOpenDialogChooseSign(!props.openDialogChooseSign);
    };
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: UserFirmasAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: WorkersTechtonAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const img = `https://upload.wikimedia.org/wikipedia/commons/c/c1/Lionel_Messi_20180626.jpg`;

  return (
    <div>
      <Dialog
        open={props.openDialogGeneratedSignature}
        onClose={handleOpenDialog}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            width: "483px",
            height: "562px",
            backgroundColor: "#171a1e",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                lineHeight: 1.3,
                textAlign: "left",
                color: "#fff",
              }}
            >
              Firma generada
            </Typography>

            <Grid
              container
              justifyContent="flex-end"
              style={{ position: "absolute" }}
            >
              <Button onClick={handleOpenDialog}>
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{
                    width: "16px",
                    height: "16px",
                    color: "#fff",
                  }}
                />
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>
        <div
          style={{
            width: "482px",
            height: 0,
            border: "solid 1px #707070",
          }}
        ></div>

        <DialogContent>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              style={{
                marginTop: "63px",
                marginBottom: "13px",
              }}
            >
              <img src={img} className={classes.photo} alt="photo" />
            </Grid>

            <Typography
              style={{
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: 1.31,
                textAlign: "left",
                color: "#fff",
              }}
            >
              Javier Medina Cazarez
            </Typography>
            <Typography
              style={{
                fontSize: "14px",
                lineHeight: 1.36,
                textAlign: "left",
                color: "#acacac",
                marginBottom: "66px",
              }}
            >
              javiermedinacazares@gmail.com
            </Typography>
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: 1.36,
                textAlign: "left",
                color: "#fff",
              }}
            >
              Fecha
            </Typography>

            <Typography
              style={{
                fontSize: "14px",
                lineHeight: 1.36,
                textAlign: "left",
                color: "#acacac",
                marginBottom: "39px",
              }}
            >
              23/05/2022 01:33
            </Typography>
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: 1.36,
                textAlign: "left",
                color: "#fff",
              }}
            >
              Realizada por usuario
            </Typography>
            <Typography
              style={{
                fontSize: "14px",
                lineHeight: 1.36,
                textAlign: "left",
                color: "#acacac",
                marginBottom: "66px",
              }}
            >
              javiermedinacazares@gmail.com
            </Typography>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
