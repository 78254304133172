/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Select, MenuItem } from "@material-ui/core";
import SmallCard from "./SmallCard";
import RecognitionCard from "./RecognitionCard";
import MediumCard from "./MediumCard";
import MediumCardImss from "./MediumCardImss";
import BigCard from "./BigCard";
import BigCardGraphics from "./BigCardGraphics";
import BigCardDoughnut from "./BigCardDoughnut";
import CardInOut from "./CardInOut";
import photox from "../../../images/profile@2x.png";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    padding: 15,
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 28,
    fontWeight: 600,
    textAlign: "left",
    color: "#ffffff",
  },
  subtitle: {
    fontFamily: "Roboto",
    fontSize: 18,
    lineHeight: 1.33,
    textAlign: "left",
    color: "#fff",
    marginBottom: 10,
  },
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 16,
    textAlign: "left",
    color: "#dedcdc",
  },
  select: {
    width: "212px",
    height: "40px",
    borderRadius: 8,
    backgroundColor: "#27292d",
    "& .MuiInputBase-input": {
      color: "#acacac",
    },
    "& .MuiSelect-icon": {
      color: "#acacac",
    },
  },
}));

export default function TodayScreen(props) {
  const classes = useStyles();

  return (
    <Grid container direction="row">
      <Grid
        item
        xs={12}
        spacing={3}
        container
        direction="row"
        justifyContent="flex-start"
        style={{
          marginBottom: 10,
        }}
      >
        <Grid item>
          <SmallCard
            title={"Empleados activos"}
            icon={"fa-clock"}
            active={"empleados"}
            number={150}
            new={5}
          />
        </Grid>

        <Grid item>
          <SmallCard
            title={"Número de empresas"}
            icon={"fa-suitcase"}
            active={"empresas"}
            number={12}
            new={1}
          />
        </Grid>

        <Grid item>
          <RecognitionCard
            title={"Número de reconocimientos"}
            icon={"fa-mobile-screen-button"}
            active={"reconocimientos"}
            number={"230,000"}
          />
        </Grid>

        <Grid item>
          <SmallCard
            title={"Reportes de conducta"}
            icon={"fa-circle-exclamation"}
            active={"reporte"}
            number={1}
            new={1}
          />
        </Grid>

        <Grid item>
          <SmallCard
            title={"Empleados sin IMSS"}
            icon={"fa-circle-exclamation"}
            active={"empleado"}
            number={1}
            new={1}
          />
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        spacing={1}
        container
        direction="row"
        justifyContent="flex-start"
      >
        <Grid item>
          <MediumCardImss
            // urlPhoto={photo}
            title={"Empleados sin IMSS en obra"}
            number={33}
            active={"Empleados"}
          />
        </Grid>

        <Grid item>
          <MediumCard
            // urlPhoto={photo}
            title={"Empleados sin registro de entrada"}
            number={54}
            active={"Empleados"}
          />
        </Grid>

        <Grid item>
          <BigCard
            title={"Empleados en obra"}
            subtitle={"Contabiliza los empleados que se encuentran en tu obra"}
          />
        </Grid>

        <Grid item>
          <BigCardGraphics
            title={"Reconocimientos"}
            subtitle={"26 de noviembre"}
          />
        </Grid>

        <Grid item>
          <CardInOut
            title={"Ultimas entradas"}
            urlPhoto={photox}
            name={"Javier Medina Cazares"}
            role={"Software Engineer"}
            hour={"17:20"}
          />
        </Grid>

        <Grid item>
          <CardInOut
            title={"Ultimas salidas"}
            urlPhoto={photox}
            name={"Javier Medina Cazares"}
            role={"Software Engineer"}
            hour={"17:20"}
          />
        </Grid>

        <Grid item>
          <BigCardGraphics
            title={"Número de salidas"}
            subtitle={"26 de noviembre"}
          />
        </Grid>

        <Grid item>
          <BigCardGraphics
            title={"Número de entradas"}
            subtitle={"26 de noviembre"}
          />
        </Grid>

        <Grid item>
          <BigCardDoughnut
            title={"Estatus IMSS empleados"}
            subtitle={"26 de noviembre"}
          />
        </Grid>

        <Grid item>
          <BigCardGraphics
            title={"Estatus empleados"}
            subtitle={"26 de noviembre"}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
