/*eslint-disable*/
import { types } from "../types/types";

export const authReducer = (state = {}, action) => {
  switch (action.type) {
    case types.login:
      return {
        userId: action.payload.userId,
        userName: action.payload.userName,
        token: action.payload.token,
      };

    case types.reset:
      return {};

    default:
      return state;
  }
};
