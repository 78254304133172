/*eslint-disable*/
import React, { useEffect, useState, useRef, useCallback } from "react";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CardHistory from "./Components/CardHistory";
import axiosInstance from "../../../utils/axiosInstance";
import ScrollBars from "../../../common_components/ScrollBars";

export default function DialogDeleteHistory(props) {
  const { setOpenDialog, openDialog, employeeId, date } = props;
  const [history, setHistory] = useState([]);

  const handleClose = () => {
    setOpenDialog(!openDialog);
  };

  const getHistory = async () => {
    var params = new URLSearchParams();
    params.append("Collection", "asistencias");
    params.append("ResponseData.trabajadores_id", employeeId);
    params.append("ResponseData.date_string", date);

    await axiosInstance()
      .get("/api/v1/rules/historial", { params })
      .then((res) => {
        setHistory(res.data.data);
      })
      .catch((e) => {
        console.log(e);
        setHistory([]);
      });
  };

  useEffect(() => {
    if (openDialog) {
      getHistory();
    }
  }, [openDialog]);

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        style: {
          backgroundColor: "#171a1e",
          textColor: "#fsf",
          // width: 440,
          height: 595,
          paddingTop: 15,
          borderRadius: 18,
        },
      }}
    >
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 20,
              textAlign: "center",
              color: "#fff",
              fontWeight: "bold",
            }}
          >
            Historial de asistencia
          </Typography>

          <Grid
            container
            justifyContent="flex-end"
            style={{ position: "absolute" }}
          >
            <Button onClick={handleClose}>
              <FontAwesomeIcon
                icon={faTimes}
                style={{
                  width: "15px",
                  height: "22px",
                  color: "#fff",
                }}
              />
            </Button>
          </Grid>
        </Grid>
        <div
          style={{
            width: "100%",
            height: 1,
            background: "#707070",
            marginTop: 8,
            padding: 0,
          }}
        ></div>
      </DialogTitle>

      <DialogContent>
        <ScrollBars style={{ height: 460, width: 410}}>
          {history.map((_history, index) => (
            <Grid key={index} style={{ marginBottom: 8 }}>
              <CardHistory
                date={_history.ResponseData?.datetime}
                user={_history.User.Email}
                type={_history.ResponseData?.tipoEvento}
                status={_history.ResponseData?.status}
              />
            </Grid>
          ))}
        </ScrollBars>
      </DialogContent>
    </Dialog>
  );
}
