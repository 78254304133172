/*eslint-disable*/
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SearchReportScreen from "./SearchReportScreen";
import CreateReportScreen from "./CreateReportScreen";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    // height: "100%",
    // backgroundColor: "red",
  },
  divBack: {
    cursor: "pointer",
    marginBottom: "10px",
  },
  back: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 500,
    textAlign: "left",
    color: "#fff",
  },
  icon: {
    color: "white",
    marginRight: 6,
    marginTop: -2,
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 24,
    fontWeight: 500,
    textAlign: "left",
    color: "#fff",
    // marginBottom: 25,
  },
}));

export default function BehaviorProfileScreen(props) {
  const classes = useStyles();
  const [behaviorScreen, setBehaviorScreen] = useState(1);
  const [reportData, setReportData] = useState({
    idReport: "",
    dateReport: "",
    activity: "",
    area: "",
    times: "",
    incidentsMade: [],
    remedialAction: "",
    sanction: "",
    daysSanction: "",
    riskmodification: "",
    establishedActions: "",
    complementaryActions: "",
  });

  const renderScreen = () => {
    if (behaviorScreen == 1)
      return (
        <SearchReportScreen
          reportData={reportData}
          setReportData={setReportData}
          setBehaviorScreen={setBehaviorScreen}
        />
      );
    if (behaviorScreen == 2)
      return (
        <CreateReportScreen
          reportData={reportData}
          setReportData={setReportData}
          behaviorScreen={behaviorScreen}
          setBehaviorScreen={setBehaviorScreen}
        />
      );
  };

  return <div className={classes.divMain}>{renderScreen()}</div>;
}
