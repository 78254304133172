/*eslint-disable*/
import { types } from "../types/types";

const initialState = {
    workingTimeSelected: null,
};

export const workingTimeReducer = (state = initialState, action) => {
  switch (action.type) {

    case types.workingTimeSelectedDetails:
      return {
        ...state,
        workingTimeSelected: action.payload,
      };

    case types.reset:
      return initialState;

    default:
      return state;
  }
};
