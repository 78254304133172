/*eslint-disable*/
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles((theme) => ({
  divMain: {
    maxWidth: 345,
  },
  card: {
    maxWidth: "100%",
    // minHeight: "240px",
    borderRadius: "16px",
    cursor: "pointer",
    backgroundColor: "#2e2e2e",
    padding: "15px 20px 15px 20px",
  },
  noWrap: {
    textOverflow: "ellipsis",
    wordWrap: "anywhere",
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
  },
  logo: {
    width: 40,
    height: 76,
  },
}));

export default function CardDevice(props) {
  const classes = useStyles();
  const { name, status, ip, mac, serial, obra } = props;

  return (
    // <div>
      <Card className={classes.card}>
        <Grid container direction="column">
          <Grid item container direction="row">
            <Grid container direction="row" style={{ marginTop: 5 }}>
              <div
                style={{
                  width: 12,
                  height: 12,
                  backgroundColor: status ? "#49f80b" : "#aeaeae",
                  borderRadius: "50%",
                  marginTop: 7,
                }}
              />

              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 18,
                  color: "#fff",
                  fontWeight: "bold",
                  marginLeft: 6,
                }}
              >
                {status ? "Online" : "Offline"}
              </Typography>
            </Grid>
          </Grid>

          <Grid item style={{ marginTop: 20 }}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 20,
                color: "#fff",
                fontWeight: "bold",
              }}
            >
              {name ?? "--"}
            </Typography>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 20,
                color: "#fff",
                fontWeight: "bold",
              }}
            >
              {ip ?? "--"}
            </Typography>

            <Grid container direction="row" style={{ marginTop: 8 }}>
              <FontAwesomeIcon
                icon={faBuilding}
                style={{
                  width: 11,
                  height: 14,
                  color: "#d233ff",
                  marginTop: 3,
                }}
              />

              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 14,
                  color: "#fff",
                  marginLeft: 6,
                }}
              >
                {obra ?? "--"}
              </Typography>
            </Grid>

            <Grid style={{ marginTop: 15 }}>
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 16,
                  color: "#aeaeae",
                }}
              >
                {mac ?? "--"}
              </Typography>

              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 16,
                  color: "#aeaeae",
                }}
              >
                {serial ?? "--"}
              </Typography>
            </Grid>

            <Grid style={{ marginTop: 15 }}>
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 18,
                  color: "#aeaeae",
                  fontWeight: "bold",
                }}
              >
                FaceDN2
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    // </div>
  );
}
