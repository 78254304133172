/*eslint-disable*/
import { types } from "../types/types";

const initialState = {
  permissions: null,
  modules: null,
  keyPermission: null,
};

// var flattenPermisions = (permisions) => {
//   let allModules = [];

//   const _permisions = Object.entries(permisions).map(([name, obj]) => ({
//     name,
//     ...obj,
//   }));

//   allModules.push(_permisions);
//   _permisions.map((sub) => {
//     if (sub?.submodulos) {
//       allModules.push(
//         Object.entries(sub?.submodulos).map(([name, obj]) => ({
//           name,
//           padre: sub.name,
//           ...obj,
//         }))
//       );
//       allModules = allModules.flat();
//     }
//   });

//   const keyPermission = [];
//   Object.entries(allModules).map(([name, obj]) => {
//     Object.keys(obj).map((key) => {
//       keyPermission.push(key);
//     });
//   });
//   let uniqueKeyPermission = [...new Set(keyPermission)];
//   uniqueKeyPermission = uniqueKeyPermission.filter(x => x != "name" && x != "submodulos" && x != "padre")

//   return { permissions: allModules, keyPermission: uniqueKeyPermission };
// };

// var flattenModules = (modules) => {
//   let allModules = [];
//   allModules.push(modules);
//   let submodules = modules?.flatMap((obj) => obj.submodulos);
//   submodules = submodules?.filter((x) => x != undefined);
//   allModules.push(submodules);
//   allModules = allModules.flat();

//   return allModules;
// };

// var flattenModules = (modules) => {
//   let allModules = [];
//   allModules.push(modules);
//   allModules = allModules.flat();

//   allModules.map((sub) => {
//     if (sub?.submodulos) {
//       allModules.push(
//         sub?.submodulos.map((obj) => ({
//           ...obj,
//           padre: sub.nombre,

//         }))
//       );
//     }
//   })

//   allModules = allModules.flat();
//   return allModules;
// };

var flattenPermisions = (permissions) => {
  const _permisions = Object.entries(permissions).map(([name, obj]) => ({
    name,
    ...obj,
  }));

  const keyPermission = [];
  Object.entries(permissions).map(([name, obj]) => {
    Object.keys(obj).map((key) => {
      keyPermission.push(key);
    });
  });
  let uniqueKeyPermission = [...new Set(keyPermission)];
  uniqueKeyPermission = uniqueKeyPermission.filter(
    (x) => x != "name" && x != "submodulo" && x != "padre"
  );

  return { permissions: _permisions, keyPermission: uniqueKeyPermission };
};

export const permissionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.permissionsLoad:
      return {
        ...state,
        ...flattenPermisions(action.payload),
      };

    case types.modulesLoad:
      return {
        ...state,
        modules: action.payload,
        // modules: flattenModules(action.payload),
      };

    case types.reset:
      return initialState;

    default:
      return state;
  }
};
