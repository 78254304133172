/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  divMain: {
    height: "24px",
    minWidth: "200px",
    maxWidth: "250px",
    margin: "5px 5px 0px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "16px",
    backgroundColor: "#4f5257",
  },
  typo: {
    fontFamily: "Roboto",
    fontSize: 12,
    textAlign: "left",
    color: "#fff",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    maxWidth: "90%",
  },
}));

export default function IncidentComponentCard(props) {
  const classes = useStyles();

  return (
    <div className={classes.divMain}>
      <Typography className={classes.typo}>{props.incident}</Typography>
    </div>
  );
}
