/*eslint-disable*/
import { types } from "../types/types";

const initialState = {
  workingTime: null,
  employees: null,
  proyects: null,
  subcontractors: null,
  gateways: null,
  devices: null,
};

export const filtersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.filtersWordkingTime:
      return {
        ...state,
        workingTime: action.payload,
      };

    case types.filtersAttendance:
      return {
        ...state,
        attendance: action.payload,
      };

    case types.filtersAssistance:
      return {
        ...state,
        assistance: action.payload,
      };

    case types.filtersEmployees:
      return {
        ...state,
        employees: action.payload,
      };

    case types.filtersProyects:
      return {
        ...state,
        proyects: action.payload,
      };

    case types.filtersSubcontractors:
      return {
        ...state,
        subcontractors: action.payload,
      };

    case types.filtersGateway:
      return {
        ...state,
        gateways: action.payload,
      };

    case types.filtersDevice:
      return {
        ...state,
        devices: action.payload,
      };

    case types.filtersReset:
      return initialState;

    case types.reset:
      return initialState;

    default:
      return state;
  }
};
