/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { MultiSelect } from "react-multi-select-component";
import { makeStyles } from "@material-ui/styles";
import "./styles.css";

const useStyles = makeStyles((theme) => ({
  multi: {
    color: "white",
    padding: 2,
    border: 0,
    borderRadius: "8px",
  },
}));

export default function MutiSelect(props) {
  const classes = useStyles();

  const filterOptions = (options, filter) => {
    // console.log(options);
    // console.log(filter);
    if (!filter) {
      return options;
    }
    const re = new RegExp(filter, "i");
    return options.filter(({ label }) => label && label.match(re));
  };

  return (
    <div className={classes.root}>
      <MultiSelect
        disabled={props.disabled}
        options={props.options}
        filterOptions={filterOptions}
        value={props.value}
        onChange={props.onChange}
        labelledBy="Select"
        className={props.className ? props.className : classes.multi}
        overrideStrings={{
          allItemsAreSelected: "Todos los elementos están seleccionados.",
          clearSearch: "Borrar búsqueda",
          noOptions: "Sin opciones",
          search: "Buscar",
          selectAll: "Todas",
          selectAllFiltered: "Seleccionar todo (filtrado)",
          selectSomeItems: "Todas",
        }}
      />
    </div>
  );
}
