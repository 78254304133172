/*eslint-disable*/
import React, { Fragment, useState } from "react";
// import {  } from "@material-ui/core/styles";
import {
  Card,
  Popover,
  Typography,
  Grid,
  makeStyles,
  Button,
} from "@material-ui/core";
// import  from "@material-ui/core/Typography";
// import  from "@material-ui/core/Grid";
import photo from "../../../../images/profile@2x.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDoorClosed,
  faExclamationTriangle,
  faTrash,
  faUserEdit,
} from "@fortawesome/free-solid-svg-icons";
import ModeEditIcon from "@mui/icons-material/ModeEdit"; // mode-edit
import TimeIconComponent from "../../../../common_components/TimeIconComponent";
import svgSalida from "../../../../images/salida_icono.svg";
// import { faDoorClosed } from "@fortawesome/free-solid-svg-icons";
const useStyles = makeStyles((theme) => ({
  divMain: {
    maxWidth: 345,
  },
  card: {
    width: "auto",
    minWidth: 96,
    minHeight: 40,
    height: "auto",
    borderRadius: 8,
    backgroundColor: "#4f5257",
    padding: 4,
  },
  griCard: {
    padding: theme.spacing(2),
  },
  noWrap: {
    textOverflow: "ellipsis",
    wordWrap: "anywhere",
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": 3,
    "-webkit-box-orient": "vertical",
  },
  button: {
    cursor: "pointer",
    padding: 8,
    "&:hover": {
      backgroundColor: "#4f5257",
    },
  },
}));

export default function CardAttendanceEvents(props) {
  const classes = useStyles();
  const { data, edit, event, manual } = props;
  const [editRegister, setEditRegister] = useState(false);
  const [anchorElEdit, setAnchorElEdit] = useState(null);
  const [value, setValue] = useState("");
  const openEdit = Boolean(anchorElEdit);

  const handleOpenEdit = (event) => {
    if (edit) setAnchorElEdit(event.currentTarget);
  };

  const handleCloseEdit = () => {
    setAnchorElEdit(null);
  };

  return (
    <Fragment>
      <Card
        className={classes.card}
        onClick={handleOpenEdit}
        style={{ cursor: edit && "pointer" }}
      >
        {data ? (
          <Grid container direction="row">
            <Grid item xs={4}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <img
                  src={svgSalida}
                  style={{
                    width: 14,
                    height: 16,
                    marginTop: 8,
                  }}
                />
                {/* <FontAwesomeIcon
                  icon={faDoorClosed}
                  style={{
                    width: 14,
                    height: 16,
                    color: "#fff",
                    marginTop: 8,
                  }}
                /> */}
              </Grid>
            </Grid>

            <Grid item xs={8}>
              <Grid container direction="column">
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 14,
                    textAlign: "left",
                    color: "#fff",
                    fontWeight: 500,
                    lineHeight: 1.3,
                  }}
                >
                  {event}
                </Typography>
                <Typography
                  className={classes.noWrap}
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 14,
                    textAlign: "left",
                    color: "#acacac",
                    lineHeight: 1.3,
                  }}
                >
                  {new Date(data * 1000).toLocaleTimeString(
                    // navigator.language,
                    "default",
                    {
                      hour: "2-digit",
                      minute: "2-digit",
                    }
                  )}
                </Typography>
              </Grid>
            </Grid>

            {manual && (
              <Grid container direction="row" justifyContent="center">
                {/* <FontAwesomeIcon
                icon={faUserEdit}
                style={{
                  width: 10,
                  height: 8,
                  color: "#d233ff",
                }}
              /> */}

                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 9,
                    textAlign: "left",
                    color: "#a4a4a4",
                    lineHeight: 1.25,
                    marginLeft: 2,
                  }}
                >
                  Asistencia manual
                </Typography>
              </Grid>
            )}
          </Grid>
        ) : (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              style={{
                width: 16,
                height: 14,
                color: "#e3c817",
              }}
            />

            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 12,
                textAlign: "left",
                color: "#fff",
              }}
            >
              Sin registro
            </Typography>
          </Grid>
        )}
      </Card>
      <Popover
        open={openEdit}
        anchorEl={anchorElEdit}
        onClose={handleCloseEdit}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            width: 170,
            height: "auto",
            backgroundColor: "#212429",
            borderRadius: 6,
            marginTop: -20,
            marginLeft: 15,
          },
        }}
      >
        <Grid container direction="column" style={{ padding: 5 }}>
          {!editRegister ? (
            <Grid>
              <Grid
                container
                direction="row"
                className={classes.button}
                onClick={() => setEditRegister(true)}
              >
                <ModeEditIcon
                  style={{
                    width: 16,
                    height: 16,
                    color: "#ebebeb",
                    marginTop: 2,
                  }}
                />

                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 14,
                    textAlign: "left",
                    color: "#fff",
                    fontWeight: 500,
                    marginLeft: 12,
                  }}
                >
                  Editar
                </Typography>
              </Grid>

              <Grid container direction="row" className={classes.button}>
                <FontAwesomeIcon
                  icon={faTrash}
                  style={{
                    width: 13,
                    height: 14,
                    color: "#ebebeb",
                    marginTop: 2,
                  }}
                />
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 14,
                    textAlign: "left",
                    color: "#fff",
                    fontWeight: 500,
                    marginLeft: 12,
                  }}
                >
                  Eliminar
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{ marginTop: 10 }}
              >
                <TimeIconComponent value={value} setValue={setValue} />

                <div
                  style={{
                    width: "100%",
                    height: 1,
                    background: "#707070",
                    marginTop: 15,
                    padding: 0,
                  }}
                ></div>

                <Button
                  style={{
                    backgroundColor: "#212429",
                    textTransform: "none",
                    marginTop: 8,
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 16,
                      textAlign: "left",
                      color: "#fff",
                    }}
                  >
                    Registrar
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Popover>
    </Fragment>
  );
}
