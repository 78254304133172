/*eslint-disable*/
import React, { Fragment, usestatus } from "react";
import { Grid, Typography, Menu, MenuItem } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { saveAs } from "file-saver";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

export default function CardHistory(props) {
  const { data } = props;

  const ITEM_HEIGHT = 48;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownload = () => {
    saveAs(data.url, data.originalName);
    handleClose();
  };

  const options = {
    month: "long",
    day: "numeric",
    year: "numeric",
  };

  return (
    <Fragment>
      <Grid container direction="row">
        <Grid item xs={1} style={{ position: "relative" }}>
          <div
            style={{
              background: "#d233ff",
              position: "absolute",
              width: 28,
              height: 28,
              marginTop: 20,
              marginLeft: 8,
              zIndex: -1,
            }}
          />
          <FontAwesomeIcon
            icon={faFileDownload}
            style={{
              width: 40,
              height: 53,
              color: "#fff",
              zIndex: 1,
            }}
          />
        </Grid>

        <Grid
          item
          xs={9}
          container
          direction="column"
          justifyContent="center"
          style={{ marginLeft: 16 }}
        >
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 16,
              textAlign: "left",
              color: "#fff",
              lineHeight: 1.13,
              textDecoration: "underline",
            }}
          >
            {data.originalName}
          </Typography>

          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 14,
              textAlign: "left",
              color: "#b7b7b7",
              lineHeight: 1.29,
              marginTop: 6,
            }}
          >
            {new Date(data.date).toLocaleString("es-MX", options)}
            {/* {data.date} */}
          </Typography>
        </Grid>

        <Grid
          item
          xs={1}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ cursor: "pointer" }}
          onClick={handleClick}
        >
          <FontAwesomeIcon
            icon={faEllipsisV}
            style={{
              width: 8,
              height: 20,
              color: "#fff",
            }}
          />
        </Grid>

        <Menu
          // classes={{ paper: classes.menuPaper }}
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: 250,
              backgroundColor: "#27292d",
            },
          }}
        >
          <MenuItem
            key={"Descargar"}
            style={{ color: "white" }}
            onClick={handleDownload}
          >
            <FileDownloadIcon />

            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 16,
                textAlign: "left",
                color: "#fff",
                marginLeft: 8,
              }}
            >
              Descargar documento
            </Typography>
          </MenuItem>
        </Menu>
      </Grid>
    </Fragment>
  );
}
