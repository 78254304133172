/*eslint-disable*/
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CardDevice from "./components/CardDevice";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import Gateway from "../../images/gtw.svg"
import Camera from "../../images/face_dn_2_isometrico_icono.png"

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    padding: 5,
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 32,
    fontWeight: 500,
    textAlign: "left",
    color: "#ffffff",
  },
}));
export default function SelectedDevice(props) {
  const classes = useStyles();
  const { permissions, modules } = useSelector((state) => state.permissions);
  const permissionsReport = permissions?.filter(
    (x) => x.padre == "Dispositivos" && x.habilitar == true
  );
  const moduleReport = permissionsReport?.map((_module) => {
    return modules.find((x) => x.nombre == _module.name);
  });

  const getImg = (titulo) => {
    if(titulo == "Gateways") return {img: Gateway, maxWidth: 70};
    if(titulo == "Camaras") return {img: Camera, maxWidth: 50};
  }

  return (
    <div className={classes.divMain}>
      <Grid item>
        <Typography
          className={classes.title}
          style={{ marginBottom: 30, marginTop: 10 }}
        >
          Dispositivos
        </Typography>
      </Grid>

      <Grid container spacing={4}>
        {moduleReport
          ?.sort((a, b) => (a.posicion > b.posicion ? 1 : -1))
          .map((report, index) => (
            <Grid item xs={12} md={12} lg={6} xl={6} sm={12} key={index}>
              <CardDevice
                img={getImg(report.nombre)}
                title={report.titulo}
                body={report.descripcion}
                url={report.url}
              />
            </Grid>
          ))}
      </Grid>
    </div>
  );
}
