/*eslint-disable*/
import React, {
  useState,
  useEffect,
  Fragment,
  useRef,
  useCallback,
} from "react";
import { useHistory } from "react-router-dom";
import { Grid, Typography, TextField, Box, Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { makeStyles } from "@material-ui/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import InputAdornment from "@material-ui/core/InputAdornment";
import MultiSelect from "../../../common_components/Filters/MutiSelect";
import DateRange from "../../../common_components/Filters/DateRange";
import { useDispatch, useSelector } from "react-redux";
import { FiltersAttendance } from "../../../actions/filters";
import CardAttendance from "./Components/CardAttendance";
import useAttendance from "./useAttendance";
import axios from "axios";
import axiosInstance from "../../../utils/axiosInstance";
import NotAttendanceFound from "./Components/NotAttendanceFound";
import { dateFilter } from "../../../utils/getRangeDateFilter";
import { saveAs } from "file-saver";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiInputBase-input": {
      color: "676767 !important",
    },
  },
  divMain: {
    flexGrow: 1,
    paddingTop: 10,
    height: "100%",
    // minHeight: "100%",
  },
  divBack: {
    cursor: "pointer",
    marginBottom: "10px",
    // marginTop: "5px",
  },
  back: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 500,
    textAlign: "left",
    color: "#fff",
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 32,
    fontWeight: 500,
    textAlign: "left",
    color: "#ffffff",
  },
  adornedStart: {
    backgroundColor: "red",
    height: "5rem",
    maxHeight: "8rem",
  },
  button: {
    width: "189px",
    height: "40px",
    borderRadius: "8px",
    backgroundColor: "#ac0bce",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#4f5257",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: 20,
    },
  },
}));

export default function AttendanceList(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { user } = useSelector((state) => state.user);
  const { attendance } = useSelector((state) => state.filters);
  const [loadingData, setLoadingData] = useState(false);
  const [subcontratistas, setSubcontratistas] = useState(null);
  const [proyectos, setProyectos] = useState(null);
  const [unidades, setUnidades] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [loadingExport, setLoadingExport] = useState(false);
  const [limit, setLimit] = useState(100);
  var options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const [dateRange, setDateRange] = useState([
    {
      startDate: attendance ? attendance.intialDate : new Date(),
      endDate: attendance ? attendance.finalDate : new Date(),
      key: "selection",
    },
  ]);
  const [query, setQuery] = useState({
    Empleado: attendance?.Empleado ?? "",
    Proyectos: attendance?.Proyectos ?? [],
    Subcontratistas: attendance?.Subcontratistas ?? [],
    Unidad: attendance?.Unidad ?? [],
    intialDate: attendance?.intialDate ?? new Date(),
    finalDate: attendance?.finalDate ?? new Date(),
    update: attendance?.update ?? false,
  });

  const { books, hasMore, loading, error, total, paramsState } = useAttendance(
    query,
    pageNumber,
    limit,
    loadingData,
    subcontratistas,
    proyectos,
    unidades
  );

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const handleSelect = (ranges) => {
    setDateRange([ranges.selection]);
    updateData("intialDate", ranges.selection.startDate);
    updateData("finalDate", ranges.selection.endDate);
  };

  const handleQueryChange = (queryElement) => (e) => {
    const value =
      queryElement === "Proyectos" ||
      queryElement === "Subcontratistas" ||
      queryElement === "Unidad"
        ? e
        : e.target.value;
    return setQuery((prevQuery) => {
      return { ...prevQuery, ...{ [queryElement]: value } };
    });
  };

  const updateData = (k, v) => {
    setQuery((prev) => ({ ...prev, [k]: v }));
  };

  const backStep = () => {
    history.push("/dashboard/reportes");
  };

  const getDateFilter = async () => {
    try {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      var params = new URLSearchParams();
      params.append("type_string", "Calendarios_default");
      params.append("name_string", "Reportes_asistencias_calendario");

      return axiosInstance()
        .get(`/api/v1/rules/config`, {
          params,
          cancelToken: source.token,
        })
        .then((response) => {
          // console.log(response.data.data[0]);
          const rangeDate = dateFilter(response.data.data[0].rangoFechaDefault);

          setQuery((prev) => {
            return {
              ...prev,
              intialDate: rangeDate.initialDate,
              finalDate: rangeDate.finalDate,
            };
          });
          setDateRange((prev) => {
            return [
              {
                ...prev,
                startDate: rangeDate.initialDate,
                endDate: rangeDate.finalDate,
                key: "selection",
              },
            ];
          });
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const getProyectsList = async () => {
    try {
      var params = new URLSearchParams();
      params.append("sortMongoAsc", "proyecto");

      return await axiosInstance()
        .get(`/api/v1/rules/lista-de-asistencia/comboboxes/obras`, { params })
        .then((response) => {
          const proyects = response.data.data.map((object) => {
            return { label: object.proyecto, value: object.proyecto };
          });

          setProyectos(proyects);
          return proyects;
        })
        .catch((e) => {
          console.log(e);
          return false;
        });
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const getSubcontractorList = async () => {
    try {
      var params = new URLSearchParams();
      params.append("showoculta", true);
      params.append("sortMongoAsc", "empresa");

      return await axiosInstance()
        .get(`/api/v1/rules/lista-de-asistencia/comboboxes/subcontratistas`, {
          params,
        })
        .then((response) => {
          const empresa = response.data.data.map((object) => {
            return { label: object.empresa, value: object.empresa };
          });

          setSubcontratistas(empresa);
          return empresa;
        })
        .catch((e) => {
          console.log(e);
          return false;
        });
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const getUnidadesList = async () => {
    try {
      var params = new URLSearchParams();
      params.append("sortMongoAsc", "name");

      return await axiosInstance()
        .get("/api/v1/rules/lista-de-asistencia/comboboxes/unidad", { params })
        .then((response) => {
          let unidades = response.data.data.map((object) => {
            return { label: object.name, value: object._id };
          });

          setUnidades(unidades);
          return unidades;
        })
        .catch((e) => {
          console.log(e);
          return false;
        });
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const exportReport = async () => {
    // console.log("paramsState", paramsState)
    if (paramsState) {
      setLoadingExport(true);
      await axiosInstance()
        .get(`/api/v1/rules/workingtime/excelV3?${paramsState}`)
        .then((res) => {
          // console.log(res.data);
          setTimeout(() => {
            saveAs(res.data.data.url, res.data.data.fileName);
          }, 500);
        })
        .catch((e) => {
          console.log(e.response);
          enqueueSnackbar("Error al generar reporte", {
            variant: "error",
          });
        });
    } else {
      enqueueSnackbar("Favor de llenar los filtros", {
        variant: "error",
      });
    }

    setLoadingExport(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoadingData(false);

      await getSubcontractorList();
      await getProyectsList();
      await getUnidadesList();
      await getDateFilter();

      setLoadingData(true);
    };

    fetchData();
  }, []);

  useEffect(() => {
    setPageNumber(0);
    dispatch(FiltersAttendance(query));
  }, [query]);

  return (
    <div className={classes.divMain}>
      <Grid container direction="column">
        <Grid
          item
          xs={12}
          container
          direction="row"
          alignItems="center"
          onClick={backStep}
          className={classes.divBack}
        >
          <Grid item>
            <FontAwesomeIcon
              icon={faArrowAltCircleLeft}
              style={{
                width: "20px",
                height: "20px",
                marginRight: "10px",
                color: "#d6d6d6",
              }}
            />
          </Grid>
          <Grid item>
            <Typography className={classes.back}>Regresar</Typography>
          </Grid>
        </Grid>

        <Typography
          style={{
            marginBottom: 25,
            marginTop: 10,
            fontFamily: "Roboto",
            fontSize: 30,
            fontWeight: 500,
            textAlign: "left",
            color: "#fff",
          }}
        >
          Lista de asistencia
        </Typography>

        <Grid container direction="row">
          <Grid xs={2} item>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 16,
                textAlign: "left",
                color: "#dedcdc",
              }}
            >
              Fecha
            </Typography>

            <DateRange value={dateRange} onChange={handleSelect} />
          </Grid>

          <Grid item xs={2} style={{ marginLeft: 30 }}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 16,
                textAlign: "left",
                color: "#dedcdc",
              }}
            >
              Obra
            </Typography>
            <div>
              <MultiSelect
                options={proyectos ? proyectos : []}
                value={query.Proyectos}
                onChange={handleQueryChange("Proyectos")}
              />
            </div>
          </Grid>

          <Grid item xs={2} style={{ marginLeft: 30 }}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 16,
                textAlign: "left",
                color: "#dedcdc",
              }}
            >
              Unidad
            </Typography>
            <div>
              <MultiSelect
                options={unidades ? unidades : []}
                value={query.Unidad}
                onChange={handleQueryChange("Unidad")}
              />
            </div>
          </Grid>

          <Grid item xs={2} style={{ marginLeft: 30 }}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 16,
                textAlign: "left",
                color: "#dedcdc",
              }}
            >
              Subcontratista
            </Typography>
            <div>
              <MultiSelect
                options={subcontratistas ? subcontratistas : []}
                value={query.Subcontratistas}
                onChange={handleQueryChange("Subcontratistas")}
              />
            </div>
          </Grid>

          <Grid item xs={4}>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Buscar empleado por nombre"
              value={query?.Empleado}
              onChange={handleQueryChange("Empleado")}
              className={classes.root}
              style={{
                width: 350,
                background: "white",
                borderRadius: "8px",
                marginTop: 25,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon
                      icon={faSearch}
                      style={{
                        width: "18px",
                        height: "18px",
                        color: "#676767",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid
            item
            xs={2}
            container
            direction="row"
            justifyContent="center"
            alignContent="center"
            style={{ marginLeft: -150, marginTop: 23 }}
          >
            <Button
              disabled={loadingExport}
              onClick={exportReport}
              className={classes.button}
            >
              {!loadingExport ? (
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 18,
                    textAlign: "left",
                    color: "#fff",
                  }}
                >
                  Exportar reporte
                </Typography>
              ) : (
                <CircularProgress style={{ color: "white" }} size={24} />
              )}
            </Button>
          </Grid>
        </Grid>

        <Grid style={{ marginTop: 30 }}>
          {books.length == 0 && !loading ? (
            <NotAttendanceFound />
          ) : (
            books?.map((attendance, index) => (
              <Fragment key={index}>
                {books[index - 1]?.fecha != attendance?.fecha && (
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 28,
                      textAlign: "left",
                      color: "#fff",
                      fontWeight: 500,
                      marginBottom: 10,
                      marginTop: 15,
                    }}
                  >
                    {new Date(attendance?.fecha)?.toLocaleDateString(
                      "es-MX",
                      options
                    )}
                  </Typography>
                )}

                <Grid
                  key={index}
                  style={{ marginTop: 15 }}
                  ref={lastBookElementRef}
                >
                  <CardAttendance
                    key={index}
                    data={attendance}
                    query={query}
                    setQuery={setQuery}
                  />
                </Grid>
              </Fragment>
            ))
          )}
        </Grid>

        <Box
          display="flex"
          m={1}
          p={1}
          alignItems="center"
          justifyContent="center"
        >
          {loading && (
            <CircularProgress disableShrink size={100} color="secondary" />
          )}
        </Box>
      </Grid>
    </div>
  );
}
