/*eslint-disable*/
import React from "react";
import { DataGrid, esES } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/styles";
import Lottie from "react-lottie";
import LottieNotFound from "../../animations/lottie_not_found.json";
import { Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    border: 0,
    "& .MuiDataGrid-root": {
      height: "90%",
    },
    "& .MuiDataGrid-footerContainer": {
      borderTop: `1px solid ${theme.palette.secondary.main}`,
    },
    // "& .MuiDataGrid-selectedRowCount": {
    //   color: "red",
    // },
    "& .MuiDataGrid-columnsContainer": {
      // fontFamily: "Roboto",
      // fontSize: "18px",
      // fontWeight: 500,
      // color: "white",
      borderColor: theme.palette.secondary.dark,
    },
    "& .MuiDataGrid-iconSeparator": {
      color: "#707070",
    },
    "& .MuiTablePagination-caption": {
      fontFamily: "Roboto",
      fontSize: "18px",
      fontWeight: 500,
      color: theme.palette.secondary.dark,
    },
    "& .MuiTablePagination-actions": {
      color: "white",
    },
    "& .MuiTablePagination-input": {
      color: "white",
    },
    "& .MuiTablePagination-selectIcon": {
      color: "#8a06c7",
    },
    "& .MuiSvgIcon-root": {
      color: "#a8a8a8",
    },
    "& .MuiPaper-root": {
      backgroundColor: "red",
      color: "red",
    },
    "& .MuiDataGrid-row": {
      margin: "auto",
      paddingBottom: 50,
      "&:hover": {
        cursor: "pointer",
      },
    },
    "& .header": {
      fontFamily: "Roboto",
      fontSize: 18,
      fontWeight: 500,
      color: theme.palette.secondary.dark,
      display: "flex",
    },
    "& .MuiDataGrid-cell": {
      fontFamily: "Roboto",
      fontSize: 16,
      textAlign: "center !important",
      color: theme.palette.secondary.main,
      borderColor: "#707070",
      height: "20px !important",
      // lineHeight: "unset !important",
      // maxHeight: "none !important",
      // whiteSpace: "normal",
    },
  },
  dataGrid: {
    marginTop: 40,
    borderRadius: 10,
    borderColor: "#2d3034",
    backgroundColor: theme.palette.neutral.primary,
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 22,
    textAlign: "left",
    color: theme.palette.secondary.dark,
  },
}));

export default function DataGridCommon(props) {
  const classes = useStyles();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LottieNotFound,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className={classes.root} style={props.style}>
      <DataGrid
        selectionModel={props.selectionModel}
        onSelectionModelChange={props.onSelectionModelChange}
        localeText={esES.props.MuiDataGrid.localeText}
        rows={props.rows}
        columns={props.columns}
        rowHeight={props.rowHeight ? props.rowHeight : 60}
        pagination
        loading={props.loading}
        paginationMode={props?.paginationMode ?? "server"}
        rowCount={props.rowCount}
        rowsPerPageOptions={props.rowsPerPageOptions}
        page={props.page - 1}
        pageSize={props.pageSize}
        onPageChange={props.onPageChange}
        onPageSizeChange={props.onPageSizeChange}
        initialState={props.initialState}
        className={classes.dataGrid}
        components={{
          NoRowsOverlay: () => (
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              direction="column"
              style={{ marginTop: 100, position: "absolute" }}
            >
              <Lottie options={defaultOptions} height={300} width={300} />
              <Typography className={classes.title}>
                No se han encontrado datos
              </Typography>
            </Grid>
          ),
          NoResultsOverlay: () => (
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              direction="column"
              style={{ marginTop: 100, position: "absolute" }}
            >
              <Lottie options={defaultOptions} height={300} width={300} />
              <Typography className={classes.title}>
                No se han encontrado datos
              </Typography>
            </Grid>
          ),
        }}
      />
    </div>
  );
}
