/*eslint-disable*/
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button } from "@material-ui/core";
import Lottie from "react-lottie";

const useStyles = makeStyles((theme) => ({
  gridMain: {
    width: "100%",
    height: "100%",
    // margin: "auto",
    // backgroundColor: "blue",
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 26,
    fontWeight: 500,
    lineHeight: 1.33,
    color: "#fff",
    marginTop: 20,
  },
  subtitle: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 1.31,
    color: "#dcdcdc",
    marginTop: 10,
  },
}));

export default function NotFoundAnimation(props) {
  const classes = useStyles();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: props.animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      className={classes.gridMain}
    >
      <Grid container direction="column" alignItems="center">
        <Lottie
          options={defaultOptions}
          width={props.width}
          height={props.height}
        />
        <Typography className={classes.title}>{props.title}</Typography>
        <Typography className={classes.subtitle}>{props.subtitle}</Typography>
      </Grid>
    </Grid>
  );
}
