/*eslint-disable*/
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { Typography, Button, Grid } from "@material-ui/core";
import Typo from "../../../common_components/typo";
import Lottie from "react-lottie";
import animationCorrect from "../../../animations/lottie_correct.json";

const useStyles = makeStyles((theme) => ({
  divMain: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    padding: theme.spacing(5),
    // backgroundColor: "blue",
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 32,
    fontWeight: 600,
    color: theme.palette.secondary.dark,
    marginTop: "12%",
  },
  subtitle: {
    fontFamily: "Roboto",
    fontSize: 18,
    color: theme.palette.secondary.light,
  },
  button: {
    height: "56px",
    marginTop: "20%",
    // marginBottom: "20%",
    borderRadius: "4px",
    backgroundColor: theme.palette.primary.main,
    textTransform: "none",
  },
  typoButton: {
    fontFamily: "Roboto",
    fontSize: 28,
    fontWeight: 300,
    color: theme.palette.neutral.primary,
  },
}));

export default function PasswordChanged(props) {
  const history = useHistory();
  const classes = useStyles();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationCorrect,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className={classes.divMain}>
      <Grid
        item
        xs={10}
        container
        direction="column"
        alignItems="center"
        style={{
          width: "503px",
          height: "100%",
        }}
      >
        <Lottie options={defaultOptions} width={187} height={181} />

        <Typography className={classes.title}>
          Cambio de contraseña exitoso
        </Typography>
        <Typography className={classes.subtitle}>
          Se ha recuperado tu contraseña exitosamente
        </Typography>

        <Button
          fullWidth
          variant="contained"
          onClick={() => history.push("/inicio_sesion")}
          className={classes.button}
        >
          <Typography className={classes.typoButton}>Ir a Login</Typography>
        </Button>
      </Grid>
    </div>
  );
}
