/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import CardSummarySubcontractor from "./Components/CardSummarySubcontractor";
import CardDetailSubcontractor from "./Components/CardDetailSubcontractor";
import CardFileSubcontractor from "./Components/CardFileSubcontractor";
import CardEnterpriseEmployee from "./Components/CardEnterpriseEmployee";
import CardAssignmentsSubcontractor from "./Components/CardAssignmentsSubcontractor";
import CardHistorySubcontractor from "./Components/CardHistorySubcontractor";
import DialogEditObra from "./Components/DialogEditObra";
import { useDispatch, useSelector } from "react-redux";
import { addFile, clearFiles } from "../../../actions/file";
import { SelectedDetailsContractor } from "../../../actions/contractor";
import axios from "axios";
import axiosInstance from "../../../utils/axiosInstance";
import { isObjectId } from "../../../utils/CheckObjectId";

const useStyles = makeStyles((theme) => ({
  divBack: {
    cursor: "pointer",
    marginBottom: "10px",
  },
  back: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 500,
    textAlign: "left",
    color: "#fff",
  },
}));

export default function SubcontractorProfileScreen(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const url = window.location.pathname.split("/");
  const { user } = useSelector((state) => state.user);
  const [openDialog, setOpendialog] = useState(false);
  const [subcontractorData, setSubcontractorData] = useState({
    subcontractorId: "",
    foto: "",
    nombre: "--",
    razonSocial: "--",
    rfc: "--",
    unidad: "--",
    empleados: "--",
    numeroSeguro: "--",
    representante: "--",
    telefono: "--",
    direccion: "--",
    activo: "--",
    banco: "--",
    cuentaBancaria: "--",
    proyectos: [],
    proyectos_id: [],
  });
  const [statusEmployee, setStatusEmployee] = useState({
    Activo: null,
    Inactivo: null,
    Boletinado: null,
    Vetado: null,
  });

  const getEnterprise = () => {
    let modulo = url.pop();

    if (isObjectId(modulo)) {
      modulo = url.pop().replace("contratistas", "subcontratistas");
    }

    return axiosInstance()
      .get(`/api/v1/rules/${modulo}/${localStorage.getItem("idSubcontractor")}`)
      .then((response) => {
        // console.log("get subcontractor", response.data.data[0]);
        setSubcontractorData({
          ...subcontractorData,
          subcontractorId: response.data.data[0]._id
            ? response.data.data[0]._id
            : subcontractorData.subcontractorId,
          foto: response.data.data[0].foto
            ? response.data.data[0].foto.url
            : subcontractorData.foto,
          nombre: response.data.data[0].empresa,
          razonSocial: response.data.data[0].razonSocial
            ? response.data.data[0].razonSocial
            : subcontractorData.razonSocial,
          rfc: response.data.data[0].rfc
            ? response.data.data[0].rfc
            : subcontractorData.rfc,
          unidad: response.data.data[0].unidad
            ? response.data.data[0].unidad
            : subcontractorData.unidad,
          numeroSeguro: response.data.data[0].numeroSeguro
            ? response.data.data[0].numeroSeguro
            : subcontractorData.numeroSeguro,
          representante: response.data.data[0].representante
            ? response.data.data[0].representante
            : subcontractorData.representante,
          telefono: response.data.data[0].telefono
            ? response.data.data[0].telefono
            : subcontractorData.telefono,
          // firstTime: response.data.data[0].firstTime
          //   ? response.data.data[0].firstTime
          //   : subcontractorData.firstTime,
          activo: response.data.data[0].activo
            ? response.data.data[0].activo
            : subcontractorData.activo,
          banco: response.data.data[0].banco
            ? response.data.data[0].banco
            : subcontractorData.banco,
          cuentaBancaria: response.data.data[0].cuentaBancaria
            ? response.data.data[0].cuentaBancaria
            : subcontractorData.cuentaBancaria,
          documentos: response.data.data[0].documentos
            ? response.data.data[0].documentos
            : subcontractorData.documentos,
          proyectos: response.data.data[0].proyectos
            ? response.data.data[0].proyectos
            : subcontractorData.proyectos,
          empleados: response.data.data[0].trabajadores_id
            ? response.data.data[0].trabajadores_id.length
            : subcontractorData.empleados,
          proyectos_id: response.data.data[0].proyectos_id
            ? response.data.data[0].proyectos_id
            : subcontractorData.proyectos_id,
        });

        dispatch(SelectedDetailsContractor(response?.data?.data[0]));

        dispatch(clearFiles());
        const doc = response.data.data[0].documentos
          ? response.data.data[0].documentos
          : [];
        doc.map((file, index) =>
          dispatch(
            addFile({
              name: file.filename,
              url: file.file,
              originalName: file.originalName,
              process: "db",
              date: file.datetime,
            })
          )
        );
      })
      .catch((e) => {
        console.log(e.response);
      });
  };

  const getEmployeesStatus = () => {
    var array = ["Activo", "Inactivo", "Boletinado", "Vetado"];

    const response = array.map((status, index) => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      var params = new URLSearchParams();
      params.append("limit", 1);
      params.append("page", 0);
      params.append(
        "subcontratistas_id",
        localStorage.getItem("idSubcontractor")
      );
      params.append("statusDemek_string", status);

      return axiosInstance()
        .get(`/api/v1/rules/trabajadores/filter`, {
          params,
          cancelToken: source.token,
        })
        .then((response) => {
          // console.log([status] + ": " + response.data.data.Total);
          setStatusEmployee((statusEmployee) => {
            return {
              ...statusEmployee,
              [status]: response.data.data.Total,
            };
          });
        })
        .catch((e) => {
          console.log(e.response);
        });
    });

    return response;
  };

  useEffect(() => {
    if (user) {
      if (user?.RoleName == "Administrador Subcontratista") {
        localStorage.setItem("idSubcontractor", user?.Subcontractor_id);
      }

      getEnterprise();
      getEmployeesStatus();
    }
  }, [user]);

  return (
    <div>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={4}
          xl={4}
          className={classes.gridMargin}
        >
          <CardSummarySubcontractor
            urlPhoto={subcontractorData.foto}
            name={subcontractorData.nombre}
            businessName={subcontractorData.razonSocial}
            rfc={subcontractorData.rfc}
            unit={subcontractorData.unidad}
            employees={subcontractorData.empleados}
            securityNumber={subcontractorData.numeroSeguro}
            agent={subcontractorData.representante}
            telephone={subcontractorData.telefono}
          />

          <Grid
            style={{
              height: "10px",
            }}
          />

          <CardDetailSubcontractor
            name={subcontractorData.nombre}
            rfc={subcontractorData.rfc}
            unit={subcontractorData.unidad}
            businessName={subcontractorData.razonSocial}
            securityNumber={subcontractorData.numeroSeguro}
            agent={subcontractorData.representante}
            telephone={subcontractorData.telefono}
            bank={subcontractorData.banco}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          <CardAssignmentsSubcontractor
            subcontractorId={subcontractorData.subcontractorId}
            proyectos={subcontractorData.proyectos}
            setOpendialog={setOpendialog}
            openDialog={openDialog}
          />

          <Grid
            style={{
              height: "10px",
            }}
          />

          <CardFileSubcontractor files={subcontractorData.documentos} />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          <CardEnterpriseEmployee
            id={subcontractorData.subcontractorId}
            enterprise={subcontractorData.nombre}
            employees={subcontractorData.empleados}
            active={statusEmployee.Activo ?? 0}
            inactive={statusEmployee.Inactivo ?? 0}
            bulletin={statusEmployee.Boletinado ?? 0}
            veto={statusEmployee.Vetado ?? 0}
          />

          <Grid
            style={{
              height: "10px",
            }}
          />

          <CardHistorySubcontractor />
        </Grid>
      </Grid>

      <DialogEditObra
        setOpendialog={setOpendialog}
        openDialog={openDialog}
        subcontractorData={subcontractorData}
        setSubcontractorData={setSubcontractorData}
        data={subcontractorData.proyectos_id}
      />
    </div>
  );
}
