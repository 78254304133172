/*eslint-disable*/
import { types } from "../types/types";

export const SetScreen = (result) => ({
  type: types.screen,
  payload: result,
});

export const SetScreenEmployees = (result) => ({
  type: types.screenEmployees,
  payload: result,
});

export const SetScreenProfile = (result) => ({
  type: types.screenProfile,
  payload: result,
});
