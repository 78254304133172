/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "100%",
    height: "122px",
    borderRadius: "7px",
    backgroundColor: "#2d3034",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "auto",
    },
  },
  griCard: {
    padding: theme.spacing(2),
  },
}));

export default function CardHours(props) {
  const classes = useStyles();

  function secondsToHms(d) {
    d = Number(d);
    var hours = Math.floor(d / 3600);
    var minutes = Math.floor((d % 3600) / 60);
    var seconds = Math.floor((d % 3600) % 60);
    return {
      hours,
      minutes,
      seconds,
    };
  }

  return (
    <Card className={classes.card}>
      <Grid className={classes.griCard}>
        <Grid container direction="row" justifyContent="flex-start">
          <Grid item xs={9}>
            <Grid container direction="column">
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 18,
                  textAlign: "left",
                  color: "#fff",
                }}
              >
                {props.title}
              </Typography>

              {/* {props.employees == "" || props.employees == null ? (
                <div
                  style={{
                    height: "12px",
                    width: "50%",
                    backgroundColor: "#525151",
                  }}
                />
              ) : ( */}
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 12,
                  textAlign: "left",
                  color: "#ccc",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  // maxWidth: "100%",
                }}
              >
                {props.employees == 0 || props.employees == null
                  ? "-- trabajadores"
                  : props.employees == 1
                  ? "--" + " trabajadores"
                  : props.employees + " trabajadores"}
              </Typography>
              {/* )} */}
            </Grid>
          </Grid>

          <Grid item xs={3} container direction="row" justifyContent="flex-end">
            <div
              style={{
                width: "32px",
                height: "32px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "8px",
                backgroundImage:
                  "linear-gradient(91deg, #d233ff 1%, #ac0bce 99%)",
                marginLeft: "15px",
              }}
            >
              <FontAwesomeIcon
                icon={faClock}
                style={{
                  width: "19px",
                  height: "19px",
                  color: "#fff",
                  borderRadius: "5px",
                }}
              />
            </div>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-end"
        >
          {props.seconds == "" || props.seconds == null ? (
            <Typography
              style={{
                fontSize: 30,
                color: "white",
                textAlign: "left",
                marginRight: 10,
                // backgroundColor: "red",
              }}
            >
              {"--"}
            </Typography>
          ) : (
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 30,
                textAlign: "left",
                color: "#fff",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                marginRight: 10,
                // backgroundColor: "red",
              }}
            >
              {secondsToHms(props.seconds)?.hours}
            </Typography>
          )}

          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 12,
              textAlign: "left",
              color: "#ccc",
              marginRight: 10,
              // backgroundColor: "black",
            }}
          >
            Hrs
          </Typography>

          {props.seconds == "" || props.seconds == null ? (
            <Typography
              style={{
                fontSize: 30,
                color: "white",
                marginRight: 10,
              }}
            >
              {"--"}
            </Typography>
          ) : (
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 30,
                textAlign: "left",
                color: "#fff",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                maxWidth: "95%",
                marginRight: 10,
              }}
            >
              {secondsToHms(props.seconds)?.minutes}
            </Typography>
          )}

          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 12,
              textAlign: "left",
              color: "#ccc",
            }}
          >
            Min
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
}
