/*eslint-disable*/
import "./styles.css";
import React, { useState } from "react";
import useStyles from "../styles/form_login_styles";
import logoKnesys from "../../../images/isologotipo_general_colores.png";
import LoginComponets from "./LoginComponents";
import ValidateAccount from "../../RegisterScreen/components/ValidateAccount";

export default function FormLogin(props) {
  const classes = useStyles();
  const [step, setStep] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const renderScreen = (step) => {
    return step == false ? (
      <LoginComponets
        setStep={setStep}
        setEmail={setEmail}
        setPassword={setPassword}
      />
    ) : (
      <ValidateAccount email={email} password={password} />
    );
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {/* <img src={logoKnesys} className={classes.logo} alt="knesys logo" /> */}
      {renderScreen(step)}
    </div>
  );
}
