/*eslint-disable*/
import React, { useState, useEffect, useRef, useCallback } from "react";
import { Grid, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import CardEnterprise from "./Components/CardEnterprise";
import CardHours from "./Components/CardHours";
import FiltersComponent from "./Components/FiltersComponent";
import useWorkingTimeSearch from "./Components/useWorkingTimeSearch";
import { ipServer } from "../../../config/vars";
import axios from "axios";
import axiosInstance from "../../../utils/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { FiltersWorkingTime } from "../../../actions/filters";
import NotHoursLogged from "./Components/NotHoursLogged";

const useStyles = makeStyles((theme) => ({
  photo: {
    width: "40px",
    height: "40px",
    borderRadius: "8px",
    margin: "auto",
    aspectRatio: 1,
  },
  divMain: {
    flexGrow: 1,
    paddingTop: 10,
    height: "100%",
  },
  divBack: {
    cursor: "pointer",
    marginBottom: "10px",
  },
  back: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 500,
    textAlign: "left",
    color: "#fff",
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 32,
    fontWeight: 500,
    textAlign: "left",
    color: "#ffffff",
  },
  adornedStart: {
    backgroundColor: "red",
    height: "5rem",
    maxHeight: "8rem",
  },
  multi: {
    color: "white",
    padding: 2,
    border: 0,
    borderRadius: "8px",
  },
  formControl: {
    width: "90%",
  },
  select: {
    width: "100%",
    height: "40px",
    borderRadius: "6px",
    backgroundColor: "#27292d",
  },
}));

export default function WorkingTimeScreen(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { workingTime } = useSelector((state) => state.filters);
  const [pageNumber, setPageNumber] = useState(0);
  const [proyectos, setProyectos] = useState([]);
  const [unidades, setUnidades] = useState([]);
  const [subcontratistas, setSubcontratistas] = useState([]);
  const [query, setQuery] = useState({
    initialDate: workingTime?.initialDate ?? "",
    finalDate: workingTime?.finalDate ?? "",
    proyectos: workingTime?.proyectos ?? [],
    unidades: workingTime?.unidades ?? [],
    subcontratistas: workingTime?.subcontratistas ?? [],
  });

  const { books, hasMore, loading, error } = useWorkingTimeSearch(
    query,
    proyectos,
    subcontratistas
  );

  const handleQueryChange = (queryElement) => (e) => {
    const value =
      queryElement === "proyectos" ||
      queryElement === "subcontratistas" ||
      queryElement === "unidades"
        ? e
        : e.target.value;
    return setQuery((prevQuery) => {
      return { ...prevQuery, ...{ [queryElement]: value } };
    });
  };

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const getProyectsList = async () => {
    var params = new URLSearchParams();
    params.append("sortMongoAsc", "proyecto");

    await axiosInstance()
      .get(`/api/v1/rules/workingtime/comboboxes/obras`, { params })
      .then((response) => {
        const proyects = response.data.data.map((object) => {
          return { label: object.proyecto, value: object._id };
        });

        setProyectos(proyects);
        !workingTime &&
          setQuery((query) => {
            return {
              ...query,
              proyectos: proyects,
            };
          });
      })
      .catch((e) => {
        console.log(e.response);
      });
  };

  const getUnitList = async () => {
    var params = new URLSearchParams();
    params.append("sortMongoAsc", "name");

    await axiosInstance()
      .get(`/api/v1/rules/workingtime/comboboxes/unidad`, { params })
      .then((res) => {
        const units = res.data.data.map((object) => {
          return { label: object.name, value: object._id };
        });

        setUnidades(units);
        !workingTime &&
          setQuery((query) => {
            return {
              ...query,
              unidades: units,
            };
          });
      })
      .catch((e) => {
        console.log(e.response);
      });
  };

  const getSubcontractorList = async () => {
    var params = new URLSearchParams();
    params.append("showoculta", true);
    params.append("sortMongoAsc", "empresa");

    await axiosInstance()
      .get(`/api/v1/rules/workingtime/comboboxes/subcontratistas`, {
        params
      })
      .then((response) => {
        const subcontractors = response.data.data.map((object) => {
          return { label: object.empresa, value: object._id };
        });

        setSubcontratistas(subcontractors);
        !workingTime &&
          setQuery((query) => {
            return {
              ...query,
              subcontratistas: subcontractors,
            };
          });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getProyectsList();
    getUnitList();
    getSubcontractorList();
  }, []);

  useEffect(() => {
    setPageNumber(0);
    dispatch(FiltersWorkingTime(query));
  }, [query]);

  return (
    <div className={classes.divMain}>
      <Grid container>
        <Grid item xs={12}>
          <FiltersComponent
            query={query}
            setQuery={setQuery}
            loading={loading}
            handleQueryChange={handleQueryChange}
            proyectos={proyectos}
            unidades={unidades}
            subcontratistas={subcontratistas}
          />
        </Grid>

        <Grid item xs={12} style={{ height: "30px" }} />

        {books?.resumenSubcontratista?.length == 0 && !loading ? (
          <Grid item xs={12}>
            <NotHoursLogged />
          </Grid>
        ) : (
          <Grid container spacing={4}>
            <Grid item xs={12} container direction="row" spacing={3}>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <CardHours
                  title={"Total de horas"}
                  employees={books.totalTrabajadores}
                  seconds={books.totalSegundos}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <CardHours
                  title={"Horas indirectas"}
                  employees={books.trabajadoresIndirectas}
                  seconds={books.segundosIndirectas}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <CardHours
                  title={"Horas directas"}
                  employees={books.trabajadoresDirectas}
                  seconds={books.segundosDirectas}
                />
              </Grid>
            </Grid>

            {books?.resumenSubcontratista?.map((event, index) => {
              if (books?.resumenSubcontratista?.length === index + 1) {
                return (
                  <Grid
                    ref={lastBookElementRef}
                    item
                    xs={12}
                    sm={12}
                    md={3}
                    lg={3}
                    xl={3}
                    key={index}
                  >
                    <CardEnterprise
                      key={index}
                      id={event._id}
                      urlPhoto={event.foto}
                      enterprise={event.empresa}
                      businessName={event.razonSocial}
                      securityNumber={event.numeroSeguro}
                      seconds={event.segundos}
                      employees={event.trabajadores}
                      employeesPhoto={event.trabajadoresFoto}
                      setStep={props.setStep}
                    />
                  </Grid>
                );
              } else {
                return (
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3} key={index}>
                    <CardEnterprise
                      key={index}
                      id={event._id}
                      urlPhoto={event.foto}
                      enterprise={event.empresa}
                      businessName={event.razonSocial}
                      securityNumber={event.numeroSeguro}
                      seconds={event.segundos}
                      employees={event.trabajadores}
                      employeesPhoto={event.trabajadoresFoto}
                      setStep={props.setStep}
                    />
                  </Grid>
                );
              }
            })}
          </Grid>
        )}

        <Grid item xs={12}>
          <Box
            m={1}
            p={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {loading && (
              <CircularProgress disableShrink size={100} color="secondary" />
            )}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
