/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import ShowMoreButton from "../../components/ShowMoreButton";
import {
  ValidatePermissions,
  ValidateStatus,
} from "../../../../utils/permissions";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
  },
  gridBasic: {
    padding: theme.spacing(3),
    height: "100%",
    borderRadius: "16px",
    backgroundColor: "#2d3034",
  },
}));

export default function CardBasicDataEmployee(props) {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useSelector((state) => state.user);
  const [moreData, setMoreData] = useState(false);

  const handleMoreData = () => {
    setMoreData(!moreData);
  };

  const calcularAños = (fecha) => {
    const fechaNacimiento = new Date(fecha);
    const date = new Date();
    var years = date.getFullYear() - fechaNacimiento.getFullYear();

    if (
      date.getMonth() < fechaNacimiento.getMonth() ||
      (date.getMonth() == fechaNacimiento.getMonth() &&
        date.getDate() < fechaNacimiento.getDate())
    ) {
      years--;
    }

    return years;
  };

  const calcularAntiguedad = (fecha) => {
    const fechaIngreso = new Date(fecha);
    const date = new Date();
    var years = date.getFullYear() - fechaIngreso.getFullYear();
    var months = date.getMonth() - fechaIngreso.getMonth();
    var antiguedad = "";

    if (years == 0) antiguedad = months + " meses";
    if (months == 0) antiguedad = years + " años";
    if (years != 0 && months != 0)
      antiguedad = years + " años " + months + " meses";

    return antiguedad;
  };

  return (
    <div className={classes.divMain}>
      <Grid item xs={12} className={classes.gridBasic}>
        <Grid container direction="row">
          <Grid item xs={10}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 22,
                fontWeight: 500,
                color: "#fff",
              }}
            >
              Datos básicos trabajador
            </Typography>
          </Grid>

          {ValidatePermissions("editar") &&
            ValidateStatus(props?.employeeData?.statusDemek, user) && (
              <Grid
                item
                xs={2}
                container
                direction="row"
                justifyContent="flex-end"
              >
                <Typography
                  onClick={() =>
                    history.push(
                      `/dashboard/empleados/editar/${localStorage.getItem(
                        "idEmployee"
                      )}`
                    )
                  }
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 18,
                    fontWeight: 500,
                    color: "#d233ff",
                    cursor: "pointer",
                  }}
                >
                  Editar
                </Typography>
              </Grid>
            )}
        </Grid>

        <Grid container direction="row" style={{ marginTop: 40 }}>
          <Grid item xs={6}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 16,
                color: "#c1c1c1",
              }}
            >
              Nombre completo
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 16,
                color: "#c1c1c1",
                marginRight: 5,
              }}
            >
              CURP
            </Typography>
            {(user?.EnterpriseData?.ValidateImss &&
              props.employeeData?.estatusImss?.estatusActual ==
                "Información Incorrecta" &&
              props.employeeData?.estatusImss?.messageErrorBot ==
                "Los datos de entrada no coinciden con los datos de la entidad externa RENAPO.") ||
            props.employeeData?.estatusImss?.messageErrorBot ==
              "El NSS capturado no coincide con la CURP." ? (
              <FontAwesomeIcon
                icon={faExclamationCircle}
                style={{
                  width: "16px",
                  height: "16px",
                  color: "#ff0000",
                  paddingBottom: 2,
                }}
              />
            ) : null}
          </Grid>

          <Grid item xs={6}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 18,
                color: "#fff",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
                textOverflow: "ellipsis",
                overflow: "hidden",
                maxWidth: "90%",
              }}
            >
              {`${props.employeeData.nombre} ${props.employeeData.apellidos}`}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 18,
                color: "#fff",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                maxWidth: "90%",
              }}
            >
              {props.employeeData.curp.toUpperCase()}
            </Typography>
          </Grid>
        </Grid>

        <Grid container direction="row" style={{ marginTop: 20 }}>
          <Grid item xs={6}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 16,
                color: "#c1c1c1",
              }}
            >
              Subcontratista
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 16,
                color: "#c1c1c1",
              }}
            >
              {user?.EnterpriseData?.Db != "GrupoGarzaPonce903228"
                ? "Puesto"
                : "Especialidad"}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 18,
                color: "#fff",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                maxWidth: "90%",
              }}
            >
              {props.employeeData.subcontratista}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 18,
                color: "#fff",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                maxWidth: "90%",
              }}
            >
              {user?.EnterpriseData?.Db != "GrupoGarzaPonce903228"
                ? props.employeeData.puesto
                : props.employeeData.especialidad}
            </Typography>
          </Grid>
        </Grid>

        <Grid container direction="row" style={{ marginTop: 20 }}>
          <Grid item xs={12}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 16,
                color: "#c1c1c1",
              }}
            >
              Domicilio de trabajador
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 18,
                color: "#fff",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                maxWidth: "95%",
              }}
            >
              {props.employeeData.domicilio}
            </Typography>
          </Grid>
        </Grid>

        <Grid container direction="row" style={{ marginTop: 20 }}>
          <Grid item xs={6}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 16,
                color: "#c1c1c1",
              }}
            >
              Fecha de nacimiento
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 16,
                color: "#c1c1c1",
              }}
            >
              Edad
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 18,
                color: "#fff",
              }}
            >
              {props.employeeData.fechaNacimientoString}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 18,
                color: "#fff",
              }}
            >
              {props.employeeData.fechaNacimiento == "--"
                ? "--"
                : calcularAños(props.employeeData.fechaNacimiento) + " años"}
            </Typography>
          </Grid>
        </Grid>

        {moreData ? (
          <Grid container>
            <Grid container direction="row" style={{ marginTop: 20 }}>
              <Grid item xs={6}>
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 16,
                    color: "#c1c1c1",
                  }}
                >
                  Grado de estudios
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 16,
                    color: "#c1c1c1",
                  }}
                >
                  Antigüedad en el puesto
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 18,
                    color: "#fff",
                  }}
                >
                  {props.employeeData.gradoEstudios}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 18,
                    color: "#fff",
                  }}
                >
                  {props.employeeData.fechaIngreso == "--"
                    ? "--"
                    : calcularAntiguedad(props.employeeData.fechaIngreso)}
                </Typography>
              </Grid>
            </Grid>

            <Grid container direction="row" style={{ marginTop: 20 }}>
              <Grid item xs={6}>
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 16,
                    color: "#c1c1c1",
                  }}
                >
                  Sabe escribir
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 16,
                    color: "#c1c1c1",
                  }}
                >
                  Sabe leer
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 18,
                    color: "#fff",
                  }}
                >
                  {props.employeeData.escribir}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 18,
                    color: "#fff",
                  }}
                >
                  {props.employeeData.leer}
                </Typography>
              </Grid>
            </Grid>

            <Grid container direction="row" style={{ marginTop: 20 }}>
              <Grid item xs={6}>
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 16,
                    color: "#c1c1c1",
                  }}
                >
                  Lugar de nacimiento
                </Typography>
              </Grid>

              {props.employeeData.subcontratista ==
              user?.Subcontractor[0].empresa ? (
                <Grid item xs={6}>
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 16,
                      color: "#c1c1c1",
                    }}
                  >
                    Unidad
                  </Typography>
                </Grid>
              ) : (
                <Grid item xs={6}></Grid>
              )}

              <Grid item xs={6}>
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 18,
                    color: "#fff",
                  }}
                >
                  {props.employeeData.lugarNacimiento}
                </Typography>
              </Grid>

              {props.employeeData.subcontratista ==
              user?.Subcontractor[0].empresa ? (
                <Grid item xs={6}>
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 18,
                      color: "#fff",
                    }}
                  >
                    {props.employeeData.unidad}
                  </Typography>
                </Grid>
              ) : (
                <Grid item xs={6}></Grid>
              )}
            </Grid>
          </Grid>
        ) : (
          <div></div>
        )}

        <Grid
          item
          xs={12}
          container
          direction="row"
          justifyContent="center"
          style={{ marginTop: 25 }}
        >
          <ShowMoreButton onClick={handleMoreData} moreData={moreData} />
        </Grid>
      </Grid>
    </div>
  );
}
