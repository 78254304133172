/*eslint-disable*/
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button } from "@material-ui/core";
import Lottie from "react-lottie";
import WorkersAnimation from "../../../animations/workers_techton.json";

const useStyles = makeStyles((theme) => ({
  gridMain: {
    width: "80%",
    margin: "auto",
    // backgroundColor: "red",
  },
}));

export default function NotEmployeeFound(props) {
  const classes = useStyles();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: WorkersAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      className={classes.gridMain}
    >
      <Grid container direction="column" alignItems="center">
        <Lottie options={defaultOptions} height={300} width={300} />
        <Typography
          style={{
            fontFamily: "Roboto",
            fontSize: 26,
            fontWeight: 500,
            color: "#fff",
          }}
        >
          No se han encontrado empleados
        </Typography>
        <Typography
          style={{
            fontFamily: "Roboto",
            fontSize: 18,
            color: "#acacac",
          }}
        >
          No hemos encontrado empleados en la búsqueda
        </Typography>
      </Grid>
    </Grid>
  );
}
