/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "#171a1e",
  },
}));

export default function BackdropComponent(props) {
  const classes = useStyles();

  const handleClose = () => {
    props.setOpenBackdrop(!props.openBackdrop);
  };

  return (
    <Grid
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{}}
      container
    >
      <Backdrop
        className={classes.backdrop}
        open={props.openBackdrop}
        /* onClick={handleClose} */
      >
        <Grid
          direction="column"
          justifyContent="center"
          alignItems="center"
          container
          style={{ marginTop: "90px" }}
        >
          <CircularProgress color="inherit" />
          <Typography
            style={{
              fontSize: "18px",
              textAlign: "center",
              color: "#fff",
              marginTop: "25px",
            }}
          >
            Guardando perfil de empleado
          </Typography>

          <Typography
            style={{
              fontSize: "14px",
              textAlign: "center",
              color: "#b9b9b9",
              marginTop: "12px",
            }}
          >
            Espera a que se guarde el perfil del empleado
          </Typography>
        </Grid>
      </Backdrop>
    </Grid>
  );
}
