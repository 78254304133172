/*eslint-disable*/
import React, { useEffect, useState, useRef, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { TextField, Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import InputAdornment from "@material-ui/core/InputAdornment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import MultiSelect from "../../../common_components/Filters/MutiSelect";
import useSubcontractorSearch from "./useSubcontractorSearch";
import CardSubcontratista from "./CardSubcontratista";
import NotSubcontractorFound from "./NotSubcontractorFound";
import DialogInviteSubcontratista from "./DialogInviteSubcontratista";
import { useDispatch, useSelector } from "react-redux";
import { FiltersSubcontractors } from "../../../actions/filters";
import { ValidatePermissions } from "../../../utils/permissions";
import axiosInstance from "../../../utils/axiosInstance";
import * as Yup from "yup";
import { useFormik } from "formik";
import DialogUnitsList from "../../../common_components/Dialogs/DialogUnitsList";
import DialogAdminUnitsList from "../../../common_components/Dialogs/DialogAdminUnitsList";
import DialogAddNewUnit from "../../../common_components/Dialogs/DialogAddNewUnit";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    padding: 15,
  },
  multi: {
    color: "white",
    padding: 2,
    border: 0,
    borderRadius: "8px",
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 28,
    fontWeight: 600,
    textAlign: "left",
    color: "#ffffff",
    marginBottom: 25,
  },
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: "bold",
    textAlign: "left",
    color: "#cdcece",
  },
  textFieldCustom: {
    width: "478px",
    color: "#676767",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
  },
  buttonSearch: {
    width: "232px",
    height: "40px",
    borderRadius: "8px",
    color: "#ffffff",
    backgroundColor: "#ac0bce",
    textTransform: "none",
    marginLeft: 30,
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
      marginLeft: 0,
    },
  },
}));

export default function SearchSubcontratistaScreen(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { subcontractors } = useSelector((state) => state.filters);
  const [unidades, setUnidades] = useState(null);
  // const [estatus, setEstatus] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [openDialog, setOpendialog] = useState(false);
  const [openDialogUnits, setOpenDialogUnits] = useState(false);
  const [openDialogUnitsAdmin, setOpenDialogUnitsAdmin] = useState(false);
  const [openDialogAddNewUnit, setOpenDialogAddNewUnit] = useState(false);
  const [unitsFlag, setUnitsFlag] = useState(false);
  const [changesFlag, setChangesFlag] = useState(false);
  const [proyectos, setProyectos] = useState(null);
  const [unit, setUnit] = useState({
    id: "",
    type: "unidad",
    name: "",
    description: "",
  });

  const [query, setQuery] = useState({
    Empresa: subcontractors?.Empresa ?? "",
    Unidades: subcontractors?.Unidades ?? [],
    Proyectos: subcontractors?.Proyectos ?? [],
  });

  const { books, hasMore, loading, error } = useSubcontractorSearch(
    query,
    pageNumber,
    loadingData,
    proyectos,
    unidades
  );

  const handleQueryChange = (queryElement) => (e) => {
    const value =
      queryElement === "Proyectos" || queryElement === "Unidades"
        ? e
        : e.target.value;
    return setQuery((prevQuery) => {
      return { ...prevQuery, ...{ [queryElement]: value } };
    });
  };

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const handleInvite = () => {
    setOpendialog(!openDialog);
  };

  function rfcValido(rfc, aceptarGenerico = true) {
    const re =
      /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
    var validado = rfc.match(re);

    if (!validado) return false;

    const digitoVerificador = validado.pop(),
      rfcSinDigito = validado.slice(1).join(""),
      len = rfcSinDigito.length,
      diccionario = "0123456789ABCDEFGHIJKLMN&OPQRSTUVWXYZ Ñ",
      indice = len + 1;
    var suma, digitoEsperado;

    if (len == 12) suma = 0;
    else suma = 481;

    for (var i = 0; i < len; i++)
      suma += diccionario.indexOf(rfcSinDigito.charAt(i)) * (indice - i);
    digitoEsperado = 11 - (suma % 11);
    if (digitoEsperado == 11) digitoEsperado = 0;
    else if (digitoEsperado == 10) digitoEsperado = "A";

    if (
      digitoVerificador != digitoEsperado &&
      (!aceptarGenerico || rfcSinDigito + digitoVerificador != "XAXX010101000")
    )
      return false;
    else if (
      !aceptarGenerico &&
      rfcSinDigito + digitoVerificador == "XEXX010101000"
    )
      return false;
    return rfcSinDigito + digitoVerificador;
  }

  Yup.addMethod(Yup.string, "ValidateRFC", function (errorMessage) {
    return this.test(`test-card-length`, errorMessage, function (value) {
      const { path, createError } = this;

      return (
        (value && rfcValido(value)) ||
        createError({ path, message: errorMessage })
      );
    });
  });

  const schema = Yup.object({
    correo: Yup.string().email("Formato Incorrecto"),
    empresa: Yup.string().required("Campo Obligatorio"),
    unidad: Yup.string().required("Campo Obligatorio"),
    numeroSeguro: Yup.string()
      .required("Campo Obligatorio")
      .matches(/^[a-zA-Z]{1}(\d{10})?$/, "Registro Patronal Invalido"),
    obras: Yup.array().required("Campo Obligatorio"),
    rfc: Yup.string().required("Campo Obligatorio").ValidateRFC("RFC Invalido"),
  });

  const formik = useFormik({
    initialValues: {
      correo: "",
      empresa: "",
      unidad: " ",
      rfc: "",
      numeroSeguro: "",
      obras: [],
    },
    validationSchema: schema,
  });

  const validateData = async () => {
    return await schema.isValid(formik.values);
  };

  const getUnitList = () => {
    return new Promise((resolve, reject) => {
      var params = new URLSearchParams();
      params.append("sortMongoAsc", "name");

      axiosInstance()
        .get("/api/v1/rules/subcontratistas/comboboxes/unidad", { params })
        .then((res) => {
          let _unidades = res.data.data.map((object) => {
            return { label: object.name, value: object._id };
          });

          setUnidades(_unidades);
          resolve(_unidades);
        })
        .catch((e) => {
          console.log(e);
          resolve(false);
        });
    });
  };

  const getProyectsList = () => {
    return new Promise((resolve, reject) => {
      var params = new URLSearchParams();
      params.append("sortMongoAsc", "proyecto");

      axiosInstance()
        .get(`/api/v1/rules/subcontratistas/comboboxes/obras`, { params })
        .then((response) => {
          let proyects = response.data.data.map((object) => {
            return { label: object.proyecto, value: object._id };
          });

          setProyectos(proyects);
          resolve(proyects);
        })
        .catch((e) => {
          console.log(e);
          resolve(false);
        });
    });
  };

  useEffect(() => {
    setPageNumber(0);
    dispatch(FiltersSubcontractors(query));
  }, [query]);

  useEffect(() => {
    getUnitList();
    getProyectsList();
  }, []);

  return (
    <div className={classes.divMain}>
      <Typography className={classes.title}>Subcontratistas</Typography>
      <Grid container direction="row">
        <TextField
          id="enterprise"
          variant="outlined"
          size="small"
          placeholder="Buscar subcontratista"
          value={query?.Empresa}
          className={classes.textFieldCustom}
          onChange={handleQueryChange("Empresa")}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon
                  icon={faSearch}
                  style={{
                    width: "18px",
                    height: "18px",
                    color: "#676767",
                  }}
                />
              </InputAdornment>
            ),
          }}
        />

        {ValidatePermissions("crear") ? (
          <Button
            variant="contained"
            onClick={handleInvite}
            className={classes.buttonSearch}
          >
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 18,
                textAlign: "left",
                color: "#fff",
              }}
            >
              Invitar subcontratista
            </Typography>
          </Button>
        ) : (
          <div></div>
        )}
      </Grid>

      <Grid
        style={{
          height: "20px",
        }}
      />

      <Grid container spacing={3}>
        {/* <Grid item xs={2}>
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 16,
              textAlign: "left",
              color: "#dedcdc",
            }}
          >
            Estatus
          </Typography>
          <div>
            <MultiSelect
              options={estatus ? estatus : []}
              value={query.Estatus}
              onChange={handleQueryChange("Estatus")}
            />
          </div>
        </Grid> */}

        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 16,
              textAlign: "left",
              color: "#dedcdc",
            }}
          >
            Unidad
          </Typography>
          <div>
            <MultiSelect
              options={unidades ? unidades : []}
              value={query.Unidades}
              onChange={handleQueryChange("Unidades")}
            />
          </div>
        </Grid>

        <Grid item xs={2}>
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 16,
              textAlign: "left",
              color: "#dedcdc",
            }}
          >
            Obras
          </Typography>
          <div>
            <MultiSelect
              options={proyectos ? proyectos : []}
              value={query.Proyectos}
              onChange={handleQueryChange("Proyectos")}
            />
          </div>
        </Grid>
      </Grid>

      <Grid
        style={{
          height: "30px",
        }}
      />

      <Grid container spacing={4}>
        {books.length == 0 && !loading ? (
          <NotSubcontractorFound></NotSubcontractorFound>
        ) : (
          books.map((event, index) => {
            if (books.length === index + 1) {
              return (
                <Grid
                  ref={lastBookElementRef}
                  item
                  xs={12}
                  md={6}
                  lg={4}
                  xl={4}
                  sm={12}
                  key={index}
                >
                  <CardSubcontratista
                    idSubcontractor={event._id}
                    urlPhoto={event.foto ? event.foto.url : ""}
                    enterprise={event.empresa}
                    businessName={event.razonSocial}
                    description={event.descripcion}
                    rfc={event.rfc}
                    employees={
                      event.trabajadores_id ? event.trabajadores_id.length : ""
                    }
                    securityNumber={event.numeroSeguro}
                    agent={event.representante}
                    telephone={event.telefono}
                    status={event.estatus ? event.estatus : ""}
                    unit={event.unidad ? event.unidad : ""}
                  />
                </Grid>
              );
            } else {
              return (
                <Grid item xs={12} md={6} lg={4} xl={4} sm={12} key={index}>
                  <CardSubcontratista
                    idSubcontractor={event._id}
                    urlPhoto={event.foto ? event.foto.url : ""}
                    enterprise={event.empresa}
                    businessName={event.razonSocial}
                    description={event.descripcion}
                    rfc={event.rfc}
                    employees={
                      event.trabajadores_id ? event.trabajadores_id.length : ""
                    }
                    securityNumber={event.numeroSeguro}
                    agent={event.representante}
                    telephone={event.telefono}
                    status={event.estatus ? event.estatus : ""}
                    unit={event.unidad ? event.unidad : ""}
                  />
                </Grid>
              );
            }
          })
        )}
      </Grid>

      <DialogInviteSubcontratista
        formik={formik}
        validateData={validateData}
        setOpendialog={setOpendialog}
        openDialog={openDialog}
        openDialogUnits={openDialogUnits}
        setOpenDialogUnits={setOpenDialogUnits}
      />

      <DialogUnitsList
        formik={formik}
        unitsFlag={unitsFlag}
        setChangesFlag={setChangesFlag}
        openDialogUnits={openDialogUnits}
        setOpenDialogUnits={setOpenDialogUnits}
        openDialogUnitsAdmin={openDialogUnitsAdmin}
        setOpenDialogUnitsAdmin={setOpenDialogUnitsAdmin}
      />

      <DialogAdminUnitsList
        unit={unit}
        setUnit={setUnit}
        unitsFlag={unitsFlag}
        setUnitsFlag={setUnitsFlag}
        openDialogUnits={openDialogUnits}
        setOpenDialogUnits={setOpenDialogUnits}
        openDialogUnitsAdmin={openDialogUnitsAdmin}
        setOpenDialogUnitsAdmin={setOpenDialogUnitsAdmin}
        openDialogAddNewUnit={openDialogAddNewUnit}
        setOpenDialogAddNewUnit={setOpenDialogAddNewUnit}
      />

      <DialogAddNewUnit
        unit={unit}
        setUnit={setUnit}
        unitsFlag={unitsFlag}
        setUnitsFlag={setUnitsFlag}
        openDialogUnitsAdmin={openDialogUnitsAdmin}
        setOpenDialogUnitsAdmin={setOpenDialogUnitsAdmin}
        openDialogAddNewUnit={openDialogAddNewUnit}
        setOpenDialogAddNewUnit={setOpenDialogAddNewUnit}
      />
    </div>
  );
}
