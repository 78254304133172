/*eslint-disable*/
import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles((theme) => ({
  divMain: {
    marginTop: 20,
  },
  typoLabel: {
    fontFamily: "Roboto",
    fontSize: 20,
    fontWeight: 500,
    textAlign: "left",
    color: "#fff",
  },
  typoNumber: {
    fontFamily: "Roboto",
    fontSize: 24,
    fontWeight: 500,
    textAlign: "left",
    color: "#fff",
    marginRight: 8,
  },
  typoText: {
    fontFamily: "Roboto",
    fontSize: 18,
    textAlign: "left",
    color: "#c1c1c1",
  },
}));

export default function CardComponent(props) {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      className={classes.divMain}
    >
      <Grid item xs={2}>
        {props.icon == 1 ? (
          <div
            style={{
              width: "32px",
              height: "32px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "8px",
              backgroundImage:
                "linear-gradient(91deg, #d233ff 1%, #ac0bce 99%)",
            }}
          >
            <FontAwesomeIcon
              icon={faClock}
              style={{
                width: "19px",
                height: "19px",
                color: "#fff",
                borderRadius: "5px",
              }}
            />
          </div>
        ) : (
          <div
            style={{
              width: "32px",
              height: "32px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "8px",
              backgroundColor: "#fff",
            }}
          >
            <FontAwesomeIcon
              icon={faCalendarAlt}
              style={{
                width: "19px",
                height: "19px",
                color: "#d233ff",
              }}
            />
          </div>
        )}
      </Grid>

      <Grid
        item
        xs={10}
        container
        direction="column"
        justifyContent="flex-start"
      >
        <Typography className={classes.typoLabel}>{props.title}</Typography>
        <Grid container direction="row" alignItems="center">
          <Typography className={classes.typoNumber}>{props.number}</Typography>
          <Typography className={classes.typoText}>{props.type}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
