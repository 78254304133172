/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, TextField, Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { SetScreenProfile } from "../../../actions/screen";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Lottie from "react-lottie";
import UserFirmasAnimation from "../../../animations/users_firmas.json";
import WorkersTechtonAnimation from "../../../animations/workers_techton.json";
import notFoundEmployee from "../../../animations/lottie_not_found.json";

import useUserSearch from "./useUserSearch";
import CardEmployee from "./CardUser";
import ScrollBars from "../../../common_components/ScrollBars";

import {
  faTimes,
  faSearch,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles((theme) => ({
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: "bold",
    textAlign: "left",
    color: "#cdcece",
  },
  textFieldCustom: {
    width: "350px",
    color: "#676767",
    border: "solid 1px #c5c5c5",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
  },
  divImg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "356px",
    height: "290px",
    borderRadius: "8px",
    border: "solid 1px #707070",
    backgroundColor: "#212429",
    cursor: "pointer",
  },
  trackVertical: {
    height: "95%",
    right: 0,
    width: "10px !important",
  },
  thumbVertical: {
    backgroundColor: "#2d3034",
    height: "100%",
    right: 0,
    width: "8px !important",
    borderRadius: "8px",
  },
}));

export default function DialogSearchUser(props) {
  const classes = useStyles();

  const handleOpenDialog = () => {
    props.setOpenDialogSearchUser(!props.openDialogSearchUser);
  };

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    noKeyboard: true,
    accept: "image/*",
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      acceptedFiles.map((file) => processImage(file));
    },
    // noClick: true
  });

  const processImage = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let fileURL = window.URL.createObjectURL(file);
      props.setUserData({
        ...props.userData,
        foto: file,
        fotoCache: fileURL,
        fotoReq: false,
      });
      props.setOpenDialogChooseSign(!props.openDialogChooseSign);
    };
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: UserFirmasAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: notFoundEmployee,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [pageNumber, setPageNumber] = useState(0);

  const [loadingData, setLoadingData] = useState(false);

  const [query, setQuery] = useState({
    Usuario: "",
  });

  useEffect(() => {
    setPageNumber(0);
  }, [query]);

  const { books, hasMore, loading, error } = useUserSearch(
    query,
    pageNumber,
    loadingData
  );

  const handleQueryChange = (queryElement) => (e) => {
    const value = e.target.value;
    return setQuery((prevQuery) => {
      return { ...prevQuery, ...{ [queryElement]: value } };
    });
  };

  return (
    /** 
    <div>
      <Dialog
        open={props.openDialogSearchUser}
        onClose={handleOpenDialog}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            width: "483px",
            height: "562px",
            backgroundColor: "#171a1e",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                lineHeight: 1.3,
                textAlign: "left",
                color: "#fff",
              }}
            >
              Asignación de firma
            </Typography>

            <Grid
              container
              justifyContent="flex-end"
              style={{ position: "absolute" }}
            >
              <Button onClick={handleOpenDialog}>
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{
                    width: "16px",
                    height: "16px",
                    color: "#fff",
                  }}
                />
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>
        <div
          style={{
            width: "482px",
            height: 0,
            border: "solid 1px #707070",
          }}
        ></div>

        <DialogContent>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <TextField
              variant="outlined"
              size="small"
              placeholder="Buscar usuario"
              style={{
                width: "391px",
                height: "40px",
                borderRadius: "8px",
                backgroundColor: "#fff",
                marginTop: "38px",
                marginBottom: "108.6px",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon
                      icon={faSearch}
                      style={{
                        width: "18px",
                        height: "18px",
                        color: "#676767",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />

            <FontAwesomeIcon
              icon={faUserCircle}
              style={{
                width: "48px",
                height: "48px",
                color: "#9a9a9a",
                marginBottom: "31.9px",
              }}
            />
            <Typography
              style={{
                fontFamily: "SegoeUI",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: 1.31,
                textAlign: "left",
                color: "#fff",
                marginBottom: "4px",
              }}
            >
              Busca usuario para asignar una firma
            </Typography>

            <Typography
              style={{
                fontSize: "14px",
                lineHeight: 1.36,
                textAlign: "s",
                color: "#8f8f8f",
              }}
            >
              Con la foto podrás generar firmas digitales
            </Typography>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>*/
    <div>
      <Dialog
        open={props.openDialogSearchUser}
        onClose={handleOpenDialog}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            width: "483px",
            height: "562px",
            backgroundColor: "#171a1e",
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                lineHeight: 1.3,
                textAlign: "left",
                color: "#fff",
              }}
            >
              Asignación de firma
            </Typography>

            <Grid
              container
              justifyContent="flex-end"
              style={{ position: "absolute" }}
            >
              <Button onClick={handleOpenDialog}>
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{
                    width: "16px",
                    height: "16px",
                    color: "#fff",
                  }}
                />
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>
        <div
          style={{
            width: "482px",
            height: 0,
            border: "solid 1px #707070",
          }}
        ></div>

        <DialogContent>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <TextField
              variant="outlined"
              size="small"
              placeholder="Buscar usuario"
              style={{
                width: "391px",
                height: "40px",
                borderRadius: "8px",
                backgroundColor: "#fff",
                marginTop: "38px",
                marginBottom: "29px",
              }}
              onChange={handleQueryChange("Usuario")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon
                      icon={faSearch}
                      style={{
                        width: "18px",
                        height: "18px",
                        color: "#676767",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
            {query.Usuario == "" ? (
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                style={{
                  height: "350px",
                }}
              >
                <FontAwesomeIcon
                  icon={faUserCircle}
                  style={{
                    width: "48px",
                    height: "48px",
                    color: "#9a9a9a",
                    marginBottom: "31.9px",
                  }}
                />
                <Typography
                  style={{
                    fontFamily: "SegoeUI",
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: 1.31,
                    textAlign: "left",
                    color: "#fff",
                    marginBottom: "4px",
                  }}
                >
                  Busca usuario para asignar una firma
                </Typography>

                <Typography
                  style={{
                    fontSize: "14px",
                    lineHeight: 1.36,
                    textAlign: "s",
                    color: "#8f8f8f",
                  }}
                >
                  Con la foto podrás generar firmas digitales
                </Typography>
              </Grid>
            ) : (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{
                  marginTop: 10,
                  paddingLeft: 20,
                }}
              >
                {books.length > 0 ? (
                  <ScrollBars style={{ width: "100%", height: "45vh" }}>
                    {books.map((item, index) => {
                      return (
                        <CardEmployee
                          key={index}
                          openDialogSearchUser={props.openDialogSearchUser}
                          setOpenDialogSearchUser={
                            props.setOpenDialogSearchUser
                          }
                          urlPhoto={item?.foto}
                          name={item?.Name}
                          email={item?.Email}
                          // enterprise={item?.empresa}
                          // unit={item?.unidad}
                        />
                      );
                    })}
                  </ScrollBars>
                ) : (
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                      height: "300px",
                    }}
                  >
                    <Lottie
                      options={defaultOptions2}
                      height={126}
                      width={149}
                    />
                    <Typography
                      style={{
                        fontFamily: "SegoeUI",
                        fontSize: "16px",
                        fontWeight: 600,
                        lineHeight: 1.31,
                        textAlign: "center",
                        color: "#fff",
                      }}
                    >
                      No se han encontrado usuarios
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "14px",
                        lineHeight: 1.36,
                        textAlign: "center",
                        color: "#8f8f8f",
                      }}
                    >
                      No se han encontrado usuarios en la busqueda
                    </Typography>
                  </Grid>
                )}
              </Grid>
            )}
            {/* <Grid
              container
              direction="row"
              justifyContent="center"
              style={{
                width: "393px",
                height: "94px",
                borderRadius: "12px",
                backgroundColor: "#2d3034",
                marginBottom: "16px",
              }}
            >
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{
                  width: "48px",
                  height: "48px",
                  backgroundColor: "#d233ff",
                  borderRadius: "50px",
                  marginTop: "23px",
                  marginRight: "23px",
                }}
              >
                <Typography
                  style={{
                    fontSize: "24px",
                    fontWeight: 500,
                    lineHeight: 1.33,
                    textAlign: "left",
                    color: "#fff",
                  }}
                >
                  JM
                </Typography>
              </Grid>
              <Grid
                style={{
                  marginTop: "25px",
                }}
              >
                <Typography
                  style={{
                    fontSize: "16px",
                    fontWeight: 500,
                    lineHeight: 1.31,
                    textAlign: "left",
                    color: "#fff",
                  }}
                >
                  Javier Medina Cazarez
                </Typography>
                <Typography
                  style={{
                    fontSize: "14px",
                    lineHeight: 1.36,
                    textAlign: "left",
                    color: "#acacac",
                  }}
                >
                  javiermedinacazares@gmail.com
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="center"
              style={{
                width: "393px",
                height: "94px",
                borderRadius: "12px",
                backgroundColor: "#2d3034",
                marginBottom: "16px",
              }}
            >
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{
                  width: "48px",
                  height: "48px",
                  backgroundColor: "#d233ff",
                  borderRadius: "50px",
                  marginTop: "23px",
                  marginRight: "23px",
                }}
              >
                <Typography
                  style={{
                    fontSize: "24px",
                    fontWeight: 500,
                    lineHeight: 1.33,
                    textAlign: "left",
                    color: "#fff",
                  }}
                >
                  JM
                </Typography>
              </Grid>
              <Grid
                style={{
                  marginTop: "25px",
                }}
              >
                <Typography
                  style={{
                    fontSize: "16px",
                    fontWeight: 500,
                    lineHeight: 1.31,
                    textAlign: "left",
                    color: "#fff",
                  }}
                >
                  Javier Medina Cazarez
                </Typography>
                <Typography
                  style={{
                    fontSize: "14px",
                    lineHeight: 1.36,
                    textAlign: "left",
                    color: "#acacac",
                  }}
                >
                  javiermedinacazares@gmail.com
                </Typography>
              </Grid>
            </Grid> */}
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
