/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  TextField,
  Select,
  FormControl,
  MenuItem,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    width: "100%",
  },
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "left",
    color: "#cdcece",
  },
  textFieldCustom: {
    width: "80%",
    color: "#676767",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  formControl: {
    width: "70%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  select: {
    width: "100%",
    height: "40px",
    borderRadius: "6px",
    backgroundColor: "#fff",
  },
  gridMargin: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
}));

export default function InfoHealthAdvance(props) {
  const classes = useStyles();
  const [allergyFlag, setAllergyFlag] = useState(false);

  const handleChange = (e) => {
    props.setChangesFlag(true);
    const value = e.target.value;
    props.setEmployeeData({
      ...props.employeeData,
      [e.target.name]: value,
    });
    // console.log(props.employeeData);
  };

  // useEffect(() => {
  //   // console.log(props.employeeData.alergias);
  //   if (props.employeeData.alergias == "Si") setAllergyFlag(true);
  //   else {
  //     setAllergyFlag(false);
  //     props.setEmployeeData({
  //       ...props.employeeData,
  //       tipoAlergia: "",
  //     });
  //   }
  // }, [props.employeeData.alergias]);

  useEffect(() => {}, [props.employeeData]);

  return (
    <Grid container className={classes.divMain}>
      <Grid
        item
        lg={12}
        xl={12}
        style={{
          height: "80px",
        }}
      />

      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          className={classes.gridMargin}
        >
          <Typography className={classes.textFieldLetter}>
            ¿Padece alguna enfermedad crónica degenerativa?
          </Typography>
          <FormControl className={classes.formControl}>
            <Select
              name="enfermedadCronica"
              value={props.employeeData.enfermedadCronica}
              onChange={handleChange}
              defaultValue=""
              inputProps={{ "aria-label": "Without label" }}
              className={classes.select}
            >
              <MenuItem value=" "> </MenuItem>
              <MenuItem value="Si">&ensp;Si</MenuItem>
              <MenuItem value="No">&ensp;No</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          className={classes.gridMargin}
        >
          {props.employeeData.enfermedadCronica == "Si" ? (
            <div>
              <Typography className={classes.textFieldLetter}>
                ¿Cuál enfermedad?
              </Typography>
              <TextField
                name="tipoEnfermedadCronica"
                size="small"
                variant="outlined"
                value={props.employeeData.tipoEnfermedadCronica}
                className={classes.textFieldCustom}
                onChange={handleChange}
                inputProps={{
                  maxLength: 50,
                  style: { textTransform: "capitalize" },
                }}
              />
            </div>
          ) : (
            <div></div>
          )}
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            height: "30px",
          }}
        ></Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          className={classes.gridMargin}
        >
          <Typography className={classes.textFieldLetter}>
            ¿Se encuentra bajo tratamiento médico?
          </Typography>
          <FormControl className={classes.formControl}>
            <Select
              name="tratamientoMedico"
              value={props.employeeData.tratamientoMedico}
              onChange={handleChange}
              defaultValue=""
              inputProps={{ "aria-label": "Without label" }}
              className={classes.select}
            >
              <MenuItem value=" "> </MenuItem>
              <MenuItem value="Si">&ensp;Si</MenuItem>
              <MenuItem value="No">&ensp;No</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          className={classes.gridMargin}
        >
          {props.employeeData.tratamientoMedico == "Si" ? (
            <div>
              <Typography className={classes.textFieldLetter}>
                Indique el tratamiento médico
              </Typography>
              <TextField
                name="tipoTratamientoMedico"
                size="small"
                variant="outlined"
                value={props.employeeData.tipoTratamientoMedico}
                className={classes.textFieldCustom}
                onChange={handleChange}
                inputProps={{
                  maxLength: 50,
                  style: { textTransform: "capitalize" },
                }}
              />
            </div>
          ) : (
            <div></div>
          )}
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            height: "30px",
          }}
        ></Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          className={classes.gridMargin}
        >
          <Typography className={classes.textFieldLetter}>
            ¿Esta tomando algún medicamento?
          </Typography>
          <FormControl className={classes.formControl}>
            <Select
              name="medicamento"
              value={props.employeeData.medicamento}
              onChange={handleChange}
              defaultValue=""
              inputProps={{ "aria-label": "Without label" }}
              className={classes.select}
            >
              <MenuItem value=" "> </MenuItem>
              <MenuItem value="Si">&ensp;Si</MenuItem>
              <MenuItem value="No">&ensp;No</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          className={classes.gridMargin}
        >
          {props.employeeData.medicamento == "Si" ? (
            <div>
              <Typography className={classes.textFieldLetter}>
                Enliste los medicamentos
              </Typography>
              <TextField
                name="tipoMedicamento"
                size="small"
                variant="outlined"
                value={props.employeeData.tipoMedicamento}
                className={classes.textFieldCustom}
                onChange={handleChange}
                inputProps={{
                  maxLength: 50,
                  style: { textTransform: "capitalize" },
                }}
              />
            </div>
          ) : (
            <div></div>
          )}
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            height: "30px",
          }}
        ></Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          className={classes.gridMargin}
        >
          <Typography className={classes.textFieldLetter}>
            ¿Requiere lentes de seguridad con graduación?
          </Typography>
          <FormControl className={classes.formControl}>
            <Select
              name="lentes"
              value={props.employeeData.lentes}
              onChange={handleChange}
              defaultValue=""
              inputProps={{ "aria-label": "Without label" }}
              className={classes.select}
            >
              <MenuItem value=" "> </MenuItem>
              <MenuItem value="Si">&ensp;Si</MenuItem>
              <MenuItem value="No">&ensp;No</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          className={classes.gridMargin}
        >
          {props.employeeData.lentes == "Si" ? (
            <div>
              <Typography className={classes.textFieldLetter}>
                Indique la graduación para los lentes de seguridad
              </Typography>
              <TextField
                name="tipoLentes"
                size="small"
                variant="outlined"
                value={props.employeeData.tipoLentes}
                className={classes.textFieldCustom}
                onChange={handleChange}
                inputProps={{
                  maxLength: 50,
                }}
              />
            </div>
          ) : (
            <div></div>
          )}
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            height: "30px",
          }}
        ></Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          className={classes.gridMargin}
        >
          <Typography className={classes.textFieldLetter}>
            ¿Padece algún tipo de alergias?
          </Typography>
          <FormControl className={classes.formControl}>
            <Select
              name="alergias"
              value={props.employeeData.alergias}
              onChange={handleChange}
              defaultValue=""
              inputProps={{ "aria-label": "Without label" }}
              className={classes.select}
            >
              <MenuItem value=" "> </MenuItem>
              <MenuItem value="Si">&ensp;Si</MenuItem>
              <MenuItem value="No">&ensp;No</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          className={classes.gridMargin}
        >
          {props.employeeData.alergias == "Si" ? (
            <div>
              <Typography className={classes.textFieldLetter}>
                ¿Cuál tipo alergia?
              </Typography>
              <TextField
                name="tipoAlergia"
                size="small"
                variant="outlined"
                value={props.employeeData.tipoAlergia}
                className={classes.textFieldCustom}
                onChange={handleChange}
                inputProps={{
                  maxLength: 50,
                  style: { textTransform: "capitalize" },
                }}
              />
            </div>
          ) : (
            <div></div>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
