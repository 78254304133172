/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faTimes, faCog } from "@fortawesome/free-solid-svg-icons";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ScrollBars from "../../../common_components/ScrollBars";
import useSpecialtySearch from "./useSpecialtySearch";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: "bold",
    textAlign: "left",
    color: "#cdcece",
  },
  textFieldCustom: {
    width: "90%",
    color: "#676767",
    border: "solid 1px #c5c5c5",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
  },
  textFieldCustom2: {
    width: "100%",
    color: "#676767",
    border: "solid 1px #c5c5c5",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
  },
}));

export default function DialogSpecialtyList(props) {
  const classes = useStyles();
  const { user } = useSelector((state) => state.user);
  const { specialtyFlag } = props;
  const [query, setQuery] = useState({
    Type: "trabajador_Especialidades",
    Nombre: "",
  });

  const { books, hasMore, loading, error } = useSpecialtySearch(
    query,
    specialtyFlag
  );

  const handleOpenDialog = () => {
    props.setOpenDialogSpecialty(!props.openDialogSpecialty);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setQuery({
      ...query,
      [e.target.name]: value,
    });
  };

  const jobsList = () => {
    // console.log(books);
    books.sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });

    const items = books.map((job, index) => {
      return (
        <Grid
          key={index}
          onClick={() => {
            props.setEmployeeData({
              ...props.employeeData,
              especialidad: job.name,
            });
            props.setChangesFlag(true);
            props.setOpenDialogSpecialty(!props.openDialogSpecialty);
          }}
          container
          direction="row"
          justifyContent="flex-start"
          style={{
            cursor: "pointer",
            paddingTop: "5%",
            paddingLeft: "5%",
          }}
        >
          <Grid item xs={10}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 20,
                fontWeight: "bold",
                textAlign: "left",
                color: "#fff",
              }}
            >
              {job.name}
            </Typography>
          </Grid>

          <Grid
            item
            xs={2}
            container
            direction="row"
            justifyContent="flex-end"
            style={{
              paddingRight: "5%",
              // backgroundColor: "red"
            }}
          >
            <FontAwesomeIcon
              icon={faChevronRight}
              className={classes.iconForm}
              style={{
                width: "10px",
                height: "20px",
                color: "#fff",
              }}
            />
          </Grid>
        </Grid>
      );
    });
    return items;
  };

  return (
    <div>
      <Dialog
        open={props.openDialogSpecialty}
        onClose={handleOpenDialog}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            backgroundColor: "#171a1e",
            textColor: "#fsf",
            width: "483px",
            height: "600px",
            paddingTop: 15,
          },
        }}
      >
        <DialogTitle>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 22,
                textAlign: "center",
                color: "#fff",
                fontWeight: "bold",
              }}
            >
              Especialidades
            </Typography>

            <Grid
              container
              justifyContent="flex-end"
              style={{ position: "absolute" }}
            >
              <Button onClick={handleOpenDialog}>
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{
                    width: "20px",
                    height: "20px",
                    color: "#fff",
                  }}
                />
              </Button>
            </Grid>
          </Grid>
          <div
            style={{
              width: "100%",
              height: 1,
              background: "#707070",
              marginTop: 8,
              padding: 0,
            }}
          ></div>
        </DialogTitle>

        <DialogContent>
          <Grid
            container
            direction="column"
            justifyContent="center"
            // alignItems="flex-end"
          >
            {user?.Subcontractor[0]?.empresaPadre ? (
              <Grid
                container
                direction="row"
                // justifyContent="flex-end"
              >
                <Grid item xs={10}>
                  <TextField
                    placeholder="Buscar..."
                    name="Nombre"
                    size="small"
                    variant="outlined"
                    value={query?.Nombre}
                    className={classes.textFieldCustom2}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button
                    onClick={() => {
                      props.setOpenDialogSpecialty(!props.openDialogSpecialty);
                      props.setOpenDialogSpecialtyAdmin(!props.openDialogSpecialtyAdmin);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faCog}
                      style={{
                        width: "30px",
                        height: "30px",
                        color: "#fff",
                      }}
                    />
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <TextField
                  placeholder="Buscar..."
                  name="Nombre"
                  size="small"
                  variant="outlined"
                  value={query?.Nombre}
                  className={classes.textFieldCustom}
                  onChange={handleChange}
                />
              </Grid>
            )}

            <ScrollBars style={{ height: 410 }}>{jobsList()}</ScrollBars>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
