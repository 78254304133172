/*eslint-disable*/
import React from "react";
import ReactDOM from "react-dom";
import AppScreen from "./screens/app_screen/app_screen";
import { store } from "./store/store";
import { Provider } from "react-redux";
import {library} from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/free-solid-svg-icons';

const iconList = Object.keys(Icons)
  .filter((key) => key !== 'fas' && key !== 'prefix')
  .map((icon) => Icons[icon]);

library.add(...iconList);

const container = document.getElementById("app");

ReactDOM.render(
  <Provider store={store}>
    <AppScreen />
  </Provider>,
  container
);
