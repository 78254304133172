/*eslint-disable*/
import { useEffect, useState } from "react";
import axios from "axios";
import axiosInstance from "../../../utils/axiosInstance";
import secondsToHms from "../../../utils/secondsToHms";

export default function useBooksSearch(query, pageNumber) {
  const [loading, setLoading] = useState(true);
  const [error2, setError2] = useState(false);
  const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [hours, sethours] = useState(0);

  useEffect(() => {
    setRows([]);
  }, [query]);

  useEffect(() => {
    var params = new URLSearchParams();
    params.append("page", query.page - 1);
    params.append("limit", query.pageSize);
    params.append("trabajadores_id", localStorage.getItem("idEmployee"));
    params.append("lookup", "catalogos");
    //   params.append("lookup", "respuestasformularios");

    params.append(
      "initialDate",
      new Date(query.intialDate.setHours(0, 0, 0, 0))
    );
    params.append(
      "finalDate",
      new Date(query.finalDate.setHours(23, 59, 59, 999))
    );

    setLoading(true);
    setError2(false);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    axiosInstance()
      .get("/api/v1/rules/workingtime/filter", {
        params,
        cancelToken: source.token,
      })
      .then((response) => {
        let seconds = 0;
        // console.log(response.data.data.Results);
        response.data.data.Results.map((event, index) => {
          // console.log(`${index}: ${event.segundosTrabajados}`);
          seconds += event.segundosTrabajados;
        });

        sethours(secondsToHms(seconds).hours);
        // result.map((asistencia) => {
        //   asistencia.id = asistencia._id;
        // });

        // setRows(result);
        // setTotalRows(parseInt(response.data.data.Total));
        // setLoading(false);
      })
      .catch((e) => {
        console.log("error");
        if (axios.isCancel(e)) return;
        setError2(true);
      });
    return () => source.cancel();
  }, [query, pageNumber]);

  return { hours, error2 };
}
