/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { makeStyles } from "@material-ui/styles";
import TopTab from "./Components/TopTab";
import WorkingTimeScreen from "./WorkingTimeScreen";
import ObservationsScreen from "./ObservationsScreen";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    paddingTop: 10,
    height: "100%",
  },
  divBack: {
    maxWidth: "120px",
    cursor: "pointer",
    marginBottom: "10px",
    // backgroundColor: "red",
  },
  back: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 500,
    textAlign: "left",
    color: "#fff",
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 32,
    fontWeight: 500,
    textAlign: "left",
    color: "#ffffff",
  },
}));

export default function WorkingTime(props) {
  const classes = useStyles();
  const history = useHistory();
  const [tab, setTab] = useState(1);

  const backStep = () => {
    history.push("/dashboard/reportes");
  };

  return (
    <div className={classes.divMain}>
      <Grid container direction="column">
        <Grid
          container
          direction="row"
          alignItems="center"
          onClick={backStep}
          className={classes.divBack}
        >
          <Grid item>
            <FontAwesomeIcon
              icon={faArrowAltCircleLeft}
              style={{
                width: "20px",
                height: "20px",
                marginRight: "10px",
                color: "#d6d6d6",
              }}
            />
          </Grid>
          <Grid item>
            <Typography className={classes.back}>Regresar</Typography>
          </Grid>
        </Grid>

        <Typography
          style={{
            marginBottom: 10,
            marginTop: 10,
            fontFamily: "Roboto",
            fontSize: 30,
            fontWeight: 500,
            textAlign: "left",
            color: "#fff",
          }}
        >
          Working time
        </Typography>

        <Grid /* item xs={12} */>
          <TopTab tab={tab} setTab={setTab}></TopTab>
        </Grid>

        <Grid /* item xs={12} */>
          {tab === 1 ? (
            <WorkingTimeScreen setStep={props.setStep} />
          ) : (
            <ObservationsScreen />
          )}
        </Grid>
      </Grid>
    </div>
  );
}
