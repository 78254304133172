/*eslint-disable*/
import { useEffect, useState } from "react";
import axios from "axios";
import axiosInstance from "../../../utils/axiosInstance";
import { useDispatch, useSelector } from "react-redux";

export default function useBookSearch(query, pageNumber, loadingData) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [books, setBooks] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    setBooks([]);
  }, [query]);

  useEffect(() => {
    if (user) {
      setLoading(true);
      setError(false);
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      var params = new URLSearchParams();
      params.append("limit", 11);
      params.append("page", pageNumber);
      params.append("Name_partial", query.Usuario);

      ///   if (user?.RoleName == "Administrador Subcontratista")
      /// params.append("subcontratistas_id", user.SubcontractorId);

      axiosInstance()
        .get("/api/v1/security/user", {
          params,
          cancelToken: source.token,
        })
        .then((res) => {
          // console.log(res.data.data.Results);
          setBooks((prevBooks) => {
            return [...new Set([...prevBooks, ...res.data.data.Results])];
          });
          setHasMore(res.data.data.Results.length > 0);
          setLoading(false);
        })
        .catch((e) => {
          if (axios.isCancel(e)) return;
          setError(true);
        });
      return () => source.cancel();
    }
  }, [query, pageNumber, loadingData]);

  return { loading, error, books, hasMore };
}
