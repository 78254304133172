/*eslint-disable*/
import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { Grid, Typography, TextField, Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import InputAdornment from "@material-ui/core/InputAdornment";
import axios from "axios";
import useVideosSearch from "./components/useVideosSearch";
import DateRange from "../../common_components/Filters/DateRange";
import DataGridVideos from "./components/DataGridVideos";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../utils/axiosInstance";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { CSVLink } from "react-csv";
import { dateFilter } from "../../utils/getRangeDateFilter";
import BackdropComponent from "./components/Backdrop";
import { saveAs } from "file-saver";
import { ipServer } from "../../config/vars";
import DateHourComponent from "../../common_components/DateHourComponent";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    height: "100%",
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 32,
    fontWeight: 500,
    textAlign: "left",
    color: theme.palette.secondary.dark,
  },
  root: {
    width: "100%",
    background: "white",
    borderRadius: "8px",
    "& .MuiInputBase-input": {
      color: "676767 !important",
    },
  },
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 16,
    textAlign: "left",
    color: theme.palette.secondary.main,
  },
  adornedStart: {
    backgroundColor: "red",
    height: "5rem",
    maxHeight: "8rem",
  },
}));

export default function VideosScreen(props) {
  const classes = useStyles();
  const history = useHistory();
  const [percentage, setPercentage] = useState(0);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [selectionRows, setSelectionRows] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  // const [dateRange, setDateRange] = useState([
  //   {
  //     startDate: new Date(),
  //     endDate: new Date(),
  //     key: "selection",
  //   },
  // ]);
  const [query, setQuery] = useState({
    Order: "",
    intialDate: new Date(new Date().setHours(0, 0, 0, 0)),
    finalDate: new Date(new Date().setHours(23, 59, 59, 999)),
    pageSize: 10,
    rowsPerPageOptions: [5, 10, 15],
    page: 1,
  });

  const { rows, totalRows, loading, error } = useVideosSearch(query, 0);

  const handleQueryChange = (queryElement) => (e) => {
    return setQuery((prevQuery) => {
      return { ...prevQuery, ...{ [queryElement]: e.target.value } };
    });
  };

  // const handleSelect = (ranges) => {
  //   setDateRange([ranges.selection]);
  //   updateData("intialDate", ranges.selection.startDate);
  //   updateData("finalDate", ranges.selection.endDate);
  // };

  const updateData = (k, v) => {
    setQuery((prev) => ({ ...prev, [k]: v }));
  };

  const downloadVideo = (id) => {
    try {
      let video = rows.filter((row) => row._id == id);
      // console.log(video);

      setOpenBackdrop(true);
      return axios
        .get(`${ipServer}/api/v1/rules/fs/files/${video[0].Key}`, {
          responseType: "blob",
          onDownloadProgress: (progressEvent) => {
            // console.log(progressEvent);
            const total = parseFloat(progressEvent.total);
            const current = progressEvent.loaded;
            let percentCompleted = Math.floor((current / total) * 100);
            // console.log("completed: ", percentCompleted);
            setPercentage(percentCompleted);
          },
        })
        .then((response) => {
          // console.log(response);
          // Let the user save the file.
          saveAs(response.data, video[0].dateWeek + "-" + video[0].Key);
          setOpenBackdrop(false);
          setSelectionRows([]);
          setPercentage(0);
          enqueueSnackbar("Video descargado correctamente.", {
            variant: "success",
          });
        })
        .catch((response) => {
          console.error("Error al descargar video.", response);
          setOpenBackdrop(false);
          setSelectionRows([]);
          setPercentage(0);
          enqueueSnackbar("Error al descargar video.", {
            variant: "error",
          });
        });
    } catch (error) {
      console.error("Error al descargar video.", error);
      setOpenBackdrop(false);
      setSelectionRows([]);
      setPercentage(0);
      enqueueSnackbar("Error al descargar video.", {
        variant: "error",
      });
    }
  };

  // const getDateFilter = async () => {
  //   try {
  //     const CancelToken = axios.CancelToken;
  //     const source = CancelToken.source();
  //     var params = new URLSearchParams();
  //     params.append("type_string", "Calendarios_default");
  //     params.append("name_string", "Reportes_eventos_calendario");

  //     return axiosInstance()
  //       .get(`/api/v1/rules/config`, {
  //         params,
  //         cancelToken: source.token,
  //       })
  //       .then((response) => {
  //         // console.log(response.data.data[0]);
  //         const rangeDate = dateFilter(response.data.data[0].rangoFechaDefault);

  //         setQuery((prev) => {
  //           return {
  //             ...prev,
  //             intialDate: rangeDate.initialDate,
  //             finalDate: rangeDate.finalDate,
  //           };
  //         });
  //         setDateRange((prev) => {
  //           return [
  //             {
  //               ...prev,
  //               startDate: rangeDate.initialDate,
  //               endDate: rangeDate.finalDate,
  //               key: "selection",
  //             },
  //           ];
  //         });
  //       })
  //       .catch((e) => {
  //         console.log(e);
  //       });
  //   } catch (error) {
  //     console.log(error);
  //     return false;
  //   }
  // };

  // useEffect(() => {
  //   if (user && user?.EnterpriseData?.Db == "GrupoGarzaPonce903228") {
  //     setDateRange([
  //       {
  //         startDate: new Date(),
  //         endDate: new Date(),
  //         key: "selection",
  //       },
  //     ]);
  //   }
  // }, [user]);

  //   useEffect(() => {
  //     if (selectionRows.length > 0) {
  //       setOpenDialogReportAssitance(!openDialogReportAssitance);
  //     }
  //   }, [selectionRows]);

  // useEffect(() => {
  //   getDateFilter();
  // }, [user]);

  return (
    <div className={classes.divMain}>
      <Grid container>
        <Typography className={classes.title}>Videos</Typography>

        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-end"
        >
          <Grid item xs={12} sm={12} md={5} lg={4} xl={4}>
            <TextField
              variant="outlined"
              size="small"
              placeholder="Buscar video por orden de compra"
              value={query?.Empleado}
              onChange={handleQueryChange("Order")}
              className={classes.root}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon
                      icon={faSearch}
                      style={{
                        width: "18px",
                        height: "18px",
                        color: "#676767",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid
            item
            xs
            spacing={2}
            container
            direction="row"
            justifyContent="flex-start"
          >
            <Grid
              item
              xs={6}
              sm={6}
              md={4}
              lg={4}
              xl={4}
              container
              direction="column"
            >
              <Typography className={classes.textFieldLetter}>
                Fecha de inicio
              </Typography>
              <DateHourComponent
                name={"intialDate"}
                date={query.intialDate}
                data={query}
                setData={setQuery}
              />
            </Grid>

            <Grid
              item
              xs={6}
              sm={6}
              md={4}
              lg={4}
              xl={4}
              container
              direction="column"
            >
              <Typography className={classes.textFieldLetter}>
                Fecha de termino
              </Typography>
              <DateHourComponent
                name={"finalDate"}
                date={query.finalDate}
                data={query}
                setData={setQuery}
              />
            </Grid>
          </Grid>

          {/* <Grid item xs={3}>
            <Typography className={classes.textFieldLetter}>Fecha</Typography>
            <DateRange value={dateRange} onChange={handleSelect} />
          </Grid> */}

          {/* <Grid
          item
          xs={2}
          container
          direction="row"
          alignItems="flex-end"
          style={{ marginLeft: 10 }}
        >
          <Button
            onClick={handleExportExcel}
            variant="contained"
            style={{
              minWidth: 137,
              height: 40,
              borderRadius: 8,
              backgroundColor: "#d233ff",
              textTransform: "none",
            }}
          >
            <FileDownloadIcon style={{ color: "white" }} />
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 16,
                textAlign: "left",
                color: "#fff",
                marginLeft: 4,
              }}
            >
              Exportar
            </Typography>
          </Button>

          <CSVLink
            data={dataCSV}
            filename="data.csv"
            className="hidden"
            ref={csvLink}
            target="_blank"
          />
        </Grid>*/}
        </Grid>

        <Grid container>
          <DataGridVideos
            rows={rows ? rows : []}
            loading={loading}
            rowCount={totalRows}
            rowsPerPageOptions={query.rowsPerPageOptions}
            page={query.page}
            pageSize={query.pageSize}
            paginationMode={"client"}
            selectionModel={selectionRows}
            onSelectionModelChange={(ids) => {
              setSelectionRows(ids);
              // downloadVideo(ids[0]);
              history.push(`/dashboard/videos/${ids}`);
            }}
            onPageChange={(data) => {
              updateData("page", data + 1);
            }}
            onPageSizeChange={(data) => {
              updateData("page", 1);
              updateData("pageSize", data);
            }}
            style={{
              height: 785,
              width: "100%",
              display: "flex",
              marginTop: -20,
            }}
          />
        </Grid>
      </Grid>

      {/* <BackdropComponent
        percentage={percentage}
        openBackdrop={openBackdrop}
        setOpenBackdrop={setOpenBackdrop}
      /> */}
    </div>
  );
}
