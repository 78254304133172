/*eslint-disable*/
import React, { Fragment, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Typography,
  makeStyles,
  IconButton,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCog } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import DialogAdminStatusList from "./DialogAdminStatusList";
import axiosInstance from "../../../utils/axiosInstance";

const useStyles = makeStyles((theme) => ({
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: "bold",
    textAlign: "left",
    color: "#cdcece",
  },
  textFieldCustom: {
    width: "90%",
    color: "#676767",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
  },
  formControl: {
    width: "90%",
  },
  select: {
    width: "90%",
    height: "40px",
    borderRadius: "6px",
    backgroundColor: "#fff",
  },
  multi: {
    color: "white",
    padding: 2,
    border: 0,
    borderRadius: "8px",

    "&.rmsc .dropdown-heading ": {
      backgroundColor: "white !important",
      color: "#676767",
      borderRadius: 5,
    },
  },
}));

export default function DialogStatus(props) {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { setOpendialog, openDialog, statusDemek } = props;
  const [status, setStatus] = useState([]);
  const [openAdminDialog, setOpenAdminDialog] = useState(false);
  const [update, setUpdate] = useState(false);

  const schema = Yup.object({
    status: Yup.string().required("Campo Obligatorio"),
    comment: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      status: statusDemek ?? "",
      comment: "",
    },
    validationSchema: schema,
  });

  const validateData = async () => {
    return await schema.isValid(formik.values);
  };

  const handleDialog = () => {
    setOpendialog(!openDialog);
    formik.resetForm();
  };

  const handleAdminDialog = () => {
    setOpenAdminDialog(!openAdminDialog);
    setOpendialog(!openDialog);
  };

  const saveStatus = (idUser, status, comment) => {
    return axiosInstance()
      .post(
        `/api/v1/rules/trabajadores/${idUser}/${
          status === "Activo" ? "aprobar" : "rechazar"
        }`,
        {
          comentariosStatusDemek: comment,
        }
      )
      .then((response) => {
        return true;
      })
      .catch((e) => {
        console.log(e);
        return false;
      });
  };

  const updateStatus = (idUser, status, comment) => {
    return axiosInstance()
      .patch(`/api/v1/rules/trabajadores/${idUser}/estatus`, {
        statusDemek: status,
        comentariosStatusDemek: comment,
      })
      .then((response) => {
        return true;
      })
      .catch((e) => {
        console.log(e);
        return false;
      });
  };
  const handleChangeStatus = async () => {
    let result = false;

    if (!(await validateData())) {
      enqueueSnackbar("Favor de llenar todos los campos", {
        variant: "warning",
      });
      return;
    }

    if (
      formik.values.status === "Activo" ||
      formik.values.status === "Información rechazada"
    ) {
      result = await saveStatus(
        localStorage.getItem("idEmployee"),
        formik.values.status,
        formik.values.comment
      );
    } else {
      result = await updateStatus(
        localStorage.getItem("idEmployee"),
        formik.values.status,
        formik.values.comment
      );
    }

    if (result) {
      enqueueSnackbar("Empleado actualizado correctamente", {
        variant: "success",
      });
      props.setStatusDemek(formik.values.status);
      handleDialog();
      // setOpendialog(!openDialog);
    } else {
      enqueueSnackbar("Error al actualizar empleado", {
        variant: "warning",
      });
    }
  };

  const getStatusList = (type) => {
    var params = new URLSearchParams();
    params.append("type_string", type);

    axiosInstance()
      .get("/api/v1/rules/catalogos/filter", {
        params,
      })
      .then((response) => {
        // console.log(response.data.data);
        let result = response.data.data;
        if (props.statusDemek === "En espera de aprobación") {
          result = result.filter(
            (_status) =>
              _status.name === "Activo" ||
              _status.name === "Información rechazada"
          );
        }

        result = result.filter(
          (_status) =>
            _status.name != "Borrador" &&
            _status.name != "En espera de aprobación"
        );

        formik.setFieldValue("status", props.statusDemek);
        setStatus(result);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getStatusList("trabajador_status");
  }, [props.statusDemek, update]);

  return (
    <Fragment>
      <Dialog
        open={openDialog}
        aria-labelledby="form-dialog-title"
        onClose={handleDialog}
        PaperProps={{
          style: {
            backgroundColor: "#171a1e",
            textColor: "#fsf",
            width: "483px",
            height: "690px",
            paddingTop: 15,
            borderRadius: 8,
          },
        }}
      >
        <DialogTitle>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 22,
                textAlign: "center",
                color: "#fff",
                fontWeight: "bold",
              }}
            >
              Estatus de empleado
            </Typography>

            <Grid
              container
              justifyContent="flex-end"
              style={{ position: "absolute" }}
            >
              <IconButton onClick={handleAdminDialog}>
                <FontAwesomeIcon
                  icon={faCog}
                  style={{
                    width: "20px",
                    height: "20px",
                    color: "#fff",
                    marginLeft: -80,
                  }}
                />
              </IconButton>

              <IconButton onClick={handleDialog}>
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{
                    width: "20px",
                    height: "20px",
                    color: "#fff",
                    marginLeft: -50,
                  }}
                />
              </IconButton>
            </Grid>
          </Grid>
          <div
            style={{
              width: "100%",
              height: 1,
              background: "#707070",
              marginTop: 8,
              padding: 0,
            }}
          ></div>
        </DialogTitle>

        <DialogContent>
          <Grid container direction="column" alignContent="center">
            <Typography
              style={{
                marginTop: 35,
                fontFamily: "Roboto",
                fontSize: 17,
                fontWeight: "bold",
                textAlign: "left",
                color: "#cdcece",
              }}
            >
              Estatus de empleado
            </Typography>
            <Select
              name="status"
              inputProps={{ "aria-label": "Without label" }}
              className={classes.select}
              value={formik.values.status}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              displayEmpty
            >
              <MenuItem value="" disabled>
                &ensp;Obligatorio
              </MenuItem>
              {status.map((_status, index) => (
                <MenuItem key={index} value={_status.name}>
                  <Typography
                    style={{
                      fontFamily: "Roboto",
                      fontSize: 17,
                      marginLeft: 10,
                      color: "black",
                    }}
                  >
                    {_status.name}
                  </Typography>
                </MenuItem>
              ))}
            </Select>

            <Typography
              style={{
                marginTop: 35,
                fontFamily: "Roboto",
                fontSize: 17,
                fontWeight: "bold",
                textAlign: "left",
                color: "#cdcece",
              }}
            >
              Comentarios
            </Typography>

            <TextField
              id="comment"
              name="comment"
              size="small"
              variant="outlined"
              className={classes.textFieldCustom}
              multiline
              minRows={10}
              value={formik.values.comment}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="center"
            style={{ marginTop: 80 }}
          >
            <Button
              onClick={handleChangeStatus}
              style={{
                width: "320px",
                height: "40px",
                borderRadius: "8px",
                color: "#ffffff",
                backgroundColor: "#ac0bce",
                textTransform: "none",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 21,
                  color: "#ffffff",
                }}
              >
                Guardar
              </Typography>
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>

      <DialogAdminStatusList
        openAdminDialog={openAdminDialog}
        setOpenAdminDialog={setOpenAdminDialog}
        setOpendialog={setOpendialog}
        openDialog={openDialog}
        update={update}
        setUpdate={setUpdate}
      />
    </Fragment>
  );
}
