/*eslint-disable*/
import { types } from "../types/types";

const initialState = {
  files: [],
  filesRetry: [],
  otherDocuments: [],
  fileModal: false,
};

export const fileReducer = (state = initialState, action) => {
  switch (action.type) {
    // case types.fileAdd:
    //   return {
    //     ...state,
    //     files: [...state.files, action.payload],
    //   };

    // case types.fileAdd:
    //   var files = state.files;
    //   var index = files.findIndex((fi) => fi.name === action.payload.name);

    //   if (index === -1) {
    //     files.push(action.payload);
    //   } else {
    //     files[index] = action.payload;
    //   }
    //   return {
    //     ...state,
    //     files,
    //   };

    case types.fileAdd:
      var files = state.files;
      // console.log("action.payload", action.payload)
      files.push(action.payload);
      files = files?.sort((a, b) => (a.date > b.date ? 1 : -1));
      // console.log("action.payload", files)

      return {
        ...state,
        files,
      };

    // ?.sort((a, b) => (a.module.order > b.module.order ? 1 : -1))

    case types.fileRetry:
      var filesRetry = state.filesRetry;
      var index = filesRetry.findIndex((fi) => fi.name === action.payload.name);

      if (index === -1) {
        filesRetry.push(action.payload);
      } else {
        filesRetry[index] = action.payload;
      }
      return {
        ...state,
        filesRetry,
      };

    case types.fileDelete:
      // console.log(state.payload);
      return {
        ...state,
        files: state.files.filter((e) => e.name !== action.payload),
      };

    case types.fileUpdate:
      return {
        ...state,
        files: state.files.map((e) =>
          e.name === action.payload.name ? action.payload : e
        ),
      };

    case types.fileClear:
      return {
        ...state,
        files: [],
        filesRetry: [],
      };

    case types.fileUpdateObject:
      return {
        ...state,
        files: state.files.map((file, i) =>
          file?.id && file.id === action.payload.id
            ? Object.assign(file, action.payload)
            : file
        ),
      };
      
    case types.fileReplaceObject:
      return {
        ...state,
        files: state.files.map((file, i) =>
          file?.id && file.id === action.payload.id ? action.payload : file
        ),
      };

    case types.fileUpdloadModal:
      return {
        ...state,
        fileModal: !state.fileModal,
      };

    case types.reset:
      return initialState;

    default:
      return state;
  }
};
