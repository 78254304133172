// secondsToHms.js

export default function secondsToHms(d) {
  d = Number(d);
  var hours = Math.floor(d / 3600);
  var minutes = Math.floor((d % 3600) / 60);
  var seconds = Math.floor((d % 3600) % 60);
  return {
    hours,
    minutes,
    seconds,
  };
}
