/*eslint-disable*/
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Logo from "../images/ABSA-INDUSTRIAL-LOGO-BLANCO.png";

const useStyles = makeStyles((theme) => ({
  divMain: {
    display: "flex",
    justifyContent: "center",
    marginTop: "4%",
  },
  logo: {
    width: "600px",
    height: "140px",
  },
  release: {
    width: "280px",
    height: "22px",
    position: "absolute",
    top: "92%",
    left: "80%",
    fontFamily: "Roboto",
    fontSize: 18,
    fontWeight: 300,
    textAlign: "left",
    color: "#ffffff",
  },
  rightsReserved: {
    width: "280px",
    height: "22px",
    position: "absolute",
    top: "95%",
    left: "80%",
    fontFamily: "Roboto",
    fontSize: 18,
    fontWeight: 300,
    textAlign: "left",
    color: "#ffffff",
  },
}));

export default function BackGroundLogin() {
  const classes = useStyles();

  return (
    <div className={classes.divMain}>
      <img src={Logo} className={classes.logo} alt="techton logo" />

      {/* <Typography className={classes.release}>Release 0.0.3</Typography>
      <Typography className={classes.rightsReserved}>
        ©{new Date().getFullYear()} Techton All rights reserved
      </Typography> */}
    </div>
  );
}
