/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Typography,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faArrowAltCircleLeft,
} from "@fortawesome/free-solid-svg-icons";
import DataGridPurchaseOrders from "./components/DataGridPurchaseOrders";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    padding: theme.spacing(0, 3, 0, 3),
  },
  divBack: {
    cursor: "pointer",
    marginBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
  back: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 500,
    textAlign: "left",
    color: "#ffffff",
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 32,
    fontWeight: 500,
    lineHeight: 1.34,
    textAlign: "left",
    color: "#fff",
    marginBottom: 20,
  },
  textFieldCustom: {
    width: 466,
    color: "#676767",
    borderRadius: "8px",
    backgroundColor: "#ffffff",
  },
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 16,
    textAlign: "left",
    color: "#dedcdc",
  },
  button: {
    width: "215px",
    height: "40px",
    borderRadius: "8px",
    color: "#ffffff",
    backgroundColor: "#d233ff",
    textTransform: "none",
    marginLeft: 12,
  },
  typoButton: {
    fontFamily: "Roboto",
    fontSize: 16,
    textAlign: "left",
    color: "#ffffff",
  },
}));

export default function PurchaseOrdersScreen(props) {
  const classes = useStyles();
  const history = useHistory();
  const [selectionRows, setSelectionRows] = useState([]);
  const [query, setQuery] = useState({
    Empleado: "",
    Subcontratistas: [],
    Obras: [],
    pageSize: 10,
    rowsPerPageOptions: [5, 10, 15],
    page: 1,
  });

  const rows = [
    {
      id: 1,
      Folio: "12134234",
      Proveedor: "Plomería Juanito",
      AlmacenEntregar: "Almacén lobby 33",
      CreadaPor: "javiermedinacazares@gmail.com",
      DiaEntrega: "21 de Dic",
      Estatus: "Activo",
    },
  ];

  return (
    <div className={classes.divMain}>
      <Grid
        item
        xs={6}
        sm={4}
        md={2}
        lg={2}
        xl={2}
        container
        direction="row"
        alignItems="center"
        onClick={() => history.push(`/dashboard/almacen`)}
        className={classes.divBack}
      >
        <Grid item>
          <FontAwesomeIcon
            icon={faArrowAltCircleLeft}
            style={{
              width: "20px",
              height: "20px",
              marginRight: "10px",
              color: "#d6d6d6",
            }}
          />
        </Grid>
        <Grid item>
          <Typography className={classes.back}>Regresar</Typography>
        </Grid>
      </Grid>
      <Grid item xs={6} sm={8} md={10} lg={10} xl={10}></Grid>

      <Grid container direction="column">
        <Typography className={classes.title}>Ordenes de compra</Typography>

        <Grid container direction="row" alignContent="center">
          <TextField
            placeholder="Buscar orden de compra"
            variant="outlined"
            size="small"
            // onChange={handleQueryChange("Empleado")}
            className={classes.textFieldCustom}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon
                    icon={faSearch}
                    style={{
                      width: "18px",
                      height: "18px",
                      color: "#676767",
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />

          <Button
            variant="contained"
            onClick={() =>
              history.push(`/dashboard/almacen/ordenes-compra/crear`)
            }
            className={classes.button}
          >
            <Typography className={classes.typoButton}>
              Nueva orden de compra
            </Typography>
          </Button>
        </Grid>

        <DataGridPurchaseOrders
          rows={rows ?? []}
          // loading={loading}
          // rowCount={totalRows}
          loading={false}
          rowCount={10}
          rowsPerPageOptions={query.rowsPerPageOptions}
          page={query.page}
          pageSize={query.pageSize}
          selectionModel={selectionRows}
          onSelectionModelChange={(ids) => {
            setSelectionRows(ids);
            // history.push(`/dashboard/almacen/ordenes-compra/editar/${ids}`);
            history.push(
              `/dashboard/almacen/ordenes-compra/editar/631f97b8da92f5d581617ea4`
            );
          }}
          onPageChange={(data) => {
            updateData("page", data + 1);
          }}
          onPageSizeChange={(data) => {
            updateData("page", 1);
            updateData("pageSize", data);
          }}
          style={{ height: 680, width: "100%", display: "flex" }}
        />
      </Grid>
    </div>
  );
}
