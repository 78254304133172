/*eslint-disable*/
import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Box, Typography } from "@material-ui/core";
import Lottie from "react-lottie";
import UploadAnimation from "../../../animations/lottie_uploading.json";
import CorrectAnimation from "../../../animations/lottie_correct_file.json";
import FaildAnimation from "../../../animations/lottie_fail.json";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import { faFileAlt, faUndoAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { RetryUploadDocuments } from "../../../actions/file";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiLinearProgress-colorPrimary": {
      backgroundColor: "#6d6d6d",
    },
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#d233ff",
    },
  },
  divMain: {
    width: "300px",
  },
  colorPrimary: {
    backgroundColor: "#00695C",
  },
  barColorPrimary: {
    backgroundColor: "#B2DFDB",
  },
  noWrap: {
    textOverflow: "ellipsis",
    wordWrap: "anywhere",
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
  },
}));

export default function CardUploadFiles(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  var { tag, id } = props;
  const { files } = useSelector((status) => status.file);
  const file = files.find((file) => file.id === id);

  const AnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: UploadAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const AnimationCorrectOptions = {
    loop: false,
    autoplay: true,
    animationData: CorrectAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const AnimationFailOptions = {
    loop: true,
    autoplay: true,
    animationData: FaildAnimation,
    rendererSettings: {
      preserveAspectRatio: "none",
    },
  };

  const handleRetry = (file) => {
    let files = [];
    files.push(file);
    dispatch(RetryUploadDocuments(files, props.type));
  };

  return (
    <Fragment>
      <Grid container direction="row" style={{ marginBottom: 8 }}>
        {file?.status === "upload" ? (
          <Lottie
            options={AnimationOptions}
            height={83}
            width={66}
            style={{ marginRight: 10 }}
          />
        ) : file?.status === "correct" ? (
          <Lottie
            options={AnimationCorrectOptions}
            height={49}
            width={56}
            style={{ marginTop: 14, marginRight: 14 }}
          />
        ) : file?.status === "failure" ? (
          <Lottie
            options={AnimationFailOptions}
            height={80}
            width={80}
            style={{ marginTop: 5, marginLeft: -10 }}
          />
        ) : (
          <Grid>
            <Grid
              container
              style={{ position: "relative", marginRight: 18, marginLeft: 10 }}
            >
              <div
                style={{
                  background: "#d233ff",
                  position: "absolute",
                  width: "24px",
                  height: "22px",
                  marginTop: 30,
                  marginLeft: 10,
                }}
              />

              <FontAwesomeIcon
                icon={faFileAlt}
                style={{
                  width: "40px",
                  height: "54px",
                  color: "#fff",
                  marginTop: 12,
                  zIndex: 99,
                }}
              />
            </Grid>
          </Grid>
        )}

        <Grid style={{ marginLeft: 10 }}>
          <Typography
            className={classes.noWrap}
            style={{
              fontFamily: "Roboto",
              fontSize: 18,
              fontWeight: "bold",
              color: "#fff",
              marginTop: 8,

              width: "20vh",
            }}
          >
            {file ? file.file.name : "error"}
          </Typography>

          {file?.status !== "failure" ? (
            <Fragment>
              <Box display="flex" alignItems="center">
                <Box width="24vh" mr={1} className={classes.root}>
                  <LinearProgress
                    variant="determinate"
                    value={file ? file.progress : 0}
                    style={{
                      height: "12px",
                      borderRadius: "5px",
                      marginTop: 3,
                    }}
                  />
                </Box>
              </Box>

              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 14,
                  color: "#fff",
                  marginTop: 3,
                }}
              >
                {file?.status !== "wait"
                  ? `${file?.progress}% completado`
                  : "En espera"}
              </Typography>
            </Fragment>
          ) : (
            <Button
              variant="contained"
              onClick={() => handleRetry(file)}
              style={{
                width: "149px",
                height: "32px",
                borderRadius: "8px",
                backgroundColor: "#2d3034",
                textTransform: "none",
                marginTop: 4,
              }}
            >
              <FontAwesomeIcon
                icon={faUndoAlt}
                style={{
                  width: "14px",
                  height: "14px",
                  color: "#d233ff",
                }}
              />

              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 16,
                  textAlign: "left",
                  color: "#ffffff",
                  marginLeft: 8,
                }}
              >
                Reintentar
              </Typography>
            </Button>
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
}
