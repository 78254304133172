/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, TextField } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import Typo from "../../../common_components/typo";
import Lottie from "react-lottie";
import SelfieAnimation from "../../../animations/selfie_animation.json";
import DialogChoosePhoto from "./DialogChoosePhoto";
import DialogSearchEmployee from "./DialogSearchEmployee";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
  },
  divImg: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    width: "130px",
    height: "130px",
    // borderRadius: "50%",
    cursor: "pointer",
    // backgroundColor: "#fff",
  },
  photo: {
    width: "90%",
    height: "90%",
    borderRadius: "50%",
    aspectRatio: 1,
    // position: "absolute",
    objectFit: "cover",
  },
  icon: {
    width: "40px",
    height: "40px",
    color: "white",
    marginBottom: "20px",
  },
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: "bold",
    textAlign: "left",
    color: "#cdcece",
  },
  textFieldCustom: {
    width: "90%",
    color: "#676767",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  formControl: {
    width: "90%",
  },
  select: {
    width: "100%",
    height: "40px",
    borderRadius: "6px",
    backgroundColor: "#fff",
  },
  gridDiv: {
    // width: "620px",
    height: "125px",
    padding: "10px",
    borderRadius: "8px",
    backgroundColor: "#d233ff",
    [theme.breakpoints.down("md")]: {
      height: "auto",
      marginTop: 10,
    },
  },
  gridMargin: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
}));

export default function InfoProfile(props) {
  const classes = useStyles();
  const { user } = useSelector((state) => state.user);
  const [openDialogPhoto, setOpendialogPhoto] = useState(false);
  const [openDialogSearch, setOpendialogSearch] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: SelfieAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleChange = (e) => {
    props.setChangesFlag(true);
    const value = e.target.value;
    props.setUserData({
      ...props.userData,
      [e.target.name]: value,
    });
  };

  function stringAvatar(names) {
    let initials = "";
    names.split(" ").map((name) => {
      initials = initials + name[0];
    });

    if (initials.length > 2) {
      initials = initials.slice(0, 2);
    }

    return initials;
  }

  useEffect(() => {
    if (props.userData.nombre != "" && props.userData.correo != "") {
      props.setMandatoryFlag(true);
    } else {
      props.setMandatoryFlag(false);
    }
  }, [props.userData.nombre, props.userData.correo]);

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <Typo
            fontSize={24}
            fontWeight={500}
            text={"Foto de perfil"}
            color={"#fff"}
          />
        </Grid>

        <Grid
          item
          md={12}
          lg={12}
          xl={12}
          style={{
            height: "30px",
          }}
        />

        <Grid container direction="row" justifyContent="center">
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
            <div
              className={classes.divImg}
              onClick={() => setOpendialogPhoto(!openDialogPhoto)}
            >
              {props.userData.fotoCache ? (
                <img
                  src={props.userData.fotoCache}
                  className={classes.photo}
                  alt="photo"
                />
              ) : (
                <div>
                  <div
                    style={{
                      width: "120px",
                      height: "120px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "50%",
                      backgroundColor: "#d233ff",
                    }}
                  >
                    <Typo
                      fontSize={40}
                      fontWeight={500}
                      color={"#fff"}
                      text={stringAvatar(user ? user.Name : "")}
                    />
                  </div>
                  <div style={{ position: "absolute", top: 90, left: 90 }}>
                    <div
                      style={{
                        width: "32px",
                        height: "32px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                        backgroundColor: "#fff",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faPen}
                        style={{
                          width: "16px",
                          height: "16px",
                          color: "#707070",
                          borderRadius: "8px",
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            container
            direction="row"
            className={classes.gridDiv}
          >
            <Grid container direction="row">
              <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                <Typo
                  fontSize={20}
                  fontWeight={500}
                  color={"#fff"}
                  text={
                    "Sube tu foto de perfil para poder generar firmas digitales"
                  }
                  marginBottom={10}
                />
                <Typo
                  fontSize={14}
                  fontWeight={300}
                  color={"#e8e8e8"}
                  text={
                    "Con tu firma digital podrás firmar documentos con una selfie."
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                <Lottie options={defaultOptions} height={100} width={107} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>

        <Grid item xs={12}>
          <Typo
            fontSize={24}
            fontWeight={500}
            text={"Información de perfil"}
            color={"#fff"}
            marginTop={60}
            marginBottom={16}
          />
        </Grid>

        <Grid
          item
          md={12}
          lg={12}
          xl={12}
          style={{
            height: "30px",
          }}
        />

        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Typography className={classes.textFieldLetter}>Nombre</Typography>
          <TextField
            placeholder="Obligatorio"
            name="nombre"
            size="small"
            variant="outlined"
            value={props.userData.nombre}
            className={classes.textFieldCustom}
            onChange={handleChange}
            inputProps={{
              maxLength: 50,
              style: { textTransform: "capitalize" },
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          className={classes.gridMargin}
        >
          <Typography className={classes.textFieldLetter}>Email</Typography>
          <TextField
            placeholder="Obligatorio"
            name="correo"
            size="small"
            variant="outlined"
            value={props.userData.correo.toLowerCase()}
            className={classes.textFieldCustom}
            onChange={handleChange}
            inputProps={{
              maxLength: 12,
            }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            height: "30px",
          }}
        ></Grid>

        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Typography className={classes.textFieldLetter}>
            Número de celular
          </Typography>
          <TextField
            type="number"
            name="telefono"
            size="small"
            variant="outlined"
            value={props.userData.telefono}
            className={classes.textFieldCustom}
            onChange={handleChange}
            onInput={(e) => {
              e.target.value = e.target.value.slice(0, 10);
            }}
          />
        </Grid>
      </Grid>

      <DialogChoosePhoto
        userData={props.userData}
        setUserData={props.setUserData}
        openDialogPhoto={openDialogPhoto}
        setOpendialogPhoto={setOpendialogPhoto}
        openDialogSearch={openDialogSearch}
        setOpendialogSearch={setOpendialogSearch}
      />

      <DialogSearchEmployee
        userData={props.userData}
        setUserData={props.setUserData}
        openDialogSearch={openDialogSearch}
        setOpendialogSearch={setOpendialogSearch}
      />
    </div>
  );
}
