/*eslint-disable*/
import { types } from "../types/types";
import axiosInstance from "../utils/axiosInstance";

export const LoadObras = (result) => ({
  type: types.obraLoad,
  payload: result,
});

export const SelectedObras = (result) => ({
  type: types.obraSelected,
  payload: result,
});

export const UpdateObras = (result) => ({
  type: types.obrasUpdate,
  payload: result,
});

export const SelectedContratista = (result) => ({
  type: types.obrasSelectedContratista,
  payload: result,
});

export const getObra = (id) => {
  return async (dispatch) => {
    await axiosInstance()
      .get(`/api/v1/rules/proyectos/${id}`)
      .then((response) => {
        let result = response.data.data[0];
        result.obrasCount = result.subcontratistas_id
          ? result.subcontratistas_id.length - 2
          : 0;

        result.trabajadoresCount = result.trabajadores_id
          ? result.trabajadores_id.length
          : 0;

        dispatch(UpdateObras(result));
        return dispatch(SelectedObras(result));
      })
      .catch((e) => {
        console.log(e);
      });
  };
};
