/*eslint-disable*/
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ImageGallery from "react-image-gallery";

// # SCSS
// @import "~react-image-gallery/styles/scss/image-gallery.scss";

import "react-image-gallery/styles/css/image-gallery.css";

const useStyles = makeStyles((theme) => ({
  divMain: {
    width: "100%",
    height: "auto",
    borderRadius: "12px",
    backgroundColor: "#2d3034",
    padding: theme.spacing(2),
  },
  typoTitle: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 1.31,
    textAlign: "left",
    color: "#acacac",
    marginRight: 12,
  },
  typoTitle2: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 1.31,
    textAlign: "left",
    color: "#acacac",
  },
  typoSubtitle: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.31,
    color: "#fff",
  },
  typoSubtitle2: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 1.31,
    color: "#fff",
  },
  button: {
    width: 96,
    height: 96,
    borderRadius: 8,
    border: "solid 1px #707070",
  },
  photo: {
    width: 85,
    height: 85,
    borderRadius: 8,
    aspectRatio: 1,
    objectFit: "cover",
  },
  divRelative: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  divEvidence: {
    position: "absolute",
    top: 80,
    left: 80,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 24,
    height: 24,
    borderRadius: "50%",
    backgroundColor: "#d233ff",
  },
}));

export default function ImageGalleryComponent(props) {
  const classes = useStyles();

  const images = [
    {
      original: "https://picsum.photos/id/1018/1000/600/",
      thumbnail: "https://picsum.photos/id/1018/250/150/",
    },
    {
      original: "https://picsum.photos/id/1015/1000/600/",
      thumbnail: "https://picsum.photos/id/1015/250/150/",
    },
    {
      original: "https://picsum.photos/id/1019/1000/600/",
      thumbnail: "https://picsum.photos/id/1019/250/150/",
    },
  ];

  return (
    <ImageGallery
      // ref={(i) => (this._imageGallery = i)}
      items={images}
      // onClick={this._onImageClick.bind(this)}
      // onImageLoad={this._onImageLoad}
      // onSlide={this._onSlide.bind(this)}
      // onPause={this._onPause.bind(this)}
      // onScreenChange={this._onScreenChange.bind(this)}
      // onPlay={this._onPlay.bind(this)}
      // infinite={this.state.infinite}
      // showBullets={this.state.showBullets}
      // showFullscreenButton={
      //   this.state.showFullscreenButton &&
      //   this.state.showGalleryFullscreenButton
      // }
      // showPlayButton={
      //   this.state.showPlayButton && this.state.showGalleryPlayButton
      // }
      // showThumbnails={this.state.showThumbnails}
      // showIndex={this.state.showIndex}
      // showNav={this.state.showNav}
      // isRTL={this.state.isRTL}
      // thumbnailPosition={this.state.thumbnailPosition}
      // slideDuration={parseInt(this.state.slideDuration)}
      // slideInterval={parseInt(this.state.slideInterval)}
      // slideOnThumbnailOver={this.state.slideOnThumbnailOver}
      // additionalClass="app-image-gallery"
      // useWindowKeyDown={this.state.useWindowKeyDown}
    />
  );
}
