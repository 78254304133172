/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Select,
  FormControl,
  MenuItem,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import IncidentComponent from "./IncidentComponent";
import { CapitalizeString } from "../../../utils/CapitalizeString";

const useStyles = makeStyles((theme) => ({
  divMain: {
    height: "24px",
    minWidth: "200px",
    maxWidth: "350px",
    margin: "0px 5px 5px 0px",
    padding: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "16px",
    backgroundColor: "#2d3034",
  },
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: "bold",
    textAlign: "left",
    color: "#cdcece",
  },
  textFieldLetter2: {
    fontFamily: "Roboto",
    fontSize: 15,
    fontWeight: "bold",
    textAlign: "left",
    color: "#cdcece",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
  textFieldCustom: {
    width: "90%",
    color: "#676767",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
  textFieldCustom2: {
    width: "97%",
    color: "#676767",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
  divIncident: {
    width: "97%",
    height: "95px",
    color: "#676767",
    borderRadius: "6px",
    border: "solid 1px #c5c5c5",
    padding: "5px 5px 5px 0px",
    overflowY: "scroll",
    overflowX: "hidden",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
  multilineColor: {
    color: "white",
  },
  formControl: {
    width: "90%",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
  select: {
    width: "100%",
    height: "40px",
    borderRadius: "6px",
    backgroundColor: "#fff",
  },
  gridMargin: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
}));

export default function InfoReport(props) {
  const classes = useStyles();

  const handleChange = (e) => {
    props.setChangesFlag(true);
    const value = e.target.value;
    props.setReportData({
      ...props.reportData,
      [e.target.name]: value,
    });
  };

  const renderIncidents = () => {
    if (
      props.reportData.incidentsMade == "" ||
      props.reportData.incidentsMade == null
    ) {
      return <div></div>;
    } else {
      const items = props.reportData.incidentsMade.map((incident, index) => {
        return (
          <div key={index} className={classes.divMain}>
            <IncidentComponent incident={incident} />
            <FontAwesomeIcon
              onClick={() => {
                props.setReportData({
                  ...props.reportData,
                  incidentsMade: props.reportData.incidentsMade.filter(
                    (item) => item !== incident
                  ),
                });
              }}
              icon={faTimesCircle}
              style={{
                width: "13px",
                height: "13px",
                marginLeft: "5px",
                color: "#272727",
                borderRadius: "50%",
                backgroundColor: "#fff",
              }}
            />
          </div>
        );
      });
      return items;
    }
  };

  useEffect(() => {
    if (
      props.reportData.activity != "" &&
      props.reportData.area != "" &&
      props.reportData.times != "" &&
      props.reportData.incidentsMade != undefined &&
      props.reportData.incidentsMade.length > 0
    ) {
      props.setMandatoryFlag(true);
    } else {
      props.setMandatoryFlag(false);
    }
  }, [
    props.reportData.activity,
    props.reportData.area,
    props.reportData.times,
    props.reportData.incidentsMade,
  ]);

  return (
    <div>
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            height: "30px",
          }}
        ></Grid>

        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Typography className={classes.textFieldLetter}>
            Actividad que se realizaba
          </Typography>
          <TextField
            placeholder="Obligatorio"
            name="activity"
            size="small"
            variant="outlined"
            value={props.reportData.activity}
            className={classes.textFieldCustom}
            onChange={handleChange}
            inputProps={{
              maxLength: 50,
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          className={classes.gridMargin}
        >
          <Typography className={classes.textFieldLetter}>
            Área donde se realizó
          </Typography>
          <TextField
            placeholder="Obligatorio"
            name="area"
            size="small"
            variant="outlined"
            value={props.reportData.area}
            className={classes.textFieldCustom}
            onChange={handleChange}
            inputProps={{
              maxLength: 50,
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          className={classes.gridMargin}
        >
          <Typography className={classes.textFieldLetter2}>
            Número de veces que se ha realizado
          </Typography>
          <TextField
            placeholder="Obligatorio"
            type="number"
            name="times"
            size="small"
            variant="outlined"
            value={props.reportData.times}
            className={classes.textFieldCustom}
            onChange={handleChange}
            InputProps={{ inputProps: { min: 1 } }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            height: "30px",
          }}
        ></Grid>

        <Grid item xs={12}>
          <Typography className={classes.textFieldLetter}>
            Incidencias realizadas
          </Typography>
          <div className={classes.divIncident}>
            <Grid container direction="row" justifyContent="flex-start">
              <Button
                onClick={() =>
                  props.setOpenDialogIncidents(!props.openDialogIncidents)
                }
                style={{
                  width: "94px",
                  height: "24px",
                  marginRight: "5px",
                  marginLeft: "5px",
                  borderRadius: "13px",
                  backgroundColor: "#ac0bce",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 12,
                    textAlign: "left",
                    color: "#fff",
                    textTransform: "none",
                  }}
                >
                  + Agregar
                </Typography>
              </Button>

              {renderIncidents()}
            </Grid>
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            height: "30px",
          }}
        ></Grid>

        <Grid item xs={12}>
          <Typography className={classes.textFieldLetter}>
            Disposición y acción remediadora a implementar para reiniciar
            actividades
          </Typography>
          <TextField
            name="remedialAction"
            size="small"
            variant="outlined"
            multiline={true}
            minRows={3}
            value={props.reportData.remedialAction}
            onChange={handleChange}
            className={classes.textFieldCustom2}
          />
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            height: "30px",
          }}
        ></Grid>

        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-end"
        >
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Typography className={classes.textFieldLetter}>
              Sanción aplicable
            </Typography>
            <TextField
              name="sanction"
              size="small"
              variant="outlined"
              value={props.reportData.sanction}
              className={classes.textFieldCustom}
              onChange={handleChange}
              // inputProps={{ maxLength: 18 }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            className={classes.gridMargin}
          >
            <Typography className={classes.textFieldLetter}>
              Días de sanción
            </Typography>
            <TextField
              type="number"
              name="daysSanction"
              size="small"
              variant="outlined"
              value={props.reportData.daysSanction}
              className={classes.textFieldCustom}
              onChange={handleChange}
              InputProps={{ inputProps: { min: 0 } }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            className={classes.gridMargin}
          >
            <Typography className={classes.textFieldLetter2}>
              ¿Requiere ser modificada la identificación de riesgos?
            </Typography>
            <FormControl className={classes.formControl}>
              <Select
                name="riskmodification"
                value={props.reportData.riskmodification}
                onChange={handleChange}
                defaultValue=""
                inputProps={{ "aria-label": "Without label" }}
                className={classes.select}
              >
                <MenuItem value=" "> </MenuItem>
                <MenuItem value="Si">&ensp;Si</MenuItem>
                <MenuItem value="No">&ensp;No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            height: "30px",
          }}
        ></Grid>

        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-end"
        >
          <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
            <Typography className={classes.textFieldLetter2}>
              ¿Las acciones establecidas por el responsable fueron realizadas?
            </Typography>
            <FormControl className={classes.formControl}>
              <Select
                name="establishedActions"
                value={props.reportData.establishedActions}
                onChange={handleChange}
                defaultValue=""
                inputProps={{ "aria-label": "Without label" }}
                className={classes.select}
              >
                <MenuItem value=" "> </MenuItem>
                <MenuItem value="Si">&ensp;Si</MenuItem>
                <MenuItem value="No">&ensp;No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            className={classes.gridMargin}
          >
            <Typography className={classes.textFieldLetter2}>
              ¿Fueron requeridas acciones complementarias?
            </Typography>
            <TextField
              name="complementaryActions"
              size="small"
              variant="outlined"
              value={props.reportData.complementaryActions}
              className={classes.textFieldCustom}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
