/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import CardComponent from "./CardComponent";

const useStyles = makeStyles((theme) => ({
  divMain: {
    width: "100%",
    height: 610,
    borderRadius: 16,
    backgroundColor: "#2d3034",
    padding: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      marginTop: 40,
    },
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 24,
    fontWeight: 500,
    textAlign: "left",
    color: "#fff",
  },
  typoLabel: {
    fontFamily: "Roboto",
    fontSize: 20,
    fontWeight: 500,
    textAlign: "left",
    color: "#fff",
  },
  typoNumber: {
    fontFamily: "Roboto",
    fontSize: 24,
    fontWeight: 500,
    textAlign: "left",
    color: "#fff",
    marginRight: 8,
  },
  typoText: {
    fontFamily: "Roboto",
    fontSize: 18,
    textAlign: "left",
    color: "#c1c1c1",
  },
}));

export default function EmployeeStadisticsCard(props) {
  const classes = useStyles();

  return (
    <Grid className={classes.divMain}>
      <Typography className={classes.title}>Estadísticas empleado</Typography>

      <CardComponent
        icon={1}
        title={"Horas trabajadas"}
        number={props.hours}
        type={"horas"}
      />
      {/* <CardComponent icon={1} title={"Faltas"} number={"2"} type={"faltas"} />
      <CardComponent
        icon={1}
        title={"Días de incapacidad"}
        number={"0"}
        type={"días"}
      />
      <CardComponent
        icon={2}
        title={"Días de vacaciones"}
        number={"2"}
        type={"días"}
      />
      <CardComponent
        icon={1}
        title={"Días de permiso"}
        number={"0"}
        type={"días"}
      /> */}
    </Grid>
  );
}
