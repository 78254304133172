/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { Button, Card, Grid, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faArrowAltCircleLeft,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import axiosInstance from "../../../utils/axiosInstance";
import CheckboxComponent from "./CheckboxComponent";
import ScrollBars from "../../../common_components/ScrollBars";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    padding: 15,
  },
  card: {
    width: 570,
    height: 718,
    padding: theme.spacing(4),
    borderRadius: 16,
    backgroundColor: "#2d3034",
  },
  divBack: {
    cursor: "pointer",
    marginBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
  back: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 500,
    textAlign: "left",
    color: "#ffffff",
    cursor: "pointer",
  },
  icon: {
    width: "20px",
    height: "20px",
    color: "#d6d6d6",
    marginRight: "10px",
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 28,
    fontWeight: 600,
    textAlign: "left",
    color: "#ffffff",
    marginRight: 50,
  },
  button: {
    width: 115,
    height: 40,
    borderRadius: 8,
    backgroundColor: "#ac0bce",
    textTransform: "none",
  },
  typoButton: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 1.31,
    textAlign: "left",
    color: "#fff",
  },
  typoLabel: {
    fontFamily: "Roboto",
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.3,
    textAlign: "left",
    color: "#fff",
    marginTop: 12,
    marginBottom: 5,
  },
  checkbox: {
    padding: 2,
    color: "#484848",
    "&.Mui-checked": {
      color: "#d233ff",
    },
  },
  typo: {
    fontFamily: "Roboto",
    fontSize: 16,
    lineHeight: 1.31,
    textAlign: "left",
    color: "#cdcece",
  },
}));

export default function ReportSettingsScreen(props) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [reportSettings, setReportSettings] = useState([]);
  const arrayOptions = [
    "Hoy",
    "Semana en curso",
    "Mes en curso",
    "Últimos 7 días",
    "Últimos 30 días",
  ];

  const getReportSettings = () => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    var params = new URLSearchParams();
    params.append("type_string", "Calendarios_default");

    return axiosInstance()
      .get(`/api/v1/rules/config`, {
        params,
        cancelToken: source.token,
      })
      .then((response) => {
        // console.log(response.data.data[0]);
        setReportSettings(response.data.data);
      })
      .catch((e) => {
        console.log(e.response);
      });
  };

  const saveReportSettings = () => {
    const response = reportSettings.map((report, index) => {
      return axiosInstance()
        .patch(`/api/v1/rules/config/${report._id}`, {
          rangoFechaDefault: report.rangoFechaDefault,
        })
        .then((response) => {
          // console.log(response.data);
          return true;
        })
        .catch((e) => {
          console.log(e);
          return false;
        });
    });

    if (response)
      enqueueSnackbar("Configuración actualizada correctamente", {
        variant: "success",
      });
    else
      enqueueSnackbar("Error al actualizar la configuración", {
        variant: "error",
      });
  };

  useEffect(() => {
    getReportSettings();
  }, []);

  return (
    <Grid className={classes.divMain}>
      <Grid
        item
        xs={6}
        sm={4}
        md={2}
        lg={2}
        xl={2}
        container
        direction="row"
        alignItems="center"
        onClick={() => history.push(`/dashboard/miempresa/configuracion`)}
        className={classes.divBack}
      >
        <Grid item>
          <FontAwesomeIcon
            icon={faArrowAltCircleLeft}
            className={classes.icon}
          />
        </Grid>
        <Grid item>
          <Typography className={classes.back}>Regresar</Typography>
        </Grid>
      </Grid>
      <Grid item xs={6} sm={8} md={10} lg={10} xl={10}></Grid>

      <Grid
        item
        xs={12}
        style={{
          height: "10px",
        }}
      />

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          style={{
            width: 570,
          }}
        >
          <Typography className={classes.title}>
            Configuración de reportes
          </Typography>

          <Button
            variant="contained"
            className={classes.button}
            onClick={saveReportSettings}
          >
            <Typography className={classes.typoButton}>Guardar</Typography>
          </Button>
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            height: "20px",
          }}
        />

        <Card className={classes.card}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={2}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "57px",
                  height: "55px",
                  borderRadius: 8,
                  backgroundColor: "#d233ff",
                }}
              >
                <FontAwesomeIcon
                  icon={faChartLine}
                  style={{
                    width: "24px",
                    height: "18px",
                    color: "#fff",
                  }}
                />
              </div>
            </Grid>

            <Grid item xs={10} container direction="column">
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 24,
                  lineHeight: 1.33,
                  color: "#fff",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                Reportes
              </Typography>

              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 14,
                  fontWeight: 500,
                  lineHeight: 1.36,
                  color: "#a4a4a4",
                  // textOverflow: "ellipsis",
                  // whiteSpace: "nowrap",
                  // overflow: "hidden",
                }}
              >
                Configura los días por default seleccionados al abrir un reporte
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            // style={{
            //   backgroundColor: "blue",
            // }}
          >
            <ScrollBars style={{ height: 580, marginTop: 20 }} color="#212429">
              {reportSettings.map((item, indexItem) => {
                return (
                  <Grid key={indexItem}>
                    <Typography className={classes.typoLabel}>
                      {item.title}
                    </Typography>
                    {arrayOptions.map((option, index) => (
                      <CheckboxComponent
                        key={index}
                        text={option}
                        reportName={item.name}
                        reportSettings={reportSettings}
                        setReportSettings={setReportSettings}
                        value={reportSettings.some(
                          (x) =>
                            x.rangoFechaDefault == option && x.name == item.name
                        )}
                      />
                    ))}
                  </Grid>
                );
              })}
            </ScrollBars>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}
