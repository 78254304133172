/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Typography, Box, Card } from "@material-ui/core";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faMapMarkerAlt,
  faBriefcaseMedical,
  faExclamationCircle,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import RViewerJS from "viewerjs-react";
import "viewerjs-react/dist/index.css";
import { DateFormatString2 } from "../../../../utils/DateFormatString";
import PropTypes from "prop-types";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    borderRadius: 16,
    backgroundColor: "#2d3034",
    padding: theme.spacing(2),
  },
  photo: {
    maxWidth: "100px",
    maxHeight: "120px",
    borderRadius: "16px",
    aspectRatio: 1,
    objectFit: "cover",
    cursor: "pointer",
  },
  // gridPaper: {
  //   padding: theme.spacing(1, 3, 1, 3),
  //   height: "auto",
  //   borderRadius: "16px",
  //   backgroundColor: "#2d3034",
  // },
  gridMargin: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
  boxProgress: {
    "& .MuiLinearProgress-colorPrimary": {
      backgroundColor: "#6d6d6d",
    },
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#d233ff",
    },
  },
}));

function LinearProgressWithLabel(props) {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1} className={classes.boxProgress}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export default function CardDetailEmployee(props) {
  const classes = useStyles();
  const { user } = useSelector((state) => state.user);
  const totalCameras = props.cameras.length;
  const totalCamerasOK = props.cameras.filter(
    (x) => x.status == "success"
  )?.length;

  return (
    <Card className={classes.divMain}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.gridPaper}
      >
        <Grid
          item
          xs={3}
          sm={3}
          md={2}
          lg={2}
          xl={2}
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <RViewerJS>
            <img src={props.urlPhoto} className={classes.photo} alt="photo" />
          </RViewerJS>

          {totalCameras > 0 && (
            <Grid
              container
              direction="column"
              alignContent="flex-start"
              style={{ marginTop: 4 }}
            >
              <Typography
                style={{
                  fontSize: 14,
                  fontWeight: 500,
                  color: "#fff",
                }}
              >
                Carga de foto
              </Typography>

              <LinearProgressWithLabel value={((totalCamerasOK * 100) / totalCameras)} />

              <Typography
                style={{
                  fontSize: 14,
                  fontWeight: 500,
                  color: "#fff",
                }}
              >
                {`${totalCamerasOK} de ${totalCameras} cámaras`}
              </Typography>

              <Typography
                style={{
                  fontSize: 14,
                  fontWeight: 500,
                  color: "#d233ff",
                }}
              >
                Ver detalle
              </Typography>
            </Grid>
          )}
        </Grid>

        <Grid
          item
          xs={8}
          // sm={7}
          // md={7}
          // lg={8}
          // xl={8}
          style={{
            height: "100%",
            paddingLeft: 20,
            overflowY: "hidden",
          }}
        >
          <Grid item xs container direction="column" spacing={2}>
            <Grid item xs>
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 20,
                  fontWeight: 500,
                  color: "#fff",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {`${props.name} ${props.lastName}`}
              </Typography>

              {props.numeroEmpleado && (
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 14,
                    color: "#acacac",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {props.numeroEmpleado}
                </Typography>
              )}

              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 14,
                  color: "#acacac",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {props.role}
              </Typography>
              <Grid item xs container direction="row">
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 16,
                    fontWeight: 500,
                    color: "#acacac",
                    marginRight: 5,
                  }}
                >
                  Unidad
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 16,
                    fontWeight: 500,
                    color: "#fff",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {props.unit == "--" ? props.subcontratistaUnidad : props.unit}
                </Typography>
              </Grid>
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 18,
                  fontWeight: 500,
                  color: "#fff",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {props.subcontractor}
              </Typography>
            </Grid>

            <Grid item xs>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <FontAwesomeIcon
                  icon={faBuilding}
                  style={{
                    width: "14px",
                    height: "18px",
                    color: "#d233ff",
                  }}
                />
                <Grid
                  style={{
                    width: "10px",
                  }}
                />
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 16,
                    color: "#fff",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    maxWidth: "100%",
                  }}
                >
                  {props.proyect}
                </Typography>
              </Grid>

              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  style={{
                    width: "14px",
                    height: "18px",
                    color: "#d233ff",
                  }}
                />
                <Grid
                  style={{
                    width: "10px",
                  }}
                />
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 16,
                    color: "#fff",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    maxWidth: "90%",
                  }}
                >
                  {props.address}
                </Typography>
              </Grid>

              {user?.EnterpriseData?.ValidateImss ? (
                <Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    {props.statusImss?.estatusActual == "IMSS Vigente" ? (
                      <FontAwesomeIcon
                        icon={faBriefcaseMedical}
                        style={{
                          width: "17px",
                          height: "16px",
                          color: "#d233ff",
                        }}
                      />
                    ) : props.statusImss?.estatusActual == "IMSS No Vigente" ? (
                      <FontAwesomeIcon
                        icon={faExclamationCircle}
                        style={{
                          width: "16px",
                          height: "16px",
                          color: "#d60101",
                        }}
                      />
                    ) : props.statusImss?.estatusActual ==
                      "Información Incorrecta" ? (
                      <FontAwesomeIcon
                        icon={faExclamationCircle}
                        style={{
                          width: "20px",
                          height: "18px",
                          color: "#fff",
                        }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        style={{
                          width: "20px",
                          height: "18px",
                          color: "#e1bd2f",
                        }}
                      />
                    )}
                    <Grid
                      style={{
                        width: "6px",
                      }}
                    />
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontSize: 16,
                        textAlign: "left",
                        color: "#fff",
                        paddingRight: 5,
                        // textOverflow: "ellipsis",
                        // whiteSpace: "nowrap",
                        // overflow: "hidden",
                        // maxWidth: "80%",
                      }}
                    >
                      {props.statusImss.estatusActual ?? "IMSS Sin Estatus"}
                    </Typography>

                    {props.statusImss?.ultimaLectura_datetime ? (
                      <Typography
                        style={{
                          fontFamily: "Roboto",
                          fontSize: 12,
                          fontWeight: 300,
                          textAlign: "center",
                          color: "#a4a4a4",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          maxWidth: "100%",
                        }}
                      >
                        (Ultima verificación{" "}
                        {DateFormatString2(
                          props.statusImss?.ultimaLectura_datetime
                        )}
                        )
                      </Typography>
                    ) : null}
                  </Grid>

                  {props.statusImss?.estatusActual ==
                  "Información Incorrecta" ? (
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Typography
                        style={{
                          fontFamily: "Roboto",
                          fontSize: 12,
                          fontWeight: 300,
                          textAlign: "left",
                          color: "#fff",
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 2,
                          textOverflow: "ellipsis",
                          // whiteSpace: "nowrap",
                          overflow: "hidden",
                          maxWidth: "100%",
                          // paddingLeft: 20,
                        }}
                      >
                        ({props.statusImss?.messageErrorBot})
                      </Typography>
                    </Grid>
                  ) : null}
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={2}
          lg={2}
          xl={2}
          container
          direction="row"
          justifyContent="center"
          className={classes.gridMargin}
        >
          <Box position="relative" display="inline-block">
            <Box top={0} left={0} bottom={0} right={0} position="absolute">
              <CircularProgress
                variant="determinate"
                value={100}
                color="secondary"
                style={{
                  width: "130px",
                  height: "130px",
                  position: "relative",
                }}
              />
            </Box>
            <CircularProgress
              size={130}
              value={props.progress ? props.progress : 0}
              variant="determinate"
            />
            <Box
              top={0}
              left={0}
              bottom={0}
              right={0}
              position="absolute"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 24,
                    textAlign: "center",
                    color: "#fff",
                  }}
                >
                  {`${props.progress ? props.progress : 0}%`}
                </Typography>

                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 12,
                    textAlign: "center",
                    color: "#d9d9d9",
                    maxWidth: "50%",
                  }}
                >
                  Perfil completado
                </Typography>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
}
