/*eslint-disable*/
import { types } from "../types/types";

export const login = (userId, userName, token) => {
  return {
    type: types.login,
    payload: {
      userId,
      userName,
      token,
    },
  };
};
