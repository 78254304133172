/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faPlusCircle,
  faPen,
  faTrashAlt,
  faArrowAltCircleLeft,
} from "@fortawesome/free-solid-svg-icons";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ScrollBars from "../../../common_components/ScrollBars";
import useSpecialtySearch from "./useSpecialtySearch";
import axios from "axios";
import { ipServer } from "../../../config/vars";

const useStyles = makeStyles((theme) => ({
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: "bold",
    textAlign: "left",
    color: "#cdcece",
  },
  textFieldCustom2: {
    width: "100%",
    color: "#676767",
    border: "solid 1px #c5c5c5",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
  },
  divImg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "356px",
    height: "290px",
    borderRadius: "8px",
    border: "solid 1px #707070",
    backgroundColor: "#212429",
    cursor: "pointer",
  },
  divBack: {
    width: "15%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    // marginBottom: "20px",
    // backgroundColor: "blue",
  },
}));

export default function DialogAdminSpecialtyList(props) {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { specialtyFlag } = props;
  const [query, setQuery] = useState({
    Type: "trabajador_Especialidades",
    Nombre: "",
  });
  const { books, hasMore, loading, error } = useSpecialtySearch(query, specialtyFlag);

  const handleOpenDialog = () => {
    props.setOpenDialogSpecialtyAdmin(!props.openDialogSpecialtyAdmin);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setQuery({
      ...query,
      [e.target.name]: value,
    });
  };

  const backStep = () => {
    props.setOpenDialogSpecialtyAdmin(!props.openDialogSpecialtyAdmin);
    props.setOpenDialogSpecialty(!props.openDialogSpecialty);
  };

  const deleteJob = async (id) => {
    // console.log(id);

    return await axios
      .delete(`${ipServer}/api/v1/rules/catalogos/${id}`, {
        headers: {
          Authorization: localStorage.getItem("JWT"),
        },
      })
      .then((response) => {
        // console.log(response.data);
        enqueueSnackbar("Puesto removido correctamente", {
          variant: "success",
        });
        props.setOpenDialogJobsAdmin(!props.openDialogSpecialtyAdmin);
        props.setJobsFlag(!props.specialtyFlag);
        return true;
      })
      .catch((e) => {
        enqueueSnackbar("Error al remover puesto", {
          variant: "error",
        });
        console.log(e);
        return false;
      });
  };

  const jobsList = () => {
    // console.log(books);
    books.sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });

    const items = books.map((job, index) => {
      return (
        <Grid
          key={index}
          container
          direction="row"
          justifyContent="flex-start"
          style={{
            paddingTop: "5%",
            paddingLeft: "5%",
          }}
        >
          <Grid item xs={10}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 20,
                fontWeight: "bold",
                textAlign: "left",
                color: "#fff",
              }}
            >
              {job.name}
            </Typography>
          </Grid>

          <Grid
            item
            xs={2}
            container
            direction="row"
            justifyContent="flex-end"
            style={{
              paddingRight: "5%",
            }}
          >
            <FontAwesomeIcon
              icon={faPen}
              onClick={() => {
                props.setJob({
                  ...props.job,
                  id: job._id,
                  name: job.name,
                });
                props.setOpenDialogJobsAdmin(!props.openDialogSpecialtyAdmin);
                props.setOpenDialogAddNewJob(!props.openDialogAddNewSpecialty);
              }}
              className={classes.iconForm}
              style={{
                width: "15px",
                height: "15px",
                color: "#fff",
                cursor: "pointer",
              }}
            />

            <Grid
              style={{
                width: "10px",
              }}
            />

            <FontAwesomeIcon
              icon={faTrashAlt}
              onClick={() => deleteJob(job._id)}
              className={classes.iconForm}
              style={{
                width: "15px",
                height: "15px",
                color: "#fff",
                cursor: "pointer",
              }}
            />
          </Grid>
        </Grid>
      );
    });
    return items;
  };

  return (
    <div>
      <Dialog
        open={props.openDialogSpecialtyAdmin}
        onClose={handleOpenDialog}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            backgroundColor: "#171a1e",
            textColor: "#fsf",
            width: "483px",
            height: "600px",
            paddingTop: 15,
          },
        }}
      >
        <DialogTitle>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={1} onClick={backStep} className={classes.divBack}>
              <FontAwesomeIcon
                icon={faArrowAltCircleLeft}
                style={{
                  width: "20px",
                  height: "20px",
                  marginRight: "10px",
                  color: "#d6d6d6",
                }}
              />
            </Grid>

            <Grid item xs={9}>
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 22,
                  textAlign: "center",
                  color: "#fff",
                  fontWeight: "bold",
                }}
              >
                Adminsitración de Especialidades
              </Typography>
            </Grid>

            <Grid item xs={2}>
              <Button onClick={handleOpenDialog}>
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{
                    width: "20px",
                    height: "20px",
                    color: "#fff",
                  }}
                />
              </Button>
            </Grid>
          </Grid>
          <div
            style={{
              width: "100%",
              height: 1,
              background: "#707070",
              marginTop: 8,
              padding: 0,
            }}
          ></div>
        </DialogTitle>

        <DialogContent>
          <Grid
            container
            direction="column"
            justifyContent="center"
            // alignItems="center"
          >
            <Grid container direction="row" justifyContent="flex-end">
              <Grid item xs={10}>
                <TextField
                  placeholder="Buscar..."
                  name="Nombre"
                  size="small"
                  variant="outlined"
                  value={query?.Nombre}
                  className={classes.textFieldCustom2}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  onClick={() => {
                    props.setSpecialty({
                      ...props.specialty,
                      id: "",
                      name: "",
                    });
                    props.setOpenDialogSpecialtyAdmin(!props.openDialogSpecialtyAdmin);
                    props.setOpenDialogAddNewSpecialty(!props.openDialogAddNewSpecialty);
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    style={{
                      width: "30px",
                      height: "30px",
                      color: "#d233ff",
                      backgroundColor: "#fff",
                      borderRadius: "50%",
                    }}
                  />
                </Button>
              </Grid>
            </Grid>

            <ScrollBars style={{ height: 410 }}>{jobsList()}</ScrollBars>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
