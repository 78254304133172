/*eslint-disable*/
import React from "react";
import {
  Typography,
  Grid,
  Button,
  TextField,
  IconButton,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import axiosInstance from "../../utils/axiosInstance";

export default function DialogNewRole(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { openDialog, setOpenDialog, setUpdate } = props;

  const handleOpenModal = () => {
    setOpenDialog(!openDialog);
    formik.resetForm();
  };

  const schema = Yup.object({
    roleName: Yup.string().required("Campo Obligatorio"),
  });

  const formik = useFormik({
    initialValues: {
      roleName: "",
    },
    validationSchema: schema,
    enableReinitialize: true,
  });

  const validateData = async () => {
    return await schema.isValid(formik.values);
  };

  const handleNewRole = async (values) => {
    const { roleName } = values;

    if (!(await validateData())) {
      basictoaster("basicwarningToast", "Llenar todos los campos requridos");
      return;
    }

    const addRole = await postRoles(roleName);

    if (addRole) {
      handleOpenModal();
      setUpdate((update) => !update);
      enqueueSnackbar("Role agregado correctamente", {
        variant: "success",
      });
    } else {
      enqueueSnackbar("Error al agregar role", {
        variant: "error",
      });
    }

    formik.resetForm(roleName);
  };

  const postRoles = async (name) => {
    return await axiosInstance()
      .post(`/api/v1/security/role`, { RoleName: name, Permisions: {} })
      .then((response) => {
        return true;
      })
      .catch((e) => {
        console.log(e);
        return false;
      });
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleOpenModal}
      disableEnforceFocus
      aria-labelledby="form-dialog-title"
      PaperProps={{
        style: {
          backgroundColor: "#171a1e",
          width: 476,
          maxWidth: 476,
          height: 342,
          maxHeight: 342,
          padding: 15,
          borderRadius: 12,
        },
      }}
    >
      <DialogTitle>
        <Grid container direction="row" justifyContent="center">
          <Grid item xs={11}>
            <Typography
              style={{
                fontSize: 20,
                textAlign: "center",
                color: "#fff",
                fontWeight: "bold",
                marginLeft: 20,
              }}
            >
              Crear rol
            </Typography>
          </Grid>

          <Grid item xs={1}>
            <Grid container direction="row" justifyContent="flex-end">
              <IconButton onClick={handleOpenModal}>
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{
                    width: 25,
                    height: 25,
                    color: "#bcbcbc",
                  }}
                />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12} style={{ margin: "auto", marginTop: 15 }}>
            <Typography
              style={{
                fontSize: 16,
                fontWeight: "bold",
                textAlign: "left",
                color: "#cdcece",
              }}
            >
              Nombre de rol
            </Typography>
            <TextField
              placeholder="admin"
              id="roleName"
              name="roleName"
              size="small"
              variant="outlined"
              style={{
                width: 400,
                color: "#8e8e8e",
                borderRadius: 4,
                backgroundColor: "#ffffff",
                border: "solid 0.5px #186cba",
                marginTop: 2,
              }}
              value={formik.values.roleName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              inputProps={{
                maxLength: 20,
              }}
            />
          </Grid>

          <Grid item xs={12} style={{ margin: "auto", marginTop: 60 }}>
            <Button
              onClick={() => handleNewRole(formik.values)}
              style={{
                width: 240,
                height: 40,
                borderRadius: 12,
                backgroundColor: "#ac0bce",
                textTransform: "none",
              }}
            >
              <Typography
                style={{
                  fontSize: 16,
                  color: "#ffffff",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                Guardar
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
