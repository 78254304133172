/*eslint-disable*/
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { TextField, Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import useEmployeeSearch from "./useEmployeeSearch";
import CardEmpleado from "./CardEmpleado";
import NotEmployeeFound from "./NotEmployeeFound";
import { useDispatch, useSelector } from "react-redux";
import { clearFiles } from "../../../actions/file";
import { FiltersEmployees } from "../../../actions/filters";
import { ValidatePermissions } from "../../../utils/permissions";
import MultiSelect from "../../../common_components/Filters/MutiSelect";
import axiosInstance from "../../../utils/axiosInstance";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    padding: 15,
  },
  buttonSearch: {
    width: "232px",
    height: "40px",
    borderRadius: "8px",
    color: "#ffffff",
    backgroundColor: "#ac0bce",
    textTransform: "none",
    [theme.breakpoints.down("md")]: {
      marginTop: 10,
      marginLeft: 0,
    },
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
      marginLeft: 10,
    },
  },
  multi: {
    color: "white",
    padding: 2,
    border: 0,
    borderRadius: "8px",
  },
  title: {
    fontFamily: "Roboto",
    fontSize: 28,
    fontWeight: 600,
    textAlign: "left",
    color: "#ffffff",
    marginBottom: 25,
  },
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 16,
    textAlign: "left",
    color: "#676767",
  },
  textFieldCustom: {
    width: "478px",
    color: "#676767",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
  },
}));

export default function SearchEmployeeScreen(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { employees } = useSelector((state) => state.filters);
  const [pageNumber, setPageNumber] = useState(0);
  const [subcontratistas, setSubcontratistas] = useState(null);
  const [proyectos, setProyectos] = useState(null);
  const [status, setStatus] = useState(null);
  const [statusImss, setStatusImss] = useState(null);
  const [loadingData, setLoadingData] = useState(false);

  const [query, setQuery] = useState({
    Empleado: employees?.Empleado ?? "",
    Proyectos: employees?.Proyectos ?? [],
    Subcontratistas: employees?.Subcontratistas ?? [],
    Status: employees?.Status ?? [],
    EstatusImss: employees?.EstatusImss ?? [],
  });

  const registerEmployee = () => {
    localStorage.setItem("idEmployee", "");
    dispatch(clearFiles());
    history.push(`/dashboard/empleados/crear`);
  };

  const { books, hasMore, loading, error } = useEmployeeSearch(
    query,
    pageNumber,
    loadingData,
    proyectos,
    subcontratistas
  );

  const getProyectsList = () => {
    return new Promise((resolve, reject) => {
      var params = new URLSearchParams();
      params.append("sortMongoAsc", "proyecto");

      axiosInstance()
        .get(`/api/v1/rules/trabajadores/comboboxes/obras`, { params })
        .then((response) => {
          let proyects = response.data.data.map((object) => {
            return { label: object.proyecto, value: object._id };
          });

          setProyectos(proyects);
          resolve(proyects);
        })
        .catch((e) => {
          console.log(e);
          resolve(false);
        });
    });
  };

  const getSubcontractorList = () => {
    return new Promise((resolve, reject) => {
      var params = new URLSearchParams();
      params.append("showoculta", true);
      params.append("sortMongoAsc", "empresa");

      axiosInstance()
        .get("/api/v1/rules/trabajadores/comboboxes/subcontratistas", {
          params,
        })
        .then((response) => {
          let empresa = response.data.data.map((object) => {
            return { label: object.empresa, value: object._id };
          });

          setSubcontratistas(empresa);
          resolve(empresa);
        })
        .catch((e) => {
          console.log(e);
          resolve(false);
        });
    });
  };

  const getStatusList = () => {
    return new Promise((resolve, reject) => {
      var params = new URLSearchParams();
      params.append("name_nestring", "Borrador");
      params.append("sortMongoAsc", "name");

      axiosInstance()
        .get("/api/v1/rules/trabajadores/comboboxes/estatus", { params })
        .then((response) => {
          let status = response.data.data.map((object) => {
            return { label: object.name, value: object._id };
          });

          setStatus(status);
          resolve(status);
        })
        .catch((e) => {
          console.log(e);
          resolve(false);
        });
    });
  };

  const getStatusImss = () => {
    return new Promise((resolve, reject) => {
      var params = new URLSearchParams();
      params.append("sortMongoAsc", "name");

      axiosInstance()
        .get("/api/v1/rules/trabajadores/comboboxes/estatusimss", { params })
        .then((response) => {
          let status = response.data.data.map((object) => {
            return { label: object.name, value: object._id };
          });

          setStatusImss(status);
          resolve(status);
        })
        .catch((e) => {
          console.log(e);
          resolve(false);
        });
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoadingData(false);
      await getSubcontractorList();
      await getProyectsList();
      await getStatusList();
      await getStatusImss();

      // setQuery({
      //   ...query,
      //   Proyectos: _proyectos,
      //   Subcontratistas: _subcontratistas,
      // });

      setLoadingData(true);
    };

    fetchData();
  }, [user]);

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const handleQueryChange = (queryElement) => (e) => {
    const value =
      queryElement === "Proyectos" ||
      queryElement === "Subcontratistas" ||
      queryElement === "Status" ||
      queryElement === "EstatusImss"
        ? e
        : e.target.value;
    return setQuery((prevQuery) => {
      return { ...prevQuery, ...{ [queryElement]: value } };
    });
  };

  useEffect(() => {
    setPageNumber(0);
    dispatch(FiltersEmployees(query));
  }, [query]);

  return (
    <div className={classes.divMain}>
      <Typography className={classes.title}>Empleados</Typography>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <TextField
          variant="outlined"
          size="small"
          placeholder="Buscar por nombre o número de empleado"
          value={query?.Empleado}
          onChange={handleQueryChange("Empleado")}
          className={classes.textFieldCustom}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon
                  icon={faSearch}
                  style={{
                    width: "18px",
                    height: "18px",
                    color: "#676767",
                  }}
                />
              </InputAdornment>
            ),
          }}
        />

        {ValidatePermissions("crear") && (
          <Button
            variant="contained"
            onClick={registerEmployee}
            className={classes.buttonSearch}
          >
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 18,
                textAlign: "left",
                color: "#fff",
              }}
            >
              Registrar empleado
            </Typography>
          </Button>
        )}
      </Grid>

      <Grid
        style={{
          height: "20px",
        }}
      />

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 16,
              textAlign: "left",
              color: "#dedcdc",
            }}
          >
            Obra
          </Typography>
          <div>
            <MultiSelect
              options={proyectos ? proyectos : []}
              value={query.Proyectos}
              onChange={handleQueryChange("Proyectos")}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 16,
              textAlign: "left",
              color: "#dedcdc",
            }}
          >
            Subcontratista
          </Typography>
          <div>
            <MultiSelect
              options={subcontratistas ? subcontratistas : []}
              value={query.Subcontratistas}
              onChange={handleQueryChange("Subcontratistas")}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
          <Typography
            style={{
              fontFamily: "Roboto",
              fontSize: 16,
              textAlign: "left",
              color: "#dedcdc",
            }}
          >
            Estatus
          </Typography>
          <div>
            <MultiSelect
              options={status ? status : []}
              value={query.Status}
              onChange={handleQueryChange("Status")}
            />
          </div>
        </Grid>
        {user?.EnterpriseData?.ValidateImss ? (
          <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
            <Typography
              style={{
                fontFamily: "Roboto",
                fontSize: 16,
                textAlign: "left",
                color: "#dedcdc",
              }}
            >
              Estatus IMSS
            </Typography>
            <div>
              <MultiSelect
                options={statusImss ? statusImss : []}
                value={query.EstatusImss}
                onChange={handleQueryChange("EstatusImss")}
              />
            </div>
          </Grid>
        ) : null}
      </Grid>

      <Grid
        style={{
          height: "30px",
        }}
      />

      {/* <Box
        style={{ marginTop: 30 }}
        sx={{
          display: "grid",
          gap: 40,
          gridTemplateColumns: "repeat(auto-fill, minmax(320px, 1fr))",
        }}
      >
        {books.length == 0 && !loading ? (
          <NotEmployeeFound />
        ) : (
          books.map((event, index) => (
            <Grid ref={lastBookElementRef} key={index}>
              <CardEmpleado
                statusDemek={event.statusDemek}
                idUser={event._id}
                urlPhoto={event.foto}
                name={event.nombre}
                lastName={event.apellidos ? event.apellidos : ""}
                numeroEmpleado={event.numeroEmpleado}
                role={event.puesto}
                subcontractor={
                  event.subcontratistas[0]
                    ? event.subcontratistas[0].empresa
                    : ""
                }
                proyect={event.proyectos[0] ? event.proyectos[0].proyecto : ""}
                percentage={event.percentage ? event.percentage : 0}
                statusImss={
                  event.estatusImss
                    ? event.estatusImss?.estatusActual
                    : "IMSS Sin Estatus"
                }
                incidents={event.incidencia ? event.incidencia.length : null}
                url={`/dashboard/empleados/${event._id}`}
              />
            </Grid>
          ))
        )}
      </Box> */}

      <Grid container spacing={3}>
        {books.length == 0 && !loading ? (
          <NotEmployeeFound></NotEmployeeFound>
        ) : (
          books.map((event, index) => {
            if (books.length === index + 1) {
              return (
                <Grid
                  ref={lastBookElementRef}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  xl={3}
                  key={index}
                >
                  <CardEmpleado
                    statusDemek={event.statusDemek}
                    idUser={event._id}
                    urlPhoto={event.foto}
                    name={event.nombre}
                    lastName={event.apellidos ? event.apellidos : ""}
                    numeroEmpleado={event.numeroEmpleado}
                    // role={event.puesto}
                    role={
                      user?.EnterpriseData?.Db != "GrupoGarzaPonce903228"
                        ? event?.puesto
                        : event?.especialidad
                    }
                    subcontractor={
                      event.subcontratistas[0]
                        ? event.subcontratistas[0].empresa
                        : ""
                    }
                    proyect={
                      event.proyectos[0] ? event.proyectos[0].proyecto : ""
                    }
                    percentage={event.percentage ? event.percentage : 0}
                    statusImss={
                      event.estatusImss
                        ? event.estatusImss?.estatusActual
                        : "IMSS Sin Estatus"
                    }
                    incidents={
                      event.incidencia ? event.incidencia.length : null
                    }
                    url={`/dashboard/empleados/${event._id}`}
                  />
                </Grid>
              );
            } else {
              return (
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={index}>
                  <CardEmpleado
                    statusDemek={event.statusDemek}
                    idUser={event._id}
                    urlPhoto={event.foto}
                    name={event.nombre}
                    lastName={event.apellidos ? event.apellidos : ""}
                    numeroEmpleado={event.numeroEmpleado}
                    // role={event.puesto}
                    role={
                      user?.EnterpriseData?.Db != "GrupoGarzaPonce903228"
                        ? event?.puesto
                        : event?.especialidad
                    }
                    subcontractor={
                      event.subcontratistas[0]
                        ? event.subcontratistas[0].empresa
                        : ""
                    }
                    proyect={
                      event.proyectos[0] ? event.proyectos[0].proyecto : ""
                    }
                    percentage={event.percentage ? event.percentage : 0}
                    statusImss={
                      event.estatusImss
                        ? event.estatusImss?.estatusActual
                        : "IMSS Sin Estatus"
                    }
                    incidents={
                      event.incidencia ? event.incidencia.length : null
                    }
                    url={`/dashboard/empleados/${event._id}`}
                  />
                </Grid>
              );
            }
          })
        )}
      </Grid>

      <Box
        display="flex"
        m={1}
        p={1}
        alignItems="center"
        justifyContent="center"
      >
        {loading && (
          <CircularProgress disableShrink size={100} color="secondary" />
        )}
      </Box>

      {/* <Box
        display="flex"
        m={1}
        p={1}
        alignItems="center"
        justifyContent="center"
      >
        {!hasMore && books.length > 0 && (
          <Typography variant="caption" color="textPrimary" component="p">
            No hay mas eventos
          </Typography>
        )}
      </Box> */}
    </div>
  );
}
