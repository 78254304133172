/*eslint-disable*/
// Get range date filter

export const dateFilter = (range) => {
  var obj = {};

  obj.initialDate =
    range == "Semana en curso"
      ? new Date(
          new Date(
            new Date().setDate(new Date().getDate() - new Date().getDay() + 1)
          ).setHours(0, 0, 0, 0)
        )
      : range == "Mes en curso"
      ? new Date(new Date().getFullYear(), new Date().getMonth(), 1)
      : range == "Últimos 7 días"
      ? new Date(
          new Date(new Date().setDate(new Date().getDate() - 7)).setHours(
            0,
            0,
            0,
            0
          )
        )
      : range == "Últimos 30 días"
      ? new Date(
          new Date(new Date().setDate(new Date().getDate() - 30)).setHours(
            0,
            0,
            0,
            0
          )
        )
      : new Date(new Date().setHours(0, 0, 0, 0));

  obj.finalDate =
    range == "Semana en curso"
      ? new Date(
          new Date(
            new Date().setDate(new Date().getDate() - new Date().getDay() + 7)
          ).setHours(23, 59, 59, 999)
        )
      : range == "Mes en curso"
      ? new Date(
          new Date(
            new Date().getFullYear(),
            new Date().getMonth() + 1,
            0
          ).setHours(23, 59, 59, 999)
        )
      : new Date(new Date().setHours(23, 59, 59, 999));

  //   console.log("initialDate: " + obj.initialDate);
  //   console.log("finalDate: " + obj.finalDate);
  return obj;
};
