/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import svgTrabajador from "../../../../images/usuario_trabajador.svg";
import { faMapMarkerAlt, faImage } from "@fortawesome/free-solid-svg-icons";
import WorkIcon from "@material-ui/icons/Work";
import { ValidatePermissions } from "../../../../utils/permissions";

const useStyles = makeStyles((theme) => ({
  divMain: {
    maxWidth: 345,
  },
  card: {
    width: "100%",
    height: "100%",
    borderRadius: "16px",
    backgroundColor: "#2d3034",
    padding: "16px",
    objectFit: "cover",
  },

  editResponsive: {
    marginTop: -25,

    [theme.breakpoints.down("md")]: {
      marginTop: 0,
    },
  },
  gridResponsive: {
    marginLeft: 10,

    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
      marginLeft: 0,
    },
  },
  textResponsive: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    maxWidth: "100%",
    [theme.breakpoints.down("md")]: {
      maxWidth: "90%",
    },
  },
}));

export default function CardObras(props) {
  const classes = useStyles();
  const history = useHistory();
  const { data } = props;

  return (
    <Card className={classes.card}>
      <Grid container direction="row-reverse" justifyContent="center">
        <Grid container item xs={12} justifyContent="flex-end">
          {ValidatePermissions("editar") && (
            <Typography
              onClick={() => {
                localStorage.setItem("idSite", data._id);
                history.push(`/dashboard/obras/editar/${data._id}`);
              }}
              style={{
                fontFamily: "Roboto",
                fontSize: 18,
                fontWeight: 500,
                color: "#d233ff",
                cursor: "pointer",
                zIndex: 1,
              }}
            >
              Editar
            </Typography>
          )}
        </Grid>

        <Grid
          container
          item
          xs={12}
          direction="row"
          justifyContent="flex-start"
          className={classes.editResponsive}
          // style={{ marginTop: -25 }}
        >
          <Grid item>
            {data?.foto ? (
              <img
                src={data?.foto.url}
                width="100%"
                height="204px"
                style={{
                  borderRadius: 8,
                  minWidth: 200,
                  width: 292,
                  maxWidth: 292,
                  maxHeight: 204,
                  objectFit: "cover",
                }}
              />
            ) : (
              <Grid
                container
                justifyContent="center"
                alignContent="center"
                style={{
                  width: 292,
                  maxWidth: 292,
                  maxHeight: 204,
                  height: 204,
                  borderRadius: 8,
                  backgroundColor: "#a5a5a5",
                }}
              >
                <FontAwesomeIcon
                  icon={faImage}
                  style={{
                    width: 98,
                    height: 68,
                    color: "#717171",
                  }}
                />
              </Grid>
            )}
          </Grid>

          <Grid item xs={12} lg={7}>
            <Grid className={classes.gridResponsive}>
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontWeight: 500,
                  fontSize: 18,
                  textAlign: "left",
                  color: "#fff",
                }}
              >
                {`${data?.proyecto}`}
              </Typography>

              <Grid container direction="row" justifyContent="flex-start">
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  style={{
                    width: "11px",
                    height: "15px",
                    color: "#d233ff",
                    marginTop: 2,
                  }}
                />
                <Typography
                  className={classes.textResponsive}
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 14,
                    color: "#fff",
                    marginLeft: 8,
                  }}
                >
                  {data?.domicilio}
                </Typography>
              </Grid>

              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                style={{ marginTop: 10 }}
              >
                <WorkIcon
                  style={{
                    color: "#fff",
                    width: "13.6px",
                    height: "12.5px",
                    marginTop: 2,
                  }}
                />

                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 14,
                    color: "#fff",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    maxWidth: "90%",
                    marginLeft: 6,
                  }}
                >
                  {`${data?.obrasCount} subcontratistas`}
                </Typography>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                style={{ marginTop: 4 }}
              >
                <img
                  src={svgTrabajador}
                  style={{
                    width: 14,
                    height: 14,
                    marginTop: 1,
                  }}
                  alt="techton logo"
                />
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 14,
                    color: "#fff",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    maxWidth: "90%",
                    marginLeft: 6,
                  }}
                >
                  {`${data?.trabajadoresCount} trabajadores`}
                </Typography>
              </Grid>

              <Grid container direction="row" style={{ marginTop: 15 }}>
                <Grid item xs={6} container direction="row">
                  <Grid item xs={6}>
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontSize: 14,
                        color: "#c1c1c1",
                      }}
                    >
                      Registro Patronal
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontSize: 14,
                        color: "#fff",
                      }}
                    >
                      {data?.registroPatronal != ""
                        ? data?.registroPatronal
                        : "--"}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item xs={6} container direction="row">
                  <Grid item xs={7}>
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontSize: 14,
                        color: "#c1c1c1",
                      }}
                    >
                      Delegación IMSS
                    </Typography>
                  </Grid>

                  <Grid item xs={5}>
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontSize: 14,
                        color: "#fff",
                      }}
                    >
                      {data?.delegacionIMSS != "" ? data?.delegacionIMSS : "--"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container direction="row" style={{ marginTop: 8 }}>
                <Grid item xs={6} container direction="row">
                  <Grid item xs={6}>
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontSize: 14,
                        color: "#c1c1c1",
                      }}
                    >
                      Registro SIROC
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontSize: 14,
                        color: "#fff",
                      }}
                    >
                      {data?.registroSIROC != "" ? data?.registroSIROC : "--"}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item xs={6} container direction="row">
                  <Grid item xs={7}>
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontSize: 14,
                        color: "#c1c1c1",
                      }}
                    >
                      Subdelegación IMSS
                    </Typography>
                  </Grid>

                  <Grid item xs={5}>
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontSize: 14,
                        color: "#fff",
                      }}
                    >
                      {data?.subdelegacionIMSS != ""
                        ? data?.subdelegacionIMSS
                        : "--"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container direction="row" style={{ marginTop: 8 }}>
                <Grid item xs={6} container direction="row">
                  <Grid item xs={6}>
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontSize: 14,
                        color: "#c1c1c1",
                      }}
                    >
                      Clave de Proyecto
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography
                      style={{
                        fontFamily: "Roboto",
                        fontSize: 14,
                        color: "#fff",
                      }}
                    >
                      {data?.claveProyecto != "" ? data?.claveProyecto : "--"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}
