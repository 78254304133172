/*eslint-disable*/
import React, { useState, useEffect, Fragment } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Typography, TextField } from "@material-ui/core";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import { useDropzone } from "react-dropzone";
import { useFormik } from "formik";
import * as Yup from "yup";
import DialogMaps from "./DialogMaps";
import { CapitalizeString } from "../../../utils/CapitalizeString";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
  },
  divImg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "160px",
    height: "160px",
    borderRadius: "16px",
    border: "solid 1px #707070",
    backgroundColor: "#212429",
    cursor: "pointer",
  },
  icon: {
    width: "40px",
    height: "40px",
    color: "white",
    marginBottom: "20px",
  },
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: "bold",
    textAlign: "left",
    color: "#cdcece",
  },
  textFieldCustom: {
    width: "90%",
    color: "#676767",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
  },
  select: {
    width: "100%",
    height: "40px",
    borderRadius: "6px",
    backgroundColor: "#fff",
  },
}));

export default function InfoBasic(props) {
  const classes = useStyles();
  const [openDialog, setOpendialog] = useState(false);

  // const schema = Yup.object({
  //   nombre: Yup.string().required("Campo Obligatorio"),
  //   ubicacion: Yup.string().required("Campo Obligatorio"),
  //   cliente: Yup.string().required("Campo Obligatorio"),
  //   claveProyecto: Yup.string(),
  //   registroPatronal: Yup.string(),
  //   registroSIROC: Yup.string(),
  //   delegacionIMSS: Yup.string(),
  //   subdelegacionIMSS: Yup.string(),
  // });

  // const formik = useFormik({
  //   initialValues: {
  //     nombre: props.obrasData.name,
  //     ubicacion: props.obrasData.address,
  //     cliente: props.obrasData.client,
  //     claveProyecto: "",
  //     registroPatronal: "",
  //     registroSIROC: "",
  //     delegacionIMSS: "",
  //     subdelegacionIMSS: "",
  //   },
  //   validationSchema: schema,
  // });

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    noKeyboard: true,
    accept: "image/*",
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      acceptedFiles.map((file) => processImage(file));
    },
  });

  const processImage = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let fileURL = window.URL.createObjectURL(file);
      props.setObrasData({
        ...props.obrasData,
        photo: file,
        photoCache: fileURL,
      });
      props.setChangeDialog(true);
    };
  };

  const handleChange = (e) => {
    props.setChangeDialog(true);
    const value = e.target.value;
    props.setObrasData({
      ...props.obrasData,
      [e.target.name]: value,
    });
  };

  const handleDialog = () => {
    setOpendialog(!openDialog);
  };

  useEffect(() => {
    // console.log(props.obrasData)
    if (
      props.obrasData.name != "" &&
      props.obrasData.address != "" &&
      props.obrasData.client != "" &&
      props.obrasData.claveProyecto != ""
    ) {
      props.setMandatoryFlag(true);
    } else {
      props.setMandatoryFlag(false);
    }
  }, [props.obrasData]);

  return (
    <Fragment>
      <Grid container direction="row" justifyContent="center">
        <div className={classes.divImg} {...getRootProps()}>
          <input {...getInputProps()} />

          {props.obrasData.photoCache ? (
            <img
              src={props.obrasData.photoCache}
              style={{ width: "80%", height: "80%" }}
              alt="photoCache"
            />
          ) : (
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              style={{ width: "100%" }}
            >
              <AddToPhotosIcon className={classes.icon} />
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 16,
                  fontWeight: 500,
                  lineHeight: "1.13",
                  textAlign: "center",
                  color: "#d9d9d9",
                  htmlFor: "fileInput",
                }}
              >
                Subir imagen
              </Typography>
            </Grid>
          )}
        </div>
      </Grid>

      <Grid item xs={12} container style={{ marginTop: 30 }}>
        <Grid
          item
          xs={4}
          container
          direction="row"
          justifyContent="flex-start"
          alignContent="center"
        >
          <Typography className={classes.textFieldLetter}>
            Nombre de obra
          </Typography>
          <TextField
            name="name"
            size="small"
            variant="outlined"
            placeholder="Obligatorio"
            className={classes.textFieldCustom}
            value={props.obrasData.name}
            onChange={handleChange}
            disabled={props.obrasData?.id ? true : false}
            // value={formik.values.nombre}
            // onChange={formik.handleChange}
            // onBlur={formik.handleBlur}
            inputProps={{
              maxLength: 50,
              style: { textTransform: "capitalize" },
            }}
          />
        </Grid>

        <Grid item xs={4} onClick={handleDialog}>
          <Typography className={classes.textFieldLetter}>
            Ubicación de obra
          </Typography>
          <TextField
            name="address"
            size="small"
            variant="outlined"
            placeholder="Obligatorio"
            className={classes.textFieldCustom}
            value={props.obrasData.address}
            onChange={handleChange}
            InputProps={{
              readOnly: true,
            }}
            // value={formik.values.ubicacion}
            // onChange={formik.handleChange}
            // onBlur={formik.handleBlur}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography className={classes.textFieldLetter}>
            Nombre de cliente
          </Typography>
          <TextField
            name="client"
            size="small"
            variant="outlined"
            placeholder="Obligatorio"
            className={classes.textFieldCustom}
            value={props.obrasData.client}
            onChange={handleChange}
            // value={formik.values.cliente}
            // onChange={formik.handleChange}
            // onBlur={formik.handleBlur}
            inputProps={{
              maxLength: 50,
              style: { textTransform: "capitalize" },
            }}
          />
        </Grid>

        <Grid item xs={4} style={{ marginTop: 15 }}>
          <Typography className={classes.textFieldLetter}>
            Clave de Proyecto
          </Typography>
          <TextField
            name="claveProyecto"
            size="small"
            variant="outlined"
            placeholder="Obligatorio"
            className={classes.textFieldCustom}
            value={props.obrasData.claveProyecto.toUpperCase()}
            onChange={handleChange}
            // value={formik.values.claveProyecto}
            // onChange={formik.handleChange}
            // onBlur={formik.handleBlur}
          />
        </Grid>

        <Grid item xs={4} style={{ marginTop: 15 }}>
          <Typography className={classes.textFieldLetter}>
            Registro patronal
          </Typography>
          <TextField
            name="registroPatronal"
            size="small"
            variant="outlined"
            className={classes.textFieldCustom}
            value={props.obrasData.registroPatronal.toUpperCase()}
            onChange={handleChange}
            // value={formik.values.registroPatronal}
            // onChange={formik.handleChange}
            // onBlur={formik.handleBlur}
            inputProps={{
              maxLength: 11,
            }}
          />
        </Grid>

        <Grid item xs={4} style={{ marginTop: 15 }}>
          <Typography className={classes.textFieldLetter}>
            Registro obra SIROC
          </Typography>
          <TextField
            name="registroSIROC"
            size="small"
            variant="outlined"
            className={classes.textFieldCustom}
            value={props.obrasData.registroSIROC.toUpperCase()}
            onChange={handleChange}
            // value={formik.values.registroSIROC}
            // onChange={formik.handleChange}
            // onBlur={formik.handleBlur}
          />
        </Grid>

        <Grid item xs={4} style={{ marginTop: 15 }}>
          <Typography className={classes.textFieldLetter}>
            Delegación IMSS
          </Typography>
          <TextField
            name="delegacionIMSS"
            size="small"
            variant="outlined"
            className={classes.textFieldCustom}
            value={props.obrasData.delegacionIMSS}
            onChange={handleChange}
            // value={formik.values.delegacionIMSS}
            // onChange={formik.handleChange}
            // onBlur={formik.handleBlur}
          />
        </Grid>

        <Grid item xs={4} style={{ marginTop: 15 }}>
          <Typography className={classes.textFieldLetter}>
            Subdelegación IMSS
          </Typography>
          <TextField
            name="subdelegacionIMSS"
            size="small"
            variant="outlined"
            className={classes.textFieldCustom}
            value={props.obrasData.subdelegacionIMSS}
            onChange={handleChange}
            // value={formik.values.subdelegacionIMSS}
            // onChange={formik.handleChange}
            // onBlur={formik.handleBlur}
          />
        </Grid>
      </Grid>

      <DialogMaps
        setOpendialog={setOpendialog}
        openDialog={openDialog}
        obrasData={props.obrasData}
        setObrasData={props.setObrasData}
      />
    </Fragment>
  );
}
