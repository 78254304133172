/*eslint-disable*/
import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import {
  faTimes,
  faCog,
  faPen,
  faTrashAlt,
  faPlusCircle,
  faArrowAltCircleLeft,
} from "@fortawesome/free-solid-svg-icons";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ScrollBars from "../../../common_components/ScrollBars";
import useJobsSearch from "./useJobsSearch";
import { useSelector } from "react-redux";
import { CapitalizeString } from "../../../utils/CapitalizeString";
import axiosInstance from "../../../utils/axiosInstance";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Roboto",
    fontSize: 22,
    fontWeight: "bold",
    textAlign: "center",
    color: "#fff",
  },
  divDivider: {
    width: "100%",
    height: 1,
    background: "#707070",
    marginTop: 8,
    padding: 0,
  },
  gridJob: {
    paddingTop: "5%",
    paddingLeft: "5%",
  },
  gridJob2: {
    paddingRight: "5%",
  },
  typoJob: {
    fontFamily: "Roboto",
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "left",
    color: "#fff",
  },
  iconEdit: {
    width: "15px",
    height: "15px",
    color: "#fff",
    cursor: "pointer",
  },
  iconBack: {
    width: "20px",
    height: "20px",
    marginRight: "10px",
    color: "#d6d6d6",
  },
  iconClose: {
    width: "20px",
    height: "20px",
    color: "#fff",
  },
  textFieldLetter: {
    fontFamily: "Roboto",
    fontSize: 17,
    fontWeight: "bold",
    textAlign: "left",
    color: "#cdcece",
    marginLeft: "18px",
  },
  textFieldCustom: {
    width: "90%",
    color: "#676767",
    border: "solid 1px #c5c5c5",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
  },
  textFieldCustom2: {
    width: "100%",
    color: "#676767",
    border: "solid 1px #c5c5c5",
    borderRadius: "6px",
    backgroundColor: "#ffffff",
  },
  button: {
    width: "80%",
    height: "48px",
    borderRadius: "8px",
    backgroundColor: "#ac0bce",
  },
  typoButton: {
    fontFamily: "Roboto",
    fontSize: 21,
    textAlign: "left",
    color: "#fff",
    textTransform: "none",
  },
}));

export default function DialogJobsList(props) {
  const { jobsFlag, setJobsFlag, openDialogJobs, setOpenDialogJobs } = props;
  const classes = useStyles();
  const [dialog, setDialog] = useState(1);
  const { user } = useSelector((state) => state.user);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [query, setQuery] = useState({
    Nombre: "",
  });

  const { books, hasMore, loading, error } = useJobsSearch(query, jobsFlag);

  const handleOpenDialog = () => {
    setOpenDialogJobs(!openDialogJobs);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setQuery({
      ...query,
      [e.target.name]: value,
    });
  };

  const updateJob = async (id) => {
    // console.log(props.job);
    if (props.job.id == "") {
      await addNewJob();
    } else {
      await editJob(id);
    }

    setJobsFlag(!jobsFlag);
  };

  const addNewJob = async () => {
    return await axiosInstance()
      .post(
        `/api/v1/rules/catalogos`,
        {
          type: props.job.type,
          name: props.job.name,
          description: props.job.description,
          extra: props.job.extra,
        },
        {
          headers: {
            Authorization: localStorage.getItem("JWT"),
          },
        }
      )
      .then((response) => {
        // console.log(response.data);
        enqueueSnackbar("Puesto agregado correctamente", {
          variant: "success",
        });
        setDialog(1);
        return true;
      })
      .catch((e) => {
        enqueueSnackbar("Error al agregar puesto", {
          variant: "error",
        });
        console.log(e);
        return false;
      });
  };

  const editJob = async (id) => {
    return await axiosInstance()
      .patch(
        `/api/v1/rules/catalogos/${id}`,
        {
          type: props.job.type,
          name: props.job.name,
          description: props.job.description,
          extra: props.job.extra,
        },
        {
          headers: {
            Authorization: localStorage.getItem("JWT"),
          },
        }
      )
      .then((response) => {
        // console.log(response.data);
        enqueueSnackbar("Puesto actualizado correctamente", {
          variant: "success",
        });
        setDialog(1);
        return true;
      })
      .catch((e) => {
        enqueueSnackbar("Error al editar puesto", {
          variant: "error",
        });
        console.log(e);
        return false;
      });
  };

  const deleteJob = async (id) => {
    return await axiosInstance()
      .delete(`/api/v1/rules/catalogos/${id}`, {
        headers: {
          Authorization: localStorage.getItem("JWT"),
        },
      })
      .then((response) => {
        // console.log(response.data);
        enqueueSnackbar("Puesto removido correctamente", {
          variant: "success",
        });
        setJobsFlag(!jobsFlag);
        setDialog(1);
        return true;
      })
      .catch((e) => {
        enqueueSnackbar("Error al remover puesto", {
          variant: "error",
        });
        console.log(e);
        return false;
      });
  };

  const jobsList = () =>
    books.map((job, index) => {
      return (
        <Grid
          key={index}
          container
          direction="row"
          justifyContent="flex-start"
          className={classes.gridJob}
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            props.setEmployeeData({
              ...props.employeeData,
              puesto: job.name,
            });
            props.setChangesFlag(true);
            props.setOpenDialogJobs(!props.openDialogJobs);
          }}
        >
          <Grid item xs={10}>
            <Typography className={classes.typoJob}>{job.name}</Typography>
          </Grid>

          <Grid
            item
            xs={2}
            container
            direction="row"
            justifyContent="flex-end"
            className={classes.gridJob2}
          >
            <FontAwesomeIcon
              icon={faChevronRight}
              className={classes.iconEdit}
              style={{
                width: "10px",
                height: "20px",
                color: "#fff",
              }}
            />
          </Grid>
        </Grid>
      );
    });

  const jobsListAdmin = () =>
    books.map((job, index) => {
      return (
        <Grid
          key={index}
          container
          direction="row"
          justifyContent="flex-start"
          className={classes.gridJob}
        >
          <Grid item xs={10}>
            <Typography className={classes.typoJob}>{job.name}</Typography>
          </Grid>

          <Grid
            item
            xs={2}
            container
            direction="row"
            justifyContent="flex-end"
            className={classes.gridJob2}
          >
            <FontAwesomeIcon
              icon={faPen}
              className={classes.iconEdit}
              onClick={() => {
                props.setJob({
                  ...props.job,
                  id: job._id,
                  name: job.name,
                });
                setDialog(3);
              }}
            />

            <Grid
              style={{
                width: "10px",
              }}
            />

            <FontAwesomeIcon
              icon={faTrashAlt}
              className={classes.iconEdit}
              onClick={() => deleteJob(job._id)}
            />
          </Grid>
        </Grid>
      );
    });

  return (
    <Dialog
      open={openDialogJobs}
      onClose={handleOpenDialog}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        style: {
          backgroundColor: "#171a1e",
          textColor: "#fsf",
          width: "483px",
          height: "600px",
          paddingTop: 15,
        },
      }}
    >
      <DialogTitle>
        {dialog == 1 ? (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Typography className={classes.title}>
              {user?.EnterpriseData?.Db != "GrupoGarzaPonce903228"
                ? "Puestos"
                : "Cargos"}
            </Typography>

            <Grid
              container
              justifyContent="flex-end"
              style={{ position: "absolute" }}
            >
              <Button onClick={handleOpenDialog}>
                <FontAwesomeIcon icon={faTimes} className={classes.iconClose} />
              </Button>
            </Grid>
          </Grid>
        ) : dialog == 2 ? (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              xs={1}
              onClick={() => setDialog(1)}
              className={classes.divBack}
            >
              <FontAwesomeIcon
                icon={faArrowAltCircleLeft}
                className={classes.iconBack}
              />
            </Grid>

            <Grid item xs={9}>
              <Typography className={classes.title}>
                {user?.EnterpriseData?.Db != "GrupoGarzaPonce903228"
                  ? "Administración de puestos"
                  : "Administración de cargos"}
              </Typography>
            </Grid>

            <Grid item xs={2}>
              <Button onClick={handleOpenDialog}>
                <FontAwesomeIcon icon={faTimes} className={classes.iconClose} />
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              xs={1}
              onClick={() => setDialog(2)}
              className={classes.divBack}
            >
              <FontAwesomeIcon
                icon={faArrowAltCircleLeft}
                className={classes.iconBack}
              />
            </Grid>

            <Grid item xs={9}>
              <Typography className={classes.title}>
                {user?.EnterpriseData?.Db != "GrupoGarzaPonce903228"
                  ? "Adminsitración de puestos"
                  : "Adminsitración de cargos"}
              </Typography>
            </Grid>

            <Grid item xs={2}>
              <Button onClick={handleOpenDialog}>
                <FontAwesomeIcon icon={faTimes} className={classes.iconClose} />
              </Button>
            </Grid>
          </Grid>
        )}

        <div className={classes.divDivider} />
      </DialogTitle>

      <DialogContent>
        {dialog == 1 ? (
          <Grid container direction="column" justifyContent="center">
            {user?.Subcontractor[0]?.empresaPadre ? (
              <Grid container direction="row">
                <Grid item xs={10}>
                  <TextField
                    placeholder="Buscar..."
                    name="Nombre"
                    size="small"
                    variant="outlined"
                    value={query?.Nombre}
                    className={classes.textFieldCustom2}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button onClick={() => setDialog(2)}>
                    <FontAwesomeIcon
                      icon={faCog}
                      style={{
                        width: "30px",
                        height: "30px",
                        color: "#fff",
                      }}
                    />
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <TextField
                  placeholder="Buscar..."
                  name="Nombre"
                  size="small"
                  variant="outlined"
                  value={query?.Nombre}
                  className={classes.textFieldCustom}
                  onChange={handleChange}
                />
              </Grid>
            )}

            <ScrollBars style={{ height: 410 }}>{jobsList()}</ScrollBars>
          </Grid>
        ) : dialog == 2 ? (
          <Grid container direction="column" justifyContent="center">
            <Grid container direction="row" justifyContent="flex-end">
              <Grid item xs={10}>
                <TextField
                  placeholder="Buscar..."
                  name="Nombre"
                  size="small"
                  variant="outlined"
                  value={query?.Nombre}
                  className={classes.textFieldCustom2}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  onClick={() => {
                    props.setJob({
                      ...props.job,
                      id: "",
                      name: "",
                    });
                    setDialog(3);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    style={{
                      width: "30px",
                      height: "30px",
                      color: "#d233ff",
                      backgroundColor: "#fff",
                      borderRadius: "50%",
                    }}
                  />
                </Button>
              </Grid>
            </Grid>

            <ScrollBars style={{ height: 410 }}>{jobsListAdmin()}</ScrollBars>
          </Grid>
        ) : (
          <Grid container direction="row" justifyContent="center">
            <Grid
              item
              xs={12}
              style={{
                height: "100px",
              }}
            />

            <Grid
              item
              xs={12}
              container
              direction="row"
              justifyContent="center"
            >
              <Typography className={classes.textFieldLetter}>
                {user?.EnterpriseData?.Db != "GrupoGarzaPonce903228"
                  ? "Nombre del puesto"
                  : "Nombre del cargo"}
              </Typography>
              <TextField
                name="name"
                size="small"
                variant="outlined"
                value={CapitalizeString(props.job.name)}
                className={classes.textFieldCustom}
                onChange={(e) => {
                  props.setJob({
                    ...props.job,
                    [e.target.name]: e.target.value,
                  });
                }}
                inputProps={{
                  maxLength: 50,
                }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                height: "120px",
              }}
            />

            <Grid
              item
              xs={12}
              container
              direction="row"
              justifyContent="center"
            >
              <Button
                variant="contained"
                onClick={() => updateJob(props.job.id)}
                className={classes.button}
              >
                <Typography className={classes.typoButton}>Guardar</Typography>
              </Button>
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
}
