/*eslint-disable*/
import { useSnackbar } from "notistack";
import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Typography, Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faPenAlt } from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles((theme) => ({
  divMain: {
    flexGrow: 1,
    [theme.breakpoints.down("md")]: {
      marginTop: 20,
    },
  },
  textInfo: {
    fontFamily: "Roboto",
    fontSize: 20,
    fontWeight: 500,
    textAlign: "left",
    color: "#ffffff",
    marginBottom: "55px",
    cursor: "pointer",
  },
  textInfoSub: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 300,
    textAlign: "left",
    color: "#bebebe",
    marginBottom: "30px",
  },
  icon: {
    width: "30px",
    height: "30px",
    color: "white",
    // backgroundColor: "red",
  },
  divIconActive: {
    width: "40px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50px",
    backgroundColor: "#d233ff",
    // marginBottom: "40px",
    cursor: "pointer",
  },
  divIconInactive: {
    width: "40px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50px",
    backgroundColor: "#a2a2a2",
    // marginBottom: "40px",
    cursor: "pointer",
  },
}));

export default function FlowComponent(props) {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const setScreenBasic = () => {
    props.setScreen(1);
  };

  const setScreenAdvance = () => {
    // if (props.screen == 1 && props.mandatoryFlag == false) {
    //   enqueueSnackbar("Favor de llenar campos obligatorios", {
    //     variant: "error",
    //   });
    //   return;
    // }
    props.setScreen(2);
  };

  return (
    <Grid container className={classes.divMain}>
      <Grid
        item
        lg={12}
        xl={12}
        style={{
          height: "100px",
        }}
      ></Grid>

      <Grid item xs={9}>
        <Typography onClick={setScreenBasic} className={classes.textInfo}>
          Información reporte
        </Typography>
        {/* <Typography className={classes.textInfoSub}>
            0 de 100% completado
          </Typography> */}

        {/* <Typography onClick={setScreenAdvance} className={classes.textInfo}>
          Generación de firmas
        </Typography> */}
        {/* <Typography className={classes.textInfoSub}>
            0 de 100% completado
          </Typography> */}
      </Grid>

      <Grid item xs={3} container direction="column" alignItems="center">
        <div
          onClick={setScreenBasic}
          className={
            props.screen === 1 ? classes.divIconActive : classes.divIconInactive
          }
        >
          <FontAwesomeIcon
            icon={faBook}
            style={{
              width: "18px",
              height: "18px",
              color: "#fff",
            }}
          />
        </div>
        {/* <Grid container direction="row" justifyContent="center">
          <div
            style={{
              width: "1px",
              height: "40px",
              backgroundColor: "#a2a2a2",
            }}
          />
        </Grid>
        <div
          onClick={setScreenAdvance}
          className={
            props.screen === 2 ? classes.divIconActive : classes.divIconInactive
          }
        >
          <FontAwesomeIcon
            icon={faPenAlt}
            style={{
              width: "18px",
              height: "18px",
              color: "#fff",
            }}
          />
        </div> */}
      </Grid>
    </Grid>
  );
}
